import config from '../config';
import pickBy from 'lodash/pickBy';

const getClassicMainhostSubdomain = () => (config.deployment === 'prod' ? 'classic' : `mainhost-${config.deployment}`);

const MARKETING_CAMPAIGN_EVENT_TYPES = [
    'Auction Calendar Banner',
    'Category Banner',
    'Category Leaderboard Ad',
    'Category Navigation Ad',
    'Featured Auction',
    'Featured Auctioneers Ad',
    'Featured Lots',
    'In-App Ad - Auction Calendar',
    'In-App Ad - Categories',
    'In-App Ad - Discover',
    'Leaderboard Ad - Auction House Directory',
    'Leaderboard Ad - Auctions Near Me',
    'Leaderboard Ad - Favorites',
    'Leaderboard Ad - Messages',
    'Leaderboard Ad - My Bids',
    'Leaderboard Ad - Won Items',
    'Saved Item Alert Ad',
    'Search Alert Ad',
];

export function urlSafeTitle(title: string = '') {
    const safe = title
        .trim() // & to and
        .replace(/&/g, 'and') // comma to hyphen
        .replace(/,+/g, '-') // * to hyphen
        .replace(/\*+/g, '-') // ; to hyphen
        .replace(/;+/g, '-') // : to hyphen
        .replace(/:+/g, '-') // ' to hyphen
        .replace(/'+/g, '-') // " to hyphen
        .replace(/"+/g, '-')
        .replace(/-*\s+-*/g, '-') // non letter/digit/& to nothing
        .replace(/[^a-zA-Z0-9-_&]/g, '') // many hyphen to one
        .replace(/--+/g, '-')
        .toLowerCase()
        .replace(/-+$/, '');
    return safe;
}
export const logoUrl = 'https://p1.liveauctioneers.com/dist/images/logo_og.jpg';
export const imagePlaceholder = 'https://p1.liveauctioneers.com/dist/images/placeholder.jpg';

export const getAuctioneerConsoleUrl = (id: number) => `/auctioneer-console/${id}`;
export const getAuctioneerCarouselUrl = (id: number) => `/auctioneer-carousel/${id}`;
export const getClerkConsoleUrl = (id: number) => `/clerk-console/${id}`;
export const getLegacyClerkConsoleUrl = (id: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/CLERKSALEWIN-${id}.html`;
export const getItemUrl = (deployment: string, id: number, title: string) => {
    const subdomain = deployment === 'prod' ? 'www' : `${deployment}-test`;
    return `https://${subdomain}.liveauctioneers.com/item/${id}_${urlSafeTitle(title)}`;
};

export function getCatalogUrl(deployment: string, id: number, title: string) {
    const subdomain = deployment === 'prod' ? 'www' : `${deployment}-test`;
    return `https://${subdomain}.liveauctioneers.com/catalog/${id}_${urlSafeTitle(title)}/`;
}

export function getSellerReviewsUrl(deployment: string, id: number, title: string) {
    const subdomain = deployment === 'prod' ? 'www' : `bidder-react-${deployment}`;
    return `https://${subdomain}.liveauctioneers.com/auctioneer/${id}/${urlSafeTitle(title)}/reviews`;
}

export const getBidderPageUrl = (catalogId: number, bidderId: number) => {
    return `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/bidder-management-new-${catalogId}.html?viewindividualbidder=${bidderId}`;
};

export const getBidderInfoPageUrl = (houseId: number, bidderId: number) => {
    return `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/brass/bidderinfo.html?checksubmit=yes&bidderid=${bidderId}`;
};

export const getBidderDetailsPageUrl = (catalogId: number, bidderId: number) => {
    return `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/bidder-management-new-${catalogId}.html?viewindividualbidder=${bidderId}`;
};

export const getItemCardImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 280);

const generateImageUrl = (
    sellerId,
    catalogId,
    itemId,
    imgNumber,
    imageVersion,
    size,
    limitQuality = true,
    cropImage: string = ''
) => {
    if (!sellerId || !catalogId || !itemId || !imgNumber) {
        return '';
    }

    const quality = limitQuality ? '&quality=50' : '';
    const crop = cropImage ? `&crop=${cropImage}` : '';

    return `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imgNumber}_x.jpg?version=${imageVersion}${
        size ? `&width=${size}` : ''
    }${quality}${crop}`;
};

export const toQString = (obj: any) => {
    const arr = [];
    // eslint-disable-next-line no-unused-vars
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            arr.push(`${encodeURIComponent(p)}=${obj[p]}`);
        }
    }
    arr.sort();
    return arr.join('&');
};

export const getImageUrl = ({
    blur,
    catalogId,
    crop,
    height,
    imageNumber,
    imageVersion,
    itemId,
    quality,
    sellerId,
    width,
}: any) => {
    if (!sellerId || !catalogId || !itemId) {
        return '';
    }

    const query = pickBy({
        blur,
        crop,
        height,
        quality,
        width,
    });

    return `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imageNumber}_x.jpg?version=${imageVersion}&${toQString(
        query
    )}`;
};

export const getMediumItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imgNumber: number,
    imageVersion: number = 1
) => generateImageUrl(sellerId, catalogId, itemId, imgNumber, imageVersion, 512);

export const getAuctioneerLogoUrl = (logoId: string, width: number = 46) => {
    return `https://images.liveauctioneers.com/houses/logos/lg/${logoId}_large.jpg?quality=100&width=${width}`;
};

export const getRankingToolTipUrl = (label: string) => {
    switch (label.toLowerCase()) {
        case 'accuracy':
            return 'https://www.liveauctioneers.com/pages/item-description-templates-for-your-next-online-auction/';
        case 'payment':
            return 'https://www.liveauctioneers.com/pages/how-a-single-payment-solution-can-drive-auction-sales/';
        case 'responsiveness':
            return 'https://www.liveauctioneers.com/pages/5-keys-to-unlocking-customer-satisfaction-and-growing-your-auction-sales/';
        case 'shipping':
            return 'https://www.liveauctioneers.com/pages/4-revenue-generating-shipping-strategies-for-your-auction-success/';
        default:
            return '';
    }
};

export const getClassicLotPerformancUrl = (itemId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/LOT${itemId}.html`;

export const getClassicAuctionSetup = (houseId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/auction-setup-init/${houseId}`;

// Lot Manager > Categories tab
export const getLotManagerCategorizer = (houseId: number, catalogId?: number) =>
    catalogId ? `/house/${houseId}/lot-manager/categories/${catalogId}` : `/house/${houseId}/lot-manager/categories`;

// Lot Manager > Performance tab
export const getCatalogItemsPerformanceUrl = (houseId: number, catalogId: number) =>
    `/house/${houseId}/lot-manager/performance/${catalogId}`;

export const getLotManagerPerformance = (houseId: number, catalogId?: number) =>
    catalogId ? `/house/${houseId}/lot-manager/performance/${catalogId}` : `/house/${houseId}/lot-manager/performance`;

// Performance > Catalog summary
export const getCatalogPerformanceUrl = (houseId: number, catalogId: number) =>
    `/house/${houseId}/performance/${catalogId}`;

export const getHousePerformanceUrl = (houseId: number) => `/house/${houseId}/performance/`;
export const getHouseUpcomingCatalogList = (houseId: number, catalogId?: number) =>
    `/upcoming-catalog-list/${houseId}/${catalogId ?? ''}`;

export const getPromotedLotsUrl = (catalogId: number) => `/featuredLots/${catalogId}`;

export const getPaymentsSummaryUrl = (houseId: number) => `/house/${houseId}/payments/summary`;
export const getPaymentsPaymentsUrl = (houseId: number) => `/house/${houseId}/payments/payments`;
export const getPaymentsDisbursementsUrl = (houseId: number) => `/house/${houseId}/payments/disbursements`;
export const getPaymentsReportingUrl = (houseId: number) => `/house/${houseId}/payments/reporting`;
export const getPaymentsCreditCardsUrl = (houseId: number) => `/house/${houseId}/payments/settings/creditcards`;

export const getDashboardUrl = (houseId: number) => `/house/${houseId}/dashboard`;
export const getBiddersUrl = (houseId: number) => `/house/${houseId}/bidders`;

export const getMarketingCampaignsListUrl = (houseId: number) => `/house/${houseId}/marketing/`;
export const getMarketingCampaignUrl = (eventId: number, houseId: number, eventType: string) => {
    let queryParam = '';
    if (Boolean(MARKETING_CAMPAIGN_EVENT_TYPES.includes(eventType))) {
        queryParam = `?eventId=${eventId}&eventType=${eventType}`;
    }
    return `/house/${houseId}/marketing/campaign/${eventId}${queryParam}`;
};

export const getClassicReportsUrl = (houseId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/reports.html?house_id=${houseId}`;

export const getClassicForumPostUrl = (forumId: number, topicId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers_bb/bb.php?action=vthread&forum=${forumId}&topic=${topicId}`;
export const getClassicForumUrl = (forumId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers_bb/bb.php?action=vtopic&forum=${forumId}`;
export const getGettingStartedUrl = () => 'https://support.liveauctioneers.com/category/438-getting-started';
export const getGuidesImageUrl = () => 'https://p1.liveauctioneers.com/dist/images/hb/Guides.jpg';
export const getMidYearReportUrl = () => 'https://www.liveauctioneers.com/pages/2019-mid-year-report';
export const getMidYearReportImageUrl = () => 'https://p1.liveauctioneers.com/dist/images/hb/Report.jpg';

export const getWebviewsUrl = (path: string) => {
    let domain = `https://webviews${config.deployment === 'prod' ? '' : `-${config.deployment}`}.liveauctioneers.com`;
    return `${domain}/${path}`;
};

export const getPayrixAddCreditCardUrl = (authToken?: string) => {
    let url = getWebviewsUrl('addcreditcard/3?showMakePrimary=true&showHouseAuth=true');
    if (authToken) {
        url = `${url}&token=${authToken}`;
    }

    return url;
};

export const getAuctionPreviewSetupUrl = (houseId: number) => `/auctionPreview/auctioneer/${houseId}`;

export const getEmailMarketingExplainationUrl = () =>
    'https://win.liveauctioneers.com/rs/507-MQX-145/images/LiveAuctioneers_Advertising.pdf';

export const getMessagesUrl = (houseId: number) => `/house/${houseId}/messages`;

export const getPayrixOnboardingIframeUrl = () => {
    if (config.deployment === 'prod' || config.deployment === 'preprod') {
        return 'https://portal.payrix.com/signup/liveauctioneers';
    } else {
        return 'https://test-portal.payrix.com/signup/liveauctioneers';
    }
};

export const getSellerTermsAndConditions = () =>
    'https://support.liveauctioneers.com/article/92-liveauctioneers-seller-terms-conditions';

export const getLAWebsiteTermsAndConditions = () => 'https://www.liveauctioneers.com/termsandconditions';

export const getLivePaymentsTerms = () => 'https://www.liveauctioneers.com/termsandconditionspayments';

export const getFeeSchedule = () =>
    'https://support.liveauctioneers.com/article/182-traditional-auction-vs-online-only-auction';

export const getPrivacyPolicy = () => 'https://www.liveauctioneers.com/privacy';

export const getHouseBillingUrl = (houseId: number, showPayButton: boolean) => {
    let url = `/house/${houseId}/account-and-billing/billing`;

    if (showPayButton) {
        url = `${url}?pay=1`;
    }

    return url;
};
export const getHouseInfoUrl = (houseId: number) => `/house/${houseId}/account-and-billing/my-info`;
export const getHouseLocationsUrl = (houseId: number): string => `/house/${houseId}/account-and-billing/locations`;

export const getHouseReceiptUrl = (receiptId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/ah-rcpt?bcode=${receiptId}`;
export const getHouseInvoiceUrl = (invoiceId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/ah-invoice?id=LA-AH${invoiceId}`;

export const getLivePaymentsExplanationUrl = () =>
    'https://support.liveauctioneers.com/article/56-what-is-the-livepayments-payments-program';

export const getPayrixOnboardingUrl = (houseId: number) => `/house/${houseId}/payments/onboarding/payrix`;

export const getPaymentsClassicDashboardUrl = (houseId: number) =>
    `https://seller-${config.deployment}.liveauctioneers.com/seller/${houseId}/finance`;

export const getCatalogProofPage = (catalogId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/PROOF${catalogId}.html`;

export const getBillingSetupUrl = (houseId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/brass/billing_v3/commission_options.php?house_id=${houseId}`;

export const getAccountingOverviewUrl = () =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers//brass/acc-overview.html`;

export const getMainhostAccountUrl = (houseId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/account-${houseId}.html`;

export const getBidderInvoiceListPage = (catalogId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/EOA-I-new3-${catalogId}.html`;

export const getClassicBidderInvoiceHouseView = (invoiceId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/invoice3?inv_id=LA${invoiceId}`;

export const getClassicBidderInvoicePreviewHouseView = (invoiceId: number) =>
    `https://${getClassicMainhostSubdomain()}.liveauctioneers.com/auctioneers/ind_invoice3.php?inv_id=LA${invoiceId}&preview=1`;

export const getScheduleTimeUrl = () => 'https://bit.ly/3wz4UZJ';

export const getAtgInvoiceUrl = (houseId: number, invoiceId: number) => `/house/${houseId}/atg-invoice/${invoiceId}`;

export const getInvoiceUrl = (houseId: number, invoiceId: number) => `/house/${houseId}/invoice/${invoiceId}`;
