import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';
import UAParser from 'ua-parser-js';

const BROWSER_DATA = 'BROWSER_DATA';

export type BrowserReduxType = {
    deviceType: string;
    isOutdated: boolean;
    majorVersion: number;
    microsoftBrowser: boolean;
    mobile: string;
    name: string;
    os: string;
};

// reducer
export const DEFAULT_STATE: BrowserReduxType = {
    deviceType: 'computer',
    isOutdated: false,
    majorVersion: 0,
    microsoftBrowser: false,
    mobile: '',
    name: '',
    os: '',
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [BROWSER_DATA]: (state: State, action: ActionWithPayload<{ browser: {} }>) => ({
            ...state,
            ...action.payload.browser,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.browser;

export const getUiBrowser = createSelector(stateSelector, (state) => state);

export const isUiMobile = createSelector(getUiBrowser, (uiBrowser) => uiBrowser.mobile);

export const isBrowserIE = createSelector(getUiBrowser, (uiBrowser) => {
    return Boolean(uiBrowser.name && uiBrowser.name.toLowerCase() === 'ie');
});

export const getDeviceType = createSelector(getUiBrowser, (uiBrowser) => uiBrowser.deviceType);

export const getMobileBrowserOS = createSelector(getUiBrowser, (uiBrowser) => {
    return uiBrowser.mobile ? uiBrowser.os : '';
});

export const isUiMicrosoftBrowser = createSelector(getUiBrowser, (uiBrowser) => uiBrowser.microsoftBrowser);

export const isUiTablet = createSelector(isUiMobile, (mobile) => {
    return mobile === 'tablet';
});

const getMinimumBrowserVersions = () => {
    return {
        chrome: 45,
        edge: 13,
        firefox: 41,
        ie: 11,
        safari: 9,
    };
};

export const getBrowserDataFromUA = (userAgentString: string): BrowserReduxType => {
    const parser = new UAParser();

    parser.setUA(userAgentString);
    const result = parser.getResult();

    let name = '';
    let os = '';
    let majorVersion = 0;
    let mobile = '';
    let deviceType = '';

    if (result && result.browser) {
        if (typeof result.browser.name === 'string') {
            // Googlebot don't have a name
            name = result.browser.name.toLowerCase();
        }
        if (result.browser.major) {
            majorVersion = parseInt(result.browser.major, 10);
        }
    }
    if (result && result.os) {
        if (typeof result.os.name === 'string') {
            os = result.os.name.toLowerCase();
        }
    }
    if (result && result.device) {
        deviceType = result.device.type || 'computer'; // [console, mobile, tablet, smarttv, wearable, embedded]

        if (result.device.type === 'mobile') {
            mobile = 'phone';
        } else if (result.device.type === 'tablet') {
            mobile = result.device.type;
        }
    }

    const minimumBrowserVersions: any = getMinimumBrowserVersions();

    let isOutdated = false;
    if (Object.prototype.hasOwnProperty.call(minimumBrowserVersions, name)) {
        isOutdated = minimumBrowserVersions[name] > majorVersion;
    }

    const isGoogle = userAgentString.toLowerCase().includes('google');
    if (isGoogle) {
        isOutdated = false;
    }

    return {
        deviceType,
        isOutdated,
        majorVersion,
        microsoftBrowser: Boolean(name === 'ie' || name === 'edge'),
        mobile,
        name,
        os,
    };
};

export const setBrowserData = (browser: any) => ({
    payload: { browser },
    type: BROWSER_DATA,
});
