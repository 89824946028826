import { handleResponse, makeDelete, makeGet, makePost } from '@/redux/api/helpers';
import type {
    DeleteParsedCatalogShippingItemDimensionsResponse,
    FetchParsedCatalogShippingItemsResponse,
    FetchParsedShippingItemsParams,
    GetDownloadShippingItemsResponse,
    ParsedCatalogShippingItem,
    ParsedShippingVisibility,
    PostConfirmCatalogShippingItemsResponse,
    PostParseCatalogShippingItemsResponse,
    PostParseShippingItemsParams,
} from './catalogShippingParser.types';

type FetchParsedShippingItemsAPIParams = {
    authToken: string;
    deployment: string;
    params: FetchParsedShippingItemsParams;
};

export const fetchParsedCatalogShippingItems = ({ authToken, deployment, params }: FetchParsedShippingItemsAPIParams) =>
    new Promise<FetchParsedCatalogShippingItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'parse-shipping-dimensions',
            authToken,
            deployment,
            path: '<CATALOG_MANAGEMENT>',
        });

        const { catalogId, filters, page, pageSize } = params;

        request.query({
            catalogId,
            page,
            pageSize,
            ...filters,
        });

        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostParseCatalogShippingForItemsParams = {
    authToken: string;
    deployment: string;
    params: PostParseShippingItemsParams;
};

export const postParseCatalogShippingForItems = ({
    authToken,
    deployment,
    params,
}: PostParseCatalogShippingForItemsParams) =>
    new Promise<PostParseCatalogShippingItemsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'parse-shipping-dimensions',
            authToken,
            deployment,
            path: '<CATALOG_MANAGEMENT>',
        });

        const { catalogId, filters, page, pageSize } = params;

        request.send({
            catalogId,
            filters,
            page,
            pageSize,
        });

        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostConfirmItemsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    editOnly: boolean;
    lots: ParsedCatalogShippingItem[];
    page: number;
    pageSize: number;
    quoteVisibility: ParsedShippingVisibility;
};

/**
 * @param {PostConfirmItemsParams} confirmItemsParams
 * @param confirmItemsParams.authToken User's auth token
 * @param confirmItemsParams.catalogId The catalog being parsed
 * @param confirmItemsParams.deployment Which environment the user is on
 * @param confirmItemsParams.editOnly If true, the values will be saved but the lots will not be confirmed
 * @param confirmItemsParams.lots Which lots are being confirmed
 * @param confirmItemsParams.page Which page is the user on
 * @param confirmItemsParams.pageSize How many lots are on one page
 * @returns {PostConfirmCatalogShippingItemsResponse}
 * @returns API response for the confirm request
 */
export const postConfirmItems = ({
    authToken,
    catalogId,
    deployment,
    editOnly,
    lots,
    page,
    pageSize,
    quoteVisibility,
}: PostConfirmItemsParams) =>
    new Promise<PostConfirmCatalogShippingItemsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'parse-shipping-dimensions/confirm',
            authToken,
            deployment,
            path: '<CATALOG_MANAGEMENT>',
        });

        request.query({
            page,
            pageSize,
        });

        request.send({
            catalogId,
            editOnly,
            lots,
            quoteVisibility,
        });

        request.end((err, response) =>
            handleResponse({
                attachMetaToError: true,
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type DeleteIemDimensionsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    lotIds: number[];
};

export const deleteItemDimensions = ({ authToken, catalogId, deployment, lotIds }: DeleteIemDimensionsParams) =>
    new Promise<DeleteParsedCatalogShippingItemDimensionsResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: 'parse-shipping-dimensions/delete',
            authToken,
            deployment,
            path: '<CATALOG_MANAGEMENT>',
        });

        request.send({
            catalogId,
            lotIds,
        });

        request.end((err, response) => {
            handleResponse({
                err,
                reject,
                resolve,
                response,
            });
        });
    });

type GetDownloadShippingItemsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const getDownloadShippingItems = ({ authToken, catalogId, deployment }: GetDownloadShippingItemsParams) =>
    new Promise<GetDownloadShippingItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'parse-shipping-dimensions/download',
            authToken,
            deployment,
            path: '<CATALOG_MANAGEMENT>',
        });

        request.query({ catalogId });

        request.end((err, response) => {
            handleResponse({ err, reject, resolve, response });
        });
    });
