import { AutoApprovals, PostCriteria } from '../../types/AutoApprovals';
import { handleResponse, makeGet, makePost } from './helpers';

type AutoApprovalParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type AutoApprovalsResponsePayload = {
    error: boolean;
    payload: AutoApprovals;
};

type FavoriteBiddersResponsePayload = {
    error: boolean;
    payload: number;
};

type PostCreateAutoApprovalCriteriaParams = {
    authToken: string;
    criteria: PostCriteria;
    deployment: string;
    houseId: number;
};

type PostCreateAutoApprovalCriteriaPayload = {
    error: boolean;
    meta?: object;
    payload: string;
};

export const getAdditionalFavoriteBidders = ({ authToken, deployment, houseId }: AutoApprovalParams) =>
    new Promise<FavoriteBiddersResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/additional-favorite-count`,
        });
        request.end((err: boolean, response: FavoriteBiddersResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const getAutoApprovalCriteria = ({ authToken, deployment, houseId }: AutoApprovalParams) =>
    new Promise<AutoApprovalsResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/criteria`,
        });
        request.end((err: boolean, response: AutoApprovalsResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postCreateAutoApprovalCriteria = ({
    authToken,
    criteria,
    deployment,
    houseId,
}: PostCreateAutoApprovalCriteriaParams) =>
    new Promise<PostCreateAutoApprovalCriteriaPayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/criteria/create`,
        });
        request.send(criteria);
        request.end((err: boolean, response: PostCreateAutoApprovalCriteriaPayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });
