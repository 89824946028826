import { handleResponse, makePost } from './helpers';

type FetchFollowerCountParams = {
    authToken: string;
    deployment: string;
    sellerIds: number[];
};

type FollowerCountResponse = {
    data: { [sellerId: number]: number };
    message: string;
    success: boolean;
};

export default {
    fetchFollowerCount: ({ deployment, sellerIds }: FetchFollowerCountParams) =>
        new Promise<FollowerCountResponse>((resolve, reject) => {
            const request = makePost({ deployment, path: '<ITEM-API>follower-count/' });
            request.send({ sellerIds });
            request.end((err: boolean, response: FollowerCountResponse) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
