import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { HousePayment } from '@/types/HousePayment';
import { SEND_LA_HOUSE_PAYMENT_FAIL, SEND_LA_HOUSE_PAYMENT_REQUEST, SEND_LA_HOUSE_PAYMENT_SUCCESS } from './actions';
import api from '../api/housePayment';

/* reducer */
export interface State {
    error: string | null;
    houseId: number | null;
    loading: boolean;
    success: boolean;
}

export const DEFAULT_STATE: State = {
    error: undefined,
    houseId: null,
    loading: false,
    success: false,
};

export const reducer = handleActions(
    {
        [combineActions(SEND_LA_HOUSE_PAYMENT_FAIL)]: (state: State, action: ActionWithPayload<any>) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [SEND_LA_HOUSE_PAYMENT_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [SEND_LA_HOUSE_PAYMENT_SUCCESS]: (state: State) => ({
            ...state,
            loading: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.housePayment;

export const getPaymentLoading = createSelector(stateSelector, (state) => state.loading);

export const getPaymentError = createSelector(stateSelector, (state) => state.error);

export const getPaymentSuccess = createSelector(stateSelector, (state) => state.success);

/* ACTION CREATORS */

export const sendLAHousePayment =
    ({
        amount,
        catalogId,
        currency,
        houseId,
        invoiceId,
        items,
        note,
        parentId,
        paymentToken,
        providerId,
        userId,
    }: HousePayment) =>
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: SEND_LA_HOUSE_PAYMENT_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await api.postLAHousePayment({
                amount,
                authToken,
                catalogId,
                currency,
                deployment,
                houseId,
                invoiceId,
                items,
                note,
                parentId,
                paymentToken,
                providerId,
                userId,
            });

            return dispatch({
                payload: response.payload,
                type: SEND_LA_HOUSE_PAYMENT_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error.message || error,
                type: SEND_LA_HOUSE_PAYMENT_FAIL,
            });
        }
    };
