import { FetchLiveAuctionStatusResponse } from '@/redux/api/clerk';
import { TypedActionWithPayload } from '@/types/redux';
import type { AuctionStatus } from '@/types/LiveAuctionState';

export const LOAD_CATALOGS_FAIL = 'la/domain/catalogs/LOAD_FAIL';
export const LOAD_CATALOGS_REQUEST = 'la/domain/catalogs/LOAD_REQUEST';
export const LOAD_CATALOGS_SUCCESS = 'la/domain/catalogs/LOAD_SUCCESS';

export const LOAD_CATALOG_BIDDING_FAIL = 'la/domain/catalogBidding/LOAD_FAIL';
export const LOAD_CATALOG_BIDDING_REQUEST = 'la/domain/catalogBidding/LOAD_REQUEST';
export const LOAD_CATALOG_BIDDING_SUCCESS = 'la/domain/catalogBidding/LOAD_SUCCESS';

export const LOAD_CATALOG_ITEMS_FAIL = 'la/domain/catalogItems/LOAD_FAIL';
export const LOAD_CATALOG_ITEMS_REQUEST = 'la/domain/catalogItems/LOAD_REQUEST';
export const LOAD_CATALOG_ITEMS_SUCCESS = 'la/domain/catalogItems/LOAD_SUCCESS';

export const LOAD_CATALOG_REGISTRATION_COUNT_FAIL = 'la/domain/catalogRegistrationCount/LOAD_FAIL';
export const LOAD_CATALOG_REGISTRATION_COUNT_REQUEST = 'la/domain/catalogRegistrationCount/LOAD_REQUEST';
export const LOAD_CATALOG_REGISTRATION_COUNT_SUCCESS = 'la/domain/catalogRegistrationCount/LOAD_SUCCESS';

export const LOAD_CONSOLE_BRANDING_FAIL = 'la/domain/consoleBranding/LOAD_FAIL';
export const LOAD_CONSOLE_BRANDING_REQUEST = 'la/domain/consoleBranding/LOAD_REQUEST';
export const LOAD_CONSOLE_BRANDING_SUCCESS = 'la/domain/consoleBranding/LOAD_SUCCESS';

export const LOAD_COVER_LOTS_FAIL = 'la/domain/coverLots/LOAD_FAIL';
export type LOAD_COVER_LOTS_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_COVER_LOTS_FAIL,
    string,
    { catalogIds: number[] }
>;
export const LOAD_COVER_LOTS_REQUEST = 'la/domain/coverLots/LOAD_REQUEST';
export const LOAD_COVER_LOTS_SUCCESS = 'la/domain/coverLots/LOAD_SUCCESS';

export const LOAD_ITEMS_FAIL = 'la/domain/items/LOAD_FAIL';
export const LOAD_ITEMS_REQUEST = 'la/domain/items/LOAD_REQUEST';
export const LOAD_ITEMS_SUCCESS = 'la/domain/items/LOAD_SUCCESS';

export const LOAD_ITEM_RESERVES_FAIL = 'la/domain/itemReserves/LOAD_FAIL';
export const LOAD_ITEM_RESERVES_REQUEST = 'la/domain/itemReserves/LOAD_REQUEST';
export const LOAD_ITEM_RESERVES_SUCCESS = 'la/domain/itemReserves/LOAD_SUCCESS';

export const LOAD_ITEM_BIDDING_FAIL = 'la/domain/itemBidding/LOAD_FAIL';
export const LOAD_ITEM_BIDDING_REQUEST = 'la/domain/itemBidding/LOAD_REQUEST';
export const LOAD_ITEM_BIDDING_SUCCESS = 'la/domain/itemBidding/LOAD_SUCCESS';

export const LOAD_ITEM_DETAILS_FAIL = 'la/domain/itemDetail/LOAD_FAIL';
export const LOAD_ITEM_DETAILS_REQUEST = 'la/domain/itemDetail/LOAD_REQUEST';
export const LOAD_ITEM_DETAILS_SUCCESS = 'la/domain/itemDetail/LOAD_SUCCESS';

/** Live auction status is either a status of 'notLoaded' with nothing else or the entire auction status */
type LiveAuctionStatusNotLoaded = {
    status: 'notLoaded';
};

type LiveAuctionStatusLoaded = FetchLiveAuctionStatusResponse['data'] & {
    status: Exclude<AuctionStatus, 'notLoaded'>;
};

export const LOAD_LIVE_CATALOG_STATUS_FAIL = 'la/domain/liveCatalogStatus/LOAD_FAIL';
export const LOAD_LIVE_CATALOG_STATUS_REQUEST = 'la/domain/liveCatalogStatus/LOAD_REQUEST';
export const LOAD_LIVE_CATALOG_STATUS_SUCCESS = 'la/domain/liveCatalogStatus/LOAD_SUCCESS';
export type LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LiveAuctionStatusNotLoaded | LiveAuctionStatusLoaded,
    {
        actionTime: number;
        bidderId: number;
        catalogId: number;
    }
>;

export const LOAD_CATALOG_REGISTRATION_STATS_FAIL = 'la/domain/catalogRegistrationStats/LOAD_FAIL';
export const LOAD_CATALOG_REGISTRATION_STATS_REQUEST = 'la/domain/catalogRegistrationStats/LOAD_REQUEST';
export const LOAD_CATALOG_REGISTRATION_STATS_SUCCESS = 'la/domain/catalogRegistrationStats/LOAD_SUCCESS';

export const LOAD_BIDDER_PADDLE_NUMBER_FAIL = 'LOAD_BIDDER_PADDLE_NUMBER_FAIL';
export type LOAD_BIDDER_PADDLE_NUMBER_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_PADDLE_NUMBER_FAIL,
    string,
    {
        catalogId: number;
        itemId: number;
    }
>;
export const LOAD_BIDDER_PADDLE_NUMBER_REQUEST = 'LOAD_BIDDER_PADDLE_NUMBER_REQUEST';
export type LOAD_BIDDER_PADDLE_NUMBER_REQUEST_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_PADDLE_NUMBER_REQUEST,
    {
        catalogId: number;
        itemId: number;
    }
>;
export const LOAD_BIDDER_PADDLE_NUMBER_SUCCESS = 'LOAD_BIDDER_PADDLE_NUMBER_SUCCESS';
export type LOAD_BIDDER_PADDLE_NUMBER_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_PADDLE_NUMBER_SUCCESS,
    { bid_master_id: string; lot_num: string; paddle_number: string },
    { actionTime: number; catalogId: number; itemId: number }
>;

export const LOAD_HOUSE_FEATURES_FAIL = 'LOAD_HOUSE_FEATURES_FAIL';
export const LOAD_HOUSE_FEATURES_REQUEST = 'LOAD_HOUSE_FEATURES_REQUEST';
export const LOAD_HOUSE_FEATURES_SUCCESS = 'LOAD_HOUSE_FEATURES_SUCCESS';

export const LOAD_SAVED_ITEM_COUNT_FAIL = 'la/domain/savedItemCount/LOAD_FAIL';
export const LOAD_SAVED_ITEM_COUNT_REQUEST = 'la/domain/savedItemCount/LOAD_REQUEST';
export const LOAD_SAVED_ITEM_COUNT_SUCCESS = 'la/domain/savedItemCount/LOAD_SUCCESS';

export const LOAD_ALL_SELLERS_FAIL = 'la/domain/allSellers/LOAD_FAIL';
export const LOAD_ALL_SELLERS_REQUEST = 'la/domain/allSellers/LOAD_REQUEST';
export const LOAD_ALL_SELLERS_SUCCESS = 'la/domain/allSellers/LOAD_SUCCESS';

export const LOAD_SELLERS_FAIL = 'la/domain/sellers/LOAD_FAIL';
export const LOAD_SELLERS_REQUEST = 'la/domain/sellers/LOAD_REQUEST';
export const LOAD_SELLERS_SUCCESS = 'la/domain/sellers/LOAD_SUCCESS';

export const LOAD_STREAM_NAME_FAIL = 'LOAD_STREAM_NAME_FAIL';
export type LOAD_STREAM_NAME_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_STREAM_NAME_FAIL,
    string,
    { catalogId: number }
>;
export const LOAD_STREAM_NAME_REQUEST = 'LOAD_STREAM_NAME_REQUEST';
export type LOAD_STREAM_NAME_REQUEST_ACTION = TypedActionWithPayload<
    typeof LOAD_STREAM_NAME_REQUEST,
    number,
    { actionTime: number }
>;
export const LOAD_STREAM_NAME_SUCCESS = 'LOAD_STREAM_NAME_SUCCESS';
export type LOAD_STREAM_NAME_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_STREAM_NAME_SUCCESS,
    { status: string; streamId: string },
    { actionTime: number; catalogId: number }
>;

export const LOAD_BATCH_END = 'LOAD_BATCH_END';
export const LOAD_BATCH_START = 'LOAD_BATCH_START';

export const LOG_OUT = 'LOG_OUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const SEND_REVIEW_COMMENT_FAIL = 'la/ui/sendReviewComment/FAIL';
export const SEND_REVIEW_COMMENT_REQUEST = 'la/ui/sendReviewComment/REQUEST';
export const SEND_REVIEW_COMMENT_SUCCESS = 'la/ui/sendReviewComment/SUCCESS';

export const LOAD_REVIEW_COMMENTS_FAIL = 'la/domain/reviewComments/LOAD_FAIL';
export const LOAD_REVIEW_COMMENTS_REQUEST = 'la/domain/reviewComments/LOAD_REQUEST';
export const LOAD_REVIEW_COMMENTS_SUCCESS = 'la/domain/reviewComments/LOAD_SUCCESS';

export const SEND_CHANGE_CATALOG_START_TIME_FAILURE = 'SEND_CHANGE_CATALOG_START_TIME_FAILURE';
export type SEND_CHANGE_CATALOG_START_TIME_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CATALOG_START_TIME_FAILURE,
    string,
    {
        actionTime: number;
        catalogId: number;
    }
>;
export const SEND_CHANGE_CATALOG_START_TIME_REQUEST = 'SEND_CHANGE_CATALOG_START_TIME_REQUEST';
export type SEND_CHANGE_CATALOG_START_TIME_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CATALOG_START_TIME_REQUEST,
    {
        catalogId: number;
        newStartTs: number;
    }
>;
export const SEND_CHANGE_CATALOG_START_TIME_SUCCESS = 'SEND_CHANGE_CATALOG_START_TIME_SUCCESS';
export type SEND_CHANGE_CATALOG_START_TIME_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CATALOG_START_TIME_SUCCESS,
    {
        catalogId: number;
    },
    {
        catalogId: number;
    }
>;

export const UPDATE_MISSIVE_BUTTONS = 'UPDATE_MISSIVE_BUTTONS';

export const RESET_FEEDBACK_MODAL = 'la/ui/submitFeedback/RESET';
export const SUBMIT_FEEDBACK_FAIL = 'la/ui/submitFeedback/FAIL';
export const SUBMIT_FEEDBACK_REQUEST = 'la/ui/submitFeedback/REQUEST';
export const SUBMIT_FEEDBACK_SUCCESS = 'la/ui/submitFeedback/SUCCESS';
