import { BidValueMetrics } from '../../types/BidValueMetrics';
import { handleResponse, makeGet } from './helpers';

type BidValueMetricsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
};

type BidValueMetricsResponsePayload = {
    error: boolean;
    payload: BidValueMetrics;
};

export default {
    getBidValueMetrics: ({ authToken, catalogId, deployment, houseId }: BidValueMetricsParams) =>
        new Promise<BidValueMetricsResponsePayload>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/upcoming-bid-values`,
            });
            request.query({ catalogId });
            request.end((err: boolean, response: BidValueMetricsResponsePayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
