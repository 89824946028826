const modalTypes = {
    ACCOUNTING_CONTACT: 'ACCOUNTING_CONTACT',
    ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
    BALANCE_PAYMENT: 'PAYMENT',
    BIDDER_GUARANTEE_PROGRAM: 'BIDDER_GUARANTEE_PROGRAM',
    BIDDER_GUARANTEE_PROGRAM_OPT_OUT_MODAL: 'BIDDER_GUARANTEE_PROGRAM_OPT_OUT_MODAL',
    BIDDER_MASS_FAVORITING: 'BIDDER_MASS_FAVORITING',
    BIDDER_MESSAGE: 'BIDDER_MESSAGE',
    CATALOG_ADS: 'CATALOG_ADS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CREATE_ANNOUNCEMENT_MODAL: 'CREATE_ANNOUNCEMENT_MODAL',
    FEATURED_LOTS: 'FEATURED_LOTS',
    FEEDBACK: 'FEEDBACK',
    HEALTHCHECK: 'HEALTHCHECK',
    LAP_MANDATE: 'LAP_MANDATE',
    MARKETING_ADVERTISING: 'MARKETING_ADVERTISING',
    NONE: 'NONE',
    PAGEVIEW_REFERRAL_SOURCES: 'PAGEVIEW_REFERRAL_SOURCES',
    PAYMENTS_TOGGLE_SAME_DAY: 'PAYMENTS_TOGGLE_SAME_DAY',
    PERFORMANCE_REGISTRATION_COUNTRY: 'PERFORMANCE_REGISTRATION_COUNTRY',
    PR_SERVICES: 'PR_SERVICES',
    SPEED_CHECK: 'SPEED_CHECK',
};

export default modalTypes;
