import * as api from '../api/upcomingAuctions';
import { ActionWithPayload } from '../../types/redux';
import { Auction } from '../../types/UpcomingAuctions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { LOAD_UPCOMING_AUCTIONS_FAIL, LOAD_UPCOMING_AUCTIONS_REQUEST, LOAD_UPCOMING_AUCTIONS_SUCCESS } from './actions';

export type State = {
    error: boolean;
    isLoading: boolean;
    upcomingAuctions: Auction[];
};
/* reducer */
export const DEFAULT_STATE: State = {
    error: false,
    isLoading: false,
    upcomingAuctions: [{ label: '', saleStart: '', title: '', value: 0 }],
};

export const reducer = handleActions(
    {
        [LOAD_UPCOMING_AUCTIONS_FAIL]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: false,
        }),
        [LOAD_UPCOMING_AUCTIONS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_UPCOMING_AUCTIONS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                upcomingAuctions: Auction[];
            }>
        ): State => {
            return {
                ...state,
                isLoading: false,
                upcomingAuctions: action.payload.upcomingAuctions,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.upcomingAuctions;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const auctionsSelector = createSelector(stateSelector, (state) => state.upcomingAuctions);

/* ACTION CREATORS */
export const fetchUpcomingAuctions = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_UPCOMING_AUCTIONS_REQUEST,
        });

        const response = await api.getUpcomingAuctions({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            payload: { upcomingAuctions: response.payload },
            type: LOAD_UPCOMING_AUCTIONS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: LOAD_UPCOMING_AUCTIONS_FAIL,
        });
    }
};
