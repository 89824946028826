import { AccountingContact } from '../../types/AccountContact';
import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_ACCOUNTING_CONTACT_FAIL,
    LOAD_ACCOUNTING_CONTACT_REQUEST,
    LOAD_ACCOUNTING_CONTACT_SUCCESS,
    SEND_ACCOUNTING_CONTACT_FAIL,
    SEND_ACCOUNTING_CONTACT_REQUEST,
    SEND_ACCOUNTING_CONTACT_SUCCESS,
} from './actions';
import api from '../api/accountingContact';

export type State = {
    contact: AccountingContact;
    error: boolean;
    loading: boolean;
};

export const DEFAULT_STATE: State = {
    contact: {
        email: '',
        fax: '',
        houseId: 0,
        name: '',
        phone: '',
    },
    error: false,
    loading: false,
};

export const reducer = handleActions(
    {
        [LOAD_ACCOUNTING_CONTACT_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            loading: false,
        }),
        [LOAD_ACCOUNTING_CONTACT_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [LOAD_ACCOUNTING_CONTACT_SUCCESS]: (state: State, action: ActionWithPayload<any>): State => ({
            ...state,
            contact: action.payload,
            loading: false,
        }),
        [SEND_ACCOUNTING_CONTACT_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            loading: false,
        }),
        [SEND_ACCOUNTING_CONTACT_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [SEND_ACCOUNTING_CONTACT_SUCCESS]: (state: State): State => ({
            ...state,
            loading: false,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState): State => state.accountingContact;

export const getAccountingContact = createSelector(stateSelector, (state) => state.contact);

export const fetchAccountingContact = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: LOAD_ACCOUNTING_CONTACT_REQUEST });

        const response = await api.getAccountingContact({
            authToken,
            deployment,
            houseId,
        });

        dispatch({
            payload: response.payload,
            type: LOAD_ACCOUNTING_CONTACT_SUCCESS,
        });
    } catch (error) {
        dispatch({ error: true, payload: error, type: LOAD_ACCOUNTING_CONTACT_FAIL });
    }
};

export const sendAccountingContact =
    (houseId: number, name: string, email: string, phone: string, fax: string) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: SEND_ACCOUNTING_CONTACT_REQUEST });

            const response = await api.postAccountingContact({
                authToken,
                deployment,
                email,
                fax,
                houseId,
                name,
                phone,
            });

            dispatch({
                payload: response.payload,
                type: SEND_ACCOUNTING_CONTACT_SUCCESS,
            });
        } catch (error) {
            dispatch({ error: true, payload: error, type: SEND_ACCOUNTING_CONTACT_FAIL });
        }
    };
