import { defaultConfigSlice as configDefaultState } from './modules/config';
import config from '../config';
import uuid from 'uuid';

const getCookieValue = (a: string) => {
    const b = typeof document !== 'undefined' && document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : '';
};

const getReferrer = (req: any) => {
    return req.headers.referrer || req.headers.referer || document.referrer || '';
};

export const getInitialState = (req: any = { cookies: [], headers: [], hostname: '', url: '' }) => {
    const token = req.cookies['auctioneer-auth'] || getCookieValue('auctioneer-auth') || '';
    const impersonatedHouseId =
        req.cookies['admin-impersonated-house'] || getCookieValue('admin-impersonated-house') || '';
    const referrer = getReferrer(req);
    return {
        config: {
            ...configDefaultState,
            ...config,
        },
        session: {
            sessionId: uuid.v4(),
        },
        user: {
            impersonatedHouseId,
            referrer,
            token,
            userData: {},
        },
    };
};
