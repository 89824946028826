import { handleResponse, makePost } from './helpers';

type PostHouseForgotPasswordParams = {
    deployment: string;
    username: string;
};

type PostSpaAuctioneerLoginPayload = {
    meta: {
        actionTime: number;
        analytics: {
            eventPayload: {
                traits: {
                    houseId: number | null;
                    userType: string;
                };
                userId: string;
            };
            eventType: string;
        };
    };
    payload: {
        amcToken: string;
        id: number;
        sessionKey: string;
        token: string;
        type: string;
        userData: {
            adminAccount: boolean;
            email: string;
            featureLotExclusion: boolean;
            houseId: number | null;
            limitedAccess: boolean;
            name: string;
            sellerId: string | undefined;
            staffPerms: object;
            username: string;
        };
    };
};

export const postHouseForgotPassword = ({ deployment, username }: PostHouseForgotPasswordParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'forgot-password', deployment, path: '<HOUSE-USER-API>' });
        request.send({
            username,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostHouseResetPasswordParams = {
    deployment: string;
    hash: string;
    newPassword: string;
    userId: number;
};

export const postHouseResetPassword = ({ deployment, hash, newPassword, userId }: PostHouseResetPasswordParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'reset-password', deployment, path: '<HOUSE-USER-API>' });
        request.send({
            hash,
            newPassword,
            userId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSpaAuctioneerLoginParams = {
    deployment: string;
    password: string;
    username: string;
};

export const postSpaAuctioneerLogin = ({ deployment, password, username }: PostSpaAuctioneerLoginParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>auth/spaauctioneerlogin' });
        request.send({
            password,
            username,
        });
        request.end((err: boolean, response: PostSpaAuctioneerLoginPayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });
