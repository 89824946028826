import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getCatalog } from './catalog';
import { getDeployment } from '../../redux/modules/config';
import {
    LIVE_BID_ACCEPTED,
    LIVE_LOT_CLOSED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_NEXT_LOT_LOADED,
    SEND_RETRACT_BID_FAILURE,
    SEND_RETRACT_BID_REQUEST,
    SEND_RETRACT_BID_SUCCESS,
} from './actions';
import { postRetractBid } from '../api/clerk';
import type { GlobalState } from '@/redux/rootReducer';

export type State = {
    isRetractable: boolean;
};

export const DEFAULT_STATE: State = {
    isRetractable: false,
};

export const reducer = handleActions(
    {
        [LIVE_BID_ACCEPTED]: (state: State): State => ({
            ...state,
            isRetractable: true,
        }),
        [combineActions(
            LIVE_LOT_CLOSED,
            LIVE_LOT_PASSED,
            LIVE_LOT_REOPENED,
            LIVE_LOT_SKIPPED,
            LIVE_LOT_SOLD,
            LIVE_LOT_UNSOLD,
            LIVE_NEXT_LOT_LOADED,
            SEND_RETRACT_BID_SUCCESS
        )]: (state: State): State => ({
            ...state,
            isRetractable: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.retractBid;

export const getIsBidRetractable = createSelector(stateSelector, (state) => Boolean(state.isRetractable));

/* ACTION CREATORS */
export const submitRetractBid =
    (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);

            dispatch({
                type: SEND_RETRACT_BID_REQUEST,
            });
            const response = await postRetractBid({
                authToken,
                catalogId,
                deployment,
                itemId,
                sellerId,
            });
            dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response.payload,
                type: SEND_RETRACT_BID_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SEND_RETRACT_BID_FAILURE,
            });
        }
    };
