import { getUnreadInboxCount } from '@/redux/modules/conversationFolders';
import { useAppSelector } from '@/redux/hooks';
import CountBadge from '@liveauctioneers/caterwaul-components/lib/CountBadge/CountBadge';
import NavMenuItem, { NavMenuItemProps } from '@/components/NavMenu/NavMenuItem';
import styled from 'styled-components';

const MessagesTab = (props: NavMenuItemProps) => {
    const unreadCount = useAppSelector(getUnreadInboxCount);

    return (
        <MessagesTabContainer>
            <StyledCountBadge count={unreadCount} />
            <NavMenuItem {...props} />
        </MessagesTabContainer>
    );
};

const StyledCountBadge = styled(CountBadge)`
    position: absolute;
    right: 0;
    top: -7.5px;
    z-index: 1;
`;

const MessagesTabContainer = styled.div`
    position: relative;
`;

export default MessagesTab;
