import { BidSource } from '@/types/BidSource';
import { SfsCurrentLotState } from '@/types/sfs';
import { TypedActionWithPayload } from '@/types/redux';
import type { AuctionStatus, CurrentLotStatus } from '@/types/LiveAuctionState';
import type { PubSubProvider } from '@/types/PubSubProvider';

export const LOAD_CLERK_AUCTION_STATE_FAIL = 'LOAD_CLERK_AUCTION_STATE_FAIL';
export const LOAD_CLERK_AUCTION_STATE_SUCCESS = 'LOAD_CLERK_AUCTION_STATE_SUCCESS';
export type LOAD_CLERK_AUCTION_STATE_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CLERK_AUCTION_STATE_SUCCESS,
    {
        catalogId: number;
        currentItem: {
            askPrice: number;
            increment: number;
            itemId: number;
            itemIndex: number;
            lotNumber: string;
            lotStatus: CurrentLotStatus;
        };
        itemCount: number;
        lastUpdated: string;
        previousLotNumber: string;
        status: AuctionStatus;
    },
    {
        catalogId: number;
    }
>;
export const LOAD_CLERK_AUCTION_STATE_REQUEST = 'LOAD_CLERK_AUCTION_STATE_REQUEST';

export const LIVE_ASK_CHANGED = 'LIVE_ASK_CHANGED';
export type LIVE_ASK_CHANGED_ACTION = TypedActionWithPayload<
    typeof LIVE_ASK_CHANGED,
    {
        ask: number;
        catalogId: number;
        created: number;
        currency: string;
        increment: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
    }
>;
export const LIVE_AUCTION_ENDED = 'LIVE_AUCTION_ENDED';
export type LIVE_AUCTION_ENDED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_ENDED,
    { catalogId: number; created: number }
>;
export const LIVE_AUCTION_LOADED = 'LIVE_AUCTION_LOADED';
export type LIVE_AUCTION_LOADED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_LOADED,
    { catalogId: number; created: number; serverId: number }
>;
export const LIVE_AUCTION_OPENED = 'LIVE_AUCTION_OPENED';
export type LIVE_AUCTION_OPENED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_OPENED,
    { catalogId: number; created: number }
>;
export const LIVE_AUCTION_MOVED = 'LIVE_AUCTION_MOVED';
export type LIVE_AUCTION_MOVED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_MOVED,
    { catalogId: number; created: number }
>;
export const LIVE_AUCTION_PAUSED = 'LIVE_AUCTION_PAUSED';
export type LIVE_AUCTION_PAUSED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_PAUSED,
    { catalogId: number; created: number }
>;
export const LIVE_AUCTION_RESUMED = 'LIVE_AUCTION_RESUMED';
export type LIVE_AUCTION_RESUMED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_RESUMED,
    { catalogId: number; created: number }
>;
export const LIVE_AUCTION_STARTED = 'LIVE_AUCTION_STARTED';
export type LIVE_AUCTION_STARTED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_STARTED,
    { catalogId: number; created: number }
>;
export const LIVE_BID_ACCEPTED = 'LIVE_BID_ACCEPTED';
export type LIVE_BID_ACCEPTED_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_ACCEPTED,
    {
        amount: number;
        ask: number;
        assignedId: number;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        increment: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        myBid: boolean;
        source: BidSource;
    }
>;
export const LIVE_BID_HOVER_DISMISS = 'LIVE_BID_HOVER_DISMISS';
export type LIVE_BID_HOVER_DISMISS_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_HOVER_DISMISS,
    {
        amount: number;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        itemId: number;
    }
>;
export const LIVE_BID_HOVER_INTENT = 'LIVE_BID_HOVER_INTENT';
export type LIVE_BID_HOVER_INTENT_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_HOVER_INTENT,
    {
        amount: number;
        bidderHasHighBid: boolean;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        isItemSaved: boolean;
        isSellerFollowed: boolean;
        itemId: number;
        source: BidSource;
    }
>;
export const LIVE_BID_PLACED = 'LIVE_BID_PLACED';
export type LIVE_BID_PLACED_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_PLACED,
    {
        amount: number;
        // ask: number,
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        itemId: number;
        shouldStoreBidPlacedMessages: boolean;
        source: BidSource;
    }
>;
export const LIVE_BID_RETRACTED = 'LIVE_BID_RETRACTED';
export type LIVE_BID_RETRACTED_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_RETRACTED,
    {
        amount: number;
        ask: number;
        assignedId: number;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        increment: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        source: BidSource;
    }
>;
export const LIVE_BID_SENT = 'LIVE_BID_SENT';
export type LIVE_BID_SENT_ACTION = TypedActionWithPayload<
    typeof LIVE_BID_SENT,
    {
        amount: number;
        assignedId: number;
        catalogId: number;
        created: number;
        currency: string;
        itemId: number;
        source: BidSource;
    }
>;
export const LIVE_LOT_CLOSED = 'LIVE_LOT_CLOSED';
export type LIVE_LOT_CLOSED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_CLOSED,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
        soldAssignedId: number;
    }
>;
export const LIVE_LOT_PASSED = 'LIVE_LOT_PASSED';
export type LIVE_LOT_PASSED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_PASSED,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
    }
>;
export const LIVE_LOT_REOPENED = 'LIVE_LOT_REOPENED';
export type LIVE_LOT_REOPENED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_REOPENED,
    {
        askPrice: number;
        catalogId: number;
        created: number;
        highBid: number;
        increment: number;
        itemId: number;
        itemIndex: number;
        leadingAssignedId: number;
        leadingBidderId: number;
        lotNumber: string;
    }
>;
export const LIVE_LOT_SKIPPED = 'LIVE_LOT_SKIPPED';
export type LIVE_LOT_SKIPPED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_SKIPPED,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
        newLotNumber: string;
    }
>;
export const LIVE_LOT_SOLD = 'LIVE_LOT_SOLD';
export type LIVE_LOT_SOLD_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_SOLD,
    {
        amount: number;
        assignedId: number;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        itemId: number;
        lotNumber: string;
        myBid: boolean;
        source: BidSource;
    }
>;
export const LIVE_LOT_UNSOLD = 'LIVE_LOT_UNSOLD';
export type LIVE_LOT_UNSOLD_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_UNSOLD,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
    }
>;
export const LIVE_MISSIVE = 'LIVE_MISSIVE';
export type LIVE_MISSIVE_ACTION = TypedActionWithPayload<
    typeof LIVE_MISSIVE,
    {
        catalogId: number;
        created: number;
        itemId: number;
        missiveText: string;
    }
>;

export const LIVE_NETWORK_DISCONNECTED = 'LIVE_NETWORK_DISCONNECTED';
export type LIVE_NETWORK_DISCONNECTED_ACTION = TypedActionWithPayload<
    typeof LIVE_NETWORK_DISCONNECTED,
    {
        catalogId: number;
        provider: PubSubProvider;
    }
>;

export const LIVE_NETWORK_RECONNECTED = 'LIVE_NETWORK_RECONNECTED';
export type LIVE_NETWORK_RECONNECTED_ACTION = TypedActionWithPayload<
    typeof LIVE_NETWORK_RECONNECTED,
    {
        catalogId: number;
        provider: PubSubProvider;
    }
>;

export const LIVE_NEXT_LOT_LOADED = 'LIVE_NEXT_LOT_LOADED';
export type LIVE_NEXT_LOT_LOADED_ACTION = TypedActionWithPayload<
    typeof LIVE_NEXT_LOT_LOADED,
    {
        askPrice: number;
        catalogId: number;
        created: number;
        currency: string;
        description: string;
        highEstimate: number;
        increment: number;
        index: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        lotTitle: string;
        lowEstimate: number;
        pendingAssignedId: number;
        pendingBid: number;
        status: CurrentLotStatus;
    }
>;

export const LIVE_UPDATE_CATALOG_OCCUPANCY = 'LIVE_UPDATE_CATALOG_OCCUPANCY';
export type LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION = TypedActionWithPayload<
    typeof LIVE_UPDATE_CATALOG_OCCUPANCY,
    {
        bidderCount: number;
        catalogId: number;
    }
>;

export const SEND_CHANGE_BID_INCREMENT_FAILURE = 'SEND_CHANGE_BID_INCREMENT_FAILURE';
export type SEND_CHANGE_BID_INCREMENT_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_BID_INCREMENT_FAILURE,
    {},
    {
        actionTime: number;
        catalogId: number;
        currency: string;
        increment: number;
        itemId: number;
        lotNumber: string;
        previousIncrement: number;
    }
>;
export const SEND_CHANGE_BID_INCREMENT_REQUEST = 'SEND_CHANGE_BID_INCREMENT_REQUEST';
export type SEND_CHANGE_BID_INCREMENT_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_BID_INCREMENT_REQUEST,
    {
        catalogId: number;
        increment: number;
        itemId: Number;
    }
>;
export const SEND_CHANGE_BID_INCREMENT_SUCCESS = 'SEND_CHANGE_BID_INCREMENT_SUCCESS';

export const SEND_CHANGE_CURRENT_ASK_FAILURE = 'SEND_CHANGE_CURRENT_ASK_FAILURE';
export type SEND_CHANGE_CURRENT_ASK_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CURRENT_ASK_FAILURE,
    {},
    {
        actionTime: number;
        ask: number;
        catalogId: number;
        currency: string;
        itemId: number;
        lotNumber: string;
        previousAsk: number;
    }
>;
export const SEND_CHANGE_CURRENT_ASK_REQUEST = 'SEND_CHANGE_CURRENT_ASK_REQUEST';
export type SEND_CHANGE_CURRENT_ASK_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CURRENT_ASK_REQUEST,
    {
        ask: number;
        catalogId: number;
        itemId: number;
    }
>;
export const SEND_CHANGE_CURRENT_ASK_SUCCESS = 'SEND_CHANGE_CURRENT_ASK_SUCCESS';

export const SEND_CHANGE_CURRENT_LOT_FAILURE = 'SEND_CHANGE_CURRENT_LOT_FAILURE';
export type SEND_CHANGE_CURRENT_LOT_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_CHANGE_CURRENT_LOT_FAILURE,
    {
        msg: string;
    },
    {
        actionTime: number;
        catalogId: number;
        lotNumber: string;
    }
>;
export const SEND_CHANGE_CURRENT_LOT_REQUEST = 'SEND_CHANGE_CURRENT_LOT_REQUEST';
export const SEND_CHANGE_CURRENT_LOT_SUCCESS = 'SEND_CHANGE_CURRENT_LOT_SUCCESS';

export const SEND_CLOSE_AUCTION_FAILURE = 'SEND_CLOSE_AUCTION_FAILURE';
export type SEND_CLOSE_AUCTION_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_CLOSE_AUCTION_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_CLOSE_AUCTION_REQUEST = 'SEND_CLOSE_AUCTION_REQUEST';
export const SEND_CLOSE_AUCTION_SUCCESS = 'SEND_CLOSE_AUCTION_SUCCESS';

export const SEND_COMPETING_BID_FAILURE = 'SEND_COMPETING_BID_FAILURE';
export type SEND_COMPETING_BID_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_COMPETING_BID_FAILURE,
    {},
    { actionTime: number; amount: number; catalogId: number; currency: string; lotNumber: string }
>;
export const SEND_COMPETING_BID_REQUEST = 'SEND_COMPETING_BID_REQUEST';
export type SEND_COMPETING_BID_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_COMPETING_BID_REQUEST,
    {},
    { amount: number; itemId: number }
>;
export const SEND_COMPETING_BID_SUCCESS = 'SEND_COMPETING_BID_SUCCESS';

export const SEND_INTERNET_BID_FAILURE = 'SEND_INTERNET_BID_FAILURE';
export type SEND_INTERNET_BID_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_INTERNET_BID_FAILURE,
    {},
    { actionTime: number; amount: number; catalogId: number; currency: string; lotNumber: string }
>;
export const SEND_INTERNET_BID_REQUEST = 'SEND_INTERNET_BID_REQUEST';
export type SEND_INTERNET_BID_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_INTERNET_BID_REQUEST,
    {},
    { amount: number; itemId: number }
>;
export const SEND_INTERNET_BID_SUCCESS = 'SEND_INTERNET_BID_SUCCESS';

export const SEND_LOT_PASSED_FAILURE = 'SEND_LOT_PASSED_FAILURE';
export type SEND_LOT_PASSED_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_LOT_PASSED_FAILURE,
    {},
    { actionTime: number; catalogId: number; currentState: SfsCurrentLotState; lotNumber: string }
>;
export const SEND_LOT_PASSED_REQUEST = 'SEND_LOT_PASSED_REQUEST';
export const SEND_LOT_PASSED_SUCCESS = 'SEND_LOT_PASSED_SUCCESS';

export const SEND_LOT_REOPEN_FAILURE = 'SEND_LOT_REOPEN_FAILURE';
export type SEND_LOT_REOPEN_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_LOT_REOPEN_FAILURE,
    {},
    { actionTime: number; catalogId: number; currentState: SfsCurrentLotState; lotNumber: string }
>;
export const SEND_LOT_REOPEN_REQUEST = 'SEND_LOT_REOPEN_REQUEST';
export const SEND_LOT_REOPEN_SUCCESS = 'SEND_LOT_REOPEN_SUCCESS';

export const SEND_LOT_SKIPPED_FAILURE = 'SEND_LOT_SKIPPED_FAILURE';
export type SEND_LOT_SKIPPED_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_LOT_SKIPPED_FAILURE,
    {},
    { actionTime: number; catalogId: number; currentState: SfsCurrentLotState; lotNumber: string }
>;
export const SEND_LOT_SKIPPED_REQUEST = 'SEND_LOT_SKIPPED_REQUEST';
export const SEND_LOT_SKIPPED_SUCCESS = 'SEND_LOT_SKIPPED_SUCCESS';

export const SEND_LOT_SOLD_FAILURE = 'SEND_LOT_SOLD_FAILURE';
export type SEND_LOT_SOLD_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_LOT_SOLD_FAILURE,
    {
        msg: string;
    },
    {
        actionTime: number;
        catalogId: number;
        currency: string;
        currentState: SfsCurrentLotState;
        itemId: number;
        lotNumber: string;
    }
>;
export const SEND_LOT_SOLD_REQUEST = 'SEND_LOT_SOLD_REQUEST';
export const SEND_LOT_SOLD_SUCCESS = 'SEND_LOT_SOLD_SUCCESS';

export const SEND_MISSIVE_FAILURE = 'SEND_MISSIVE_FAILURE';
export type SEND_MISSIVE_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_MISSIVE_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_MISSIVE_REQUEST = 'SEND_MISSIVE_REQUEST';
export const SEND_MISSIVE_SUCCESS = 'SEND_MISSIVE_SUCCESS';

export const SEND_LOAD_AUCTION_FAILURE = 'SEND_LOAD_AUCTION_FAILURE';
export type SEND_LOAD_AUCTION_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_LOAD_AUCTION_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_LOAD_AUCTION_REQUEST = 'SEND_LOAD_AUCTION_REQUEST';
export type SEND_LOAD_AUCTION_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_LOAD_AUCTION_REQUEST,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_LOAD_AUCTION_SUCCESS = 'SEND_LOAD_AUCTION_SUCCESS';
export type SEND_LOAD_AUCTION_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SEND_LOAD_AUCTION_SUCCESS,
    {},
    { actionTime: number; catalogId: number }
>;

export const SEND_OPEN_AUCTION_FAILURE = 'SEND_OPEN_AUCTION_FAILURE';
export type SEND_OPEN_AUCTION_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_OPEN_AUCTION_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_OPEN_AUCTION_REQUEST = 'SEND_OPEN_AUCTION_REQUEST';
export const SEND_OPEN_AUCTION_SUCCESS = 'SEND_OPEN_AUCTION_SUCCESS';

export const SEND_OPEN_NEXT_LOT_FAILURE = 'SEND_OPEN_NEXT_LOT_FAILURE';
export type SEND_OPEN_NEXT_LOT_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_OPEN_NEXT_LOT_FAILURE,
    { msg: string },
    { actionTime: number; catalogId: number; currentState: SfsCurrentLotState }
>;
export const SEND_OPEN_NEXT_LOT_REQUEST = 'SEND_OPEN_NEXT_LOT_REQUEST';
export const SEND_OPEN_NEXT_LOT_SUCCESS = 'SEND_OPEN_NEXT_LOT_SUCCESS';

export const SEND_PAUSE_AUCTION_FAILURE = 'SEND_PAUSE_AUCTION_FAILURE';
export type SEND_PAUSE_AUCTION_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_PAUSE_AUCTION_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_PAUSE_AUCTION_REQUEST = 'SEND_PAUSE_AUCTION_REQUEST';
export const SEND_PAUSE_AUCTION_SUCCESS = 'SEND_PAUSE_AUCTION_SUCCESS';

export const SEND_RESUME_AUCTION_FAILURE = 'SEND_RESUME_AUCTION_FAILURE';
export type SEND_RESUME_AUCTION_FAILURE_ACTION = TypedActionWithPayload<
    typeof SEND_RESUME_AUCTION_FAILURE,
    {},
    { actionTime: number; catalogId: number }
>;
export const SEND_RESUME_AUCTION_REQUEST = 'SEND_RESUME_AUCTION_REQUEST';
export const SEND_RESUME_AUCTION_SUCCESS = 'SEND_RESUME_AUCTION_SUCCESS';

export const SEND_RETRACT_BID_FAILURE = 'SEND_RETRACT_BID_FAILURE';
export const SEND_RETRACT_BID_REQUEST = 'SEND_RETRACT_BID_REQUEST';
export const SEND_RETRACT_BID_SUCCESS = 'SEND_RETRACT_BID_SUCCESS';

export const AUTO_ACCEPT_BIDS_ENABLED = 'AUTO_ACCEPT_BIDS_ENABLED';
export type AUTO_ACCEPT_BIDS_ENABLED_ACTION = TypedActionWithPayload<
    typeof AUTO_ACCEPT_BIDS_ENABLED,
    {},
    { actionTime: number; catalogId: number }
>;
export const AUTO_ACCEPT_BIDS_DISABLED = 'AUTO_ACCEPT_BIDS_DISABLED';
export type AUTO_ACCEPT_BIDS_DISABLED_ACTION = TypedActionWithPayload<
    typeof AUTO_ACCEPT_BIDS_DISABLED,
    {},
    { actionTime: number; catalogId: number }
>;
