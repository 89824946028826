import { createSelector } from '@reduxjs/toolkit';
import { fetchSpeedData } from '../api/speedCheck';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { openSpeedCheckModal } from './modal';
import {
    SPEED_CHECK_FAIL,
    SPEED_CHECK_ITERATION_FAIL,
    SPEED_CHECK_ITERATION_FAIL_ACTION,
    SPEED_CHECK_ITERATION_REQUEST,
    SPEED_CHECK_ITERATION_REQUEST_ACTION,
    SPEED_CHECK_ITERATION_SUCCESS,
    SPEED_CHECK_ITERATION_SUCCESS_ACTION,
    SPEED_CHECK_REQUEST,
    SPEED_CHECK_SUCCESS,
    SPEED_CHECK_SUCCESS_ACTION,
} from './actions';
import type { GlobalState } from '@/redux/rootReducer';

type Iteration = {
    duration: number;
    error: boolean;
};

type IterationData = {
    [key: number]: Iteration;
};

type TestData = {
    average: number;
    max: number;
    min: number;
    speedCheckSuccessful: boolean;
};

export type State = {
    error: boolean;
    iterationData: IterationData;
    submitted: boolean;
    success: boolean;
    testData: TestData;
};

export const DEFAULT_STATE: State = {
    error: false,
    iterationData: {},
    submitted: false,
    success: false,
    testData: {
        average: 0,
        max: 0,
        min: 0,
        speedCheckSuccessful: true,
    },
};

export const reducer = handleActions(
    {
        [SPEED_CHECK_FAIL]: (state: State): State => {
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
                testData: {
                    average: 0,
                    max: 0,
                    min: 0,
                    speedCheckSuccessful: false,
                },
            };
        },
        [SPEED_CHECK_ITERATION_FAIL]: (state: State, action: SPEED_CHECK_ITERATION_FAIL_ACTION): State => {
            return {
                ...state,
                iterationData: {
                    ...state.iterationData,
                    [action.meta.iteration]: {
                        ...state.iterationData[action.meta.iteration],
                        duration: 0,
                        error: true,
                    },
                },
            };
        },
        [SPEED_CHECK_ITERATION_REQUEST]: (state: State, action: SPEED_CHECK_ITERATION_REQUEST_ACTION): State => {
            return {
                ...state,
                iterationData: {
                    ...state.iterationData,
                    [action.payload.iteration]: {
                        ...state.iterationData[action.payload.iteration],
                        duration: 0,
                        error: false,
                    },
                },
            };
        },
        [SPEED_CHECK_ITERATION_SUCCESS]: (state: State, action: SPEED_CHECK_ITERATION_SUCCESS_ACTION): State => {
            return {
                ...state,
                iterationData: {
                    ...state.iterationData,
                    [action.payload.iteration]: {
                        ...state.iterationData[action.payload.iteration],
                        duration: action.payload.duration || 0,
                        error: false,
                    },
                },
            };
        },
        [SPEED_CHECK_REQUEST]: () => {
            return DEFAULT_STATE;
        },
        [SPEED_CHECK_SUCCESS]: (state: State, action: SPEED_CHECK_SUCCESS_ACTION): State => {
            return {
                ...state,
                error: false,
                submitted: false,
                success: true,
                testData: { ...action.payload },
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.speedCheck;

export const speedCheckSelector = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
export const doSpeedCheck =
    (catalogId: number, manual: boolean = false) =>
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: SPEED_CHECK_REQUEST,
            });

            // Do an initial "0" iteration to get a better average.  The first one always takes a little longer for dns lookups and handshakes.
            await dispatch(doSpeedCheckForIteration(0));
            await dispatch(doSpeedCheckForIteration(1));
            await dispatch(doSpeedCheckForIteration(2));
            await dispatch(doSpeedCheckForIteration(3));
            await dispatch(doSpeedCheckForIteration(4));

            const state2 = getState();

            const speedData = speedCheckSelector(state2);
            const data1 = speedData.iterationData[1] || { duration: 0, error: true };
            const data2 = speedData.iterationData[2] || { duration: 0, error: true };
            const data3 = speedData.iterationData[3] || { duration: 0, error: true };
            const data4 = speedData.iterationData[4] || { duration: 0, error: true };

            const durations = [data1.duration, data2.duration, data3.duration, data4.duration];
            if (!durations.length) {
                throw Error('no speed data found');
            }

            const max = Math.max(...durations);
            const min = Math.min(...durations);
            const sum = durations.reduce((prev, cur) => {
                return prev + cur;
            }, 0);
            const average = sum / durations.length;
            const hadAnError = data1.error || data2.error || data3.error || data4.error;

            const failedConsoleSpeedCheck = max > 250 || average > 250 || hadAnError;

            dispatch({
                payload: {
                    average,
                    max,
                    min,
                    speedCheckSuccessful: !failedConsoleSpeedCheck,
                },
                type: SPEED_CHECK_SUCCESS,
            });

            if (manual || failedConsoleSpeedCheck) {
                dispatch(openSpeedCheckModal());
            }
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: SPEED_CHECK_FAIL,
            });
        }
    };
const doSpeedCheckForIteration = (iteration: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        dispatch({
            payload: { iteration },
            type: SPEED_CHECK_ITERATION_REQUEST,
        });
        const beforeTime = Date.now();
        const deployment = getDeployment(state);
        await fetchSpeedData({ deployment });
        const afterTime = Date.now();

        const duration = afterTime - beforeTime;

        return dispatch({
            payload: { duration, iteration },
            type: SPEED_CHECK_ITERATION_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { iteration },
            payload: error,
            type: SPEED_CHECK_ITERATION_FAIL,
        });
    }
};
