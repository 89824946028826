import { handleResponse, makeGet } from './helpers';

type GetHouseStatusParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export default {
    getHouseStatus: ({ authToken, deployment, houseId }: GetHouseStatusParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/update-status`,
                authToken,
                deployment,
                path: '<HOUSE-USER-API>',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
