import {
    CLEAR_DRAFT,
    CLEAR_DRAFT_ACTION,
    CONVERSATIONS_CREATE_DRAFT_FAIL,
    CONVERSATIONS_CREATE_DRAFT_REQUEST,
    CONVERSATIONS_CREATE_DRAFT_SUCCESS,
    CONVERSATIONS_CREATE_DRAFT_SUCCESS_ACTION,
    CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_FAIL,
    CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST,
    CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS,
    CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS_ACTION,
    LOAD_CONVERSATION_MESSAGES_FAIL,
    LOAD_CONVERSATION_MESSAGES_FAIL_ACTION,
    LOAD_CONVERSATION_MESSAGES_REQUEST,
    LOAD_CONVERSATION_MESSAGES_REQUEST_ACTION,
    LOAD_CONVERSATION_MESSAGES_SUCCESS,
    LOAD_CONVERSATION_MESSAGES_SUCCESS_ACTION,
    SEND_BIDDER_MESSAGE_FAIL,
    SEND_BIDDER_MESSAGE_REQUEST,
    SEND_BIDDER_MESSAGE_SUCCESS,
    SEND_CONVERSATION_MESSAGE_FAIL,
    SEND_CONVERSATION_MESSAGE_FAIL_ACTION,
    SEND_CONVERSATION_MESSAGE_REQUEST,
    SEND_CONVERSATION_MESSAGE_REQUEST_ACTION,
    SEND_CONVERSATION_MESSAGE_SUCCESS,
    SEND_CONVERSATION_MESSAGE_SUCCESS_ACTION,
} from './actions';
import { cloneDeep, difference, union, uniqBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '../modules/user';
import { getDeployment } from '../modules/config';
import { handleActions } from 'redux-actions';
import api from '../api/conversationMessages';

/* reducer */
export type State = {
    byId: {
        // different reducer handlers seem to be using different types??
        [id: number]: any;
    };
    loaded: { [id: number]: number };
    loading: number[];
    messageDrafts: {
        [id: number]: {
            draftAttachments?: { id: number; name: string }[];
            draftId?: number;
        };
    };
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
    messageDrafts: {},
};

export const reducer = handleActions(
    {
        [CLEAR_DRAFT]: (state: State, action: CLEAR_DRAFT_ACTION) => {
            const existing = cloneDeep(state.messageDrafts);
            existing[action.payload.conversationId] = {};
            return {
                ...state,
                messageDrafts: existing,
            };
        },
        [CONVERSATIONS_CREATE_DRAFT_SUCCESS]: (state: State, action: CONVERSATIONS_CREATE_DRAFT_SUCCESS_ACTION) => {
            const existing = cloneDeep(state.messageDrafts);
            const draftAttachments = action.payload.attachments.map((attachment) => ({
                id: attachment.attachmentId,
                name: attachment.originalFilename,
            }));
            existing[action.payload.conversationId] = {
                draftAttachments,
                draftId: action.payload.draftId,
            };
            return {
                ...state,
                messageDrafts: existing,
            };
        },
        [CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS]: (
            state: State,
            action: CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS_ACTION
        ) => {
            const existing = cloneDeep(state.messageDrafts);
            const draftAttachments = existing[action.meta.conversationId].draftAttachments.filter(
                (draftAttachment) => action.meta.attachmentName !== draftAttachment.name
            );
            existing[action.meta.conversationId].draftAttachments = draftAttachments;
            return {
                ...state,
                messageDrafts: existing,
            };
        },
        [LOAD_CONVERSATION_MESSAGES_FAIL]: (state: State, action: LOAD_CONVERSATION_MESSAGES_FAIL_ACTION) => ({
            ...state,
            loading: difference(state.loading, [action.meta.conversationId]),
        }),
        [LOAD_CONVERSATION_MESSAGES_REQUEST]: (state: State, action: LOAD_CONVERSATION_MESSAGES_REQUEST_ACTION) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_CONVERSATION_MESSAGES_SUCCESS]: (state: State, action: LOAD_CONVERSATION_MESSAGES_SUCCESS_ACTION) => {
            const existing = cloneDeep(state.byId);
            const loaded = cloneDeep(state.loaded);
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (Array.isArray(action.payload)) {
                existing[action.meta.conversationId] = action.payload;
                loaded[action.meta.conversationId] = time;
                loading = difference(loading, [action.meta.conversationId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
        [SEND_CONVERSATION_MESSAGE_FAIL]: (state: State, action: SEND_CONVERSATION_MESSAGE_FAIL_ACTION) => ({
            ...state,
            loading: difference(state.loading, [action.meta.conversationId]),
        }),
        [SEND_CONVERSATION_MESSAGE_REQUEST]: (state: State, action: SEND_CONVERSATION_MESSAGE_REQUEST_ACTION) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [SEND_CONVERSATION_MESSAGE_SUCCESS]: (state: State, action: SEND_CONVERSATION_MESSAGE_SUCCESS_ACTION) => {
            const existing = cloneDeep(state.byId);
            const loaded = cloneDeep(state.loaded);
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (Array.isArray(action.payload)) {
                const messages = action.payload;
                existing[action.meta.conversationId] = uniqBy(
                    [...existing[action.meta.conversationId], ...messages],
                    'messageId'
                );
                loaded[action.meta.conversationId] = time;
                loading = difference(loading, [action.meta.conversationId]);
            } else {
                const message = action.payload;
                existing[action.meta.conversationId] = uniqBy(
                    [...existing[action.meta.conversationId], message],
                    'messageId'
                );
                loaded[action.meta.conversationId] = time;
                loading = difference(loading, [action.meta.conversationId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

const stateSelector = (state) => state.conversationMessages;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const draftsSelector = createSelector(stateSelector, (state) => state.messageDrafts);

const getMessages = createSelector([byIdSelector, idSelector], (byId, id) => {
    return byId[id] || [];
});

export const getConversationMessages = (state: any, conversationId: number) => {
    return getMessages(state, conversationId).map((message) => {
        if (message?.sender?.type === 'bidder') {
            const nameParts = message?.sender?.name?.split(' ') || [];
            const firstName = nameParts[0];
            const lastName = nameParts[nameParts.length - 1];
            return {
                ...message,
                sender: {
                    ...message.sender,
                    initials: `${firstName[0]}${lastName[0]}`,
                },
            };
        }
        return message;
    });
};

export const getMessage = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

export const getLoadTimeForConversationMessages = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isConversationMessagesLoading = createSelector(loadingSelector, (loading) => loading);

export const getDraftId = createSelector(
    [draftsSelector, idSelector],
    (messageDrafts, id) => messageDrafts[id]?.draftId
);

export const getDraftAttachments = createSelector(
    [draftsSelector, idSelector],
    (messageDrafts, id) => messageDrafts[id]?.draftAttachments || []
);

export const getAttachmentId = createSelector(
    [draftsSelector, idSelector, idSelector],
    (messageDrafts, { attachmentName, conversationId }) => {
        const attachments = messageDrafts[conversationId]?.draftAttachments;
        const attachment = attachments.find((att) => att.name === attachmentName);

        return attachment.name ? attachment.id : 0;
    }
);

export const clearDraft = (conversationId: number) => (dispatch: Function) => {
    dispatch({
        payload: { conversationId },
        type: CLEAR_DRAFT,
    } as CLEAR_DRAFT_ACTION);
};

const loadConversationMessages = (conversationId: number, houseId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: conversationId,
            type: LOAD_CONVERSATION_MESSAGES_REQUEST,
        } as LOAD_CONVERSATION_MESSAGES_REQUEST_ACTION);
        const response = await api.fetchConversationMessages({ authToken, conversationId, deployment, houseId });
        return dispatch({
            meta: { actionTime: Date.now(), conversationId },
            payload: response.payload,
            type: LOAD_CONVERSATION_MESSAGES_SUCCESS,
        } as LOAD_CONVERSATION_MESSAGES_SUCCESS_ACTION);
    } catch (error) {
        return dispatch({
            error: true,
            meta: { conversationId },
            payload: error,
            type: LOAD_CONVERSATION_MESSAGES_FAIL,
        } as LOAD_CONVERSATION_MESSAGES_FAIL_ACTION);
    }
};

export const sendConversationMessage =
    (conversationId: number, houseId: number, messageBody: any, draftId: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                payload: conversationId,
                type: SEND_CONVERSATION_MESSAGE_REQUEST,
            } as SEND_CONVERSATION_MESSAGE_REQUEST_ACTION);
            const response = await api.sendConversationMessage({
                authToken,
                conversationId,
                deployment,
                draftId: Boolean(draftId) ? draftId : undefined,
                houseId,
                messageBody,
            });

            // Delete any drafts for the convo so new drafts can be made
            dispatch(clearDraft(conversationId));

            return dispatch({
                meta: { actionTime: Date.now(), conversationId },
                payload: response.payload,
                type: SEND_CONVERSATION_MESSAGE_SUCCESS,
            } as SEND_CONVERSATION_MESSAGE_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                meta: { conversationId },
                payload: error,
                type: SEND_CONVERSATION_MESSAGE_FAIL,
            } as SEND_CONVERSATION_MESSAGE_FAIL_ACTION);
        }
    };

export const fetchConversationMessages = (conversationId: number, houseId: number) => async (dispatch: Function) => {
    return dispatch(loadConversationMessages(conversationId, houseId as number));
};

const filterAttachedImages = (conversationId, newAttachments) => async (dispatch, getState) => {
    const state = getState();
    const draftAttachments = getDraftAttachments(state, conversationId);
    const draftAttachmentsNames = draftAttachments.map((att) => att.name);

    return newAttachments.filter((newAttachment) => {
        return !draftAttachmentsNames.includes(newAttachment.name);
    });
};

export const addAttachment =
    (conversationId: number, attachments: Array<any>, messageBody: string, houseId: number, draftId: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            // necessary to make sure we do not attach files that are already attached
            const filteredAttachments = await dispatch(filterAttachedImages(conversationId, attachments));
            if (!filteredAttachments.length) {
                return Promise.resolve({ error: false });
            }

            dispatch({
                type: CONVERSATIONS_CREATE_DRAFT_REQUEST,
            });
            const response = await api.addAttachment({
                attachments: filteredAttachments,
                authToken,
                conversationId,
                deployment,
                draftId,
                houseId,
                messageBody,
            });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: CONVERSATIONS_CREATE_DRAFT_SUCCESS,
            } as CONVERSATIONS_CREATE_DRAFT_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: CONVERSATIONS_CREATE_DRAFT_FAIL,
            });
        }
    };

export const deleteAttachment =
    (attachmentName: string, conversationId: number, houseId: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            const attachmentId = getAttachmentId(state, { attachmentName, conversationId });

            dispatch({
                type: CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST,
            });
            const response = await api.deleteDraftAttachment({
                attachmentId,
                authToken,
                deployment,
                houseId,
            });
            return dispatch({
                meta: { actionTime: Date.now(), attachmentName, conversationId },
                payload: response.payload,
                type: CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS,
            } as CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_FAIL,
            });
        }
    };

export const startConversationWithAttachment =
    ({
        attachments,
        bidderIds,
        houseId,
        messageBody,
        subject,
    }: {
        attachments: Array<File>;
        bidderIds: Array<number>;
        houseId: number;
        messageBody: string;
        subject: string;
    }) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SEND_BIDDER_MESSAGE_REQUEST,
            });
            const response = await api.startConversationWithAttachment({
                attachments,
                authToken,
                bidderIds,
                deployment,
                houseId,
                messageBody,
                subject,
            });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: SEND_BIDDER_MESSAGE_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: SEND_BIDDER_MESSAGE_FAIL,
            });
        }
    };
