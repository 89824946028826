import { $Values } from 'utility-types';
import {
    AccountSummary,
    BankAccount,
    BankAccountForm,
    Disbursement,
    Payment,
    PayrixHouseAccount,
} from '@/types/Payment';
import { ActionWithPayload } from '@/types/redux';
import {
    ADD_BANK_ACCOUNT_FAIL,
    ADD_BANK_ACCOUNT_REQUEST,
    ADD_BANK_ACCOUNT_SUCCESS,
    DISBURSEMENT_FILTERS_CHANGE,
    DISBURSEMENT_FILTERS_CHANGE_FAIL,
    LOAD_ACCOUNT_SUMMARY_FAIL,
    LOAD_ACCOUNT_SUMMARY_REQUEST,
    LOAD_ACCOUNT_SUMMARY_SUCCESS,
    LOAD_BANK_ACCOUNTS_FAIL,
    LOAD_BANK_ACCOUNTS_REQUEST,
    LOAD_BANK_ACCOUNTS_SUCCESS,
    LOAD_BIDDER_PAYMENTS_FAIL,
    LOAD_BIDDER_PAYMENTS_REQUEST,
    LOAD_BIDDER_PAYMENTS_SUCCESS,
    LOAD_BIDDER_PAYMENTS_SUCCESS_ACTION,
    LOAD_DISBURSEMENTS_FAIL,
    LOAD_DISBURSEMENTS_REQUEST,
    LOAD_DISBURSEMENTS_SUCCESS,
    LOAD_DISBURSEMENTS_SUCCESS_ACTION,
    LOAD_PAYRIX_HOUSE_ACCOUNT_FAIL,
    LOAD_PAYRIX_HOUSE_ACCOUNT_REQUEST,
    LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS,
    LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS_ACTION,
    PAYMENT_FILTERS_CHANGE,
    PAYMENT_FILTERS_CHANGE_FAIL,
    REMOVE_BANK_ACCOUNTS_FAIL,
    REMOVE_BANK_ACCOUNTS_REQUEST,
    REMOVE_BANK_ACCOUNTS_SUCCESS,
    REPORTING_FILTERS_CHANGE,
    REPORTING_FILTERS_CHANGE_FAIL,
    TOGGLE_PAYOUT_TYPE_FAIL,
    TOGGLE_PAYOUT_TYPE_REQUEST,
    TOGGLE_PAYOUT_TYPE_SUCCESS,
    UPDATE_PRIMARY_BANK_ACCOUNT_FAIL,
    UPDATE_PRIMARY_BANK_ACCOUNT_REQUEST,
    UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS,
    UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS_ACTION,
} from './actions';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import {
    deactivateBankAccount,
    getAccountSummary,
    getBankAccounts,
    getBidderPayments,
    getDisbursements,
    getHouseDisbursementRecords,
    getHousePaymentRecords,
    getPayrixHouseAccount,
    postBankAccount,
    postPayrixPayoutType,
    putUpdatePrimaryBankAccount,
} from '../api';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { payoutType } from '@/enums/payment';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

type PaginationFilterWithStatus = PaginationFilter & {
    status?: string;
};

/* reducer */
export const DEFAULT_STATE: State = {
    accountSummary: {
        accountBalance: 0,
        gtv: 0,
        totalDisbursementsThisMonth: 0,
        totalDisbursementsThisYear: 0,
        totalInvoicesDue: 0,
        totalPaymentsThisMonth: 0,
    },
    accountSummaryLoaded: undefined,
    accountSummaryLoading: false,
    addBankAccountLoading: false,
    bankAccounts: [],
    bankAccountsLoaded: undefined,
    bankAccountsLoading: false,
    disbursements: [],
    disbursementsFilters: {
        from: moment().subtract(365, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        to: new Date(),
        totalRecords: 0,
    },
    disbursementsLoaded: undefined,
    disbursementsLoading: false,
    disbursementsTotalRecords: 0,
    error: undefined,
    payments: [],
    paymentsFilters: {
        from: moment().subtract(365, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 24,
        status: '',
        to: new Date(),
        totalRecords: 0,
    },
    paymentsLoaded: undefined,
    paymentsLoading: false,
    paymentsTotalRecords: 0,
    payoutTypeToggleLoading: false,
    payrixHouseAccount: undefined,
    payrixHouseAccountLoaded: undefined,
    payrixHouseAccountLoading: false,
    reportingFilters: {
        from: moment().subtract(90, 'days').toDate(),
        keyword: '',
        page: 1,
        pageSize: 'all',
        to: new Date(),
    },
};

export type State = {
    accountSummary: AccountSummary;
    accountSummaryLoaded?: Date;
    accountSummaryLoading: boolean;
    addBankAccountLoading: boolean;
    bankAccounts: BankAccount[];
    bankAccountsLoaded?: Date;
    bankAccountsLoading: boolean;
    disbursements: Disbursement[];
    disbursementsFilters: PaginationFilter;
    disbursementsLoaded?: Date;
    disbursementsLoading: boolean;
    disbursementsTotalRecords: number;
    error?: any;
    payments: Payment[];
    paymentsFilters: PaginationFilterWithStatus;
    paymentsLoaded?: Date;
    paymentsLoading: boolean;
    paymentsTotalRecords: number;
    payoutTypeToggleLoading: boolean;
    payrixHouseAccount: PayrixHouseAccount[];
    payrixHouseAccountLoaded?: Date;
    payrixHouseAccountLoading: boolean;
    reportingFilters: PaginationFilter;
};

export const reducer = handleActions(
    {
        [combineActions(
            ADD_BANK_ACCOUNT_FAIL,
            LOAD_ACCOUNT_SUMMARY_FAIL,
            LOAD_BANK_ACCOUNTS_FAIL,
            LOAD_BIDDER_PAYMENTS_FAIL,
            LOAD_DISBURSEMENTS_FAIL,
            LOAD_PAYRIX_HOUSE_ACCOUNT_FAIL,
            PAYMENT_FILTERS_CHANGE_FAIL,
            DISBURSEMENT_FILTERS_CHANGE_FAIL,
            REMOVE_BANK_ACCOUNTS_FAIL,
            REPORTING_FILTERS_CHANGE_FAIL,
            TOGGLE_PAYOUT_TYPE_FAIL,
            UPDATE_PRIMARY_BANK_ACCOUNT_FAIL
        )]: (state: State, action: ActionWithPayload<any>): State => ({
            ...state,
            accountSummaryLoading: false,
            addBankAccountLoading: false,
            bankAccountsLoading: false,
            disbursementsLoading: false,
            error: action.payload,
            paymentsLoading: false,
            payoutTypeToggleLoading: false,
            payrixHouseAccountLoading: false,
        }),
        [ADD_BANK_ACCOUNT_REQUEST]: (state: State): State => ({
            ...state,
            addBankAccountLoading: true,
        }),
        [ADD_BANK_ACCOUNT_SUCCESS]: (state: State, action: ActionWithPayload<BankAccount>): State => ({
            ...state,
            addBankAccountLoading: false,
            bankAccounts: [...state.bankAccounts, action.payload],
            error: undefined,
        }),
        [DISBURSEMENT_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>): State => {
            let newState = {
                ...state,
                disbursementsFilters: {
                    ...state.disbursementsFilters,
                    ...action.payload,
                },
                disbursementsLoaded: undefined,
            };
            return newState;
        },
        [LOAD_ACCOUNT_SUMMARY_REQUEST]: (state: State): State => ({
            ...state,
            accountSummaryLoading: true,
        }),
        [LOAD_ACCOUNT_SUMMARY_SUCCESS]: (state: State, action: ActionWithPayload<AccountSummary>): State => ({
            ...state,
            accountSummary: action.payload,
            accountSummaryLoaded: new Date(),
            accountSummaryLoading: false,
            error: undefined,
        }),
        [LOAD_BANK_ACCOUNTS_REQUEST]: (state: State): State => ({
            ...state,
            bankAccountsLoading: true,
        }),
        [LOAD_BANK_ACCOUNTS_SUCCESS]: (state: State, action: ActionWithPayload<BankAccount[]>): State => ({
            ...state,
            bankAccounts: action.payload,
            bankAccountsLoaded: new Date(),
            bankAccountsLoading: false,
            error: undefined,
        }),
        [LOAD_BIDDER_PAYMENTS_REQUEST]: (state: State): State => ({
            ...state,
            paymentsLoading: true,
        }),
        [LOAD_BIDDER_PAYMENTS_SUCCESS]: (state: State, action: LOAD_BIDDER_PAYMENTS_SUCCESS_ACTION): State => ({
            ...state,
            error: undefined,
            payments: action.payload.payments,
            paymentsLoaded: new Date(),
            paymentsLoading: false,
            paymentsTotalRecords: action.payload.totalRecords,
        }),
        [LOAD_DISBURSEMENTS_REQUEST]: (state: State): State => ({
            ...state,
            disbursementsLoading: true,
        }),
        [LOAD_DISBURSEMENTS_SUCCESS]: (state: State, action: LOAD_DISBURSEMENTS_SUCCESS_ACTION): State => ({
            ...state,
            disbursements: action.payload.disbursements,
            disbursementsLoaded: new Date(),
            disbursementsLoading: false,
            disbursementsTotalRecords: action.payload.totalRecords,
            error: undefined,
        }),
        [LOAD_PAYRIX_HOUSE_ACCOUNT_REQUEST]: (state: State): State => ({
            ...state,
            payrixHouseAccountLoading: true,
        }),
        [LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS]: (
            state: State,
            action: LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS_ACTION
        ): State => ({
            ...state,
            error: undefined,
            payrixHouseAccount: action.payload,
            payrixHouseAccountLoaded: new Date(),
            payrixHouseAccountLoading: false,
        }),
        [PAYMENT_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>): State => {
            let newState = {
                ...state,
                paymentsFilters: {
                    ...state.paymentsFilters,
                    ...action.payload,
                },
                paymentsLoaded: undefined,
            };
            return newState;
        },
        [REMOVE_BANK_ACCOUNTS_REQUEST]: (state: State): State => ({
            ...state,
            bankAccountsLoading: true,
        }),
        [REMOVE_BANK_ACCOUNTS_SUCCESS]: (state: State, action: ActionWithPayload<number>): State => {
            let newState = {
                ...state,
                bankAccounts: state.bankAccounts.filter((bankAccount) => bankAccount.bankAccountId !== action.payload),
                bankAccountsLoading: false,
                error: undefined,
            };
            return newState;
        },
        [REPORTING_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<PaginationFilter>): State => {
            let newState = {
                ...state,
                reportingFilters: {
                    ...state.reportingFilters,
                    ...action.payload,
                },
            };
            return newState;
        },
        [TOGGLE_PAYOUT_TYPE_REQUEST]: (state: State): State => ({
            ...state,
            payoutTypeToggleLoading: true,
        }),
        [TOGGLE_PAYOUT_TYPE_SUCCESS]: (state: State): State => ({
            ...state,
            payoutTypeToggleLoading: false,
            payrixHouseAccountLoaded: undefined,
        }),
        [UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS]: (
            state: State,
            action: UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS_ACTION
        ): State => {
            let bankAccounts = cloneDeep(state.bankAccounts);
            bankAccounts.forEach(
                (bankAccount) =>
                    (bankAccount.defaultPayoutAccount =
                        bankAccount.bankAccountId === action.payload.bankAccountId ? true : false)
            );
            let newState = {
                ...state,
                bankAccounts,
                bankAccountsLoading: false,
                error: undefined,
            };
            return newState;
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.payments;
const passThroughSelector = (state, passThroughValue) => passThroughValue;

export const accountSummarySelector = createSelector(stateSelector, (state) => state.accountSummary);

export const accountSummaryLoadedSelector = createSelector(stateSelector, (state) => state.accountSummaryLoaded);

export const accountSummaryLoadingSelector = createSelector(stateSelector, (state) => state.accountSummaryLoading);

export const addBankAccountLoadingSelector = createSelector(stateSelector, (state) => state.addBankAccountLoading);

export const bankAccountsSelector = createSelector(stateSelector, (state) => state.bankAccounts);

export const bankAccountsLoadedSelector = createSelector(stateSelector, (state) => state.bankAccountsLoaded);

export const bankAccountsLoadingSelector = createSelector(stateSelector, (state) => state.bankAccountsLoading);

export const paymentsErrorSelector = createSelector(stateSelector, (state) => {
    let stateError = state.error;
    if (stateError) {
        if (stateError.indexOf('INVALID_REQUEST_ERROR') > -1) {
            //From api: Error: Category - INVALID_REQUEST_ERROR Code - INVALID_VALUE Detail - value is invalid Field - primary_bank_identification_number
            if (stateError.indexOf('primary_bank_identification_number') > -1) {
                return 'payments.invalidRoutingNumber';
            }
        }
        return stateError;
    }
    return undefined;
});

export const paymentsSelector = createSelector(stateSelector, (state) => state.payments);

export const paymentsTotalRecordsSelector = createSelector(stateSelector, (state) => state.paymentsTotalRecords);

export const paymentsLoadedSelector = createSelector(stateSelector, (state) => state.paymentsLoaded);

export const paymentsLoadingSelector = createSelector(stateSelector, (state) => state.paymentsLoading);

export const getPayoutTypeToggleLoading = createSelector(stateSelector, (state) => state.payoutTypeToggleLoading);

// This should probably select something more spesific
export const payrixHouseAccountSelector = createSelector(
    stateSelector,
    (state) => state.payrixHouseAccount?.length && state.payrixHouseAccount.find((account) => account.houseId)
);

export const payrixHouseAccountLoadedSelector = createSelector(
    stateSelector,
    (state) => !state.payrixHouseAccountLoading && state.payrixHouseAccountLoaded
);

export const payrixHouseAccountLoadingSelector = createSelector(
    stateSelector,
    (state) => state.payrixHouseAccountLoading
);

export const disbursementsSelector = createSelector(stateSelector, (state) => state.disbursements);

export const disbursementsTotalRecordsSelector = createSelector(
    stateSelector,
    (state) => state.disbursementsTotalRecords
);

export const disbursementsLoadedSelector = createSelector(stateSelector, (state) => state.disbursementsLoaded);

export const disbursementsLoadingSelector = createSelector(stateSelector, (state) => state.disbursementsLoading);

export const summaryPageLoadingSelector = createSelector(
    accountSummaryLoadingSelector,
    paymentsLoadingSelector,
    payrixHouseAccountLoadingSelector,
    (accountSummaryLoading, paymentsLoading, payrixHouseAccountLoading) =>
        accountSummaryLoading || paymentsLoading || payrixHouseAccountLoading
);

export const paymentsFiltersSelector = createSelector(
    stateSelector,
    paymentsTotalRecordsSelector,
    (state, totalRecords) => ({
        ...state.paymentsFilters,
        totalRecords,
    })
);

export const disbursementsFiltersSelector = createSelector(
    stateSelector,
    disbursementsTotalRecordsSelector,
    (state, totalRecords): PaginationFilter => ({
        ...state.disbursementsFilters,
        totalRecords,
    })
);

export const reportingFiltersSelector = createSelector(stateSelector, (state) => state.reportingFilters);

export const shouldFetchAccountSummary = createSelector(
    accountSummaryLoadedSelector,
    accountSummaryLoadingSelector,
    (accountSummaryLoaded, accountSummaryLoading) => {
        if (accountSummaryLoading) {
            return false;
        }
        const time = Date.now();
        // @ts-expect-error
        const diff = time - accountSummaryLoaded;
        return !accountSummaryLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const shouldFetchBankAccounts = createSelector(
    bankAccountsLoadedSelector,
    bankAccountsLoadingSelector,
    (bankAccountsLoaded, bankAccountsLoading) => {
        if (bankAccountsLoading) {
            return false;
        }
        const time = Date.now();
        // @ts-expect-error
        const diff = time - bankAccountsLoaded;
        return !bankAccountsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const shouldFetchDisbursements = createSelector(
    disbursementsLoadedSelector,
    disbursementsLoadingSelector,
    disbursementsFiltersSelector,
    passThroughSelector,
    (disbursementsLoaded, disbursementsLoading, storedFilters, filters) => {
        if (disbursementsLoading) {
            return false;
        }
        if (!isEqual(storedFilters, filters)) {
            return true;
        }
        const time = Date.now();
        // @ts-expect-error
        const diff = time - disbursementsLoaded;
        return !disbursementsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const shouldFetchPayments = createSelector(
    paymentsLoadedSelector,
    paymentsLoadingSelector,
    (paymentsLoaded, paymentsLoading) => {
        if (paymentsLoading) {
            return false;
        }
        const time = Date.now();
        // @ts-expect-error
        const diff = time - paymentsLoaded;
        return !paymentsLoaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const shouldFetchPayrixHouseAccount = createSelector(
    payrixHouseAccountLoadedSelector,
    payrixHouseAccountLoadingSelector,
    (loaded, loading) => {
        if (loading) {
            return false;
        }
        const time = Date.now();
        // @ts-expect-error
        const diff = time - loaded;
        return !loaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

/* ACTION CREATORS */
export const onPaymentsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: PAYMENT_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: PAYMENT_FILTERS_CHANGE_FAIL,
        });
    }
};

export const onDisbursementsFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: DISBURSEMENT_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: DISBURSEMENT_FILTERS_CHANGE_FAIL,
        });
    }
};

export const onReportingFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: REPORTING_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: REPORTING_FILTERS_CHANGE_FAIL,
        });
    }
};

export const loadAccountSummaryIfNeeded = (houseId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    if (shouldFetchAccountSummary(state)) {
        dispatch({
            type: LOAD_ACCOUNT_SUMMARY_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getAccountSummary({ authToken, deployment, houseId });
            dispatch({
                payload: response.payload,
                type: LOAD_ACCOUNT_SUMMARY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error?.message || error,
                type: LOAD_ACCOUNT_SUMMARY_FAIL,
            });
        }
    } else {
        return Promise.resolve({});
    }
};

export const removeBankAccount =
    (houseId: number, bankAccountId: number) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: REMOVE_BANK_ACCOUNTS_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const { payload } = await deactivateBankAccount({ authToken, bankAccountId, deployment, houseId });

            //The api returns true if it was successful.
            if (payload) {
                dispatch({
                    payload: bankAccountId,
                    type: REMOVE_BANK_ACCOUNTS_SUCCESS,
                });
            } else {
                dispatch({
                    error: true,
                    payload: 'Failed to remove bank account',
                    type: REMOVE_BANK_ACCOUNTS_FAIL,
                });
            }
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: REMOVE_BANK_ACCOUNTS_FAIL,
            });
        }
    };

export const addBankAccount =
    (bankAccount: BankAccountForm, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: ADD_BANK_ACCOUNT_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const providerId = 3;
            const response = await postBankAccount({ authToken, bankAccount, deployment, houseId, providerId });
            dispatch({
                payload: response.payload,
                type: ADD_BANK_ACCOUNT_SUCCESS,
            });
            return 'success';
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message || error,
                type: ADD_BANK_ACCOUNT_FAIL,
            });
            return 'error';
        }
    };

export const fetchHouseBankAccountsIfNeeded = (houseId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    if (shouldFetchBankAccounts(state)) {
        try {
            dispatch({
                type: LOAD_BANK_ACCOUNTS_REQUEST,
            });
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getBankAccounts({ authToken, deployment, houseId });
            dispatch({
                payload: response.payload,
                type: LOAD_BANK_ACCOUNTS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: LOAD_BANK_ACCOUNTS_FAIL,
            });
        }
    } else {
        return Promise.resolve({});
    }
};

export const fetchBidderPaymentsIfNeeded =
    (houseId: number, filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchPayments(state)) {
            try {
                dispatch({
                    payload: houseId,
                    type: LOAD_BIDDER_PAYMENTS_REQUEST,
                });

                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                const response = await getBidderPayments({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    houseId,
                    keyword: filters.keyword || '',
                    page: filters.page,
                    // @ts-ignore
                    pageSize: filters.pageSize,
                    // @ts-ignore
                    status: filters.status,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                return dispatch({
                    meta: { houseId },
                    payload: {
                        page: filters.page,
                        payments: response.payload.data,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_BIDDER_PAYMENTS_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { houseId },
                    payload: error.message,
                    type: LOAD_BIDDER_PAYMENTS_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };

export const fetchHouseDisbursementsIfNeeded =
    (houseId: number, filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchDisbursements(state, filters)) {
            try {
                dispatch({
                    payload: houseId,
                    type: LOAD_DISBURSEMENTS_REQUEST,
                });

                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                const response = await getDisbursements({
                    authToken,
                    deployment,
                    from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                    houseId,
                    keyword: filters.keyword || '',
                    page: filters.page,
                    // @ts-ignore
                    pageSize: filters.pageSize,
                    to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
                });

                return dispatch({
                    meta: { houseId },
                    payload: {
                        disbursements: response.payload.data,
                        page: filters.page,
                        totalRecords: response.payload.totalRecords,
                    },
                    type: LOAD_DISBURSEMENTS_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    meta: { houseId },
                    payload: error.message,
                    type: LOAD_DISBURSEMENTS_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };

export const updatePrimaryBankAccount =
    (houseId: number, bankAccountId: number) => async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: UPDATE_PRIMARY_BANK_ACCOUNT_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const { payload } = await putUpdatePrimaryBankAccount({ authToken, bankAccountId, deployment, houseId });

            // api returns a bool
            if (payload) {
                dispatch({
                    payload: { bankAccountId },
                    type: UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS,
                });
            } else {
                dispatch({
                    error: true,
                    payload: 'Failed to update primary bank account',
                    type: UPDATE_PRIMARY_BANK_ACCOUNT_FAIL,
                });
            }
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: UPDATE_PRIMARY_BANK_ACCOUNT_FAIL,
            });
        }
    };

//This function does not dispacth actions but instead returns a blob of csv
export const loadHousePaymentRecords =
    (houseId: number, filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getHousePaymentRecords({
                authToken,
                deployment,
                from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                houseId,
                keyword: filters.keyword || '',
                to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

//This function does not dispacth actions but instead returns a blob of csv
export const loadHouseDisbursementRecords =
    (houseId: number, filters: PaginationFilter) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getHouseDisbursementRecords({
                authToken,
                deployment,
                from: moment(filters.from.setHours(0, 0, 0)).format(), // format() defaults to iso8601
                houseId,
                to: moment(filters.to.setHours(23, 59, 59)).format(), // format() defaults to iso8601
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

export const fetchPayrixHouseAccountIfNeeded = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        if (shouldFetchPayrixHouseAccount(state)) {
            dispatch({
                type: LOAD_PAYRIX_HOUSE_ACCOUNT_REQUEST,
            });
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getPayrixHouseAccount({ authToken, deployment, houseId });

            dispatch({
                payload: response.payload,
                type: LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS,
            });
        }
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message || error,
            type: LOAD_PAYRIX_HOUSE_ACCOUNT_FAIL,
        });
    }
};

export const togglePayoutType =
    (houseId: number, entityId: string, type: $Values<typeof payoutType>) =>
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: TOGGLE_PAYOUT_TYPE_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const { payload } = await postPayrixPayoutType({
                authToken,
                deployment,
                entityId,
                houseId,
                payoutType: type,
            });

            if (payload) {
                dispatch({
                    type: TOGGLE_PAYOUT_TYPE_SUCCESS,
                });
            } else {
                dispatch({
                    error: true,
                    payload: 'Failed to toggle payout type',
                    type: TOGGLE_PAYOUT_TYPE_FAIL,
                });
            }
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: TOGGLE_PAYOUT_TYPE_FAIL,
            });
        }
    };
