import * as api from '../api/catalog';
import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { Item } from '../../types/Item';
import { LOAD_CATALOG_ITEMS_FAIL, LOAD_CATALOG_ITEMS_REQUEST, LOAD_CATALOG_ITEMS_SUCCESS } from './actions';
import { shouldFetchItemsForCatalog } from './item';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
    // TODO move loaded and loading to here from loaded reducer
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_CATALOG_ITEMS_SUCCESS]: (state: State, action: ActionWithPayload<{ catalogItems: Item[] }>) => {
            const existing = cloneDeep(state.byId);

            if (action.payload.catalogItems) {
                action.payload.catalogItems.forEach((item) => {
                    existing[item.catalogId] = { ...item };
                });
            }
            return {
                ...state,
                byId: existing,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.catalogItems;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const itemsSelector = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

export const getTotalItemCount = createSelector(itemsSelector, (items) => items.totalCount || 0);

/* ACTION CREATORS */
const loadCatalogItems = (catalogId, cursor, page, count, itemId, buyNow) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            meta: { actionTime: Date.now() },
            payload: catalogId,
            type: LOAD_CATALOG_ITEMS_REQUEST,
        });
        const response = await api.postCatalogItems({
            authToken,
            // @ts-ignore
            buyNow,
            catalogId,
            count,
            cursor,
            deployment,
            itemId,
            page,
        });
        return dispatch({
            meta: { actionTime: Date.now(), catalogId },
            payload: response.data,
            type: LOAD_CATALOG_ITEMS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { catalogId },
            payload: error,
            type: LOAD_CATALOG_ITEMS_FAIL,
        });
    }
};

export const fetchCatalogItemsIfNeeded =
    (catalogId: number, cursor: number | boolean, page: number, count: number | 'all', itemId: number, buyNow: any) =>
    async (dispatch: Function, getState: Function) => {
        const needed = shouldFetchItemsForCatalog(getState(), catalogId);
        if (needed) {
            return dispatch(loadCatalogItems(catalogId, cursor, page, count, itemId, buyNow));
        }
        return Promise.resolve();
    };
