import { handleResponse, makeGet } from './helpers';

export type FeatureAccessParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export type FeatureAccessResponsePayload = {
    error: boolean;
    payload: {
        fullUpload: boolean;
        fullUploadLiveSales: boolean;
        massEmail: boolean;
        massEmailLimited: boolean;
        onlineOnlyPlan: boolean;
    };
};

export type FeatureAccessErrorPayload = {
    error: boolean;
    payload: string;
};

const getFeatureAccess = ({ authToken, deployment, houseId }: FeatureAccessParams) =>
    new Promise<FeatureAccessResponsePayload | FeatureAccessErrorPayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<HOUSE-USER-API>house/${houseId}/permissions`,
        });
        request.end((err: boolean, response: FeatureAccessResponsePayload | FeatureAccessErrorPayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export default {
    getFeatureAccess,
};
