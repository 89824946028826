import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_HOUSE_BETA_ENROLLMENTS_FAILURE,
    LOAD_HOUSE_BETA_ENROLLMENTS_REQUEST,
    LOAD_HOUSE_BETA_ENROLLMENTS_SUCCESS,
} from './actions';
import api from '../api/houseBetaEnrollments';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20m');

export type State = {
    enrollments: any;
    error?: string | null;
    loaded: number;
    loading: boolean;
};

export const DEFAULT_STATE: State = {
    enrollments: {},
    error: undefined,
    loaded: undefined,
    loading: false,
};

export const reducer = handleActions(
    {
        [LOAD_HOUSE_BETA_ENROLLMENTS_FAILURE]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [LOAD_HOUSE_BETA_ENROLLMENTS_REQUEST]: (state: State) => ({
            ...state,
            loaded: undefined,
            loading: true,
        }),
        [LOAD_HOUSE_BETA_ENROLLMENTS_SUCCESS]: (state: State, action: ActionWithPayload<any>) => {
            let betas = {};
            action.payload.forEach((e) => {
                betas[e.name] = e.enrolled;
            });
            return { ...state, enrollments: betas, loaded: new Date(), loading: false };
        },
    },
    DEFAULT_STATE
);

const stateSelector = (state) => state.houseBetaEnrollments;

export const getHouseBetaEnrollments = createSelector(stateSelector, (state) => state.enrollments);

export const getHouseBetaEnrollmentsLoaded = createSelector(stateSelector, (state) => state.loaded);

export const getHouseBetaEnrollmentsLoading = createSelector(stateSelector, (state) => state.loading);

export const shouldFetchHouseBetas = createSelector(
    getHouseBetaEnrollmentsLoaded,
    getHouseBetaEnrollmentsLoading,
    (loaded, loading) => {
        if (loading) {
            return false;
        }
        const time = Date.now();
        const diff = time - loaded;
        return !loaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const fetchHouseBetaEnrollmentsIfNeeded =
    (houseId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchHouseBetas(state)) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                dispatch({ type: LOAD_HOUSE_BETA_ENROLLMENTS_REQUEST });

                const response = await api.getHouseBetaEnrollments({
                    authToken,
                    deployment,
                    houseId,
                });

                dispatch({
                    payload: response.payload,
                    type: LOAD_HOUSE_BETA_ENROLLMENTS_SUCCESS,
                });
            } catch (error) {
                dispatch({ error: true, payload: error, type: LOAD_HOUSE_BETA_ENROLLMENTS_FAILURE });
            }
        } else {
            return Promise.resolve({});
        }
    };
