import * as api from '../api/remote';
import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_REVIEW_COMMENTS_FAIL,
    LOAD_REVIEW_COMMENTS_REQUEST,
    LOAD_REVIEW_COMMENTS_SUCCESS,
    SEND_REVIEW_COMMENT_FAIL,
    SEND_REVIEW_COMMENT_REQUEST,
    SEND_REVIEW_COMMENT_SUCCESS,
} from './actions';
import { ReviewComment } from '../../types/ReviewComment';
import cloneDeep from 'lodash/cloneDeep';

// reducer
export const DEFAULT_STATE = {
    byHouseId: {},
    error: false,
    loaded: false,
    loading: false,
    submitStatus: {},
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_REVIEW_COMMENTS_FAIL]: (state: State) => ({
            ...state,
            error: true,
            loaded: false,
            loading: false,
        }),
        [LOAD_REVIEW_COMMENTS_REQUEST]: (state: State) => ({
            ...state,
            error: false,
            loaded: false,
            loading: true,
        }),
        [LOAD_REVIEW_COMMENTS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ comments: any[] }, { actionTime: number }>
        ) => {
            const comments = cloneDeep(state.byHouseId);
            if (action.payload.comments) {
                action.payload.comments.forEach((comment) => {
                    comments[comment.houseId]
                        ? (comments[comment.houseId][comment.commentId] = comment)
                        : (comments[comment.houseId] = { [comment.commentId]: comment });
                });
            }
            return {
                ...state,
                byHouseId: comments,
                loaded: true,
                loading: false,
            };
        },
        [SEND_REVIEW_COMMENT_FAIL]: (state: State, action: ActionWithPayload<{ error: any; loadingId: string }>) => ({
            ...state,
            error: true,
            submitStatus: { [action.payload.loadingId]: 'fail' },
        }),
        [SEND_REVIEW_COMMENT_REQUEST]: (state: State, action: ActionWithPayload<{ loadingId: string }>) => ({
            ...state,
            error: false,
            submitStatus: { [action.payload.loadingId]: 'submitted' },
        }),
        [SEND_REVIEW_COMMENT_SUCCESS]: (state: State, action: ActionWithPayload<{ loadingId: string }>) => ({
            ...state,
            submitStatus: { [action.payload.loadingId]: 'success' },
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.reviewComments;
const idSelector = (state, id) => id;

const byHouseIdSelector = createSelector(stateSelector, (state) => state.byHouseId);

export const getSubmitStatus = createSelector(stateSelector, (state) => state.submitStatus);

export const getHouseReviewComments = createSelector(
    [byHouseIdSelector, idSelector],
    (houseId, id) => houseId[id] || []
);

/* ACTION CREATORS */
export const submitReviewComment = (reviewComment: ReviewComment) => async (dispatch: Function, getState: Function) => {
    const loadingId = reviewComment.privacyStatus + reviewComment.reviewId;
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: { loadingId },
            type: SEND_REVIEW_COMMENT_REQUEST,
        });
        const response = await api.postReviewComment({ authToken, deployment, reviewComment });
        dispatch({
            payload: { ...response, loadingId },
            type: SEND_REVIEW_COMMENT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: { ...error, loadingId },
            type: SEND_REVIEW_COMMENT_FAIL,
        });
    }
};

export const fetchHouseReviewComments = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_REVIEW_COMMENTS_REQUEST,
        });

        const response = await api.fetchHouseReviewComments({
            authToken,
            deployment,
            houseId,
        });

        dispatch({
            payload: {
                ...response,
            },
            type: LOAD_REVIEW_COMMENTS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_REVIEW_COMMENTS_FAIL,
        });
    }
};
