import { handleResponse, makeGet } from './helpers';
import isEmpty from 'lodash/isEmpty';

type FetchCatalogPerformanceData = {
    authToken: string;
    catalogId: number;
    deployment: string;
    endTime?: string;
    houseId: number;
    startTime?: string;
};

type FetchHouseStatsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const transformData = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const { change, chartPoints, currency = 'USD', stat } = payload;
    return {
        change,
        currency,
        data: chartPoints.map((datum) => {
            return { x: new Date(datum.time), y: datum.value };
        }),
        stat,
    };
};

export const transformCatalogRegistrationInfo = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const { approvedRegistrations, pendingRegistrations, registrationCountries } = payload;

    return {
        approvals: approvedRegistrations,
        countries: registrationCountries,
        pending: pendingRegistrations,
    };
};

export const transformSalesData = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const {
        catalogId,
        currency,
        exclusiveBids,
        floorBids,
        houseId,
        lots,
        lotsSoldLa,
        outbids,
        salesGrowth,
        totalHammer,
        underbids,
        withPremium,
    } = payload;

    return {
        catalogId,
        currency: currency || 'USD',
        exclusiveBids,
        floorBids,
        houseId,
        lots: lots || [],
        lotsSoldLa,
        outbids,
        salesGrowth,
        totalHammer,
        underbids,
        withPremium,
    };
};

export const transformBidCountInfoData = (body: any = {}, catalogId: number) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const { absenteeBids, bidsMissed = 0, liveBids } = payload;

    return [
        {
            title: 'Absentee',
            value: absenteeBids,
        },
        {
            title: 'Live',
            value: liveBids,
        },
        {
            catalogId,
            title: 'Missed Bids',
            value: bidsMissed,
        },
    ];
};

export const transformBidderCountInfoData = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const { newBidders, returningBidders } = payload;

    return [
        {
            title: 'Returning',
            value: returningBidders,
        },
        {
            title: 'New',
            value: newBidders,
        },
    ];
};

export const transformHouseStats = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }

    const {
        growthInPrices,
        houseCurrencyCode,
        percentageGrowthInPrices,
        sellThroughRate,
        totalBidsPlaced,
        totalCatalogCount,
        totalPageViews: totalPageviews,
        totalRegistrations,
        totalSoldOnLA,
    } = payload;

    return {
        payload: {
            growthInPrices: {
                amount: growthInPrices,
                currency: houseCurrencyCode || 'USD',
            },
            percentageGrowthInPrices: percentageGrowthInPrices
                ? percentageGrowthInPrices * 0.01
                : percentageGrowthInPrices,
            sellThroughRate: sellThroughRate ? sellThroughRate * 0.01 : sellThroughRate,
            totalBidsPlaced,
            totalCatalogCount,
            totalPageviews,
            totalRegistrations,
            totalSoldOnLA: {
                amount: totalSoldOnLA,
                currency: houseCurrencyCode || 'USD',
            },
        },
    };
};

export const transformMarketingEvents = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }
    if (Boolean(payload.data) && Boolean(payload.data.length)) {
        let transformed = payload.data;
        transformed.forEach((t) => {
            t.title = t.title.replace('Ad Unit', 'Ad Unit Email');
        });
        payload.data = transformed;
    }
    return payload;
};

export default {
    fetchAbsenteeBidData: ({
        authToken,
        catalogId,
        deployment,
        endTime,
        houseId,
        startTime,
    }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/absenteebidcountstat`,
            });

            request.query({
                endTime,
                startTime,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformData,
                })
            );
        }),
    fetchBidValueData: ({
        authToken,
        catalogId,
        deployment,
        endTime,
        houseId,
        startTime,
    }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/bidvaluestat`,
            });

            request.query({
                endTime,
                startTime,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformData,
                })
            );
        }),
    fetchCatalogBidCountInfoData: ({ authToken, catalogId, deployment, houseId }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/bidcountinfo`,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: (body) => transformBidCountInfoData(body, catalogId),
                })
            );
        }),
    fetchCatalogBidderCountInfoData: ({ authToken, catalogId, deployment, houseId }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/biddercountinfo`,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: (body) => transformBidderCountInfoData(body),
                })
            );
        }),
    fetchCatalogPageviewInfoData: ({ authToken, catalogId, deployment, houseId }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/pageviewinfo`,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                })
            );
        }),
    fetchCatalogPageviewsData: ({
        authToken,
        catalogId,
        deployment,
        endTime,
        houseId,
        startTime,
    }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/pageviewstat`,
            });

            request.query({
                endTime,
                startTime,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformData,
                })
            );
        }),
    fetchCatalogRegistrationData: ({
        authToken,
        catalogId,
        deployment,
        endTime,
        houseId,
        startTime,
    }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/registrationcountstat`,
            });

            request.query({
                endTime,
                startTime,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformData,
                })
            );
        }),
    fetchCatalogRegistrationInfo: ({ authToken, catalogId, deployment, houseId }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/registrationinfo`,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformCatalogRegistrationInfo,
                })
            );
        }),
    fetchCatalogSalesData: ({ authToken, catalogId, deployment, houseId }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/growth`,
            });

            return request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformSalesData,
                })
            );
        }),

    fetchHouseStats: ({ authToken, deployment, houseId }: FetchHouseStatsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/year-to-date`,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformHouseStats,
                })
            );
        }),
    fetchSavedItemData: ({
        authToken,
        catalogId,
        deployment,
        endTime,
        houseId,
        startTime,
    }: FetchCatalogPerformanceData) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/saveditemcountstat`,
            });

            request.query({
                endTime,
                startTime,
            });

            request.end((err, response) =>
                handleResponse({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformData,
                })
            );
        }),
};

type FetchMarketingEventsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    endTime: string;
    houseId: number;
    startTime: string;
};

export const fetchMarketingEvents = ({
    authToken,
    catalogId,
    deployment,
    endTime,
    houseId,
    startTime,
}: FetchMarketingEventsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: '<MARKETING-REPORTS-API>/getcampaignsandevents',
        });

        request.query({
            catalogId,
            endTime,
            houseId,
            startTime,
        });

        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: transformMarketingEvents,
            })
        );
    });

type FetchRecentMarketingEventsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    page: number;
    pageSize: number;
};

export const fetchRecentMarketingEvents = ({
    authToken,
    deployment,
    houseId,
    page,
    pageSize,
}: FetchRecentMarketingEventsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: '<MARKETING-REPORTS-API>/getcampaignsandevents',
        });

        request.query({
            houseId,
            page,
            pageSize,
            recentEmailCampaigns: 'true',
        });

        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: transformMarketingEvents,
            })
        );
    });

type FetchCatalogItemStatsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
    page: number;
    pageSize: number;
    sort: string;
};

export const transformItemStats = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }
    if (Boolean(payload.data.length)) {
        // leading bid/hammer price are the same, just pre/post auction
        const transformed = payload.data.map((item) => {
            const {
                catalogId,
                catalogStatus,
                catalogTitle,
                currencyCode: currency,
                firstPhoto,
                houseId,
                imageVersion,
                isPromoted,
                lotBids: bids,
                lotId,
                lotLeadingBid: hammer,
                lotLeadingBid: leadingBid,
                lotNumber,
                lotPageViews: pageViews,
                lotSaves: saves,
                lotTitle: title,
                lowEstimate,
                percentFromEstimate,
                salesGrowth,
            } = item;

            return {
                bids,
                catalogId,
                catalogStatus,
                catalogTitle,
                currency,
                firstPhoto,
                hammer,
                houseId,
                imageVersion,
                isPromoted,
                leadingBid,
                lotId,
                lotNumber,
                lowEstimate,
                pageViews,
                percentFromEstimate,
                salesGrowth,
                saves,
                title,
            };
        });
        payload.data = transformed;
    }
    return payload;
};

export const fetchCatalogItemStats = ({
    authToken,
    catalogId,
    deployment,
    houseId,
    page,
    pageSize,
    sort,
}: FetchCatalogItemStatsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<REPORT-API>/house/${houseId}/catalog/${catalogId}/lotstats`,
        });

        request.query({
            catalogId,
            houseId,
            page,
            pageSize,
            sort,
        });

        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: transformItemStats,
            })
        );
    });

type FetchCatalogStatsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    page: number;
    pageSize: number;
    sort: string;
    year: string;
};

export const transformCatalogStats = (body: any = {}) => {
    const { payload = {} } = body;
    if (typeof payload === 'string' || !payload || isEmpty(body)) {
        return body;
    }
    return payload;
};

export const fetchCatalogStats = ({
    authToken,
    deployment,
    houseId,
    page,
    pageSize,
    sort,
    year,
}: FetchCatalogStatsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<REPORT-API>/house/${houseId}/catalogstats`,
        });

        request.query({
            houseId,
            page,
            pageSize,
            sort,
            year,
        });

        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: transformCatalogStats,
            })
        );
    });
