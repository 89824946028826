import { getUserData } from '../redux/modules/user';
import { makePost } from '../redux/api/helpers';
import uuid from 'uuid';

const sessionId = uuid.v4();
const sessionStartTime = Date.now();

let store = undefined;
let firstLoad = true;

let tags = {};
let appContext = {};
type AppContext = {
    environment: string;
    release: string;
};

export const setTag = (name: string, value: string) => {
    tags[name] = value;
};

let longTasks = [];
let observer = undefined;
export const setContext = ({ environment = 'preprod', release = 'LOCALDEV' }: AppContext, s: any) => {
    appContext = { environment, release };
    store = s;
    try {
        observer = new PerformanceObserver(function (list) {
            var perfEntries = list.getEntries();
            for (var i = 0; i < perfEntries.length; i++) {
                longTasks.push(perfEntries[i]);
            }
        });

        // register observer for long task notifications
        observer.observe({ entryTypes: ['longtask'] });
    } catch (err) {}
};

const sendBeacon = () => {
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    const timings = performanceEntries();
    if (timings.length <= 0) {
        return;
    }

    let cpus = -1;
    if (navigator.hardwareConcurrency) {
        cpus = navigator.hardwareConcurrency;
    }

    // @ts-expect-error
    const { environment } = appContext;
    const userData = getUserData(store.getState()) || {};
    // @ts-expect-error
    const { email, houseId, name, sellerId, username } = userData;
    const session = {
        isFirstLoad: firstLoad,
        sessionDurationMs: Date.now() - sessionStartTime,
        sessionId: sessionId,
        sessionStartTimeMs: sessionStartTime,
        userAgent: navigator.userAgent,
    };

    const beacon = {
        cpus,
        longTasks: longTasks,
        memory: memoryMetrics(),
        sent: Date.now(),
        session,
        tags: { ...appContext, ...tags },
        timings,
        user: { email, houseId, name, sellerId, username },
    };

    const t = timings.map((timing) => ({
        cpus,
        sent: Date.now(),
        ...session,
        ...memoryMetrics(),
        ...appContext,
        ...tags,
        ...userData,
        ...timing,
    }));

    if (environment !== 'prod') {
        console.trace(beacon, '\n', t);
    }

    postBeacon(t, environment);

    longTasks = [];
    performance.clearResourceTimings();
    firstLoad = false;
};

export const instrumentRequest = (url) => {
    try {
        url === '<AUCTION-ENGINE-API>' && sendBeacon();
    } catch (error) {
        // @ts-expect-error
        if (appContext?.environment !== 'prod') {
            console.error('could not send API timings: ', error);
        }

        console.log(`could not send clerk beacon ${error}`, {
            extra: {
                ...appContext,
            },
        });
    }
};

const memoryMetrics = () => {
    // @ts-expect-error
    const { memory } = window.performance || {};
    if (memory) {
        const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = memory;
        return { limit: jsHeapSizeLimit, total: totalJSHeapSize, used: usedJSHeapSize };
    }

    return { limit: 0, total: 0, used: 0 };
};

const performanceEntries = () =>
    performance
        .getEntries()
        // @ts-expect-error
        .filter(({ initiatorType, name }) => name.includes('auction-service-') && initiatorType === 'xmlhttprequest')
        .map(
            ({
                // @ts-expect-error
                connectEnd,
                // @ts-expect-error
                connectStart,
                // @ts-expect-error
                decodedBodySize,
                // @ts-expect-error
                domainLookupEnd,
                // @ts-expect-error
                domainLookupStart,
                duration,
                // @ts-expect-error
                encodedBodySize,
                // @ts-expect-error
                fetchStart,
                name,
                // @ts-expect-error
                nextHopProtocol,
                // @ts-expect-error
                redirectEnd,
                // @ts-expect-error
                redirectStart,
                // @ts-expect-error
                requestStart,
                // @ts-expect-error
                responseEnd,
                // @ts-expect-error
                responseStart,
                // @ts-expect-error
                secureConnectionStart,
                startTime,
                // @ts-expect-error
                transferSize,
            }) => ({
                connectEnd,
                connectStart,
                decodedBodySize,
                domainLookupEnd,
                domainLookupStart,
                duration,
                encodedBodySize,
                fetchStart,
                name,
                protocol: nextHopProtocol,
                redirectEnd,
                redirectStart,
                requestStart,
                responseEnd,
                responseStart,
                secureConnectionStart,
                startTime,
                transferSize,
            })
        );

const postBeacon = (b: any, environment: string) =>
    new Promise((resolve, reject) => {
        const request = makePost({
            deployment: environment,
            path: '<BOOMCATCH-API>',
        });

        request.set('Content-Type', 'application/json');
        request.send(b);
        request.end((err) => {
            if (err) {
                reject(err);

                console.log('could not send clerk beacon', {
                    data: {
                        ...b,
                    },
                });

                return;
            }
            // @ts-expect-error
            resolve();
        });
    });
