import { ActionWithPayload } from '@/types/redux';
import { BidderProfile } from '@/types/BidderProfile';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_BIDDER_PROFILE_FAIL,
    LOAD_BIDDER_PROFILE_REQUEST,
    LOAD_BIDDER_PROFILE_SUCCESS,
    LOAD_UPDATE_HOUSE_NOTE_SUCCESS,
} from './actions';
import api from '../api/bidderProfile';

export type State = {
    bidder: BidderProfile;
    error: boolean;
    isLoading: boolean;
};

/* reducer */
export const DEFAULT_STATE: State = {
    bidder: {
        allowMessaging: true,
        approvalCount: 0,
        auctionsAttended: 0,
        averageHammer: 0,
        averageHammerRange: '',
        cardOnFile: false,
        closedDisputeDetails: [
            {
                amount: 0,
                created: '',
                currencyCode: 'USD',
                houseName: '',
            },
        ],
        disputeDetails: [
            {
                amount: 0,
                created: '',
                currencyCode: 'USD',
                houseName: '',
            },
        ],
        disputeInfo: {
            ClosedDisputesPastYear: 0,
            HouseDisputes: {},
            OpenDisputes: 0,
            TotalDisputes: 0,
        },
        escrow: false,
        goodStanding: false,
        houseNote: '',
        isBlocked: false,
        isFavorited: false,
        lotsWon: 0,
        paymentInfo: '',
        personalDetails: {
            Address: '',
            Address2: '',
            City: '',
            Country: '',
            Created: '',
            Email: '',
            FirstName: '',
            LastName: '',
            PhoneNumber: '',
            ProfileComplete: false,
            State: '',
            UserName: '',
            Zip: 0,
        },
        prediction: '',
        premium: false,
        previouslyApproved: false,
        recommendedFavorite: false,
        registrationDetails: [],
        taxExemptions: [],
        totalBidsPlaced: 0,
        totalSpent: 0,
        totalSpentRange: '',
    },
    error: false,
    isLoading: false,
};

export const reducer = handleActions(
    {
        [LOAD_BIDDER_PROFILE_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_BIDDER_PROFILE_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BIDDER_PROFILE_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidder: BidderProfile;
            }>
        ): State => {
            return {
                ...state,
                bidder: action.payload.bidder,
                isLoading: false,
            };
        },
        [LOAD_UPDATE_HOUSE_NOTE_SUCCESS]: (state: State, action: ActionWithPayload<string>): State => ({
            ...state,
            bidder: {
                ...state.bidder,
                houseNote: action.payload,
            },
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.bidderProfile;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const getError = createSelector(stateSelector, (state) => state.error);
export const bidderSelector = createSelector(stateSelector, (state) => state.bidder);
export const getBidderIsBlocked = createSelector(bidderSelector, ({ isBlocked }) => isBlocked);
export const registrationsSelector = createSelector(
    bidderSelector,
    ({ registrationDetails }) => registrationDetails || []
);
export const getBidderHouseNote = createSelector(bidderSelector, ({ houseNote }) => houseNote || '');

/* ACTION CREATORS */

export const fetchBidderProfileData =
    (bidderId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BIDDER_PROFILE_REQUEST,
            });

            const response = await api.getBidderProfile({
                authToken,
                bidderId,
                deployment,
                houseId,
            });

            dispatch({
                payload: { bidder: response.payload },
                type: LOAD_BIDDER_PROFILE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_BIDDER_PROFILE_FAIL,
            });
        }
    };
