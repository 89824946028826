import { CategoryIdentifier } from '@/types/CategoryIdentifier';
import { FacetOption } from '@liveauctioneers/caterwaul-components/types/Facet';
import { PartialLot, SelectedCategory } from '../categorizer';
import { TaxonomyEntry } from '@/types/Taxonomy';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_CATEGORIZER_FAIL = 'la/domain/categorizer/LOAD_FAIL';
export type LOAD_CATEGORIZER_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_CATEGORIZER_FAIL,
    {
        error: any;
    }
>;
export const LOAD_CATEGORIZER_REQUEST = 'la/domain/categorizer/LOAD_REQUEST';
export type LOAD_CATEGORIZER_REQUEST_ACTION = TypedActionWithPayload<typeof LOAD_CATEGORIZER_REQUEST>;
export const LOAD_CATEGORIZER_LOTS_SUCCESS = 'la/domain/categorizer/LOAD_LOTS_SUCCESS';
export type LOAD_CATEGORIZER_LOTS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CATEGORIZER_LOTS_SUCCESS,
    { lotCategories: { categories: TaxonomyEntry[]; lotId: number }[]; total: number }
>;

export const LOAD_CATEGORIZER_NAMES_SUCCESS = 'la/domain/categorizer/LOAD_NAMES_SUCCESS';
export type LOAD_CATEGORIZER_NAMES_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CATEGORIZER_NAMES_SUCCESS,
    PartialLot[]
>;

export const LOAD_CATEGORIZER_FACETS_SUCCESS = 'la/domain/categorizer/LOAD_FACETS_SUCCESS';
export type LOAD_CATEGORIZER_FACETS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CATEGORIZER_FACETS_SUCCESS,
    { facets: FacetOption[]; missingCounts: { 1: number; 2: number; 3: number; 4: number; 5: number } }
>;

export const SELECT_CATEGORY = 'la/domain/categorizer/SELECT_FACET';
export type SELECT_CATEGORY_ACTION = TypedActionWithPayload<
    typeof SELECT_CATEGORY,
    { [key in CategoryIdentifier]: SelectedCategory[] }
>;

export const SET_CATALOGID = 'la/domain/categorizer/SET_CATALOGID';
export type SET_CATALOGID_ACTION = TypedActionWithPayload<typeof SET_CATALOGID, number>;

export const UPDATE_PAGINATION = 'la/domain/categorizer/UPDATE_PAGINATION';
export type UPDATE_PAGINATION_ACTION = TypedActionWithPayload<
    typeof UPDATE_PAGINATION,
    {
        page: number;
        pageSize?: number;
    }
>;

export const SET_SELECTED_LOT_IDS = 'la/domain/categorizer/SET_SELECTED_LOT_IDS';
export type SET_SELECTED_LOT_IDS_ACTION = TypedActionWithPayload<typeof SET_SELECTED_LOT_IDS, number[]>;

export const ADD_TAG_TO_LOTS_FAIL = 'la/domain/categorizer/ADD_TAG_TO_LOTS_FAIL';
export type ADD_TAG_TO_LOTS_FAIL_ACTION = TypedActionWithPayload<
    typeof ADD_TAG_TO_LOTS_FAIL,
    {
        error: any;
    }
>;
export const ADD_TAG_TO_LOTS_REQUEST = 'la/domain/categorizer/ADD_TAG_TO_LOTS_REQUEST';
export const ADD_TAG_TO_LOTS_SUCCESS = 'la/domain/categorizer/ADD_TAG_TO_LOTS_SUCCESS';
export type ADD_TAG_TO_LOTS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof ADD_TAG_TO_LOTS_SUCCESS,
    any[],
    {
        lotCategory: TaxonomyEntry;
        lotIds: number[];
    }
>;

export const REMOVE_TAG_FROM_LOT_FAIL = 'la/domain/categorizer/REMOVE_TAG_FROM_LOT_FAIL';
export type REMOVE_TAG_FROM_LOT_FAIL_ACTION = TypedActionWithPayload<
    typeof REMOVE_TAG_FROM_LOT_FAIL,
    {
        error: any;
    }
>;
export const REMOVE_TAG_FROM_LOT_REQUEST = 'la/domain/categorizer/REMOVE_TAG_FROM_LOT_REQUEST';
export const REMOVE_TAG_FROM_LOT_SUCCESS = 'la/domain/categorizer/REMOVE_TAG_FROM_LOT_SUCCESS';
export type REMOVE_TAG_FROM_LOT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof REMOVE_TAG_FROM_LOT_SUCCESS,
    any[],
    {
        categoryId: number;
        lotId: number;
    }
>;
