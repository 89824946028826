import { getOpenModal } from '@/redux/modules/modal';
import { lazy, Suspense } from 'react';
import { useAppSelector } from '@/redux/hooks';
import modalTypes from '../../enums/modalTypes';

const AddCreditCardModal = lazy(() => import('./AddCreditCard/AddCreditCardModalContainer'));
const BidderGuaranteeProgramOptOutModal = lazy(
    () => import('./BidderGuaranteeProgramOptOut/BidderGuaranteeProgramOptOutModal')
);
const BidderGuaranteeProgramModal = lazy(() => import('./BidderGuaranteeProgram/BidderGuaranteeProgramModal'));
const BidderMassFavoritingModal = lazy(() => import('./BidderMassFavoriting/BidderMassFavoritingModal'));
const MarketingAdvertisingModal = lazy(() => import('./MarketingAdvertising/MarketingAdvertisingModalContainer'));
const FeedbackModal = lazy(() => import('./Feedback/FeedbackModalContainer'));
const HealthcheckModal = lazy(() => import('./Healthcheck/HealthcheckModal'));
const PaymentToggleSameDayModal = lazy(() => import('./PaymentToggleSameDayModal/PaymentToggleSameDayModal'));
const PerformanceRegistrationCountryModal = lazy(
    () => import('./PerformanceRegistrationCountryModal/PerformanceRegistrationCountryModalContainer')
);
const PageviewReferralSourcesModal = lazy(
    () => import('./PageviewReferralSourcesModal/PageviewReferralSourcesModalContainer')
);
const SpeedCheckModal = lazy(() => import('./SpeedCheck/SpeedCheckModalContainer'));
const CreateAnnouncementModal = lazy(() => import('./CreateAnnouncement/CreateAnnouncementModalContainer'));
const ChangePasswordModal = lazy(() => import('./ChangePassword/ChangePasswordModal'));
const PaymentModal = lazy(() => import('./Payment/PaymentModal'));
const AddAccountingContactModal = lazy(() => import('./AddAccountingContact/AddAccountingContactModal'));
const SendBidderMessageModal = lazy(() => import('./SendBidderMessage/SendBidderMessageModal'));
const LapPaymentMandateModal = lazy(() => import('./LapPaymentMandate/LapPaymentMandateModalContainer'));

const Modals = () => {
    const openModal = useAppSelector(getOpenModal);
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {openModal === modalTypes.ADD_CREDIT_CARD && <AddCreditCardModal />}
            {openModal === modalTypes.MARKETING_ADVERTISING && <MarketingAdvertisingModal />}
            {openModal === modalTypes.FEEDBACK && <FeedbackModal />}
            {openModal === modalTypes.HEALTHCHECK && <HealthcheckModal />}
            {openModal === modalTypes.PAGEVIEW_REFERRAL_SOURCES && <PageviewReferralSourcesModal />}
            {openModal === modalTypes.PAYMENTS_TOGGLE_SAME_DAY && <PaymentToggleSameDayModal />}
            {openModal === modalTypes.PERFORMANCE_REGISTRATION_COUNTRY && <PerformanceRegistrationCountryModal />}
            {openModal === modalTypes.SPEED_CHECK && <SpeedCheckModal />}
            {openModal === modalTypes.CREATE_ANNOUNCEMENT_MODAL && <CreateAnnouncementModal />}
            {openModal === modalTypes.CHANGE_PASSWORD && <ChangePasswordModal />}
            {openModal === modalTypes.BALANCE_PAYMENT && <PaymentModal />}
            {openModal === modalTypes.ACCOUNTING_CONTACT && <AddAccountingContactModal />}
            {openModal === modalTypes.BIDDER_MESSAGE && <SendBidderMessageModal />}
            {openModal === modalTypes.BIDDER_MASS_FAVORITING && <BidderMassFavoritingModal />}
            {openModal === modalTypes.BIDDER_GUARANTEE_PROGRAM && <BidderGuaranteeProgramModal />}
            {openModal === modalTypes.BIDDER_GUARANTEE_PROGRAM_OPT_OUT_MODAL && <BidderGuaranteeProgramOptOutModal />}
            {openModal === modalTypes.LAP_MANDATE && <LapPaymentMandateModal />}
        </Suspense>
    );
};

export default Modals;
