import { AsyncThunkBaseConfig, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken, getUserHouseId } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    getHouseLocations,
    getHouseLocationValidation,
    GetHouseLocationValidationResponse,
    HouseLocationValidationError,
    postHouseLocation,
    putHouseLocation,
} from '@/redux/modules/houseLocations/houseLocations.api';
import { HouseLocation } from '@/redux/modules/houseLocations/houseLocations.types';

export const fetchHouseLocations = createAsyncThunk<HouseLocation[], number>(
    'la/domain/houseLocations/fetchHouseLocations',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const {
            payload: { houseLocations },
        } = await getHouseLocations({
            authToken,
            deployment,
            houseId,
        });
        return houseLocations;
    }
);

type SaveLocationSuccessResult = {
    houseId: number;
    location: HouseLocation;
};

export const createNewHouseLocation = createAsyncThunk<
    SaveLocationSuccessResult,
    HouseLocation,
    AsyncThunkBaseConfig & {
        rejectValue: string;
    }
>('la/domain/houseLocations/createNewHouseLocation', async (houseLocation, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const houseId = getUserHouseId(state);
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await postHouseLocation({
            authToken,
            deployment,
            houseId,
            houseLocation,
        });
        return {
            houseId,
            location: payload,
        };
    } catch {
        return rejectWithValue('CREATE_FAILURE');
    }
});

export const validateHouseLocationAddress = createAsyncThunk<
    GetHouseLocationValidationResponse['payload'],
    HouseLocation,
    AsyncThunkBaseConfig & {
        rejectValue: string;
    }
>('la/domain/houseLocations/validateHouseLocationAddress', async (houseLocation, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const result = await getHouseLocationValidation({
            authToken,
            deployment,
            houseLocation,
        });
        return result.payload;
    } catch (error) {
        if (Boolean(error.error?.code)) {
            return rejectWithValue((error as HouseLocationValidationError).error?.code);
        }
    }
});

export const editHouseLocation = createAsyncThunk<
    SaveLocationSuccessResult,
    HouseLocation,
    AsyncThunkBaseConfig & {
        rejectValue: string;
    }
>('la/domain/houseLocations/editHouseLocation', async (houseLocation, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const houseId = getUserHouseId(state);
        const result = await putHouseLocation({
            authToken,
            deployment,
            houseId,
            houseLocation,
        });
        return {
            houseId,
            location: result.payload,
        };
    } catch {
        return rejectWithValue('EDIT_FAILURE');
    }
});

export const archiveHouseLocation = createAsyncThunk<void, HouseLocation>(
    'la/domain/houseLocations/archiveHouseLocation',
    async (houseLocation, { dispatch }) => {
        const archivedHouseLocation: HouseLocation = {
            ...houseLocation,
            enabled: false,
        };
        await dispatch(editHouseLocation(archivedHouseLocation));
    }
);

export const restoreHouseLocation = createAsyncThunk<void, HouseLocation>(
    'la/domain/houseLocations/restoreHouseLocation',
    async (houseLocation, { dispatch }) => {
        const restoredHouseLocation: HouseLocation = {
            ...houseLocation,
            enabled: true,
        };
        await dispatch(editHouseLocation(restoredHouseLocation));
    }
);
