import { $Values } from 'utility-types';
import { BankAccountForm } from '../../types/Payment';
import { handleResponse, makeDelete, makeGet, makePost, makePut } from './helpers';
import { payoutType } from '../../enums/payment';

type GetBankAccountsApiParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const getBankAccounts = ({ authToken, deployment, houseId }: GetBankAccountsApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `sellers/bankaccounts/${houseId}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetBidderPaymentsParams = {
    authToken: string;
    deployment: string;
    from: string;
    houseId: number;
    keyword: string;
    page: number;
    pageSize: number;
    status: string;
    to: string;
};

export const getBidderPayments = ({
    authToken,
    deployment,
    from,
    houseId,
    keyword,
    page,
    pageSize,
    status,
    to,
}: GetBidderPaymentsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ apiPath: `house/${houseId}/payments`, authToken, deployment, path: '<PAYMENT-API>' });
        request.query({
            from,
            keyword,
            page,
            pageSize,
            status,
            to,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetDisbursementsParams = {
    authToken: string;
    deployment: string;
    from: string;
    houseId: number;
    keyword: string;
    page: number;
    pageSize: number;
    to: string;
};

export const getDisbursements = ({
    authToken,
    deployment,
    from,
    houseId,
    keyword,
    page,
    pageSize,
    to,
}: GetDisbursementsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/disbursements`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.query({
            from,
            keyword,
            page,
            pageSize,
            to,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBankAccountApiParams = {
    authToken: string;
    bankAccount: BankAccountForm;
    deployment: string;
    houseId: number;
    providerId: number;
};

export const postBankAccount = ({
    authToken,
    bankAccount,
    deployment,
    houseId,
    providerId,
}: PostBankAccountApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `sellers/bankaccounts/${houseId}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({
            ...bankAccount,
            providerId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeactivateBankAccountApiParams = {
    authToken: string;
    bankAccountId: number;
    deployment: string;
    houseId: number;
};

export const deactivateBankAccount = ({
    authToken,
    bankAccountId,
    deployment,
    houseId,
}: DeactivateBankAccountApiParams) =>
    new Promise<any>((resolve, reject) => {
        const providerId = 3; // TODO clean this provider id up.
        const request = makeDelete({
            apiPath: `sellers/bankaccounts/${houseId}/${bankAccountId}/${providerId}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type UpdateBankAccountApiParams = {
    authToken: string;
    bankAccountId: number;
    deployment: string;
    houseId: number;
};

export const putUpdatePrimaryBankAccount = ({
    authToken,
    bankAccountId,
    deployment,
    houseId,
}: UpdateBankAccountApiParams) =>
    new Promise<any>((resolve, reject) => {
        const providerId = 3;
        const request = makePut({
            apiPath: `sellers/bankaccounts/${houseId}/setprimary`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.send({
            // @ts-ignore
            bankAccountId: parseInt(bankAccountId),
            providerId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const getAccountSummary = ({ authToken, deployment, houseId }: GetAccountSummaryParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/payments/stats`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetAccountSummaryParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type GetHousePaymentRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    houseId: number;
    keyword: string;
    to: string;
};

export const getHousePaymentRecords = ({
    authToken,
    deployment,
    from,
    houseId,
    keyword,
    to,
}: GetHousePaymentRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/paymentrecords`,
            authToken,
            deployment,
            path: '<REDSTRIPE-API>',
        });
        request.query({ from, keyword, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetHouseDisbursementRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    houseId: number;
    to: string;
};

export const getHouseDisbursementRecords = ({
    authToken,
    deployment,
    from,
    houseId,
    to,
}: GetHouseDisbursementRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/disbursementrecords`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.query({ from, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });

type GetPayrixHouseAccountParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export const getPayrixHouseAccount = ({ authToken, deployment, houseId }: GetPayrixHouseAccountParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `payrix/houseaccount/${houseId}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostPayrixPayoutTypeParams = {
    authToken: string;
    deployment: string;
    entityId: string;
    houseId: number;
    payoutType: $Values<typeof payoutType>;
};

export const postPayrixPayoutType = ({
    authToken,
    deployment,
    entityId,
    houseId,
    payoutType,
}: PostPayrixPayoutTypeParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `payrix/houseaccount/${houseId}/payrixpayouttype/${payoutType}`,
            authToken,
            deployment,
            path: '<PAYMENT-API>',
        });
        request.query({ entityId });
        request.send({});
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
