import { createSelector } from '@reduxjs/toolkit';
import { LOAD_BATCH_END, LOAD_BATCH_START } from './actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
export const DEFAULT_STATE = {
    loading: [],
};

type State = typeof DEFAULT_STATE;

export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOAD_BATCH_END:
            return {
                ...state,
                loading: difference(state.loading, [action.payload]),
            };
        case LOAD_BATCH_START:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.fetchBatch;
const idSelector = (state, id) => id;

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const isDataLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

/* ACTION CREATORS */
export const loadBatchEnd = (loadingId: string) => ({
    payload: loadingId,
    type: LOAD_BATCH_END,
});

export const loadBatchStart = (loadingId: string) => ({
    payload: loadingId,
    type: LOAD_BATCH_START,
});
