import { Auction } from '../../types/UpcomingAuctions';
import { handleResponse, makeGet } from './helpers';

type UpcomingAuctionsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type UpcomingAuctionsResponsePayload = {
    error: boolean;
    payload: Auction[];
};

export const getUpcomingAuctions = ({ authToken, deployment, houseId }: UpcomingAuctionsParams) =>
    new Promise<UpcomingAuctionsResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/upcoming-auctions`,
        });
        request.end((err: boolean, response: UpcomingAuctionsResponsePayload) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: (body) => {
                    return {
                        payload: body.payload?.map(({ catalogId, label, saleStart, title }) => ({
                            label,
                            saleStart,
                            title,
                            value: catalogId,
                        })),
                    };
                },
            })
        );
    });
