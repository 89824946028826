import * as React from 'react';
import { allNavTabs, auctioneerNav, liveAuctionNav, NavTab, postAuctionNav, preAuctionNav } from './NavDefinitions';
import { fetchHouseBetaEnrollmentsIfNeeded, getHouseBetaEnrollments } from '@/redux/modules/houseBetaEnrollments';
import {
    getUserPermissions,
    isAuthenticated,
    isBrassOrStaffAdminSelector,
    userDataIsLoading,
} from '@/redux/modules/user';
import { pathTabsCheck, showNavForPathname, UserPermissionsType } from '@/utils/permissions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { userHasPermission } from '@/utils/user';
import catalogStatusEnums from '@/enums/catalogStatus';
import cloneDeep from 'lodash/cloneDeep';
import MessagesNav from './MessagesNav';
import NavMenuItem from '@/components/NavMenu/NavMenuItem';
import './NavigationBar.css';

const getTabsByStatus = (catalogStatus?: any): NavTab[] => {
    let navTabs: NavTab[];
    switch (catalogStatus) {
        case catalogStatusEnums.PRE_AUCTION:
            navTabs = preAuctionNav;
            break;
        case catalogStatusEnums.LIVE_AUCTION:
            navTabs = liveAuctionNav;
            break;
        case catalogStatusEnums.POST_AUCTION:
            navTabs = postAuctionNav;
            break;
        default:
            navTabs = auctioneerNav;
            break;
    }
    return navTabs;
};

const urlStringReplace = (str: any, classicHost: string, houseId: number, catalogId: number) => {
    if (!str) {
        return;
    }
    return str.replace('<CLASSIC-HOST>', classicHost).replace('<HOUSE-ID>', houseId).replace('<CATALOG-ID>', catalogId);
};

export interface NavigationBarProps {
    alwaysShow?: boolean;
    catalogId?: number;
    catalogStatus?: string;
    classicHost: string;
    houseId: number;
    pathname?: string;
    selected?: string;
    showVideo?: boolean;
}

const NavigationBar = ({
    alwaysShow = false,
    catalogId = 0,
    catalogStatus,
    classicHost,
    houseId,
    pathname,
    selected = undefined,
    showVideo = false,
}: NavigationBarProps) => {
    const dispatch = useAppDispatch();

    const { laPaymentsCatalogMandate, payrix_payments, paysafe_high_risk, paysafe_payments } =
        useAppSelector(getHouseBetaEnrollments);

    const isLapMandate = laPaymentsCatalogMandate && !(payrix_payments || paysafe_payments || paysafe_high_risk);
    const isAdmin = useAppSelector(isBrassOrStaffAdminSelector);
    const userPermissions = useAppSelector(getUserPermissions);
    const isLoadingUser = useAppSelector(userDataIsLoading);
    const isAuthed = useAppSelector(isAuthenticated);

    React.useEffect(() => {
        if (isAuthed && houseId) {
            dispatch(fetchHouseBetaEnrollmentsIfNeeded(houseId));
        }
    }, [dispatch, houseId, isAuthed]);

    const mapCatalogTabs = (
        tab: NavTab,
        selected: string,
        showVideo: boolean,
        classicHost: string,
        houseId: number,
        catalogId: number,
        isAdmin: boolean,
        userPermissions: UserPermissionsType
    ) => {
        if (!showVideo && tab.label === 'VIDEOSETUP') {
            return null;
        }

        return mapIndividualTab(tab, selected, classicHost, houseId, catalogId, isAdmin, userPermissions);
    };

    const mapIndividualTab = (
        tab: NavTab,
        selected: string | null | undefined,
        classicHost: string,
        houseId: number,
        catalogId: number,
        isAdmin: boolean,
        userPermissions: UserPermissionsType
    ) => {
        if (!isAdmin && !userHasPermission(userPermissions, tab.permissions)) {
            return;
        }

        const url = urlStringReplace(tab.url, classicHost, houseId, catalogId);

        if (tab.label === 'Auction Setup' && isLapMandate) {
            return null;
        }

        return tab.label !== 'Messages' ? (
            <NavMenuItem
                children={tab.children}
                isAdmin={isAdmin}
                key={`${tab.label}${tab.url}`}
                label={tab.label}
                selected={selected}
                url={url}
                userPermissions={userPermissions}
            />
        ) : (
            <MessagesNav
                children={tab.children}
                key={`${tab.label}${tab.url}`}
                label={tab.label}
                selected={selected}
                url={url}
            />
        );
    };

    if ((!alwaysShow && !showNavForPathname(pathname)) || isLoadingUser || !isAuthed) {
        return null;
    }
    const mapGlobalTabs = (
        tab: NavTab,
        catalogStatus: string,
        selected: string,
        classicHost: string,
        houseId: number,
        catalogId: number,
        isAdmin: boolean,
        userPermissions: UserPermissionsType
    ) => {
        let selectedTab = selected;

        if (catalogStatus) {
            switch (catalogStatus) {
                case catalogStatusEnums.PRE_AUCTION:
                    selectedTab = 'Pre-Auction';
                    break;
                case catalogStatusEnums.LIVE_AUCTION:
                    selectedTab = 'Live Auction';
                    break;
                case catalogStatusEnums.POST_AUCTION:
                    selectedTab = 'Post-Auction';
                    break;
                default:
            }
        }

        if (window.location.pathname + window.location.search === `/house/${houseId}/bidders?tab=auto`) {
            selectedTab = 'Automation Settings';
        }

        const newTab = cloneDeep(tab);
        if (newTab.children) {
            newTab.label = `${newTab.label} ▾`;
            newTab.children.map((x) => (x.url = urlStringReplace(x.url, classicHost, houseId, catalogId)));
        }

        return mapIndividualTab(newTab, selectedTab, classicHost, houseId, catalogId, isAdmin, userPermissions);
    };

    const globalTabs = getTabsByStatus();
    const catalogTabs = getTabsByStatus(catalogStatus);

    const selectedNav = selected ? selected : pathTabsCheck(allNavTabs, pathname)?.label;

    return (
        <>
            <div className="laTabs">
                {globalTabs.map((tab) =>
                    mapGlobalTabs(
                        tab,
                        catalogStatus,
                        selectedNav,
                        classicHost,
                        houseId,
                        catalogId,
                        isAdmin,
                        userPermissions
                    )
                )}
            </div>
            {catalogStatus && (
                <div className="laTabs">
                    {catalogTabs.map((tab) =>
                        mapCatalogTabs(
                            tab,
                            selectedNav,
                            showVideo,
                            classicHost,
                            houseId,
                            catalogId,
                            isAdmin,
                            userPermissions
                        )
                    )}
                </div>
            )}
        </>
    );
};

export default React.memo<NavigationBarProps>(NavigationBar);
