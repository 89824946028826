import {
    CREATE_DEDICATED_FOLLOWERS_FAIL,
    CREATE_DEDICATED_FOLLOWERS_FAIL_ACTION,
    CREATE_DEDICATED_FOLLOWERS_REQUEST,
    CREATE_DEDICATED_FOLLOWERS_SUCCESS,
    GET_DEDICATED_FOLLOWERS_FAIL,
    GET_DEDICATED_FOLLOWERS_FAIL_ACTION,
    GET_DEDICATED_FOLLOWERS_REQUEST,
    GET_DEDICATED_FOLLOWERS_SUCCESS,
    GET_DEDICATED_FOLLOWERS_SUCCESS_ACTION,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { DedicatedFollowers } from '../../types/DedicatedFollowers';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import api from '../api/dedicatedFollowers';

export type State = {
    apiError: string;
    dedicatedFollowers: DedicatedFollowers;
    error: boolean;
    isLoading: boolean;
};

export const DEFAULT_STATE: State = {
    apiError: '',
    dedicatedFollowers: {
        amount: 0,
        catalogId: 0,
        followerCount: 0,
        houseId: 0,
        lotIds: [],
        status: '',
    },
    error: false,
    isLoading: false,
};

/* REDUCER */

export const reducer = handleActions(
    {
        [CREATE_DEDICATED_FOLLOWERS_FAIL]: (state: State, action: CREATE_DEDICATED_FOLLOWERS_FAIL_ACTION): State => ({
            ...state,
            apiError: action.payload,
            error: true,
            isLoading: false,
        }),

        [CREATE_DEDICATED_FOLLOWERS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),

        [CREATE_DEDICATED_FOLLOWERS_SUCCESS]: (state: State): State => ({
            ...state,
            isLoading: false,
        }),

        [GET_DEDICATED_FOLLOWERS_FAIL]: (state: State, action: GET_DEDICATED_FOLLOWERS_FAIL_ACTION): State => ({
            ...state,
            apiError: action.payload,
            error: true,
            isLoading: false,
        }),

        [GET_DEDICATED_FOLLOWERS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),

        [GET_DEDICATED_FOLLOWERS_SUCCESS]: (state: State, action: GET_DEDICATED_FOLLOWERS_SUCCESS_ACTION): State => ({
            ...state,
            dedicatedFollowers: action.payload.dedicatedFollowers,
            isLoading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */

const stateSelector = (state: GlobalState): State => state.dedicatedFollowers;
export const lotIdsSelector = createSelector(stateSelector, (state) => state.dedicatedFollowers.lotIds);
export const statusSelector = createSelector(stateSelector, (state) => state.dedicatedFollowers.status);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

/* ACTION CREATORS */

export const fetchDedicatedFollowers = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: GET_DEDICATED_FOLLOWERS_REQUEST,
        });

        const response = await api.fetchDedicatedFollowers({
            authToken,
            catalogId,
            deployment,
        });

        dispatch({
            payload: { dedicatedFollowers: response.payload },
            type: GET_DEDICATED_FOLLOWERS_SUCCESS,
        } as GET_DEDICATED_FOLLOWERS_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            payload: error,
            type: GET_DEDICATED_FOLLOWERS_FAIL,
        } as GET_DEDICATED_FOLLOWERS_FAIL_ACTION);
    }
};

export const postDedicatedFollowers =
    (catalogId: number, followerCount: number, houseId: number, lotIds: number[]) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: CREATE_DEDICATED_FOLLOWERS_REQUEST,
            });

            const response = await api.postDedicatedFollowers({
                authToken,
                catalogId,
                deployment,
                followerCount,
                houseId,
                lotIds,
            });

            dispatch({
                payload: response,
                type: CREATE_DEDICATED_FOLLOWERS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                payload: error,
                type: CREATE_DEDICATED_FOLLOWERS_FAIL,
            } as CREATE_DEDICATED_FOLLOWERS_FAIL_ACTION);
        }
    };
