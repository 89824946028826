import { handleResponse, makeGet, makePost } from './helpers';
import type { BidIncrement } from '../../types/BidIncrement';
import type { Catalog } from '../../types/Catalog';
import type { Seller } from '../../types/Seller';

type FetchCatalogsByIdsParams = {
    authToken?: string;
    catalogIds: number[];
    deployment: string;
    forceNoCache?: boolean;
};

type FetchRegistrationCountParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

type PostCatalogItemsParams = {
    authToken: string;
    catalogId: number;
    count: any;
    cursor: any;
    deployment: string;
    itemId: number;
    page: number;
};

export type FetchCatalogsByIdsPayload = {
    data: {
        bidIncrements: BidIncrement[];
        catalogs: Catalog[];
        sellers: Seller[];
    };
};

export const fetchCatalogsByIds = ({ authToken, catalogIds, deployment, forceNoCache }: FetchCatalogsByIdsParams) =>
    new Promise<FetchCatalogsByIdsPayload>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/catalogs' });
        if (forceNoCache) {
            request.query({ forceNoCache });
        }
        request.send({ ids: catalogIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const fetchRegistrationCount = ({ authToken, catalogId, deployment }: FetchRegistrationCountParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ authToken, deployment, path: `<ITEM-API>registration-count/${catalogId}` });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postCatalogItems = ({
    authToken,
    catalogId,
    count,
    cursor,
    deployment,
    itemId,
    page,
}: PostCatalogItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/catalog/items' });
        const data: any = {};

        if (cursor) {
            data.cursor = cursor;
        }

        if (page) {
            data.page = page;
        }

        if (count) {
            data.pageSize = count;
        }

        if (itemId) {
            data.itemId = itemId;
        }

        // @ts-ignore
        if (global.__SERVER__ && itemId) {
            data.pageSize = 4;
            data.wrap = true;
            // @ts-ignore
        } else if (global.__SERVER__) {
            data.cursor = (page - 1) * count;
        }
        request.type('form');
        request.send({ catalogId, ...data });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostChangeStartTimeParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    newStartTs: number;
    sellerId: number;
};

export const postChangeStartTime = ({
    authToken,
    catalogId,
    deployment,
    newStartTs,
    sellerId,
}: PostChangeStartTimeParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${sellerId}/catalog/${catalogId}/start-time`,
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.send({ startDateTs: newStartTs / 1000 });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
