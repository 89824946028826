import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { fetchCatalogItemStats } from '../api/dashboard';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_ITEM_STATS_DATA_FAIL, LOAD_ITEM_STATS_DATA_REQUEST, LOAD_ITEM_STATS_DATA_SUCCESS } from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';

/* reducer */
export const DEFAULT_STATE = {
    byCatalogId: {},
    filters: {
        page: 1,
        pageSize: 24,
        totalRecords: 0,
    },
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_ITEM_STATS_DATA_FAIL]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            // @ts-ignore
            loading: difference(state.loading, action.meta.catalogId),
        }),
        [LOAD_ITEM_STATS_DATA_REQUEST]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            loading: [...state.loading, action.meta.catalogId],
        }),
        [LOAD_ITEM_STATS_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                { data: any; page: number; pageSize: number; totalRecords: number },
                { actionTime: number; catalogId: number }
            >
        ) => {
            const existing = cloneDeep(state.byCatalogId);
            // @ts-ignore
            const loading = difference(state.loading, action.meta.catalogId);
            const loaded = cloneDeep(state.loaded);
            const time = action.meta.actionTime;

            if (Boolean(action.payload) && Boolean(action.payload.data)) {
                existing[action.meta.catalogId] = action.payload.data;
                loaded[action.meta.catalogId] = time;
            }

            let { page, pageSize, totalRecords } = action.payload;
            const filters = { page, pageSize, totalRecords };

            if (Boolean(action.payload) && Boolean(action.payload.totalRecords)) {
                totalRecords = action.payload.totalRecords;
            }

            return {
                ...state,
                byCatalogId: existing,
                filters,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.itemStats;
const idSelector = (state, id) => id;

export const byCatalogIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);

export const filtersSelector = createSelector(stateSelector, (state) => state.filters);

export const itemStatsByCataglogIdSelector = createSelector(
    [byCatalogIdSelector, idSelector],
    (events, catalogId) => events[catalogId] || []
);

/* ACTION CREATORS */
const loadItemStats =
    (catalogId: number, houseId: number, page: number, pageSize: number, sort: string) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                meta: { catalogId },
                payload: { catalogId },
                type: LOAD_ITEM_STATS_DATA_REQUEST,
            });
            const response = await fetchCatalogItemStats({
                authToken,
                catalogId,
                deployment,
                houseId,
                page,
                pageSize,
                sort,
            });
            return dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response,
                type: LOAD_ITEM_STATS_DATA_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { catalogId },
                payload: error,
                type: LOAD_ITEM_STATS_DATA_FAIL,
            });
        }
    };

export const fetchItemStatsIfNeeded =
    (catalogId: number, houseId: number, page: number, pageSize: number, sort: string) =>
    async (dispatch: Function) => {
        return dispatch(loadItemStats(catalogId, houseId, page, pageSize, sort));
    };
