import { makeGet } from './helpers';

type GetWinningBiddersRecordsParams = {
    authToken: string;
    deployment: string;
    from: string;
    houseId: number;
    to: string;
};

export const getWinningBiddersRecords = ({
    authToken,
    deployment,
    from,
    houseId,
    to,
}: GetWinningBiddersRecordsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `report/house/${houseId}/bidders/winning/csv`,
            authToken,
            deployment,
            path: '<HOUSEREPORT-API>',
        });
        request.query({ from, to });
        request.responseType('blob');
        request.end((err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });
