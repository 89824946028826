import * as React from 'react';
import {
    fetchUserData,
    getAmcToken,
    getAuthToken,
    getSessionKey,
    getUserData,
    getUserType,
    setPermissionsFromToken,
} from '@/redux/modules/user';
import { recordAnalyticsForIdentify } from '@/redux/modules/analytics';
import { setAmcStaffCookie, setAuthCookie, setSessionKeyCookie } from '@/utils/cookies';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import isEmpty from 'lodash/isEmpty';

const getExp = (token: string = '') => {
    const splitToken = token.split('.');
    if (splitToken.length < 2) {
        console.warn('Failed to decode JWT expiration');
        return;
    }
    try {
        const payload = JSON.parse(atob(splitToken[1]));
        return new Date(payload.exp * 1000);
    } catch (e) {
        console.warn('Failed to decode JWT expiration', e);
    }
};

const useAuthenticateSession = () => {
    const dispatch = useAppDispatch();
    const amcToken = useAppSelector(getAmcToken);
    const authToken = useAppSelector(getAuthToken);
    const sessionKey = useAppSelector(getSessionKey);
    const userData = useAppSelector(getUserData);
    const userType = useAppSelector(getUserType);

    React.useEffect(() => {
        if (authToken) {
            dispatch(fetchUserData());
            dispatch(setPermissionsFromToken());
            const expires = getExp(authToken);
            setAuthCookie(authToken, expires);
        }
    }, [authToken, dispatch]);

    React.useEffect(() => {
        if (!isEmpty(userData) && userType) {
            dispatch(recordAnalyticsForIdentify(userData, userType));
        }
    }, [dispatch, userData, userType]);

    React.useEffect(() => {
        if (Boolean(amcToken)) {
            setAmcStaffCookie(amcToken);
        }
    }, [amcToken]);

    React.useEffect(() => {
        if (Boolean(sessionKey)) {
            setSessionKeyCookie(sessionKey);
        }
    }, [sessionKey]);

    return Boolean(authToken);
};

export default useAuthenticateSession;
