import * as React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import isEqual from 'react-fast-compare';
import PageHeaderMeta from '@liveauctioneers/caterwaul-components/lib/PageHeaderMeta/PageHeaderMeta';
import styled, { useTheme } from 'styled-components';
import './Layout.css';

interface LayoutProps {
    children: React.ReactNode;
    houseId: number;
    showHeaderAndFooter?: boolean;
}
const Layout = ({ children, houseId, showHeaderAndFooter }: LayoutProps) => {
    const {
        colors: { blue100 },
    } = useTheme();

    return (
        <>
            <PageHeaderMeta
                canonical="/"
                title="LiveAuctioneers Partners"
            >
                <link
                    href="/apple-touch-icon.png"
                    rel="apple-touch-icon"
                    sizes="180x180"
                />
                <link
                    href="/favicon-32x32.png"
                    rel="icon"
                    sizes="32x32"
                    type="image/png"
                />
                <link
                    href="/favicon-16x16.png"
                    rel="icon"
                    sizes="16x16"
                    type="image/png"
                />
                <link
                    href="/site.webmanifest"
                    rel="manifest"
                />
                <meta
                    content={blue100}
                    name="msapplication-TileColor"
                />
                <meta
                    content={blue100}
                    name="theme-color"
                />
            </PageHeaderMeta>
            {showHeaderAndFooter && <Header houseId={houseId} />}
            <MainWrapper>
                <MainContent>{children}</MainContent>
            </MainWrapper>
            {showHeaderAndFooter && (
                <FooterContainer>
                    <Footer />
                </FooterContainer>
            )}
        </>
    );
};

export default React.memo<LayoutProps>(Layout, isEqual);

const FooterContainer = styled.div`
    && {
        @media print {
            display: none;
        }
    }
`;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`;

const MainContent = styled.main`
    max-width: ${({ theme }) => theme.breakpoints.pageWidth};
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    min-height: 750px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        padding: 0 10px;
    }
`;
