import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, getUserData } from './user';
import { getCatalog } from './catalog';
import { getDeployment, getPubSubMessagingProvider } from '@/redux/modules/config';
import { getItem } from './item';
import { getLiveItemData } from './console';
import { GlobalState } from '../rootReducer';
import { handleCatalogOnMessage } from './liveAuctionEvents';
import {
    LIVE_LOT_REOPENED,
    LIVE_NEXT_LOT_LOADED,
    SEND_CHANGE_BID_INCREMENT_FAILURE,
    SEND_CHANGE_BID_INCREMENT_REQUEST,
    SEND_CHANGE_BID_INCREMENT_SUCCESS,
    SEND_CHANGE_CURRENT_ASK_FAILURE,
    SEND_CHANGE_CURRENT_ASK_REQUEST,
    SEND_CHANGE_CURRENT_ASK_SUCCESS,
    SEND_CHANGE_CURRENT_LOT_FAILURE,
    SEND_CHANGE_CURRENT_LOT_REQUEST,
    SEND_CHANGE_CURRENT_LOT_SUCCESS,
    SEND_CLOSE_AUCTION_FAILURE,
    SEND_CLOSE_AUCTION_REQUEST,
    SEND_CLOSE_AUCTION_SUCCESS,
    SEND_COMPETING_BID_FAILURE,
    SEND_COMPETING_BID_REQUEST,
    SEND_COMPETING_BID_REQUEST_ACTION,
    SEND_COMPETING_BID_SUCCESS,
    SEND_INTERNET_BID_FAILURE,
    SEND_INTERNET_BID_REQUEST,
    SEND_INTERNET_BID_REQUEST_ACTION,
    SEND_INTERNET_BID_SUCCESS,
    SEND_LOAD_AUCTION_FAILURE,
    SEND_LOAD_AUCTION_FAILURE_ACTION,
    SEND_LOAD_AUCTION_REQUEST,
    SEND_LOAD_AUCTION_SUCCESS,
    SEND_LOT_PASSED_FAILURE,
    SEND_LOT_PASSED_REQUEST,
    SEND_LOT_PASSED_SUCCESS,
    SEND_LOT_REOPEN_FAILURE,
    SEND_LOT_REOPEN_REQUEST,
    SEND_LOT_REOPEN_SUCCESS,
    SEND_LOT_SKIPPED_FAILURE,
    SEND_LOT_SKIPPED_REQUEST,
    SEND_LOT_SKIPPED_SUCCESS,
    SEND_LOT_SOLD_FAILURE,
    SEND_LOT_SOLD_REQUEST,
    SEND_LOT_SOLD_SUCCESS,
    SEND_MISSIVE_FAILURE,
    SEND_MISSIVE_REQUEST,
    SEND_MISSIVE_SUCCESS,
    SEND_OPEN_AUCTION_FAILURE,
    SEND_OPEN_AUCTION_REQUEST,
    SEND_OPEN_AUCTION_SUCCESS,
    SEND_OPEN_NEXT_LOT_FAILURE,
    SEND_OPEN_NEXT_LOT_REQUEST,
    SEND_OPEN_NEXT_LOT_SUCCESS,
    SEND_PAUSE_AUCTION_FAILURE,
    SEND_PAUSE_AUCTION_REQUEST,
    SEND_PAUSE_AUCTION_SUCCESS,
    SEND_RESUME_AUCTION_FAILURE,
    SEND_RESUME_AUCTION_REQUEST,
    SEND_RESUME_AUCTION_SUCCESS,
} from './actions';
import {
    postAcceptBid,
    postCloseAuction,
    postLoadAuction,
    postNewSFSReopenLot,
    postOpenAuction,
    postOpenLot,
    postOpenNextLot,
    postPassLot,
    postPauseAuction,
    postReopenLot,
    postResumeAuction,
    postSendMissive,
    postSetAsk,
    postSetIncrement,
    postSkipLot,
    postSoldLot,
} from '../api/clerk';
import type { BidSource } from '@/types/BidSource';

type SubmittingBid = {
    amount: number;
    itemId: number;
};

export type State = {
    changeAskPriceSubmitting: boolean;
    changeBidIncrementSubmitting: boolean;
    competingBidSubmitting: boolean;
    internetBidSubmitting: boolean;
    loadAuctionError: string;
    loadAuctionSubmitting: boolean;
    loadAuctionSuccess: boolean;
    lotSoldSubmitting: boolean;
    missiveSubmitting: boolean;
    nextLotError: string;
    nextLotSubmitting: boolean;
    submittingBid: SubmittingBid | null;
};

/* reducer */
export const DEFAULT_STATE: State = {
    changeAskPriceSubmitting: false,
    changeBidIncrementSubmitting: false,
    competingBidSubmitting: false,
    internetBidSubmitting: false,
    loadAuctionError: '',
    loadAuctionSubmitting: false,
    loadAuctionSuccess: false,
    lotSoldSubmitting: false,
    missiveSubmitting: false,
    nextLotError: '',
    nextLotSubmitting: false,
    submittingBid: null,
};

export const reducer = handleActions(
    {
        [combineActions(SEND_COMPETING_BID_FAILURE, SEND_COMPETING_BID_SUCCESS)]: (state: State) => ({
            ...state,
            competingBidSubmitting: false,
            submittingBid: null,
        }),
        [SEND_CHANGE_BID_INCREMENT_REQUEST]: (state: State): State => ({
            ...state,
            changeBidIncrementSubmitting: true,
        }),
        [SEND_CHANGE_CURRENT_ASK_REQUEST]: (state: State): State => ({
            ...state,
            changeAskPriceSubmitting: true,
        }),
        [combineActions(SEND_CHANGE_CURRENT_ASK_FAILURE, SEND_CHANGE_CURRENT_ASK_SUCCESS)]: (state: State): State => ({
            ...state,
            changeAskPriceSubmitting: false,
        }),
        [combineActions(SEND_CHANGE_BID_INCREMENT_FAILURE, SEND_CHANGE_BID_INCREMENT_SUCCESS)]: (
            state: State
        ): State => ({
            ...state,
            changeBidIncrementSubmitting: false,
        }),
        [SEND_COMPETING_BID_REQUEST]: (state: State, action: SEND_COMPETING_BID_REQUEST_ACTION) => ({
            ...state,
            competingBidSubmitting: true,
            submittingBid: action.payload,
        }),
        [combineActions(SEND_INTERNET_BID_FAILURE, SEND_INTERNET_BID_SUCCESS)]: (state: State) => ({
            ...state,
            internetBidSubmitting: false,
            submittingBid: null,
        }),
        [SEND_INTERNET_BID_REQUEST]: (state: State, action: SEND_INTERNET_BID_REQUEST_ACTION) => ({
            ...state,
            internetBidSubmitting: true,
            submittingBid: action.payload,
        }),
        [combineActions(SEND_LOT_SOLD_FAILURE, SEND_LOT_SOLD_SUCCESS)]: (state: State) => ({
            ...state,
            lotSoldSubmitting: false,
        }),
        [SEND_LOAD_AUCTION_FAILURE]: (state: State, action: SEND_LOAD_AUCTION_FAILURE_ACTION) => ({
            ...state,
            loadAuctionError: action.payload,
            loadAuctionSubmitting: false,
            loadAuctionSuccess: false,
        }),
        [SEND_LOAD_AUCTION_REQUEST]: (state: State) => ({
            ...state,
            loadAuctionError: false,
            loadAuctionSubmitting: true,
            loadAuctionSuccess: false,
        }),
        [SEND_LOAD_AUCTION_SUCCESS]: (state: State) => ({
            ...state,
            loadAuctionError: false,
            loadAuctionSubmitting: false,
            loadAuctionSuccess: true,
        }),
        [SEND_LOT_SOLD_REQUEST]: (state: State) => ({
            ...state,
            lotSoldSubmitting: true,
        }),
        [combineActions(SEND_MISSIVE_FAILURE, SEND_MISSIVE_SUCCESS)]: (state: State) => ({
            ...state,
            missiveSubmitting: false,
        }),
        [SEND_MISSIVE_REQUEST]: (state: State) => ({
            ...state,
            missiveSubmitting: true,
        }),
        [combineActions(
            LIVE_LOT_REOPENED,
            LIVE_NEXT_LOT_LOADED,
            SEND_OPEN_NEXT_LOT_FAILURE,
            SEND_OPEN_NEXT_LOT_SUCCESS
        )]: (state: State): State => ({
            ...state,
            nextLotSubmitting: false,
        }),
        [SEND_OPEN_NEXT_LOT_FAILURE]: (state: State, action: ActionWithPayload<string>): State => {
            // Make sure error returned from submitNextLot() is a string from the api call instead of a new Error() from a thrown exception
            if (typeof action.payload === 'string') {
                return {
                    ...state,
                    nextLotError: action.payload,
                    nextLotSubmitting: false,
                };
            } else {
                return { ...state };
            }
        },
        [SEND_OPEN_NEXT_LOT_REQUEST]: (state: State): State => ({
            ...state,
            nextLotError: '',
            nextLotSubmitting: true,
        }),
        [SEND_OPEN_NEXT_LOT_SUCCESS]: (state: State): State => ({
            ...state,
            nextLotError: '',
            nextLotSubmitting: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.clerkButtons;

export const getClerkButtonsUIState = createSelector(stateSelector, (state) => state);
export const getLoadAuctionError = createSelector(stateSelector, (state) => state.loadAuctionError);
export const getLoadAuctionSubmitting = createSelector(stateSelector, (state) => state.loadAuctionSubmitting);
export const getLoadAuctionSuccess = createSelector(stateSelector, (state) => state.loadAuctionSuccess);

/**
 * returns true if an "all lots have been closed" error was returned from submitted next lot
 *
 * used to tell the console to end the auction
 */
export const getAllLotsHaveBeenClosed = createSelector(stateSelector, (state) =>
    state.nextLotError.includes('all lots have been closed')
);

const clerkButtonTests = {
    changeAsk: 0,
    changeIncrement: 0,
    changeLot: 0,
    closeAuction: 0,
    competingBid: 0,
    internetBid: 0,
    newSFSReopenLot: 0,
    nextLot: 0,
    openAuction: 0,
    passLot: 0,
    pauseAuction: 0,
    reopenLot: 0,
    resumeAuction: 0,
    skipLot: 0,
    soldLot: 0,
};

const resetTimer = (action: string) => {
    const responseTime = performance.now() - clerkButtonTests[action];
    if (clerkButtonTests[action] && responseTime > 500) {
        console.error(`Attempting to ${action} took longer than 500ms - ${responseTime}`);
    }
    clerkButtonTests[action] = 0;
};

const checkTimer = (action: string, catalogId: number, userData: ReturnType<typeof getUserData>) => {
    const responseTime = performance.now() - clerkButtonTests[action];
    if (clerkButtonTests[action] && responseTime > 500) {
        console.error(`Attempting to ${action} took longer than 500ms - ${responseTime}`);

        try {
            const user = { email: userData?.email, id: userData?.houseId || 'brass', username: userData?.username };

            // Get the last network performance entry for auction-service api
            const entry = performance
                .getEntriesByType('resource')
                .filter((timing) => timing.name.includes('auction-service'))
                .slice(-1)[0] as PerformanceResourceTiming;

            const dnsLookup = Math.floor(entry?.domainLookupEnd - entry?.domainLookupStart);
            const duration = Math.floor(entry?.duration);
            const connectTime = Math.floor(entry?.connectEnd - entry?.connectStart);
            const fetchTime = Math.floor(entry?.responseEnd - entry?.fetchStart);
            const endpoint = entry?.name;

            console.error(`API call over 500ms on catalog ${catalogId}`, {
                extra: {
                    connectTime,
                    dnsLookup,
                    duration,
                    endpoint,
                    fetchTime,
                    ...user,
                },
                tags: {
                    action,
                    catalogId,
                    category: 'performance',
                    connectTime,
                    dnsLookup,
                    fetchTime,
                },
            });
        } catch (e) {
            console.log('error capturing borked with error: ', e);
        }
    }
    clerkButtonTests[action] = 0;
};

const startTimer = (action: string) => {
    clerkButtonTests[action] = performance.now();
};

/* ACTION CREATORS */
export const submitChangeCurrentAsk =
    (newAsk: number, catalogId: number, itemId: number, previousAsk: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);
            const currentProvider = getPubSubMessagingProvider(state);

            dispatch({
                payload: { ask: newAsk, catalogId, itemId },
                type: SEND_CHANGE_CURRENT_ASK_REQUEST,
            });

            startTimer('changeAsk');
            const response = await postSetAsk({ ask: newAsk, authToken, catalogId, deployment, itemId, sellerId });
            checkTimer('changeAsk', catalogId, userData);

            // Update console state with new live auction event from api for speed, instead of waiting for a websocket message
            const message = response.payload;

            // Process live auction event into redux
            await dispatch(handleCatalogOnMessage(catalogId, 'clerk-button', currentProvider)(message));

            dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_CHANGE_CURRENT_ASK_SUCCESS,
            });
        } catch (error) {
            const state = getState();
            const { currency } = getCatalog(state, catalogId);
            const { lotNumber } = getItem(state, itemId);
            resetTimer('changeAsk');

            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), ask: newAsk, catalogId, currency, itemId, lotNumber, previousAsk },
                payload: error,
                type: SEND_CHANGE_CURRENT_ASK_FAILURE,
            });
        }
    };

export const submitChangeIncrement =
    (catalogId: number, increment: number, itemId: number, previousIncrement: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);

            dispatch({
                payload: { catalogId, increment, itemId },
                type: SEND_CHANGE_BID_INCREMENT_REQUEST,
            });

            startTimer('changeIncrement');
            const response = await postSetIncrement({ authToken, catalogId, deployment, increment, itemId, sellerId });
            checkTimer('changeIncrement', catalogId, userData);

            return dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_CHANGE_BID_INCREMENT_SUCCESS,
            });
        } catch (error) {
            const state = getState();
            const { currency } = getCatalog(state, catalogId);
            const { lotNumber } = getItem(state, itemId);
            resetTimer('changeIncrement');

            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId, currency, increment, itemId, lotNumber, previousIncrement },
                payload: error,
                type: SEND_CHANGE_BID_INCREMENT_FAILURE,
            });
        }
    };

export const submitChangeLot =
    (catalogId: number, currentItemId: number, lotNumber: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);

            dispatch({
                type: SEND_CHANGE_CURRENT_LOT_REQUEST,
            });

            startTimer('changeLot');
            const response = await postOpenLot({
                authToken,
                catalogId,
                deployment,
                itemId: currentItemId,
                lotNumber,
                sellerId,
            });
            checkTimer('changeLot', catalogId, userData);

            return dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_CHANGE_CURRENT_LOT_SUCCESS,
            });
        } catch (error) {
            let payload = {};
            if (
                typeof error === 'object' &&
                'payload' in error &&
                typeof error.payload === 'object' &&
                'msg' in error.payload
            ) {
                payload = error.payload;
            } else if (typeof error === 'object' && 'payload' in error && typeof error.payload === 'string') {
                payload = { msg: error.payload };
            } else if (typeof error === 'string') {
                payload = { msg: error };
            }
            resetTimer('changeLot');
            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId, lotNumber },
                payload,
                type: SEND_CHANGE_CURRENT_LOT_FAILURE,
            });
        }
    };

export const submitCloseAuction = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_CLOSE_AUCTION_REQUEST,
        });

        startTimer('closeAuction');
        const response = await postCloseAuction({ authToken, catalogId, deployment, sellerId });
        checkTimer('closeAuction', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_CLOSE_AUCTION_SUCCESS,
        });
    } catch (error) {
        resetTimer('closeAuction');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId },
            payload: error,
            type: SEND_CLOSE_AUCTION_FAILURE,
        });
    }
};

export const submitCompetingBid =
    (amount: number, catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);
            const currentProvider = getPubSubMessagingProvider(state);

            dispatch({
                payload: {
                    amount,
                    itemId,
                },
                type: SEND_COMPETING_BID_REQUEST,
            });

            startTimer('competingBid');
            const response = await postAcceptBid({
                amount,
                assignedId: 0,
                authToken,
                catalogId,
                deployment,
                itemId,
                sellerId,
                source: 'Floor',
            });

            // Update console state with new live auction event from api for speed, instead of waiting for a websocket message
            const message = response.payload;

            // Process live auction event into redux
            await dispatch(handleCatalogOnMessage(catalogId, 'clerk-button', currentProvider)(message));

            dispatch({
                meta: { catalogId },
                type: SEND_COMPETING_BID_SUCCESS,
            });

            checkTimer('competingBid', catalogId, userData);
        } catch (error) {
            const state = getState();
            const { currency } = getCatalog(state, catalogId);
            const { lotNumber } = getItem(state, itemId);
            resetTimer('competingBid');

            return dispatch({
                error: true,
                meta: {
                    actionTime: Date.now(),
                    amount,
                    catalogId,
                    currency,
                    itemId,
                    lotNumber,
                    previousAsk: amount,
                },
                payload: error,
                type: SEND_COMPETING_BID_FAILURE,
            });
        }
    };

export const submitInternetBid =
    (amount: number, assignedId: number, catalogId: number, itemId: number, source: BidSource) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);
            const currentProvider = getPubSubMessagingProvider(state);

            dispatch({
                payload: {
                    amount,
                    itemId,
                },
                type: SEND_INTERNET_BID_REQUEST,
            });

            startTimer('internetBid');
            const response = await postAcceptBid({
                amount,
                assignedId,
                authToken,
                catalogId,
                deployment,
                itemId,
                sellerId,
                source,
            });

            // Update console state with new bid-accepted message from api for speed, instead of waiting for websocket
            const pubnubEvent = response.payload;

            // Process auction event into redux
            await dispatch(handleCatalogOnMessage(catalogId, 'clerk-button', currentProvider)(pubnubEvent));

            dispatch({
                meta: { catalogId },
                type: SEND_INTERNET_BID_SUCCESS,
            });

            checkTimer('internetBid', catalogId, userData);
        } catch (error) {
            const state = getState();
            const { currency } = getCatalog(state, catalogId);
            const { lotNumber } = getItem(state, itemId);
            resetTimer('internetBid');

            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), amount, catalogId, currency, lotNumber },
                payload: error,
                type: SEND_INTERNET_BID_FAILURE,
            });
        }
    };

export const submitMissiveMessage =
    (catalogId: number, missiveText: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);

            dispatch({
                type: SEND_MISSIVE_REQUEST,
            });

            const response = await postSendMissive({ authToken, catalogId, deployment, missiveText, sellerId });

            return dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_MISSIVE_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId },
                payload: error,
                type: SEND_MISSIVE_FAILURE,
            });
        }
    };

export const submitNextLot = (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_OPEN_NEXT_LOT_REQUEST,
        });

        startTimer('nextLot');
        const response = await postOpenNextLot({ authToken, catalogId, deployment, itemId, sellerId });
        checkTimer('nextLot', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_OPEN_NEXT_LOT_SUCCESS,
        });
    } catch (error) {
        let errorMessage = '';
        let currentState = null;
        if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
            currentState = error.meta.currentState;
            errorMessage = error.payload;
        }
        resetTimer('nextLot');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId, currentState },
            payload: errorMessage || error,
            type: SEND_OPEN_NEXT_LOT_FAILURE,
        });
    }
};

export const submitLoadAuction = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_LOAD_AUCTION_REQUEST,
        });

        startTimer('loadAuction');
        const response = await postLoadAuction({ authToken, catalogId, deployment, sellerId });
        checkTimer('loadAuction', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_LOAD_AUCTION_SUCCESS,
        });
    } catch (error) {
        resetTimer('loadAuction');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId },
            payload: error,
            type: SEND_LOAD_AUCTION_FAILURE,
        });
    }
};

export const submitOpenAuction = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_OPEN_AUCTION_REQUEST,
        });

        startTimer('openAuction');
        const response = await postOpenAuction({ authToken, catalogId, deployment, sellerId });
        checkTimer('openAuction', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_OPEN_AUCTION_SUCCESS,
        });
    } catch (error) {
        resetTimer('openAuction');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId },
            payload: error,
            type: SEND_OPEN_AUCTION_FAILURE,
        });
    }
};

export const submitPassLot = (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_LOT_PASSED_REQUEST,
        });

        startTimer('passLot');
        const response = await postPassLot({ authToken, catalogId, deployment, itemId, sellerId });
        checkTimer('passLot', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_LOT_PASSED_SUCCESS,
        });
    } catch (error) {
        const state = getState();
        const { lotNumber } = getItem(state, itemId);

        let currentState = null;
        if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
            currentState = error.meta.currentState;
        }
        resetTimer('passLot');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId, currentState, lotNumber },
            payload: error,
            type: SEND_LOT_PASSED_FAILURE,
        });
    }
};

export const submitPauseAuction = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            meta: { catalogId },
            type: SEND_PAUSE_AUCTION_REQUEST,
        });

        startTimer('pauseAuction');
        const response = await postPauseAuction({ authToken, catalogId, deployment, sellerId });
        checkTimer('pauseAuction', catalogId, userData);

        dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_PAUSE_AUCTION_SUCCESS,
        });
        alert('The Auction is Paused.  Click OK to resume the auction.');

        return dispatch(submitResumeAuction(catalogId));
    } catch (error) {
        resetTimer('pauseAuction');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId },
            payload: error,
            type: SEND_PAUSE_AUCTION_FAILURE,
        });
    }
};

export const submitNewSFSReopenLot =
    (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);

            dispatch({
                type: SEND_LOT_REOPEN_REQUEST,
            });

            startTimer('newSFSReopenLot');
            const response = await postNewSFSReopenLot({ authToken, catalogId, deployment, itemId, sellerId });
            checkTimer('newSFSReopenLot', catalogId, userData);

            return dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_LOT_REOPEN_SUCCESS,
            });
        } catch (error) {
            const state = getState();
            const { lotNumber } = getItem(state, itemId);

            let currentState = null;
            if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
                currentState = error.meta.currentState;
            }
            resetTimer('newSFSReopenLot');
            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId, currentState, lotNumber },
                payload: error,
                type: SEND_LOT_REOPEN_FAILURE,
            });
        }
    };

export const submitReopenLot =
    (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);
            const userData = getUserData(state);

            dispatch({
                type: SEND_LOT_REOPEN_REQUEST,
            });

            startTimer('reopenLot');
            const response = await postReopenLot({ authToken, catalogId, deployment, itemId, sellerId });
            checkTimer('reopenLot', catalogId, userData);

            return dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_LOT_REOPEN_SUCCESS,
            });
        } catch (error) {
            const state = getState();
            const { lotNumber } = getItem(state, itemId);

            let currentState = null;
            if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
                currentState = error.meta.currentState;
            }
            resetTimer('reopenLot');
            return dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId, currentState, lotNumber },
                payload: error,
                type: SEND_LOT_REOPEN_FAILURE,
            });
        }
    };

export const submitResumeAuction = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            meta: { catalogId },
            type: SEND_RESUME_AUCTION_REQUEST,
        });

        startTimer('resumeAuction');
        const response = await postResumeAuction({ authToken, catalogId, deployment, sellerId });
        checkTimer('resumeAuction', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_RESUME_AUCTION_SUCCESS,
        });
    } catch (error) {
        resetTimer('resumeAuction');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId },
            payload: error,
            type: SEND_RESUME_AUCTION_FAILURE,
        });
    }
};

export const submitSkipLot = (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        dispatch({
            type: SEND_LOT_SKIPPED_REQUEST,
        });

        startTimer('skipLot');
        const response = await postSkipLot({ authToken, catalogId, deployment, itemId, sellerId });
        checkTimer('skipLot', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_LOT_SKIPPED_SUCCESS,
        });
    } catch (error) {
        const state = getState();
        const { lotNumber } = getItem(state, itemId);

        let currentState = null;
        if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
            currentState = error.meta.currentState;
        }
        resetTimer('skipLot');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId, currentState, lotNumber },
            payload: error,
            type: SEND_LOT_SKIPPED_FAILURE,
        });
    }
};

export const submitSoldLot = (catalogId: number, itemId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const { sellerId } = getCatalog(state, catalogId);
        const deployment = getDeployment(state);
        const userData = getUserData(state);

        const liveItemData = getLiveItemData(state, itemId);
        const { assignedId, leadingBid, leadingBidder, source } = liveItemData;

        dispatch({
            type: SEND_LOT_SOLD_REQUEST,
        });

        startTimer('soldLot');
        const response = await postSoldLot({
            amount: leadingBid,
            assignedId: Number(assignedId),
            authToken,
            bidderId: leadingBidder,
            catalogId,
            deployment,
            itemId,
            sellerId,
            source,
        });
        checkTimer('soldLot', catalogId, userData);

        return dispatch({
            meta: { catalogId },
            payload: response.payload,
            type: SEND_LOT_SOLD_SUCCESS,
        });
    } catch (error) {
        const state = getState();
        const { currency } = getCatalog(state, catalogId);
        const { lotNumber } = getItem(state, itemId);

        let currentState = null;
        if (typeof error == 'object' && 'meta' in error && 'currentState' in error.meta) {
            currentState = error.meta.currentState;
        }

        let payload = {};
        if (
            typeof error === 'object' &&
            'payload' in error &&
            typeof error.payload === 'object' &&
            'msg' in error.payload
        ) {
            payload = error.payload;
        } else if (typeof error === 'object' && 'payload' in error && typeof error.payload === 'string') {
            payload = { msg: error.payload };
        } else if (typeof error === 'string') {
            payload = { msg: error };
        }
        resetTimer('soldLot');
        return dispatch({
            error: true,
            meta: { actionTime: Date.now(), catalogId, currency, currentState, itemId, lotNumber },
            payload,
            type: SEND_LOT_SOLD_FAILURE,
        });
    }
};
