import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

export interface NavMenuProps {
    children: (React.ReactElement | string)[] | (React.ReactElement | string);
    selected?: boolean;
    url: string;
}

export const NavMenuLink = (props: NavMenuProps) => {
    const { children, selected = false, url } = props;

    return (
        <StyledLink
            selected={selected}
            to={url}
        >
            <StyledLinkText secondary>{children}</StyledLinkText>
        </StyledLink>
    );
};

export default NavMenuLink;

const selectedLinkFragment = css`
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 1px solid ${({ theme }) => theme.colors.black};
`;

const StyledLink = styled(Link)<{ selected?: boolean }>`
    background-color: ${({ theme }) => theme.colors.grey200};
    border: 1px solid ${({ theme }) => theme.colors.grey200};
    border-radius: 0;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    margin: 0 0 5px 0;
    padding: 0 7px;
    cursor: pointer;
    height: 22px;
    text-decoration: none;

    &:hover {
        background: ${({ theme }) => theme.colors.grey700};
        color: ${({ theme }) => theme.colors.black};
    }

    ${(props) => props.selected && selectedLinkFragment}
`;

const StyledLinkText = styled(Text)`
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }

    ${StyledLink}:hover & {
        color: ${({ theme }) => theme.colors.black};
    }
`;
