import { ActionWithPayload } from '../../types/redux';
import { AppDispatch, AppGetState } from '@/redux/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getCatalog } from './catalog';
import { getClerkAuctionState } from '../api/clerk';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_LIVE_CATALOG_STATUS_FAIL,
    LOAD_LIVE_CATALOG_STATUS_REQUEST,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export const DEFAULT_STATE = {
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_LIVE_CATALOG_STATUS_FAIL]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.catalogId]),
        }),
        [LOAD_LIVE_CATALOG_STATUS_REQUEST]: (state: State, action: ActionWithPayload<number>) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number }, { actionTime: number; catalogId: number }>
        ) => {
            const loaded = cloneDeep(state.loaded);
            const loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            loaded[action.meta.catalogId] = time;

            return {
                ...state,
                loaded,
                loading: difference(loading, [action.payload.catalogId]),
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.liveCatalogStatus;
const idSelector = (state, id) => id;

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getLoadTimeForLiveCatalogStatus = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isLiveCatalogStatusLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

const shouldFetchLiveCatalogStatus = (state, catalogId) => {
    if (!catalogId) {
        return false;
    }
    const loaded = getLoadTimeForLiveCatalogStatus(state, catalogId);
    if (loaded) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLiveCatalogStatusLoading(state, catalogId);
    return !loading;
};

/* ACTION CREATORS */
const loadLiveCatalogStatus = (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        let bidderId = 0; //getBidderId(); // state);
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { sellerId } = getCatalog(state, catalogId);

        // bidderId = bidderId === -1 ? false : bidderId;

        dispatch({
            payload: catalogId,
            type: LOAD_LIVE_CATALOG_STATUS_REQUEST,
        });

        /**
         * Get the live auction state
         */
        const response = await getClerkAuctionState({
            authToken,
            catalogId,
            deployment,
            sellerId,
        });

        dispatch({
            meta: { actionTime: Date.now(), bidderId, catalogId },
            payload: response.data,
            type: LOAD_LIVE_CATALOG_STATUS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true, // TODO: this isn't used
            meta: { catalogId },
            payload: error,
            type: LOAD_LIVE_CATALOG_STATUS_FAIL,
        });
    }
};

export const fetchLiveCatalogStatusIfNeeded =
    (catalogId: number, force: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        if (force || shouldFetchLiveCatalogStatus(getState(), catalogId)) {
            await dispatch(loadLiveCatalogStatus(catalogId));
        }
        return Promise.resolve();
    };
