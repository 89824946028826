import { Dispatch, ThunkAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/rootReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export type AppDispatch = ThunkDispatch<GlobalState, null, UnknownAction> & Dispatch<UnknownAction>;
export type AppGetState = () => GlobalState;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, GlobalState, unknown, UnknownAction>;

/**
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook
 * Use instead of react-redux' `useDispatch`
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
/**
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook
 * Use instead of react-redux `useSelector`
 */
export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector;
