import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getCatalogOwner } from './catalogOwner.api';
import { getDeployment } from '@/redux/modules/config';

export const fetchCatalogOwner = createAsyncThunk<number, number>(
    'la/domain/catalogOwner/fetchCatalogOwner',
    async (catalogId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await getCatalogOwner({
            authToken,
            catalogId,
            deployment,
        });

        return payload.ownerId;
    }
);
