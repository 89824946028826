import { createSelector } from '@reduxjs/toolkit';
import { fetchStreamId } from '../api/video';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_STREAM_NAME_FAIL,
    LOAD_STREAM_NAME_FAIL_ACTION,
    LOAD_STREAM_NAME_REQUEST,
    LOAD_STREAM_NAME_REQUEST_ACTION,
    LOAD_STREAM_NAME_SUCCESS,
    LOAD_STREAM_NAME_SUCCESS_ACTION,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

// reducer
export const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_STREAM_NAME_FAIL]: (state: State, action: LOAD_STREAM_NAME_FAIL_ACTION) => ({
            ...state,
            loading: difference(state.loading, [action.meta.catalogId]),
        }),
        [LOAD_STREAM_NAME_REQUEST]: (state: State, action: LOAD_STREAM_NAME_REQUEST_ACTION) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_STREAM_NAME_SUCCESS]: (state: State, action: LOAD_STREAM_NAME_SUCCESS_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded, [catalogId]: actionTime };
            let loading = cloneDeep(state.loading);

            existing[catalogId] = action.payload;
            loading = difference(loading, [catalogId]);

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.video;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getStreamStatus = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

const isStreamIdLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.includes(id);
});

/* ACTION CREATORS */
const loadStreamId = (catalogId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            meta: { actionTime: Date.now() },
            payload: catalogId,
            type: LOAD_STREAM_NAME_REQUEST,
        });
        const { payload } = await fetchStreamId({
            authToken,
            catalogId,
            deployment,
        });

        dispatch({
            meta: { actionTime: Date.now(), catalogId },
            payload,
            type: LOAD_STREAM_NAME_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { catalogId },
            payload: error,
            type: LOAD_STREAM_NAME_FAIL,
        });
    }
};

export const fetchStreamIdIfNeeded = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    if (!isStreamIdLoading(getState(), catalogId) && catalogId) {
        return dispatch(loadStreamId(catalogId));
    }
    return Promise.resolve({});
};
