import { TypedActionWithPayload } from '../../../types/redux';

export const SEND_LA_HOUSE_PAYMENT_FAIL = 'SEND_LA_HOUSE_PAYMENT_FAIL';
export const SEND_LA_HOUSE_PAYMENT_REQUEST = 'SEND_LA_HOUSE_PAYMENT_REQUEST';
export const SEND_LA_HOUSE_PAYMENT_SUCCESS = 'SEND_LA_HOUSE_PAYMENT_SUCCESS';

export const CLEAR_HOUSE_PAYMENT_AMOUNT = 'CLEAR_HOUSE_PAYMENT_AMOUNT';
export type CLEAR_HOUSE_PAYMENT_AMOUNT_ACTION = TypedActionWithPayload<typeof CLEAR_HOUSE_PAYMENT_AMOUNT>;

export const SET_HOUSE_PAYMENT_AMOUNT = 'SET_HOUSE_PAYMENT_AMOUNT';
export type SET_HOUSE_PAYMENT_AMOUNT_ACTION = TypedActionWithPayload<
    typeof SET_HOUSE_PAYMENT_AMOUNT,
    { paymentAmount: number; selectedCardToken: string }
>;
