import { createSelector } from '@reduxjs/toolkit';
import { getHealthCheckData } from '../api/healthcheck';
import isEmpty from 'lodash/isEmpty';

/* Action Types */
export const LOAD_HEALTH_CHECK_DATA_FAIL = 'la/domain/healthcheck/LOAD_FAIL';
export const LOAD_HEALTH_CHECK_DATA_REQUEST = 'la/domain/healthcheck/LOAD_REQUEST';
export const LOAD_HEALTH_CHECK_DATA_SUCCESS = 'la/domain/healthcheck/LOAD_SUCCESS';

// reducer
export const DEFAULT_STATE = {
    data: {},
    loading: false,
};

export type State = typeof DEFAULT_STATE;

export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case LOAD_HEALTH_CHECK_DATA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_HEALTH_CHECK_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_HEALTH_CHECK_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.healthcheck;

export const dataSelector = createSelector(stateSelector, (state) => state.data);

export const healthCheckDataSelector = createSelector(dataSelector, (data) => (!isEmpty(data) ? data : false));

/* ACTION CREATORS */
export const fetchHealthCheckData = () => async (dispatch: Function) => {
    try {
        dispatch({
            type: LOAD_HEALTH_CHECK_DATA_REQUEST,
        });
        const response = await getHealthCheckData();
        dispatch({
            payload: response,
            type: LOAD_HEALTH_CHECK_DATA_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_HEALTH_CHECK_DATA_FAIL,
        });
    }
};
