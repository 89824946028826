import { GetCatalogOwnerParams, GetCatalogOwnerResponse } from './catalogOwner.types';
import { handleResponse, makeGet } from '../../api/helpers';

export const getCatalogOwner = ({ authToken, catalogId, deployment }: GetCatalogOwnerParams) =>
    new Promise<GetCatalogOwnerResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `catalog/${catalogId}/owner`,
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
