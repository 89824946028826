import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { fetchForumPosts as fetchForumPostsAPI } from '../api/forumPosts';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_FORUM_POSTS_FAIL, LOAD_FORUM_POSTS_REQUEST, LOAD_FORUM_POSTS_SUCCESS } from './actions';
import ms from 'ms';
import type { GlobalState } from '@/redux/rootReducer';

const REDUX_STORE_TIME = ms('60m');

export type State = {
    loaded: number;
    loading: boolean;
    posts: any[];
};

export const DEFAULT_STATE: State = {
    loaded: 0,
    loading: false,
    posts: [],
};

export const reducer = handleActions(
    {
        [LOAD_FORUM_POSTS_FAIL]: (state: State): State => ({
            ...state,
            loaded: 0,
            loading: false,
        }),
        [LOAD_FORUM_POSTS_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [LOAD_FORUM_POSTS_SUCCESS]: (state: State, action: ActionWithPayload<any[], { actionTime: number }>): State => {
            const { actionTime } = action.meta;

            return {
                ...state,
                loaded: actionTime,
                loading: false,
                posts: action.payload,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.forumPosts;

export const forumPostsSelector = createSelector(stateSelector, (state) => state.posts);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const shouldFetch = createSelector([forumPostsSelector, loadedSelector, loadingSelector], (data, loaded, loading) => {
    if (data.length) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    return !loading;
});

/* ACTION CREATORS */
const fetchForumPosts = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_FORUM_POSTS_REQUEST,
        });
        const response = await fetchForumPostsAPI({ authToken, deployment });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload,
            type: LOAD_FORUM_POSTS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_FORUM_POSTS_FAIL,
        });
    }
};

export const fetchForumPostsIfNeeded = () => async (dispatch: Function, getState: Function) => {
    if (shouldFetch(getState())) {
        return dispatch(fetchForumPosts());
    }
    return Promise.resolve({});
};
