import * as React from 'react';
import { defaultSeller } from '@/types/Seller';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { fetchSearchSuggestions, getSearchSuggestions } from '@/redux/modules/searchSuggestions';
import { fetchSellersIfNeeded, getSeller } from '@/redux/modules/seller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDashboardUrl } from '@/utils/urls';
import {
    getImpersonatedHouseId,
    isAuthenticated,
    isBrassOrStaffAdminSelector,
    submitImpersonateHouse,
} from '@/redux/modules/user';
import { Select } from '@liveauctioneers/hammer-ui-core/select';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import isEqual from 'react-fast-compare';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import LoginContainer from '../Login/LoginContainer';
import Logo from '@liveauctioneers/caterwaul-components/lib/Assets/Logo';
import styled from 'styled-components';

const cookie = new Cookies();
let doneTypingInterval = 150;
let typingTimer: ReturnType<typeof setTimeout>; // Essentially a debounce (lodash.debounce)

type Props = {
    houseId: number;
};

const Header = ({ houseId }: Props) => {
    const [number, setNumber] = React.useState(-1);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const admin = useAppSelector(isBrassOrStaffAdminSelector);
    const searchOptions = useAppSelector(getSearchSuggestions);
    const currentImpersonation = useAppSelector(getImpersonatedHouseId);
    const auth = useAppSelector(isAuthenticated);
    const currentSeller = useAppSelector((state) => getSeller(state, number));

    const onLoginPage = (pathname === '/' || pathname === '/login') && auth && admin && !Boolean(currentImpersonation);
    const wasNotLoggedIn = React.useRef(onLoginPage);

    const [showSelect, setSelect] = React.useState(onLoginPage);
    const [showedSelectOnLogin, setLoggedIn] = React.useState(false);

    if (!auth && showSelect) {
        setSelect(false);
    }

    if (wasNotLoggedIn.current === false && onLoginPage && !showedSelectOnLogin) {
        setLoggedIn(true);
        setSelect(true);
    }

    const options = !isEqual(currentSeller, defaultSeller)
        ? [{ label: currentSeller.name, value: currentSeller.sellerId }]
        : searchOptions;

    const loadOptions = (inputValue) => {
        clearTimeout(typingTimer); // Ensures the function is not run repeatedly if letters are typed quickly
        const isNotNumber = isNaN(inputValue) || inputValue === '';
        if (isNotNumber) {
            typingTimer = setTimeout(() => {
                dispatch(fetchSearchSuggestions(inputValue));
            }, doneTypingInterval);
            setNumber(-1);
        } else if (Number(inputValue) > 0) {
            const inputNumber = Number(inputValue);
            typingTimer = setTimeout(() => {
                dispatch(fetchSellersIfNeeded([inputNumber]));
            }, doneTypingInterval * 5);
            setNumber(inputNumber);
        }
        return inputValue;
    };

    const impersonateAction = ({ value }) => {
        dispatch(submitImpersonateHouse(Number(value)));
        let refresh = false;
        if (value !== Number(currentImpersonation)) {
            cookie.set('admin-impersonated-house', value, {
                domain: '.liveauctioneers.com',
                expires: new Date(Date.now() + 86400000), // 24 hours in milliseconds from now
                path: '/',
            });
            refresh = true;
        }
        if (!onLoginPage) {
            navigate(pathname.replace(RegExp(`/${currentImpersonation}/`), `/${value}/`));
            refresh && window.location.reload();
        } else {
            navigate((window.location.href = `house/${value}/dashboard`));
        }

        setSelect(false);
    };

    const url = houseId ? getDashboardUrl(houseId) : '/';
    return (
        <HeaderWrapper>
            <HeaderContainer>
                <HeaderLink
                    route
                    to={url}
                >
                    <Logo height="23px" />
                </HeaderLink>
                <HeaderNav>
                    {showSelect ? (
                        <>
                            <StyledSelect
                                autoFocus
                                isSearchable
                                noOptionsMessage={() => 'No houses found'}
                                onChange={(valuePair) => impersonateAction(valuePair)}
                                onInputChange={loadOptions}
                                options={options}
                                placeholder="Search name or ID"
                            />
                            <CloseButton
                                icon={faTimes}
                                onClick={() => setSelect(false)}
                            />
                        </>
                    ) : (
                        <LoginContainer setImpersonateAction={() => setSelect(true)} />
                    )}
                </HeaderNav>
            </HeaderContainer>
        </HeaderWrapper>
    );
};

export default Header;

const HeaderWrapper = styled.div`
    height: 88px;
    width: 100%;
    background: ${({ theme }) => theme.colors.grey500};
    color: ${({ theme }) => theme.colors.grey100};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    font-weight: 400;
    line-height: 36px;
    display: flex;
    justify-content: center;
    position: relative;

    @media print {
        display: none;
    }

    & .popover div[class='popover-content'] {
        padding: 10px 0 0;
    }
`;

const StyledSelect = styled(Select)`
    width: 250px;
`;

const HeaderContainer = styled.div`
    padding: 0 20px;
    max-width: ${({ theme }) => theme.breakpoints.pageWidth};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

const HeaderLink = styled(Link)`
    color: ${({ theme }) => theme.colors.blue100};
    text-decoration: none;
`;

const HeaderNav = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 13px;
    align-content: space-between;
`;

const CloseButton = styled(FontAwesomeIcon)`
    margin: auto 15px;
    cursor: pointer;
    font-size: 20px;
`;
