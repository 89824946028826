import { handleResponse, makePost } from './helpers';

type FetchCoverLotsParams = {
    authToken?: string;
    catalogIds: number[];
    deployment: string;
};

export default {
    fetchCoverLots: ({ authToken, catalogIds, deployment }: FetchCoverLotsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({ authToken, deployment, path: '<ITEM-API>small/cover-lots' });
            request.send({ ids: catalogIds });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
