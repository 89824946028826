import { ActionWithPayload } from '../../types/redux';
import {
    ADD_CREDIT_CARD_FAIL,
    ADD_CREDIT_CARD_REQUEST,
    ADD_CREDIT_CARD_SUCCESS,
    LOAD_CREDIT_CARD_FAIL,
    LOAD_CREDIT_CARD_REQUEST,
    LOAD_CREDIT_CARD_SUCCESS,
    UPDATE_CREDIT_CARD_FAIL,
    UPDATE_CREDIT_CARD_REQUEST,
    UPDATE_CREDIT_CARD_SUCCESS,
} from './actions';
import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { CreditCard } from '@liveauctioneers/caterwaul-components/types/CreditCard';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import api from '../api/creditCard';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

/* reducer */
export interface State {
    error: string | null;
    laHouseCards: CreditCard[];
    loaded?: Date | null;
    loading: boolean;
}

export const DEFAULT_STATE = {
    error: undefined,
    laHouseCards: [],
    loaded: undefined,
    loading: false,
};

export const reducer = handleActions(
    {
        [combineActions(ADD_CREDIT_CARD_FAIL, LOAD_CREDIT_CARD_FAIL, UPDATE_CREDIT_CARD_FAIL)]: (
            state: State,
            action: ActionWithPayload<any>
        ) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [ADD_CREDIT_CARD_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [ADD_CREDIT_CARD_SUCCESS]: (state: State, action: ActionWithPayload<CreditCard>): State => {
            let cardsCopy = cloneDeep(state.laHouseCards);
            cardsCopy.push(action.payload);
            return {
                ...state,
                loaded: undefined,
                loading: false,
            };
        },
        [LOAD_CREDIT_CARD_REQUEST]: (state: State): State => ({
            ...state,
            laHouseCards: [],
            loading: true,
        }),
        [LOAD_CREDIT_CARD_SUCCESS]: (state: State, action: ActionWithPayload<CreditCard[]>): State => ({
            ...state,
            laHouseCards: action.payload,
            loaded: new Date(),
            loading: false,
        }),
        [UPDATE_CREDIT_CARD_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [UPDATE_CREDIT_CARD_SUCCESS]: (state: State): State => ({
            ...state,
            laHouseCards: [],
            loaded: undefined,
            loading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.creditCard;

export const getLAHouseCreditCards = createSelector(stateSelector, (state) => state.laHouseCards);

export const getHouseDefaultCreditCard = createSelector(getLAHouseCreditCards, (cards) => {
    if (cards.length === 0) {
        return {};
    }
    const defaultCard = cards.find((card) => card.defaultCard);
    if (defaultCard) {
        return defaultCard;
    }

    return {};
});

export const getcreditCardsLoaded = createSelector(stateSelector, (state) => state.loaded);

export const getcreditCardsLoading = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export type AddCreditCard = {
    billingAddress: Address;
    cardNonce: string;
    cardholderName: any;
    defaultCard: boolean;
    houseId: number;
    providerId: number;
};

export const addCreditCard =
    ({ billingAddress, cardholderName, cardNonce, defaultCard, houseId, providerId }: AddCreditCard) =>
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch({
                type: ADD_CREDIT_CARD_REQUEST,
            });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            const response = await api.postCreateLAHouseCard({
                authToken,
                billingAddress,
                cardholderName: `${cardholderName.first} ${cardholderName.last}`,
                cardNonce,
                defaultCard,
                deployment,
                houseId,
                providerId,
            });

            dispatch({
                payload: response.payload,
                type: ADD_CREDIT_CARD_SUCCESS,
            });
            return dispatch(fetchLAHouseCardsIfNeeded(houseId));
        } catch (error) {
            return dispatch({
                error: true,
                payload: error.message || error,
                type: ADD_CREDIT_CARD_FAIL,
            });
        }
    };

export const shouldFetchLAHouseCards = createSelector(
    getcreditCardsLoaded,
    getcreditCardsLoading,
    (loaded, loading) => {
        if (loading) {
            return false;
        }
        const time = Date.now();
        const diff = time - (loaded?.getTime() || 0);
        return !loaded || diff > REDUX_STORE_TIME_ITEM;
    }
);

export const fetchLAHouseCardsIfNeeded =
    (houseId: number, force?: boolean) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetchLAHouseCards(state) || force) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                dispatch({ type: LOAD_CREDIT_CARD_REQUEST });

                const response = await api.getLAHouseCards({ authToken, deployment, houseId });

                return dispatch({
                    payload: response.payload,
                    type: LOAD_CREDIT_CARD_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    payload: error,
                    type: LOAD_CREDIT_CARD_FAIL,
                });
            }
        }
    };

type UpdateHouseCard = {
    cardId: number;
    defaultCard: boolean;
    enabled?: boolean;
    houseId: number;
};

export const updateLAHouseCard =
    ({ cardId, defaultCard, enabled = true, houseId }: UpdateHouseCard) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: UPDATE_CREDIT_CARD_REQUEST });

            const response = await api.postUpdateLAHouseCard({
                authToken,
                cardId,
                defaultCard,
                deployment,
                enabled,
                houseId,
            });

            dispatch({
                payload: response.payload,
                type: UPDATE_CREDIT_CARD_SUCCESS,
            });
            return dispatch(fetchLAHouseCardsIfNeeded(houseId));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: UPDATE_CREDIT_CARD_FAIL,
            });
        }
    };
