export function roundValueAndTrimToTwoPlaces(value: any) {
    return Number((Math.round(value * 100) / 100).toFixed(2));
}

export function valueToSingleFixedPlace(value: any) {
    return parseFloat(value).toFixed(1);
}

export function truncateToSecondPlaceWithoutRounding(value: any) {
    const num = value.toString();
    const idx = num.indexOf('.');
    if (idx > -1) {
        return Number(num.slice(0, idx + 3));
    } else {
        return Number(value);
    }
}

export function numberWithCommas(originalNumber: any) {
    return Boolean(originalNumber) ? originalNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
}
