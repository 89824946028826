import { CatalogPromotion, Promotion } from '@/types/Promotion';
import { combineActions, handleActions } from 'redux-actions';
import {
    CREATE_SELECTED_PROMOTION_FAIL,
    CREATE_SELECTED_PROMOTION_FAIL_ACTION,
    CREATE_SELECTED_PROMOTION_REQUEST,
    CREATE_SELECTED_PROMOTION_SUCCESS,
    PREVIOUS_SELECTED_PROMOTION_FAIL,
    PREVIOUS_SELECTED_PROMOTION_FAIL_ACTION,
    PREVIOUS_SELECTED_PROMOTION_REQUEST,
    PREVIOUS_SELECTED_PROMOTION_SUCCESS,
    PREVIOUS_SELECTED_PROMOTION_SUCCESS_ACTION,
    UPDATE_SELECTED_PROMOTION_FAIL,
    UPDATE_SELECTED_PROMOTION_FAIL_ACTION,
    UPDATE_SELECTED_PROMOTION_REQUEST,
    UPDATE_SELECTED_PROMOTION_SUCCESS,
} from './actions';
import { createPromotionObject } from '@/utils/createPromotionObject';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import api from '../api';

/* reducer */

export type State = {
    apiError: string;
    isLoading: boolean;
    isPreviousPromotionReady: boolean;
    previousSelectedCatalog: CatalogPromotion;
    previousSelectedPromotion: Promotion | null | undefined;
    previousSelectedPromotionLots: number[];
    promotionPlan: string;
    sentSelectedPromotionSuccess: boolean;
};

export const DEFAULT_STATE: State = {
    apiError: '',
    isLoading: false,
    isPreviousPromotionReady: false,
    previousSelectedCatalog: {
        catalogId: 0,
    },
    previousSelectedPromotion: null,
    previousSelectedPromotionLots: [],
    promotionPlan: '',
    sentSelectedPromotionSuccess: false,
};

export const reducer = handleActions(
    {
        [combineActions(CREATE_SELECTED_PROMOTION_SUCCESS, UPDATE_SELECTED_PROMOTION_SUCCESS)]: (
            state: State
        ): State => ({
            ...state,
            apiError: '',
            isLoading: false,
            sentSelectedPromotionSuccess: true,
        }),
        [combineActions(CREATE_SELECTED_PROMOTION_REQUEST, UPDATE_SELECTED_PROMOTION_REQUEST)]: (
            state: State
        ): State => ({
            ...state,
            apiError: '',
            isLoading: true,
        }),
        [combineActions(CREATE_SELECTED_PROMOTION_FAIL, UPDATE_SELECTED_PROMOTION_FAIL)]: (
            state: State,
            action: CREATE_SELECTED_PROMOTION_FAIL_ACTION | UPDATE_SELECTED_PROMOTION_FAIL_ACTION
        ): State => ({
            ...state,
            apiError: action.payload,
            isLoading: false,
            sentSelectedPromotionSuccess: false,
        }),
        [PREVIOUS_SELECTED_PROMOTION_FAIL]: (state: State, action: PREVIOUS_SELECTED_PROMOTION_FAIL_ACTION): State => ({
            ...state,
            apiError: action.payload,
            isLoading: false,
        }),
        [PREVIOUS_SELECTED_PROMOTION_REQUEST]: (state: State): State => ({
            ...state,
            apiError: '',
            isLoading: true,
        }),
        [PREVIOUS_SELECTED_PROMOTION_SUCCESS]: (
            state: State,
            action: PREVIOUS_SELECTED_PROMOTION_SUCCESS_ACTION
        ): State => ({
            ...state,
            apiError: '',
            isLoading: false,
            isPreviousPromotionReady: true,
            previousSelectedCatalog: action.payload.catalog,
            previousSelectedPromotion: action.payload.promotion,
            previousSelectedPromotionLots: action.payload.promotedLots,
            promotionPlan: action.payload.promotionPlan,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.promotion;

export const getError = createSelector(stateSelector, (state) => state.apiError);
export const getPreviousSelectedCatalog = createSelector(stateSelector, (state) => state.previousSelectedCatalog);
export const getPreviousSelectedPromotionPlan = createSelector(stateSelector, (state) => state.promotionPlan);
export const getPreviousSelectedPromotion = createSelector(stateSelector, (state) => state.previousSelectedPromotion);
export const getPreviousSelectedPromotionLots = createSelector(
    stateSelector,
    (state) => state.previousSelectedPromotionLots
);
export const isPromotionLoading = createSelector(stateSelector, (state) => state.isLoading);
export const isPreviousPromotionReady = createSelector(stateSelector, (state) => state.isPreviousPromotionReady);
export const isPromotionPaidFor = createSelector(stateSelector, (state) => {
    if (state.previousSelectedPromotion) {
        if (
            state.previousSelectedPromotion.status === 'paid' ||
            state.previousSelectedPromotion.status === 'published'
        ) {
            return state.promotionPlan !== 'Minimal';
        }
    }
    return false;
});
export const isSentPromotionSuccess = createSelector(stateSelector, (state) => state.sentSelectedPromotionSuccess);

/* ACTION CREATORS */
export const fetchSelectedPromotion = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: PREVIOUS_SELECTED_PROMOTION_REQUEST,
        });

        // translate this just to pull back the lotIds from the response
        const response = await api.getPreviousSelectedPromotionPlan({
            authToken,
            catalogId,
            deployment,
        });

        let promotionPlan = '';
        if (response.payload.promotion) {
            switch (response.payload.promotion.promotionPlan.promotionPlanId) {
                case 1:
                    promotionPlan = 'Minimal';
                    break;
                case 2:
                    promotionPlan = 'Standard';
                    break;
                case 3:
                    promotionPlan = 'Premium';
                    break;
                default:
                    promotionPlan = 'Minimal';
                    break;
            }
        }

        dispatch({
            payload: {
                promotionPlan,
                ...response.payload,
            },
            type: PREVIOUS_SELECTED_PROMOTION_SUCCESS,
        } as PREVIOUS_SELECTED_PROMOTION_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: PREVIOUS_SELECTED_PROMOTION_FAIL,
        } as PREVIOUS_SELECTED_PROMOTION_FAIL_ACTION);
    }
};

export const submitSelectedPromotion =
    (
        catalogId: number,
        sellerId: number,
        selectedLots: number[],
        tabSelected: 'Minimal' | 'Standard' | 'Premium',
        previousSelectedPromotion: Promotion,
        previousSelectedCatalog: CatalogPromotion
    ) =>
    async (dispatch: Function, getState: Function) => {
        try {
            if (Boolean(previousSelectedPromotion)) {
                dispatch(
                    updateSelectedPromotion(
                        catalogId,
                        sellerId,
                        selectedLots,
                        tabSelected,
                        previousSelectedPromotion,
                        previousSelectedCatalog
                    )
                );
            } else {
                const state = getState();
                const deployment = getDeployment(state);
                const authToken = getAuthToken(state);

                dispatch({
                    type: CREATE_SELECTED_PROMOTION_REQUEST,
                });

                const promotionObject = createPromotionObject(catalogId, sellerId, selectedLots, tabSelected);

                await api
                    .postCreateSelectedPromotion({
                        authToken,
                        deployment,
                        promotionObject,
                    })
                    .then((results) => {
                        if (results.error) {
                            dispatch({
                                error: true,
                                payload: results.payload,
                                type: CREATE_SELECTED_PROMOTION_FAIL,
                            } as CREATE_SELECTED_PROMOTION_FAIL_ACTION);
                        } else {
                            dispatch({
                                type: CREATE_SELECTED_PROMOTION_SUCCESS,
                            });
                        }
                    });
            }
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: CREATE_SELECTED_PROMOTION_FAIL,
            } as CREATE_SELECTED_PROMOTION_FAIL_ACTION);
        }
    };

const updateSelectedPromotion =
    (
        catalogId: number,
        sellerId: number,
        selectedLots: any,
        tabSelected: any,
        previousSelectedPromotion: any,
        previousSelectedCatalog: any
    ) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: UPDATE_SELECTED_PROMOTION_REQUEST,
            });

            const promotionObject = createPromotionObject(
                catalogId,
                sellerId,
                selectedLots,
                tabSelected,
                previousSelectedPromotion,
                previousSelectedCatalog
            );

            await api
                .postUpdateSelectedPromotion({
                    authToken,
                    deployment,
                    promotionObject,
                })
                .then((results) => {
                    if (results.error) {
                        dispatch({
                            error: true,
                            payload: results.payload,
                            type: UPDATE_SELECTED_PROMOTION_FAIL,
                        } as UPDATE_SELECTED_PROMOTION_FAIL_ACTION);
                    } else {
                        dispatch({
                            type: UPDATE_SELECTED_PROMOTION_SUCCESS,
                        });
                    }
                });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: UPDATE_SELECTED_PROMOTION_FAIL,
            } as UPDATE_SELECTED_PROMOTION_FAIL_ACTION);
        }
    };
