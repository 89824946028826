export const LOAD_AUTO_APPROVAL_CRITERIA_FAIL = 'LOAD_AUTO_APPROVAL_CRITERIA_FAIL';
export const LOAD_AUTO_APPROVAL_CRITERIA_REQUEST = 'LOAD_AUTO_APPROVAL_CRITERIA_REQUEST';
export const LOAD_AUTO_APPROVAL_CRITERIA_SUCCESS = 'LOAD_AUTO_APPROVAL_CRITERIA_SUCCESS';

export const LOAD_POST_AUTO_APPROVAL_CRITERIA_FAIL = 'LOAD_POST_AUTO_APPROVAL_CRITERIA_FAIL';
export const LOAD_POST_AUTO_APPROVAL_CRITERIA_REQUEST = 'LOAD_POST_AUTO_APPROVAL_CRITERIA_REQUEST';
export const LOAD_POST_AUTO_APPROVAL_CRITERIA_SUCCESS = 'LOAD_POST_AUTO_APPROVAL_CRITERIA_SUCCESS';

export const LOAD_ADDITIONAL_FAVORITE_BIDDERS_FAIL = 'LOAD_ADDITIONAL_FAVORITE_BIDDERS_FAIL';
export const LOAD_ADDITIONAL_FAVORITE_BIDDERS_REQUEST = 'LOAD_ADDITIONAL_FAVORITE_BIDDERS_REQUEST';
export const LOAD_ADDITIONAL_FAVORITE_BIDDERS_SUCCESS = 'LOAD_ADDITIONAL_FAVORITE_BIDDERS_SUCCESS';
