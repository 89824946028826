import { BidderStatusFilters } from '../../types/BidderApproval';
import { BidderTables } from '../../types/BidderTablesData';
import { handleResponse, makeGet } from './helpers';

type BidderTablesParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    filter: BidderStatusFilters[];
    houseId: number;
    page: number;
    pageSize: number;
    search: string;
    sort: string;
    status: number;
};

type BidderTableResponsePayload = {
    error: Boolean;
    payload: {
        approvals: BidderTables;
        page: number;
        pageSize: number;
        totalCount: number;
    };
};

export default {
    getBidderTables: ({
        authToken,
        catalogId,
        deployment,
        filter,
        houseId,
        page,
        pageSize,
        search,
        sort,
        status,
    }: BidderTablesParams) =>
        new Promise<BidderTableResponsePayload>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<APPROVAL-API>house/${houseId}/registrationsv2`,
            });
            request.query({ catalogId, filter: JSON.stringify(filter), page, pageSize, search, sort, status });
            request.end((err: boolean, response: BidderTableResponsePayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
