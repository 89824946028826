import { Features } from '../../../types/FeatureAccess';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_FEATURE_ACCESS_FAIL = 'LOAD_FEATURE_ACCESS_FAIL';
export type LOAD_FEATURE_ACCESS_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_FEATURE_ACCESS_FAIL,
    { error: string; houseId: number }
>;

export const LOAD_FEATURE_ACCESS_REQUEST = 'LOAD_FEATURE_ACCESS_REQUEST';
export type LOAD_FEATURE_ACCESS_REQUEST_ACTION = TypedActionWithPayload<
    typeof LOAD_FEATURE_ACCESS_REQUEST,
    { houseId: number }
>;

export const LOAD_FEATURE_ACCESS_SUCCESS = 'LOAD_FEATURE_ACCESS_SUCCESS';
export type LOAD_FEATURE_ACCESS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_FEATURE_ACCESS_SUCCESS,
    { features: Features }
>;
