import { AccountingContact } from '../../types/AccountContact';
import { handleResponse, makeGet, makePost } from './helpers';

type GetAccountingContactParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type GetAccountingContactPayload = {
    error: boolean;
    payload: AccountingContact;
};

type PostAccountingContactPayload = {
    error: boolean;
    payload: AccountingContact;
};

type PostAccountingContactParams = {
    authToken: string;
    deployment: string;
    email: string;
    fax: string;
    houseId: number;
    name: string;
    phone: string;
};

export default {
    getAccountingContact: ({ authToken, deployment, houseId }: GetAccountingContactParams) =>
        new Promise<GetAccountingContactPayload>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/accountingcontact`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.end((err: boolean, response: GetAccountingContactPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
    postAccountingContact: ({ authToken, deployment, email, fax, houseId, name, phone }: PostAccountingContactParams) =>
        new Promise<PostAccountingContactPayload>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/accountingcontact`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.send({
                email,
                fax,
                houseId,
                name,
                phone,
            });
            request.end((err: boolean, response: PostAccountingContactPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
