import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState } from '@/redux/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { EventTypes } from 'redux-segment';
import { getFavoriteBiddersRecords } from '@/redux/modules/bidderFavoriting';
import { getUserHouseId } from './user';
import { handleActions } from 'redux-actions';

/* ACTION TYPES */
export const IDENTIFY_ANALYTICS = 'IDENTIFY_ANALYTICS';
export const TRACK_PAGEVIEW = 'TRACK_PAGEVIEW';
export const SAVE_FULLSTORY_SESSION_URL = 'SAVE_FULLSTORY_SESSION_URL';
export const TRACK_APPROVE_BIDDER = 'TRACK_APPROVE_BIDDER';
export const TRACK_DECLINE_BIDDER = 'TRACK_DECLINE_BIDDER';
export const TRACK_FAVORITED_BIDDER = 'TRACK_FAVORITED_BIDDER';
export const TRACK_BLOCKED_BIDDER = 'TRACK_BLOCKED_BIDDER';

// CAPAB Modal
export const TRACK_CONFIRM_CAPAB_MODAL_CLICK = 'TRACK_CONFIRM_CAPAB_MODAL_CLICK';
export const TRACK_DECLINE_CAPAB_MODAL_CLICK = 'TRACK_DECLINE_CAPAB_MODAL_CLICK';
export const TRACK_DISMISS_CAPAB_MODAL_CLICK = 'TRACK_DISMISS_CAPAB_MODAL_CLICK';

// Recommended Favorite Bidders Modal
export const TRACK_CONFIRM_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK =
    'TRACK_CONFIRM_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK';
export const TRACK_DECLINE_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK =
    'TRACK_DECLINE_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK';
export const TRACK_DISMISS_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK =
    'TRACK_DISMISS_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK';

/* reducer */
export type State = {
    fullStorySessionId: string;
    fullStorySessionUrl: string;
};

export const DEFAULT_STATE: State = {
    fullStorySessionId: '',
    fullStorySessionUrl: '',
};

export const reducer = handleActions(
    {
        [SAVE_FULLSTORY_SESSION_URL]: (
            state: State,
            action: ActionWithPayload<{ sessionId: string; sessionUrl: string }, {}>
        ) => ({
            ...state,
            fullStorySessionId: action.payload.sessionId,
            fullStorySessionUrl: action.payload.sessionUrl,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.analytics;

export const getFullStorySessionUrl = createSelector(stateSelector, (state) => state.fullStorySessionUrl);
export const getFullStorySessionId = createSelector(stateSelector, (state) => state.fullStorySessionId || 'N/A');

/* ACTION CREATORS */
export const saveFullStorySessionData =
    (sessionData: { sessionId: string; sessionUrl: string }) =>
    (dispatch: Function): void => {
        dispatch({
            payload: sessionData,
            type: SAVE_FULLSTORY_SESSION_URL,
        });
    };

export const getLoginAnalytics = (userData: any, userType: string) => ({
    analytics: {
        eventPayload: {
            traits: {
                houseId: userData.houseId,
                userType: userType,
            },
            userId: userData.username,
        },
        eventType: EventTypes.identify,
    },
});

export const recordAnalyticsForIdentify = (userData: any, userType: string) => ({
    meta: getLoginAnalytics(userData, userType),
    payload: {},
    type: IDENTIFY_ANALYTICS,
});

const trackPageView =
    <Props>(pageType: string, props?: Props) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const { pathname, search } = window.location;
        const currentUrl = `${pathname}${search}`;
        const houseId = getUserHouseId(state);

        return dispatch({
            meta: {
                actionTime: Date.now(),
                analytics: {
                    eventPayload: {
                        properties: {
                            houseId,
                            pageType,
                            url: currentUrl,
                            ...props,
                        },
                    },
                    eventType: EventTypes.page,
                },
            },
            payload: {},
            type: TRACK_PAGEVIEW,
        });
    };

export const recordApproveBidder = (bidderId: number, bidLimit: number, catalogId: number, houseId: number) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Approved Bidder',
                properties: {
                    bidderId,
                    bidLimit,
                    catalogId,
                    houseId,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TRACK_APPROVE_BIDDER,
});

export const recordDeclineBidder = (bidderId: number, bidLimit: number, catalogId: number, houseId: number) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Declined Bidder',
                properties: {
                    bidderId,
                    bidLimit,
                    catalogId,
                    houseId,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TRACK_DECLINE_BIDDER,
});

export const recordFavoriteBidder = (bidderId: number, bidLimit: number, catalogId: number, houseId: number) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Favorited Bidder',
                properties: {
                    bidderId,
                    bidLimit,
                    catalogId,
                    houseId,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TRACK_FAVORITED_BIDDER,
});

export const recordBlockBidder = (bidderId: number, bidLimit: number, catalogId: number, houseId: number) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Blocked Bidder',
                properties: {
                    bidderId,
                    bidLimit,
                    catalogId,
                    houseId,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TRACK_BLOCKED_BIDDER,
});

export const recordAnalyticsForBidderApprovalPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Bidder Approval'));

export const recordAnalyticsForAuctioneerCarouselPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Live Auction Carousel'));

export const recordAnalyticsForAuctioneerConsolePageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Auctioneer Console'));

export const recordAnalyticsForAuctionPreviewPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Set Up Auction Preview'));

export const recordAnalyticsForBNAReportPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Bid Missed Report'));

export const recordAnalyticsForCampaignsPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Marketing'));

export const recordAnalyticsForCatalogPaymentPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Catalog Payment'));

export const recordAnalyticsForCatalogPerformancePageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Auction Performance'));

export const recordAnalyticsForCatalogsPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Catalogs'));

export const recordAnalyticsForClerkConsolePageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Clerk Console'));

export const recordAnalyticsForDashboardPageView = () => async (dispatch: Function) => dispatch(trackPageView('Home'));

export const recordAnalyticsForEditLotPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Edit Lot'));

export const recordAnalyticsForForgotPasswordPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Forgot House Password'));

export const recordAnalyticsForHomePageView = () => async (dispatch: Function) => dispatch(trackPageView('Admin Home'));

export const recordAnalyticsForLoginPageView = () => async (dispatch: Function) => dispatch(trackPageView('Log In'));

export const recordAnalyticsForMarketingPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Email Campaigns'));

export const recordAnalyticsForNotFoundPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Not Found'));

export const recordAnalyticsForPaymentsPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Payments'));

export const recordAnalyticsForMessagePageView = () => async (dispatch: Function) => dispatch(trackPageView('Message'));

export const recordAnalyticsForMessagesPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Messages'));

export const recordAnalyticsForPerformanceCatalogsListPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Performance'));

export const recordAnalyticsForPerformanceLotsListPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Lot Performance'));

export const recordAnalyticsForLotManagerCategorizerPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Lot Categorizer'));

export const recordAnalyticsForPromotedLotsPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Featured Lots'));

export const recordAnalyticsForRatingsPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Ratings & Reviews'));

export const recordAnalyticsForResetPasswordPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Reset House Password'));

export const recordAnalyticsForStreamPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('A/V Stream'));

export const recordAnalyticsForUpcomingCatalogListPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Upcoming Auctions'));

export const recordAnalyticsForVideoSetupPageView = () => async (dispatch: Function) =>
    dispatch(trackPageView('Video Setup'));

export const recordCAPABModalConfirmClick = () => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const houseId = getUserHouseId(state);
    const numFavoriteBidders = getFavoriteBiddersRecords(state);

    dispatch({
        meta: {
            analytics: {
                eventPayload: {
                    event: 'CAPAB Modal Confirm Click',
                    properties: {
                        houseId,
                        numFavoriteBidders,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_CONFIRM_CAPAB_MODAL_CLICK,
    });
};

export const recordCAPABModalDeclineClick = () => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const houseId = getUserHouseId(state);
    const numFavoriteBidders = getFavoriteBiddersRecords(state);

    dispatch({
        meta: {
            analytics: {
                eventPayload: {
                    event: 'CAPAB Modal Decline Click',
                    properties: {
                        houseId,
                        numFavoriteBidders,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_DECLINE_CAPAB_MODAL_CLICK,
    });
};

export const recordCAPABModalDismissClick = () => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const houseId = getUserHouseId(state);
    const numFavoriteBidders = getFavoriteBiddersRecords(state);

    dispatch({
        meta: {
            analytics: {
                eventPayload: {
                    event: 'CAPAB Modal Dismiss Click',
                    properties: {
                        houseId,
                        numFavoriteBidders,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_DISMISS_CAPAB_MODAL_CLICK,
    });
};

export const recordFavoriteBidderRecommendationsModalConfirmClick =
    () => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const houseId = getUserHouseId(state);
        const numFavoriteBidders = getFavoriteBiddersRecords(state);

        dispatch({
            meta: {
                analytics: {
                    eventPayload: {
                        event: 'Favorite Bidder Recommendations Modal Confirm Click',
                        properties: {
                            houseId,
                            numFavoriteBidders,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_CONFIRM_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK,
        });
    };

export const recordFavoriteBidderRecommendationsModalDeclineClick =
    () => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const houseId = getUserHouseId(state);
        const numFavoriteBidders = getFavoriteBiddersRecords(state);

        dispatch({
            meta: {
                analytics: {
                    eventPayload: {
                        event: 'Favorite Bidder Recommendations Modal Decline Click',
                        properties: {
                            houseId,
                            numFavoriteBidders,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_DECLINE_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK,
        });
    };

export const recordFavoriteBidderRecommendationsModalDismissClick =
    () => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const houseId = getUserHouseId(state);
        const numFavoriteBidders = getFavoriteBiddersRecords(state);

        dispatch({
            meta: {
                analytics: {
                    eventPayload: {
                        event: 'Favorite Bidder Recommendations Modal Dismiss Click',
                        properties: {
                            houseId,
                            numFavoriteBidders,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_DISMISS_FAVORITE_BIDDER_RECOMMENDATIONS_MODAL_CLICK,
        });
    };
