import { handleResponse, makeGet, makePost } from './helpers';

type BlockBidderParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    houseId: number;
};
type GetBlockedBiddersParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    page: number;
    pageSize: number;
};

type PostBlockNameOrEmailParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    nameOrEmail: string;
};

type PostBlockNameOrEmailPayload = {
    error: boolean;
    payload: number;
};

export default {
    blockBidder: ({ authToken, bidderId, deployment, houseId }: BlockBidderParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/${bidderId}/block`,
            });
            request.send({
                note: '',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    getBlockedBidders: ({ authToken, deployment, houseId, page, pageSize }: GetBlockedBiddersParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/bidders/2`,
            });
            request.query({ page, pageSize });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postBlockBidderNameOrEmail: ({ authToken, deployment, houseId, nameOrEmail }: PostBlockNameOrEmailParams) =>
        new Promise<PostBlockNameOrEmailPayload>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/block-by-email-or-username`,
            });
            request.send(JSON.stringify(nameOrEmail));
            request.end((err: boolean, response: PostBlockNameOrEmailPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
    unblockBidder: ({ authToken, bidderId, deployment, houseId }: BlockBidderParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/${bidderId}/neutralize`,
            });
            request.send({
                note: `Removed from blocked bidders list by house: ${houseId}`,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
