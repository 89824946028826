import { handleResponse, makePost } from './helpers';

type FetchConsoleBrandingApiParams = {
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const fetchConsoleBrandingApi = ({ catalogId, deployment, sellerId }: FetchConsoleBrandingApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>console/branding' });
        request.send({
            catalogId,
            sellerId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
