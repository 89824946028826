import { ActionWithPayload } from '@/types/redux';
import { BillingHistoryItem, HouseBalanceDue } from '@/types/FinanceBackend';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_HOUSE_BALANCE_DUE_FAIL,
    LOAD_HOUSE_BALANCE_DUE_REQUEST,
    LOAD_HOUSE_BALANCE_DUE_SUCCESS,
    LOAD_HOUSE_BILLING_HISTORY_FAIL,
    LOAD_HOUSE_BILLING_HISTORY_REQUEST,
    LOAD_HOUSE_BILLING_HISTORY_SUCCESS,
} from './actions';
import api from '../api/houseBilling';

export interface State {
    balanceDue: HouseBalanceDue;
    billingHistory: BillingHistoryItem[];
    error: any;
    loading: boolean;
}

export const DEFAULT_STATE: State = {
    balanceDue: {
        balanceDue: 0,
        currencyCode: 'USD',
    },
    billingHistory: [],
    error: undefined,
    loading: false,
};

export const reducer = handleActions(
    {
        [LOAD_HOUSE_BALANCE_DUE_FAIL]: (state: State, action: ActionWithPayload<{ error: string }>): State => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [LOAD_HOUSE_BALANCE_DUE_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [LOAD_HOUSE_BALANCE_DUE_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ balanceDue?: number; currencyCode?: string }>
        ): State => ({
            ...state,
            balanceDue: {
                balanceDue: action.payload?.balanceDue,
                currencyCode: action.payload?.currencyCode,
            },
            loading: false,
        }),
        [LOAD_HOUSE_BILLING_HISTORY_FAIL]: (state: State, action: ActionWithPayload<{ error: string }>): State => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [LOAD_HOUSE_BILLING_HISTORY_REQUEST]: (state: State): State => ({
            ...state,
            billingHistory: [],
            loading: true,
        }),
        [LOAD_HOUSE_BILLING_HISTORY_SUCCESS]: (
            state: State,
            action: ActionWithPayload<BillingHistoryItem[]>
        ): State => ({
            ...state,
            billingHistory: [...action.payload],
            loading: false,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState): State => state.houseBilling;

export const getHouseBalanceDue = createSelector(stateSelector, (state) => state.balanceDue);

export const getHouseBillingHistory = createSelector(stateSelector, (state) => state.billingHistory);

export const getHouseBillingHistoryLoading = createSelector(stateSelector, (state) => state.loading);

export const getHouseBillingHistoryError = createSelector(stateSelector, (state) => state.error);

export const fetchHouseBalanceDue = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: LOAD_HOUSE_BALANCE_DUE_REQUEST });

        const response = await api.getHouseBalanceDue({
            authToken,
            deployment,
            houseId,
        });

        dispatch({
            payload: response.payload,
            type: LOAD_HOUSE_BALANCE_DUE_SUCCESS,
        });
    } catch (error) {
        dispatch({ error: true, payload: error, type: LOAD_HOUSE_BALANCE_DUE_FAIL });
    }
};

export const fetchHouseBillingHistory =
    (houseId: number, days: number, type: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: LOAD_HOUSE_BILLING_HISTORY_REQUEST });

            const response = await api.getHouseBillingHistory({
                authToken,
                days,
                deployment,
                houseId,
                type,
            });

            dispatch({
                payload: response.payload,
                type: LOAD_HOUSE_BILLING_HISTORY_SUCCESS,
            });
        } catch (error) {
            dispatch({ error: true, payload: error, type: LOAD_HOUSE_BILLING_HISTORY_FAIL });
        }
    };
