import * as api from '../api/bidderFavoriting';
import { ActionWithPayload } from '@/types/redux';
import { BidderFavoritingResponse, FavoriteBidders } from '@/types/BidderFavoriting';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_FAIL,
    LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_REQUEST,
    LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_SUCCESS,
    LOAD_FAVORITE_BIDDER_FAIL,
    LOAD_FAVORITE_BIDDER_NAMEOREMAIL_FAIL,
    LOAD_FAVORITE_BIDDER_NAMEOREMAIL_REQUEST,
    LOAD_FAVORITE_BIDDER_NAMEOREMAIL_SUCCESS,
    LOAD_FAVORITE_BIDDER_REQUEST,
    LOAD_FAVORITE_BIDDER_SUCCESS,
    LOAD_FAVORITE_BIDDERS_FAIL,
    LOAD_FAVORITE_BIDDERS_REQUEST,
    LOAD_FAVORITE_BIDDERS_SUCCESS,
    LOAD_FAVORITE_MASS_BIDDER_FAIL,
    LOAD_FAVORITE_MASS_BIDDER_REQUEST,
    LOAD_FAVORITE_MASS_BIDDER_SUCCESS,
    LOAD_NEUTRALIZE_BIDDER_FAIL,
    LOAD_NEUTRALIZE_BIDDER_REQUEST,
    LOAD_NEUTRALIZE_BIDDER_SUCCESS,
    LOAD_NEUTRALIZE_MASS_BIDDER_FAIL,
    LOAD_NEUTRALIZE_MASS_BIDDER_REQUEST,
    LOAD_NEUTRALIZE_MASS_BIDDER_SUCCESS,
} from './actions';

export type State = {
    additionalFavoriteBiddersCount: number;
    bidders: FavoriteBidders;
    error: boolean;
    favoritingByEmailResponse: number;
    isFavoriting: boolean;
    isLoading: boolean;
    isUnfavoriting: number;
    responseString: BidderFavoritingResponse;
};

/* reducer */
export const DEFAULT_STATE: State = {
    additionalFavoriteBiddersCount: 0,
    bidders: {
        bidders: [
            {
                bidderId: 0,
                bidderName: '',
                houseId: 0,
                joined: '',
                lastEvent: '',
                note: '',
                payment: '',
                type: '',
                typeId: 0,
                userName: '',
                wonItems: 0,
            },
        ],
        page: 0,
        pageSize: 0,
        totalCount: 0,
    },
    error: false,
    favoritingByEmailResponse: 0,
    isFavoriting: false,
    isLoading: false,
    isUnfavoriting: 0,
    responseString: '',
};

export const reducer = handleActions(
    {
        [LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_REQUEST]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: true,
        }),
        [LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                additionalFavoriteBiddersCount: number;
                additionalFavoriteBiddersCountLoading: Boolean;
            }>
        ): State => {
            return {
                ...state,
                additionalFavoriteBiddersCount: action.payload.additionalFavoriteBiddersCount,
                isLoading: false,
            };
        },
        [LOAD_FAVORITE_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_FAVORITE_BIDDER_NAMEOREMAIL_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_FAVORITE_BIDDER_NAMEOREMAIL_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_FAVORITE_BIDDER_NAMEOREMAIL_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                favoritingByEmailResponse: number;
            }>
        ): State => {
            return {
                ...state,
                favoritingByEmailResponse: action.payload.favoritingByEmailResponse,
                isLoading: false,
            };
        },
        [LOAD_FAVORITE_BIDDER_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_FAVORITE_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidderId: Number;
                responseString: BidderFavoritingResponse;
            }>
        ): State => {
            return {
                ...state,
                isLoading: false,
                responseString: action.payload.responseString,
            };
        },
        [LOAD_FAVORITE_BIDDERS_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_FAVORITE_BIDDERS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_FAVORITE_BIDDERS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                {
                    bidders: FavoriteBidders;
                    totalCount: number;
                },
                {
                    page: number;
                    pageSize: number;
                }
            >
        ): State => {
            return {
                ...state,
                bidders: action.payload.bidders,
                isLoading: false,
            };
        },
        [LOAD_NEUTRALIZE_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_NEUTRALIZE_BIDDER_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_NEUTRALIZE_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                responseString: BidderFavoritingResponse;
            }>
        ): State => {
            return {
                ...state,
                isLoading: false,
                responseString: action.payload.responseString,
            };
        },
        [LOAD_NEUTRALIZE_MASS_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            isLoading: false,
        }),
        [LOAD_NEUTRALIZE_MASS_BIDDER_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_NEUTRALIZE_MASS_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                responseString: BidderFavoritingResponse;
            }>
        ): State => {
            return {
                ...state,
                isLoading: false,
                responseString: action.payload.responseString,
            };
        },
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState) => state.bidderFavoriting;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const getError = createSelector(stateSelector, (state) => state.error);
export const getFavoriteBidders = createSelector(stateSelector, (state) => state.bidders);
export const getFavoriteBiddersRecords = createSelector(stateSelector, (state) => state.bidders.totalCount);
export const getAdditionalFavoriteBiddersCount = createSelector(
    stateSelector,
    (state) => state.additionalFavoriteBiddersCount
);

/* ACTION CREATORS */

export const fetchFavoriteBiddersData =
    (houseId: number, page: number, pageSize: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_FAVORITE_BIDDERS_REQUEST,
            });

            const response = await api.getFavoriteBidders({
                authToken,
                deployment,
                houseId,
                page,
                pageSize,
            });
            dispatch({
                meta: { page, pageSize },
                payload: { bidders: response.payload },
                type: LOAD_FAVORITE_BIDDERS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_FAVORITE_BIDDERS_FAIL,
            });
        }
    };

export const fetchAdditionalFavoriteBiddersCount =
    (houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_REQUEST,
            });

            const response = await api.getAdditionalFavoriteBiddersCount({
                authToken,
                deployment,
                houseId,
            });

            dispatch({
                payload: {
                    additionalFavoriteBiddersCount: response.payload,
                    error: false,
                    isLoading: false,
                },
                type: LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_FAIL,
            });
        }
    };

export const postFavoriteBidder =
    (houseId: number, bidderId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_FAVORITE_BIDDER_REQUEST,
            });

            const response = await api.postFavoriteBidder({
                authToken,
                bidderId,
                deployment,
                houseId,
            });

            dispatch({
                payload: { bidderId, responseString: response.payload },
                type: LOAD_FAVORITE_BIDDER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_FAVORITE_BIDDER_FAIL,
            });
        }
    };

export const postFavoriteBidderNameOrEmail =
    (houseId: number, nameOrEmail: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_FAVORITE_BIDDER_NAMEOREMAIL_REQUEST,
            });

            const response = await api.postFavoriteBidderNameOrEmail({
                authToken,
                deployment,
                houseId,
                nameOrEmail,
            });

            return dispatch({
                payload: { favoritingByEmailResponse: response.payload },
                type: LOAD_FAVORITE_BIDDER_NAMEOREMAIL_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_FAVORITE_BIDDER_NAMEOREMAIL_FAIL,
            });
        }
    };

export const postNeutralizeBidder =
    (houseId: number, bidderId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_NEUTRALIZE_BIDDER_REQUEST,
            });

            const response = await api.postNeutralizeBidder({
                authToken,
                bidderId,
                deployment,
                houseId,
            });

            dispatch({
                payload: { bidderId, responseString: response.payload },
                type: LOAD_NEUTRALIZE_BIDDER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_NEUTRALIZE_BIDDER_FAIL,
            });
        }
    };

export const postNeutralizeMassBidders =
    (houseId: number, bidderIds: number[]) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_NEUTRALIZE_MASS_BIDDER_REQUEST,
            });

            const response = await api.postNeutralizeMassBidders({
                authToken,
                bidderIds,
                deployment,
                houseId,
            });
            dispatch({
                payload: { responseString: response.payload },
                type: LOAD_NEUTRALIZE_MASS_BIDDER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_NEUTRALIZE_MASS_BIDDER_FAIL,
            });
        }
    };

export const postFavoriteMassBidders =
    (houseId: number, bidderIds: number[]) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_FAVORITE_MASS_BIDDER_REQUEST,
            });

            const response = await api.postFavoriteMassBidders({
                authToken,
                bidderIds,
                deployment,
                houseId,
            });
            dispatch({
                payload: { responseString: response.payload },
                type: LOAD_FAVORITE_MASS_BIDDER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_FAVORITE_MASS_BIDDER_FAIL,
            });
        }
    };
