import { createSelector } from '@reduxjs/toolkit';
import { EmailAccess, Features } from '@/types/FeatureAccess';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_FEATURE_ACCESS_FAIL,
    LOAD_FEATURE_ACCESS_FAIL_ACTION,
    LOAD_FEATURE_ACCESS_REQUEST,
    LOAD_FEATURE_ACCESS_REQUEST_ACTION,
    LOAD_FEATURE_ACCESS_SUCCESS,
    LOAD_FEATURE_ACCESS_SUCCESS_ACTION,
} from './actions';
import api from '../api/featureAccess';

export type State = {
    error: string;
    features: Features;
    isLoading: boolean;
};

/* reducer */
export const DEFAULT_STATE: State = {
    error: '',
    features: {
        fullUpload: false,
        fullUploadLiveSales: false,
        massEmail: false,
        massEmailLimited: false,
        onlineOnlyPlan: false,
    },
    isLoading: false,
};

export const reducer = handleActions(
    {
        [LOAD_FEATURE_ACCESS_FAIL]: (state: State, action: LOAD_FEATURE_ACCESS_FAIL_ACTION): State => ({
            ...state,
            error: action.payload.error,
            isLoading: false,
        }),
        [LOAD_FEATURE_ACCESS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_FEATURE_ACCESS_SUCCESS]: (state: State, action: LOAD_FEATURE_ACCESS_SUCCESS_ACTION): State => {
            return {
                ...state,
                error: '',
                features: action.payload.features,
                isLoading: false,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.featureAccess;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const getErrorSelector = createSelector(stateSelector, (state) => state.error);
export const getFeatures = createSelector(stateSelector, (state) => state.features);
export const getMassEmailAccess = createSelector(getFeatures, (features): EmailAccess => {
    if (features.massEmail) {
        return 'full';
    } else if (features.massEmailLimited) {
        return 'partial';
    } else {
        return 'none';
    }
});

/* ACTION CREATORS */

export const fetchFeatureAccess = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: { houseId },
            type: LOAD_FEATURE_ACCESS_REQUEST,
        } as LOAD_FEATURE_ACCESS_REQUEST_ACTION);

        const response = await api.getFeatureAccess({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            payload: { features: response.payload },
            type: LOAD_FEATURE_ACCESS_SUCCESS,
        } as LOAD_FEATURE_ACCESS_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            payload: { error, houseId },
            type: LOAD_FEATURE_ACCESS_FAIL,
        } as LOAD_FEATURE_ACCESS_FAIL_ACTION);
    }
};
