import { handleResponse, makePost } from './helpers';

type FetchItemDetailsByIdsApiParams = {
    deployment: string;
    itemIds: number[];
};

export const fetchItemDetailsByIdsApi = ({ deployment, itemIds }: FetchItemDetailsByIdsApiParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>spa/small/item-detail' });
        request.send({ itemIds });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
