import { Carriers } from '@/pages/Shipping/subpages/LabelsPage/components/AvailableCarriers/components/CarrierBadge/CarrierBadge.types';

export enum PackagingFees {
    CalculatedByLiveAuctioneers = 'la',
    Customized = 'house',
    NoFee = 'none',
}

export enum CustomPackagingFees {
    Lot = 'lot',
    Package = 'package',
}

export type PackagingFeeInfo = {
    // The amount for the custom-set fee option
    amount: number;
    // Whether the custom-set fee option uses package or lot
    amountType: CustomPackagingFees;
    // Which type of fee has the house selected
    type: PackagingFees;
};

export enum CarrierOwner {
    House = 'house',
    LiveAuctioneers = 'la',
    ShippingPartner = 'partner',
}

type HouseCarrierInfo = {
    isEnabled: boolean;
    owner: CarrierOwner;
};

export type HouseLabelPermissions = {
    fedexSettings: HouseCarrierInfo;
    isDefault: Carriers;
    isELabel: boolean;
    isInLiveShippingBeta: boolean;
    upsSettings: HouseCarrierInfo;
    uspsSettings: HouseCarrierInfo;
};

// the values of the regular and label keys are an artifact of poorly-understood technologies
export enum Printers {
    Pdf = 'regular',
    Zebra = 'label',
}

export type ShippingLabelsLoadTimes = {
    fee: number;
    printer: number;
};

export type ShippingLabelsLoadingInfo = {
    fee: boolean;
    printer: boolean;
};

export type ShippingLabelsErrors = {
    fee: boolean;
    printer: boolean;
};

export type ShippingLabelsSlice = {
    byId: {
        [houseId: number]: PackagingFeeInfo;
    };
    errors: {
        [houseId: number]: ShippingLabelsErrors;
    };
    loaded: {
        [houseId: number]: ShippingLabelsLoadTimes;
    };
    loading: {
        [houseId: number]: ShippingLabelsLoadingInfo;
    };
    permissions: {
        [houseId: number]: HouseLabelPermissions;
    };
    printers: {
        [houseId: number]: Printers;
    };
};

export const defaultShippingLabelsSlice: ShippingLabelsSlice = {
    byId: {},
    errors: {},
    loaded: {},
    loading: {},
    permissions: {},
    printers: {},
};

export const emptyHouseLabelPermissions: HouseLabelPermissions = {
    fedexSettings: {
        isEnabled: false,
        owner: CarrierOwner.House,
    },
    isDefault: Carriers.None,
    isELabel: false,
    isInLiveShippingBeta: false,
    upsSettings: {
        isEnabled: false,
        owner: CarrierOwner.House,
    },
    uspsSettings: {
        isEnabled: false,
        owner: CarrierOwner.House,
    },
};

export const emptyShippingLabelsLoadTimes: ShippingLabelsLoadTimes = {
    fee: 0,
    printer: 0,
};

export const emptyShippingLabelsLoadingInfo: ShippingLabelsLoadingInfo = {
    fee: false,
    printer: false,
};

export const emptyShippingLabelsErrors: ShippingLabelsErrors = {
    fee: false,
    printer: false,
};
