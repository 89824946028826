let deployment = 'preprod';
const buildNumber = process.env.REACT_APP_BUILD || process.env.BUILD || 'LOCALDEV';
const cachekey = 20170802; // change to break cache.

const hostname = window && window.location && window.location.hostname;

if (hostname.includes('barako')) {
    deployment = 'barako';
} else if (hostname.includes('stage')) {
    deployment = 'stage';
} else if (hostname.includes('preprod')) {
    deployment = 'preprod';
} else if (hostname.includes('prod')) {
    deployment = 'prod';
} else if (hostname.includes('partners')) {
    deployment = 'prod';
} else if (hostname.includes('local')) {
    deployment = process.env.REACT_APP_DEPLOYMENT || 'stage';
}
const isProd = deployment === 'prod' || deployment === 'pc';
const isPreprod = deployment === 'preprod';

const baseUrl = isProd
    ? 'https://partners.liveauctioneers.com'
    : `https://hardboiled-${deployment}.liveauctioneers.com`;

module.exports = {
    baseUrl,
    buildNumber,
    cachekey,
    deployment,
    segmentWriteKey: 'WJyclLRYCIY9z88XZkjfTAttKmU64iTA',
    useSegment: isPreprod || isProd,
};
