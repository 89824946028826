import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_SAVED_ITEM_COUNT_FAIL, LOAD_SAVED_ITEM_COUNT_REQUEST, LOAD_SAVED_ITEM_COUNT_SUCCESS } from './actions';
import { SavedItemCount } from '../../types/SavedItemCount';
import api from '../api/saveItem';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_SAVED_ITEM_COUNT_FAIL]: (state: State, action: ActionWithPayload<{}, { itemIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.itemIds),
        }),
        [LOAD_SAVED_ITEM_COUNT_REQUEST]: (state: State, action: ActionWithPayload<number>) => ({
            ...state,
            // @ts-ignore
            loading: union(state.loading, action.payload),
        }),
        [LOAD_SAVED_ITEM_COUNT_SUCCESS]: (
            state: State,
            action: ActionWithPayload<SavedItemCount[], { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload) {
                action.payload.forEach((itemCount: SavedItemCount) => {
                    existing[itemCount.itemId] = { ...itemCount };
                    loaded[itemCount.itemId] = time;
                    loading = difference(loading, [itemCount.itemId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.savedItemCount;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getSavedItemCount = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || { itemId: id, savedCount: 0 }
);

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.indexOf(id) !== -1;
});

const shouldFetch = (state, itemId) => {
    const data = getSavedItemCount(state, itemId);
    if (data.savedCount !== 0) {
        const loaded = getLoadTime(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state, itemId);
    return !loading;
};

const whichNeeded = (state, itemIds) => itemIds.filter((itemId) => shouldFetch(state, itemId));

/* ACTION CREATORS */
const loadSavedItemCounts = (itemIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: itemIds,
            type: LOAD_SAVED_ITEM_COUNT_REQUEST,
        });
        const response = await api.fetchSavedItemCounts({ deployment, itemIds });

        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.payload,
            type: LOAD_SAVED_ITEM_COUNT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_SAVED_ITEM_COUNT_FAIL,
        });
    }
};

export const fetchSavedItemCountsIfNeeded =
    (itemIds: number[], force: boolean = false) =>
    async (dispatch: Function, getState: Function) => {
        const needed = force ? itemIds : whichNeeded(getState(), itemIds);
        if (needed.length) {
            return dispatch(loadSavedItemCounts(needed));
        }
        return Promise.resolve();
    };
