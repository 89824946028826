import { CatalogPromotion, Promotion } from '@/types/Promotion';
import type { TypedActionWithPayload } from '@/types/redux';

export const CREATE_SELECTED_PROMOTION_FAIL = 'CREATE_SELECTED_PROMOTION_FAIL';
export type CREATE_SELECTED_PROMOTION_FAIL_ACTION = TypedActionWithPayload<
    typeof CREATE_SELECTED_PROMOTION_FAIL,
    string
>;
export const CREATE_SELECTED_PROMOTION_REQUEST = 'CREATE_SELECTED_PROMOTION_REQUEST';
export const CREATE_SELECTED_PROMOTION_SUCCESS = 'CREATE_SELECTED_PROMOTION_SUCCESS';
export const PREVIOUS_SELECTED_PROMOTION_FAIL = 'PREVIOUS_SELECTED_PROMOTION_FAIL';
export type PREVIOUS_SELECTED_PROMOTION_FAIL_ACTION = TypedActionWithPayload<
    typeof PREVIOUS_SELECTED_PROMOTION_FAIL,
    string
>;
export const PREVIOUS_SELECTED_PROMOTION_REQUEST = 'PREVIOUS_SELECTED_PROMOTION_REQUEST';
export const PREVIOUS_SELECTED_PROMOTION_SUCCESS = 'PREVIOUS_SELECTED_PROMOTION_SUCCESS';
export type PREVIOUS_SELECTED_PROMOTION_SUCCESS_ACTION = TypedActionWithPayload<
    typeof PREVIOUS_SELECTED_PROMOTION_SUCCESS,
    {
        catalog: CatalogPromotion;
        promotedLots: number[];
        promotion: Promotion;
        promotionPlan: string;
    }
>;
export const UPDATE_SELECTED_PROMOTION_FAIL = 'UPDATE_SELECTED_PROMOTION_FAIL';
export type UPDATE_SELECTED_PROMOTION_FAIL_ACTION = TypedActionWithPayload<
    typeof UPDATE_SELECTED_PROMOTION_FAIL,
    string
>;
export const UPDATE_SELECTED_PROMOTION_REQUEST = 'UPDATE_SELECTED_PROMOTION_REQUEST';
export const UPDATE_SELECTED_PROMOTION_SUCCESS = 'UPDATE_SELECTED_PROMOTION_SUCCESS';
