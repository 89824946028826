import * as React from 'react';
import { isBrassAdminSelector } from '@/redux/modules/user';
import { NavTab } from '@/components/NavMenu/NavDefinitions';
import { useAppSelector } from '@/redux/hooks';
import { userHasPermission } from '@/utils/user';
import { UserPermissionsType } from '@/utils/permissions';
import NavMenuLink from './NavMenuLink';
import styled from 'styled-components';

export interface NavMenuItemProps {
    children: NavTab[];
    isAdmin?: boolean;
    label: string;
    selected: string;
    url: string;
    userPermissions?: UserPermissionsType;
}

export const NavMenuItem = ({ children, isAdmin = false, label, selected, url, userPermissions }: NavMenuItemProps) => {
    const isBrassAdmin = useAppSelector(isBrassAdminSelector);

    const mapChildren = React.useCallback(
        (tab: NavTab) => {
            const userHasPerms = userHasPermission(userPermissions, tab.permissions);
            if (!isAdmin && !userHasPerms) {
                return;
            }

            if (tab.label === 'Parsing Tool' && !isBrassAdmin) {
                return;
            }

            return (
                <NavMenuLink
                    key={`${tab.label}${tab.url}`}
                    selected={selected === tab.label}
                    url={tab.url}
                >
                    {tab.label}
                </NavMenuLink>
            );
        },
        [isAdmin, isBrassAdmin, selected, userPermissions]
    );

    const subMenu = React.useMemo(() => {
        if (!children) {
            return null;
        }

        const mappedChildren = children.map(mapChildren);

        return <StyledSubmenuTab>{mappedChildren}</StyledSubmenuTab>;
    }, [children, mapChildren]);

    const isSelected = React.useMemo(() => {
        return Boolean(selected === label || (children && children.find((x) => x.label === selected)));
    }, [children, label, selected]);

    return (
        <MenuItemContainer>
            <NavMenuLink
                selected={isSelected}
                url={url}
            >
                {label}
            </NavMenuLink>
            {subMenu}
        </MenuItemContainer>
    );
};

export default NavMenuItem;

const MenuItemContainer = styled.div`
    display: flex;
    margin: 0 5px 0 0;

    & a {
        text-decoration: none;
    }
`;

const StyledSubmenuTab = styled.div`
    margin: 0;
    display: none;
    position: absolute;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.shadowOverlay100};

    ${MenuItemContainer}:hover & {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
`;
