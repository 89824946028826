import { CheckedBidder } from '@/types/BidderTablesData';
import { createSelector } from '@reduxjs/toolkit';
import {
    DISMISS_MODAL,
    OPEN_BIDDER_MASS_FAVORITING_MODAL,
    OPEN_MODAL,
    OPEN_PREMIUM_BIDDER_MODAL,
    OPEN_PREMIUM_BIDDER_OPT_OUT_MODAL,
    OPEN_SEND_BIDDER_MESSAGE_MODAL,
} from './actions';
import { GlobalState } from '@/redux/rootReducer';
import modalTypes from '@/enums/modalTypes';

// reducer
export type State = {
    display: any;
    modal: string;
    sendBidderMassFavoritingData: {
        catalogId: number;
        checkedBidders: CheckedBidder[];
        houseId: number;
    };
    sendBidderMessageData: {
        checkedBidders: CheckedBidder[];
        houseId: number;
    };
    sendPremiumBidderData: {
        houseId: number;
    };
    sendPremiumBidderOptOutData: {
        houseId: number;
    };
};

export const DEFAULT_STATE: State = {
    display: {
        PAGEVIEW_REFERRAL_SOURCES: { catalogId: 0, referralGroupName: '' },
        PERFORMANCE_REGISTRATION_COUNTRY: 0,
    },
    modal: modalTypes.NONE,

    sendBidderMassFavoritingData: {
        catalogId: 0,
        checkedBidders: [],
        houseId: 0,
    },

    sendBidderMessageData: {
        checkedBidders: [],
        houseId: 0,
    },

    sendPremiumBidderData: {
        houseId: 0,
    },

    sendPremiumBidderOptOutData: {
        houseId: 0,
    },
};

export const reducer = (state: State = DEFAULT_STATE, action: any = {}) => {
    switch (action.type) {
        case DISMISS_MODAL:
            return {
                ...state,
                modal: modalTypes.NONE,
            };
        case OPEN_BIDDER_MASS_FAVORITING_MODAL:
            return {
                ...state,
                modal: action.payload.modalType,
                sendBidderMassFavoritingData: {
                    catalogId: action.payload.catalogId,
                    checkedBidders: action.payload.checkedBidders,
                    houseId: action.payload.houseId,
                },
            };
        case OPEN_MODAL:
            return {
                ...state,
                display: {
                    ...state.display,
                    [action.payload.modalType]: action.payload?.id,
                },
                modal: action.payload.modalType,
            };
        case OPEN_SEND_BIDDER_MESSAGE_MODAL:
            return {
                ...state,
                modal: action.payload.modalType,
                sendBidderMessageData: {
                    checkedBidders: action.payload.checkedBidders,
                    houseId: action.payload.houseId,
                },
            };
        case OPEN_PREMIUM_BIDDER_MODAL:
            return {
                ...state,
                modal: action.payload.modalType,
                sendPremiumBidderData: {
                    houseId: action.payload.houseId,
                },
            };
        case OPEN_PREMIUM_BIDDER_OPT_OUT_MODAL:
            return {
                ...state,
                modal: action.payload.modalType,
                sendPremiumBidderOptOutData: {
                    houseId: action.payload.houseId,
                },
            };
        default:
            return state;
    }
};

/* SELECTORS */
const modalSelector = (state: GlobalState) => state.modal;

export const getOpenModal = createSelector(modalSelector, (modal) => modal.modal || modalTypes.NONE);

export const getPerformanceRegistrationCountryModalId = createSelector(
    modalSelector,
    (modal) => modal.display[modalTypes.PERFORMANCE_REGISTRATION_COUNTRY] || 0
);

export const getPageviewReferralSourcesModalId = createSelector(
    modalSelector,
    (modal) => modal.display[modalTypes.PAGEVIEW_REFERRAL_SOURCES] || { catalogId: 0, referralGroupName: '' }
);

export const getSendBiddersMassApprovalData = createSelector(
    modalSelector,
    (modal: State) => modal.sendBidderMassFavoritingData
);

export const getSendBidderMessageData = createSelector(modalSelector, (modal: State) => modal.sendBidderMessageData);

export const getSendPremiumBidderData = createSelector(modalSelector, (modal: State) => modal.sendPremiumBidderData);

export const getsendPremiumBidderOptOutData = createSelector(
    modalSelector,
    (modal: State) => modal.sendPremiumBidderOptOutData
);

export const dismissModal = () => ({
    type: DISMISS_MODAL,
});

// Openers
export const openCreateAnnouncementModal = () => ({
    payload: { modalType: modalTypes.CREATE_ANNOUNCEMENT_MODAL },
    type: OPEN_MODAL,
});

export const openAddCreditCardModal = () => ({
    payload: { modalType: modalTypes.ADD_CREDIT_CARD },
    type: OPEN_MODAL,
});

export const openFeedbackModal = () => ({
    payload: { modalType: modalTypes.FEEDBACK },
    type: OPEN_MODAL,
});

export const openHealthcheckModal = () => ({
    payload: { modalType: modalTypes.HEALTHCHECK },
    type: OPEN_MODAL,
});

export const openMarketingAdvertisingModal = () => ({
    payload: { modalType: modalTypes.MARKETING_ADVERTISING },
    type: OPEN_MODAL,
});

export const openFeaturedLotsModal = () => ({
    payload: { modalType: modalTypes.FEATURED_LOTS },
    type: OPEN_MODAL,
});

export const openCatalogAdsModal = () => ({
    payload: { modalType: modalTypes.CATALOG_ADS },
    type: OPEN_MODAL,
});

export const openPRServicesModal = () => ({
    payload: { modalType: modalTypes.PR_SERVICES },
    type: OPEN_MODAL,
});

export const openSpeedCheckModal = () => ({
    payload: { modalType: modalTypes.SPEED_CHECK },
    type: OPEN_MODAL,
});

export const openPaymentsToggleSameDayModal = () => ({
    payload: { modalType: modalTypes.PAYMENTS_TOGGLE_SAME_DAY },
    type: OPEN_MODAL,
});

export const openPerformanceRegistrationCountryModal = (catalogId: number) => ({
    payload: { id: catalogId, modalType: modalTypes.PERFORMANCE_REGISTRATION_COUNTRY },
    type: OPEN_MODAL,
});

export const openPageviewReferralSourcesModal = ({
    catalogId,
    referralGroupName,
}: {
    catalogId: number;
    referralGroupName: string;
}) => ({
    payload: { id: { catalogId, referralGroupName }, modalType: modalTypes.PAGEVIEW_REFERRAL_SOURCES },
    type: OPEN_MODAL,
});

export const openChangePasswordModal = () => ({
    payload: { modalType: modalTypes.CHANGE_PASSWORD },
    type: OPEN_MODAL,
});

export const openBalancePaymentModal = () => ({
    payload: { modalType: modalTypes.BALANCE_PAYMENT },
    type: OPEN_MODAL,
});

export const openAddAccountingContactModal = () => ({
    payload: { modalType: modalTypes.ACCOUNTING_CONTACT },
    type: OPEN_MODAL,
});

export const openSendBidderMessageModal = (checkedBidders: CheckedBidder[], houseId: number) => ({
    payload: { checkedBidders, houseId, modalType: modalTypes.BIDDER_MESSAGE },
    type: OPEN_SEND_BIDDER_MESSAGE_MODAL,
});

export const openBidderMassFavoritingModal = (catalogId: number, checkedBidders: CheckedBidder[], houseId: number) => ({
    payload: { catalogId, checkedBidders, houseId, modalType: modalTypes.BIDDER_MASS_FAVORITING },
    type: OPEN_BIDDER_MASS_FAVORITING_MODAL,
});

export const openOptOutPremiumModal = (houseId: number) => ({
    payload: { houseId, modalType: modalTypes.BIDDER_GUARANTEE_PROGRAM_OPT_OUT_MODAL },
    type: OPEN_PREMIUM_BIDDER_OPT_OUT_MODAL,
});

export const openLapMandateModal = () => ({
    payload: { modalType: modalTypes.LAP_MANDATE },
    type: OPEN_MODAL,
});
