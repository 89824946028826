import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { FetchConversationsResponse, KeyValues } from './bidderConversations.types';
import { getAuthToken } from '@/redux/modules/user';
import { getBidderConversations } from './bidderConversations.api';
import { getDeployment } from '@/redux/modules/config';

export const fetchBidderConversations = createAsyncThunk<FetchConversationsResponse, KeyValues>(
    'la/domain/bidderConversations/fetchBidderConversations',
    async ({ bidderId, houseId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await getBidderConversations({
            authToken,
            bidderId,
            deployment,
            houseId,
        });

        return payload;
    }
);
