import { handleResponse, makeGet } from './helpers';

type GetHouseBetaParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

export default {
    getHouseBetaEnrollments: ({ authToken, deployment, houseId }: GetHouseBetaParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/beta`,
                authToken,
                deployment,
                path: '<SUPPORT-API>',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
