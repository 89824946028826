import { handleResponse, makeGet } from './helpers';

type FetchSuggestionsParams = {
    deployment: string;
    term: any;
};

export default {
    fetchSuggestions: ({ deployment, term }: FetchSuggestionsParams) => {
        if (!term) {
            return Promise.all([]);
        }
        return new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: 'suggestions',
                deployment,
                path: '<SEARCH-API>',
                queryParams: {
                    auctionHouseQueryCount: 5,
                    itemQueryCount: 0,
                    term,
                },
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        });
    },
};
