import { ActionWithPayload } from '../../types/redux';
import {
    CATALOG_STATS_FILTERS_CHANGE,
    CATALOG_STATS_FILTERS_CHANGE_FAIL,
    LOAD_CATALOG_STATS_DATA_FAIL,
    LOAD_CATALOG_STATS_DATA_REQUEST,
    LOAD_CATALOG_STATS_DATA_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { fetchCatalogStats } from '../api/dashboard';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
export const DEFAULT_STATE = {
    catalogStats: [],
    filters: {
        page: 1,
        pageSize: 24,
        totalRecords: 0,
    },
    loading: false,
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [CATALOG_STATS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<any>) => {
            let newState = {
                ...state,
                filters: { ...state.filters, ...action.payload },
            };
            return newState;
        },
        [LOAD_CATALOG_STATS_DATA_FAIL]: (state: State) => ({
            ...state,
            loading: false,
        }),
        [LOAD_CATALOG_STATS_DATA_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [LOAD_CATALOG_STATS_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ data: any; page: number; pageSize: number; totalRecords: number }, {}>
        ) => {
            let existing = cloneDeep(state.catalogStats);
            const loading = false;

            if (Boolean(action.payload) && Boolean(action.payload.data)) {
                existing = action.payload.data;
            } else {
                existing = [];
            }

            let totalRecords: number = state.filters.totalRecords;
            if (Boolean(action.payload) && Boolean(action.payload.totalRecords)) {
                totalRecords = action.payload.totalRecords;
            } else {
                totalRecords = 0;
            }

            const filters = {
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                totalRecords: totalRecords,
            };

            return {
                ...state,
                catalogStats: existing,
                filters,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.catalogStats;

export const catalogStatsSelector = createSelector(stateSelector, (state) => state.catalogStats);

export const filtersSelector = createSelector(stateSelector, (state) => state.filters);

export const catalogStatsLoadingSelector = createSelector(stateSelector, (state) => state.loading);

/* ACTION CREATORS */
export const onFilterChange = (filters: any) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: CATALOG_STATS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: CATALOG_STATS_FILTERS_CHANGE_FAIL,
        });
    }
};

export const loadCatalogStats =
    (houseId: number, page: number, pageSize: number, sort: string, year: string) => async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                type: LOAD_CATALOG_STATS_DATA_REQUEST,
            });
            const response = await fetchCatalogStats({
                authToken,
                deployment,
                houseId,
                page,
                pageSize,
                sort,
                year,
            });

            return dispatch({
                payload: response,
                type: LOAD_CATALOG_STATS_DATA_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_CATALOG_STATS_DATA_FAIL,
            });
        }
    };

export const fetchCatalogStatsIfNeeded =
    (houseId: number, page: number, pageSize: number, sort: string, year: string) => async (dispatch: Function) => {
        return dispatch(loadCatalogStats(houseId, page, pageSize, sort, year));
    };
