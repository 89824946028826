import * as api from '../api/autoApproval';
import { ActionWithPayload } from '../../types/redux';
import { AutoApprovals, PostCriteria } from '../../types/AutoApprovals';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_ADDITIONAL_FAVORITE_BIDDERS_FAIL,
    LOAD_ADDITIONAL_FAVORITE_BIDDERS_REQUEST,
    LOAD_ADDITIONAL_FAVORITE_BIDDERS_SUCCESS,
    LOAD_AUTO_APPROVAL_CRITERIA_FAIL,
    LOAD_AUTO_APPROVAL_CRITERIA_REQUEST,
    LOAD_AUTO_APPROVAL_CRITERIA_SUCCESS,
    LOAD_POST_AUTO_APPROVAL_CRITERIA_FAIL,
    LOAD_POST_AUTO_APPROVAL_CRITERIA_REQUEST,
    LOAD_POST_AUTO_APPROVAL_CRITERIA_SUCCESS,
} from './actions';

export type State = {
    additionalFavorite: number;
    autoApprovals: AutoApprovals;
    error: boolean;
    isLoading: boolean;
    responseString: string;
};
/* reducer */
export const DEFAULT_STATE: State = {
    additionalFavorite: 0,
    autoApprovals: {
        houseId: 0,
        limited: {
            '1': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '2': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '3': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '4': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '5': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '6': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '7': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '8': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '9': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
        },
        unlimited: {
            '1': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '2': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '3': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '4': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '5': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '6': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '7': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '8': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '9': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            // eslint-disable-next-line
            '10': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
            '11': {
                active: false,
                bidLimit: 0,
                criteriaId: 0,
                dependent: false,
                flag: 0,
                houseCriteriaId: 0,
                lastUpdated: '',
                limited: false,
                threshold: 0,
            },
        },
    },
    error: false,
    isLoading: false,
    responseString: '',
};

export const reducer = handleActions(
    {
        [LOAD_ADDITIONAL_FAVORITE_BIDDERS_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_ADDITIONAL_FAVORITE_BIDDERS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_ADDITIONAL_FAVORITE_BIDDERS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                additionalFavoriteBidders: number;
            }>
        ): State => {
            return {
                ...state,
                additionalFavorite: action.payload.additionalFavoriteBidders,
                isLoading: false,
            };
        },
        [LOAD_AUTO_APPROVAL_CRITERIA_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_AUTO_APPROVAL_CRITERIA_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_AUTO_APPROVAL_CRITERIA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                autoApprovals: AutoApprovals;
            }>
        ): State => {
            return {
                ...state,
                autoApprovals: action.payload.autoApprovals,
                isLoading: false,
            };
        },
        [LOAD_POST_AUTO_APPROVAL_CRITERIA_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_POST_AUTO_APPROVAL_CRITERIA_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_POST_AUTO_APPROVAL_CRITERIA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                responseString: string;
            }>
        ): State => {
            return {
                ...state,
                responseString: action.payload.responseString,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.autoApprovals;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const autoApprovalsCriteriaSelector = createSelector(stateSelector, (state) => state.autoApprovals);
export const unlimitedApprovalsCriteria = createSelector(stateSelector, (state) => state.autoApprovals.unlimited);
export const limitedApprovalCriteria = createSelector(stateSelector, (state) => state.autoApprovals.limited);
export const additionalFavoriteBiddersSelector = createSelector(stateSelector, (state) => state.additionalFavorite);

/* ACTION CREATORS */
export const fetchAutoApprovalsCriteria = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_AUTO_APPROVAL_CRITERIA_REQUEST,
        });

        const response = await api.getAutoApprovalCriteria({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            payload: { autoApprovals: response.payload },
            type: LOAD_AUTO_APPROVAL_CRITERIA_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: LOAD_AUTO_APPROVAL_CRITERIA_FAIL,
        });
    }
};

export const postCreateAutoApprovalCriteria =
    (criteria: PostCriteria, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_POST_AUTO_APPROVAL_CRITERIA_REQUEST,
            });

            const response = await api.postCreateAutoApprovalCriteria({
                authToken,
                criteria,
                deployment,
                houseId,
            });
            dispatch({
                payload: { responseString: response.payload },
                type: LOAD_POST_AUTO_APPROVAL_CRITERIA_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_POST_AUTO_APPROVAL_CRITERIA_FAIL,
            });
        }
    };

export const fetchAdditionalFavoriteBidders = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_ADDITIONAL_FAVORITE_BIDDERS_REQUEST,
        });

        const response = await api.getAdditionalFavoriteBidders({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            payload: { additionalFavoriteBidders: response.payload },
            type: LOAD_ADDITIONAL_FAVORITE_BIDDERS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: LOAD_ADDITIONAL_FAVORITE_BIDDERS_FAIL,
        });
    }
};
