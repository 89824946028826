export enum AutomationSettingsCopyBiddersWalkthroughEnum {
    CopyBidders = 'automation-settings-copy-all-previously-approved-bidders',
}

export type AutomationSettingsCopyBiddersWalkthroughStepName = AutomationSettingsCopyBiddersWalkthroughEnum.CopyBidders;

export enum AutomationSettingsFavoriteBidderRecommendationsWalkthroughEnum {
    FavoriteBidderRecommendations = 'automation-settings-favorite-bidder-recommendations',
}

export type AutomationSettingsFavoriteBidderRecommendationsWalkthroughStepName =
    AutomationSettingsFavoriteBidderRecommendationsWalkthroughEnum.FavoriteBidderRecommendations;
