import { BuyNows, Item } from '@/types/CatalogData';
import type { TypedActionWithPayload } from '@/types/redux';

export const CATALOG_DATA_FAILURE = 'CATALOG_DATA_FAILURE';
export const CATALOG_DATA_REQUEST = 'CATALOG_DATA_REQUEST';
export const CATALOG_DATA_SUCCESS = 'CATALOG_DATA_SUCCESS';
export type CATALOG_DATA_SUCCESS_ACTION = TypedActionWithPayload<
    typeof CATALOG_DATA_SUCCESS,
    {
        buyNows: BuyNows[];
        items: Item[];
        totalCount: number;
    }
>;
export const SUBMIT_COVERLOTS_FAIL = 'SUBMIT_COVERLOTS_FAIL';
export const SUBMIT_COVERLOTS_REQUEST = 'SUBMIT_COVERLOTS_REQUEST';
export const SUBMIT_COVERLOTS_SUCCESS = 'SUBMIT_COVERLOTS_SUCCESS';
export const FETCH_COVERLOTS_FAIL = 'FETCH_COVERLOTS_FAIL';
export const FETCH_COVERLOTS_REQUEST = 'FETCH_COVERLOTS_REQUEST';
export const FETCH_COVERLOTS_SUCCESS = 'FETCH_COVERLOTS_SUCCESS';
export type FETCH_COVERLOTS_SUCCESS_ACTION = TypedActionWithPayload<typeof FETCH_COVERLOTS_SUCCESS, number[]>;
