import { DedicatedFollowers } from '@/types/DedicatedFollowers';
import { handleResponse, makeGet, makePost } from './helpers';

type FetchDedicatedFollowersParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

type PostDedicatedFollowersParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    followerCount: number;
    houseId: number;
    lotIds: number[];
};

type FetchDedicatedFollowersPayload = {
    error: boolean;
    payload: DedicatedFollowers;
};

type PostDedicatedFollowersPayload = {
    error: boolean;
    message: string;
};

export default {
    fetchDedicatedFollowers: ({ authToken, catalogId, deployment }: FetchDedicatedFollowersParams) =>
        new Promise<FetchDedicatedFollowersPayload>((resolve, reject) => {
            const request = makeGet({
                apiPath: `dedicatedfolloweralert/${catalogId}`,
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.end((err: boolean, response: FetchDedicatedFollowersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),

    postDedicatedFollowers: ({
        authToken,
        catalogId,
        deployment,
        followerCount,
        houseId,
        lotIds,
    }: PostDedicatedFollowersParams) =>
        new Promise<PostDedicatedFollowersPayload>((resolve, reject) => {
            const request = makePost({
                apiPath: 'dedicatedfolloweralert',
                authToken,
                deployment,
                path: '<PROMOTION-API-TWO>',
            });
            request.send({ catalogId, followerCount, houseId, lotIds });
            request.end((err: boolean, response: PostDedicatedFollowersPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
