import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { postHouseResetPassword } from '../api/auth';
import { SUBMIT_RESET_PASSWORD_FAIL, SUBMIT_RESET_PASSWORD_REQUEST, SUBMIT_RESET_PASSWORD_SUCCESS } from './actions';

/* reducer */
export const DEFAULT_STATE = {
    error: null,
    submitted: false,
    success: false,
};

export type State = typeof DEFAULT_STATE & {
    error: string | null | undefined;
};

export const reducer = handleActions(
    {
        [SUBMIT_RESET_PASSWORD_FAIL]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            submitted: false,
            success: false,
        }),
        [SUBMIT_RESET_PASSWORD_REQUEST]: (state: State) => ({
            ...state,
            error: null,
            submitted: true,
            success: false,
        }),
        [SUBMIT_RESET_PASSWORD_SUCCESS]: (state: State) => ({
            ...state,
            error: null,
            submitted: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.resetPassword;

export const getResetPasswordState = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
export const submitResetPassword =
    (userId: number, hash: string, newPassword: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            dispatch({
                payload: userId,
                type: SUBMIT_RESET_PASSWORD_REQUEST,
            });
            const response = await postHouseResetPassword({
                deployment,
                hash,
                newPassword,
                userId,
            });

            return dispatch({
                payload: response.data,
                type: SUBMIT_RESET_PASSWORD_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_RESET_PASSWORD_FAIL,
            });
        }
    };
