import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { LOAD_BIDDER_APPROVAL_FAIL, LOAD_BIDDER_APPROVAL_REQUEST, LOAD_BIDDER_APPROVAL_SUCCESS } from './actions';
import api from '../api/bidderApproval';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

const createCatalogRegistration = (payload) => {
    if (!payload) {
        return {
            registered: false,
        };
    }
    const { approvalStatus, bidLimit, catalogId } = payload;
    return {
        approved: approvalStatus === 1,
        bidLimit: bidLimit,
        blockedByAuctioneer: approvalStatus === 4,
        catalogId: catalogId,
        declined: approvalStatus === 2,
        limited: approvalStatus === 3,
        pending: approvalStatus === 0,
        registered: true,
        suspended: approvalStatus === 5,
    };
};

/* reducer */
export type State = {
    byId: { [id: number]: any };
    loaded: { [id: number]: number };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state: State = DEFAULT_STATE, action: any = {}): State {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_BIDDER_APPROVAL_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
            };
        case LOAD_BIDDER_APPROVAL_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_BIDDER_APPROVAL_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (existing[action.meta.bidderId]) {
                existing[action.meta.bidderId].catalogs[action.meta.catalogId] = action.payload;
            } else {
                existing[action.meta.bidderId] = { catalogs: { [action.meta.catalogId]: action.payload } };
            }

            loaded[action.payload.bidderId] = time;
            loading = difference(loading, [action.payload.catalogId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.bidderApproval;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);
const loadingSelector = createSelector(stateSelector, (state) => state.loading);
const getBidderCatalogs = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]?.catalogs || {});

export const getBidderApproval = (state: GlobalState, catalogId: number, bidderId: number) => {
    const bidderCatalogs = getBidderCatalogs(state, bidderId);
    if (bidderCatalogs?.[catalogId]) {
        return bidderCatalogs?.[catalogId];
    }
    return {};
};

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetch = (state, catalogId) => {
    const loading = isLoading(state, catalogId);
    return !loading;
};

/* ACTION CREATORS */
export const fetchBidderApprovalIfNeeded =
    (catalogId: number, bidderId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetch(state, catalogId)) {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            try {
                dispatch({
                    meta: { actionTime: Date.now() },
                    payload: catalogId,
                    type: LOAD_BIDDER_APPROVAL_REQUEST,
                });
                const response = await api.getBidderApproval({ authToken, bidderId, catalogId, deployment });
                dispatch({
                    meta: { actionTime: Date.now(), bidderId, catalogId },
                    payload: createCatalogRegistration(response.payload),
                    type: LOAD_BIDDER_APPROVAL_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { catalogId },
                    payload: error,
                    type: LOAD_BIDDER_APPROVAL_FAIL,
                });
            }
        } else {
            return Promise.resolve();
        }
    };
