import { ActionWithPayload } from '../../types/redux';
import { BidderStatusFilters } from '../../types/BidderApproval';
import { BidderTables } from '../../types/BidderTablesData';
import { BidderTableSortOrder } from '@/types/TableSortOrders';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_BIDDER_TABLE_FAIL,
    LOAD_BIDDER_TABLE_REQUEST,
    LOAD_BIDDER_TABLE_SUCCESS,
    LOAD_FAVORITE_BIDDER_SUCCESS,
    LOAD_NEUTRALIZE_BIDDER_SUCCESS,
} from './actions';
import api from '../api/bidderTables';

export type State = {
    bidders: BidderTables;
    error: boolean;
    isLoading: boolean;
};
/* reducer */
export const DEFAULT_STATE: State = {
    bidders: {
        approvals: [
            {
                approvalStatusId: 0,
                approvalTypeId: 0,
                bidCount: 0,
                bidderCreated: '',
                bidderId: 0,
                bidderInitiated: false,
                bidderName: '',
                bidLimit: 0,
                cardOnFile: false,
                catalogId: 0,
                catalogTitle: '',
                closedDisputeDetails: [
                    {
                        amount: 0,
                        created: '',
                        currencyCode: 'USD',
                        houseName: '',
                    },
                ],
                created: '',
                disputeDetails: [
                    {
                        amount: 0,
                        created: '',
                        currencyCode: 'USD',
                        houseName: '',
                    },
                ],
                hasClosedDisputes: false,
                hasDisputes: false,
                houseId: 0,
                isFavorited: false,
                isPremium: false,
                paymentInfo: '',
                personalDetails: {
                    City: '',
                    Country: '',
                    Created: '',
                    Email: '',
                    FirstName: '',
                    LastName: '',
                    PhoneNumber: '',
                    ProfileComplete: false,
                    State: '',
                    UserName: '',
                },
                prediction: '',
                recommendedFavorite: false,
                registrationId: 0,
            },
        ],
        page: 0,
        pageSize: 0,
        totalCount: 0,
    },
    error: false,
    isLoading: false,
};

export const reducer = handleActions(
    {
        [LOAD_BIDDER_TABLE_FAIL]: (state: State): State => ({
            ...state,
            isLoading: false,
        }),
        [LOAD_BIDDER_TABLE_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BIDDER_TABLE_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidders: BidderTables;
            }>
        ): State => {
            return {
                ...state,
                bidders: action.payload.bidders,
                isLoading: false,
            };
        },
        [LOAD_FAVORITE_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidderId: number;
            }>
        ): State => {
            const newState = { ...state };
            newState.bidders.approvals = newState.bidders.approvals.map((approval) => {
                if (approval.bidderId === action.payload.bidderId) {
                    approval.isFavorited = true;
                    newState.isLoading = false;
                }
                return approval;
            });
            return newState;
        },
        [LOAD_NEUTRALIZE_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidderId: number;
            }>
        ): State => {
            const newState = { ...state };
            newState.bidders.approvals = newState.bidders.approvals.map((approval) => {
                if (approval.bidderId === action.payload.bidderId) {
                    approval.isFavorited = false;
                    newState.isLoading = false;
                }
                return approval;
            });
            return newState;
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.bidderTables;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const biddersSelector = createSelector(stateSelector, (state) => state.bidders.approvals);
export const getBidderTableRecords = createSelector(stateSelector, (state) => state.bidders.totalCount);

/* ACTION CREATORS */

export const fetchBidderTablesData =
    (
        catalogId: number,
        filter: BidderStatusFilters[],
        houseId: number,
        page: number,
        pageSize: number,
        search: string,
        sort: BidderTableSortOrder,
        status: number
    ) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BIDDER_TABLE_REQUEST,
            });

            const response = await api.getBidderTables({
                authToken,
                catalogId,
                deployment,
                filter,
                houseId,
                page,
                pageSize,
                search,
                sort,
                status,
            });
            dispatch({
                payload: { bidders: response.payload },
                type: LOAD_BIDDER_TABLE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_BIDDER_TABLE_FAIL,
            });
        }
    };
