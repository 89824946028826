import { handleResponse, makeGet } from './helpers';

type GetBidLimitBalanceParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
};

export default {
    getBidderApproval: ({ authToken, bidderId, catalogId, deployment }: GetBidLimitBalanceParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `catalog/${catalogId}/bidder/${bidderId}`,
                authToken,
                deployment,
                path: '<APPROVAL-SERVER-API>',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
