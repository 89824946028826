import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';

/* Action Types */
export const UPDATE_DEVICES_SUCCESS = 'UPDATE_DEVICES_SUCCESS';
export const UPDATE_DEVICES_FAIL = 'UPDATE_DEVICES_FAIL';
export const START_VIDEO_STREAM = 'START_VIDEO_STREAM';
export const MEDIA_DEVICE_FAIL = 'MEDIA_DEVICE_FAIL';

export const supportedResolutions = [
    { height: 720, key: '720p', type: '16:9', width: 1280 },
    { height: 480, key: 'VGA', type: '4:3', width: 640 },
];

/* reducer */
export const DEFAULT_STATE = {
    audioSources: [],
    error: null,
    streamObj: null,
    videoSources: [],
};

export type State = typeof DEFAULT_STATE & {
    error: string | null | undefined;
    streamObj: any | null | undefined;
};

export const reducer = handleActions(
    {
        [MEDIA_DEVICE_FAIL]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: getErrorMsg(action.payload.error),
        }),
        [START_VIDEO_STREAM]: (state: State, action: ActionWithPayload<{ streamObj: any }>) => ({
            ...state,
            error: null,
            streamObj: action.payload.streamObj,
        }),
        [UPDATE_DEVICES_FAIL]: (state: State) => ({
            ...state,
            audioSources: [],
            videoSources: [],
        }),
        [UPDATE_DEVICES_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ audioSources: any[]; videoSources: any[] }>
        ) => ({
            ...state,
            audioSources: [...action.payload.audioSources],
            videoSources: [...action.payload.videoSources],
        }),
    },
    DEFAULT_STATE
);

const getErrorMsg = (error: any = { message: '' }) => {
    if (!error.name) {
        return 'This browser does not support video streaming.';
    }

    const { message, name } = error;

    if (name.indexOf('NotFoundError') !== -1) {
        return 'The Camera or Mic are not found or not allowed on your device';
    } else if (name.indexOf('NotReadableError') !== -1 || name.indexOf('TrackStartError') !== -1) {
        return 'The Camera or Mic is being used by some other app';
    } else if (name.indexOf('OverconstrainedError') !== -1 || name.indexOf('ConstraintNotSatisfiedError') !== -1) {
        return 'There is no device found that fits your video resolution. You may change the video resolution to try again';
    } else if (name.indexOf('NotAllowedError') !== -1 || name.indexOf('PermissionDeniedError') !== -1) {
        return 'This webpage is not allowed to access the Camera or Mic';
    } else if (name.indexOf('TypeError') !== -1) {
        return 'Video/Audio is required';
    }

    return message;
};

/* SELECTORS */
const selector = (state) => state.stream;
export const getStreamErrors = createSelector(selector, (s) => s.error);
export const getVideoSources = createSelector(selector, (s) => s.videoSources);
export const getAudioSources = createSelector(selector, (s) => s.audioSources);
export const getStreamObj = createSelector(selector, (s) => s.streamObj);
export const getUserMediaConstraints = ({ audioInput, resolution, videoInput }: any) => ({
    audio: { deviceId: audioInput ? { exact: audioInput } : undefined },
    video: {
        deviceId: videoInput ? { exact: videoInput } : undefined,
        frameRate: { ideal: 15, max: 30 },
        height: { exact: resolution.height },
        width: { exact: resolution.width },
    },
});

export const hasMediaDeviceSupport = () =>
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices;

/* ACTION CREATORS */
export const fetchUserDevices = () => async (dispatch: Function) => {
    try {
        if (!hasMediaDeviceSupport()) {
            dispatch({ payload: { error: 'This browser does not support video streaming.' }, type: MEDIA_DEVICE_FAIL });
            return;
        }

        const infos = await navigator.mediaDevices.enumerateDevices();
        const inputs = infos.reduce(
            (acc, i) => {
                let key = i.kind;
                if (i.kind === 'audioinput') {
                    // @ts-ignore
                    key = 'audioSources';
                } else if (i.kind === 'videoinput') {
                    // @ts-ignore
                    key = 'videoSources';
                }
                acc[key] = [...(acc[key] || []), i];
                return acc;
            },
            { audioSources: [], videoSources: [] }
        );

        if (inputs.videoSources.length === 0) {
            dispatch({
                payload: {
                    error: { message: 'No video devices were detected. Add an external camera and try again.' },
                },
                type: MEDIA_DEVICE_FAIL,
            });
            return;
        }

        dispatch({
            payload: { ...inputs },
            type: UPDATE_DEVICES_SUCCESS,
        });
    } catch (error) {
        dispatch({ payload: { error }, type: UPDATE_DEVICES_FAIL });
    }
};

export const getVideoStream =
    ({ audioInput, resolution, videoInput }: any) =>
    async (dispatch: Function, getState: Function) => {
        try {
            if (!hasMediaDeviceSupport()) {
                dispatch({
                    payload: { error: 'This browser does not support video streaming.' },
                    type: MEDIA_DEVICE_FAIL,
                });
                return;
            }

            const state = getState();

            const currentStreamSrcObj = getStreamObj(state);
            if (currentStreamSrcObj) {
                currentStreamSrcObj.getTracks().forEach((track) => track.stop());
            }

            const constraints = getUserMediaConstraints({ audioInput, resolution, videoInput });

            const videoStream = await navigator.mediaDevices.getUserMedia(constraints);

            dispatch({ payload: { srcObject: videoStream }, type: START_VIDEO_STREAM });
            return videoStream;
        } catch (error) {
            dispatch({ payload: { error }, type: MEDIA_DEVICE_FAIL });
        }
    };
