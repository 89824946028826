import { Invoice, InvoiceCalcualteRefund, InvoiceSendHistory } from '@/types/Invoice';
import { TypedActionWithPayload } from '@/types/redux';

export const LOAD_INVOICE_FAIL = 'LOAD_INVOICE_FAIL';
export type LOAD_INVOICE_FAIL_ACTION = TypedActionWithPayload<typeof LOAD_INVOICE_FAIL, string>;
export const LOAD_INVOICE_REQUEST = 'LOAD_INVOICE_REQUEST';
export type LOAD_INVOICE_REQUEST_ACTION = TypedActionWithPayload<typeof LOAD_INVOICE_REQUEST, number>;
export const LOAD_INVOICE_SUCCESS = 'LOAD_INVOICE_SUCCESS';
export type LOAD_INVOICE_SUCCESS_ACTION = TypedActionWithPayload<typeof LOAD_INVOICE_SUCCESS, Invoice>;

export const LOAD_INVOICE_SEND_HISTORY_FAIL = 'LOAD_INVOICE_SEND_HISTORY_FAIL';
export type LOAD_INVOICE_SEND_HISTORY_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_INVOICE_SEND_HISTORY_FAIL,
    string
>;
export const LOAD_INVOICE_SEND_HISTORY_REQUEST = 'LOAD_INVOICE_SEND_HISTORY_REQUEST';
export const LOAD_INVOICE_SEND_HISTORY_SUCCESS = 'LOAD_INVOICE_SEND_HISTORY_SUCCESS';
export type LOAD_INVOICE_SEND_HISTORY_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_INVOICE_SEND_HISTORY_SUCCESS,
    InvoiceSendHistory[]
>;

export const POST_INVOICE_SEND_FAIL = 'POST_INVOICE_SEND_FAIL';
export type POST_INVOICE_SEND_FAIL_ACTION = TypedActionWithPayload<typeof POST_INVOICE_SEND_FAIL, string>;
export const POST_INVOICE_SEND_REQUEST = 'POST_INVOICE_SEND_REQUEST';
export const POST_INVOICE_SEND_SUCCESS = 'POST_INVOICE_SEND_SUCCESS';

export const POST_REFUND_TAX_FAIL = 'POST_REFUND_TAX_FAIL';
export type POST_REFUND_TAX_FAIL_ACTION = TypedActionWithPayload<typeof POST_REFUND_TAX_FAIL, string>;
export const POST_REFUND_TAX_REQUEST = 'POST_REFUND_TAX_REQUEST';
export const POST_REFUND_TAX_SUCCESS = 'POST_REFUND_TAX_SUCCESS';

export const POST_VOID_TAX_FAIL = 'POST_VOID_TAX_FAIL';
export type POST_VOID_TAX_FAIL_ACTION = TypedActionWithPayload<typeof POST_VOID_TAX_FAIL, string>;
export const POST_VOID_TAX_REQUEST = 'POST_VOID_TAX_REQUEST';
export const POST_VOID_TAX_SUCCESS = 'POST_VOID_TAX_SUCCESS';

export const POST_INVOICE_MARK_PAID_FAIL = 'POST_INVOICE_MARK_PAID_FAIL';
export type POST_INVOICE_MARK_PAID_FAIL_ACTION = TypedActionWithPayload<typeof POST_INVOICE_MARK_PAID_FAIL, string>;
export const POST_INVOICE_MARK_PAID_REQUEST = 'POST_INVOICE_MARK_PAID_REQUEST';
export const POST_INVOICE_MARK_PAID_SUCCESS = 'POST_INVOICE_MARK_PAID_SUCCESS';

export const POST_INVOICE_UPDATE_SHIPPING_FAIL = 'POST_INVOICE_UPDATE_SHIPPING_FAIL';
export type POST_INVOICE_UPDATE_SHIPPING_FAIL_ACTION = TypedActionWithPayload<
    typeof POST_INVOICE_UPDATE_SHIPPING_FAIL,
    string
>;
export const POST_INVOICE_UPDATE_SHIPPING_REQUEST = 'POST_INVOICE_UPDATE_SHIPPING_REQUEST';
export const POST_INVOICE_UPDATE_SHIPPING_SUCCESS = 'POST_INVOICE_UPDATE_SHIPPING_SUCCESS';

export const POST_INVOICE_ADJUSTMENT_FAIL = 'POST_INVOICE_ADJUSTMENT_FAIL';
export type POST_INVOICE_ADJUSTMENT_FAIL_ACTION = TypedActionWithPayload<typeof POST_INVOICE_ADJUSTMENT_FAIL, string>;
export const POST_INVOICE_ADJUSTMENT_REQUEST = 'POST_INVOICE_ADJUSTMENT_REQUEST';
export const POST_INVOICE_ADJUSTMENT_SUCCESS = 'POST_INVOICE_ADJUSTMENT_SUCCESS';

export const POST_INVOICE_SHIPPING_METHOD_FAIL = 'POST_INVOICE_SHIPPING_METHOD_FAIL';
export type POST_INVOICE_SHIPPING_METHOD_FAIL_ACTION = TypedActionWithPayload<
    typeof POST_INVOICE_SHIPPING_METHOD_FAIL,
    string
>;
export const POST_INVOICE_SHIPPING_METHOD_REQUEST = 'POST_INVOICE_SHIPPING_METHOD_REQUEST';
export const POST_INVOICE_SHIPPING_METHOD_SUCCESS = 'POST_INVOICE_SHIPPING_METHOD_SUCCESS';

export const POST_INVOICE_ADD_PAYMENT_FAIL = 'POST_INVOICE_ADD_PAYMENT_FAIL';
export type POST_INVOICE_ADD_PAYMENT_FAIL_ACTION = TypedActionWithPayload<typeof POST_INVOICE_ADD_PAYMENT_FAIL, string>;
export const POST_INVOICE_ADD_PAYMENT_REQUEST = 'POST_INVOICE_ADD_PAYMENT_REQUEST';
export const POST_INVOICE_ADD_PAYMENT_SUCCESS = 'POST_INVOICE_ADD_PAYMENT_SUCCESS';

export const DELETE_INVOICE_ADJUSTMENT_FAIL = 'DELETE_INVOICE_ADJUSTMENT_FAIL';
export type DELETE_INVOICE_ADJUSTMENT_FAIL_ACTION = TypedActionWithPayload<
    typeof DELETE_INVOICE_ADJUSTMENT_FAIL,
    string
>;
export const DELETE_INVOICE_ADJUSTMENT_REQUEST = 'DELETE_INVOICE_ADJUSTMENT_REQUEST';
export type DELETE_INVOICE_ADJUSTMENT_REQUEST_ACTION = TypedActionWithPayload<
    typeof DELETE_INVOICE_ADJUSTMENT_REQUEST,
    number
>;
export const DELETE_INVOICE_ADJUSTMENT_SUCCESS = 'DELETE_INVOICE_ADJUSTMENT_SUCCESS';

export const DELETE_INVOICE_PAYMENT_FAIL = 'DELETE_INVOICE_PAYMENT_FAIL';
export type DELETE_INVOICE_PAYMENT_FAIL_ACTION = TypedActionWithPayload<typeof DELETE_INVOICE_PAYMENT_FAIL, string>;
export const DELETE_INVOICE_PAYMENT_REQUEST = 'DELETE_INVOICE_PAYMENT_REQUEST';
export type DELETE_INVOICE_PAYMENT_REQUEST_ACTION = TypedActionWithPayload<
    typeof DELETE_INVOICE_PAYMENT_REQUEST,
    number
>;
export const DELETE_INVOICE_PAYMENT_SUCCESS = 'DELETE_INVOICE_PAYMENT_SUCCESS';

export const REFUND_INVOICE_FAIL = 'REFUND_INVOICE_FAIL';
export type REFUND_INVOICE_FAIL_ACTION = TypedActionWithPayload<typeof REFUND_INVOICE_FAIL, string>;
export const REFUND_INVOICE_REQUEST = 'REFUND_INVOICE_REQUEST';
export const REFUND_INVOICE_SUCCESS = 'REFUND_INVOICE_SUCCESS';

export const RECALCULATE_INVOICE_FAIL = 'RECALCULATE_INVOICE_FAIL';
export type RECALCULATE_INVOICE_FAIL_ACTION = TypedActionWithPayload<typeof RECALCULATE_INVOICE_FAIL, string>;
export const RECALCULATE_INVOICE_REQUEST = 'RECALCULATE_INVOICE_REQUEST';
export const RECALCULATE_INVOICE_SUCCESS = 'RECALCULATE_INVOICE_SUCCESS';
export type RECALCULATE_INVOICE_SUCCESS_ACTION = TypedActionWithPayload<
    typeof RECALCULATE_INVOICE_SUCCESS,
    InvoiceCalcualteRefund
>;
