import { ActionWithPayload } from '@/types/redux';
import { BiddingInfo } from '@/types/BiddingInfo';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { fetchItemBidding } from '../api/biddingInfo';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LOAD_CATALOG_BIDDING_SUCCESS,
    LOAD_ITEM_BIDDING_FAIL,
    LOAD_ITEM_BIDDING_REQUEST,
    LOAD_ITEM_BIDDING_SUCCESS,
    LOAD_ITEMS_SUCCESS,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME_ITEM = ms('20s');

// reducer
export type BiddingInfoState = {
    byId: {
        [itemId: number]: BiddingInfo;
    };
    loaded: {
        [itemId: number]: number;
    };
    loading: number[];
};

export const DEFAULT_STATE: BiddingInfoState = {
    byId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LIVE_BID_ACCEPTED]: (state: BiddingInfoState, action: LIVE_BID_ACCEPTED_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            existing.bidderHasBid = action.payload.myBid ? true : existing.bidderHasBid;
            existing.leadingBid = action.payload.amount;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LIVE_BID_RETRACTED]: (state: BiddingInfoState, action: LIVE_BID_RETRACTED_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            // TODO: not sure how to handle the LIVE retracted case.  I don't know if the bidder had a different bid
            // myBid refers to the current leading bid
            // existing.bidderHasBid = action.payload.myBid ? true : existing.bidderHasBid;
            existing.leadingBid = action.payload.amount;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LIVE_LOT_PASSED]: (state: BiddingInfoState, action: LIVE_LOT_PASSED_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            existing.isAvailable = false;
            existing.isPassed = true;
            existing.isSold = false;
            existing.isUnsold = false;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LIVE_LOT_REOPENED]: (state: BiddingInfoState, action: LIVE_LOT_REOPENED_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            existing.isAvailable = true;
            existing.isPassed = false;
            existing.isSold = false;
            existing.isUnsold = false;
            existing.salePrice = 0;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LIVE_LOT_SOLD]: (state: BiddingInfoState, action: LIVE_LOT_SOLD_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            existing.bidderHasBid = action.payload.myBid ? true : existing.bidderHasBid;
            existing.bidderHasHighBid = action.payload.myBid;
            existing.isAvailable = false;
            existing.isPassed = false;
            existing.isSold = true;
            existing.isUnsold = false;
            existing.leadingBid = action.payload.amount;
            existing.salePrice = action.payload.amount;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LIVE_LOT_UNSOLD]: (state: BiddingInfoState, action: LIVE_LOT_UNSOLD_ACTION) => {
            const itemId = action.payload.itemId;

            const existing = cloneDeep(state.byId[itemId] || {}) as BiddingInfo;
            existing.isAvailable = false;
            existing.isPassed = false;
            existing.isSold = false;
            existing.isUnsold = true;
            return {
                ...state,
                byId: { ...state.byId, [itemId]: existing },
            };
        },
        [LOAD_ITEM_BIDDING_FAIL]: (state: BiddingInfoState, action: ActionWithPayload<{}, { itemId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.itemId]),
        }),
        [LOAD_ITEM_BIDDING_REQUEST]: (state: BiddingInfoState, action: ActionWithPayload<number>) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [combineActions(LOAD_CATALOG_BIDDING_SUCCESS, LOAD_ITEM_BIDDING_SUCCESS)]: (
            state: BiddingInfoState,
            action: ActionWithPayload<BiddingInfo[], { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            action.payload.forEach((item) => {
                const previousLeadingBid = (existing[item.itemId] || {}).leadingBid || 0;
                existing[item.itemId] = { ...item };
                if (existing[item.itemId].leadingBid < previousLeadingBid) {
                    existing[item.itemId].leadingBid = previousLeadingBid;
                }
                loaded[item.itemId] = time;
                loading = difference(loading, [item.itemId]);
            });
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
        [LOAD_ITEMS_SUCCESS]: (
            state: BiddingInfoState,
            action: ActionWithPayload<{ biddingInfos: BiddingInfo[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.biddingInfos) {
                action.payload.biddingInfos.forEach((item) => {
                    const previousLeadingBid = (existing[item.itemId] || {}).leadingBid || 0;
                    existing[item.itemId] = { ...item };
                    if (existing[item.itemId].leadingBid < previousLeadingBid) {
                        existing[item.itemId].leadingBid = previousLeadingBid;
                    }
                    loaded[item.itemId] = time;
                    loading = difference(loading, [item.itemId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): BiddingInfoState => state.biddingInfo;
const globalStateSelector = (state: GlobalState) => state;
const idSelector = (state: GlobalState, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getBiddingInfo = createSelector(
    [byIdSelector, idSelector],
    (byId, id): BiddingInfo =>
        byId[id] || {
            bidActivityCount: 0,
            bidCount: 0,
            bidderBidTimestamp: 0,
            bidderHasBid: false,
            bidderHasHighBid: false,
            bidderMaxBid: 0,
            isAvailable: false,
            isLocked: false,
            isPassed: false,
            isReserveMet: false,
            isSold: false,
            isUnsold: false,
            itemId: id,
            leadingBid: 0,
            salePrice: 0,
        }
);

export const getLoadTimeForItemBidding = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isItemBiddingLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const getBiddingInfos = createSelector([globalStateSelector, idSelector], (state, itemIds) =>
    itemIds.map((id) => getBiddingInfo(state, id))
);

const shouldFetchBiddingInfoForItem = createSelector(
    [idSelector, getLoadTimeForItemBidding, isItemBiddingLoading],
    (itemId, loaded, loading) => {
        if (!itemId) {
            return false;
        }
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME_ITEM) {
            return false;
        }
        return !loading;
    }
);

/* ACTION CREATORS */
export const fetchItemBiddingIfNeeded = (itemId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    if (shouldFetchBiddingInfoForItem(state, itemId)) {
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                meta: { actionTime: Date.now() },
                payload: itemId,
                type: LOAD_ITEM_BIDDING_REQUEST,
            });
            const response = await fetchItemBidding({ authToken, deployment, itemId });
            dispatch({
                meta: { actionTime: Date.now(), itemId },
                payload: response.data,
                type: LOAD_ITEM_BIDDING_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { itemId },
                payload: error,
                type: LOAD_ITEM_BIDDING_FAIL,
            });
        }
    } else {
        return Promise.resolve({});
    }
};
