import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';
import {
    LOAD_CATALOG_BIDDING_REQUEST,
    LOAD_CATALOG_BIDDING_SUCCESS,
    LOAD_CATALOG_ITEMS_REQUEST,
    LOAD_CATALOG_ITEMS_SUCCESS,
} from './actions';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME_CATALOG = ms('5m');

// reducer
export const DEFAULT_STATE = {
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_CATALOG_BIDDING_REQUEST]: (state: State, action: ActionWithPayload<number>) => {
            const loaded = { ...state.loaded };
            let loading = [...state.loading];

            if (action.type === LOAD_CATALOG_BIDDING_REQUEST) {
                loading = union(loading, [`catalog-bidding-info-${action.payload}`]);
            }

            return {
                ...state,
                loaded,
                loading,
            };
        },
        [LOAD_CATALOG_BIDDING_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{}, { actionTime: number; catalogId: number }>
        ) => {
            const loaded = { ...state.loaded };
            let loading = [...state.loading];
            const time = action.meta.actionTime;

            if (action.type === LOAD_CATALOG_BIDDING_SUCCESS) {
                const id = action.meta.catalogId;
                loaded[`catalog-bidding-info-${id}`] = time;
                loading = difference(loading, [`catalog-bidding-info-${id}`]);
            }

            return {
                ...state,
                loaded,
                loading,
            };
        },
        [LOAD_CATALOG_ITEMS_REQUEST]: (state: State, action: ActionWithPayload<number>) => {
            const loaded = { ...state.loaded };
            let loading = [...state.loading];

            // TODO: make this a pure function by moving the global
            // @ts-ignore
            if (action.type === LOAD_CATALOG_ITEMS_REQUEST && !global.__SERVER__) {
                loading = union(loading, [`catalog-items-${action.payload}`]);
            }

            return {
                ...state,
                loaded,
                loading,
            };
        },
        [LOAD_CATALOG_ITEMS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{}, { actionTime: number; catalogId: number }>
        ) => {
            const loaded = { ...state.loaded };
            let loading = [...state.loading];
            const time = action.meta.actionTime;

            // @ts-ignore
            if (action.type === LOAD_CATALOG_ITEMS_SUCCESS && !global.__SERVER__) {
                loaded[`catalog-items-${action.meta.catalogId}`] = time;
                loading = difference(loading, [`catalog-items-${action.meta.catalogId}`]);
            }

            return {
                ...state,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.loaded;
const idSelector = (state, id) => id;

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const getLoadTimeForCatalogBidding = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[`catalog-bidding-info-${id}`] || 0
);

const isCatalogBiddingLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(`catalog-bidding-info-${id}`)
);

export const shouldFetchBiddingInfoForCatalog = createSelector(
    [idSelector, getLoadTimeForCatalogBidding, isCatalogBiddingLoading],
    (catalogId, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME_CATALOG) {
            return false;
        }
        return !loading;
    }
);

export const getLoadTimeForCatalogItems = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[`catalog-items-${id}`] || 0
);

export const isCatalogItemsLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(`catalog-items-${id}`)
);
