import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { CreditCard } from '@liveauctioneers/caterwaul-components/types/CreditCard';
import { handleResponse, makeGet, makePost } from './helpers';

type GetLAHouseCardsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type PostCreateLAHouseCardApiParams = {
    authToken: string;
    billingAddress: Address;
    cardNonce: string;
    cardholderName: string;
    defaultCard: boolean;
    deployment: string;
    houseId: number;
    providerId: number;
};

type PostUpdateLAHouseCardApiParams = {
    authToken: string;
    cardId: number;
    defaultCard: boolean;
    deployment: string;
    enabled: boolean;
    houseId: number;
};

type GetLAHouseCardsPayload = {
    error: boolean;
    payload: CreditCard[];
};

type PostAndUpdateCardsPayload = {
    error: boolean;
    payload: CreditCard;
};

export default {
    getLAHouseCards: ({ authToken, deployment, houseId }: GetLAHouseCardsParams) =>
        new Promise<GetLAHouseCardsPayload>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/cards/`,
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.end((err: boolean, response: GetLAHouseCardsPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
    postCreateLAHouseCard: ({
        authToken,
        billingAddress,
        cardholderName,
        cardNonce,
        defaultCard,
        deployment,
        houseId,
        providerId,
    }: PostCreateLAHouseCardApiParams) =>
        new Promise<PostAndUpdateCardsPayload>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/card/`,
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.send({
                billingAddress,
                cardholderName,
                cardNonce,
                defaultCard,
                providerId,
            });
            request.end((err: boolean, response: PostAndUpdateCardsPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
    postUpdateLAHouseCard: ({
        authToken,
        cardId,
        defaultCard,
        deployment,
        enabled,
        houseId,
    }: PostUpdateLAHouseCardApiParams) =>
        new Promise<PostAndUpdateCardsPayload>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/card/${cardId}`,
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.send({ defaultCard, enabled });
            request.end((err: boolean, response: PostAndUpdateCardsPayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
