import { createSelector } from '@reduxjs/toolkit';

/* reducer */
export const DEFAULT_STATE = {};

export type State = typeof DEFAULT_STATE;

export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.session;

export const getSessionId = createSelector(stateSelector, (state) => state.sessionId);
