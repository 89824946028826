import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_TAXONOMIES_FAIL,
    LOAD_TAXONOMIES_FAIL_ACTION,
    LOAD_TAXONOMIES_REQUEST,
    LOAD_TAXONOMIES_REQUEST_ACTION,
    LOAD_TAXONOMIES_SUCCESS,
    LOAD_TAXONOMIES_SUCCESS_ACTION,
} from './actions';
import { TaxonomyEntry } from '@/types/Taxonomy';
import api from '@/redux/api/categorizer';

/* reducer */
export type State = {
    byFacetId: { [key: string]: TaxonomyEntry[] };
    byId: {
        [key: string]: TaxonomyEntry;
    };
    error: boolean;
    loading: boolean;
    taxonomies?: TaxonomyEntry[];
    totalRecords: number;
};

export const DEFAULT_STATE: State = {
    byFacetId: {},
    byId: {},
    error: null,
    loading: false,
    taxonomies: [],
    totalRecords: 0,
};

export const reducer = handleActions(
    {
        [LOAD_TAXONOMIES_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            loading: false,
        }),
        [LOAD_TAXONOMIES_REQUEST]: (state: State): State => ({
            ...state,
            error: false,
            loading: true,
        }),
        [LOAD_TAXONOMIES_SUCCESS]: (state: State, action: LOAD_TAXONOMIES_SUCCESS_ACTION): State => {
            const byId = { ...state.byId };
            action.payload.forEach((taxo: TaxonomyEntry) => {
                taxo.facetId = action.meta.categoryId;
                byId[String(taxo.categoryId)] = taxo;
            });
            return {
                ...state,
                byFacetId: { ...state.byFacetId, [String(action.meta.categoryId)]: action.payload },
                byId,
                loading: false,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.taxonomy;
const idSelector = (state, id) => id;
const byIdSelector = createSelector(stateSelector, (state) => state.byId);
const byFacetIdSelector = createSelector(stateSelector, (state) => state.byFacetId);

export const getTaxonomy = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]);
export const getFacetTaxonomies = createSelector(
    [byFacetIdSelector, idSelector],
    (byFacetId, id) => byFacetId[id] || []
);
export const getAllTaxonomies = createSelector(stateSelector, (state) => state.taxonomies);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.loading);

interface FetchTaxonomiesParams {
    categoryId: number;
    searchTerm?: string;
}

export const fetchTaxonomies =
    ({ categoryId, searchTerm = '' }: FetchTaxonomiesParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                type: LOAD_TAXONOMIES_REQUEST,
            } as LOAD_TAXONOMIES_REQUEST_ACTION);

            const response = await api.getAllTaxonomies({
                authToken,
                categoryId,
                deployment,
                searchTerm,
            });

            return dispatch({
                meta: { categoryId },
                payload: response.payload,
                type: LOAD_TAXONOMIES_SUCCESS,
            } as LOAD_TAXONOMIES_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_TAXONOMIES_FAIL,
            } as LOAD_TAXONOMIES_FAIL_ACTION);
        }
    };
