import {
    ADD_TAG_TO_LOTS_FAIL,
    ADD_TAG_TO_LOTS_FAIL_ACTION,
    ADD_TAG_TO_LOTS_REQUEST,
    ADD_TAG_TO_LOTS_SUCCESS,
    ADD_TAG_TO_LOTS_SUCCESS_ACTION,
    LOAD_CATEGORIZER_FACETS_SUCCESS,
    LOAD_CATEGORIZER_FACETS_SUCCESS_ACTION,
    LOAD_CATEGORIZER_FAIL,
    LOAD_CATEGORIZER_FAIL_ACTION,
    LOAD_CATEGORIZER_LOTS_SUCCESS,
    LOAD_CATEGORIZER_LOTS_SUCCESS_ACTION,
    LOAD_CATEGORIZER_NAMES_SUCCESS,
    LOAD_CATEGORIZER_NAMES_SUCCESS_ACTION,
    LOAD_CATEGORIZER_REQUEST,
    LOAD_CATEGORIZER_REQUEST_ACTION,
    REMOVE_TAG_FROM_LOT_FAIL,
    REMOVE_TAG_FROM_LOT_FAIL_ACTION,
    REMOVE_TAG_FROM_LOT_REQUEST,
    REMOVE_TAG_FROM_LOT_SUCCESS,
    REMOVE_TAG_FROM_LOT_SUCCESS_ACTION,
    SELECT_CATEGORY,
    SELECT_CATEGORY_ACTION,
    SET_CATALOGID,
    SET_CATALOGID_ACTION,
    SET_SELECTED_LOT_IDS,
    SET_SELECTED_LOT_IDS_ACTION,
    UPDATE_PAGINATION,
    UPDATE_PAGINATION_ACTION,
} from './actions';
import { CATEGORY_IDS } from '@/enums/category';
import { CategoryIdentifier } from '@/types/CategoryIdentifier';
import { createSelector } from '@reduxjs/toolkit';
import { FacetOption } from '@liveauctioneers/caterwaul-components/types/Facet';
import { getAuthToken, getUserHouseId } from './user';
import { getDeployment } from './config';
import { getTaxonomy } from './taxonomy';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { TaxonomyEntry } from '@/types/Taxonomy';
import api from '../api/categorizer';
import cloneDeep from 'lodash/cloneDeep';
import type { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';

// 'all' pageSize not supported by API; this limit should cover most scenarios per house
const ALL_CATALOG_LOT_COUNT = 1000000;

export type SelectedCategory = {
    categoryIdentifier: string;
    id: number;
    name: string;
};

/* reducer */
export type State = {
    byId: {
        [key: string]: PartialLot;
    };
    catalogId: number;
    categoryIds: number[];
    error: any;
    facets: FacetOption[];
    isTagging: boolean;
    loading: boolean;
    lotIds: number[];
    missingCounts: { [key in CategoryIdentifier]: number };
    page: number;
    pageSize: number | 'all';
    selectedCategories: { [key in CategoryIdentifier]: SelectedCategory[] };
    selectedLotIds: number[];
    totalRecords: number;
};

export const DEFAULT_STATE: State = {
    byId: {},
    catalogId: 0,
    categoryIds: [],
    error: null,
    facets: [],
    isTagging: false,
    loading: false,
    lotIds: [],
    missingCounts: {
        [CategoryIdentifier.Category]: 0,
        [CategoryIdentifier.Origin]: 0,
        [CategoryIdentifier.StylePeriod]: 0,
        [CategoryIdentifier.Creator]: 0,
        [CategoryIdentifier.Material]: 0,
    },
    page: 1,
    pageSize: 'all',
    selectedCategories: {
        [CategoryIdentifier.Category]: [],
        [CategoryIdentifier.Origin]: [],
        [CategoryIdentifier.StylePeriod]: [],
        [CategoryIdentifier.Creator]: [],
        [CategoryIdentifier.Material]: [],
    },
    selectedLotIds: [],
    totalRecords: 0,
};

export type PartialLot = {
    catalogId?: number;
    categories?: TaxonomyEntry[];
    imageUrl?: string;
    lotId: number;
    lotIndex?: number;
    lotNumber?: string;
    lotTitle?: string;
    reservePrice?: number;
    sortIdx?: number;
    startPrice?: number;
    updatedTs?: number;
};

export type LotCategories = {
    categories: TaxonomyEntry[];
    lotId: number;
    updatedTs: number;
};

export const reducer = handleActions(
    {
        [ADD_TAG_TO_LOTS_FAIL]: (state: State): State => {
            return { ...state, isTagging: false };
        },
        [ADD_TAG_TO_LOTS_REQUEST]: (state: State): State => {
            return { ...state, isTagging: true };
        },
        [ADD_TAG_TO_LOTS_SUCCESS]: (state: State, action: ADD_TAG_TO_LOTS_SUCCESS_ACTION): State => {
            const { lotCategory, lotIds } = action.meta;
            let byId = { ...state.byId };
            const existingLotIds = [...state.lotIds];
            const now = Date.now();
            lotIds.forEach((lotId: number) => {
                const lotCatIds = byId[lotId].categories.map((lotCat: TaxonomyEntry) => lotCat.categoryId);
                if (!lotCatIds.includes(lotCategory.categoryId)) {
                    // Highlander rules: replace lotCategory entry if from Category facet tree
                    if (lotCategory.facetId === CATEGORY_IDS.CATEGORY_ID) {
                        byId[lotId].categories = byId[lotId].categories.filter(
                            (lotCat) => lotCat.facetId !== CATEGORY_IDS.CATEGORY_ID
                        );
                    }
                    byId[lotId].categories = [...byId[lotId].categories, lotCategory];
                    byId[lotId].updatedTs = now;
                }
            });
            return {
                ...state,
                byId,
                isTagging: false,
                lotIds: existingLotIds,
            };
        },
        [LOAD_CATEGORIZER_FACETS_SUCCESS]: (state: State, action: LOAD_CATEGORIZER_FACETS_SUCCESS_ACTION): State => ({
            ...state,
            facets: action.payload.facets,
            loading: false,
            missingCounts: action.payload.missingCounts,
        }),
        [LOAD_CATEGORIZER_FAIL]: (state: State, action: LOAD_CATEGORIZER_FAIL_ACTION): State => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [LOAD_CATEGORIZER_LOTS_SUCCESS]: (state: State, action: LOAD_CATEGORIZER_LOTS_SUCCESS_ACTION): State => {
            const byId = {}; // !
            action.payload.lotCategories.forEach((lotCategory: LotCategories, index: number) => {
                byId[lotCategory.lotId] = byId[lotCategory.lotId]
                    ? { ...byId[lotCategory.lotId], categories: lotCategory.categories, sortIdx: index + 1 }
                    : { categories: lotCategory.categories, lotId: lotCategory.lotId, sortIdx: index + 1 };
            });

            return {
                ...state,
                byId,
                loading: false,
                lotIds: [...action.payload.lotCategories.map((lotCategory) => lotCategory.lotId)],
                totalRecords: action.payload.total,
            };
        },
        [LOAD_CATEGORIZER_NAMES_SUCCESS]: (state: State, action: LOAD_CATEGORIZER_NAMES_SUCCESS_ACTION): State => {
            const byId = { ...state.byId };
            action.payload.forEach((partialLot: PartialLot) => {
                byId[partialLot.lotId] = byId[partialLot.lotId]
                    ? { ...byId[partialLot.lotId], ...partialLot }
                    : partialLot;
            });

            return {
                ...state,
                byId,
                loading: false,
            };
        },
        [LOAD_CATEGORIZER_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [REMOVE_TAG_FROM_LOT_FAIL]: (state: State): State => {
            return { ...state, isTagging: false };
        },
        [REMOVE_TAG_FROM_LOT_REQUEST]: (state: State): State => {
            return { ...state, isTagging: true };
        },
        [REMOVE_TAG_FROM_LOT_SUCCESS]: (state: State, action: REMOVE_TAG_FROM_LOT_SUCCESS_ACTION): State => {
            const { categoryId, lotId } = action.meta;
            const byId = { ...state.byId };
            const lotCatIds = byId[lotId].categories.map((lotCat) => lotCat.categoryId);
            const now = Date.now();
            if (lotCatIds.includes(categoryId)) {
                byId[lotId].categories = byId[lotId].categories.filter(
                    (lotCat: TaxonomyEntry) => lotCat.categoryId !== categoryId
                );
                byId[lotId].updatedTs = now;
            }
            return {
                ...state,
                byId,
                isTagging: false,
            };
        },
        [SELECT_CATEGORY]: (state: State, action: SELECT_CATEGORY_ACTION): State => ({
            ...state,
            selectedCategories: action.payload,
        }),
        [SET_CATALOGID]: (state: State, action: SET_CATALOGID_ACTION): State => ({
            ...state,
            catalogId: action.payload,
        }),
        [SET_SELECTED_LOT_IDS]: (state: State, action: SET_SELECTED_LOT_IDS_ACTION): State => ({
            ...state,
            selectedLotIds: action.payload,
        }),
        [UPDATE_PAGINATION]: (state: State, action: UPDATE_PAGINATION_ACTION): State => ({
            ...state,
            ...action.payload,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.categorizer;
export const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export function getLotName(id: number) {
    return createSelector(
        [byIdSelector],
        (byId) => byId[id] || ({ categories: [], lotTitle: 'Name not found' } as PartialLot)
    );
}

export const lotIDSelector = createSelector(stateSelector, (state) => state.lotIds);
export const catalogIdSelector = createSelector(stateSelector, (state) => state.catalogId);
export const selectedLotIdsSelector = createSelector(stateSelector, (state) => state.selectedLotIds);
export const paginationSelector = createSelector(stateSelector, (state) => ({
    page: state.page,
    pageSize: state.pageSize,
    totalRecords: state.totalRecords,
}));
export const isLoadingSelector = createSelector(stateSelector, (state) => state.loading);
export const isTaggingSelector = createSelector(stateSelector, (state) => state.isTagging);
export const getTotalLots = createSelector(stateSelector, (state) => state.totalRecords);
export const facetSelector = createSelector(stateSelector, (state) => state.facets);
export const getSelectedCategories = createSelector(stateSelector, (state) => state.selectedCategories);
export const getMissingCounts = createSelector(stateSelector, (state) => state.missingCounts);
export const isSelectedCategorySelected = (selectedCategories: SelectedCategory[], categoryId: number): boolean => {
    return selectedCategoryIndexOf(selectedCategories, categoryId) >= 0;
};

/* ACTIONS */
export const setCatalogId = (newCatalogId: number) => async (dispatch: Function) =>
    dispatch({
        payload: newCatalogId,
        type: SET_CATALOGID,
    } as SET_CATALOGID_ACTION);

export const setSelectedLotIds = (selectedLotIds: number[]) => async (dispatch: Function) =>
    dispatch({
        payload: selectedLotIds,
        type: SET_SELECTED_LOT_IDS,
    } as SET_SELECTED_LOT_IDS_ACTION);

/**
 * setPagination - sets pagination object for page and refetches data
 * @param paginationFilter
 * @returns Promise
 */
export const setPagination = (paginationFilter: PaginationFilter) => async (dispatch: Function) => {
    dispatch({
        payload: paginationFilter,
        type: UPDATE_PAGINATION,
    } as UPDATE_PAGINATION_ACTION);
    return dispatch(fetchLotIdsThenNames());
};

export const toggleSelectedLot = (lotId: number) => async (dispatch: Function, getState: Function) => {
    const selectedLotIds = selectedLotIdsSelector(getState());
    if (selectedLotIds.includes(lotId)) {
        const selectedMinusClicked = selectedLotIds.filter((id) => id !== lotId);
        return dispatch(setSelectedLotIds(selectedMinusClicked));
    } else {
        return dispatch(setSelectedLotIds([...selectedLotIds, lotId]));
    }
};

export const selectAllLots = () => async (dispatch: Function, getState: Function) =>
    dispatch(setSelectedLotIds(getState().categorizer.lotIds));

/**
 * selectRangeLots
 * selects a range of lots after the earliest selected lot on the same page
 * @param rangeLotId
 * @returns void
 */
export const selectRangeLots = (rangeLotId: number) => async (dispatch: Function, getState: () => GlobalState) => {
    const selectedLotIds = selectedLotIdsSelector(getState());
    const selectedLotsInfo = selectedLotIds.map((selectedLotId: number) => getLotName(selectedLotId)(getState()));
    const rangeLot = getLotName(rangeLotId)(getState());
    const rangeLotIdx = rangeLot.sortIdx;
    const nearestLotIdx = selectedLotsInfo.reduce((acc: number, lot: PartialLot) => {
        if (lot.sortIdx > acc && lot.sortIdx < rangeLotIdx) {
            acc = lot.sortIdx;
        }
        return acc;
    }, 0);
    const pageLots = byIdSelector(getState());
    const rangeLotIds = Object.values(pageLots).reduce((acc: number[], lot: PartialLot) => {
        if (lot.sortIdx > nearestLotIdx && lot.sortIdx < rangeLotIdx) {
            acc.push(lot.lotId);
        }
        return acc;
    }, []);
    rangeLotIds.push(rangeLotId);
    return dispatch(setSelectedLotIds([...selectedLotIds, ...rangeLotIds]));
};

export const clearSelectedLots = () => async (dispatch: Function) => {
    return dispatch(setSelectedLotIds([]));
};

export const reduceSelected = (selectedFacets: State['selectedCategories']): SelectedCategory[] => {
    return [].concat.apply([], Object.values(selectedFacets));
};

const reduceSelectedToId = (selectedFacets: State['selectedCategories']): number[] => {
    return reduceSelected(selectedFacets).map(({ id }) => id);
};

interface FetchLotIdsParams {
    // override loading status to prevent page flash
    ignoreLoading?: boolean;
    // page number to load
    page?: number;
    // search term to filter results by
    searchTerm?: string;
}
export const fetchLotIds =
    ({ ignoreLoading = false, page, searchTerm }: FetchLotIdsParams = {}) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            const catalogId = catalogIdSelector(state);
            let pagination = paginationSelector(state);
            // API work around for 'all' results option
            pagination.pageSize = pagination.pageSize === 'all' ? ALL_CATALOG_LOT_COUNT : pagination.pageSize;
            // reset page if valid number
            if (typeof page !== 'undefined') {
                pagination.page = page;
            }
            const selectedFacets = getSelectedCategories(state);

            if (!ignoreLoading) {
                dispatch({
                    type: LOAD_CATEGORIZER_REQUEST,
                } as LOAD_CATEGORIZER_REQUEST_ACTION);
            }

            const categoryIds = reduceSelectedToId(selectedFacets);

            const response = await api.getLotIds({
                authToken,
                catalogId,
                categoryIds,
                deployment,
                page: pagination.page,
                pageSize: pagination.pageSize,
                searchTerm,
            });

            return dispatch({
                payload: response.payload,
                type: LOAD_CATEGORIZER_LOTS_SUCCESS,
            } as LOAD_CATEGORIZER_LOTS_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_CATEGORIZER_FAIL,
            } as LOAD_CATEGORIZER_FAIL_ACTION);
        }
    };

export const fetchIdNames =
    (ignoreLoading: boolean = false) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const lotIds = lotIDSelector(state);
            const houseId = getUserHouseId(state);
            const catalogId = catalogIdSelector(state);

            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            if (!ignoreLoading) {
                dispatch({
                    type: LOAD_CATEGORIZER_REQUEST,
                } as LOAD_CATEGORIZER_REQUEST_ACTION);
            }

            const response = await api.getLotNames({
                authToken,
                catalogId,
                deployment,
                houseId,
                lotIds,
            });

            return dispatch({
                payload: response.payload,
                type: LOAD_CATEGORIZER_NAMES_SUCCESS,
            } as LOAD_CATEGORIZER_NAMES_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_CATEGORIZER_FAIL,
            } as LOAD_CATEGORIZER_FAIL_ACTION);
        }
    };

export const fetchLotIdsThenNames = (fetchLotIdsParams?: FetchLotIdsParams) => async (dispatch: Function) =>
    dispatch(fetchLotIds(fetchLotIdsParams)).then(() => dispatch(fetchIdNames(fetchLotIdsParams?.ignoreLoading)));

const recursiveRemapValue = (item: FacetOption) => {
    item.categories = item.categories.map(recursiveRemapValue);
    // @ts-expect-error level is what is passed to us but we expect depth.
    item.depth = item.level - 1;
    // @ts-expect-error level is what is passed to us but we expect depth.
    delete item.level;
    return item;
};

interface FetchFacetsParams {
    // skip loading status update to prevent UI flash
    ignoreLoading?: boolean;
}

export const fetchFacets = (params?: FetchFacetsParams) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const catalogId = catalogIdSelector(state);
        const selectedFacets = getSelectedCategories(state);

        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);

        const { ignoreLoading = false } = params;
        if (!ignoreLoading) {
            dispatch({
                type: LOAD_CATEGORIZER_REQUEST,
            } as LOAD_CATEGORIZER_REQUEST_ACTION);
        }

        const categoryIds = reduceSelectedToId(selectedFacets);

        const response = await api.getFacets({
            authToken,
            catalogId,
            categoryIds,
            deployment,
        });

        response.payload.facets = response.payload.facets.map(recursiveRemapValue);

        return dispatch({
            payload: response.payload,
            type: LOAD_CATEGORIZER_FACETS_SUCCESS,
        } as LOAD_CATEGORIZER_FACETS_SUCCESS_ACTION);
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_CATEGORIZER_FAIL,
        } as LOAD_CATEGORIZER_FAIL_ACTION);
    }
};

export const resetCategorySelections =
    (reload: boolean = false) =>
    async (dispatch: Function) => {
        const action = dispatch({
            payload: DEFAULT_STATE.selectedCategories,
            type: SELECT_CATEGORY,
        } as SELECT_CATEGORY_ACTION);

        if (!reload) {
            return action;
        } else {
            return Promise.all([
                dispatch(fetchFacets({ ignoreLoading: true })),
                dispatch(fetchLotIdsThenNames({ ignoreLoading: true })),
            ]);
        }
    };

type SelectCategoryOptions = {
    categoryId: number | string;
    categoryIdentifier: string;
    categoryName: string;
    oldId?: string | null;
};

const selectedCategoryIndexOf = (selectedCategories: SelectedCategory[], categoryId: number): number => {
    let loc = -1;
    selectedCategories.forEach(({ id }, index) => {
        if (id === categoryId) {
            loc = index;
        }
    });
    return loc;
};

export const selectCategory =
    ({ categoryId, categoryIdentifier: categoryidentifier, categoryName, oldId }: SelectCategoryOptions) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();
        const selectedCategoriesObject = cloneDeep(getSelectedCategories(state));
        const categoryIdentifier: CategoryIdentifier = categoryidentifier as unknown as CategoryIdentifier;

        categoryId = Number(categoryId);

        if (categoryId >= 0) {
            let selectedCategories = selectedCategoriesObject[categoryIdentifier];

            // Remove all negative category ids, aka reset the Missing selection
            selectedCategories = selectedCategories.filter(({ id }) => id >= 0);

            // Remove an old id if provided
            if (Boolean(oldId) && categoryId !== Number(oldId)) {
                const oldIndex = selectedCategoryIndexOf(selectedCategories, Number(oldId));
                if (oldIndex > -1) {
                    selectedCategories.splice(oldIndex, 1);
                }
            }

            // Add id if not found or remove id if found.
            const index = selectedCategoryIndexOf(selectedCategories, categoryId);
            if (index > -1) {
                selectedCategories.splice(index, 1);
            } else {
                selectedCategories.push({
                    categoryIdentifier: `${categoryIdentifier}`,
                    id: categoryId,
                    name: categoryName,
                });
            }

            selectedCategoriesObject[categoryIdentifier] = selectedCategories;
        } else {
            // Save state before you clear
            const currentlySelectedNegative =
                selectedCategoryIndexOf(selectedCategoriesObject[categoryIdentifier], categoryId) >= 0;

            // Remove all negative category ids
            Object.keys(selectedCategoriesObject).forEach((key) => {
                selectedCategoriesObject[key] = selectedCategoriesObject[key].filter((x) => x >= 0);
            });

            // Either clear the list or set it only to itself.
            if (currentlySelectedNegative) {
                selectedCategoriesObject[categoryIdentifier] = [];
            } else {
                selectedCategoriesObject[categoryIdentifier] = [
                    {
                        categoryIdentifier: `${categoryIdentifier}`,
                        id: categoryId,
                        name: categoryName,
                    },
                ];
            }
        }

        await dispatch({
            payload: selectedCategoriesObject,
            type: SELECT_CATEGORY,
        } as SELECT_CATEGORY_ACTION);

        return Promise.all([
            dispatch(fetchFacets({ ignoreLoading: true })),
            dispatch(fetchLotIdsThenNames({ ignoreLoading: true })),
        ]);
    };

interface AddTagToLotsParams {
    catalogId: number;
    categoryId: number;
    lotIds: number[];
}

export const addTagToLots =
    ({ catalogId, categoryId, lotIds }: AddTagToLotsParams) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            const lotCategory = getTaxonomy(state, categoryId);

            dispatch({
                type: ADD_TAG_TO_LOTS_REQUEST,
            });

            let result = await api.addTagToLots({
                authToken,
                catalogId,
                categoryId,
                deployment,
                lotIds,
            });

            if (!result.error) {
                // clear selected lots
                dispatch({
                    payload: [],
                    type: SET_SELECTED_LOT_IDS,
                });

                return dispatch({
                    meta: {
                        lotCategory,
                        lotIds,
                    },
                    payload: result,
                    type: ADD_TAG_TO_LOTS_SUCCESS,
                });
            } else {
                throw result.payload;
            }
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: ADD_TAG_TO_LOTS_FAIL,
            } as ADD_TAG_TO_LOTS_FAIL_ACTION);
        }
    };

interface RemoveTagFromLotParams {
    catalogId: number;
    categoryId: number;
    lotId: number;
}

export const removeTagFromLot =
    ({ catalogId, categoryId, lotId }: RemoveTagFromLotParams) =>
    async (dispatch: Function, getState: () => GlobalState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);

            dispatch({
                type: REMOVE_TAG_FROM_LOT_REQUEST,
            });

            const result = await api.removeTagFromLot({
                authToken,
                catalogId,
                categoryId,
                deployment,
                lotId,
            });

            if (!result.error) {
                return dispatch({
                    meta: {
                        categoryId,
                        lotId,
                    },
                    payload: result,
                    type: REMOVE_TAG_FROM_LOT_SUCCESS,
                });
            } else {
                throw result.payload;
            }
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: REMOVE_TAG_FROM_LOT_FAIL,
            } as REMOVE_TAG_FROM_LOT_FAIL_ACTION);
        }
    };
