import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_CAMPAIGN_AD_PREVIEW_FAIL,
    LOAD_CAMPAIGN_AD_PREVIEW_REQUEST,
    LOAD_CAMPAIGN_AD_PREVIEW_SUCCESS,
} from './actions';
import api from '../api/campaignAdPreview';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type CampaignAdPreview = {
    catalogTitle: string;
    houseName: string;
    imageURL1: string;
    imageURL2: string;
    imageURL3: string;
    saleStartDate: number;
};

export const emptyCatalogAdPreview: CampaignAdPreview = {
    catalogTitle: '',
    houseName: '',
    imageURL1: '',
    imageURL2: '',
    imageURL3: '',
    saleStartDate: 0,
};

export type State = {
    byId: { [id: number]: CampaignAdPreview };
    loaded: { [id: number]: number };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_CAMPAIGN_AD_PREVIEW_FAIL]: (state: State, action: ActionWithPayload<{}, { adUnitId: string }>) => ({
            ...state,
            loading: difference(state.loading, [Number(action.meta.adUnitId)]),
        }),
        [LOAD_CAMPAIGN_AD_PREVIEW_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [LOAD_CAMPAIGN_AD_PREVIEW_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ campaignAdPreview: any }, { actionTime: number; adUnitId: string }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            existing[action.meta.adUnitId] = { ...action.payload };
            loaded[action.meta.adUnitId] = time;
            loading = difference(loading, [Number(action.meta.adUnitId)]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.campaignAdPreview;
const idSelector = (_: GlobalState, campaignAdId: number) => campaignAdId;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getCampaignAdPreview = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || emptyCatalogAdPreview
);

const getLoadTimeForCampaignAdPreview = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isCampaignAdPreviewLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchCampaignAdPreviewData = createSelector(
    [getCampaignAdPreview, getLoadTimeForCampaignAdPreview, isCampaignAdPreviewLoading],
    (catalog, loaded, loading) => {
        if (catalog) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

/* ACTION CREATORS */
const loadCampaignAdPreviewData = (adUnitId: string, houseId: string) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            payload: adUnitId,
            type: LOAD_CAMPAIGN_AD_PREVIEW_REQUEST,
        });
        const response = await api.fetchCampaignAdPreviewById({
            adUnitId,
            authToken,
            deployment,
            houseId,
        });
        return dispatch({
            meta: { actionTime: Date.now(), adUnitId },
            payload: response.payload,
            type: LOAD_CAMPAIGN_AD_PREVIEW_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { adUnitId },
            payload: error,
            type: LOAD_CAMPAIGN_AD_PREVIEW_FAIL,
        });
    }
};

export const fetchCampaignAdPreviewIfNeeded =
    (adUnitId: any, houseId: string) => async (dispatch: Function, getState: Function) => {
        if (shouldFetchCampaignAdPreviewData(getState(), adUnitId)) {
            return dispatch(loadCampaignAdPreviewData(adUnitId, houseId));
        }

        return Promise.resolve();
    };
