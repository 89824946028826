import { DedicatedFollowers } from '@/types/DedicatedFollowers';
import type { TypedActionWithPayload } from '@/types/redux';

export const CREATE_DEDICATED_FOLLOWERS_FAIL = 'CREATE_DEDICATED_FOLLOWERS_FAIL';
export type CREATE_DEDICATED_FOLLOWERS_FAIL_ACTION = TypedActionWithPayload<
    typeof CREATE_DEDICATED_FOLLOWERS_FAIL,
    string
>;
export const CREATE_DEDICATED_FOLLOWERS_REQUEST = 'CREATE_DEDICATED_FOLLOWERS_REQUEST';
export const CREATE_DEDICATED_FOLLOWERS_SUCCESS = 'CREATE_DEDICATED_FOLLOWERS_SUCCESS';

export const GET_DEDICATED_FOLLOWERS_FAIL = 'GET_DEDICATED_FOLLOWERS_FAIL';
export type GET_DEDICATED_FOLLOWERS_FAIL_ACTION = TypedActionWithPayload<typeof GET_DEDICATED_FOLLOWERS_FAIL, string>;
export const GET_DEDICATED_FOLLOWERS_REQUEST = 'GET_DEDICATED_FOLLOWERS_REQUEST';
export const GET_DEDICATED_FOLLOWERS_SUCCESS = 'GET_DEDICATED_FOLLOWERS_SUCCESS';
export type GET_DEDICATED_FOLLOWERS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof GET_DEDICATED_FOLLOWERS_SUCCESS,
    {
        dedicatedFollowers: DedicatedFollowers;
        error: boolean;
    }
>;
