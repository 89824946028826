import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogOwnerSlice } from './catalogOwner.types';
import { fetchCatalogOwner } from '../actions';

const catalogOwnerSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchCatalogOwner.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCatalogOwner.fulfilled, (state, { meta, payload: houseId }) => {
            state.byId[meta.arg] = { houseId };
            state.loaded = Date.now();
            state.loading = false;
        });
        builder.addCase(fetchCatalogOwner.rejected, (state) => {
            state.loading = false;
        });
    },
    initialState: defaultCatalogOwnerSlice,
    name: 'catalogOwner',
    reducers: {},
});

export const { reducer: catalogOwnerReducer } = catalogOwnerSlice;
