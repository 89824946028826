import { handleResponse, makeGet } from './helpers';

type FetchSpeedDataParams = {
    deployment: string;
};

export const fetchSpeedData = ({ deployment }: FetchSpeedDataParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'clerk/healthcheck',
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
