import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { LOAD_SELLER_CATALOGS_FAILURE, LOAD_SELLER_CATALOGS_REQUEST, LOAD_SELLER_CATALOGS_SUCCESS } from './actions';
import { SellerCatalog } from '@/types/SellerCatalog';
import api from '../api';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

export type State = {
    catalogs: SellerCatalog[];
    houseId: number | null;
    loaded: number;
    loading: boolean;
};

export const DEFAULT_STATE: State = {
    catalogs: [],
    houseId: null,
    loaded: 0,
    loading: false,
};

export const reducer = handleActions(
    {
        [LOAD_SELLER_CATALOGS_FAILURE]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.payload.error,
            loading: false,
        }),
        [LOAD_SELLER_CATALOGS_REQUEST]: (state: State) => ({
            ...state,
            catalogs: [],
            loading: true,
        }),
        [LOAD_SELLER_CATALOGS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<SellerCatalog[], { actionTime: number; houseId: number }>
        ) => ({
            ...state,
            catalogs: [...action.payload],
            houseId: action.meta.houseId,
            loaded: action.meta.actionTime,
            loading: false,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState): State => state.sellerCatalogs;
const idSelector = (_, id: number) => id;

export const sellerCatalogsSelector = createSelector(stateSelector, (state) => {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    return (
        state.catalogs
            .filter((cat) => {
                return new Date(cat.saleStart) >= now;
            })
            .sort((a, b) => {
                const firstStart = new Date(a.saleStart);
                const secondStart = new Date(b.saleStart);
                if (firstStart < secondStart) {
                    return -1;
                }
                if (firstStart > secondStart) {
                    return 1;
                }
                return 0;
            }) || []
    );
});

export const sellerCatalogByIdSelector = createSelector(
    [sellerCatalogsSelector, idSelector],
    (sellerCatalogs, catalogId) => sellerCatalogs.find((catalog) => catalog.catalogId === catalogId) || {}
);

export const getSellerCatalogsLoaded = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const currentHouseIdSelector = createSelector(stateSelector, (state) => state.houseId);

const shouldFetchSellerCatalogs = createSelector(
    [getSellerCatalogsLoaded, loadingSelector, currentHouseIdSelector, idSelector],
    (loaded, loading, houseId, currentHouseId) => {
        if (houseId !== currentHouseId) {
            return true;
        }

        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME_ITEM) {
            return false;
        }
        return !loading;
    }
);

export const fetchSellerCatalogs = (sellerId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: LOAD_SELLER_CATALOGS_REQUEST });

        const response = await api.fetchSellerCatalogs({
            authToken,
            deployment,
            sellerId,
        });

        dispatch({
            meta: { actionTime: Date.now(), houseId: sellerId },
            payload: response.payload,
            type: LOAD_SELLER_CATALOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({ payload: { error }, type: LOAD_SELLER_CATALOGS_FAILURE });
    }
};

export const fetchSellerCatalogsIfNeeded = (sellerId: number) => async (dispatch: Function, getState: Function) => {
    const needed = shouldFetchSellerCatalogs(getState(), sellerId);
    if (needed) {
        return dispatch(fetchSellerCatalogs(sellerId));
    }
    return Promise.resolve();
};
