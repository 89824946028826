import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import api from '../api';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

export const BNA_REPORT_FAIL = 'BNA_REPORT_FAIL';
export const BNA_REPORT_REQUEST = 'BNA_REPORT_REQUEST';
export const BNA_REPORT_SUCCESS = 'BNA_REPORT_SUCCESS';

// reducer
export type BidNotAcceptedReport = {
    BidNotAcceptedReportRows: any[];
    catalogId: number;
    revenueLost: number;
    saleTotal: number;
};

export type BidsNotAcceptedState = {
    byId: { [catalogId: number]: BidNotAcceptedReport };
    loaded: { [catalogId: number]: number };
    loading: number[];
};

export const DEFAULT_STATE: BidsNotAcceptedState = {
    byId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [BNA_REPORT_FAIL]: (state: BidsNotAcceptedState, action: ActionWithPayload<{}, { catalogId: number }>) => {
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
            };
        },
        [BNA_REPORT_REQUEST]: (state: BidsNotAcceptedState, action: ActionWithPayload<{ payload: [] }>) => {
            return {
                ...state,
                // @ts-ignore
                loading: union(state.loading, action.payload),
            };
        },
        [BNA_REPORT_SUCCESS]: (
            state: BidsNotAcceptedState,
            action: ActionWithPayload<BidNotAcceptedReport, { actionTime: number; catalogId: number }>
        ) => {
            let existing = cloneDeep(state.byId);
            existing[action.meta.catalogId] = action.payload;
            let loaded = { ...state.loaded };
            loaded[action.meta.catalogId] = action.meta.actionTime;
            let loading = difference(state.loading, [action.meta.catalogId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.bnareport;
const idSelector = (state: GlobalState, id: number) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getReport = createSelector(
    [byIdSelector, idSelector],
    (byId, id): BidNotAcceptedReport =>
        byId[id] || {
            BidNotAcceptedReportRows: [],
            catalogId: 0,
            revenueLost: 0,
            saleTotal: 0,
        }
);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const isReportLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const fetchBNAReport =
    (catalogId: number, sellerId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ payload: [catalogId], type: BNA_REPORT_REQUEST });

            const response = await api.fetchBNAReport({
                authToken,
                catalogId,
                deployment,
                sellerId,
            });

            dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response.payload,
                type: BNA_REPORT_SUCCESS,
            });
        } catch (error) {
            dispatch({ meta: { catalogId }, payload: { error }, type: BNA_REPORT_FAIL });
        }
    };
