import { allPermissions } from '@/utils/allPermissions';

export type NavTab = {
    catalogStatus?: string;
    children?: NavTab[] | null;
    hidden?: boolean;
    label: string | null;
    permissions?: string[] | null;
    url: string | null;
    urlMatcher?: RegExp;
};

type AllNavTabsType = { [tabName: string]: NavTab };

export const allNavTabs: AllNavTabsType = {
    allItems: {
        children: null,
        label: 'All Items',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/CL<CATALOG-ID>.html',
    },
    auctionMenu: {
        children: [
            {
                label: 'Pre-Auction',
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/pre.html',
            },
            {
                label: 'Live Auction',
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/live.html',
            },
            {
                label: 'Post-Auction',
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/post.html',
            },
        ],
        label: 'Auctions',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/pre.html',
    },
    auctionSetup: {
        children: null,
        label: 'Auction Setup',
        permissions: [allPermissions.SETUP_AUCTION],
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/auction-setup-init/<HOUSE-ID>',
    },
    bidderMenu: {
        children: [
            {
                label: 'Manual Approval',
                url: '/house/<HOUSE-ID>/bidders',
            },
            {
                label: 'Automation Settings',
                url: '/house/<HOUSE-ID>/bidders?tab=auto',
            },
        ],
        label: 'Bidders',
        url: '/house/<HOUSE-ID>/bidders',
    },
    catalogStats: {
        children: null,
        label: 'Stats',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/CS<CATALOG-ID>.html',
    },
    communityAndHelpMenu: {
        children: [
            {
                label: 'Forum',
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers_bb/bb.php',
            },
            {
                label: 'Terms & Rules',
                url: 'https://support.liveauctioneers.com/article/44-liveauctioneers-listing-policies',
            },
            {
                label: 'Seller Resource Center',
                url: 'https://www.liveauctioneers.com/pages/resource-center/',
            },
            {
                label: 'Support Center',
                url: 'https://support.liveauctioneers.com',
            },
        ],
        label: 'Help',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers_bb/bb.php',
    },
    consignments: {
        children: null,
        label: 'Consignments',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/consignments-<HOUSE-ID>.html',
    },
    details: {
        children: null,
        label: 'Details',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/C<CATALOG-ID>.html',
    },
    eoa: {
        children: null,
        label: 'EOA',
        permissions: [allPermissions.VIEW_EOA],
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/EOA-new-<CATALOG-ID>.html',
    },
    formerClerksale: {
        children: null,
        label: 'LiveConsole',
        url: '/upcoming-catalog-list/<HOUSE-ID>/<CATALOG-ID>',
    },
    globalMarketing: {
        children: null,
        label: 'Marketing',
        permissions: [allPermissions.VIEW_OPTIONS],
        url: '/house/<HOUSE-ID>/marketing',
    },
    globalStats: {
        children: null,
        label: 'Performance',
        permissions: [allPermissions.VIEW_OPTIONS],
        url: '/house/<HOUSE-ID>/performance',
    },
    home: {
        children: null,
        label: 'Home',
        url: '/house/<HOUSE-ID>/dashboard',
    },
    homeClassic: {
        children: null,
        label: 'Home (Classic)',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/home-<HOUSE-ID>.html',
    },
    invoices: {
        children: null,
        label: 'Invoices',
        permissions: [allPermissions.VIEW_INVOICES],
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/EOA-I-new-<CATALOG-ID>.html',
    },
    liveShip: {
        children: [
            {
                label: 'LiveShip Label',
                permissions: [],
                url: '/liveship/label',
            },
            {
                label: 'Parsing Tool',
                permissions: [allPermissions.STAFF_ADMIN],
                url: '/liveship/catalog-shipping-parser/catalog-search',
            },
        ],
        label: 'LiveShip',
        permissions: [],
        url: '/liveship/label',
    },
    lotManagerCategories: {
        children: null,
        label: 'Lot Manager',
        url: '/house/<HOUSE-ID>/lot-manager/categories/<CATALOG-ID>',
    },
    lotManagerPerformance: {
        children: null,
        label: 'Lot Manager',
        url: '/house/<HOUSE-ID>/lot-manager/performance/<CATALOG-ID>',
    },
    marketing: {
        children: null,
        label: 'Marketing',
        permissions: [allPermissions.VIEW_OPTIONS],
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/Marketing-<CATALOG-ID>.html',
    },
    messages: {
        children: null,
        label: 'Messages',
        permissions: [allPermissions.EMAIL_BIDDERS],
        url: '/house/<HOUSE-ID>/messages',
    },
    paymentDisbursements: {
        children: null,
        label: 'Disbursements',
        permissions: [allPermissions.VIEW_OPTIONS],
        url: '/house/<HOUSE-ID>/payments/disbursements',
    },
    paymentInvoices: {
        children: null,
        label: 'Invoices',
        permissions: [allPermissions.VIEW_OPTIONS, allPermissions.VIEW_INVOICES],
        url: '/house/<HOUSE-ID>/payments/payments',
    },
    paymentReporting: {
        children: null,
        label: 'Reporting',
        permissions: [allPermissions.VIEW_OPTIONS],
        url: '/house/<HOUSE-ID>/payments/reporting',
    },
    payments: {
        children: null,
        label: 'Payments',
        permissions: [allPermissions.VIEW_OPTIONS, allPermissions.VIEW_INVOICES],
        url: '/house/<HOUSE-ID>/payments',
    },
    paymentSummary: {
        children: null,
        label: 'Summary',
        permissions: [allPermissions.VIEW_OPTIONS, allPermissions.VIEW_INVOICES],
        url: '/house/<HOUSE-ID>/payments/summary',
    },
    reviews: {
        children: null,
        label: 'Reviews',
        url: '/ratings/<HOUSE-ID>',
    },
    settingsMenu: {
        children: [
            {
                label: 'Billing',
                permissions: [allPermissions.VIEW_ACCOUNT],
                url: '/house/<HOUSE-ID>/account-and-billing/billing',
            },
            {
                label: 'My Info',
                url: '/house/<HOUSE-ID>/account-and-billing/my-info',
            },
            {
                label: 'Locations',
                url: '/house/<HOUSE-ID>/account-and-billing/locations',
            },
            {
                label: 'Auctioneer Details',
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/details-<HOUSE-ID>.html',
            },
            {
                label: 'Staff',
                permissions: [allPermissions.STAFF_ADMIN],
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/staff-<HOUSE-ID>.html',
            },
            {
                label: 'Invoice Settings',
                permissions: [allPermissions.VIEW_OPTIONS],
                url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/options-<HOUSE-ID>.html',
            },
        ],
        label: 'Billing & Account',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/details-<HOUSE-ID>.html',
    },
    transcript: {
        children: null,
        label: 'Transcript',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/transcript-<CATALOG-ID>',
    },
    upload: {
        children: null,
        label: 'Upload',
        url: 'https://<CLASSIC-HOST>.liveauctioneers.com/auctioneers/UPLOAD<CATALOG-ID>.html',
    },
    videoSetup: {
        children: null,
        label: 'VIDEOSETUP',
        url: '/catalog/<CATALOG-ID>/videosetup',
    },
};

export const preAuctionNav = [
    allNavTabs['details'],
    allNavTabs['allItems'],
    allNavTabs['catalogStats'],
    allNavTabs['marketing'],
    allNavTabs['payments'],
    allNavTabs['eoa'],
    allNavTabs['invoices'],
    allNavTabs['transcript'],
    allNavTabs['videoSetup'],
];
export const liveAuctionNav = [
    allNavTabs['details'],
    allNavTabs['allItems'],
    allNavTabs['upload'],
    allNavTabs['bidderMenu'],
    allNavTabs['catalogStats'],
    allNavTabs['marketing'],
    allNavTabs['payments'],
    allNavTabs['formerClerksale'],
    allNavTabs['videoSetup'],
];
export const postAuctionNav = [
    allNavTabs['details'],
    allNavTabs['allItems'],
    allNavTabs['catalogStats'],
    allNavTabs['marketing'],
    allNavTabs['payments'],
    allNavTabs['eoa'],
    allNavTabs['invoices'],
    allNavTabs['transcript'],
];
export const auctioneerNav = [
    allNavTabs['home'],
    allNavTabs['homeClassic'],
    allNavTabs['auctionMenu'],
    allNavTabs['auctionSetup'],
    allNavTabs['bidderMenu'],
    allNavTabs['lotManagerCategories'],
    allNavTabs['liveShip'],
    allNavTabs['messages'],
    allNavTabs['globalStats'],
    allNavTabs['globalMarketing'],
    allNavTabs['payments'],
    allNavTabs['reviews'],
    allNavTabs['communityAndHelpMenu'],
    allNavTabs['consignments'],
    allNavTabs['settingsMenu'],
];
