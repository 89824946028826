import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { POST_PAYMENT_OVERRIDE_FAILURE, POST_PAYMENT_OVERRIDE_REQUEST, POST_PAYMENT_OVERRIDE_SUCCESS } from './actions';
import api from '../api/admin';

export interface State {
    readonly error: boolean;
    readonly loading: boolean;
    readonly success: boolean;
}

export const DEFAULT_STATE: State = {
    error: false,
    loading: false,
    success: false,
};

export const reducer = handleActions(
    {
        [POST_PAYMENT_OVERRIDE_FAILURE]: (state: State): State => ({
            ...state,
            error: true,
            loading: false,
        }),
        [POST_PAYMENT_OVERRIDE_REQUEST]: (state: State): State => ({
            ...state,
            loading: true,
        }),
        [POST_PAYMENT_OVERRIDE_SUCCESS]: (state: State): State => ({
            ...state,
            loading: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState): State => state.admin;

export const adminOverrideSuccessful = createSelector(stateSelector, (state) => state.success);

export const submitPaymentOverride =
    (amount: number, catalogId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: POST_PAYMENT_OVERRIDE_REQUEST });

            const response = await api.postPaymentOverride({
                amount: amount * 100, // should be the amount in cents
                authToken,
                catalogId,
                deployment,
                houseId,
            });

            return dispatch({
                payload: response.payload,
                type: POST_PAYMENT_OVERRIDE_SUCCESS,
            });
        } catch (error) {
            dispatch({ error: true, payload: error, type: POST_PAYMENT_OVERRIDE_FAILURE });
        }
    };
