import { handleResponse, makeGet } from './helpers';

type FetchSavedItemCountsParams = {
    deployment: string;
    itemIds: number[];
};

export default {
    fetchSavedItemCounts: ({ deployment, itemIds }: FetchSavedItemCountsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({ deployment, path: '<CROWS-API>saveditemscount' });
            request.query({ lotIds: itemIds.join(',') });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
