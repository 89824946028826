import { FormattedMessage } from 'react-intl';
import { getHouseBetaEnrollments, getHouseBetaEnrollmentsLoading } from '@/redux/modules/houseBetaEnrollments';
import { Throbber } from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';
import { useAppSelector } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import SystemLevelMessage from '@liveauctioneers/caterwaul-components/lib/SystemLevelMessage/SystemLevelMessage';

export type LapMandateNoticeProps = {
    houseId: number;
};

const LapMandateNotice = ({ houseId }: LapMandateNoticeProps) => {
    const { laPaymentsCatalogMandate, payrix_payments } = useAppSelector(getHouseBetaEnrollments);

    const isLoading = useAppSelector(getHouseBetaEnrollmentsLoading);

    let isLapMandate: boolean;
    if (houseId) {
        isLapMandate = laPaymentsCatalogMandate && !payrix_payments;
    }

    if (isLoading) {
        return <Throbber center />;
    }

    return (
        <>
            {houseId && isLapMandate ? (
                <SystemLevelMessage statusType="error">
                    <FormattedMessage
                        id="infoBanner.livePayments.completeYourApplication"
                        values={{
                            link: (
                                <Link to="mailto:payments@liveauctioneers.com">
                                    <FormattedMessage id="infoBanner.livePayments.email" />
                                </Link>
                            ),
                        }}
                    />
                </SystemLevelMessage>
            ) : (
                ''
            )}
        </>
    );
};

export default LapMandateNotice;
