import { ActionWithPayload } from '@/types/redux';
import { BidSource } from '@/types/BidSource';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { DanteCurrentItem } from '@/types/DanteCurrentItem';
import { GlobalState } from '@/redux/rootReducer';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LIVE_BID_SENT,
    LIVE_BID_SENT_ACTION,
    LIVE_LOT_CLOSED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
} from './actions';

export type State = {
    error: string | null | undefined;
    pendingAssignedId: number;
    pendingBidAmount: number;
    pendingBidSource: BidSource | '';
};

export const DEFAULT_STATE: State = {
    error: null,
    pendingAssignedId: 0,
    pendingBidAmount: 0,
    pendingBidSource: '',
};

export const reducer = handleActions(
    {
        [LIVE_BID_ACCEPTED]: (state: State, action: LIVE_BID_ACCEPTED_ACTION): State => {
            const { amount } = action.payload;

            if (amount >= state.pendingBidAmount) {
                return {
                    ...state,
                    pendingAssignedId: 0,
                    pendingBidAmount: 0,
                    pendingBidSource: '',
                };
            }
            return state;
        },
        [LIVE_BID_RETRACTED]: (state: State, action: LIVE_BID_RETRACTED_ACTION): State => {
            const { assignedId } = action.payload;

            if (assignedId === state.pendingAssignedId) {
                return {
                    ...state,
                    pendingAssignedId: 0,
                    pendingBidAmount: 0,
                    pendingBidSource: '',
                };
            }
            return state;
        },
        [LIVE_BID_SENT]: (state: State, action: LIVE_BID_SENT_ACTION): State => {
            const { amount, assignedId, source } = action.payload;

            // TODO: source is 'DesktopWeb' do I even care what the source is?
            // if (source === 'Absentee' || source === 'Internet') {
            return {
                ...state,
                pendingAssignedId: assignedId,
                pendingBidAmount: amount,
                pendingBidSource: source,
            };
            // }
            // return state;
        },
        [LIVE_NEXT_LOT_LOADED]: (state: State, action: LIVE_NEXT_LOT_LOADED_ACTION): State => {
            const { pendingAssignedId, pendingBid } = action.payload;

            if (pendingBid && pendingAssignedId) {
                return {
                    ...state,
                    pendingAssignedId,
                    pendingBidAmount: pendingBid,
                    pendingBidSource: 'Absentee', // I think
                };
            }
            return {
                ...state,
                ...DEFAULT_STATE,
            };
        },
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; currentItem: DanteCurrentItem }>
        ): State => {
            const { currentItem } = action.payload;
            const { pendingAssignedId, pendingBid } = currentItem;

            if (pendingBid && pendingAssignedId) {
                return {
                    ...state,
                    pendingAssignedId,
                    pendingBidAmount: pendingBid,
                    pendingBidSource: 'Absentee', // I think
                };
            }
            return {
                ...state,
                ...DEFAULT_STATE,
            };
        },
        [combineActions(
            LIVE_LOT_CLOSED,
            LIVE_LOT_PASSED,
            LIVE_LOT_REOPENED,
            LIVE_LOT_SKIPPED,
            LIVE_LOT_SOLD,
            LIVE_LOT_UNSOLD
        )]: (state: State): State => {
            return {
                ...state,
                ...DEFAULT_STATE,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.pendingInternetBid;

export const getPendingInternetBid = createSelector(stateSelector, (state) => state);
