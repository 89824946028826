import type { TypedActionWithPayload } from '@/types/redux';

export const LOAD_FOLLOWER_COUNT_FAIL = 'LOAD_FOLLOWER_COUNT_FAIL';
export const LOAD_FOLLOWER_COUNT_REQUEST = 'LOAD_FOLLOWER_COUNT_REQUEST';
export const LOAD_FOLLOWER_COUNT_SUCCESS = 'LOAD_FOLLOWER_COUNT_SUCCESS';
export type LOAD_FOLLOWER_COUNT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_FOLLOWER_COUNT_SUCCESS,
    {
        data: { [sellerId: number]: number };
        message: string;
        success: boolean;
    }
>;
