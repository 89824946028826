import Tracker, { SanitizeLevel } from '@openreplay/tracker';

/**
 * Creates the OpenReplay tracker instance for session/redux recording
 *
 * @see https://docs.openreplay.com/en/sdk/constructor/#initialization-options
 */
const tracker = new Tracker({
    // record input values by default
    defaultInputMode: 0,
    /**
     * @see https://docs.openreplay.com/en/troubleshooting/session-recordings/#white-screen fix white screen sessions
     */
    disableStringDict: true,
    // sanitize sensitive input data before sending to openreplay
    domSanitizer: (node: Element) => {
        const isPassword = node.getAttribute('password') === 'password';

        if (isPassword) {
            return SanitizeLevel.Obscured;
        }

        return SanitizeLevel.Plain;
    },
    ingestPoint: 'https://openreplay.auctiontechnologygroup.com/ingest',
    /**
     * @see https://docs.openreplay.com/en/installation/network-options/
     */
    network: {
        // disable network tracking inside iFrames
        captureInIframes: false,
        // store request/response body payloads
        capturePayload: true,
        // capture requests having 4xx-5xx HTTP status code
        failuresOnly: false,
        // capture all request headers
        ignoreHeaders: false,
        sanitizer: (data) => {
            const apiUrl = data.url.toLowerCase();

            // if api request body contains a password, redact it from openreplay
            if (
                apiUrl.includes('/auth/spalogin') ||
                apiUrl.includes('/user/createpassword') ||
                apiUrl.includes('/auth/spa/reset-password')
            ) {
                data.request.body = null;
            }

            return data;
        },
        sessionTokenHeader: false,
    },
    projectKey: 'gRrQ8hOKYsvib53QDYLp',
});

export default tracker;
