import { BuyNows, Item } from '@/types/CatalogData';
import { CatalogPromotion, Promotion } from '@/types/Promotion';
import { CoverLots } from '@/types/CoverLot';
import { handleResponse, makeGet, makePost } from './helpers';
import { roundValueAndTrimToTwoPlaces, truncateToSecondPlaceWithoutRounding } from '../../utils/numbers';
import type { FetchCatalogsByIdsPayload } from '../../redux/api/catalog';

const transformSmallSellerCatalogsToNeeded = (body) => {
    const catalogIds = body?.data?.catalogs?.map((catalog) => {
        return {
            catalogId: catalog.catalogId,
            hasExplicitMaterial: catalog.hasExplicitMaterial,
            saleStartTs: catalog.saleStartTs,
            status: catalog.catalogStatus,
            title: catalog.title,
        };
    });
    return catalogIds || [];
};

const transformRatingsPercentiles = (body) => {
    return {
        accuracy: Math.floor(body.payload.accuracy),
        isTopRated: Boolean(body.payload.isTopRated),
        overall: Math.floor(body.payload.overall),
        payment: Math.floor(body.payload.payment),
        responsiveness: Math.floor(body.payload.responsiveness),
        shipping: Math.floor(body.payload.shipping),
    };
};

const transformRatingsAndReviews = (body) => {
    const ratings = {
        accuracy: roundValueAndTrimToTwoPlaces(body.payload.accuracy),
        fiveStar: body.payload.fiveStarReviews,
        fourStar: body.payload.fourStarReviews,
        oneStar: body.payload.oneStarReviews,
        overall: truncateToSecondPlaceWithoutRounding(body.payload.overall),
        payment: roundValueAndTrimToTwoPlaces(body.payload.payment),
        responsiveness: roundValueAndTrimToTwoPlaces(body.payload.responsiveness),
        shipping: roundValueAndTrimToTwoPlaces(body.payload.shipping),
        threeStar: body.payload.threeStarReviews,
        twoStar: body.payload.twoStarReviews,
    };
    const totalReviews = body.payload.totalReviews;
    const filteredCount = body.payload.filteredReviewCount;
    let reviews = [];
    if (body.payload.reviews) {
        reviews = body.payload.reviews.map((review) => {
            return {
                bidderFirstName: review.bidderFirstName,
                bidderId: review.bidderId,
                bidderLastName: review.bidderLastName,
                catalogTitle: review.catalogName,
                note: review.note,
                rating: review.overall,
                review: review.review,
                reviewHeadline: review.reviewHeadline,
                reviewId: review.reviewId,
                reviewSubmitted: review.reviewSubmitted,
            };
        });
    }
    const allowPublicReviews = body.payload.allowPublicReviews;
    return { allowPublicReviews, filteredCount, ratings, reviews, totalReviews };
};

const transformReviewComments = (body) => {
    let comments = [];
    if (body.payload && body.payload.map) {
        comments = body.payload.map((comment) => comment);
    }
    return { comments };
};

const transformPreviousPromotionPlanLots = (body) => {
    const lotIds = body.payload.promotedLots.map((lot) => {
        return lot.lotId;
    });
    body.payload.promotedLots = lotIds;
    return body;
};

const transformCoverLotsToIds = (body: GetCoverLotsPayload): number[] => {
    let lotIds: number[] = [];
    if (body.data.length) {
        lotIds = body.data[0].results.map((coverLot) => {
            return coverLot.itemId;
        });
    }
    return lotIds;
};

type FetchBNAReportParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

type FetchHouseReviewCommentsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type FetchSellerCatalogsParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};

type GetAuctionPreviewParams = {
    authToken: string;
    deployment: string;
    previewId: any;
    sellerId: number;
};

type GetCatalogItemsParams = {
    catalogId: number;
    deployment: string;
};

type GetCoverLotsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

type GetPreviousSelectedPromotionPlanParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

type GetRatingsAndReviewsParams = {
    authToken: string;
    deployment: string;
    filter: any;
    houseId: number;
    page: number;
    pageSize: number;
    sort: any;
};

type GetRatingsPercentilesParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type GetSmallCatalogsParams = {
    catalogId: number;
    deployment: string;
};

type GetSmallSellerRecentParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};

type GetSpaUserParams = {
    authToken: string;
    deployment: string;
};

type PostCreateSelectedPromotionParams = {
    authToken: string;
    deployment: string;
    promotionObject: any;
};

type PostReviewCommentParams = {
    authToken: string;
    deployment: string;
    reviewComment: any;
};

type PostSetPublicReviewsPreferenceParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    preference: any;
};

type PostSubmitAuctionPreviewParams = {
    auctionPreview: any;
    authToken: string;
    deployment: string;
};

type PostSubmitCoverLotsParams = {
    authToken: string;
    catalogId: number;
    coverLots: any;
    deployment: string;
};

type PostUpdateAuctionPreviewParams = {
    auctionPreview: any;
    authToken: string;
    deployment: string;
    previewId: any;
};

type PostUpdateImageParams = {
    authToken: string;
    dataUrl: string;
    deployment: string;
    index: number;
    lotId: number;
    lotNum: string;
};

type PostUpdateSelectedPromotionParams = {
    authToken: string;
    deployment: string;
    promotionObject: any;
};

type StartPublishParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    streamId: any;
};

type StopPublishParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    streamId: any;
};

type GetCatalogItemPayload = {
    data: {
        buyNows: BuyNows[];
        items: Item[];
        totalCount: number;
    };
    message: string;
    success: boolean;
};

type GetPreviousSelectedPromotionPlanPayload = {
    error: boolean;
    payload: {
        catalog: CatalogPromotion;
        promotedLots: number[];
        promotion: Promotion | null | undefined;
    };
};

type GetCoverLotsPayload = {
    data: CoverLots[];
};

export const fetchBNAReport = ({ authToken, catalogId, deployment, sellerId }: FetchBNAReportParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `report/house/${sellerId}/catalog/${catalogId}/bna`,
            authToken,
            deployment,
            path: '<HOUSEREPORT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const fetchHouseReviewComments = ({ authToken, deployment, houseId }: FetchHouseReviewCommentsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-house-review-comments',
            authToken,
            deployment,
            path: '<REVIEW-API>',
            queryParams: {
                houseId,
            },
        });
        request.end((err, response) =>
            handleResponse({ err, reject, resolve, response, transform: transformReviewComments })
        );
    });
export const fetchSellerCatalogs = ({ authToken, deployment, sellerId }: FetchSellerCatalogsParams) => {
    return new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${sellerId}/catalogs`,
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
};
export const getAuctionPreview = ({ authToken, deployment, previewId, sellerId }: GetAuctionPreviewParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'house/previews/preview',
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.send({ houseId: sellerId, previewId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const getCatalogItems = ({ catalogId, deployment }: GetCatalogItemsParams) =>
    new Promise<GetCatalogItemPayload>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>spa/small/catalog/items2' });
        request.send({ forceNoCache: true, ids: [catalogId] });
        request.end((err: boolean, response: GetCatalogItemPayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });
export const getCoverLots = ({ authToken, catalogId, deployment }: GetCoverLotsParams) =>
    new Promise<number[]>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<ITEM-API>small/cover-lots' });
        request.send({ forceNoCache: true, ids: [catalogId] });

        request.end((err: boolean, response: GetCoverLotsPayload) =>
            handleResponse({ err, reject, resolve, response, transform: transformCoverLotsToIds })
        );
    });
export const getPreviousSelectedPromotionPlan = ({
    authToken,
    catalogId,
    deployment,
}: GetPreviousSelectedPromotionPlanParams) =>
    new Promise<GetPreviousSelectedPromotionPlanPayload>((resolve, reject) => {
        const request = makeGet({
            apiPath: `catalog/${catalogId}`,
            authToken,
            deployment,
            path: '<PROMOTION-API>',
        });
        request.end((err: boolean, response: GetPreviousSelectedPromotionPlanPayload) =>
            handleResponse({ err, reject, resolve, response, transform: transformPreviousPromotionPlanLots })
        );
    });
export const getRatingsAndReviews = ({
    authToken,
    deployment,
    filter,
    houseId,
    page,
    pageSize,
    sort,
}: GetRatingsAndReviewsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'rating',
            authToken,
            deployment,
            path: '<REVIEW-API>',
            queryParams: {
                filter,
                houseId,
                page,
                pageSize,
                sort,
            },
        });
        request.end((err, response) =>
            handleResponse({ err, reject, resolve, response, transform: transformRatingsAndReviews })
        );
    });
export const getRatingsPercentiles = ({ authToken, deployment, houseId }: GetRatingsPercentilesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'rank',
            authToken,
            deployment,
            path: '<REVIEW-API>',
            queryParams: {
                houseId,
            },
        });
        request.end((err, response) =>
            handleResponse({ err, reject, resolve, response, transform: transformRatingsPercentiles })
        );
    });
export const getSmallCatalogs = ({ catalogId, deployment }: GetSmallCatalogsParams) =>
    new Promise<FetchCatalogsByIdsPayload>((resolve, reject) => {
        const request = makePost({ deployment, path: '<ITEM-API>spa/small/catalogs' });
        request.send({ forceNoCache: true, ids: [catalogId] });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const getSmallSellerRecent = ({ authToken, deployment, sellerId }: GetSmallSellerRecentParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<ITEM-API>spa/small/seller/${sellerId}/upcomingAndBuild`,
        });
        request.end((err, response) =>
            handleResponse({ err, reject, resolve, response, transform: transformSmallSellerCatalogsToNeeded })
        );
    });
export const getSpaUser = ({ authToken, deployment }: GetSpaUserParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ authToken, deployment, path: '<ITEM-API>auth/spauser' });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postCreateSelectedPromotion = ({
    authToken,
    deployment,
    promotionObject,
}: PostCreateSelectedPromotionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'create', authToken, deployment, path: '<PROMOTION-API>' });
        request.send(promotionObject);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postReviewComment = ({ authToken, deployment, reviewComment }: PostReviewCommentParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'create-review-comment',
            authToken,
            deployment,
            path: '<REVIEW-API>',
        });

        if (!reviewComment) {
            reject(new Error('no reviewComment present'));
        }

        request.send(reviewComment);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postSetPublicReviewsPreference = ({
    authToken,
    deployment,
    houseId,
    preference,
}: PostSetPublicReviewsPreferenceParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'set-public-reviews-preference',
            authToken,
            deployment,
            path: '<REVIEW-API>',
        });

        request.send({ houseId, preference });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postSubmitAuctionPreview = ({ auctionPreview, authToken, deployment }: PostSubmitAuctionPreviewParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'house/previews/preview/add',
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.send(auctionPreview);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postSubmitCoverLots = ({ authToken, catalogId, coverLots, deployment }: PostSubmitCoverLotsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ authToken, deployment, path: '<ITEM-API>create/cover-lots' });
        request.send({
            catalogId,
            itemIds: coverLots,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postUpdateAuctionPreview = ({
    auctionPreview,
    authToken,
    deployment,
    previewId,
}: PostUpdateAuctionPreviewParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'house/previews/preview/edit',
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.send({ ...auctionPreview, previewId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postUpdateImage = ({ authToken, dataUrl, deployment, index, lotId, lotNum }: PostUpdateImageParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'ajax_scripts/save_image_edit.php',
            authToken,
            deployment,
            path: '<MAINHOST-API>',
        });
        request
            .type('form')
            .withCredentials()
            .send({ i: index })
            .send({ image: dataUrl })
            .send({ lot_num: lotNum })
            .send({ lotID: lotId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const postUpdateSelectedPromotion = ({
    authToken,
    deployment,
    promotionObject,
}: PostUpdateSelectedPromotionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'update', authToken, deployment, path: '<PROMOTION-API>' });
        request.send(promotionObject);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const startPublish = ({ authToken, catalogId, deployment, streamId }: StartPublishParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'start', authToken, deployment, path: '<STREAM-API>' });
        request.send({ catalogId, streamId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
export const stopPublish = ({ authToken, catalogId, deployment, streamId }: StopPublishParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({ apiPath: 'stop', authToken, deployment, path: '<STREAM-API>' });
        request.send({ catalogId, streamId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
