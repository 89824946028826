import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { fetchItemDetailsByIdsApi } from '../api/itemDetail';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { ItemDetail } from '../../types/ItemDetail';
import { LOAD_ITEM_DETAILS_FAIL, LOAD_ITEM_DETAILS_REQUEST, LOAD_ITEM_DETAILS_SUCCESS } from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_ITEM_DETAILS_FAIL]: (state: State, action: ActionWithPayload<{}, { itemIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.itemIds),
        }),
        [LOAD_ITEM_DETAILS_REQUEST]: (state: State, action: ActionWithPayload<{}>) => ({
            ...state,
            // @ts-ignore
            loading: union(state.loading, action.payload),
        }),
        [LOAD_ITEM_DETAILS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ itemDetails: ItemDetail[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.itemDetails) {
                action.payload.itemDetails.forEach((x) => {
                    existing[x.itemId] = { ...x };
                    loaded[x.itemId] = time;
                    loading = difference(loading, [x.itemId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.itemDetail;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

// takes in a (state, id) and passes both params to every function on
// the way down.
export const getItemDetail = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

export const getLoadTimeForItemDetail = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const isItemDetailLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchItemDetail = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getItemDetail(state, itemId);
    if (item) {
        const loaded = getLoadTimeForItemDetail(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isItemDetailLoading(state, itemId);
    return !loading;
};

const whichItemDetailsNeeded = (state, itemIds) => itemIds.filter((itemId) => shouldFetchItemDetail(state, itemId));

/* ACTION CREATORS */
const fetchItemDetails = (itemIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            payload: itemIds,
            type: LOAD_ITEM_DETAILS_REQUEST,
        });
        const response = await fetchItemDetailsByIdsApi({ deployment, itemIds });
        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.data,
            type: LOAD_ITEM_DETAILS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_ITEM_DETAILS_FAIL,
        });
    }
};

export const fetchItemDetailsIfNeeded = (itemIds: number[]) => async (dispatch: Function, getState: Function) => {
    const needed = whichItemDetailsNeeded(getState(), itemIds);
    if (needed.length) {
        return dispatch(fetchItemDetails(needed));
    }
    return Promise.resolve();
};
