export const ADD_CREDIT_CARD_REQUEST = 'ADD_CREDIT_CARD_REQUEST';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_FAIL = 'ADD_CREDIT_CARD_FAIL';

export const LOAD_CREDIT_CARD_REQUEST = 'LOAD_CREDIT_CARD_REQUEST';
export const LOAD_CREDIT_CARD_SUCCESS = 'LOAD_CREDIT_CARD_SUCCESS';
export const LOAD_CREDIT_CARD_FAIL = 'LOAD_CREDIT_CARD_FAIL';

export const UPDATE_CREDIT_CARD_REQUEST = 'UPDATE_CREDIT_CARD_REQUEST';
export const UPDATE_CREDIT_CARD_SUCCESS = 'UPDATE_CREDIT_CARD_SUCCESS';
export const UPDATE_CREDIT_CARD_FAIL = 'UPDATE_CREDIT_CARD_FAIL';
