import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_BIDDER_REGISTRATIONS_FAIL,
    LOAD_BIDDER_REGISTRATIONS_REQUEST,
    LOAD_BIDDER_REGISTRATIONS_SUCCESS,
} from './actions';
import api from '../api/bidderRegistrations';

/* reducer */
export type State = {
    approved: number;
    approvedWithLimit: number;
    blocked: number;
    declined: number;
    error?: any;
    isLoading: boolean;
    pending: number;
    registered: number;
};

export const DEFAULT_STATE: State = {
    approved: 0,
    approvedWithLimit: 0,
    blocked: 0,
    declined: 0,
    isLoading: false,
    pending: 0,
    registered: 0,
};

export const reducer = handleActions(
    {
        [LOAD_BIDDER_REGISTRATIONS_FAIL]: (state: State, action: ActionWithPayload<{}, { houseId: number }>) => ({
            ...state,
            error: action.payload,
            isLoading: false,
        }),
        [LOAD_BIDDER_REGISTRATIONS_REQUEST]: (state: State) => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BIDDER_REGISTRATIONS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                approved: number;
                approvedWithLimit: number;
                blocked: number;
                declined: number;
                pending: number;
                registered: number;
            }>
        ) => {
            return {
                ...state,
                approved: action.payload.approved,
                approvedWithLimit: action.payload.approvedWithLimit,
                blocked: action.payload.blocked,
                declined: action.payload.declined,
                isLoading: false,
                pending: action.payload.pending,
                registered: action.payload.registered,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.bidderRegistrations;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const getError = createSelector(stateSelector, (state) => state.error);
export const approvedSelector = createSelector(stateSelector, (state) => state.approved);
export const approvedWithLimitSelector = createSelector(stateSelector, (state) => state.approvedWithLimit);
export const blockedSelector = createSelector(stateSelector, (state) => state.blocked);
export const declinedSelector = createSelector(stateSelector, (state) => state.declined);
export const pendingSelector = createSelector(stateSelector, (state) => state.pending);
export const registeredSelector = createSelector(stateSelector, (state) => state.registered);

/* ACTION CREATORS */

export const fetchBidderRegistrationsData =
    (catalogId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BIDDER_REGISTRATIONS_REQUEST,
            });

            const response = await api.getBidderRegistrations({
                authToken,
                catalogId,
                deployment,
                houseId,
            });
            dispatch({
                payload: response.payload,
                type: LOAD_BIDDER_REGISTRATIONS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_BIDDER_REGISTRATIONS_FAIL,
            });
        }
    };
