import { Deployments } from '@/redux/modules/config.types';
import { handleResponse, makeGet, makePost, makePut } from '@/redux/api/helpers';
import { HouseLocation } from '@/redux/modules/houseLocations/houseLocations.types';

type GetHouseLocationParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
};

type GetHouseLocationResponsePayload = {
    error: boolean;
    payload: {
        houseLocations: HouseLocation[];
    };
};

export const getHouseLocations = ({ authToken, deployment, houseId }: GetHouseLocationParams) =>
    new Promise<GetHouseLocationResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<CATALOG-MANAGEMENT-API>house/${houseId}/locations`,
        });
        request.end((err: boolean, response: GetHouseLocationResponsePayload) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type PostHouseLocationParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
    houseLocation: HouseLocation;
};

type PostHouseLocationResult = {
    error: boolean;
    payload: HouseLocation;
};

export const postHouseLocation = ({ authToken, deployment, houseId, houseLocation }: PostHouseLocationParams) =>
    new Promise<PostHouseLocationResult>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<CATALOG-MANAGEMENT-API>house/${houseId}/location`,
        });
        request.send(houseLocation);
        request.end((err: boolean, response: unknown) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

/**
 * @category Validation API
 * @see getHouseLocationValidation
 */
type GetHouseLocationValidationParams = {
    authToken: string;
    deployment: string;
    houseLocation: HouseLocation;
};

export type HouseLocationValidationError = {
    error: {
        code: string;
        logId: string;
        message: string;
    };
};

/**
 * @category User API
 * @see getHouseLocationValidation
 */
export type GetHouseLocationValidationResponse = {
    error: boolean;
    payload: 'success' | HouseLocationValidationError;
};

/**
 * GET address validation from valdation API
 * @function getHouseLocationValidation
 * @category Validation API
 * @param {GetHouseLocationValidationParams}
 * @see https://validation-DEPLOYMENT.liveauctioneers.com/address/validate?country_alpha2=us&region_alpha2=WA&postal_code=98107&city=seattle&bidderid=5847373
 */
export const getHouseLocationValidation = ({
    authToken,
    deployment,
    houseLocation,
}: GetHouseLocationValidationParams) =>
    new Promise<GetHouseLocationValidationResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'address/validate',
            authToken,
            deployment,
            path: '<ADDRESS-VALIDATION-API>',
            queryParams: {
                city: houseLocation.city,
                country_alpha2: houseLocation.countryCode,
                postal_code: houseLocation.postalCode,
                region_alpha2: houseLocation.state,
            },
        });
        return request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type PutHouseLocationParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
    houseLocation: HouseLocation;
};

type PutHouseLocationResponse = {
    error: boolean;
    payload: HouseLocation;
};

export const putHouseLocation = ({ authToken, deployment, houseId, houseLocation }: PutHouseLocationParams) =>
    new Promise<PutHouseLocationResponse>((resolve, reject) => {
        const request = makePut({
            authToken,
            deployment,
            path: `<CATALOG-MANAGEMENT-API>house/${houseId}/location/${houseLocation.addressId}`,
        });
        request.send(houseLocation);
        return request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
