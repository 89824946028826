export const allPermissions = {
    ADD_LOTS: 'addlot',
    ADVANCED_LOT_MANAGEMENT: 'advlotmanage',
    ALTER_ACCOUNT_DETAILS: 'alterclientdetails',
    ALTER_API: 'alterapi',
    ALTER_BIDDERS: 'alterbidders',
    ALTER_EOA: 'altereoa',
    ALTER_INVOICES: 'alterinvoices',
    ALTER_OPTIONS: 'alteroptions',
    DELETE_LOT: 'deletelot',
    EDIT_AUCTIONS: 'editauction',
    EDIT_LOTS: 'editlot',
    EMAIL_BIDDERS: 'emailbidders',
    PROOF_AUCTIONS: 'proofauction',
    SETUP_AUCTION: 'setupauction',
    STAFF_ADMIN: 'staffadmin',
    VIEW_ACCOUNT: 'viewaccount',
    VIEW_AUCTION_STATS: 'viewstats',
    VIEW_BIDDERS: 'viewbidders',
    VIEW_EOA: 'vieweoa',
    VIEW_INVOICES: 'viewinvoices',
    VIEW_LIVE_AUCTIONS: 'viewlivelist',
    VIEW_OPTIONS: 'viewoptions',
    VIEW_POST_AUCTIONS: 'viewpostlist',
    VIEW_PRE_AUCTIONS: 'viewprelist',
    VIEW_REPORTS: 'viewreports',
};
