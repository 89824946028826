import * as React from 'react';
import { getBrowserDataFromUA, setBrowserData } from '@/redux/modules/browser';
import { useAppDispatch } from '@/redux/hooks';

const useBrowserAgent = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const userAgent = navigator.userAgent || '';
        const browser = getBrowserDataFromUA(userAgent);
        dispatch(setBrowserData(browser));
    }, [dispatch]);
};

export default useBrowserAgent;
