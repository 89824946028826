import { handleResponse, makeGet } from './helpers';

type FetchForumPostsParams = {
    authToken: string;
    deployment: string;
};

export const fetchForumPosts = ({ authToken, deployment }: FetchForumPostsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'admin',
            authToken,
            deployment,
            path: '<FORUMPOSTS-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
