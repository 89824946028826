import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_FOLLOWER_COUNT_FAIL,
    LOAD_FOLLOWER_COUNT_REQUEST,
    LOAD_FOLLOWER_COUNT_SUCCESS,
    LOAD_FOLLOWER_COUNT_SUCCESS_ACTION,
} from './actions';
import api from '../api/followerCount';

export type State = {
    error: boolean;
    followerCount: { [sellerId: number]: number };
    isLoading: boolean;
};

export const DEFAULT_STATE: State = {
    error: false,
    followerCount: { 0: 0 },
    isLoading: false,
};

/* REDUCER */
export const reducer = handleActions(
    {
        [LOAD_FOLLOWER_COUNT_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),

        [LOAD_FOLLOWER_COUNT_REQUEST]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: true,
        }),

        [LOAD_FOLLOWER_COUNT_SUCCESS]: (state: State, action: LOAD_FOLLOWER_COUNT_SUCCESS_ACTION): State => {
            return {
                ...state,
                error: false,
                followerCount: action.payload[0],
                isLoading: false,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */

const stateSelector = (state: GlobalState): State => state.followerCount;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const followerCountSelector = createSelector(stateSelector, (state) => state.followerCount);

/* ACTION CREATORS */

export const fetchFollowerCount = (sellerIds: number[]) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_FOLLOWER_COUNT_REQUEST,
        });

        const response = await api.fetchFollowerCount({ authToken, deployment, sellerIds });

        dispatch({
            payload: response.data,
            type: LOAD_FOLLOWER_COUNT_SUCCESS,
        } as LOAD_FOLLOWER_COUNT_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            payload: error,
            type: LOAD_FOLLOWER_COUNT_FAIL,
        });
    }
};
