import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { DanteCurrentItem } from '@/types/DanteCurrentItem';
import { GlobalState } from '@/redux/rootReducer';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_NEXT_LOT_LOADED,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    SEND_LOT_PASSED_FAILURE,
    SEND_LOT_PASSED_FAILURE_ACTION,
    SEND_LOT_REOPEN_FAILURE,
    SEND_LOT_REOPEN_FAILURE_ACTION,
    SEND_LOT_SKIPPED_FAILURE,
    SEND_LOT_SKIPPED_FAILURE_ACTION,
    SEND_LOT_SOLD_FAILURE,
    SEND_LOT_SOLD_FAILURE_ACTION,
    SEND_OPEN_NEXT_LOT_FAILURE,
    SEND_OPEN_NEXT_LOT_FAILURE_ACTION,
} from './actions';

type LiveItemStatus = {
    bidderLiveBid: number;
    catalogId: number;
    itemId: number;
    open: boolean;
};

export type State = {
    byId: {
        [key: number]: LiveItemStatus;
    };
};

export const DEFAULT_STATE: State = {
    byId: {},
};

const closeAllLots = (oldState: State['byId'], catalogId: number) => {
    const lots = {};
    Object.keys(oldState).forEach((cur) => {
        const key = Number(cur);
        const lot = { ...oldState[key] };
        if (lot.catalogId === catalogId) {
            lot.open = false;
        }
        lots[lot.itemId] = lot;
    });
    return lots;
};

export const reducer = handleActions(
    {
        [LIVE_BID_ACCEPTED]: (state: State, action: LIVE_BID_ACCEPTED_ACTION): State => {
            const { amount, catalogId, itemId, myBid } = action.payload;

            const existing = closeAllLots(state.byId, catalogId);

            existing[itemId] = {
                bidderLiveBid: myBid ? amount : 0,
                catalogId,
                itemId,
                open: true,
            };
            return {
                ...state,
                byId: existing,
            };
        },

        [combineActions(LIVE_BID_RETRACTED, LIVE_LOT_REOPENED, LIVE_NEXT_LOT_LOADED)]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; itemId: number }>
        ): State => {
            const { catalogId, itemId } = action.payload;

            const existing = closeAllLots(state.byId, catalogId);

            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: true,
            };
            return {
                ...state,
                byId: existing,
            };
        },
        [combineActions(LIVE_LOT_PASSED, LIVE_LOT_SOLD, LIVE_LOT_UNSOLD)]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; itemId: number }>
        ): State => {
            const { catalogId, itemId } = action.payload;

            const existing = closeAllLots(state.byId, catalogId);

            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: false,
            };
            return {
                ...state,
                byId: existing,
            };
        },
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; currentItem: DanteCurrentItem }>
        ): State => {
            const { catalogId, currentItem } = action.payload;
            const { itemId, lotStatus } = currentItem;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'open',
            };

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_LOT_PASSED_FAILURE]: (state: State, action: SEND_LOT_PASSED_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { itemId, lotStatus } = currentState;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'OPN' || lotStatus === 'RE_OPND',
            };

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_LOT_REOPEN_FAILURE]: (state: State, action: SEND_LOT_REOPEN_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { itemId, lotStatus } = currentState;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'OPN' || lotStatus === 'RE_OPND',
            };

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_LOT_SKIPPED_FAILURE]: (state: State, action: SEND_LOT_SKIPPED_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { itemId, lotStatus } = currentState;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'OPN' || lotStatus === 'RE_OPND',
            };

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_LOT_SOLD_FAILURE]: (state: State, action: SEND_LOT_SOLD_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { itemId, lotStatus } = currentState;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'OPN' || lotStatus === 'RE_OPND',
            };

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_OPEN_NEXT_LOT_FAILURE]: (state: State, action: SEND_OPEN_NEXT_LOT_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { itemId, lotStatus } = currentState;

            if (!itemId) {
                return state;
            }

            const existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'OPN' || lotStatus === 'RE_OPND',
            };

            return {
                ...state,
                byId: existing,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.liveItemStatus;
const idSelector = (state: GlobalState, id: number) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getLiveItemStatus = createSelector(
    [byIdSelector, idSelector],
    (byId, id): LiveItemStatus =>
        byId[id] || {
            bidderLiveBid: 0,
            catalogId: 0,
            itemId: 0,
            open: false,
        }
);
