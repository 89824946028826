import { handleResponse, makeGet } from './helpers';

type FetchCampaignParams = {
    authToken?: string;
    campaignId: string;
    deployment: string;
    eventId: number;
    eventType: number;
    houseId: number;
};

export default {
    fetchCampaignById: ({ authToken, campaignId, deployment, eventId, eventType, houseId }: FetchCampaignParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({ authToken, deployment, path: '<MARKETING-REPORTS-API>/getemailcampaignstats' });

            request.query({
                blastId: campaignId,
                eventId,
                eventType,
                houseId,
            });

            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
