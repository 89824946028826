import { Carriers } from '@/pages/Shipping/subpages/LabelsPage/components/AvailableCarriers/components/CarrierBadge/CarrierBadge.types';
import { Printers } from '@/redux/modules/shipping/labels/shippingLabels.types';
import caterwaulIntlEn from '@liveauctioneers/caterwaul-components/lib/Intl/intl/en';

/*
Organize your translations by area, and do them in camelCase
*/

export default {
    accountAndBilling: {
        accountingOverview: 'Accounting Overview',
        billing: 'Billing',
        billingSetup: 'Billing Set-up',
        legacyBilling: 'Billing (Legacy)',
        locations: {
            add: {
                addLocation: 'Add Location',
                modal: {
                    error: {
                        close: 'Close',
                        description:
                            'Please ensure that the nickname you chose is not already taken and that the address is valid. If the error persists, please contact our support team.',
                        title: 'Error',
                    },
                    form: {
                        address1: {
                            error: 'Enter a valid address',
                            label: 'Address',
                        },
                        address2: {
                            label: 'Apt, Suite, etc. (Optional)',
                        },
                        city: {
                            error: 'Enter a city',
                            label: 'City',
                        },
                        country: {
                            error: 'Select a country',
                            label: 'Country',
                        },
                        newLocation: 'New Location',
                        nickname: {
                            error: 'Enter a name for your location',
                            help: 'We recommend that the nickname be short and easy to remember',
                            label: 'Nickname',
                            placeholder: 'e.g. Warehouse 1',
                        },
                        postalCode: {
                            error: {
                                required: 'Enter ZIP / Postal Code',
                                stateMismatch: 'Postal code and state do not match',
                            },
                            label: 'ZIP/Postal Code',
                        },
                        state: {
                            error: 'Select a state',
                            label: 'State/Province',
                        },
                    },
                },
            },
            card: {
                archive: 'Archive',
                edit: 'Edit',
                restore: 'Restore',
            },
            description:
                'Locations listed below should only reflect those at which lots are stored/shipped out of. These will be used to identify the location of a lot for the purpose of shipping/pickup. {readMoreLink}',
            descriptionReadMore: 'Read More',
            edit: {
                cancel: 'Cancel',
                description:
                    'Please note, any edits made to a location will reflect on all past, present, and future lots and catalogs affiliated with the said location.',
                save: 'Save Changes',
                title: 'Edit Location',
            },
            title: 'Locations',
            viewArchived: 'Show Archived Locations',
        },
        myInfo: 'My Info',
        title: 'Account & Billing',
    },
    accountingContact: {
        accountingContact: 'Accounting Contact',
        addAccountingContact: 'Add Accounting Contact',
        ariaLabel: {
            contactEmail: 'Accounting Contact Email',
            contactFaxNumber: 'Accounting Contact Fax Number',
            contactName: 'Accounting Contact Name',
            contactPhone: 'Accounting Contact Phone Number',
        },
        edit: 'Edit',
        editAccountingContact: 'Edit Accounting Contact',
        email: 'Email',
        fax: 'Fax: ',
        faxNumber: 'Fax',
        invalidEmail: 'Enter a valid email address',
        invalidName: 'Enter a name',
        invalidPhone: 'Enter a valid phone number',
        name: 'Full Name',
        phone: 'Phone: ',
        phoneNumber: 'Phone Number',
        pleaseAdd: 'Please add accounting contact:',
        saveSettings: 'Save Settings',
        sendInvoiceEmails: "We'll send invoice emails to this contact.",
    },
    accountSettings: {
        billing: 'Billing',
        changePassword: 'Change Password',
        email: 'Email',
        logo: 'Logo',
        myInfo: 'My Info',
        password: 'Password',
        username: 'Username',
    },
    admin: {
        paymentOverride: 'Admin override payment',
    },
    auctioneerCarousel: {
        position: '{currentIndex} / {lotsListed}',
    },
    auctioneerConsole: {
        exit: 'Exit',
        live: 'Live',
    },
    auctionPreview: {
        add: 'Add Image',
        complete: {
            button: 'Return to dashboard',
            message:
                'Congratulations! Your auction preview is now live and viewable for bidders! To convert your preview to an auction, just revisit this flow.',
        },
        form: {
            button: 'Complete',
            description: 'Auction Preview Description',
            image: 'Auction Preview Image',
            link: 'here',
            note: 'Note: auction time is set in PT time standard. Look up time zone converter {link}.',
            time: 'Auction Preview Time',
            title: 'Auction Preview Title',
        },
        remove: 'Remove',
        title: 'Auction Preview Setup',
    },
    balancePayment: {
        achPayment: 'Bank Payment (ACH)',
        add: 'Add',
        addOneNow: 'add one now?',
        address1: 'c/o Mark Lichter',
        address2: '9740 62nd Drive, #4J',
        address3: 'Rego Park, NY 11374',
        billingQuestions: 'Billing Questions?',
        cancel: 'Cancel',
        cardOnFile: 'Card on File',
        forFurtherInfo:
            'For further information please contact our finance department via email at finance@liveauctioneers.com or at 888-600-2437, option 2, then option 3.',
        hideOtherMethods: 'Hide Other Payment Methods',
        invalidPaymentAmount: 'Invalid payment amount',
        invalidPaymentAmount0: 'Payment must be more than {currencySymbol}0',
        invalidPaymentAmountHigherThanBalance: 'May not be higher than the balance due',
        liveAuctioneersLLC: 'Live Auctioneers LLC',
        makeAPayment: 'Make a Payment',
        makeChecksPayableTo: 'Make Checks Payable To: ',
        makeThisMyPrimary: 'Make this my primary payment method.',
        noCardsAdded: 'There are currently no cards on file. Click continue to add one.',
        payByCheck: 'Pay by Check',
        paymentAmount: 'Payment Amount',
        payWithPaypal: 'Pay with Paypal',
        saveAndContinue: 'Continue',
        selectYourMethod: 'Select your payment method.',
        showOtherMethods: 'More Payment Methods',
        update: 'Update',
        usePartialPayment: 'Make Partial Payment',
    },
    bidderProfile: {
        title: 'Bidder Profile',
    },
    bidders: {
        activeBids: 'Placed Bid',
        addBidder: {
            placeholderText: 'bidderId', // 'Enter Bidder Email or Username',
            title: 'Add a New Bidder',
        },
        addFavorite: 'Add to Favorite Bidders?',
        addFavoriteWitihValue:
            'Do you want to add {bidderLength} {singular} to your Favorite Bidders list to be automatically approved?',
        address: 'Address:',
        addSingleBlocked: 'Add to Blocked Bidders',
        addSingleFavorite:
            'Add to Favorite Bidders to auto-approve them in the future.  Bidders with open disputes, suspensions, or blocked by our fraud team will not be approved.',
        approvalAssessment: 'Live.Approvals',
        approvalTooltip: {
            learnMore: 'Learn More',
            tip: 'Live.Approvals indicates whether an active bidder would be approved according to best practices used across the industry. These insights are provided as added context, and should not be solely relied upon for approval decisions.',
        },
        approve: 'Approve',
        approveAll: 'Approve All ({regLength})',
        approved: 'Approved',
        approvedLimited: 'Approved Limited',
        approveWithNumber: 'Approve ({number})',
        attended: 'Attended',
        auction: 'Auction',
        auctionsAttended: 'Auctions Attended',
        auctionToReview: 'Select An Auction to Review',
        autoApprove: 'Automation Settings',
        automaticRules: 'Custom Auto-Approval',
        automaticRulesTab: {
            acceptFavoriteBidder: 'Accept Favorite Bidder Recommendations',
            alwaysApprove: 'Always Approve',
            alwaysApproved: 'Always Approved',
            apply: 'Apply',
            areThereBidders: 'Are there bidders that won’t be automatically approved?',
            areThereBiddersInfoOne:
                'Not all bidders will be automatically approved. Under all automation options besides the Bidder Guarantee Program, bidders with open disputes, suspensions, or blocked by our fraud team will not be approved. ',
            areThereBiddersInfoTwo:
                'If you enroll in the Bidder Guarantee Program, bidders will not be auto-approved if they are on your Blocked Bidders list, are suspended, or have an open dispute with you. Bidders who receive a new open dispute after being auto-approved will be moved back to pending.',
            auctionLimit: 'Have been approved for at least',
            autoApprove: 'Auto-Approve Premium Bidders',
            automateApprovals: 'Automate Approvals Your Way',
            automateApprovalsMessage:
                'Confidently automate your approval decisions by selecting the criteria that matter most to you. Use these rules independently or in combination to maximize automation while managing risk. Bidders with open disputes, suspensions, or blocked by our fraud team will not be approved.',
            automateOne:
                'When you implement automated approvals, you will save time normally spent individually reviewing bidders who meet your desired criteria. With this saved time, you can focus more on what really matters to you—showcasing unique items that drive your business.',
            automateTwo:
                'Your bidders who are automatically approved will also have a more enjoyable experience by not having to wait for approval to start participating in your auctions.',
            automationOptionsOne:
                'Consider the criteria you currently weigh when making approval decisions. Whether you want to automatically approve every bidder you have approved previously, or you want to select higher standards such as high past spending on LiveAuctioneers, a large number of past successful wins, and a card on file, you can tailor a solution to meet your needs.',
            automationOptionsTwo:
                'LivePayments users have the option to take advantage of the Bidder Guarantee Program that offers automatic approvals of carefully selected bidders, along with payment guarantees. Find out more {link}.',
            bidder: 'bidder',
            bidderGuranteeProgram: 'Bidder Guarantee Program',
            bidders: 'bidders',
            bidLimit: 'Bid Limit {limit}',
            blockedBidderNote:
                'Note: Bidders will not be auto-approved if they are on your Blocked Bidders list, are suspended, or have an open dispute with you. Bidders who receive a new open dispute after being auto-approved will be moved back to pending.',
            cancel: 'Cancel',
            claimsProcess: 'claims process',
            confirm: 'Confirm',
            confirmEnrollment: 'Confirm Enrollment: Bidder Guarantee Program',
            confirmEnrollmentModalMessage:
                'Upon enrollment, Premium Bidders will be automatically approved.  Please review the {kblink} and contact {link} if you have questions or wish to opt-out.',
            confirmFavoriteBidder:
                'Once confirmed, all Favorite Bidder Recommendations will be accepted and added to your Favorite Bidder List.',
            confirmOptOut: 'Confirm Opt-out: Bidder Guarantee Program',
            confirmOptOutMessage:
                'Once confirmed, this auction seller will no longer be enrolled in the Bidder Guarantee Program.',
            copyBidderBody:
                'Once confirmed, {count} {bidder} will be added to your Favorite Bidders List and automatically approved for all future auctions. Bidders you approve moving forward will also be added to your Favorite Bidders List. Please allow up to 24 hours for this update to finish processing.',
            copyBidderCancelBody:
                'Once confirmed, your Favorite Bidders List will stop growing automatically, and you may see fewer automated approvals for your future auctions.',
            copyBidderHeader: 'Do you want to copy all previously approved bidders to your Favorite Bidders List?',
            copyBidderHeaderCancel:
                'Are you sure you want to stop adding previously approved bidders to your Favorite Bidders List?',
            custom: 'Customizable House Rule',
            customApproval: 'Custom Auto-Approval',
            customApprovalLimit: 'Custom Auto-Approval with Bid Limit',
            customApprovalLimitMessage:
                'Select criteria to automatically approve qualifying bidders to participate in your auctions with a bid limit applied. This option is ideal for allowing bidders with shorter bidding histories to participate in your auctions while limiting risk.',
            customApprovalMessage:
                'Select criteria to automatically approve qualifying bidders to participate in your auctions without a bid limit.',
            customRuleHeader: 'Automatically approve bidders if {selector} of the following conditions are met:',
            customRuleWithLimit: 'Customizable House Rule With Bid Limit',
            edit: 'Edit',
            enroll: 'Enroll',
            enrolled: 'Enrolled',
            enrolledOn: 'Enrolled on {date}',
            favoriteBidderBodyText:
                'You have approved these bidders before, they have successfully completed at least one transaction with you, and they have never had a dispute with you. This will apply to bidders with current registrations and those who register in the future.',
            favoriteBidderOff: 'Are you sure you want to turn off Favorite Bidder Recommendations?',
            favoriteBidderOffMessage:
                'Favorite Bidder Recommendations help you instantly approve your best bidders. Once turned off, your Favorite Bidders List will stop growing automatically, requiring you to spend more time reviewing bidders you may already know and trust.',
            favoriteBidderRecommendations: 'Favorite Bidder Recommendations',
            growYourList: 'Favorite Bidder Recommendations',
            haveCard: 'Have a verified credit card on file',
            haveSpent: 'Have spent',
            here: 'here',
            howIBenefit: 'How will I benefit from automating approvals?',
            knowledgeBaseArticle: 'knowledge base article',
            limitTooltip: 'Allow these bidders to bid up to this specified amount in your auction.',
            liveApprovals: 'Live.Approvals Rule',
            liveApprovalsHeader: 'Are likely approved through {link}',
            liveApprovalsHeaderNoLink: 'Are likely approved through',
            myPastBidders: 'My Past Bidders',
            no: 'No',
            notAutoApproved:
                'Bidders will not be auto-approved if they are on your Blocked Bidders list, are suspended, or have an open dispute with you. Bidders who receive a new open dispute after being auto-approved will be moved back to pending.',
            optOut: 'Opt-Out',
            orMore: 'item(s)',
            orMoreAuctions: 'auction(s)',
            pastBiddersHeader: 'I have approved them in the past',
            pastBiddersMessage:
                'Bidders with open disputes, suspensions, or blocked by our fraud team will not be approved.',
            premiumBidders:
                'Automated approvals with payment guarantees available to Auction Houses using LivePayments*',
            premiumBiddersOptIn:
                'Learn more about the Bidder Guarantee Program {hereLink}, review our {claimLink}, and enroll by contacting {link}.',
            premiumBiddersOptOut:
                'If you need to file a claim, please review the Bidder Guarantee Program {claimLink}. For all other questions or to opt-out of this program, please contact {link}.',
            premiumRule: 'Bidder Guarantee Program',
            premiumRuleMain:
                'LiveAuctioneers has identified some of the most reliable bidders on the platform and labeled them as Premium Bidders. When you enroll in the {link}, Premium Bidders will be automatically approved, saving you time on manual approvals and ensuring you’re protected in the event of a non-paying bidder.',
            readyToAccept: 'Ready To Accept?',
            requirements: 'Do I have to meet certain requirements to use automation?',
            requirementsInfo:
                'All Auction Houses on LiveAuctioneers can start simplifying their work by implementing automation, free of cost. The Bidder Guarantee Program is an exclusive perk available to LivePayments users. Find out more {link}.',
            saveCriteria: 'Save Criteria',
            savedOn: 'Saved on {date}',
            startAutomating: 'How do I start automating my approval decisions?',
            startAutomatingInfo:
                'Once you have determined what criteria matters to you, simply navigate through the automation tools from the menu and turn on the rules that you feel comfortable with. In the case of Favorite Bidders, you can add a bidder to the list by clicking the star icon or by entering in their username or email address.',
            subjectTo: '*Subject to the Bidder Guarantee Program {link}',
            termsAndConditions: 'Terms and Conditions',
            title: 'Set up Automatic Approval Rules',
            turnOnFavoriteBidders: 'Do you want to turn on Favorite Bidder Recommendations?',
            understandingAutomation: 'Understanding Automation',
            whichAutomationOptions: 'How do I know which automation options are right for me?',
            wonItemsLimit: 'Have won at least',
            yes: 'Yes',
        },
        avgHammer: 'Avg Hammer Price',
        bidCount: '{bidCount} bid',
        bidCountPlural: '{bidCount} bids',
        bidderConversations: {
            lastMessage: 'Last Message: {date}',
            messageCount: '{start} - {end} of {all}',
            none: '0 of 0',
            viewAll: 'View All Conversations',
            viewMessages: 'View Messages ({messages})',
        },
        bidderDetails: 'Bidder Analytics',
        bidderMessaging: {
            conversations: 'Conversations with Bidder',
        },
        bidderName: 'Bidder Name',
        bidderNameOrUsername: 'Bidder Name or Username',
        bidderProfile: 'BIDDER PROFILE',
        bidders: 'Bidders',
        bidderSearch: 'Search for and apply filters to bidders that are: {status} in {auction}.',
        bidderTable: {
            remove: 'Remove',
            unblock: 'Unblock',
        },
        bidLimit: 'Bid Limit',
        bids: 'Bids',
        bidsPlaced: 'Bids Placed',
        bidsPlacedColon: 'Bids Placed: {bids}',
        blocked: 'Blocked',
        blockedBidders: 'Blocked Bidders',
        blockedBiddersTab: {
            blockBidder: 'Block Bidder',
            blockBidders: 'Add Bidders to Blocked Bidders List',
            blockBiddersText:
                'Bidders added to your Blocked Bidders List are permanently blocked from bidding in all of your auctions.',
            cta: 'Block bidder',
            placeholderText: 'bidderId', // 'Enter Bidder Email or Username',
            subtitle: 'Text explaining behavior of Blocked Bidder List.',
            tableTitle: 'Blocked Bidders List',
            title: 'Add a New Bidder',
            userOrEmail: 'Username or Email',
        },
        cancel: 'Cancel',
        cardOnFile: 'Card on File',
        clearFilters: 'Clear Filters',
        closedDispute: '1 closed dispute in the last 12 months',
        closedDisputes: 'Closed Disputes',
        closedDisputesNumber: '{count} closed disputes in the last 12 months',
        confirm: 'Confirm',
        confirmCopyAllBiddersModal: {
            body: `The Copy Bidders feature (in our classic version) has been replaced by the option to
            "Copy All Previously Approved Bidders" to your Favorite Bidders List. Since you've
            used Copy Bidders in the past, we’re making it simple to transition to this new feature.
            {paragraphBreak}
            If you confirm, {numPrevApprovedBidders} bidders will be added to your
            Favorite Bidders List and automatically approved for all future auctions. Bidders you
            approve moving forward will also be added to your Favorite Bidders List. Please allow
            up to 24 hours for this update to finish processing.`,
            controls: {
                confirmButton: 'Confirm',
                declineButton: 'Decline',
            },
            successToastBody: 'Confirmed activation of Copy All Previously Approved Bidders on {date}.',
            title: 'Confirm Activation of Copy Bidders Replacement',
        },
        confirmFavoriteBidderRecommendationsModal: {
            body: `Favorite Bidder Recommendations help grow your Favorite Bidders List by adding
            bidders that you have approved in the past and that have successfully completed at
            least one transaction with you as they register for your auctions.
            {paragraphBreak}
            If you confirm, {numRecommendedFavorites} Recommended Favorite Bidders with pending registrations
            will be added to your Favorite Bidders List and automatically approved for all future auctions.
            Moving forward, bidders that satisfy this criteria and register for your auctions in the future will
            automatically be added to you Favorite Bidders List. Please allow up to 24 hours for this update to finish processing.`,
            controls: {
                confirmButton: 'Confirm',
                declineButton: 'Decline',
            },
            successToastBody: 'Confirmed activation of Favorite Bidder Recommendations on {date}.',
            title: 'Confirm Activation of Favorite Bidder Recommendations',
        },
        country: 'Country',
        decline: 'Decline',
        declined: 'Declined',
        declineWithNumber: 'Decline ({number})',
        details: 'Details',
        disputeDiscription: 'on {date} for {amount}',
        disputeDiscriptionLong: '{house} on {date} for {amount} {code}.',
        disputeHistory: 'Dispute History: {first} {last}',
        disputeHistoryName: 'Dispute History',
        disputes: '{open} open, {closed} closed',
        favorite: 'Favorite',
        favoriteBidders: 'Favorite Bidders',
        favoriteBiddersTab: {
            addBidders: 'Grow Your Favorite Bidders List',
            addBiddersLink: 'Favorite Bidder Recommendations.',
            addBiddersParagraphOne:
                'Simplify your approvals by adding bidders you know and trust to your Favorite Bidders. You’ll save time reviewing quality bidders, and they’ll enjoy a more seamless experience by being automatically approved to participate in your auctions. Choose how to grow your list with one or more of the following methods. If a Favorite Bidder has an open dispute and registers for your auction, they will remain in Pending for your review.',
            addIndividually: 'Add Bidders Individually',
            addIndividuallyText:
                'You can add a bidder individually by clicking the star icon on the Manually Approve page or by entering in the bidder’s username or email address.',
            addToFavorites: 'Add To Favorites',
            copyBidders: 'Copy All Previously Approved Bidders',
            copyBiddersText:
                'You have approved these bidders before. This will apply to bidders with current registrations, those who register in the future, and those with past registrations.',
            cta: 'Always approve bidder',
            placeholderText: 'bidderId', // 'Enter Bidder Email or Username',
            subtitle: 'Add a bidder id to add a bidder to your favorite list.',
            tableTitle: 'Favorite Bidders List',
            title: 'Add a New Bidder',
            userOrEmail: 'Username or Email',
        },
        favorited: 'Favorited',
        favoriteTooltip:
            'We think this may be a bidder you know and trust. By {link} these bidders will be automatically added to your Favorite Bidders List.',
        favoriteTooltipLink: 'accepting Favorite Bidders Recommendations',
        hasBidLimit: 'Has Bid Limit',
        international: 'International',
        isPremium: 'Premium Bidder',
        itemsWon: 'Items Won',
        leadingBid: 'Leading Bid Value',
        learnMore: 'Learn More',
        likelyApproved: 'Likely Approved',
        liveApprovals: 'Live.Approvals:',
        location: 'Location',
        manualApproval: 'Manual Approval',
        memberSince: 'Member Since',
        message: 'Message',
        messageBidder: 'Message Bidder',
        mobileTel: 'Tel:',
        more: 'More...',
        moreNoDots: 'More',
        moreWithNumber: '{number} More',
        na: 'N/A',
        name: 'Bidder Name',
        no: 'No',
        noBidders: 'No Bidders',
        noBidLimit: 'No Bid Limit',
        noRecords: 'No Records found for your filter criteria',
        note: 'Note',
        notes: 'Notes on Bidder',
        oneApproved: "You've approved 1 bidder.",
        oneDeclined: "You've declined 1 bidder.",
        openDispute: '1 open dispute',
        openDisputes: 'Open Disputes',
        openDisputesNumber: '{count} open disputes',
        or: 'or',
        payment: 'Payment',
        paymentRate: 'Payment Rate',
        pending: 'Pending',
        prediction: 'Prediction',
        premiumBidder: 'Premium Bidder: ',
        premiumBidderTooltip:
            'Premium Bidders have been identified as some of the most reliable bidders based on their previous auction history.',
        premiumBidderTooltipTwo:
            'If you are enrolled in the Bidder Guarantee Program, Premium Bidders will be automatically approved and LiveAuctioneers will back their purchases.',
        recommendedFavorite: 'Recommended Favorite:',
        recommendedFavoriteTooltip:
            'Premium Bidders have been identified as some of the most reliable bidders based on their previous auction history.',
        registered: 'Registered',
        registeredColon: 'Registered: {time}',
        registrationApprovedWithLimitStatus: 'Status: Approved for {bidLimit}',
        registrations: 'Registrations',
        removeBlocked: 'Remove from Blocked Bidders',
        removeFavorite: 'Remove from Favorite Bidders',
        restrictBiddersBanner:
            'In light of current events and resulting operational complexities, bidders located in Russia will not be automatically approved. Any registrants located in Russia will remain in pending for manual review for the time being.',
        riskLevel: {
            custom: 'Custom',
            high: 'Highest Risk',
            low: 'Lowest Risk',
            medium: 'Medium Risk',
        },
        saveNote: 'Save Note',
        selected: '{count} Selected Bidders',
        selectedNoCount: 'Selected',
        selectRegistrations: 'Select which registrations to approve individually or together.',
        status: 'Status',
        statusColon: 'Status: {active}',
        taxExemption: 'Tax Exemption',
        tempMainhostLink:
            'Welcome to the improved Bidder Approval page. Stay tuned for more enhancements over the coming days and weeks. To revert back to the classic page, click {link}.',
        total: 'Total',
        totalBid: 'Total Bid Value',
        unitedStates: 'United States',
        upcomingAuctions: 'All Upcoming auctions',
        upcomingRegistrations: 'Upcoming Registrations ({regLength})',
        usedBy: 'Rule used by {count} other houses',
        userName: 'Username',
        userNameOrEmail: 'Username or email',
        verifiedPayment: 'Verified Card',
        verifiedPhoneNumber: 'Verified Phone Number',
        view: 'View By:',
        won: 'won',
        yes: 'Yes',
    },
    billing: {
        accountId: 'Id',
        activity: 'Activity',
        addMethod: 'Add Payment Method',
        addPaymentMethod: 'Please add a payment method:',
        all: 'All',
        amount: 'Amount',
        balanceDue: 'Balance',
        billingMethods: 'Payment Method',
        charges: 'Charges',
        collections: 'Collections',
        date: 'Date',
        dateRange: 'Date Range:',
        deliquent: 'Deliquent',
        description: 'Description',
        inactive: 'Inactive',
        invoice: 'Invoice',
        invoices: 'Invoices',
        last15Days: 'Last 15 Days',
        last30Days: 'Last 30 Days',
        last60Days: 'Last 60 Days',
        last7Days: 'Last 7 Days',
        last90Days: 'Last 90 Days',
        limitedAccess: 'Limited Access',
        listingFees: 'Listing Fees',
        maintainValidCard: 'Auctioneers must maintain a valid card on file to sell on LiveAuctioneers.',
        noActivityYet: 'No Activity Yet',
        notAvailable: 'N/A',
        payingByCheckOrPaypal: 'Paying by check or PayPal?',
        payments: 'Payments',
        payNow: 'Pay Balance',
        primary: 'Primary',
        requiresUpdate: 'Requires updated payment info',
        secondary: 'Secondary',
        transactionType: 'Transaction Type:',
        update: 'Update',
    },
    breadcrumbs: {
        auctions: 'Auctions',
        lots: 'Lots',
        performance: 'Performance',
    },
    campaign: {
        breadcrumbs: {
            base: 'Marketing',
            current: 'Ad Campaigns',
        },
        campaignsTitle: 'Campaigns and Events',
        cta: 'New Campaign',
        emailClicks: {
            table: {
                clicks: 'Clicks',
                link: 'Link',
            },
            title: 'Email Clicks',
        },
        sectionTitles: {
            bottom: 'Conversions Driven',
            emailTop: 'Inbox Engagement',
            right: 'Insights',
            top: 'Engagement',
        },
        statsBlocks: {
            bids: {
                popover:
                    'Total number of absentee bids, regardless if they were accepted or not, and live bids from bidders who opened your email or clicked your ad.',
                title: 'Bids',
            },
            clickThrough: {
                popover: 'The percent of bidders who opened the email who also clicked a link.',
                title: 'Click-Through Rate',
            },
            delivered: {
                popover: 'The count of emails delivered to LiveAuctioneers bidders. Excludes bounced emails.',
                title: 'Delivered',
            },
            followers: {
                popover: 'Count of bidders who followed you after opening your email or clicking your ad.',
                title: 'New Followers',
            },
            openRate: {
                popover: 'Number of unique opens divided by number of emails delivered.',
                title: 'Open Rate',
            },
            pageViews: {
                popover:
                    'Count of pageviews on your items and catalogs from bidders who click on your ad or links in your email.',
                title: 'Pageviews',
            },
            registration: {
                popover:
                    'Count of bidders who opened your email or clicked your ad then requested auction registration.',
                title: 'Registrations',
            },
            salesDriven: {
                popover:
                    'Total hammer price of items sold, excluding any disputed items, by bidders who opened your email or clicked your ad.',
                title: 'Sales Driven',
            },
            salesGrowth: {
                popover:
                    'Total lift in hammer price driven by winning bidders and underbidders opened your email or clicked your ad, regardless if the items sold on LiveAuctioneers or not.',
                title: 'Lift in Hammer',
            },
            totalClicks: {
                popover: 'Number of clicks on links in your ad or email.',
                title: 'Total Clicks',
            },
            totalImpressions: {
                popover: 'Number of views of your ad or email.',
                title: 'Total Impressions',
            },
            uniqueClicks: {
                popover: 'Number of unique bidders who clicked a link in your email.',
                title: 'Unique Clicks',
            },
            uniqueImpressions: {
                popover: 'Number of unique bidders who viewed your ad or email.',
                title: 'Unique Impressions',
            },
            uniqueOpens: {
                popover: 'The number of unique bidders who opened your email.',
                title: 'Unique Opens',
            },
            winners: {
                popover: 'Unique winners',
                title: 'Winning Bidders',
            },
        },
    },
    catalogPerformance: {
        chart: {
            title: 'Pre-Auction Activity',
        },
        donutCharts: {
            bidders: 'Bidders',
            bids: 'Bids',
            missed: '{amount} bids missed',
            missedOne: '1 bid missed',
            total: 'Total',
        },
        insights: {
            learnMore: 'Learn More',
            title: 'Insights',
        },
        pageviewReferrals: {
            pageviews: 'Pageviews',
            social: 'Social',
            title: 'Pageview Referral Sources',
            url: 'URL',
        },
        registrations: {
            approved: 'Approved',
            country: 'Country',
            pending: '{pending} bidders pending',
            pendingOne: '1 bidder pending',
            rightLabel: '{amount} ({percent}%)',
            theRest: '+{count} More',
            title: 'Registrations',
        },
        salesGrowth: {
            exclusive: 'Exclusive Bids',
            exclusiveTip:
                "On lots with bids only from LiveAuctioneers, the lift in hammer driven by competing LiveAuctioneers' bidders.\n\n Assumes lots without floor bidder sell to the floor at starting bid amount.",
            floor: 'Floor Bids',
            floorTip: "Total leading floor bid amount without LiveAuctioneers' bidders.",
            growth: 'Lift in Hammer',
            hammer: 'Sold Online',
            out: 'Outbids',
            outTip: "On lots with floor bids sold to LiveAuctioneers, the lift in hammer driven by LiveAuctioneers' winning bidders.\n\n Assumes lots with floor bidder sell at highest floor bid amount prior to competing LiveAuctioneers bid.",
            title: 'Sales',
            under: 'Underbids',
            underTip:
                "On lots with floor bids that sold to floor bidders, the lift in hammer driven by LiveAuctioneers' underbidders.\n\n Assumes lots with floor bidder sell at highest floor bid amount prior to competing LiveAuctioneers bid.",
            wPremium: "{amount} with Buyer's Premium",
        },
        soldLots: {
            all: 'See All',
            hammer: 'Hammer Price',
            lotTitle: 'Title',
            na: 'N/A',
            salesGrowth: 'Lift in Hammer',
            subtitle: 'You sold {lotsSold} lots. Your best-performing lots:',
            subTitleUpcoming: 'Your best-performing lots so far:',
            title: 'Lots',
        },
    },
    categorizer: {
        allAuctions: 'All Upcoming Auctions',
        category: 'Category',
        clearFilters: 'Clear Filters',
        creator: 'Creator',
        filterByKeyword: 'Filter by Keyword',
        filters: 'Auction',
        lot: '{total} Lot',
        lotManager: 'Lot Manager',
        lots: '{total} Lots',
        materialTechniques: 'Material / Techniques',
        noLotsFound: 'No Lots Found',
        noUpcomingAuctions: 'No upcoming auctions',
        origin: 'Region of Origin',
        select: 'Select',
        selectCreator: 'Start typing to find a creator',
        selectedLots: 'Selected ({count})',
        stylePeriod: 'Style / Period',
        support: 'Support',
        tagItems: 'Tag Items',
    },
    consoleOverlay: {
        countdown: {
            days: 'DAYS',
            hours: 'HOURS',
            minutes: 'MINUTES',
            seconds: 'SECONDS',
        },
        ended: 'Catalog has ended',
        invoicePageLink: 'Go to invoices',
        load: 'Load',
        notLoaded: 'Sale is not yet loaded. Please wait.',
        sendInvoiceReminder:
            'Please make updates and send your invoices quickly. Buyers have been told that they will receive their invoices and receipts shortly after the conclusion of the auction.',
        start: 'Start',
    },
    creditCard: {
        addCreditCard: 'Add Credit Card',
    },
    custom: 'Custom',
    dashboard: {
        auctionCta: 'Set Up New Auction',
        campaign: {
            date: 'Date',
            scheduled: ' (scheduled)',
            title: 'Campaigns & Events',
            type: 'Type',
        },
        empty: {
            description: {
                existing:
                    'Catalog not ready yet? Set up an {link} to promote your upcoming sale, collect consignments, and grow your following as you prepare your catalog.',
                new: 'Catalog not ready yet? Set up an {link} to promote your upcoming sale, collect consignments, and grow your following as you prepare your catalog.',
                preview: 'Auction Preview',
            },
            start: 'Set Up Auction',
            subtitle: {
                existing: 'Ready to set up an auction?',
                new: 'Ready to set up your first auction?',
            },
            title: 'Active Auctions',
        },
        forumPosts: {
            featured: {
                guides: {
                    body: 'Helpful guides for listing and managing your auction.',
                    title: 'Guides: How to Use LiveAuctioneers',
                },
                report: {
                    body: `With 59 million auction registrations and 29% growth in items sold on LiveAuctioneers
                    during the first half of 2019, auction-house partners saw all-time highs in auction
                    results, gaining more value during the first six months of this year than in any prior
                    year.`,
                    title: 'LiveAuctioneers Mid-Year Report',
                },
            },
            more: 'See All',
            title: 'Updates & Tips',
        },
        pageTitle: 'Auctioneer Home',
        ranges: {
            fourteenDays: 'Last 14 Days',
            sevenDays: 'Last 7 Days',
            sinceStart: 'Since Listed',
            thirtyDays: 'Last 30 Days',
        },
        statsBlocks: {
            absentee: {
                popover:
                    'Total number of all absentee bids placed, regardless if they were accepted or not. Excludes retracted bids.',
                title: 'Absentee Bids',
            },
            bidValue: {
                popover: 'Sum of current bid amounts across all lots.',
                title: 'Leading Bid Value',
            },
            pageViews: {
                popover: 'Total number of views on your catalog and item pages.',
                title: 'Pageviews',
            },
            registration: {
                popover: 'Number of occurrences a bidder registered to bid.',
                title: 'Auction Registrations',
            },
            savedItem: {
                popover: 'Number of occurrences an item was saved by a registered LiveAuctioneers user.',
                title: 'Item Saves',
            },
        },
        yearStats: {
            auctionCount: '{number} auctions',
            bidsplaced: 'Bids',
            bidsplacedPopover: 'Total number of absentee bids, regardless if they were accepted or not, and live bids.',
            details: 'More details',
            growthinprices: 'Lift in Hammer',
            growthinpricesPopover:
                'Total lift in hammer price driven by winning bidders and underbidders on LiveAuctioneers, regardless if the items sold on LiveAuctioneers or not.',
            itemsSold: 'Items Sold',
            pageviews: 'Pageviews',
            pageviewsPopover: 'Total number of views on your catalog and item pages.',
            percentagegrowthinprices: 'Lift in Hammer %',
            percentagegrowthinpricesPopover:
                "Percent lift in hammer price driven by LiveAuctioneers' winning bidders and underbidders, regardless if they sold on LiveAuctioneers or not.",
            sellthrough: 'Sell-Through Rate',
            sellthroughPopover: 'Number of items sold divided by number of items listed, excluding any disputed items.',
            soldonla: 'Sold on liveauctioneers',
            soldonlaPopover: 'Total hammer price of items sold, excluding any disputed items.',
            subtitle: "You've listed {count} with LiveAuctioneers.",
            title: 'In the Last 12 Months {details}',
            totalregistrations: 'Auction Registrations',
            totalregistrationsPopover: 'Number of occurrences a bidder registered to bid.',
        },
    },
    featuredLots: {
        address: '10 East 38th Street 4th Floor | New York, NY 10016',
        advertisingGuidelines: 'advertising guidelines',
        allRights: '2002-2021 LiveAuctioneers. All Rights Reserved',
        apiError:
            'An error occurred. Please refresh your page and try again. If the error persists, please reach out to {link}',
        appearOn: 'Appear on the auction calendar, catalog, emails and more.',
        atLeastFiveHundred: 'Auction sellers must have at least 500 followers to purchase a Dedicated Follower Alert.',
        biddersMet: 'Based on your following of {number} bidders',
        biddersNotMet: 'Based on your following of {number} bidders, you do not meet the minimum follower requirement.',
        bidOn: 'Bid on thousands of items added daily with the free LiveAuctioneers mobile app.',
        cancel: 'Cancel',
        choseLots: 'Select how many lots to feature:',
        clickToExpand: 'Click to expand',
        continue: 'Continue',
        continueToPayment: 'Continue To Payment',
        cover: 'Cover',
        coverFeatured: 'Cover + Featured',
        coverLots:
            "Cover Lots are the lots that a bidder will see before clicking into your catalog. Ideally, you should choose the best 3 lots you have to grab the bidder's attention.",
        dedicatedAlert: 'DEDICATED ALERT EXAMPLE',
        dedicatedFollowersEmail:
            'Dedicated Follower Alerts are sent within 15 minutes following auction proofing. If you set up Dedicated Follower Alerts for more than one auction, they will be sent 6 hours apart and in the order you create them. Dedicated Follower Alerts cannot be sent once live bidding has started.',
        dedicatedStep: '2. Dedicated Follower Alert',
        details: 'Details:',
        emailMarketing: 'For more email marketing, contact {email}',
        exploreTheCatalog: 'Explore The Full Catalog',
        featured: 'Featured',
        featuredLotDuration:
            'Featured lots are promoted for seven days leading up to your auction. They appear on related category pages and on search result pages, maximizing your exposure to a high-intent bidder-base. This is a great way to attract the most relevant bidders to your most important lots.',
        featuredLots:
            'Featured lots are displayed on our Search and Category pages. Lots will be dispersed at random to users based on a keyword match.',
        featuredLotStep: '1. Featured Lots',
        finalCount:
            "Your final email delivery count may differ from your follower count based on bidders' opt-out/bounce statuses at time of send.",
        fiveFeaturedLots: '5 Featured Lots',
        fiveFeaturedLotsMessage: 'Appear on search and browse pages which receive over 1 million visits monthly.',
        generateInterest:
            'Generate interest and drive immediate activity for your upcoming auction by sending a dedicated email alert to your followers on LiveAuctioneers. Bidders spend 1.6X more, purchase 65% more items and have a higher likelihood of repeat bidding in sales from auction houses they follow.',
        generateInterestTwo:
            'A dedicated follower alert is a cost-effective way to get your sale in front of your most engaged bidders. Your cover lots, along with six lots of your choosing will be featured in your exclusive email. This is a great marketing option if you want to encourage returning bidders to participate in your auction.',
        getFiveLots: 'Get 5 Featured Lots For $325',
        getTwentyLots: 'Get 20 Featured Lots for $935',
        imagesMayNotShow: '*Images may not show up here until photo processing has completed.',
        increaseReach: 'Increase Your Bidder Reach',
        itemsFeatured: 'Items Featurd In this Auction',
        lotNumber: 'Lot #',
        lotsAdded: '{numberOfLots} Lots Just Added From {house}',
        makeYourLots: 'Get Your Best Lots Seen by Relevant Buyers',
        makeYourLotsText:
            'Give your sale an added boost and promote your best lots to bidders actively searching for similar items on LiveAuctioneers. Featured lots are 30% more likely to receive bids and realize 35% higher hammer prices because of the increased exposure. Additionally, consignors love seeing that you’ve featured their lots.',
        manageYourPreferences: 'Manage your auctioneer email preferences: {link}',
        manageYourPreferencesLink: 'Auctioneers You Follow',
        minimal: 'Minimal',
        minimalPlan: 'MINIMAL PLAN INCLUDES:',
        minimalPrice: '$0.00',
        na: 'N/A',
        note: 'Note: ',
        noteText:
            'Standard and Premium options for Featured Lots are not available for this catalog due to the presence of sensitive content. Read more about our {link}.',
        premium: 'Premium',
        premiumPlan: 'PREMIUM PLAN INCLUDES:',
        premiumPrice: '$935.00',
        price: 'Price:',
        pricePerFollower: 'PRICE PER FOLLOWER | TIER',
        promoteAuction: 'Promote Your Auction to Your Followers',
        salesEmail: 'sales@liveauctioneers.com',
        seeWhatElse: 'See what else {house} has to offer in this upcoming auction!',
        selectCoverLots: 'Select Cover Lots',
        selectFeaturedLots: 'Select Featured Lots',
        selectLots: 'Select Lots to Feature in Your Dedicated Follower Alert',
        skipForNow: 'Skip For Now',
        standard: 'Standard',
        standardPlan: 'STANDARD PLAN INCLUDES:',
        standardPrice: '$325.00',
        supportEmail: 'support@liveauctioneers.com',
        threeCoverLots: '3 Cover Lots',
        threeLotsMinimal: '3 Cover Lots, 0 Featured Lots',
        threeLotsPremium: '3 Cover Lots, 20 Featured Lots',
        threeLotsStandard: '3 Cover Lots, 5 Featured Lots',
        tierPriceMax: '$0.08 | 500 - 4,999 Followers',
        tierPriceMed: '$0.07 | 5,000 - 9,999 Followers',
        tierPriceMin: '$0.06 | 10,000 + Followers',
        twentyLots: '20 Featured Lots',
        worldTreasure: "The world's treasures, at your fingertips.",
    },
    feedback: {
        contact: 'Contact Us',
        email: 'Email Address',
        feedback: 'Feedback',
        footer: 'Send Feedback',
        helpUs: 'Help Us Improve Your Experience',
        helpYou: 'Need immediate help? Visit our {link}.',
        improve: 'How can we improve this page?',
        link: 'Support Center',
        send: 'Send Feedback',
        thanks: 'Thank you. We appreciate your feedback.',
    },
    forgotPassword: {
        back: 'Back to Login',
        cta: 'Send Reset Instructions',
        header: 'Please enter your username below.',
        resend: 'resend the email',
        subheader: "We'll send you a link to reset your password.",
        success:
            'If you haven’t received an email within a few minutes, check your spam folder. You can also {link} once every five minutes. Only the most recent reset email will work.',
        successHeader: 'Check your email.',
        successSubheader: 'We sent you an email with instructions on how to reset your password.',
        successSubheaderEmail: 'We sent an email to {email} with instructions on how to reset your password.',
        username: 'Username',
        usernameError: 'Username is required',
    },
    home: {
        houseId: 'Auction House ID',
    },
    housePayments: {
        balanceDue: 'Balance Due',
        chargesSummary: 'Summary',
        errorLapMandate:
            'Please complete your LivePayments enrollment application in order to post this catalog. If you need assistance, please schedule time with an onboarding specialist.',
        errorLoadingInvoice: 'There was an error loading your invoice.',
        getStartedLink: 'Get Started',
        itemAndCatalog: '{item} - Catalog #{catalogId}',
        listingFee: 'Listing Fee',
        listingFeeCharge: 'Listing Fee - Catalog #{catalogId}',
        listingFeePayment: 'Listing Fee Payment - Catalog #{catalogId}',
        payment: 'Payment',
        paymentError: 'There was an error processing your payment.',
        payNow: 'Pay Now',
        scheduleTimeLink: 'Schedule Time',
        securePayment: 'Secure Payment',
        total: 'Total',
    },
    infoBanner: {
        livePayments: {
            completeYourApplication:
                'Urgent Notice: You must complete your LivePayments enrollment application in order to post a catalog. Contact {link} to get started.',
            email: 'payments@liveauctioneers.com',
        },
    },
    invoice: {
        additionalFee: 'Additional Fee',
        addressUnavilable: 'Unavailable',
        addRow: 'Add',
        atgPay: 'AtgPay',
        auction: 'Auction',
        auctionAddress: 'Auction Address',
        autopayFail: 'Autopay attempt failed',
        balanceDue: 'Estimated Balance Due',
        billingAddress: 'Billing Address',
        buyersPremiumHeader: "Buyer's Premium",
        descritptionHeader: 'Description',
        details: 'Invoice Details',
        discount: 'Discount',
        errorLotTooHigh: 'Refund value cannot be higher than the price of the item. Please adjust the refund value.',
        errorTotalTooHigh:
            'Self-Service refunds must be below $5,000. Please contact support or reduce the total refund value.',
        fulfillmentStatus: 'Fulfillment Status',
        history: 'Invoice Payment History',
        instantAutopay: 'Instant Autopay',
        invoiceId: 'Invoice #{invoiceId}',
        invoiceMarkPickupFail:
            'Invoice shipping method could not be marked as pickup. Please try again or contact support.',
        invoiceMessage: 'Invoice Message:',
        invoiceNotFound: 'Unable to find invoice {invoiceId}, please try again',
        invoiceSent: 'Invoice Sent',
        invoiceTotal: 'Estimated Total',
        itemSubtotal: 'Item Subtotal',
        livePayments: 'LivePayments,',
        lotHeader: 'Lot #',
        lotsTax: 'Estimated Sales Tax',
        markForPickup: 'Mark for Pick-Up',
        markForPickupCancel: 'No',
        markForPickupConfirm: 'Yes',
        markForPickupSubTitle:
            'Updating the Shipping Status to Pick-Up will refund the buyer for the appropriate sales tax and all shipping fees - if any have been received. This cannot be undone.',
        markForPickupTitle: 'Mark for Pick-Up',
        markPaid: 'Marking this invoice as paid will inform the bidder that there are no further pending payments.',
        markPaidModalCancel: 'No',
        markPaidModalConfirm: 'Yes',
        markPaidModalSubTitle: 'Are you sure you want to mark this invoice as fully paid?',
        markPaidModalTitle: 'Mark as Paid',
        messageToBidder: 'Message To Bidder',
        messageToBidderHelp: 'This note will be displayed to the bidder on their invoice',
        na: 'N/A',
        newAlteration: 'New Alteration (20 character limit)',
        newAlterationAmount: 'New Alteration Amount',
        newPayment: 'New Payment Received',
        newPaymentAmount: 'New Payment Amount',
        nextAutopay: 'Next Autopay Attempt:',
        pageTitle: 'Invoice',
        paid: 'Paid',
        paymentMethod: 'Payment Method',
        paymentReceived: 'Payment Received',
        paymentReceivedLA: 'LivePayments Received',
        previewLink: 'Preview Invoice',
        priceHeader: 'Price',
        printInvoice: 'Print Invoice',
        processingFeeTotal: 'Estimated Processing Fee',
        refund: 'Issue Refund',
        refundAmount: 'Refund Amount',
        refundEstimatedSalesTaxAddedLater: 'Calculated at Next Step',
        refundFull: 'Full Refund',
        refundHeader: 'Refund Value',
        refundIssued: 'Refund Issued',
        refundLabel: 'Refund amount for lot {lot}',
        refundModal: {
            buyersPremium: "Buyer's Premium",
            cancel: 'No',
            confirm: 'Yes',
            items: 'Items and Alterations',
            other: 'Other',
            otherPlaceholder: 'Please specify other reason',
            reason: 'Reason',
            refundReason: {
                duplicate: 'Duplicate',
                fraudulent: 'Fraudulent',
                other: 'Other',
                requested: 'Requested by customer',
            },
            salesTax: 'Sales Tax',
            shipping: 'Shipping',
            subTitle: 'Are you sure you want to process this refund?',
            title: 'Issue Refund',
            total: 'Total Refund',
        },
        refundShippingLabel: 'Shipping refund amount',
        remove: 'Remove',
        salesTaxHeader: 'Sales Tax',
        saveRow: 'Save',
        sendInvoice: 'Send Invoice',
        sendInvoiceModalCancel: 'No',
        sendInvoiceModalConfirm: 'Yes',
        sendInvoiceModalSubTitle: 'Are you sure you want to send this invoice to the bidder?',
        sendInvoiceModalTitle: 'Send Invoice',
        shipping: 'Shipping',
        shippingAddress: 'Shipping Address',
        shippingMethod: 'Shipping Method',
        shippingMethods: {
            la_ship: 'LiveShipping',
            notSet: 'Not Set',
            pickup: 'Pick-Up',
            ship_by_bidder: 'Self-Arranged',
            ship_by_house: 'In-House',
        },
        taxExemptStatus: 'Tax Exemption Status',
        taxExemptStatuses: {
            COMPLETE: 'APPROVED',
            'N/A': 'NOT APPLICABLE',
            PENDING: 'PENDING',
            REJECTED: 'REJECTED',
        },
        taxRefund: 'Refund',
        taxRefundModal: {
            cancelText: 'No',
            confirmText: 'Yes',
            title: 'Refund Sales Tax Only',
        },
        toggleMessage: 'Toggle Invoice Message',
        totalBuyersPremium: "Total Buyer's Premium",
        totalTaxes: 'Estimated Taxes',
        username: 'Bidder',
        void: 'Void',
    },
    lapMandateModal: {
        cancelText: 'SKIP FOR NOW',
        confirmText: 'BEGIN ENROLLMENT',
        email: 'payments@liveauctioneers.com',
        link: 'schedule time with an onboarding specialist',
        subTitle: `It appears you have not yet enrolled in LivePayments, the fast, easy, and secure way to automatically collect payments from bidders.\n
        In order to proceed with catalog publication, please email {link} to obtain your application. If you need assistance, please schedule time with an onboarding specialist.`,
        title: 'Action Required: Complete LivePayments Enrollment',
    },
    linkBankAccountModal: {
        accountHolderName: 'Account Holder',
        accountHolderNameRequired: 'Account holder is required',
        accountNickname: 'Account Nickname',
        accountNicknameRequired: 'Account nickname is required',
        accountNumber: 'Account Number',
        accountNumberRequired: 'Account number is required',
        agreeToDeposit:
            'By continuing, you agree to let LiveAuctioneers Payments send a small deposit and retrive one withdrawl',
        checking: 'Checking',
        confirmDeposit: 'Confirm Deposit',
        header: 'Link Bank Account',
        makePrimary: 'Make this my primary payment method',
        routingNumber: 'Routing Number',
        routingNumberRequired: 'Routing number is required',
        savings: 'Savings',
    },
    liveShipping: {
        labels: {
            carriers: {
                badges: {
                    [Carriers.FedEx]: 'FedEx',
                    [Carriers.UPS]: 'UPS',
                    [Carriers.USPS]: 'US Postal Service',
                },
                default: 'Default',
                description:
                    "Your default carrier's rates will determine shipping costs displayed on item pages and invoices. Invoices can be updated to utilize other carriers associated with your account. To update your settings or to learn more, please contact {packagingEmail}",
                title: 'Carriers',
            },
            packaging: {
                description:
                    "Select the desired packaging and handling fee amount to be included in the total shipping cost. This fee will not be itemized in bidders' shipping quotes. You can also adjust the final amount when you create the invoices.",
                options: {
                    calculated: {
                        info: 'LiveAuctioneers will automatically choose and calculate the optimal packing and labor costs based on the size, weight, fragility, and value of the item(s).',
                        label: 'Calculated by LiveAuctioneers {info}',
                    },
                    custom: {
                        byLot: 'Lot',
                        byPackage: 'Package',
                        label: '{amountInput} per {itemizationInput}',
                    },
                    noFee: {
                        label: 'No packaging and handling fee',
                    },
                },
                title: 'Packaging and Handling Fee',
            },
            printer: {
                description: 'Choose the file format you use to print shipping labels',
                labels: {
                    [Printers.Zebra]: 'ZPL - for Zebra printers',
                    [Printers.Pdf]: 'PDF - for regular printers and most label printers',
                },
                title: 'Label File Format',
            },
            save: {
                cancel: 'Cancel',
                saveChanges: 'Save Changes',
            },
            saveModal: {
                error: {
                    close: 'Close',
                    description:
                        "We can't complete your request at the moment. Please try again later or contact us at {packagingEmail}",
                    title: 'System Error',
                },
                success: {
                    close: 'Close',
                    description: 'Your changes have been saved.',
                    title: 'Success!',
                },
            },
            unavailable: {
                label: {
                    description:
                        'Your account is configured to use LiveShip white glove service. To learn about other LiveShip options, contact your account manager or click {here}.',
                    here: 'here',
                },
                liveShipping: {
                    description:
                        'Your account is not configured with LiveShip, the primary shipping option on LiveAuctioneers. To learn more about LiveShip, contact your account manager or click {here}.',
                    here: 'here',
                },
            },
        },
        pageContent: {
            tabs: {
                labels: 'LiveShip Label',
                parser: 'Parsing Tool',
            },
            title: 'LiveShip',
        },
        shippingParser: {
            catalogShippingParserPage: {
                catalogInformationHeader: {
                    auctionHouse: 'Auction House:',
                    catalogId: 'Catalog ID:',
                    changeCatalogButton: 'Change Catalog',
                    export: 'Export',
                },
                catalogItemsTable: {
                    buttons: {
                        moreDescriptionLink: 'More',
                    },
                    fragilityOptions: {
                        fragile: 'Fragile',
                        littleFragile: 'Little Fragile',
                        notFragile: 'Not Fragile',
                        veryFragile: 'Very Fragile',
                    },
                    headings: {
                        depth: 'Depth',
                        fragility: 'Fragility',
                        height: 'Height',
                        lotInfo: 'Lot Info',
                        packageType: 'Package Type',
                        quantity: 'QTY.',
                        visibility: 'Visibility',
                        weight: 'Weight',
                        width: 'Width',
                    },
                    noResults: {
                        noMatchesForFilters: 'No lots match your filter settings',
                        noResults: 'NO RESULTS',
                    },
                    packageTypeOptions: {
                        crated: 'Crated',
                        unspecified: 'Unspecified',
                    },
                    visibilityOptions: {
                        disabled: 'Disabled',
                        inReview: 'In Review',
                        visible: 'Visible',
                    },
                },
                modals: {
                    ApplyItemFiltersModal: {
                        applyButton: 'Apply',
                        clearAllButton: 'Clear All',
                        dimension: {
                            complete: 'Complete (All dimensions are filled)',
                            incomplete: 'Incomplete (Missing 1 or more dimensions)',
                            label: 'Dimension',
                        },
                        fragility: {
                            fragile: 'Fragile',
                            label: 'Fragility',
                            littleFragile: 'Little Fragile',
                            notFragile: 'Not Fragile',
                            somewhatFragile: 'Somewhat Fragile',
                            veryFragile: 'Very Fragile',
                        },
                        packageType: {
                            crate: 'Crate',
                            label: 'Package Type',
                            unspecified: 'Unspecified',
                        },
                        quantity: {
                            label: 'Quantity (minimum quantity)',
                            placeholder: 'Quantity (minimum quantity)',
                        },
                        status: {
                            confirmed: 'Confirmed',
                            label: 'Status',
                            unconfirmed: 'Not Confirmed',
                        },
                        title: 'Filters',
                        visibility: {
                            hidden: 'Hidden',
                            label: 'Quote Visibility',
                            pending: 'In Review',
                            visible: 'Visible',
                        },
                        weight: {
                            label: 'Weight (heavier than)',
                            placeholder: 'Weight (heavier than)',
                        },
                    },
                    ChangeCatalogModal: {
                        changeCatalogButton: 'Yes',
                        subtitle: 'Are you sure you want to leave this catalog? Unsaved progress will be lost.',
                        title: 'Change Catalog',
                    },
                },
                tableActionsBar: {
                    actions: 'Selected lot(s):',
                    confirmButton: 'Confirm',
                    editButton: 'Edit',
                    filterChips: {
                        dimension: {
                            isFilteringByCompleteDimensions: 'Complete Dimensions',
                            isFilteringByIncompleteDimensions: 'Incomplete Dimensions',
                        },
                        fragile: {
                            isFragile: 'Fragile',
                            isLittleFragile: 'Little Fragile',
                            isNotFragile: 'Not Fragile',
                            isVeryFragile: 'Very Fragile',
                        },
                        status: {
                            isFilteringByConfirmed: 'Confirmed',
                            isFilteringByNotConfirmed: 'Not Confirmed',
                        },
                        visibility: {
                            isFilteringByHidden: 'Hidden',
                            isFilteringByPending: 'In Review',
                            isFilteringByVisible: 'Visible',
                        },
                        weight: 'Weight',
                    },
                    filtersButton: 'Filters',
                    searchInputPlaceholder: 'Search keyword',
                },
            },
            confirmMultipleLotsModal: {
                confirm: {
                    body: 'Are you sure you want to confirm {numberOfLots} selected lot(s)?',
                    confirm: 'Yes',
                    title: 'Confirm Selected Lot(s)',
                },
                failure: {
                    close: 'Close',
                    completeAndUtterFailure: "Your selected lots couldn't be confirmed due to incomplete dimensions.",
                    partialFailureBody:
                        "{numberOfFailedLots} of the selected lot(s) couldn't be confirmed due to incomplete dimensions. The remaining {numberOfSucceededLots} {numberOfSucceededLots, plural, one {lot has} other {lots have}} been successfully confirmed.",
                    title: "{numberOfFailedLots, plural, =0 {Failed to Confirm Selected Lot(s)} other {{numberOfFailedLots} Lot(s) Couldn't Be Confirmed}}",
                },
                success: {
                    body: "You've successfully confirmed {numberOfLots} lot(s)",
                    close: 'Close',
                    title: 'Success!',
                },
            },
            editMultipleLotsModal: {
                confirmRemoval: {
                    body: "This will disable shipping quotation for the selected items. Any other changes won't be applied.",
                    title: 'Remove Dimenions?',
                    yes: 'Yes, Remove',
                },
                failure: {
                    close: 'Close',
                    completeAndUtterFailure:
                        "All attribute changes have been saved. However, the selected lot(s) couldn't be confirmed due to incomplete dimensions.",
                    partialFailure:
                        "All attribute changes have been saved. However, {numberOfFailedLots} of the selected lot(s) couldn't be confirmed due to incomplete dimensions. The remaining {numberOfSucceededLots} {numberOfSucceededLots, plural, one {lot has} other {lots have}} been successfully confirmed.",
                    title: 'Failed to Confirm Selected Lot(s)',
                },
                form: {
                    body: "The attributes you edit will apply to all {numberOfLots} selected lot(s). Any attribute you don't edit will remain unchanged.",
                    formHeaders: {
                        depth: 'Depth',
                        fragility: 'Fragility',
                        height: 'Height',
                        packageType: 'Package Type',
                        quantity: 'Quantity',
                        visibility: 'Visibility',
                        weight: 'Weight',
                        width: 'Width',
                    },
                    removeDimensions: 'Remove Dimensions',
                    save: 'Just Save Changes',
                    saveAndConfirm: 'Save & Confirm Lots',
                    title: 'Edit {numberOfLots} Selected Lot(s)',
                },
                success: {
                    close: 'Close',
                    confirmBody:
                        '{numberOfLots} selected {numberOfLots, plural, one {lot has} other {lots have}} been confirmed and all attribute changes have been saved.',
                    saveBody: 'All changes have been saved to selected lot(s).',
                    title: 'Success!',
                },
            },
            parseCatalogFormPage: {
                catalogInputLabel: 'Catalog ID',
                inputErrorFeedback: "Catalog number doesn't exist",
                pageTitle: 'Catalog Shipping Measurements Parsing Tool',
                submitButton: 'Submit',
            },
        },
    },
    login: {
        cta: 'Log In',
        forgotPassword: 'Forgot Password?',
        hide: 'Hide',
        lostUser: "You seem to be lost. If you're seeing this message something is broken. Please contact support.",
        noAccount: "Don't have a LiveAuctioneers account?",
        pword: 'Password',
        pwordError: 'Enter a password.',
        show: 'Show',
        signUp: 'Sign Up',
        title: 'Auction House Login',
        uname: 'Username',
        unameError: 'Enter a username.',
    },
    lotmanager: {
        categories: 'Categories',
        performance: 'Performance',
    },
    marketing: {
        announcements: {
            createModal: {
                announcementBodyLabel: 'Announcement',
                announcementTitleLabel: 'Title',
                formDescription:
                    'Share important updates with your bidders through your item listings. You may also send an email to your registered bidders.',
                formError: 'Please select an auction and try again.',
                learnMoreLink: 'Learn More',
                modalTitle: 'Create an Announcement',
                openModalButton: 'Create Announcement',
                selectAuctionPlaceholder: 'Select Auction',
                sendEmail: 'Send an email to my registered bidders',
                submitButton: 'Create',
            },
            noCatalogPane: {
                auctionPreviewLink: 'Auction Preview',
                description: 'Looking to announce a new auction? Try listing an {link}.',
                header: 'Announcements are available when you have an upcoming auction.',
            },
            table: {
                columns: {
                    allCatalogsTitle: 'All Active Auctions',
                    announcement: 'Announcement',
                    auction: 'Auction',
                    date: 'Sent',
                    removeAnnouncement: 'Remove',
                    type: 'Type',
                },
                headerSubtitle:
                    'Share important updates with your bidders through your item listings. You may also send an email to your registered bidders.',
                headerTitle: 'Announcements',
            },
        },
        campaigns: {
            headerSubtitle: 'Understand how your marketing campaigns performed.',
            headerTitle: 'Campaigns',
        },
        campaignsTableTitles: {
            bids: 'Bids',
            campaign: 'Campaign',
            date: 'Date',
            impressions: 'Impressions',
            registrations: 'Registrations',
            salesDriven: 'Sales Driven',
            salesGrowth: 'Lift in Hammer',
        },
        create: 'Launch Campaign',
        followerAlerts: 'Follower Alerts',
        followerAlertsTableTitles: {
            bids: 'Bids',
            impressions: 'Impressions',
            registrations: 'Registrations',
            salesDriven: 'Sales Driven',
            salesGrowth: 'Lift in Hammer',
            type: 'Type',
        },
        insights: {
            more: 'Learn More',
            recommendations: {
                ads: {
                    description:
                        "Impress consignors and gain new bidders by promoting your auction prominently on LiveAuctioneers' homepage.",
                    title: 'Homepage Feature',
                },
                bannerAds: {
                    description:
                        'Feature your auctions on targeted Category pages and reach the most relevant bidders.',
                    title: 'Targeted Banner Ads',
                },
                consignmentAds: {
                    description:
                        'Source new consignments from collectors actively searching for price results across preferred categories.',
                    title: 'Targeted Consignment Ads',
                },
                email: {
                    cta: 'Learn More',
                    description:
                        "Achieve maximum advertising ROI with LiveAuctioneers' high-impact email marketing solutions.",
                    modalBody:
                        'Auction-house partners can choose from a variety of options suited to their individual needs. \n\nWhether you’re looking to target collectors of specific item categories, engage the most frequent buyers, or simply reach the 75,000 monthly new bidders joining LiveAuctioneers, our email advertising solutions provide a cost-effective way to connect with today’s most valuable collectors and buyers.\n\nAuction houses who use email marketing have seen up to 901% return on investment, with an average ROI of 236%.',
                    subtitle:
                        'Directly engage new, high-value buyers through LiveAuctioneers’ high-impact email marketing solutions.',
                    title: 'Email Marketing',
                },
                featured: {
                    description:
                        'Showcase your lots at the top of search results and engage a targeted, active bidder base.',
                    title: 'Featured Lots',
                },
                pr: {
                    description: 'Grow brand awareness and gain valuable auction exposure on Auction Central News.',
                    title: 'Editorial Promotion',
                },
            },
            title: 'Recommendations',
        },
        marketingPageTitle: 'Marketing',
        modal: {
            selectPlaceholder: 'Select Auction',
            table: {
                pricing: 'Pricing',
                rows: {
                    auctioneerSpotlight: 'Auctioneer Spotlight',
                    cta: 'Learn More',
                    dedicatedFollower: 'Dedicated Follower Alert',
                    emailBlast: 'Dedicated Email Blast',
                    featured: 'Featured Lots in Search Results',
                    featuredCta: 'Set up',
                    homepageFeature: 'Homepage Feature',
                    inAppAd: 'In-App Ad',
                    liveauctioneersPRServices: 'LiveAuctioneers PR Services',
                    newsletterFeature: 'Email Newsletter Feature',
                    perCampaign: '{price}/campaign',
                    perWeek: '{price}/week',
                    startingAt: 'Starting at {price}',
                    targetedBannerAd: 'Targeted Banner Ad',
                },
                type: 'Type',
            },
            title: 'Marketing & Advertising Options',
        },
        overview: 'Overview',
        overviewStats: {
            bidsLabel: 'BIDS',
            bidsMessage: 'bids Popover Message',
            deliveredLabel: 'Delivered',
            deliveredMessage: 'Delivered Popover Message',
            pageViewsLabel: 'PAGEVIEWS',
            pageViewsMessage: 'pageViews Popover Message',
            salesDrivenLabel: 'Sales Driven',
            salesDrivenMessage: 'salesDriven Popover Message',
            salesGrowthLabel: 'Lift in Hammer',
            salesGrowthMessage: 'salesGrowth Popover Message',
            winningBiddersLabel: 'Winning Bidders',
            winningBiddersMessage: 'winningBidders Popover Message',
        },
        pageTitle: 'Email Campaigns & Alerts',
        seeAll: 'See All',
    },
    messaging: {
        adminBidderInfo: 'Bidder Info',
        archive: 'Archive',
        auction: 'Auction',
        bidder: 'Bidder',
        bidderMessageModal: {
            attach_files: 'Attach files',
            clear_files: 'Clear attachments',
            discard: 'Discard',
            disclaimer: {
                closing: 'Please check with your credit card processor and/or your legal counsel.',
                intro: 'Note: If you are requesting credit card details from bidders there are several important issues you need to be aware of:',
                part_1: '1. It may be against the credit card processor terms of use to request credit cards for any of the following: a) Charges that the card member has not specifically approved. b) Overdue amounts or amounts covering returned or stop-payment checks. c) Sales made by third parties or entities conducting business in industries other than yours.',
                part_2: "2. To store credit card information electronically you should follow {link} compliance which amongst other things specifically forbids storing of any of the following: Unencrypted credit card number, CVV or CVV2, PIN numbers. If any of the above are found in a merchant's databases, log files, audit trails, backups etc., it can result in serious consequences, especially if a compromise has taken place. Many merchants believe that they own the customer and have a right to store all the data about that customer in order to help their business. Not only is this incorrect under PCI compliance, but also may be a violation of State and Federal legislation on privacy.",
                pci: 'PCI (Payment Card Industry)',
            },
            label: {
                compose: 'Compose Message',
                email_body: 'Message Body',
                from: 'From',
                sending_to: 'Sending a message to {list}',
                subject: 'Subject',
            },
            send: 'Send',
            sent: 'Message sent successfully!',
        },
        cancel: 'Cancel',
        current: 'Current: ',
        delete: 'Delete',
        edit: 'Edit',
        folders: 'Folders',
        inbox: 'Inbox',
        item: 'Item',
        markRead: 'Mark Read',
        markUnread: 'Mark Unread',
        maxBid: 'Your Max Bid: ',
        move: 'Move to Folder',
        new: 'New',
        new_folder: 'New folder name',
        paging: '{firstNumber}-{secondNumber} of {totalRecords}',
        registration: {
            approved: 'Registration Approved',
            declined: 'Registration Declined',
            limitedApproval: 'Approved with Limit',
            pending: 'Registration Pending',
            suspended: 'Account Suspended',
        },
        sent: 'Sent',
        shortEstimateValues: 'Est. {low}-{high}',
        show_less: 'Show Less',
        show_more: 'Show More',
        soldFor: 'Sold for ',
        title: 'Messages',
        trash: 'Trash',
        unread: 'Unread',
        viewBidderProfile: 'View Profile',
        yes: 'Yes',
    },
    passwords: {
        changePassword: {
            backToBrowsing: 'Back To Browsing',
            currentPassword: 'Current Password',
            incorrectPassword: 'Incorrect password',
            newPassword: 'New Password',
            setNewPassword: 'Set a new password.',
            setPassword: 'SET PASSWORD',
            yourPasswordReset: 'Your password has been successfully changed.',
        },
        passwordCriteriaList: {
            length: '7 or more characters',
            lowerAndUpperCase: 'Upper & lowercase characters',
            numberOrSpecialChar: 'At least one number or special character',
        },
        resetPassword: {
            back: 'Back to Login',
            cta: 'Set New Password',
            password: 'New Password',
            passwordConfirm: 'Confirm New Password',
            passwordConfirmError: 'Confirmation is required',
            passwordError: 'Enter a password.',
            success: "You've successfully reset your password.",
            title: 'Reset Your Password',
        },
    },
    payments: {
        accessCapital: 'Access Capital for Your Business.',
        applyInFive: 'Apply in five minutes and get the funding you need to grow your business.',
        bankAccounts: {
            linkAnotherBankAccount: 'Link Another Bank Account',
            linkBankAccount: 'Link Bank Account',
            linkBankAccountInfo: 'Link a bank account to auto-transfer your balance when bidder payments are received.',
            selectBankAccount: 'Select a bank account to auto-transfer your balance when bidder payments are received.',
            title: 'Bank Accounts',
        },
        creditCards: {
            addCreditCard: 'Add Credit Card',
            defaultCard: 'Preferred Card',
            noCreditCards:
                "You don't have any credit cards on file. Add a credit card to pay for listing fees and invoices.",
            removeCard: 'Remove Card',
            setAsDefaultCard: 'Set As Preferred Card',
            subTitle: 'Add a credit card to pay for listing fees and invoices.',
            title: 'Credit Cards',
        },
        filters: {
            disbursementsSearchPlaceholder: 'Id, or Account Number Suffix',
            endFilter: 'END',
            paymentsSearchPlaceholder: 'Name, Catalog Id, or Invoice Id',
            pending: 'Pending',
            startFilter: 'START',
        },
        getCapital: 'Get Capital',
        invalidRoutingNumber: 'The routing number you entered is invalid. Please double check it and try again',
        onboarding: {
            alreadyComplete:
                'Your application for LivePayments has been completed. Please reach out to {email} if you need to make changes or have any questions.',
            enterProvidedHouseId: 'Confirm House ID',
            enterProvidedHouseIdError:
                "Entered ID does not match what was expected with this link. Check you entered it correctly, and that you're logged in as the correct user.",
            enterProvidedHouseIdHelp: 'You were provided with this along with the link to this page',
            enterProvidedHouseIdPlaceholder: 'House ID',
            enterProvidedHouseIdSubmit: 'Submit',
            enterProvidedHouseIdSupport: "Can't find your id? Reach out to {email} for support.",
            errorLoadingForm:
                'There was an error loading the form. Please refresh and try again, or if the problems persists reach out to {email} for support.',

            yourHouseId: 'Your House ID is',
        },
        pagination: {
            seeMorePayments: 'See More Payments',
        },
        paymentConfirmation: {
            backToBilling: 'Back to Billing',
            confirmation: 'Payment Confirmation',
            thankYou: 'Thank you! We processed your payment.',
        },
        paymentsLandingPage: {
            email: 'Email {link} to obtain your application.',
            learnMore: 'LivePayments: What is it?',
            payments: 'Payments',
            paymentsExplanation:
                'LivePayments is the fast, easy, and secure way to automatically collect payments from bidders. ',
            signUp: 'Sign Up',
            upgradeToLivePayments: 'Upgrade to LivePayments',
            visitClassicDashboard: 'Visit Classic Dashboard',
        },
        paymentsPage: {
            disbursements: 'Disbursements',
            payments: 'Payments',
            reporting: 'Reporting',
            summary: 'Summary',
        },
        recentTable: {
            activityType: {
                chargeBack: 'ChargeBack',
                disbursement: 'Disbursement',
                payment: 'Payment',
                refund: 'Refund',
            },
            amount: 'Amount',
            autoDeposit: 'Auto Deposit',
            balanceTransfer: 'Balance Transfer',
            date: 'Date',
            description: 'Description',
            disbursementDescription: 'To Bank Account ending in ****{bankDigits}',
            invoiceCatalog: 'Invoice #{invoiceId} Catalog #{catalogId}',
            method: 'Method',
            methodType: {
                ach: 'Bank (ACH)',
                card: 'Credit Card',
            },
            noActivityYet: 'No activity found with selected filters',
            provider: {
                payrix: 'Payrix',
                paysafe: 'Paysafe',
            },
            providerName: 'Provider',
            status: {
                canceled: 'Canceled',
                closed: 'ChargeBackClosed',
                completed: 'Completed',
                disbursed: 'Disbursed',
                failed: 'Failed',
                fraudulent: 'Fraudulent',
                lost: 'ChargeBackLost',
                open: 'ChargeBackOpen',
                pending: 'Pending Disbursement',
                pendingComplete: 'Pending',
                refunded: 'Refunded',
                uncaptured: 'Uncaptured',
                won: 'ChargeBackWon',
            },
            statusHeader: 'Status',
        },
        reporting: {
            disbursements: 'Disbursements',
            downloadCsv: 'Download CSV',
            endFilter: 'END:',
            payments: 'Payments',
            searchFilter: 'SEARCH',
            searchPlaceholder: 'Name, Catalog Id, or invoice Id',
            startFilter: 'START:',
            title: 'Reporting',
            winningBidders: 'Winning Bidders',
        },
        settingsPage: {
            bankAccounts: 'Bank Accounts',
            creditCards: 'Credit Cards',
            profile: 'Profile',
        },
        summary: {
            accountBalance: 'Account Balance',
            exclusiveOffers: 'Exclusive Offers',
            linkBankAccount: 'Link Bank Account',
            livePaymentsTooltip: 'Percentage of total estimated transaction value processed through LivePayments.',
            livePaymentUsage: 'LivePayments Usage YTD',
            nextDayActivate: 'Activate',
            nextDayDeactivate: 'Turn Off',
            nextDayDetails: 'Balances of $200 and greater will be disbursed the following business day.',
            nextDayFee: '0.5% fee (max $25)',
            nextDayLearnMore: 'Learn More',
            nextDayPayout: 'Next Day Transfers',
            recentActivity: 'Recent Activity',
            totalDisbursementsMonth: 'Disbursements this month',
            totalDisbursementsYear: 'Disbursements YTD',
            totalInvoicesDue: 'Total Invoices Due',
            totalPaymentsMonth: 'Payments this month',
        },
        toggleSameDayModal: {
            confirm: 'Yes',
            decline: 'No',
            disableSameDay: 'Turn off same day Transfers?',
            enableSameDay: 'Turn on same day Transfers?',
        },
        unauthorized: {
            message: 'Sorry you are not authorized to view payments info for house {houseId}',
        },
    },
    performance: {
        auction: 'Auction:',
        pageTitle: 'Lot Performance',
        table: {
            bids: 'Bids',
            hammer: 'Hammer Price',
            item: 'Title',
            leadingBid: 'Leading Bid',
            lot: 'Lot',
            pageViews: 'Pageviews',
            percentageFromEstimate: '% from Estimate',
            price: 'Price + Premium',
            salesGrowth: 'Lift in Hammer',
            saves: 'Saves',
        },
        tableLabel: 'Lots',
        tableLabelOne: 'Lot',
    },
    performanceCatalogList: {
        pageTitle: 'Performance',
        statsLink: 'Stats by Month and Year',
        table: {
            bids: 'Bids',
            datetime: 'Date',
            liftPopover: 'Lift in Hammer is only available for Live auctions.',
            lotsListed: '{count} Lots',
            name: 'Auction',
            pageViews: 'Pageviews',
            registrations: 'Registrations',
            salesGrowth: 'Lift in Hammer',
            sold: 'Sold Online',
        },
        tableLabel: 'Auctions',
        tableLabelOne: 'Auction',
    },
    ratings: {
        review: {
            guideline:
                'Please refrain from including personal or inappropriate content such as: bidder names, addresses, phone numbers, emails, shipping tracking numbers, or profanity.',
        },
    },
    show: 'show',
    tableStatus: '{start}-{end} of {total}',
    toastMessages: {
        descriptions: {
            autoApprovalAddBiddersFailToast: 'Username or Email not found.',
            autoApprovalAddBiddersSuccessToast: '{nameOrEmail} has been added to your Favorite Bidders List.',
            autoApprovalBlockBiddersAlreadyBlocked: 'Bidder is already blocked',
            autoApprovalBlockBiddersFail: 'Username or Email not found.',
            autoApprovalBlockBiddersSuccess: '{nameOrEmail} has been added to your Blocked Bidders List.',
            autoApprovalCustomRuleToast: 'Criteria saved. Custom Auto-Approval is now active for your Auction House.',
            autoApprovalCustomRuleToastInactive:
                'Criteria saved. Custom Auto-Approval is no longer active for your Auction House.',
            autoApprovalCustomRuleWithLimitToast:
                'Criteria saved. Custom Auto-Approval with Bid Limit is now active for your Auction House.',
            autoApprovalCustomRuleWithLimitToastInactive:
                'Criteria saved. Custom Auto-Approval with Bid Limit is no longer active for your Auction House.',
            autoApprovalManyUnfavorited: "You've unfavorited {count} {bidders}",
            autoApprovalUnblockedBiddersCount: "You've unblocked {count} {bidders}",
            biddersAutomaticRulesTabConfirmApproved: 'Congratulations for joining the Bidder Guarantee Program!',
            biddersAutomaticRulesTabConfirmOptOut: 'Auction seller has been opted-out of the Bidder Guarantee Program.',
            blockedBiddersListOneUnblocked: "You've unblocked 1 bidder",

            categorizerAddLotsError:
                'There was an unexpected error attempting to tag lots. Contact {link} for assistance.',
            categorizerCategoryRemoved: 'Category removed from lot ID {itemId}',
            categorizerLotsTagged: '{lotCount} lots tagged as {category}',
            categorizerLotTagged: '{lotCount} lot tagged as {category}',
            categorizerRemoveLotError:
                'There was an unexpected error attempting to remove the tag, please contact {link} for assistance.',
            favoritedBiddersCount:
                "You've favorited {count} {bidders}. They'll always get approved to bid unless they have an open dispute.",
            invoiceMarkPaidFail: 'Invoice could not be marked as paid. Please try again or contact support.',
            invoiceMarkPaidSuccess: 'Invoice marked as paid.',
            invoiceMarkPickupSuccess: 'Invoice shipping method marked as pickup.',
            invoiceSendFail: 'Invoice Send Failed',
            invoiceSendSuccess: 'Invoice Sent Successfully',
            invoiceShippingUpdated: 'Shipping Updated',
            manualApprovalManyApproved: "You've approved {count} {bidders}.",
            manualApprovalManyDeclined: "You've declined {count} {bidders}.",
            manualApprovalManyRegistrationsApproved: "You've approved {count} {registrations}",
            manualApprovalManyRegistrationsDeclined: "You've declined {count} {registrations}",
            manualApprovalSavedNote: "You've saved a note to this bidder's profile.",
            messengerConversationMovedToFolder: 'Conversation moved to {folderName}',
            profileActionButtonsOneBlocked: "You've blocked 1 bidder",
            profileActionButtonsOneFavorited:
                "You've favorited 1 bidder. They'll always get approved to bid unless they have an open dispute",
            profileActionButtonsOneUnfavorited: "You've unfavorited 1 bidder",
            streamLinkCopiedToClipboard: 'Stream Link Copied to Clipboard!',
        },
        titles: {
            error: 'Error',
            info: 'Info',
            success: 'Success',
        },
    },
    unauthorized: 'Sorry, you are not authorized to view this page.',
    upcomingCatalogsPage: {
        cancel: 'Cancel',
        changeTime: 'Change Time',
        registeredLabel: '{registeredCount} Registered',
        save: 'Save',
        sorryMessage: 'You have no upcoming clerked auctions. {link} to get started.',
        sorryMessageLink: 'Set up an auction',
        startedTime: 'Started at {startHour}',
        startTime: 'Starts at {startHour}',
    },
    walkthroughs: {
        automationSettingsForCopyBidders: {
            controls: {
                gotIt: 'Got It',
            },
            popover: {
                automationContent: `Fast-track your best bidders. Copy previously {br}
                approved bidders to your Favorite Bidders...`,
                automationTitle: 'Suggested Automation',
                goToAutomationSettingsLink: 'Go to Automation Settings',
            },
            steps: {
                copyBidders: {
                    content: `Fast-track your best bidders. Copy {br}
                    previously approved bidders to your {br}
                    Favorite Bidders List to automatically {br}
                    approve them in your auctions.`,
                },
            },
        },
        automationSettingsForFavoriteBidderRecommendations: {
            controls: {
                gotIt: 'Got It',
            },
            popover: {
                automationContent: `Favorite Bidder Recommendations help grow {br}
                your Favorite Bidders List by adding register...`,
                automationTitle: 'Suggested Automation',
                goToAutomationSettingsLink: 'Go to Automation Settings',
            },
            steps: {
                bidderRecommendations: {
                    content: `Favorite Bidder Recommendations help {br}
                    grow your Favorite Bidders List by {br}
                    adding registered bidders you have had {br}
                    positive experiences with in the past.`,
                },
            },
        },
        bidderApprovalOnboarding: {
            controls: {
                finish: 'Finish',
                gotIt: 'Got It',
                next: 'Next',
                start: 'Start',
            },
            popover: {
                showTipsLink: 'Show Tips',
                trigger: 'Bidder Approval Tips',
                welcomeContent:
                    "We've given the Bidder Approval page a new {br} look and added some updates we think you'l...",
                welcomeTitle: 'Welcome Tour',
            },
            steps: {
                automationSettings: {
                    content: `Save time with Automation Settings {br}
                    that are customized based on your {br}
                    approval preferences.`,
                },
                bidderDetails: {
                    content: `Bidder details can be viewed in the {br}
                    Bidder Profile pane.`,
                },
                disputeHistory: {
                    content: `Any open disputes over the bidder's {br}
                    lifetime, plus any disputes that were {br}
                    closed in the last 12 months, will be {br}
                    shown here.`,
                },
                messagingHistory: {
                    content: `Easily view your most recent message {br}
                    history with returning bidders.`,
                },
                skipTour: {
                    content: `When you want to revisit this tour, {br}
                    simply go to “Bidder Approval Tips.”`,
                    title: 'No problem',
                },
                welcome: {
                    content: `We've given the Bidder Approval page {br}a new look and added some updates {br}
                    we think you'll like. Start the tour to {br}
                    learn more.`,
                    title: 'Welcome Back',
                },
            },
        },
    },
    ...caterwaulIntlEn,
};
