import * as remoteApi from './remote';

export default {
    fetchBNAReport: remoteApi.fetchBNAReport,
    fetchSellerCatalogs: remoteApi.fetchSellerCatalogs,
    getAuctionPreview: remoteApi.getAuctionPreview,
    getCatalogItems: remoteApi.getCatalogItems,
    getCatalogs: remoteApi.getSmallCatalogs,
    getCoverLots: remoteApi.getCoverLots,
    getPreviousSelectedPromotionPlan: remoteApi.getPreviousSelectedPromotionPlan,
    getRatingsAndReviews: remoteApi.getRatingsAndReviews,
    getRatingsPercentiles: remoteApi.getRatingsPercentiles,
    getSmallSellerRecent: remoteApi.getSmallSellerRecent,
    getSpaUser: remoteApi.getSpaUser,
    postCreateSelectedPromotion: remoteApi.postCreateSelectedPromotion,
    postSetPublicReviewsPreference: remoteApi.postSetPublicReviewsPreference,
    postSubmitAuctionPreview: remoteApi.postSubmitAuctionPreview,
    postSubmitCoverLots: remoteApi.postSubmitCoverLots,
    postUpdateAuctionPreview: remoteApi.postUpdateAuctionPreview,
    postUpdateImage: remoteApi.postUpdateImage,
    postUpdateSelectedPromotion: remoteApi.postUpdateSelectedPromotion,
};

export * from './accountingContact';
export * from './admin';
export * from './changePassword';
export * from './creditCard';
export * from './houseBetaEnrollments';
export * from './houseBilling';
export * from './housePayment';
export * from './invoice';
export * from './payment';
export * from './report';
