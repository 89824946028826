import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS } from './actions';
import api from '../api/changePassword';

export const DEFAULT_STATE = {
    error: false,
    errorMessage: '',
    submitted: false,
    success: false,
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [UPDATE_PASSWORD_FAILURE]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: true,
            errorMessage: action.payload,
            submitted: false,
            success: false,
        }),
        [UPDATE_PASSWORD_REQUEST]: (state: State) => ({
            ...state,
            error: false,
            errorMessage: '',
            submitted: true,
            success: false,
        }),
        [UPDATE_PASSWORD_SUCCESS]: (state: State) => ({
            ...state,
            error: false,
            errorMessage: '',
            submitted: false,
            success: true,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state) => state.changePassword;

export const getUiChangePassword = createSelector(stateSelector, (state) => state);

export const changeUserPassword =
    (newPassword: string, oldPassword: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: UPDATE_PASSWORD_REQUEST });

            const response = await api.postChangePassword({
                authToken,
                deployment,
                newPassword,
                oldPassword,
            });

            dispatch({
                payload: response.payload,
                type: UPDATE_PASSWORD_SUCCESS,
            });
        } catch (error) {
            dispatch({ error: true, payload: error, type: UPDATE_PASSWORD_FAILURE });
        }
    };
