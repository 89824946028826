export const LOAD_FAVORITE_BIDDER_FAIL = 'la/domain/favoriteBidder/LOAD_FAIL';
export const LOAD_FAVORITE_BIDDER_REQUEST = 'la/domain/favoriteBidder/LOAD_REQUEST';
export const LOAD_FAVORITE_BIDDER_SUCCESS = 'la/domain/favoriteBidder/LOAD_SUCCESS';

export const LOAD_FAVORITE_BIDDER_NAMEOREMAIL_FAIL = 'LOAD_FAVORITE_BIDDER_NAMEOREMAIL_FAIL';
export const LOAD_FAVORITE_BIDDER_NAMEOREMAIL_REQUEST = 'LOAD_FAVORITE_BIDDER_NAMEOREMAIL_REQUEST';
export const LOAD_FAVORITE_BIDDER_NAMEOREMAIL_SUCCESS = 'LOAD_FAVORITE_BIDDER_NAMEOREMAIL_SUCCESS';

export const LOAD_NEUTRALIZE_BIDDER_FAIL = 'la/domain/neutralizeBidder/LOAD_FAIL';
export const LOAD_NEUTRALIZE_BIDDER_REQUEST = 'la/domain/neutralizeBidder/LOAD_REQUEST';
export const LOAD_NEUTRALIZE_BIDDER_SUCCESS = 'la/domain/neutralizeBidder/LOAD_SUCCESS';

export const LOAD_FAVORITE_BIDDERS_FAIL = 'LOAD_FAVORITE_BIDDERS_FAIL';
export const LOAD_FAVORITE_BIDDERS_REQUEST = 'LOAD_FAVORITE_BIDDERS_REQUEST';
export const LOAD_FAVORITE_BIDDERS_SUCCESS = 'LOAD_FAVORITE_BIDDERS_SUCCESS';

export const LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_FAIL = 'LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_FAIL';
export const LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_REQUEST = 'LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_REQUEST';
export const LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_SUCCESS = 'LOAD_ADDITONAL_FAVORITE_BIDDERS_COUNT_SUCCESS';

export const LOAD_NEUTRALIZE_MASS_BIDDER_FAIL = 'la/domain/neutralizeMassBidder/LOAD_FAIL';
export const LOAD_NEUTRALIZE_MASS_BIDDER_REQUEST = 'la/domain/neutralizeMassBidder/LOAD_REQUEST';
export const LOAD_NEUTRALIZE_MASS_BIDDER_SUCCESS = 'la/domain/neutralizeMassBidder/LOAD_SUCCESS';

export const LOAD_FAVORITE_MASS_BIDDER_FAIL = 'la/domain/favoriteBidder/LOAD_FAIL';
export const LOAD_FAVORITE_MASS_BIDDER_REQUEST = 'la/domain/favoriteBidder/LOAD_REQUEST';
export const LOAD_FAVORITE_MASS_BIDDER_SUCCESS = 'la/domain/favoriteBidder/LOAD_SUCCESS';
