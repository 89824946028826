import { TaxonomyPayload } from '@/types/Taxonomy';
import { TypedActionWithPayload } from '@/types/redux';

export const LOAD_TAXONOMIES_FAIL = 'la/domain/categorizer/LOAD_TAXONOMIES_FAIL';
export type LOAD_TAXONOMIES_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_TAXONOMIES_FAIL,
    {
        error: boolean;
    }
>;
export const LOAD_TAXONOMIES_REQUEST = 'la/domain/categorizer/LOAD_TAXONOMIES_REQUEST';
export type LOAD_TAXONOMIES_REQUEST_ACTION = TypedActionWithPayload<typeof LOAD_TAXONOMIES_REQUEST>;
export const LOAD_TAXONOMIES_SUCCESS = 'la/domain/categorizer/LOAD_TAXONOMIES_SUCCESS';
export type LOAD_TAXONOMIES_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_TAXONOMIES_SUCCESS,
    TaxonomyPayload,
    {
        categoryId: number;
    }
>;
