import { ActionWithPayload } from '@/types/redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { defaultItem, Item } from '@/types/Item';
import { getAuthToken } from '../modules/user';
import { getCatalog } from './catalog';
import { getDeployment } from '../modules/config';
import { getLoadTimeForCatalogItems, isCatalogItemsLoading } from './loaded';
import { GlobalState } from '../rootReducer';
import { LOAD_CATALOG_ITEMS_SUCCESS, LOAD_ITEMS_FAIL, LOAD_ITEMS_REQUEST, LOAD_ITEMS_SUCCESS } from './actions';
import api from '../api/item';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type State = {
    byId: { [id: number]: Item };
    loaded: { [id: number]: number };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_ITEMS_FAIL]: (state: State, action: ActionWithPayload<{}, { itemIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.itemIds),
        }),
        [LOAD_ITEMS_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [combineActions(LOAD_CATALOG_ITEMS_SUCCESS, LOAD_ITEMS_SUCCESS)]: (
            state: State,
            action: ActionWithPayload<{ items: Item[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.items) {
                action.payload.items.forEach((item) => {
                    existing[item.itemId] = { ...item };
                    loaded[item.itemId] = time;
                    loading = difference(loading, [item.itemId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.item;
const idSelector = (state: GlobalState, id: number) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getItem = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || defaultItem);

export const getLoadTimeForItem = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const isItemLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const getItemIdsForCatalog = createSelector([byIdSelector, idSelector], (byId, id) =>
    Object.keys(byId)
        .map((key) => byId[key])
        .filter((item) => item.catalogId === id)
        .map((item) => item.itemId)
);

export const getItemIdsForSeller = createSelector([byIdSelector, idSelector], (byId, id) =>
    Object.keys(byId)
        .map((key) => byId[key])
        .filter((item) => item.sellerId === id)
        .map((item) => item.itemId)
);

const shouldFetchItem = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getItem(state, itemId);
    if (item) {
        const loaded = getLoadTimeForItem(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isItemLoading(state, itemId);
    return !loading;
};

const whichItemsNeeded = (state, itemIds) => itemIds.filter((itemId) => shouldFetchItem(state, itemId));

export const shouldFetchItemsForCatalog = (state: any, catalogId: number) => {
    if (!catalogId) {
        return false;
    }
    const loaded = getLoadTimeForCatalogItems(state, catalogId);
    const time = Date.now();
    const diff = time - loaded;

    const { lotsListed } = getCatalog(state, catalogId);
    const totalItemsCount = getItemIdsForCatalog(state, catalogId).length;
    if (totalItemsCount === lotsListed) {
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
        const loading = isCatalogItemsLoading(state, catalogId);
        return !loading;
    }

    return true;
};

/* ACTION CREATORS */
const loadItems = (itemIds: number[]) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: itemIds,
            type: LOAD_ITEMS_REQUEST,
        });
        const response = await api.fetchItemsByIds({ authToken, deployment, itemIds });
        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.data,
            type: LOAD_ITEMS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_ITEMS_FAIL,
        });
    }
};

export const fetchItemsIfNeeded = (itemIds: number[]) => async (dispatch: Function, getState: Function) => {
    const needed = whichItemsNeeded(getState(), itemIds);
    if (needed.length) {
        return dispatch(loadItems(needed));
    }
    return Promise.resolve();
};
