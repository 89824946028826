import { handleResponse, makeGet } from './helpers';

type BidderRegistrationsParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
};

type BidderRegistrationsResponsePayload = {
    error: Boolean;
    payload: {
        approved: number;
        blocked: number;
        declined: number;
        pending: number;
        registered: number;
    };
};

export default {
    getBidderRegistrations: ({ authToken, catalogId, deployment, houseId }: BidderRegistrationsParams) =>
        new Promise<BidderRegistrationsResponsePayload>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/registration-counts`,
            });
            request.query({ catalogId });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
