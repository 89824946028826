import {
    CATALOG_DATA_FAILURE,
    CATALOG_DATA_REQUEST,
    CATALOG_DATA_SUCCESS,
    CATALOG_DATA_SUCCESS_ACTION,
    FETCH_COVERLOTS_FAIL,
    FETCH_COVERLOTS_REQUEST,
    FETCH_COVERLOTS_SUCCESS,
    FETCH_COVERLOTS_SUCCESS_ACTION,
    SUBMIT_COVERLOTS_FAIL,
    SUBMIT_COVERLOTS_REQUEST,
    SUBMIT_COVERLOTS_SUCCESS,
} from './actions';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { Item } from '@/types/CatalogData';
import api from '../api';

/* reducer */

export type State = {
    catalogId: number;
    isLoading: boolean;
    isPreviousCoverLotsReady: boolean;
    items: Item[];
    previousCoverLots: number[];
    sentCoverLotsSuccess: boolean;
};
export const DEFAULT_STATE: State = {
    catalogId: 0,
    isLoading: false,
    isPreviousCoverLotsReady: false,
    items: [
        {
            buyNowEligible: false,
            catalogId: 0,
            descriptionBlurb: '',
            highBidEstimate: 0,
            imageVersion: 0,
            index: 0,
            isSEOExcluded: false,
            itemId: 0,
            lotNumber: '',
            lowbidEstimate: 0,
            photos: [],
            publishDate: '',
            sellerId: 0,
            startPrice: 0,
            title: '',
        },
    ],
    previousCoverLots: [],
    sentCoverLotsSuccess: false,
};

export const reducer = handleActions(
    {
        [CATALOG_DATA_FAILURE]: (state: State): State => ({
            ...state,
            catalogId: 0,
            isLoading: false,
            items: [],
        }),
        [CATALOG_DATA_REQUEST]: (state: State): State => ({
            ...state,
            catalogId: 0,
            isLoading: true,
            items: [],
        }),
        [CATALOG_DATA_SUCCESS]: (state: State, action: CATALOG_DATA_SUCCESS_ACTION): State => ({
            ...state,
            catalogId: action.payload.items[0].catalogId,
            isLoading: false,
            items: action.payload.items,
        }),
        [FETCH_COVERLOTS_SUCCESS]: (state: State, action: FETCH_COVERLOTS_SUCCESS_ACTION): State => ({
            ...state,
            isPreviousCoverLotsReady: true,
            previousCoverLots: action.payload,
        }),
        [combineActions(SUBMIT_COVERLOTS_FAIL, SUBMIT_COVERLOTS_REQUEST)]: (state: State): State => ({
            ...state,
            sentCoverLotsSuccess: false,
        }),
        [SUBMIT_COVERLOTS_SUCCESS]: (state: State): State => ({
            ...state,
            sentCoverLotsSuccess: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.catalogData;

export const getLots = createSelector(stateSelector, (state) => {
    return state.items || [];
});
export const isLoading = createSelector(stateSelector, (state) => state.isLoading);
export const getPreviousCoverLots = createSelector(stateSelector, (state) => state.previousCoverLots);
export const isSentCoverLotsSuccess = createSelector(stateSelector, (state) => state.sentCoverLotsSuccess);
export const isPreviousCoverLotsReady = createSelector(stateSelector, (state) => state.isPreviousCoverLotsReady);

/* ACTION CREATORS */
export const fetchCatalogItems = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            type: CATALOG_DATA_REQUEST,
        });

        const response = await api.getCatalogItems({
            catalogId,
            deployment,
        });
        dispatch({
            payload: {
                ...response.data,
            },
            type: CATALOG_DATA_SUCCESS,
        } as CATALOG_DATA_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: CATALOG_DATA_FAILURE,
        });
    }
};

export const fetchCoverLots = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: FETCH_COVERLOTS_REQUEST,
        });

        const response = await api.getCoverLots({
            authToken,
            catalogId,
            deployment,
        });
        dispatch({
            payload: response,
            type: FETCH_COVERLOTS_SUCCESS,
        } as FETCH_COVERLOTS_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: FETCH_COVERLOTS_FAIL,
        });
    }
};

export const submitCoverLots =
    (catalogId: number, coverLots: any) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SUBMIT_COVERLOTS_REQUEST,
            });

            await api.postSubmitCoverLots({
                authToken,
                catalogId,
                coverLots,
                deployment,
            });

            dispatch({
                type: SUBMIT_COVERLOTS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error.message,
                type: SUBMIT_COVERLOTS_FAIL,
            });
        }
    };
