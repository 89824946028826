import {
    AddAttachmentResponsePayload,
    FetchConversationMessagesResponsePayload,
    SendConversationMessageResponsePayload,
} from '../../api/conversationMessages';
import { TypedActionWithPayload } from '../../../types/redux';

export const LOAD_CONVERSATIONS_FAIL = 'LOAD_CONVERSATIONS_FAIL';
export const LOAD_CONVERSATIONS_REQUEST = 'LOAD_CONVERSATIONS_REQUEST';
export const LOAD_CONVERSATIONS_SUCCESS = 'LOAD_CONVERSATIONS_SUCCESS';

export const LOAD_CONVERSATION_FAIL = 'LOAD_CONVERSATION_FAIL';
export const LOAD_CONVERSATION_REQUEST = 'LOAD_CONVERSATION_REQUEST';
export const LOAD_CONVERSATION_SUCCESS = 'LOAD_CONVERSATION_SUCCESS';

export const CONVERSATIONS_ACTION_FAIL = 'CONVERSATIONS_ACTION_FAIL';
export const CONVERSATIONS_ACTION_REQUEST = 'CONVERSATIONS_ACTION_REQUEST';
export const CONVERSATIONS_ACTION_SUCCESS = 'CONVERSATIONS_ACTION_SUCCESS';

export const ARCHIVE_CONVERSATION_FOLDER_FAIL = 'ARCHIVE_CONVERSATION_FOLDER_FAIL';
export const ARCHIVE_CONVERSATION_FOLDER_REQUEST = 'ARCHIVE_CONVERSATION_FOLDER_REQUEST';
export const ARCHIVE_CONVERSATION_FOLDER_SUCCESS = 'ARCHIVE_CONVERSATION_FOLDER_SUCCESS';

export const LOAD_CONVERSATION_FOLDERS_FAIL = 'LOAD_CONVERSATION_FOLDERS_FAIL';
export const LOAD_CONVERSATION_FOLDERS_REQUEST = 'LOAD_CONVERSATION_FOLDERS_REQUEST';
export const LOAD_CONVERSATION_FOLDERS_SUCCESS = 'LOAD_CONVERSATION_FOLDERS_SUCCESS';

export const CREATE_CONVERSATION_FOLDER_FAIL = 'CREATE_CONVERSATION_FOLDER_FAIL';
export const CREATE_CONVERSATION_FOLDER_REQUEST = 'CREATE_CONVERSATION_FOLDER_REQUEST';
export const CREATE_CONVERSATION_FOLDER_SUCCESS = 'CREATE_CONVERSATION_FOLDER_SUCCESS';

export const RENAME_CONVERSATION_FOLDER_FAIL = 'RENAME_CONVERSATION_FOLDER_FAIL';
export const RENAME_CONVERSATION_FOLDER_REQUEST = 'RENAME_CONVERSATION_FOLDER_REQUEST';
export const RENAME_CONVERSATION_FOLDER_SUCCESS = 'RENAME_CONVERSATION_FOLDER_SUCCESS';

export const LOAD_CONVERSATION_MESSAGES_FAIL = 'LOAD_CONVERSATION_MESSAGES_FAIL';
export type LOAD_CONVERSATION_MESSAGES_FAIL_ACTION = TypedActionWithPayload<
    typeof LOAD_CONVERSATION_MESSAGES_FAIL,
    any,
    { conversationId: number }
>;
export const LOAD_CONVERSATION_MESSAGES_REQUEST = 'LOAD_CONVERSATION_MESSAGES_REQUEST';
export type LOAD_CONVERSATION_MESSAGES_REQUEST_ACTION = TypedActionWithPayload<
    typeof LOAD_CONVERSATION_MESSAGES_REQUEST,
    number
>;
export const LOAD_CONVERSATION_MESSAGES_SUCCESS = 'LOAD_CONVERSATION_MESSAGES_SUCCESS';
export type LOAD_CONVERSATION_MESSAGES_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_CONVERSATION_MESSAGES_SUCCESS,
    FetchConversationMessagesResponsePayload['payload'],
    { actionTime: number; conversationId: number }
>;

export const SEND_CONVERSATION_MESSAGE_FAIL = 'SEND_CONVERSATION_MESSAGE_FAIL';
export type SEND_CONVERSATION_MESSAGE_FAIL_ACTION = TypedActionWithPayload<
    typeof SEND_CONVERSATION_MESSAGE_FAIL,
    any,
    { conversationId: number }
>;
export const SEND_CONVERSATION_MESSAGE_REQUEST = 'SEND_CONVERSATION_MESSAGE_REQUEST';
export type SEND_CONVERSATION_MESSAGE_REQUEST_ACTION = TypedActionWithPayload<
    typeof SEND_CONVERSATION_MESSAGE_REQUEST,
    number
>;
export const SEND_CONVERSATION_MESSAGE_SUCCESS = 'SEND_CONVERSATION_MESSAGE_SUCCESS';
export type SEND_CONVERSATION_MESSAGE_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SEND_CONVERSATION_MESSAGE_SUCCESS,
    SendConversationMessageResponsePayload['payload'],
    { actionTime: number; conversationId: number }
>;

export const CONVERSATIONS_CREATE_DRAFT_REQUEST = 'CONVERSATIONS_CREATE_DRAFT_REQUEST';
export const CONVERSATIONS_CREATE_DRAFT_SUCCESS = 'CONVERSATIONS_CREATE_DRAFT_SUCCESS';
export type CONVERSATIONS_CREATE_DRAFT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof CONVERSATIONS_CREATE_DRAFT_SUCCESS,
    AddAttachmentResponsePayload['payload']
>;
export const CONVERSATIONS_CREATE_DRAFT_FAIL = 'CONVERSATIONS_CREATE_DRAFT_FAIL';

export const CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST = 'CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST';
export type CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST_ACTION = TypedActionWithPayload<
    typeof CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_REQUEST,
    any,
    {
        attachmentName: string;
        conversationId: number;
    }
>;
export const CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS = 'CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS';
export type CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_SUCCESS,
    any,
    {
        actionTime: number;
        attachmentName: string;
        conversationId: number;
    }
>;
export const CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_FAIL = 'CONVERSATIONS_DELETE_DRAFT_ATTACHMENT_FAIL';

export const CLEAR_DRAFT = 'CLEAR_DRAFT';
export type CLEAR_DRAFT_ACTION = TypedActionWithPayload<typeof CLEAR_DRAFT, { conversationId: number }>;

export const SEND_BIDDER_MESSAGE_REQUEST = 'SEND_BIDDER_MESSAGE_REQUEST';
export const SEND_BIDDER_MESSAGE_SUCCESS = 'SEND_BIDDER_MESSAGE_SUCCESS';
export const SEND_BIDDER_MESSAGE_FAIL = 'SEND_BIDDER_MESSAGE_FAIL';
