import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { createTracker } from 'redux-segment';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import { defaultWalkthroughsSlice, WalkthroughsState } from '@/redux/modules/walkthroughs/walkthroughs.types';
import { getInitialState } from './initialState';
// import { tracker } from '@/components/OpenReplay/tracker';
import analyticActionMapper from './middleware/analyticsActionMapper';
import cloneDeep from 'lodash/cloneDeep';
import createRootReducer from './rootReducer';
import merge from 'lodash/merge';
import storage from 'redux-persist/lib/storage';
// import trackerRedux from '@openreplay/tracker-redux';

// only store the timestamps
const ForgotPasswordTransform = createTransform(
    (inboundState: any) => {
        return {
            ...inboundState,
        };
    }, // transform state being rehydrated
    (outboundState) => {
        return {
            ...outboundState,
            error: null,
            submitted: false,
            success: false,
            timestamps: { ...outboundState.timestamps },
        };
    }, // define which reducers this transform gets called for.
    { whitelist: ['forgotPassword'] }
);

// handle deep merge of walkthroughs
const WalkthroughsTransform = createTransform(
    (inboundState: WalkthroughsState): WalkthroughsState => {
        return inboundState;
    },
    (outboundState: WalkthroughsState): WalkthroughsState =>
        merge(cloneDeep(defaultWalkthroughsSlice), cloneDeep(outboundState)),
    { whitelist: ['walkthroughs'] }
);

const localStoragePersistConfig = {
    key: 'partners',
    storage,
    transforms: [ForgotPasswordTransform, WalkthroughsTransform],
    whitelist: ['forgotPassword', 'missive', 'walkthroughs'],
};

const initialState = getInitialState();
const enhancers = [];
const middleware = [];

if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger({ collapsed: true }));
}

if (process.env.NODE_ENV !== 'development' && process.env.CI !== 'true') {
    middleware.push(createTracker(analyticActionMapper));
    // middleware.push(tracker.use(trackerRedux()));
}

/**
 * Add support for redux-devtools in development mode
 * @see https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
 */
// @ts-expect-error __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ is populated elsewhere, but not a native TS field
if (process.env.NODE_ENV === 'development' && Boolean(window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)) {
    // @ts-expect-error __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ is defined by us, but not a native TS field
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
}

const persistedReducer = persistReducer(localStoragePersistConfig, createRootReducer());

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleWare) =>
        // Add the guaranteed history, then add the middleware that's dynamically generated.
        // It's currently all disabled because our redux is incredibly broken as of now.
        // A prepend then concat is placed like this to maintain the order of previous middleware.
        // TODO: tl;dr we want to remove these two flags, as it will add performance gains
        getDefaultMiddleWare({ immutableCheck: false, serializableCheck: false }).concat(middleware),
    preloadedState: initialState,
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
