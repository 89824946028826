import { TypedActionWithPayload } from '../../../types/redux';

export const SPEED_CHECK_FAIL = 'SPEED_CHECK_FAIL';
export type SPEED_CHECK_FAIL_ACTION = TypedActionWithPayload<typeof SPEED_CHECK_FAIL, {}>;
export const SPEED_CHECK_REQUEST = 'SPEED_CHECK_REQUEST';
export type SPEED_CHECK_REQUEST_ACTION = TypedActionWithPayload<typeof SPEED_CHECK_REQUEST, {}>;
export const SPEED_CHECK_SUCCESS = 'SPEED_CHECK_SUCCESS';
export type SPEED_CHECK_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SPEED_CHECK_SUCCESS,
    {
        average: number;
        max: number;
        min: number;
        speedCheckSuccessful: boolean;
    }
>;

export const SPEED_CHECK_ITERATION_FAIL = 'SPEED_CHECK_ITERATION_FAIL';
export type SPEED_CHECK_ITERATION_FAIL_ACTION = TypedActionWithPayload<
    typeof SPEED_CHECK_ITERATION_FAIL,
    string,
    {
        iteration: number;
    }
>;
export const SPEED_CHECK_ITERATION_REQUEST = 'SPEED_CHECK_ITERATION_REQUEST';
export type SPEED_CHECK_ITERATION_REQUEST_ACTION = TypedActionWithPayload<
    typeof SPEED_CHECK_ITERATION_REQUEST,
    {
        iteration: number;
    }
>;
export const SPEED_CHECK_ITERATION_SUCCESS = 'SPEED_CHECK_ITERATION_SUCCESS';
export type SPEED_CHECK_ITERATION_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SPEED_CHECK_ITERATION_SUCCESS,
    {
        duration: number;
        iteration: number;
    }
>;
