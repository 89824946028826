import { handleResponse, makeGet } from './helpers';

type GetHouseBalanceDueParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type GetHouseBillingHistoryParams = {
    authToken: string;
    days: number | string;
    deployment: string;
    houseId: number;
    type: string;
};

export default {
    getHouseBalanceDue: ({ authToken, deployment, houseId }: GetHouseBalanceDueParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/billing/balance`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    getHouseBillingHistory: ({ authToken, days, deployment, houseId, type }: GetHouseBillingHistoryParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/billing/history`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.query({ days, type });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
