import type { PubSubProvider } from '@/types/PubSubProvider';
import type { TypedActionWithPayload } from '@/types/redux';

export const UPDATE_MESSAGING_PROVIDER = 'UPDATE_MESSAGING_PROVIDER';
export type UPDATE_MESSAGING_PROVIDER_ACTION = TypedActionWithPayload<
    typeof UPDATE_MESSAGING_PROVIDER,
    {
        provider: PubSubProvider;
    }
>;
