import { ActionWithPayload } from '../../types/redux';
import { Bidders } from '@/types/BidderFavoriting';
import {
    BLOCK_BIDDER_FAIL,
    BLOCK_BIDDER_REQUEST,
    BLOCK_BIDDER_SUCCESS,
    LOAD_BLOCK_BIDDER_FAIL,
    LOAD_BLOCK_BIDDER_REQUEST,
    LOAD_BLOCK_BIDDER_SUCCESS,
    LOAD_BLOCKED_BIDDERS_FAIL,
    LOAD_BLOCKED_BIDDERS_REQUEST,
    LOAD_BLOCKED_BIDDERS_SUCCESS,
    UNBLOCK_BIDDER_FAIL,
    UNBLOCK_BIDDER_REQUEST,
    UNBLOCK_BIDDER_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import api from '../api/blockedBidders';

export type State = {
    bidders: Bidders[];
    blockedByEmailResponse: number;
    error: boolean;
    isLoading: boolean;
    isUnblocking: number;
    page: number;
    pageSize: number;
    totalRecords: number;
};

/* reducer */
export const DEFAULT_STATE: State = {
    bidders: [
        {
            bidderId: 0,
            bidderName: '',
            houseId: 0,
            joined: '',
            lastEvent: '',
            note: '',
            payment: '',
            type: '',
            typeId: 0,
            userName: '',
            wonItems: 0,
        },
    ],

    blockedByEmailResponse: 0,
    error: false,
    isLoading: false,
    isUnblocking: 0,
    page: 1,
    pageSize: 120,
    totalRecords: 0,
};

export const reducer = handleActions(
    {
        [BLOCK_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            isLoading: false,
        }),
        [BLOCK_BIDDER_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [BLOCK_BIDDER_SUCCESS]: (state: State): State => ({
            ...state,
            isLoading: false,
        }),
        [LOAD_BLOCK_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_BLOCK_BIDDER_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BLOCK_BIDDER_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                blockedByEmailResponse: number;
            }>
        ): State => {
            return {
                ...state,
                blockedByEmailResponse: action.payload.blockedByEmailResponse,
                isLoading: false,
            };
        },
        [LOAD_BLOCKED_BIDDERS_FAIL]: (state: State): State => ({
            ...state,
            error: true,
            isLoading: false,
        }),
        [LOAD_BLOCKED_BIDDERS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BLOCKED_BIDDERS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                {
                    bidders: any;
                    totalCount: number;
                },
                {
                    page: number;
                    pageSize: number;
                }
            >
        ): State => {
            return {
                ...state,
                bidders: action.payload.bidders,
                isLoading: false,
                page: action.meta.page,
                pageSize: action.meta.pageSize,
                totalRecords: action.payload.totalCount,
            };
        },
        [UNBLOCK_BIDDER_FAIL]: (state: State): State => ({
            ...state,
            isUnblocking: 0,
        }),
        [UNBLOCK_BIDDER_REQUEST]: (
            state: State,
            action: ActionWithPayload<{ bidderId: number }, { bidderId: number }>
        ): State => ({
            ...state,
            isUnblocking: action.meta.bidderId,
        }),
        [UNBLOCK_BIDDER_SUCCESS]: (state: State): State => ({
            ...state,
            isUnblocking: 0,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.blockedBidders;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const getError = createSelector(stateSelector, (state) => state.error);
export const getBlockedBidders = createSelector(stateSelector, (state) => state.bidders);
export const getBlockedBiddersRecords = createSelector(stateSelector, (state) => state.totalRecords);
export const getUnblockingBidderStatus = createSelector(stateSelector, (state) => state.isUnblocking);

/* ACTION CREATORS */

export const fetchBlockedBiddersData =
    (houseId: number, page: number, pageSize: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BLOCKED_BIDDERS_REQUEST,
            });

            const response = await api.getBlockedBidders({
                authToken,
                deployment,
                houseId,
                page,
                pageSize,
            });

            dispatch({
                meta: { page, pageSize },
                payload: response.payload,
                type: LOAD_BLOCKED_BIDDERS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: LOAD_BLOCKED_BIDDERS_FAIL,
            });
        }
    };

export const blockBidder = (bidderId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: BLOCK_BIDDER_REQUEST,
        });

        const response = await api.blockBidder({
            authToken,
            bidderId,
            deployment,
            houseId,
        });

        dispatch({
            payload: response.payload,
            type: BLOCK_BIDDER_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: BLOCK_BIDDER_FAIL,
        });
    }
};

export const unblockBidder = (bidderId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            meta: { bidderId },
            type: UNBLOCK_BIDDER_REQUEST,
        });

        const response = await api.unblockBidder({
            authToken,
            bidderId,
            deployment,
            houseId,
        });

        dispatch({
            payload: response.payload,
            type: UNBLOCK_BIDDER_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: UNBLOCK_BIDDER_FAIL,
        });
    }
};

export const postBlockBidderNameOrEmail =
    (houseId: number, nameOrEmail: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BLOCK_BIDDER_REQUEST,
            });

            const response = await api.postBlockBidderNameOrEmail({
                authToken,
                deployment,
                houseId,
                nameOrEmail,
            });

            return dispatch({
                payload: { blockedByEmailResponse: response.payload },
                type: LOAD_BLOCK_BIDDER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_BLOCK_BIDDER_FAIL,
            });
        }
    };
