import { ActionWithPayload } from '../../types/redux';
import { BidValueMetrics } from '../../types/BidValueMetrics';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { LOAD_BID_VALUE_METRICS_FAIL, LOAD_BID_VALUE_METRICS_REQUEST, LOAD_BID_VALUE_METRICS_SUCCESS } from './actions';
import api from '../api/bidValuemetrics';

export type State = {
    error: boolean;
    isLoading: boolean;
    metrics: BidValueMetrics;
};
/* reducer */
export const DEFAULT_STATE: State = {
    error: false,
    isLoading: false,
    metrics: {
        absentee: {
            approved: 0,
            currencyCode: 'USD',
            currencySymbol: '',
            pending: 0,
            total: 0,
        },
        leading: {
            approved: 0,
            currencyCode: 'USD',
            currencySymbol: '',
            pending: 0,
            total: 0,
        },
    },
};

export const reducer = handleActions(
    {
        [LOAD_BID_VALUE_METRICS_FAIL]: (state: State): State => ({
            ...state,
            error: false,
            isLoading: false,
        }),
        [LOAD_BID_VALUE_METRICS_REQUEST]: (state: State): State => ({
            ...state,
            isLoading: true,
        }),
        [LOAD_BID_VALUE_METRICS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                bidValueMetrics: BidValueMetrics;
            }>
        ): State => {
            return {
                ...state,
                isLoading: false,
                metrics: action.payload.bidValueMetrics,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.bidValueMetrics;
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const bidValueMetricsSelector = createSelector(stateSelector, (state) => state.metrics);

/* ACTION CREATORS */
export const fetchBidMetricsData =
    (catalogId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: LOAD_BID_VALUE_METRICS_REQUEST,
            });

            const response = await api.getBidValueMetrics({
                authToken,
                catalogId,
                deployment,
                houseId,
            });
            dispatch({
                payload: { bidValueMetrics: response.payload },
                type: LOAD_BID_VALUE_METRICS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: error,
                type: LOAD_BID_VALUE_METRICS_FAIL,
            });
        }
    };
