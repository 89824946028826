import * as React from 'react';
import { ComponentProps, ReactNode } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { fetchSellersIfNeeded, getSeller } from '@/redux/modules/seller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassicHost } from '@/redux/modules/config';
import { getDashboardUrl, getMessagesUrl } from '@/utils/urls';
import { getUserHouseId, getUsername, isBrassAdminSelector } from '@/redux/modules/user';
import { submitLogout } from '@/redux/modules/login';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import ActionText from '@liveauctioneers/caterwaul-components/lib/ActionText/ActionText';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import Popover from '@liveauctioneers/caterwaul-components/lib/Popover/Popover';
import './Login.css';

type Props = {
    setImpersonateAction?: ComponentProps<typeof ActionText>['onClick'];
};

const LoginContainer = ({ setImpersonateAction }: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classicHost = useAppSelector(getClassicHost);
    const { houseId: userHouseId } = useParams<{ houseId: string }>();
    const houseId = useAppSelector(getUserHouseId) || Number(userHouseId);
    const isBrassAdmin = useAppSelector(isBrassAdminSelector);
    const house = useAppSelector((state) => getSeller(state, houseId));
    const username = useAppSelector(getUsername);

    const windowWidth = useWindowWidth();
    const houseName = house.name;

    React.useEffect(() => {
        dispatch(fetchSellersIfNeeded([houseId]));
    }, [dispatch, houseId]);

    let title = houseName ? `${username} (${houseName})` : username;
    if (windowWidth < 550) {
        title = '';
    }

    const handleLogoutClick = () => {
        dispatch(submitLogout(navigate));
    };

    const getLinks = (linkedHouseId: number) => {
        const links: ReactNode[] = [];
        if (Boolean(linkedHouseId)) {
            links.push(
                <Link
                    className="dropDownLink"
                    key="dashboard"
                    route
                    to={getDashboardUrl(linkedHouseId)}
                >
                    Dashboard
                </Link>
            );
        }
        if (isBrassAdmin) {
            links.push(
                Boolean(setImpersonateAction) ? (
                    <ActionText
                        className="dropDownLink"
                        key="impersonate"
                        onClick={setImpersonateAction}
                    >
                        Impersonate House
                    </ActionText>
                ) : (
                    <Link
                        className="dropDownLink"
                        key="impersonate"
                        route
                        to="/"
                    >
                        Impersonate House
                    </Link>
                )
            );
        }
        links.push(
            <ActionText
                className="dropDownLink"
                key="sign-out"
                onClick={handleLogoutClick}
                // @ts-expect-error "to" exists (probably), but not in the props type definition.
                to={`https://${classicHost}.liveauctioneers.com/user/logout.html?url=/auctioneers/`}
            >
                Sign out
            </ActionText>
        );
        return links;
    };

    return username ? (
        <div className="header__right">
            <div className="header__right_internal">
                <Popover
                    hover
                    placement="bottom"
                    trigger={
                        <span className="titleSpan">
                            {title} <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    }
                >
                    {getLinks(houseId)}
                </Popover>
            </div>
            <div className="messageContainer">
                <Link
                    route
                    to={getMessagesUrl(houseId)}
                >
                    <FontAwesomeIcon
                        className="envelopeIcon"
                        icon={faEnvelope}
                    />
                </Link>
            </div>
        </div>
    ) : (
        <Link
            className="header__right"
            route
            to="/login"
        >
            Login
        </Link>
    );
};

export default LoginContainer;
