import { ActionWithPayload } from '../../types/redux';
import { AUTO_ACCEPT_BIDS_DISABLED, AUTO_ACCEPT_BIDS_ENABLED } from './actions';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '../rootReducer';

export type State = {
    autoAcceptBids: boolean;
};

export const DEFAULT_STATE: State = {
    autoAcceptBids: false,
};

export const reducer = handleActions(
    {
        [combineActions(AUTO_ACCEPT_BIDS_DISABLED, AUTO_ACCEPT_BIDS_ENABLED)]: (
            state: State,
            action: ActionWithPayload<boolean>
        ) => ({
            ...state,
            autoAcceptBids: action.payload,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.autoAcceptBids;

export const getAutoAcceptBids = createSelector(stateSelector, (state) => state.autoAcceptBids);

export const updateAutoAcceptBids = (autoAcceptBids: boolean, catalogId: number) => async (dispatch: Function) => {
    dispatch({
        meta: { actionTime: Date.now(), catalogId },
        payload: autoAcceptBids,
        type: autoAcceptBids ? AUTO_ACCEPT_BIDS_ENABLED : AUTO_ACCEPT_BIDS_DISABLED,
    });
};
