import { handleResponse, makePost } from './helpers';

type PostPaymentOverrideParams = {
    amount: number;
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
};

type PostPaymentOverridePayload = {
    error: boolean;
    payload: string;
};

export default {
    postPaymentOverride: ({ amount, authToken, catalogId, deployment, houseId }: PostPaymentOverrideParams) =>
        new Promise<PostPaymentOverridePayload>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/catalog/${catalogId}/override`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.send({
                amount,
                catalogId,
            });
            request.end((err: boolean, response: PostPaymentOverridePayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
