import { GetConversationMessagesParams, GetConversationMessagesResponsePayload } from './bidderConversations.types';
import { handleResponse, makeGet } from '../../api/helpers';

export const getBidderConversations = ({ authToken, bidderId, deployment, houseId }: GetConversationMessagesParams) =>
    new Promise<GetConversationMessagesResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<MESSAGING-API>house/${houseId}/approval/${bidderId}`,
        });
        request.end((err: boolean, response: GetConversationMessagesResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });
