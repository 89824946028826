import { submitFeedback as apiSubmitFeedback } from '../api/feedback';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import {
    RESET_FEEDBACK_MODAL,
    SUBMIT_FEEDBACK_FAIL,
    SUBMIT_FEEDBACK_REQUEST,
    SUBMIT_FEEDBACK_SUCCESS,
} from './actions';

// reducer
export type State = {
    submitted: boolean;
    success: boolean;
};

export const DEFAULT_STATE: State = {
    submitted: false,
    success: false,
};

export default function reducer(state = DEFAULT_STATE, action: any = {}) {
    switch (action.type) {
        case RESET_FEEDBACK_MODAL:
        case SUBMIT_FEEDBACK_FAIL:
            return {
                ...state,
                submitted: false,
                success: false,
            };
        case SUBMIT_FEEDBACK_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SUBMIT_FEEDBACK_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const feedbackSelector = (state) => state.feedback;

export const getAccessToken = createSelector(feedbackSelector, (state) => state.accessToken);

export const getSubmitted = createSelector(feedbackSelector, (state) => state.submitted);

export const getSuccess = createSelector(feedbackSelector, (state) => state.success);

/* ACTION CREATORS */
export const resetModal = () => ({
    type: RESET_FEEDBACK_MODAL,
});

export const submitFeedback =
    ({ currentURL, email, feedback }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const authToken = getAccessToken(state);
            const deployment = getDeployment(state);
            const feedbackType = 'Site Feedback';

            const feedbackObject = {
                email,
                message: feedback,
                page: currentURL,
                source: feedbackType,
            };
            dispatch({
                payload: {
                    email,
                    feedback,
                },
                type: SUBMIT_FEEDBACK_REQUEST,
            });
            await apiSubmitFeedback({
                authToken,
                deployment,
                feedbackObject,
            });
            dispatch({
                payload: {
                    email,
                    feedbackObject,
                },
                type: SUBMIT_FEEDBACK_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_FEEDBACK_FAIL,
            });
        }
    };
