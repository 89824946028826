import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { fetchConsoleBrandingApi } from '../api/consoleBranding';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_CONSOLE_BRANDING_FAIL, LOAD_CONSOLE_BRANDING_REQUEST, LOAD_CONSOLE_BRANDING_SUCCESS } from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export const DEFAULT_STATE = {
    byCatalogId: {},
    bySellerId: {},
    loadedCatalogs: {},
    loadedSellers: {},
    loadingCatalogs: [],
    loadingSellers: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_CONSOLE_BRANDING_FAIL]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; sellerId: number }>
        ) => ({
            ...state,
            // @ts-ignore
            loadingCatalogs: difference(state.loadingCatalogs, action.payload.catalogId),
            // @ts-ignore
            loadingSellers: difference(state.loadingSellers, action.payload.sellerId),
        }),
        [LOAD_CONSOLE_BRANDING_REQUEST]: (
            state: State,
            action: ActionWithPayload<{ catalogId: number; sellerId: number }>
        ) => ({
            ...state,
            // @ts-ignore
            loadingCatalogs: union(state.loadingCatalogs, action.payload.catalogId),
            // @ts-ignore
            loadingSellers: union(state.loadingSellers, action.payload.sellerId),
        }),
        [LOAD_CONSOLE_BRANDING_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ consoleBrandings: any[] }, { actionTime: number }>
        ) => {
            let existingCatalogs = cloneDeep(state.byCatalogId);
            let existingSellers = cloneDeep(state.bySellerId);
            let loadedCatalogs = { ...state.loadedCatalogs };
            let loadedSellers = { ...state.loadedSellers };
            let loadingCatalogs = cloneDeep(state.loadingCatalogs);
            let loadingSellers = cloneDeep(state.loadingSellers);
            let time = action.meta.actionTime;

            if (action.payload.consoleBrandings) {
                action.payload.consoleBrandings.forEach((item) => {
                    let { catalogId, color = '', logoUrl = '', sellerId } = item;

                    // make sure that we're using a secure connection
                    if (logoUrl.includes('http://')) {
                        logoUrl = item.logoUrl.replace('http://', 'https://');
                    }

                    // convert to hex color style
                    if (color.includes('0x')) {
                        color = item.color.replace('0x', '#');
                    }

                    if (catalogId) {
                        existingCatalogs[catalogId] = {
                            ...item,
                            color,
                            logoUrl,
                        };
                        loadedCatalogs[catalogId] = time;
                        loadingCatalogs = difference(loadingCatalogs, [catalogId]);
                    }
                    if (sellerId) {
                        existingSellers[sellerId] = {
                            ...item,
                            color,
                            logoUrl,
                        };
                        loadedSellers[sellerId] = time;
                        loadingSellers = difference(loadingSellers, [sellerId]);
                    }
                });
            }
            return {
                ...state,
                byCatalogId: existingCatalogs,
                bySellerId: existingSellers,
                loadedCatalogs,
                loadedSellers,
                loadingCatalogs,
                loadingSellers,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.consoleBranding;
const catalogIdSelector = (state, catalogId) => catalogId;
const sellerIdSelector = (state, catalogId, sellerId) => sellerId;

const byCatalogIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);
const bySellerIdSelector = createSelector(stateSelector, (state) => state.bySellerId);

const loadedCatalogsSelector = createSelector(stateSelector, (state) => state.loadedCatalogs);
const loadedSellersSelector = createSelector(stateSelector, (state) => state.loadedSellers);

const loadingCatalogsSelector = createSelector(stateSelector, (state) => state.loadingCatalogs);
const loadingSellersSelector = createSelector(stateSelector, (state) => state.loadingSellers);

const getConsoleBrandingCatalog = createSelector(
    [byCatalogIdSelector, catalogIdSelector],
    (byId, catalogId) => byId[catalogId] || { color: '', logoUrl: '' }
);

const getConsoleBrandingSeller = createSelector(
    [bySellerIdSelector, sellerIdSelector],
    (byId, sellerId) => byId[sellerId] || { color: '', logoUrl: '' }
);

export const getConsoleBranding = createSelector(
    [getConsoleBrandingCatalog, getConsoleBrandingSeller],
    (catalog, seller) => {
        if (catalog.color !== '' || catalog.logoUrl !== '') {
            return catalog;
        } else if (seller.color !== '' || seller.logoUrl !== '') {
            return seller;
        } else {
            return {
                color: '',
                logoUrl: '',
            };
        }
    }
);

const getLoadTimeForConsoleBrandingCatalog = createSelector(
    [loadedCatalogsSelector, catalogIdSelector],
    (loaded, id) => loaded[id] || 0
);
const getLoadTimeForConsoleBrandingSeller = createSelector(
    [loadedSellersSelector, sellerIdSelector],
    (loaded, id) => loaded[id] || 0
);

export const isConsoleBrandingCatalogLoading = createSelector(
    [loadingCatalogsSelector, catalogIdSelector],
    (loading, id) => loading.includes(id)
);
export const isConsoleBrandingSellerLoading = createSelector(
    [loadingSellersSelector, sellerIdSelector],
    (loading, id) => loading.includes(id)
);

const shouldFetchConsoleBrandingCatalog = (state, catalogId) => {
    if (!catalogId) {
        return false;
    }
    const loaded = getLoadTimeForConsoleBrandingCatalog(state, catalogId);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }

    const loading = isConsoleBrandingCatalogLoading(state, catalogId);
    return !loading;
};

const shouldFetchConsoleBrandingSeller = (state, catalogId, sellerId) => {
    if (!sellerId) {
        return false;
    }
    const loaded = getLoadTimeForConsoleBrandingSeller(state, catalogId, sellerId);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }

    const loading = isConsoleBrandingSellerLoading(state, catalogId, sellerId);
    return !loading;
};

const shouldFetchConsoleBranding = (state, catalogId, sellerId) => {
    return (
        shouldFetchConsoleBrandingCatalog(state, catalogId) ||
        shouldFetchConsoleBrandingSeller(state, catalogId, sellerId)
    );
};

/* ACTION CREATORS */
const loadConsoleBranding = (catalogId, sellerId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: { catalogId, sellerId },
            type: LOAD_CONSOLE_BRANDING_REQUEST,
        });
        const response = await fetchConsoleBrandingApi({ catalogId, deployment, sellerId });
        return dispatch({
            meta: { actionTime: Date.now(), catalogId, sellerId },
            payload: response.data,
            type: LOAD_CONSOLE_BRANDING_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { catalogId, sellerId },
            payload: error,
            type: LOAD_CONSOLE_BRANDING_FAIL,
        });
    }
};

export const fetchConsoleBrandingIfNeeded =
    (catalogId: number, sellerId: number) => async (dispatch: Function, getState: Function) => {
        const needed = shouldFetchConsoleBranding(getState(), catalogId, sellerId);
        if (needed) {
            return dispatch(loadConsoleBranding(catalogId, sellerId));
        }
        return Promise.resolve();
    };
