import { handleResponse, makeGet } from './helpers';

type GetHouseInvoiceDataParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
};

export default {
    getHouseInvoiceData: ({ authToken, catalogId, deployment, houseId }: GetHouseInvoiceDataParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                apiPath: `house/${houseId}/catalog/${catalogId}/prepayments/due`,
                authToken,
                deployment,
                path: '<HOUSE-FINANCE-API>',
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
