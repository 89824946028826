// Import polyfills before any other code
import './polyfills';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { GlobalState } from '@/redux/rootReducer';
import { HammerUIProvider } from '@liveauctioneers/hammer-ui-core/providers';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { IStyleSheetContext, StyleSheetManager } from 'styled-components';
import { mergedCaterwaulAndHammerUIThemes } from '@/theme/mergedCaterwaulAndHammerUIThemes';
import { PersistGate } from 'redux-persist/integration/react'; // Use createRoot instead of render or hydrate
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setContext } from './utils/rum';
import App from './pages/App/App';
import en from './intl/en';
import ErrorBoundary from '@liveauctioneers/caterwaul-components/lib/ErrorBoundary/ErrorBoundary';
import flattenMessages from '@liveauctioneers/caterwaul-components/lib/utils/flattenMessages';
import isPropValid from '@emotion/is-prop-valid';
import React from 'react';
import store, { persistor } from './redux/create';
import './theme/huiFonts.css';
import './theme/theme.css';
import '@liveauctioneers/caterwaul-components/theme/theme.css';

/**
 * When we migrate completely to hammer-ui inputs and typography, we should be able to delete this prop filtering
 *
 * This implements the default behavior from styled-components v5
 * @see https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 */
const shouldForwardProp: IStyleSheetContext['shouldForwardProp'] = (propName, target) => {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};

const renderTheApp = () => {
    const {
        config: { buildNumber, deployment },
    } = store.getState() as GlobalState;

    setContext({ environment: deployment, release: buildNumber }, store);

    const target = document.getElementById('root');

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                initialDataUpdatedAt: Date.now() / 1000,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                retry: false,
                // Three hours
                staleTime: 3 * 60 * 60 * 1000,
            },
        },
    });

    if (target) {
        const root = createRoot(target);

        root.render(
            <QueryClientProvider client={queryClient}>
                <HammerUIProvider theme={mergedCaterwaulAndHammerUIThemes}>
                    <ErrorBoundary>
                        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                            <HelmetProvider>
                                <Provider store={store}>
                                    <IntlProvider
                                        locale="en"
                                        messages={flattenMessages(en)}
                                    >
                                        <PersistGate
                                            loading={null}
                                            persistor={persistor}
                                        >
                                            <React.StrictMode>
                                                <BrowserRouter>
                                                    <App />
                                                </BrowserRouter>
                                            </React.StrictMode>
                                        </PersistGate>
                                    </IntlProvider>
                                </Provider>
                            </HelmetProvider>
                        </StyleSheetManager>
                    </ErrorBoundary>
                </HammerUIProvider>
            </QueryClientProvider>
        );
    }
};

if (!global.Intl) {
    Promise.all([import('intl'), import('intl/locale-data/jsonp/en.js')]).then(() => renderTheApp());
} else {
    renderTheApp();
}
