import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState } from '@/redux/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { defaultSeller, Seller } from '@/types/Seller';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { Item } from '@/types/Item';
import { LOAD_SELLERS_FAIL, LOAD_SELLERS_REQUEST, LOAD_SELLERS_SUCCESS } from './actions';
import { loadBatchEnd, loadBatchStart } from './fetchBatch';
import api from '../api/seller';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */

const REDUX_STORE_TIME = ms('30m');
const FETCH_SET_SIZE = 120;

/* reducer */
export type State = {
    byId: { [id: number]: Seller };
    loaded: { [id: number]: number };
    loadedSellers: boolean;
    loading: number[];
    loadingSellers: boolean;
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loadedSellers: false,
    loading: [],
    loadingSellers: false,
};

export const reducer = handleActions(
    {
        [LOAD_SELLERS_FAIL]: (state: State, action: ActionWithPayload<{}, { sellerIds: number[] }>) => ({
            ...state,
            loading: difference(state.loading, action.meta.sellerIds),
        }),
        [LOAD_SELLERS_REQUEST]: (state: State, action: ActionWithPayload<number[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [LOAD_SELLERS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ items: Item[]; sellers: Seller[] }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.sellers) {
                action.payload.sellers.forEach((item) => {
                    existing[item.sellerId] = { ...item };
                    loaded[item.sellerId] = time;
                    loading = difference(loading, [item.sellerId]);
                });
            }
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.seller;

type SellerId = keyof State['byId'];
const idSelector = (_: GlobalState, id: SellerId) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

const getLoadTimeForSeller = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isSellerLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const getSeller = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || defaultSeller);

export const shouldFetchSeller = (state: GlobalState, sellerId: SellerId) => {
    if (!sellerId) {
        return false;
    }
    const item = getSeller(state, sellerId);
    if (item) {
        const loaded = getLoadTimeForSeller(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerLoading(state, sellerId);
    return !loading;
};

const whichSellersNeeded = (state: GlobalState, sellerIds: SellerId[]) =>
    sellerIds.filter((sellerId) => shouldFetchSeller(state, sellerId));

/* ACTION CREATORS */

// seller
const loadSellers =
    (sellerIds: SellerId[], loadingId: string, isFinalFetch: boolean) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                payload: sellerIds,
                type: LOAD_SELLERS_REQUEST,
            });
            const response = await api.fetchSellersByIds({ authToken, deployment, sellerIds });
            dispatch({
                meta: { actionTime: Date.now(), sellerIds },
                payload: response.data,
                type: LOAD_SELLERS_SUCCESS,
            });
            if (isFinalFetch) {
                dispatch(loadBatchEnd(loadingId));
            }
        } catch (error) {
            dispatch({
                error: true,
                meta: { sellerIds },
                payload: error,
                type: LOAD_SELLERS_FAIL,
            });
            dispatch(loadBatchEnd(loadingId));
        }
    };

const loadSellersInSets = (ids: SellerId[], setSize: number, loadingId: string) => {
    return (dispatch) => {
        if (ids.length > setSize) {
            return dispatch(loadSellers(ids.slice(0, setSize), loadingId, false)).then(() => {
                dispatch(loadSellersInSets(ids.slice(setSize), setSize, loadingId));
            });
        }
        return dispatch(loadSellers(ids, loadingId, true));
    };
};

export const fetchSellersIfNeeded = (sellerIds: SellerId[], loadingId: string = 'none') => {
    return (dispatch: Function, getState: Function) => {
        const neededSellers = whichSellersNeeded(getState(), sellerIds);
        // const neededCatalogCounts = whichSellerCatalogCountsNeeded(getState(), sellerIds);
        // const needed = union(neededSellers, neededCatalogCounts);
        if (neededSellers.length) {
            dispatch(loadBatchStart(loadingId));
            return dispatch(loadSellersInSets(neededSellers, FETCH_SET_SIZE, loadingId));
        }
        return Promise.resolve();
    };
};
