import { handleResponse, makeGet, makePost } from './helpers';
import type * as LiveAuctionEvents from '@/types/LiveAuctionEvent';
import type { AuctionStatus, CurrentLotStatus } from '@/types/LiveAuctionState';
import type { BidSource } from '@/types/BidSource';
import type { PubSubConfig, PubSubProvider } from '@/types/PubSubProvider';

type GetBidderPaddleNumberParams = {
    catalogId: number;
    deployment: string;
    itemId: number;
    soldAssignedId: number;
};

export const getBidderPaddleNumber = ({ catalogId, deployment, itemId, soldAssignedId }: GetBidderPaddleNumberParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'ajax_scripts/bidmaster_console_output.php',
            deployment,
            path: '<MAINHOST-API>',
        });
        request.query({
            assignedId: soldAssignedId,
            catalogId,
            lot_id: itemId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetClerkAuctionStateParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

// todo: use these types when dante auction state api is removed
export type FetchLiveAuctionStatusResponse = {
    data: {
        catalogId: number;
        currentItem: {
            askPrice: number;
            increment: number;
            itemId: number;
            itemIndex: number;
            leadingAssignedId: number;
            leadingBid: number;
            lotNumber: number;
            lotStatus: CurrentLotStatus;
            pendingAssignedId: number;
            pendingBid: number;
        };
        messagingProvider: PubSubProvider;
        previousLotNumber: number;
        status: AuctionStatus;
        subscriptions: {
            ably: PubSubConfig;
            pubnub: PubSubConfig;
        };
    };
    error?: boolean;
};

type FetchLiveCatalogStatusResponsePayload = {
    payload: {
        auctionStatus: AuctionStatus;
        currentAskPrice: number;
        currentHighBid: number;
        currentIncrement: number;
        currentLotId: number;
        currentLotIndex: number;
        currentLotNumber: number;
        currentLotStatus: CurrentLotStatus;
        leadingAssignedId: number;
        messageProvider: PubSubProvider;
        pendingAssignedId: number;
        pendingBid: number;
        previousLotNumber: number;
        subscriptions: {
            ably: PubSubConfig;
            pubnub: PubSubConfig;
        };
    };
};

export const getClerkAuctionState = ({ authToken, catalogId, deployment, sellerId }: GetClerkAuctionStateParams) =>
    new Promise<FetchLiveAuctionStatusResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/state/clerk`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
            useCacheKey: false,
        });
        request.end((err, response) => {
            // Handle behavior according to https://liveauctioneers.atlassian.net/browse/BUGS-3983
            // Treat 204 as 404
            if (response.statusCode === 204) {
                return reject();
            }

            return handleResponse({
                err,
                reject,
                resolve,
                response,
                transform: ({ payload }: FetchLiveCatalogStatusResponsePayload): FetchLiveAuctionStatusResponse => {
                    const {
                        auctionStatus,
                        currentAskPrice,
                        currentHighBid,
                        currentIncrement,
                        currentLotId,
                        currentLotIndex,
                        currentLotNumber,
                        currentLotStatus,
                        leadingAssignedId,
                        messageProvider,
                        pendingAssignedId,
                        pendingBid,
                        previousLotNumber,
                        subscriptions,
                    } = payload || {};

                    return {
                        data: {
                            catalogId,
                            currentItem: {
                                askPrice: currentAskPrice,
                                increment: currentIncrement || 1,
                                itemId: currentLotId,
                                itemIndex: currentLotIndex,
                                leadingAssignedId,
                                leadingBid: currentHighBid,
                                lotNumber: currentLotNumber,
                                lotStatus: currentLotStatus,
                                pendingAssignedId,
                                pendingBid,
                            },
                            messagingProvider: messageProvider,
                            previousLotNumber,
                            status: auctionStatus,
                            subscriptions,
                        },
                    };
                },
            });
        });
    });

type PostAcceptBidParams = {
    amount: number;
    assignedId: number;
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
    source: BidSource;
};

type PostAcceptBidResponsePayload = {
    error: boolean;
    payload: LiveAuctionEvents.BidAcceptedEvent;
};

export const postAcceptBid = ({
    amount,
    assignedId,
    authToken,
    catalogId,
    deployment,
    itemId,
    sellerId,
    source,
}: PostAcceptBidParams) =>
    new Promise<PostAcceptBidResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/accept`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            amount,
            assignedId,
            bidSource: source,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostCloseAuctionParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const postCloseAuction = ({ authToken, catalogId, deployment, sellerId }: PostCloseAuctionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/close`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostOpenAuctionParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const postOpenAuction = ({ authToken, catalogId, deployment, sellerId }: PostOpenAuctionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/open`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostLoadAuctionParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const postLoadAuction = ({ authToken, catalogId, deployment, sellerId }: PostLoadAuctionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/load`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostOpenLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    lotNumber: string;
    sellerId: number;
};

export const postOpenLot = ({ authToken, catalogId, deployment, itemId, lotNumber, sellerId }: PostOpenLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/open`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            lotNumber,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostOpenNextLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postOpenNextLot = ({ authToken, catalogId, deployment, itemId, sellerId }: PostOpenNextLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/openNext`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostPassLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postPassLot = ({ authToken, catalogId, deployment, itemId, sellerId }: PostPassLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/pass`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostPauseAuctionParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const postPauseAuction = ({ authToken, catalogId, deployment, sellerId }: PostPauseAuctionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/pause`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostReopenLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postReopenLot = ({ authToken, catalogId, deployment, itemId, sellerId }: PostReopenLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/reopen`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostNewSFSReopenLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postNewSFSReopenLot = ({
    authToken,
    catalogId,
    deployment,
    itemId,
    sellerId,
}: PostNewSFSReopenLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/reopenPrevious`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostResumeAuctionParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    sellerId: number;
};

export const postResumeAuction = ({ authToken, catalogId, deployment, sellerId }: PostResumeAuctionParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/resume`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostRetractBidParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postRetractBid = ({ authToken, catalogId, deployment, itemId, sellerId }: PostRetractBidParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/retractLast`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSendMissiveParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    missiveText: string;
    sellerId: number;
};

export const postSendMissive = ({ authToken, catalogId, deployment, missiveText, sellerId }: PostSendMissiveParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/missives/send`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            missiveText,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetAskParams = {
    ask: number;
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

type PostSetAskPayload = {
    error: boolean;
    payload: LiveAuctionEvents.AskChangedEvent;
};

export const postSetAsk = ({ ask, authToken, catalogId, deployment, itemId, sellerId }: PostSetAskParams) =>
    new Promise<PostSetAskPayload>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/askPrice`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            askPrice: ask,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSetIncrementParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    increment: number;
    itemId: number;
    sellerId: number;
};

type PostSetIncrementPayload = {
    error: boolean;
    payload: string;
};

export const postSetIncrement = ({
    authToken,
    catalogId,
    deployment,
    increment,
    itemId,
    sellerId,
}: PostSetIncrementParams) =>
    new Promise<PostSetIncrementPayload>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/increment`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            bidIncrement: increment,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSkipLotParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
};

export const postSkipLot = ({ authToken, catalogId, deployment, itemId, sellerId }: PostSkipLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/skip`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostSoldLotParams = {
    amount: number;
    assignedId: number;
    authToken: string;
    bidderId?: number;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
    source: string;
};

export const postSoldLot = ({
    amount,
    assignedId,
    authToken,
    bidderId,
    catalogId,
    deployment,
    itemId,
    sellerId,
    source,
}: PostSoldLotParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/sell`,
            authToken,
            deployment,
            path: '<AUCTION-ENGINE-API>',
        });
        request.send({
            amount,
            assignedId,
            bidSource: source,
        });
        if (!assignedId) {
            request.send({
                bidderId,
            });
        }
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export type PostRecordMessageParams = {
    authToken: string;
    catalogId: number;
    clientReceivedTs: number;
    deployment: string;
    lotId?: number;
    message: string;
    messageId: number;
};

export const postRecordMessage = ({
    authToken,
    catalogId,
    clientReceivedTs,
    deployment,
    lotId,
    message,
    messageId,
}: PostRecordMessageParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'clerk/logmessage',
            authToken,
            deployment,
            path: '<CLERK-SERVICE>',
        });
        request.send({
            catalogId,
            clientReceivedTs,
            lotId,
            message,
            messageId,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
