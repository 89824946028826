import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOAD_SAVED_ITEM_DATA_FAIL, LOAD_SAVED_ITEM_DATA_REQUEST, LOAD_SAVED_ITEM_DATA_SUCCESS } from './actions';
import api from '../api/dashboard';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
export const DEFAULT_STATE = {
    byCatalogId: {},
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_SAVED_ITEM_DATA_FAIL]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            // @ts-ignore
            loading: difference(state.loading, action.meta.catalogId),
        }),
        [LOAD_SAVED_ITEM_DATA_REQUEST]: (state: State, action: ActionWithPayload<number>) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_SAVED_ITEM_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<any[], { actionTime: number; catalogId: number }>
        ) => {
            const existing = cloneDeep(state.byCatalogId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const {
                meta: { actionTime, catalogId },
                payload,
            } = action;
            const time = actionTime;

            if (action.payload) {
                existing[catalogId] = payload;
                loaded[catalogId] = time;
                loading = difference(loading, [catalogId]);
            }

            return {
                ...state,
                byCatalogId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.savedItemData;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const savedItemDataSelector = createSelector([byIdSelector, idSelector], (byCatalogId, id) => {
    return byCatalogId[id] || [];
});

export const loadTimeForSavedItemDataSelector = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isSavedItemDataLoadingSelector = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

const shouldFetchSavedItemData = createSelector([isSavedItemDataLoadingSelector], (loading) => !loading);

/* ACTION CREATORS */
const loadSavedItemData =
    (catalogId: number, houseId: number, startTime: string, endTime: string) => async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                payload: catalogId,
                type: LOAD_SAVED_ITEM_DATA_REQUEST,
            });
            const response = await api.fetchSavedItemData({
                authToken,
                catalogId,
                deployment,
                endTime,
                houseId,
                startTime,
            });
            return dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response,
                type: LOAD_SAVED_ITEM_DATA_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { catalogId },
                payload: error,
                type: LOAD_SAVED_ITEM_DATA_FAIL,
            });
        }
    };

export const fetchSavedItemDataIfNeeded =
    (catalogId: number, houseId: number, startTime: string, endTime: string) =>
    async (dispatch: Function, getState: Function) => {
        const needed = shouldFetchSavedItemData(getState(), catalogId);
        if (needed) {
            return dispatch(loadSavedItemData(catalogId, houseId, startTime, endTime));
        }
        return Promise.resolve();
    };
