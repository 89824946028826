import { createSelector } from '@reduxjs/toolkit';
import { Deployment } from '@liveauctioneers/hammer-ui-payments/types';
import { GlobalState } from '@/redux/rootReducer';
import {
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION,
    UPDATE_MESSAGING_PROVIDER,
    UPDATE_MESSAGING_PROVIDER_ACTION,
} from './actions';
import { PubSubConfig, PubSubProvider } from '@/types/PubSubProvider';

export type State = {
    baseUrl: string;
    buildNumber: string;
    cacheKey: string;
    deployment: Deployment | 'test' | '';
    messagingProvider: PubSubProvider;
    messagingProviderKeyConfigs: {
        ably: PubSubConfig;
        pubnub: PubSubConfig;
    };
    segmentWriteKey: string;
    useSegment: boolean;
};

/* reducer */
export const defaultConfigSlice: State = {
    baseUrl: '',
    buildNumber: '',
    cacheKey: '',
    deployment: '',
    messagingProvider: 'pubnub',
    messagingProviderKeyConfigs: {
        ably: {
            publishKey: '',
            subscribeKey: '',
        },
        pubnub: {
            publishKey: '',
            subscribeKey: '',
        },
    },
    segmentWriteKey: '',
    useSegment: false,
};

type Action = UPDATE_MESSAGING_PROVIDER_ACTION | LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION;

export default function reducer(state: State = defaultConfigSlice, action: Action): State {
    switch (action.type) {
        case UPDATE_MESSAGING_PROVIDER:
            return {
                ...state,
                messagingProvider: action.payload.provider,
            };
        case LOAD_LIVE_CATALOG_STATUS_SUCCESS:
            if (action.payload.status === 'notLoaded') {
                return state;
            } // Update pub/sub keys from auction engine state

            return {
                ...state,
                messagingProvider: action.payload?.messagingProvider || state.messagingProvider,
                messagingProviderKeyConfigs: action.payload?.subscriptions || state.messagingProviderKeyConfigs,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.config;

export const getBaseUrl = createSelector(stateSelector, (state: State) => state.baseUrl || '');
export const getBuildNumber = createSelector(stateSelector, (state: State) => state.buildNumber || 'none');
export const getClassicHost = createSelector(stateSelector, (state: State) =>
    state.deployment === 'prod' ? 'classic' : `mainhost-${state.deployment}`
);
export const getDeployment = createSelector(stateSelector, (state: State) => state.deployment || '');
export const getSegmentWriteKey = createSelector(stateSelector, (state: State) => state.segmentWriteKey || '');
export const getUseSegment = createSelector(stateSelector, (state: State) => state.useSegment || false);

export const getMessagingProviderKeyConfigs = createSelector(
    stateSelector,
    (state: State) => state.messagingProviderKeyConfigs
);

export const getPubSubMessagingProvider = createSelector(stateSelector, (state: State) => state.messagingProvider);

/**
 * Returns the publishKey and subscribeKey for the current messagingProvider
 */
export const getPubSubMessagingProviderKeys = createSelector(
    [getMessagingProviderKeyConfigs, getPubSubMessagingProvider],
    (messagingProviderKeyConfigs, messagingProvider): PubSubConfig => {
        return (
            messagingProviderKeyConfigs[messagingProvider] || {
                publishKey: '',
                subscribeKey: '',
            }
        );
    }
);

/* ACTION CREATORS */
export const updateMessagingProvider = (provider: PubSubProvider) => async (dispatch: Function) => {
    dispatch({
        payload: {
            provider,
        },
        type: UPDATE_MESSAGING_PROVIDER,
    } as UPDATE_MESSAGING_PROVIDER_ACTION);
};
