import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import {
    getHousePackagingAndHandlingFeeSettings,
    getShippingLabelHousePermissions,
    getShippingLabelPrinterSettings,
    postHousePackagingAndHandlingFeeSettings,
    postShippingLabelPrinterSettings,
} from '@/redux/modules/shipping/labels/shippingLabels.api';
import {
    HouseLabelPermissions,
    PackagingFeeInfo,
    Printers,
} from '@/redux/modules/shipping/labels/shippingLabels.types';
import {
    shouldFetchShippingLabelsFeesInfo,
    shouldFetchShippingLabelsPrinterInfo,
} from '@/redux/modules/shipping/labels/shippingLabels.selectors';

export const fetchPackagingFeeInfo = createAsyncThunk<PackagingFeeInfo, number>(
    'la/ui/shippingLabels/fetchPackagingFeeInfo',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await getHousePackagingAndHandlingFeeSettings({
            authToken,
            deployment,
            houseId,
        });
        return payload;
    }
);

export const fetchPackagingFeeInfoIfNeeded = createAsyncThunk<void, number>(
    'la/ui/shippingLabels/fetchPackagingFeeInfoIfNeeded',
    async (houseId, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchShippingLabelsFeesInfo(state, houseId)) {
            await dispatch(fetchPackagingFeeInfo(houseId));
        }
    }
);

export type SavePackagingFeeInfoPayload = {
    houseId: number;
    newSettings: PackagingFeeInfo;
};

export const savePackagingFeeInfo = createAsyncThunk<PackagingFeeInfo, SavePackagingFeeInfoPayload>(
    'la/ui/shippingLabels/savePackagingFeeInfo',
    async ({ houseId, newSettings }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await postHousePackagingAndHandlingFeeSettings({
            authToken,
            deployment,
            houseId,
            newSettings,
        });
        return payload;
    }
);

export const fetchShippingLabelsHousePermissions = createAsyncThunk<HouseLabelPermissions, number>(
    'la/ui/shippingLabels/fetchShippingLabelsPermissions',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getShippingLabelHousePermissions({
            authToken,
            deployment,
            houseId,
        });
        return payload;
    }
);

export const fetchShippingLabelsPrinterSettings = createAsyncThunk<Printers, number>(
    'la/ui/shippingLabels/fetchShippingLabelsPrinterSettings',
    async (houseId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getShippingLabelPrinterSettings({
            authToken,
            deployment,
            houseId,
        });
        return payload;
    }
);

export const fetchShippingLabelsPrinterSettingsIfNeeded = createAsyncThunk<void, number>(
    'la/ui/shippingLabels/fetchShippingLabelsPrinterSettingsIfNeeded',
    async (houseId, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchShippingLabelsPrinterInfo(state, houseId)) {
            await dispatch(fetchShippingLabelsPrinterSettings(houseId));
        }
    }
);

type SaveShippingLabelsPrinterSettingsParams = {
    houseId: number;
    selectedPrinter: Printers;
};

export const saveShippingLabelsPrinterSettings = createAsyncThunk<Printers, SaveShippingLabelsPrinterSettingsParams>(
    'la/ui/shippingLabels/savePrinterSettings',
    async ({ houseId, selectedPrinter }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await postShippingLabelPrinterSettings({
            authToken,
            deployment,
            houseId,
            selectedPrinter,
        });
        if (!payload.success) {
            throw new Error('Saving printer settings failed');
        }
        return selectedPrinter;
    }
);
