import { handleResponse, makePost } from './helpers';
import { HouseTransactionItem } from '../../types/HousePayment';

type PostLAHousePaymentApiParams = {
    amount: number;
    authToken: string;
    catalogId: number;
    currency?: string;
    deployment: string;
    houseId: number;
    invoiceId?: number;
    items: HouseTransactionItem[];
    note?: string;
    parentId?: number;
    paymentToken: string;
    providerId: number;
    userId?: number;
};

export default {
    postLAHousePayment: ({
        amount,
        authToken,
        catalogId,
        currency,
        deployment,
        houseId,
        invoiceId,
        items,
        note,
        parentId,
        paymentToken,
        providerId,
        userId,
    }: PostLAHousePaymentApiParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                apiPath: `house/${houseId}/payment`,
                authToken,
                deployment,
                path: '<PAYMENT-API>',
            });
            request.send({
                amount,
                catalogId,
                currency,
                invoiceId,
                items,
                note,
                parentId,
                paymentToken,
                providerId,
                userId,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
