import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';
import {
    LIVE_BID_HOVER_DISMISS,
    LIVE_BID_HOVER_DISMISS_ACTION,
    LIVE_BID_HOVER_INTENT,
    LIVE_BID_HOVER_INTENT_ACTION,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

const HOVER_EXPIRE_TIME = ms('2m');

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
};

export type State = typeof DEFAULT_STATE;

const getHoversThatAreNotExpired = (currentCatalog: any) => {
    const time = Date.now();

    const retVal = {};

    Object.keys(currentCatalog.hovers).forEach((key) => {
        if (time - currentCatalog.hovers[key].created < HOVER_EXPIRE_TIME) {
            retVal[currentCatalog.hovers[key].bidderId] = { ...currentCatalog.hovers[key] };
        }
    });

    return retVal;
};

export const reducer = handleActions(
    {
        [LIVE_BID_HOVER_DISMISS]: (state: State, action: LIVE_BID_HOVER_DISMISS_ACTION) => {
            const existing = cloneDeep(state.byId);
            const { bidderId, catalogId } = action.payload;

            const currentCatalog = existing[catalogId] || { catalogId, hovers: {} };
            const editableHovers = getHoversThatAreNotExpired(currentCatalog);
            delete editableHovers[bidderId];

            currentCatalog.hovers = editableHovers;
            existing[catalogId] = currentCatalog;
            return {
                ...state,
                byId: existing,
            };
        },
        [LIVE_BID_HOVER_INTENT]: (state: State, action: LIVE_BID_HOVER_INTENT_ACTION) => {
            const existing = cloneDeep(state.byId);
            const { bidderHasHighBid, bidderId, catalogId, created, isItemSaved, isSellerFollowed } = action.payload;

            const currentCatalog = existing[catalogId] || { catalogId, hovers: {} };
            const editableHovers = getHoversThatAreNotExpired(currentCatalog);
            let currentHover = editableHovers[bidderId];

            // if the bidder has high bid, do extra stuff
            if (bidderHasHighBid) {
                // if bidder has high bid but isnt in the hover list already, dont put it in.
                if (!currentHover) {
                    return state;
                }

                // if the bidder has the high bid but is in the hover list, remove it
                delete editableHovers[bidderId];
                currentCatalog.hovers = editableHovers;
                existing[catalogId] = currentCatalog;
                return {
                    ...state,
                    byId: existing,
                };
            } else if (!currentHover) {
                currentHover = { bidderId, created, isItemSaved: false, isSellerFollowed: false };
            }

            currentHover.created = created;
            currentHover.isItemSaved = isItemSaved;
            currentHover.isSellerFollowed = isSellerFollowed;

            editableHovers[bidderId] = currentHover;

            currentCatalog.hovers = editableHovers;
            existing[catalogId] = currentCatalog;
            return {
                ...state,
                byId: existing,
            };
        },
        [LIVE_NEXT_LOT_LOADED]: (state: State, action: LIVE_NEXT_LOT_LOADED_ACTION) => {
            const existing = cloneDeep(state.byId);
            const { catalogId } = action.payload;

            // Expire old hovers when the lot opens
            const currentCatalog = existing[catalogId] || { catalogId, hovers: {} };
            const editableHovers = getHoversThatAreNotExpired(currentCatalog);

            currentCatalog.hovers = editableHovers;
            existing[catalogId] = currentCatalog;
            return {
                ...state,
                byId: existing,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.hoverCount;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getHovers = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || { catalogId: id, hovers: {} }
);
