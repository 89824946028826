import { AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { Dispatch } from 'redux';
import { GlobalState } from '@/redux/rootReducer';
import { createAsyncThunk as toolkitCreateThunk } from '@reduxjs/toolkit';

// NOTE: This is copied from redux-toolkit, because it is not exported.
// We shouldn't need to touch this often, but this will serve as a reference point, just in case
type AsyncThunkConfig = {
    dispatch?: Dispatch;
    extra?: unknown;
    fulfilledMeta?: unknown;
    pendingMeta?: unknown;
    rejectValue?: unknown;
    rejectedMeta?: unknown;
    serializedErrorType?: unknown;
    state?: unknown;
};

export type AsyncThunkBaseConfig = AsyncThunkConfig & {
    state: GlobalState;
};

export type AsyncThunkConfigWithRejectValue = AsyncThunkBaseConfig & {
    rejectValue: Error;
};

export function createAsyncThunk<Returned, Input = void, Config extends AsyncThunkConfig = { state: GlobalState }>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, Input, Config>,
    options?: AsyncThunkOptions<Input, Config>
): AsyncThunk<Returned, Input, Config> {
    return toolkitCreateThunk<Returned, Input, Config>(typePrefix, payloadCreator, options);
}
