import { createSelector } from '@reduxjs/toolkit';
import { getBidderPaddleNumber as getBidderPaddleNumberApi } from '../api/clerk';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_BIDDER_PADDLE_NUMBER_FAIL,
    LOAD_BIDDER_PADDLE_NUMBER_FAIL_ACTION,
    LOAD_BIDDER_PADDLE_NUMBER_REQUEST,
    LOAD_BIDDER_PADDLE_NUMBER_REQUEST_ACTION,
    LOAD_BIDDER_PADDLE_NUMBER_SUCCESS,
    LOAD_BIDDER_PADDLE_NUMBER_SUCCESS_ACTION,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME_ITEM = ms('20s');

// reducer
export const DEFAULT_STATE = {
    byId: {},
    loaded: {},
    loading: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_BIDDER_PADDLE_NUMBER_FAIL]: (state: State, action: LOAD_BIDDER_PADDLE_NUMBER_FAIL_ACTION) => ({
            ...state,
            loading: difference(state.loading, [action.meta.itemId]),
        }),
        [LOAD_BIDDER_PADDLE_NUMBER_REQUEST]: (state: State, action: LOAD_BIDDER_PADDLE_NUMBER_REQUEST_ACTION) => ({
            ...state,
            loading: union(state.loading, [action.payload.itemId]),
        }),
        [LOAD_BIDDER_PADDLE_NUMBER_SUCCESS]: (state: State, action: LOAD_BIDDER_PADDLE_NUMBER_SUCCESS_ACTION) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const { actionTime: time, itemId } = action.meta;

            existing[itemId] = { ...action.payload };
            loaded[itemId] = time;
            loading = difference(loading, [itemId]);

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.bidderPaddleNumber;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getBidderPaddleNumber = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetch = createSelector([idSelector, getLoadTime, isLoading], (itemId, loaded, loading) => {
    if (!itemId) {
        return false;
    }
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME_ITEM) {
        return false;
    }
    return !loading;
});

/* ACTION CREATORS */
export const fetchBidderPaddleNumberIfNeeded =
    (catalogId: number, itemId: number, soldAssignedId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        if (shouldFetch(state, itemId)) {
            try {
                const deployment = getDeployment(state);
                dispatch({
                    payload: { catalogId, itemId },
                    type: LOAD_BIDDER_PADDLE_NUMBER_REQUEST,
                });
                const response = await getBidderPaddleNumberApi({ catalogId, deployment, itemId, soldAssignedId });
                if (response) {
                    dispatch({
                        meta: { actionTime: Date.now(), catalogId, itemId },
                        payload: response,
                        type: LOAD_BIDDER_PADDLE_NUMBER_SUCCESS,
                    });
                }
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { catalogId, itemId },
                    payload: error,
                    type: LOAD_BIDDER_PADDLE_NUMBER_FAIL,
                });
            }
        } else {
            return Promise.resolve({});
        }
    };
