import { createSelector } from '@reduxjs/toolkit';
import { fetchCatalog, getCatalog } from './catalog';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { postChangeStartTime } from '../api/catalog';
import {
    SEND_CHANGE_CATALOG_START_TIME_FAILURE,
    SEND_CHANGE_CATALOG_START_TIME_FAILURE_ACTION,
    SEND_CHANGE_CATALOG_START_TIME_REQUEST,
    SEND_CHANGE_CATALOG_START_TIME_REQUEST_ACTION,
    SEND_CHANGE_CATALOG_START_TIME_SUCCESS,
    SEND_CHANGE_CATALOG_START_TIME_SUCCESS_ACTION,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [SEND_CHANGE_CATALOG_START_TIME_FAILURE]: (
            state: State,
            action: SEND_CHANGE_CATALOG_START_TIME_FAILURE_ACTION
        ) => {
            const { catalogId } = action.meta;
            const error = action.payload;
            const existing = cloneDeep(state.byId);
            existing[catalogId] = existing[catalogId] || {};
            existing[catalogId].error = error;
            existing[catalogId].submitted = false;
            existing[catalogId].success = false;

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_CHANGE_CATALOG_START_TIME_REQUEST]: (
            state: State,
            action: SEND_CHANGE_CATALOG_START_TIME_REQUEST_ACTION
        ) => {
            const { catalogId } = action.payload;
            const existing = cloneDeep(state.byId);
            existing[catalogId] = existing[catalogId] || {};
            existing[catalogId].error = null;
            existing[catalogId].submitted = true;
            existing[catalogId].success = false;

            return {
                ...state,
                byId: existing,
            };
        },
        [SEND_CHANGE_CATALOG_START_TIME_SUCCESS]: (
            state: State,
            action: SEND_CHANGE_CATALOG_START_TIME_SUCCESS_ACTION
        ) => {
            const { catalogId } = action.payload;
            const existing = cloneDeep(state.byId);
            existing[catalogId] = existing[catalogId] || {};
            existing[catalogId].error = null;
            existing[catalogId].submitted = false;
            existing[catalogId].success = true;

            return {
                ...state,
                byId: existing,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.changeStartTime;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getChangeStartTimeState = createSelector(
    [byIdSelector, idSelector],
    (byId, id) =>
        byId[id] || {
            error: null,
            submitted: false,
            success: false,
        }
);

/* ACTION CREATORS */
export const submitChangeStartTime =
    (catalogId: number, newStartTs: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const { sellerId } = getCatalog(state, catalogId);

            dispatch({
                payload: { catalogId, newStartTs },
                type: SEND_CHANGE_CATALOG_START_TIME_REQUEST,
            });
            const response = await postChangeStartTime({ authToken, catalogId, deployment, newStartTs, sellerId });
            dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_CHANGE_CATALOG_START_TIME_SUCCESS,
            });
            dispatch(fetchCatalog(catalogId));
        } catch (error) {
            dispatch({
                error: true,
                meta: { actionTime: Date.now(), catalogId },
                payload: error,
                type: SEND_CHANGE_CATALOG_START_TIME_FAILURE,
            });
        }
    };
