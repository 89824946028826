import * as api from '../api/catalog';
import { ActionWithPayload } from '@/types/redux';
import { CatalogRegistrationCount } from '@/types/CatalogRegistrationCount';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from '../modules/config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_CATALOG_REGISTRATION_COUNT_FAIL,
    LOAD_CATALOG_REGISTRATION_COUNT_REQUEST,
    LOAD_CATALOG_REGISTRATION_COUNT_SUCCESS,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type CatalogRegistrationCountState = {
    byCatalogId: {
        [catalogId: number]: CatalogRegistrationCount;
    };
    loaded: {
        [catalogId: number]: number;
    };
    loading: number[];
};

export const DEFAULT_STATE = {
    byCatalogId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_CATALOG_REGISTRATION_COUNT_FAIL]: (
            state: CatalogRegistrationCountState,
            action: ActionWithPayload<{}, { catalogId: number }>
        ) => ({
            ...state,
            loading: difference(state.loading, [action.meta.catalogId]),
        }),
        [LOAD_CATALOG_REGISTRATION_COUNT_REQUEST]: (
            state: CatalogRegistrationCountState,
            action: ActionWithPayload<number>
        ) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_CATALOG_REGISTRATION_COUNT_SUCCESS]: (
            state: CatalogRegistrationCountState,
            action: ActionWithPayload<{ registrationCounts: CatalogRegistrationCount }, { actionTime: number }>
        ) => {
            const existing = cloneDeep(state.byCatalogId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            if (action.payload.registrationCounts) {
                existing[action.payload.registrationCounts.catalogId] = { ...action.payload.registrationCounts };
                loaded[action.payload.registrationCounts.catalogId] = time;
                loading = difference(loading, [action.payload.registrationCounts.catalogId]);
            }
            return {
                ...state,
                byCatalogId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.catalogRegistrationCount;
const idSelector = (_: GlobalState, id: keyof CatalogRegistrationCountState['byCatalogId']) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getCatalogRegistrationCount = createSelector(
    [byIdSelector, idSelector],
    (byCatalogId, id) =>
        byCatalogId[id] || {
            approved: 0,
            blockedByAuctioneer: 0,
            catalogId: id,
            declined: 0,
            limited: 0,
            pending: 0,
            suspended: 0,
            total: 0,
        }
);

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetch = (state: GlobalState, catalogId: keyof CatalogRegistrationCountState['byCatalogId']) => {
    if (!catalogId) {
        return false;
    }
    const data = getCatalogRegistrationCount(state, catalogId);
    if (data) {
        const loaded = getLoadTime(state, catalogId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state, catalogId);
    return !loading;
};

/* ACTION CREATORS */
const fetchCatalogRegistrationCounts = (catalogId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            payload: catalogId,
            type: LOAD_CATALOG_REGISTRATION_COUNT_REQUEST,
        });
        const response = await api.fetchRegistrationCount({ authToken, catalogId, deployment });
        return dispatch({
            meta: { actionTime: Date.now(), catalogId },
            payload: response.data,
            type: LOAD_CATALOG_REGISTRATION_COUNT_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { catalogId },
            payload: error,
            type: LOAD_CATALOG_REGISTRATION_COUNT_FAIL,
        });
    }
};

export const fetchCatalogRegistrationCountsIfNeeded =
    (catalogId: number) => async (dispatch: Function, getState: Function) => {
        const needed = shouldFetch(getState(), catalogId);
        if (needed) {
            return dispatch(fetchCatalogRegistrationCounts(catalogId));
        }
        return Promise.resolve();
    };
