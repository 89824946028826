import Cookies from 'universal-cookie';

const cookie = new Cookies();

const TWENTYFOUR_HOURS = 86400 * 1000;
const TWO_HOURS = 7200 * 1000;

export const setAuthCookie = (authToken: string, expires: Date) => {
    cookie.set('auctioneer-auth', authToken, {
        domain: '.liveauctioneers.com',
        expires,
        path: '/',
    });
};

export const setAmcStaffCookie = (amcToken: string) => {
    cookie.set('amc_staff', amcToken, {
        domain: '.liveauctioneers.com',
        expires: new Date(Date.now() + TWENTYFOUR_HOURS),
        path: '/',
    });
};

export const setSessionKeyCookie = (sessionKey: string) => {
    // Legacy mainhost security cookie name for auctioneer logins
    cookie.set('la_ah_867', sessionKey, {
        domain: '.liveauctioneers.com',
        expires: new Date(Date.now() + TWO_HOURS),
        path: '/',
    });
};

export const unsetCookies = () => {
    cookie.remove('auctioneer-auth', { domain: '.liveauctioneers.com', path: '/' });
    cookie.remove('admin-impersonated-house', { domain: '.liveauctioneers.com', path: '/' });
    cookie.remove('amc_staff', { domain: '.liveauctioneers.com', path: '/' });
    cookie.remove('la_ah_867', { domain: '.liveauctioneers.com', path: '/' });
};
