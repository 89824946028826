import * as apis from '../api/remote';
import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { getUserMediaConstraints } from './stream';
import { handleActions } from 'redux-actions';
import WrtcAdapter from '@liveauctioneers/webrtc-js';
const { startPublish, stopPublish } = apis;

const WEBSOCKET_URL = 'wss://cast.liveauctioneers.com:5443/WebRTCAppEE/websocket';
const PUBLISH_API_TIMEOUT = 10000;

/* Action Types */
export const STARTING_PUBLISH = 'STARTING_PUBLISH';
export const PUBLISH_STARTED = 'PUBLISH_STARTED';
export const STOP_PUBLISH = 'STOP_PUBLISH';
export const PUBLISH_ERROR = 'PUBLISH_ERROR';
export const RECONNECTING = 'RECONNECTING';

export const DEFAULT_STATE = {
    error: null,
    publishing: false,
    reconnecting: false,
    shouldReconnect: false,
    startingPublish: false,
    streamId: null,
};

export type State = typeof DEFAULT_STATE & {
    error: string | null | undefined;
    streamId: string | null | undefined;
};

export const reducer = handleActions(
    {
        [PUBLISH_ERROR]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.payload.error,
            publishing: false,
            startingPublish: false,
            streamId: null,
        }),
        [PUBLISH_STARTED]: (state: State) => ({
            ...state,
            publishing: true,
            reconnecting: false,
        }),
        [RECONNECTING]: (state: State) => ({
            ...state,
            reconnecting: true,
        }),
        [STARTING_PUBLISH]: (state: State, action: ActionWithPayload<{ streamId: string }>) => ({
            ...state,
            shouldReconnect: true,
            startingPublish: true,
            streamId: action.payload.streamId,
        }),
        [STOP_PUBLISH]: (state: State) => ({
            ...state,
            publishing: false,
            shouldReconnect: false,
            startingPublish: false,
            streamId: null,
        }),
    },
    DEFAULT_STATE
);

const selector = (s) => s.publish;
export const isPublishing = createSelector(selector, (s) => s.publishing);
export const isStartingPublish = createSelector(selector, (s) => s.startingPublish);
export const getPublishError = createSelector(selector, (s) => s.error);
export const getShouldReconnect = createSelector(selector, (s) => s.shouldReconnect);
export const getStreamId = createSelector(selector, (s) => s.streamId);
export const isReconnecting = createSelector(selector, (s) => s.reconnecting);

let wrtcAdapter = null;
export const startBroadcast =
    ({ audioInput, catalogId, resolution, videoInput }: any) =>
    async (dispatch: Function, getState: Function) => {
        if (wrtcAdapter) {
            console.log('stopping previous stream...');
            await stopBroadcast(catalogId)(dispatch, getState);
            wrtcAdapter = null;
        }

        try {
            const streamId = `catalog-${catalogId}-${Date.now()}`;
            dispatch({ payload: { streamId }, type: STARTING_PUBLISH });

            const constraints = getUserMediaConstraints({ audioInput, resolution, videoInput });

            if (!wrtcAdapter) {
                wrtcAdapter = new WrtcAdapter({
                    callback: adapterCallback({ audioInput, catalogId, dispatch, getState, resolution, videoInput }),
                    callbackError: adapterCallbackError({
                        audioInput,
                        catalogId,
                        dispatch,
                        getState,
                        resolution,
                        videoInput,
                    }),
                    debug: true,
                    localVideoId: 'videoStreamPreview',
                    mediaConstraints: constraints,
                    sdp_constraints: { OfferToReceiveAudio: false, OfferToReceiveVideo: false },
                    websocket_url: WEBSOCKET_URL,
                });
            } else {
                startBroadcastOnMediaServer(streamId, catalogId, dispatch);
            }
        } catch (error) {
            dispatch({ payload: { error }, type: PUBLISH_ERROR });
            console.log(error);
        }
    };

export const stopBroadcast = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const streamId = getStreamId(state);
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        if (wrtcAdapter) {
            wrtcAdapter.leave(streamId);
        }

        await dispatch({ type: STOP_PUBLISH });

        await stopPublish({ authToken, catalogId, deployment, streamId });
    } catch (error) {
        console.error('could not stop publishing', error);
    }
};

let reconnectTimeout = null;
let timeoutHandle = null;

const reconnect = ({ audioInput, catalogId, dispatch, resolution, videoInput }) => {
    dispatch({ type: RECONNECTING });
    reconnectTimeout = setTimeout(() => {
        console.log('reconnecting');
        dispatch(startBroadcast({ audioInput, catalogId, resolution, videoInput }));
    }, 5000);
};

const startBroadcastOnMediaServer = (streamId, catalogId, dispatch) => {
    wrtcAdapter.publish(streamId);
    timeoutHandle = setTimeout(() => dispatch(stopBroadcast(catalogId)), PUBLISH_API_TIMEOUT);
};

const adapterCallback =
    ({ audioInput, catalogId, dispatch, getState, resolution, videoInput }) =>
    async (info) => {
        if (reconnectTimeout) {
            clearTimeout(reconnectTimeout);
            reconnectTimeout = null;
        }
        if (info !== 'initialized' && timeoutHandle) {
            clearTimeout(timeoutHandle);
            timeoutHandle = null;
        }

        const state = getState();
        const streamId = getStreamId(state);

        if (info === 'initialized') {
            wrtcAdapter.startPinging(streamId);
            startBroadcastOnMediaServer(streamId, catalogId, dispatch);
        } else if (info === 'publish_started') {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);

                await startPublish({ authToken, catalogId, deployment, streamId });
                dispatch({ type: PUBLISH_STARTED });
            } catch (error) {
                wrtcAdapter.leave(streamId);
            }
        } else if (info === 'publish_finished') {
            dispatch({ type: STOP_PUBLISH });
        } else if (info === 'closed') {
            console.log('Disconnected from media server');
            if (getShouldReconnect(state)) {
                console.log('Restarting stream');
                reconnect({ audioInput, catalogId, dispatch, resolution, videoInput });
            }
        }
    };

const adapterCallbackError =
    ({ audioInput, catalogId, dispatch, getState, resolution, videoInput }) =>
    (error) => {
        // noStreamNameSpecified
        // console.log('Error from media server', error);
        dispatch({ payload: { error }, type: PUBLISH_ERROR });
        if (error === 'WebSocketNotConnected' && reconnectTimeout === null && getShouldReconnect(getState())) {
            console.log('Restarting stream');
            reconnect({ audioInput, catalogId, dispatch, resolution, videoInput });
        }
    };
