export const LOAD_BLOCKED_BIDDERS_FAIL = 'LOAD_BLOCKED_BIDDERS_FAIL';
export const LOAD_BLOCKED_BIDDERS_REQUEST = 'LOAD_BLOCKED_BIDDERS_REQUEST';
export const LOAD_BLOCKED_BIDDERS_SUCCESS = 'LOAD_BLOCKED_BIDDERS_SUCCESS';

export const BLOCK_BIDDER_FAIL = 'BLOCK_BIDDER_FAIL';
export const BLOCK_BIDDER_REQUEST = 'BLOCK_BIDDER_REQUEST';
export const BLOCK_BIDDER_SUCCESS = 'BLOCK_BIDDER_SUCCESS';

export const LOAD_BLOCK_BIDDER_FAIL = 'LOAD_BLOCK_BIDDER_FAIL';
export const LOAD_BLOCK_BIDDER_REQUEST = 'LOAD_BLOCK_BIDDER_REQUEST';
export const LOAD_BLOCK_BIDDER_SUCCESS = 'LOAD_BLOCK_BIDDER_SUCCESS';

export const UNBLOCK_BIDDER_FAIL = 'UNBLOCK_BIDDER_FAIL';
export const UNBLOCK_BIDDER_REQUEST = 'UNBLOCK_BIDDER_REQUEST';
export const UNBLOCK_BIDDER_SUCCESS = 'UNBLOCK_BIDDER_SUCCESS';
