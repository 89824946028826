import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_HOUSE_INVOICE_DATA_FAILURE,
    LOAD_HOUSE_INVOICE_DATA_REQUEST,
    LOAD_HOUSE_INVOICE_DATA_SUCCESS,
} from './actions';
import api from '../api/houseInvoice';

export interface State {
    readonly catalogId: number | null;
    readonly error: string | null;
    readonly houseId: number | null;
    readonly houseInvoiceData: any;
    readonly loading: boolean;
}

export const DEFAULT_STATE: State = {
    catalogId: null,
    error: undefined,
    houseId: null,
    houseInvoiceData: {},
    loading: false,
};

export const reducer = handleActions(
    {
        [LOAD_HOUSE_INVOICE_DATA_FAILURE]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [LOAD_HOUSE_INVOICE_DATA_REQUEST]: (state: State) => ({
            ...state,
            houseInvoiceData: {},
            loading: true,
        }),
        [LOAD_HOUSE_INVOICE_DATA_SUCCESS]: (state: State, action: ActionWithPayload<{}, {}>) => ({
            ...state,
            error: undefined,
            houseInvoiceData: { ...action.payload },
            loading: false,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState): State => state.houseInvoice;

export const getHouseInvoiceData = createSelector(stateSelector, (state) => state.houseInvoiceData);

export const getHouseInvoiceLoading = createSelector(stateSelector, (state) => state.loading);

export const getHouseInvoiceError = createSelector(stateSelector, (state) => state.error);

export const fetchHouseInvoiceData =
    (houseId: number, catalogId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({ type: LOAD_HOUSE_INVOICE_DATA_REQUEST });

            const response = await api.getHouseInvoiceData({
                authToken,
                catalogId,
                deployment,
                houseId,
            });

            dispatch({
                payload: response.payload,
                type: LOAD_HOUSE_INVOICE_DATA_SUCCESS,
            });
        } catch (error) {
            dispatch({ error: true, payload: error, type: LOAD_HOUSE_INVOICE_DATA_FAILURE });
        }
    };
