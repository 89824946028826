import {
    AUTO_ACCEPT_BIDS_DISABLED,
    AUTO_ACCEPT_BIDS_DISABLED_ACTION,
    AUTO_ACCEPT_BIDS_ENABLED,
    AUTO_ACCEPT_BIDS_ENABLED_ACTION,
    LIVE_ASK_CHANGED,
    LIVE_ASK_CHANGED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LIVE_LOT_CLOSED,
    LIVE_LOT_CLOSED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SKIPPED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LIVE_MISSIVE,
    LIVE_MISSIVE_ACTION,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LOAD_BIDDER_PADDLE_NUMBER_SUCCESS,
    LOAD_BIDDER_PADDLE_NUMBER_SUCCESS_ACTION,
    SEND_CHANGE_BID_INCREMENT_FAILURE,
    SEND_CHANGE_BID_INCREMENT_FAILURE_ACTION,
    SEND_CHANGE_CURRENT_ASK_FAILURE,
    SEND_CHANGE_CURRENT_ASK_FAILURE_ACTION,
    SEND_CHANGE_CURRENT_LOT_FAILURE,
    SEND_CHANGE_CURRENT_LOT_FAILURE_ACTION,
    SEND_CLOSE_AUCTION_FAILURE,
    SEND_CLOSE_AUCTION_FAILURE_ACTION,
    SEND_COMPETING_BID_FAILURE,
    SEND_COMPETING_BID_FAILURE_ACTION,
    SEND_INTERNET_BID_FAILURE,
    SEND_INTERNET_BID_FAILURE_ACTION,
    SEND_LOT_PASSED_FAILURE,
    SEND_LOT_PASSED_FAILURE_ACTION,
    SEND_LOT_REOPEN_FAILURE,
    SEND_LOT_REOPEN_FAILURE_ACTION,
    SEND_LOT_SKIPPED_FAILURE,
    SEND_LOT_SKIPPED_FAILURE_ACTION,
    SEND_LOT_SOLD_FAILURE,
    SEND_LOT_SOLD_FAILURE_ACTION,
    SEND_MISSIVE_FAILURE,
    SEND_MISSIVE_FAILURE_ACTION,
    SEND_OPEN_AUCTION_FAILURE,
    SEND_OPEN_AUCTION_FAILURE_ACTION,
    SEND_OPEN_NEXT_LOT_FAILURE,
    SEND_OPEN_NEXT_LOT_FAILURE_ACTION,
    SEND_PAUSE_AUCTION_FAILURE,
    SEND_PAUSE_AUCTION_FAILURE_ACTION,
    SEND_RESUME_AUCTION_FAILURE,
    SEND_RESUME_AUCTION_FAILURE_ACTION,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';

/* helpers */
const addMessage = (messages, newMessages) => {
    messages = [...messages];
    if (Array.isArray(newMessages)) {
        newMessages.forEach((message) => doAddMessage(messages, message));
    } else {
        doAddMessage(messages, newMessages);
    }

    if (messages.length > 250) {
        messages = messages.splice(messages.length - 50, 50);
    }
    return messages;
};

const doAddMessage = (messages, message) => {
    messages.push(message);
    messages.sort((a, b) => a.created - b.created);
};

const cannotChangeLotBecauseStatusRegex = /CSL: Unable to skip current lot (.*)(\(.*), current status: (.*)/;
const cannotChangeLotNoMatchRegex = /Failed to set lot, unable to find a valid match for/;
const cannotSellLotBecauseRegex =
    /error selling lot (\d*) for (\d*) to bidder (\d*) with assignedId (\d*) in auction (\d*) -- Lot status not changed to (.*), current state: (.*)/;

const invalidParamsError = /Invalid Parameters - (.*)/;

/* reducer */
export const DEFAULT_STATE = {
    byId: {},
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [AUTO_ACCEPT_BIDS_DISABLED]: (state: State, action: AUTO_ACCEPT_BIDS_DISABLED_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'auto-accept-bids-disabled',
                    }),
                },
            };
        },
        [AUTO_ACCEPT_BIDS_ENABLED]: (state: State, action: AUTO_ACCEPT_BIDS_ENABLED_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'auto-accept-bids-enabled',
                    }),
                },
            };
        },
        [LIVE_ASK_CHANGED]: (state: State, action: LIVE_ASK_CHANGED_ACTION) => {
            const { ask, catalogId, created, currency } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { ask, currency },
                        type: 'ask-changed',
                    }),
                },
            };
        },
        [LIVE_BID_RETRACTED]: (state: State, action: LIVE_BID_RETRACTED_ACTION) => {
            const { catalogId, created } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {},
                        type: 'retracted',
                    }),
                },
            };
        },
        [LIVE_LOT_CLOSED]: (state: State, action: LIVE_LOT_CLOSED_ACTION) => {
            const { catalogId, created, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            itemId,
                            lotNumber,
                        },
                        type: 'closed',
                    }),
                },
            };
        },
        [LIVE_LOT_PASSED]: (state: State, action: LIVE_LOT_PASSED_ACTION) => {
            const { catalogId, created, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'passed',
                    }),
                },
            };
        },
        [LIVE_LOT_REOPENED]: (state: State, action: LIVE_LOT_REOPENED_ACTION) => {
            const { catalogId, created, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'reopened',
                    }),
                },
            };
        },
        [LIVE_LOT_SKIPPED]: (state: State, action: LIVE_LOT_SKIPPED_ACTION) => {
            const { catalogId, created, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'skipped',
                    }),
                },
            };
        },
        [LIVE_LOT_SOLD]: (state: State, action: LIVE_LOT_SOLD_ACTION) => {
            const { amount, assignedId, bidderId, catalogId, created, currency, itemId, lotNumber, myBid, source } =
                action.payload;

            const existing = state.byId[catalogId] || [];

            let messages = addMessage(existing, {
                created,
                data: {
                    amount,
                    assignedId,
                    bidderId,
                    currency,
                    itemId,
                    lotNumber,
                    myBid,
                    source: bidderId === 0 ? 'Floor' : source, // TODO: do we need source to come back in these messages?
                },
                type: 'sold',
            });

            if (assignedId) {
                messages = addMessage(messages, {
                    created: created + 1,
                    data: { itemId, lotNumber, soldAssignedId: assignedId },
                    type: 'paddle-number-2',
                });
            }
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: messages,
                },
            };
        },
        [LIVE_LOT_UNSOLD]: (state: State, action: LIVE_LOT_UNSOLD_ACTION) => {
            const { catalogId, created, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'unsold',
                    }),
                },
            };
        },
        [LIVE_MISSIVE]: (state: State, action: LIVE_MISSIVE_ACTION) => {
            const { catalogId, created, itemId, missiveText } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, missiveText },
                        text: missiveText,
                        type: 'missive',
                    }),
                },
            };
        },
        [LIVE_NEXT_LOT_LOADED]: (state: State, action: LIVE_NEXT_LOT_LOADED_ACTION) => {
            const { askPrice, catalogId, created, currency, itemId, lotNumber } = action.payload;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: askPrice,
                            currency,
                            itemId,
                            lotNumber,
                        },
                        type: 'opened',
                    }),
                },
            };
        },
        [LOAD_BIDDER_PADDLE_NUMBER_SUCCESS]: (state: State, action: LOAD_BIDDER_PADDLE_NUMBER_SUCCESS_ACTION) => {
            const { bid_master_id, lot_num, paddle_number } = action.payload;
            const { actionTime, catalogId, itemId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: { bid_master_id, itemId, lot_num, paddle_number },
                        type: 'paddle-number',
                    }),
                },
            };
        },
        [SEND_CHANGE_BID_INCREMENT_FAILURE]: (state: State, action: SEND_CHANGE_BID_INCREMENT_FAILURE_ACTION) => {
            const { actionTime, catalogId, currency, increment, lotNumber } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: { amount: increment, currency, lotNumber },
                        type: 'change-bid-increment-fail',
                    }),
                },
            };
        },
        [SEND_CHANGE_CURRENT_ASK_FAILURE]: (state: State, action: SEND_CHANGE_CURRENT_ASK_FAILURE_ACTION) => {
            const { actionTime, ask, catalogId, currency, lotNumber } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: { amount: ask, currency, lotNumber },
                        type: 'change-current-ask-fail',
                    }),
                },
            };
        },
        [SEND_CHANGE_CURRENT_LOT_FAILURE]: (state: State, action: SEND_CHANGE_CURRENT_LOT_FAILURE_ACTION) => {
            const { actionTime, catalogId, lotNumber } = action.meta;
            const { msg: error } = action.payload;

            let extra = '';
            const statusMatches = error.match(cannotChangeLotBecauseStatusRegex);
            if (statusMatches && statusMatches[1] && statusMatches[3]) {
                if (statusMatches[3] === 'K_BID_EVENT_LOT_SOLD') {
                    extra = `Unable to skip to lot (${statusMatches[1]}) as it was hammered as SOLD.`;
                } // Do we want to add more cases here?
            } else {
                const validMatches = error.match(cannotChangeLotNoMatchRegex);
                if (validMatches) {
                    extra = 'Unable to find a valid match.';
                }
            }

            if (extra === '') {
                const invalidParams = error.match(invalidParamsError);
                if (invalidParams && invalidParams[1]) {
                    extra = `${invalidParams[1]}`;
                }
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            extra,
                            lotNumber,
                        },
                        type: 'change-current-lot-fail',
                    }),
                },
            };
        },
        [SEND_CLOSE_AUCTION_FAILURE]: (state: State, action: SEND_CLOSE_AUCTION_FAILURE_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            catalogId,
                        },
                        type: 'close-auction-fail',
                    }),
                },
            };
        },
        [SEND_COMPETING_BID_FAILURE]: (state: State, action: SEND_COMPETING_BID_FAILURE_ACTION) => {
            const { actionTime, amount, catalogId, currency, lotNumber } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            amount,
                            currency,
                            lotNumber,
                        },
                        type: 'competing-bid-fail',
                    }),
                },
            };
        },
        [SEND_INTERNET_BID_FAILURE]: (state: State, action: SEND_INTERNET_BID_FAILURE_ACTION) => {
            const { actionTime, amount, catalogId, currency, lotNumber } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            amount,
                            currency,
                            lotNumber,
                        },
                        type: 'internet-bid-fail',
                    }),
                },
            };
        },
        [SEND_LOT_PASSED_FAILURE]: (state: State, action: SEND_LOT_PASSED_FAILURE_ACTION) => {
            const { actionTime, catalogId, currentState, lotNumber } = action.meta;

            let extra = '';

            if (extra === '' && currentState) {
                extra = 'Updated the console status, try again.';
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            extra,
                            lotNumber,
                        },
                        type: 'lot-passed-fail',
                    }),
                },
            };
        },
        [SEND_LOT_REOPEN_FAILURE]: (state: State, action: SEND_LOT_REOPEN_FAILURE_ACTION) => {
            const { actionTime, catalogId, currentState, lotNumber } = action.meta;

            let extra = '';

            if (extra === '' && currentState) {
                extra = 'Updated the console status, try again.';
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            extra,
                            lotNumber,
                        },
                        type: 'lot-reopened-fail',
                    }),
                },
            };
        },
        [SEND_LOT_SKIPPED_FAILURE]: (state: State, action: SEND_LOT_SKIPPED_FAILURE_ACTION) => {
            const { actionTime, catalogId, currentState, lotNumber } = action.meta;

            let extra = '';

            if (extra === '' && currentState) {
                extra = 'Updated the console status, try again.';
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            extra,
                            lotNumber,
                        },
                        type: 'lot-skipped-fail',
                    }),
                },
            };
        },
        [SEND_LOT_SOLD_FAILURE]: (state: State, action: SEND_LOT_SOLD_FAILURE_ACTION) => {
            const { actionTime, catalogId, currentState, lotNumber } = action.meta;
            const { msg: error } = action.payload;

            let extra = '';
            const matches = error.match(cannotSellLotBecauseRegex);
            // matches[7] is the reason in the regex matches
            if (matches && matches[7]) {
                if (matches[7] === 'K_BID_EVENT_LOT_SOLD') {
                    extra = 'Unable to sell current lot as it is marked as SOLD.';
                } // Do we want to add more cases here?
            }

            if (extra === '' && currentState) {
                extra = 'Updated the console status, try again.';
            }

            if (extra === '') {
                const invalidParams = error.match(invalidParamsError);
                if (invalidParams && invalidParams[1]) {
                    extra = `${invalidParams[1]}`;
                }
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {
                            extra,
                            lotNumber,
                        },
                        type: 'lot-sold-fail',
                    }),
                },
            };
        },
        [SEND_MISSIVE_FAILURE]: (state: State, action: SEND_MISSIVE_FAILURE_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'send-missive-fail',
                    }),
                },
            };
        },
        [SEND_OPEN_AUCTION_FAILURE]: (state: State, action: SEND_OPEN_AUCTION_FAILURE_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'open-auction-fail',
                    }),
                },
            };
        },
        [SEND_OPEN_NEXT_LOT_FAILURE]: (state: State, action: SEND_OPEN_NEXT_LOT_FAILURE_ACTION) => {
            const { actionTime, catalogId, currentState } = action.meta;

            let extra = '';
            if (extra === '' && currentState) {
                extra = 'Updated the console status, try again.';
            }

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: { extra },
                        type: 'open-next-lot-fail',
                    }),
                },
            };
        },
        [SEND_PAUSE_AUCTION_FAILURE]: (state: State, action: SEND_PAUSE_AUCTION_FAILURE_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'pause-auction-fail',
                    }),
                },
            };
        },
        [SEND_RESUME_AUCTION_FAILURE]: (state: State, action: SEND_RESUME_AUCTION_FAILURE_ACTION) => {
            const { actionTime, catalogId } = action.meta;

            const existing = state.byId[catalogId] || [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created: actionTime,
                        data: {},
                        type: 'resume-auction-fail',
                    }),
                },
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.clerkConsoleMessages;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getClerkConsoleMessages = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);
