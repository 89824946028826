import { handleResponse, makeGet } from './helpers';

type FetchCatalogBiddingParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const fetchCatalogBidding = ({ authToken, catalogId, deployment }: FetchCatalogBiddingParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<ITEM-API>spa/small/catalog/${catalogId}/bidding`,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type FetchItemBiddingParams = {
    authToken: string;
    deployment: string;
    itemId: number;
};

export const fetchItemBidding = ({ authToken, deployment, itemId }: FetchItemBiddingParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({ authToken, deployment, path: `<ITEM-API>spa/small/item/${itemId}/bidding` });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
