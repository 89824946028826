import { handleResponse, makePost } from './helpers';

type SubmitFeedbackParams = {
    authToken: string;
    deployment: string;
    feedbackObject: any;
};

export const submitFeedback = ({ authToken, deployment, feedbackObject }: SubmitFeedbackParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'feedback',
            authToken,
            deployment,
            path: '<TELEGRAM-API>',
        });
        request.send(feedbackObject);
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });
