import {
    fetchHouseBetaEnrollmentsIfNeeded,
    getHouseBetaEnrollmentsLoading,
} from '@/redux/modules/houseBetaEnrollments';
import { getBrowserDataFromUA } from '@/redux/modules/browser';
import { getClassicHost } from '@/redux/modules/config';
import { getUserHouseId, isAuthenticated, submitImpersonateHouse } from '@/redux/modules/user';
import { lazy, Suspense, useCallback, useEffect, useMemo } from 'react';
import { openFeedbackModal } from '@/redux/modules/modal';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from '@liveauctioneers/hammer-ui-core/toast';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import FeedbackWidget from '@liveauctioneers/caterwaul-components/lib/FeedbackWidget/FeedbackWidget';
import LapMandateNotice from '@/components/LapMandateNoticeBanner/LapMandateNotice';
import Layout from '@/components/Layout/Layout';
import MeltedMac from '@liveauctioneers/caterwaul-components/lib/ErrorBoundary/MeltedMac';
import Modals from '@/components/Modals/Modals';
import ModuleAccess from '@/components/ModuleAccess/ModuleAccess';
import NavigationBar from '@/components/NavMenu/NavigationBar';
import OpenReplay from '@/components/OpenReplay/OpenReplay';
import PrivateRoute from '@/components/PrivateRoute/PrivateRoute';
import SegmentScript from '@/components/Segment/SegmentScript';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';
import useAuthenticateSession from '@/hooks/useAuthenticateSession';
import useBrowserAgent from '@/hooks/useBrowserAgent';
import '@liveauctioneers/hammer-ui-theme/css/LiveAuctioneers.css';

const AuctioneerCarouselPage = lazy(() => import('../AuctioneerCarousel/AuctioneerCarouselPage'));
const AuctioneerConsolePage = lazy(() => import('../AuctioneerConsole/AuctioneerConsolePage'));
const AuctionPreviewPage = lazy(() => import('../AuctionPreview/AuctionPreviewPage'));
const BiddersPage = lazy(() => import('../Bidders/ManualApproval/BiddersPageContainer'));
const BidderProfilePage = lazy(() => import('../BidderProfile/BidderProfilePageContainer'));
const BNAReportPage = lazy(() => import('../BNAReport/BNAReport'));
const CatalogPaymentPage = lazy(() => import('../CatalogPayment/CatalogPaymentPageContainer'));
const CatalogPerformancePage = lazy(() => import('../CatalogPerformance/CatalogPerformancePage'));
const CatalogsPage = lazy(() => import('../Catalogs/CatalogsPage'));
const CatalogPrintPage = lazy(() => import('../CatalogPrint/CatalogPrintPage'));
const ClerkConsolePage = lazy(() => import('../ClerkConsole/ClerkConsolePage'));
const DashboardPage = lazy(() => import('../Dashboard/DashboardPage'));
const EmailMarketingPage = lazy(() => import('../EmailMarketing/MarketingPage'));
const CampaignsListPage = lazy(() => import('../EmailMarketing/CampaignsPage'));
const CampaignPage = lazy(() => import('../Campaign/CampaignPage'));
const GuaranteedBiddersTermsPage = lazy(() => import('../GuaranteedBiddersTerms/GuaranteedBiddersTermsPage'));
const BidderGuaranteeClaimsProcessPage = lazy(
    () => import('../BidderGuaranteeClaimsProcess/BidderGuaranteeClaimsProcessPage')
);
const EditLotPage = lazy(() => import('../LiveAuction/EditLot/EditLotPage'));
const ForgotPasswordPage = lazy(() => import('../ForgotPassword/ForgotPasswordPage'));
const LoginPage = lazy(() => import('../Login/LoginPage'));
const LotManagerPage = lazy(() => import('../LotManager/LotManagerPage'));
const MessagesPage = lazy(() => import('../Messages/MessagesPage'));
const MessengerPage = lazy(() => import('../Messages/MessengerPage'));
const NotFoundPage = lazy(() => import('../NotFound/NotFoundPageContainer'));
const PaymentsPage = lazy(() => import('../Payments/PaymentsPage'));
const InvoicePage = lazy(() => import('../Invoices/InvoicePage'));
const AtgInvoicePage = lazy(() => import('../Invoices/AtgInvoiceProvider'));
const PayrixOnboardingPage = lazy(() => import('../PaymentsOnboarding/PayrixOnboardingPage'));
const PerformanceCatalogsListPage = lazy(() => import('../PerformanceCatalogsList/PerformancePage'));
const PromotedLotsPage = lazy(() => import('../PromotedLotsV2/PromotedLotsContainer'));
const RatingsPage = lazy(() => import('../Ratings/RatingsPageContainer'));
const ResetPasswordPage = lazy(() => import('../ResetPassword/ResetPasswordPageContainer'));
const StreamPage = lazy(() => import('../Stream/StreamPageContainer'));
const UpcomingCatalogListPage = lazy(() => import('../UpcomingCatalogList/UpcomingCatalogListPage'));
const VideoSetupPage = lazy(() => import('../VideoSetup/VideoSetupPage'));
const AccountAndBilling = lazy(() => import('../AccountAndBilling/AccountAndBillingPageContainer'));
const PaymentConfirmation = lazy(() => import('../PaymentConfirmation/PaymentConfirmationPage'));
const ShippingPage = lazy(() => import('../Shipping/ShippingPage'));

const App = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = window.location;
    const { pathname } = location;

    const classicHost = useAppSelector(getClassicHost);
    const userHouseId = useAppSelector(getUserHouseId);
    const authed = useAppSelector(isAuthenticated);

    // authenticates user and sets up session cookies and redux state
    useAuthenticateSession();
    // sets browser object in redux store with current request navigator.agent
    useBrowserAgent();

    /**
     * extractHouseIdFromPathname
     * if the first URI REST field matches on of the houseId specifiers,
     * the 2nd URI REST field is the urlHouseId
     * todo: migrate this routine to util lib
     * todo: homogenize/redirect all houseId URI specifiers to 'house'
     */
    const urlHouseId = useMemo(() => {
        const splits = pathname.split('/');
        const houseIdUriFields = ['house', 'upcoming-catalog-list', 'bnareport', 'ratings'];
        return houseIdUriFields.includes(splits[1]) ? Number(splits[2]) : 0;
    }, [pathname]);

    /**
     * if URL houseId does not match userHouseId, check if they
     * can impersonate house and continue access
     */
    useEffect(() => {
        if (userHouseId !== urlHouseId && urlHouseId !== 0) {
            dispatch(submitImpersonateHouse(urlHouseId));
        }
    }, [dispatch, userHouseId, urlHouseId]);

    /**
     * urlHouseId may be empty if 404 page, fall back to userHouseId
     */
    const accessHouseId = urlHouseId || userHouseId;

    const showHeaderAndFooter = useMemo(() => {
        return (
            !pathname.includes('clerk-console') &&
            !pathname.includes('auctioneer-carousel') &&
            !pathname.includes('auctioneer-console') &&
            !pathname.includes('bnareport') &&
            !pathname.includes('stream') &&
            !pathname.includes('payments/onboarding')
        );
    }, [pathname]);

    const showFeedbackWidget = useMemo(() => {
        return !pathname.includes('clerk-console') && !pathname.includes('auctioneer-carousel');
    }, [pathname]);

    const handleFeedbackModal = useCallback(() => {
        dispatch(openFeedbackModal());
    }, [dispatch]);

    useEffect(() => {
        if (authed && accessHouseId) {
            dispatch(fetchHouseBetaEnrollmentsIfNeeded(accessHouseId));
        }
    }, [accessHouseId, authed, dispatch]);

    const userAgent = navigator.userAgent || '';
    const browser = getBrowserDataFromUA(userAgent);
    if (browser.isOutdated) {
        return <MeltedMac outdated />;
    }

    if (pathname.startsWith('/ad-content-error')) {
        return <MeltedMac adContentError />;
    }

    return (
        <div>
            <OpenReplay />
            <SegmentScript />
            <ToastContainer />
            <FeedbackWidget
                onClick={handleFeedbackModal}
                showWidget={showFeedbackWidget}
            />
            <LapMandateNotice houseId={userHouseId} />
            <Layout
                houseId={userHouseId}
                showHeaderAndFooter={showHeaderAndFooter}
            >
                <NavigationBar
                    classicHost={classicHost}
                    houseId={accessHouseId}
                    pathname={pathname}
                />
                <ModuleAccess
                    houseId={accessHouseId}
                    pathname={pathname}
                >
                    <Suspense fallback={<Throbber center />}>
                        <Routes>
                            <Route
                                element={<LoginPage />}
                                index={!Boolean(userHouseId)}
                            />
                            <Route
                                element={<LoginPage />}
                                path="/login"
                            />
                            <Route
                                element={<DashboardPage />}
                                index={Boolean(userHouseId)}
                            />
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/dashboard"
                            >
                                <Route
                                    element={<DashboardPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<GuaranteedBiddersTermsPage />}
                                path="/GuaranteedBiddersTerms"
                            />
                            <Route
                                element={<BidderGuaranteeClaimsProcessPage />}
                                path="/BidderGuaranteeClaimsProcess"
                            />
                            <Route
                                element={<ForgotPasswordPage />}
                                path="/forgot"
                            />
                            <Route
                                element={<ResetPasswordPage />}
                                path="/password_reset/:userId/:hash"
                            />
                            <Route
                                element={<PrivateRoute />}
                                path="/auctioneer-carousel/:catalogId"
                            >
                                <Route
                                    element={<AuctioneerCarouselPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/auctioneer-console/:catalogId"
                            >
                                <Route
                                    element={<AuctioneerConsolePage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/auctionPreview/:previewId/auctioneer/:houseId"
                            >
                                <Route
                                    element={<AuctionPreviewPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/auctionPreview/auctioneer/:houseId"
                            >
                                <Route
                                    element={<AuctionPreviewPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/catalog/:catalogId/videosetup"
                            >
                                <Route
                                    element={<VideoSetupPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/catalog/:catalogId/print"
                            >
                                <Route
                                    element={<CatalogPrintPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/catalogs"
                            >
                                <Route
                                    element={<CatalogsPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/clerk-console/:catalogId"
                            >
                                <Route
                                    element={<ClerkConsolePage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/featuredLots/:catalogId"
                            >
                                <Route
                                    element={<PromotedLotsPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/ratings/:houseId"
                            >
                                <Route
                                    element={<RatingsPage location={location} />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/stream"
                            >
                                <Route
                                    element={<StreamPage location={location} />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/bnareport/:sellerId/catalog/:catalogId"
                            >
                                <Route
                                    element={<BNAReportPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/bidders/"
                            >
                                <Route
                                    element={<BiddersPage />}
                                    index
                                />
                                <Route
                                    element={<BiddersPage />}
                                    path="/house/:houseId/bidders/:catalogId"
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/marketing/email"
                            >
                                <Route
                                    element={<EmailMarketingPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/bidder/:bidderId"
                            >
                                <Route
                                    element={<BidderProfilePage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/marketing/campaign/:campaignId"
                            >
                                <Route
                                    element={<CampaignPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/marketing/"
                            >
                                <Route
                                    element={<CampaignsListPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/lot-manager/:subpage/:catalogId"
                            >
                                <Route
                                    element={<LotManagerPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/messages/:conversationId"
                            >
                                <Route
                                    element={<MessengerPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/messages/"
                            >
                                <Route
                                    element={<MessagesPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/performance/"
                            >
                                <Route
                                    element={<PerformanceCatalogsListPage />}
                                    index
                                />
                                <Route
                                    element={<CatalogPerformancePage />}
                                    path="/house/:houseId/performance/:catalogId"
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/upcoming-catalog-list/:sellerId"
                            >
                                <Route
                                    element={<UpcomingCatalogListPage />}
                                    index
                                />
                                <Route
                                    element={<UpcomingCatalogListPage />}
                                    path="/upcoming-catalog-list/:sellerId/:catalogId"
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/catalog/:catalogId/lot/:lotId/edit"
                            >
                                <Route
                                    element={<EditLotPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/payments/onboarding/payrix"
                            >
                                <Route
                                    element={<PayrixOnboardingPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/payments/"
                            >
                                <Route
                                    element={<PaymentsPage />}
                                    index
                                />
                                <Route
                                    element={<PaymentsPage />}
                                    path="/house/:houseId/payments/summary"
                                />
                                <Route
                                    element={<PaymentsPage />}
                                    path="/house/:houseId/payments/payments"
                                />
                                <Route
                                    element={<PaymentsPage />}
                                    path="/house/:houseId/payments/disbursements"
                                />
                                <Route
                                    element={<PaymentsPage />}
                                    path="/house/:houseId/payments/payments"
                                />
                                <Route
                                    element={<PaymentsPage />}
                                    path="/house/:houseId/payments/reporting"
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/guaranteedbiddersterms"
                            >
                                <Route
                                    element={<GuaranteedBiddersTermsPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/payment/:catalogId"
                            >
                                <Route
                                    element={<CatalogPaymentPage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/account-and-billing"
                            >
                                <Route
                                    element={<AccountAndBilling navigate={navigate} />}
                                    index
                                />
                                <Route
                                    element={<AccountAndBilling navigate={navigate} />}
                                    path="/house/:houseId/account-and-billing/billing"
                                />
                                <Route
                                    element={<AccountAndBilling navigate={navigate} />}
                                    path="/house/:houseId/account-and-billing/my-info"
                                />
                                <Route
                                    element={<AccountAndBilling navigate={navigate} />}
                                    path="/house/:houseId/account-and-billing/locations"
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/confirmation"
                            >
                                <Route
                                    element={<PaymentConfirmation />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/atg-invoice/:invoiceId"
                            >
                                <Route
                                    element={<AtgInvoicePage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/house/:houseId/invoice/:invoiceId"
                            >
                                <Route
                                    element={<InvoicePage />}
                                    index
                                />
                            </Route>
                            <Route
                                element={<PrivateRoute />}
                                path="/liveship"
                            >
                                <Route
                                    element={<ShippingPage />}
                                    path="/liveship/label"
                                />
                                <Route
                                    element={<ShippingPage />}
                                    path="/liveship/catalog-shipping-parser/catalog-search"
                                />
                                <Route
                                    element={<ShippingPage />}
                                    path="/liveship/catalog-shipping-parser/:catalogId"
                                />
                                <Route
                                    element={<ShippingPage />}
                                    index
                                />
                            </Route>
                            {!getHouseBetaEnrollmentsLoading && (
                                <Route
                                    element={<NotFoundPage location={location} />}
                                    path="*"
                                />
                            )}
                        </Routes>
                    </Suspense>
                </ModuleAccess>
            </Layout>
            <Modals />
        </div>
    );
};

export default App;
