import { handleResponse, makeDelete, makeGet, makePost } from './helpers';

/**
 * @category Messaging API
 * @see addAttachment
 */
type AddAttachmentParams = {
    attachments: any[];
    authToken: string;
    conversationId: number;
    deployment: string;
    draftId: number;
    houseId: number;
    messageBody: string;
};
/**
 * @category Messaging API
 * @see addAttachment
 */
export type AddAttachmentResponsePayload = {
    error: boolean;
    payload: {
        attachments: Array<{
            attachmentId: number;
            originalFilename: string;
            storedFilename: string;
            url: string;
        }>;
        bidderId: number;
        catalogId: number;
        conversationId: number;
        createdDateTime: string;
        draftId: number;
        houseId: number;
        lotId: number;
        messageBody: string;
        multiDraftErrors: string;
        recipient: string;
        subject: string;
    };
};
/**
 * Add a file(attachmentId) to a message for a houseId
 * @function addAttachment
 * @category Messaging API
 * @param {AddAttachmentParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/house/${houseId}/attach
 */
const addAttachment = ({
    attachments = [],
    authToken,
    conversationId,
    deployment,
    draftId,
    houseId,
    messageBody,
}: AddAttachmentParams) =>
    new Promise<AddAttachmentResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<MESSAGING-ATTACHMENTS-API>house/${houseId}/attach`,
        });
        attachments.forEach((file) => {
            request.attach(file.name, file);
        });
        request.field('message', JSON.stringify({ conversationId, draftId, messageBody }));
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see deleteDraftAttachment
 */
type DeleteDraftAttachmentParams = {
    attachmentId: number;
    authToken: string;
    deployment: string;
    houseId: number;
};
/**
 * @category Messaging API
 * @see deleteDraftAttachment
 */
type DeleteDraftAttachmentResponsePayload = {
    error: boolean;
    payload: string;
};
/**
 * Delete a draft attachement for a given attachmentId
 * @function deleteDraftAttachment
 * @category Messaging API
 * @param {DeleteDraftAttachmentParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/house/${houseId}/attachment/${attachmentId}
 */
const deleteDraftAttachment = ({ attachmentId, authToken, deployment, houseId }: DeleteDraftAttachmentParams) =>
    new Promise<DeleteDraftAttachmentResponsePayload>((resolve, reject) => {
        const request = makeDelete({
            authToken,
            deployment,
            path: `<MESSAGING-API>house/${houseId}/attachment/${attachmentId}`,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see fetchConversationMessages
 */
type FetchConversationMessagesParams = {
    authToken: string;
    conversationId: number;
    deployment: string;
    houseId: number;
};
/**
 * @category Messaging API
 * @see fetchConversationMessages
 */
export type FetchConversationMessagesResponsePayload = {
    error: boolean;
    payload: [
        {
            bidderId: number;
            catalogId: number;
            conversationId: number;
            houseId: number;
            lotId: number;
            messageBody: string;
            messageId: string;
            sender: {
                id: number;
                logoUrl: string;
                name: string;
                type: string;
            };
            subject: string;
            tsSent: number;
        },
    ];
};
/**
 * Get messages for a conversation for a given houseId and conversationId
 * @function fetchConversationMessages
 * @category Messaging API
 * @param {FetchConversationMessagesParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/house/${houseId}/messages
 */
const fetchConversationMessages = ({
    authToken,
    conversationId,
    deployment,
    houseId,
}: FetchConversationMessagesParams) =>
    new Promise<FetchConversationMessagesResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<MESSAGING-API>house/${houseId}/messages`,
        });
        request.query({ conversationId });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see sendConversationMessage
 */
type SendConversationMessageParams = {
    authToken: string;
    conversationId: number;
    deployment: string;
    draftId?: number;
    houseId: number;
    messageBody: any;
};
/**
 * @category Messaging API
 * @see sendConversationMessage
 */
export type SendConversationMessageResponsePayload = {
    error: boolean;
    payload: [
        {
            bidderId: number;
            bidderIds: number[];
            catalogId: number;
            conversationId: number;
            error: string;
            houseId: number;
            houseIds: number[];
            lotId: number;
            messageBody: string;
            messageId: string;
            sender: {
                id: number;
                logoUrl: string;
                name: string;
                type: string;
            };
            subject: string;
            tsSent: number;
        },
    ];
};
/**
 * Send a conversation message from a given houseId
 * @function sendConversationMessage
 * @category Messaging API
 * @param {SendConversationMessageParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/house/${houseId}/messages
 */
const sendConversationMessage = ({
    authToken,
    conversationId,
    deployment,
    draftId,
    houseId,
    messageBody,
}: SendConversationMessageParams) =>
    new Promise<SendConversationMessageResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<MESSAGING-API>house/${houseId}/messages`,
        });
        request.query({ conversationId });
        request.send({
            conversationId,
            draftId,
            messageBody,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see startConversationWithAttachment
 */
type StartConversationWithAttachmentParams = {
    attachments: Array<any>;
    authToken: string;
    bidderIds: Array<number>;
    deployment: string;
    houseId: number;
    messageBody: string;
    subject: string;
};
/**
 * @category Messaging API
 * @see startConversationWithAttachment
 */
type StartConversationWithAttachmentResponsePayload = {
    error: boolean;
    payload: [
        {
            bidderId: number;
            bidderIds: number[];
            catalogId: number;
            conversationId: number;
            error: string;
            houseId: number;
            houseIds: number[];
            lotId: number;
            messageBody: string;
            messageId: string;
            sender: {
                id: number;
                logoUrl: string;
                name: string;
                type: string;
            };
            subject: string;
            tsSent: number;
        },
    ];
};
/**
 * Start a conversation with attachment(s)
 * @function startConversationWithAttachment
 * @category Messaging API
 * @param {StartConversationWithAttachmentParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/house/${houseId}/messages
 */
const startConversationWithAttachment = ({
    attachments = [],
    authToken,
    bidderIds,
    deployment,
    houseId,
    messageBody,
    subject,
}: StartConversationWithAttachmentParams) =>
    new Promise<StartConversationWithAttachmentResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<MESSAGING-ATTACHMENTS-API>house/${houseId}/messages`,
        });
        attachments.forEach((file) => {
            request.attach(file.name, file);
        });
        request.field('message', JSON.stringify({ bidderIds, messageBody, subject }));
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export default {
    addAttachment,
    deleteDraftAttachment,
    fetchConversationMessages,
    sendConversationMessage,
    startConversationWithAttachment,
};
