import { handleResponse, makeGet } from './helpers';

type FetchStreamIdParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export const fetchStreamId = ({ authToken, catalogId, deployment }: FetchStreamIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `status/${catalogId}`,
            authToken,
            deployment,
            path: '<STREAM-MANAGER>',
        });

        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
