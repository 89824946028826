import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState } from '@/redux/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { fetchConversationFoldersIfNeeded } from './conversationFolders';
import { fetchSellersIfNeeded } from './seller';
import { getDeployment } from './config';
import { getLoginAnalytics } from './analytics';
import { getUserHouseId } from './user';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { isValidUserType } from '@/utils/user';
import { LOG_OUT, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from './actions';
import { NavigateFunction } from 'react-router';
import { postSpaAuctioneerLogin } from '../api/auth';
import { unsetCookies } from '@/utils/cookies';

export const OWNER_LOGIN_DISALLOWED = 'Owner login not allowed';

export type State = {
    error: string | null | undefined;
    isLoading: boolean;
};

/* reducer */
export const DEFAULT_STATE: State = {
    error: null,
    isLoading: false,
};

export const reducer = handleActions(
    {
        [LOGIN_FAIL]: (state: State, action: ActionWithPayload<string>): State => ({
            ...state,
            error: action.payload,
            isLoading: false,
        }),
        [LOGIN_REQUEST]: (state: State): State => ({
            ...state,
            error: null,
            isLoading: true,
        }),
        [LOGIN_SUCCESS]: (state: State): State => ({
            ...state,
            error: null,
            isLoading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.login;

export const getLoginState = createSelector(stateSelector, (state) => state);
export const isLoading = createSelector(stateSelector, (state) => state.isLoading);

/* ACTION CREATORS */
export const submitLogout = (navigate: NavigateFunction) => (dispatch: AppDispatch) => {
    unsetCookies();
    dispatch({
        type: LOG_OUT,
    });
    navigate('/login');
};

const submitLoginFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: LOGIN_FAIL,
});

export const submitLogin =
    (username: string, password: string, navigate?: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            dispatch({
                payload: username,
                type: LOGIN_REQUEST,
            });
            const response = await postSpaAuctioneerLogin({
                deployment,
                password,
                username,
            });

            if (response && response.data) {
                if (!isValidUserType(response.data.type)) {
                    return dispatch(submitLoginFail('Unauthorized'));
                } else if (response.data.userData.ownerLoginWithStaffPerms) {
                    return dispatch(submitLoginFail(OWNER_LOGIN_DISALLOWED));
                } else {
                    const sellerId =
                        response.data.userData.houseId === null ? undefined : response.data.userData.houseId;

                    await dispatch({
                        meta: {
                            ...getLoginAnalytics(response.data.userData, response.data.type),
                            actionTime: Date.now(),
                        },
                        payload: {
                            ...response.data,
                            userData: {
                                ...response.data.userData,
                                sellerId,
                            },
                        },
                        type: LOGIN_SUCCESS,
                    });

                    if (sellerId) {
                        dispatch(fetchConversationFoldersIfNeeded(sellerId));
                    }

                    const houseId = getUserHouseId(state);
                    if (houseId) {
                        dispatch(fetchSellersIfNeeded([houseId]));
                        navigate?.(`/house/${houseId}/dashboard`);
                    }
                }
                navigate?.('/');
            }
        } catch (error) {
            if (error === 'Failed to get the login.') {
                dispatch(submitLoginFail('Invalid username or password.'));
            } else if (typeof error === 'string') {
                dispatch(submitLoginFail(error));
            } else {
                dispatch(submitLoginFail('Your username or password is incorrect.'));
            }
        }
    };
