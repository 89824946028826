import { BidderProfile } from '../../types/BidderProfile';
import { handleResponse, makeGet } from './helpers';

type BidderProfileParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    houseId: number;
};

type BidderProfileResponsePayload = {
    error: boolean;
    payload: BidderProfile;
};

export default {
    getBidderProfile: ({ authToken, bidderId, deployment, houseId }: BidderProfileParams) =>
        new Promise<BidderProfileResponsePayload>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/${bidderId}/info-detail`,
            });
            request.end((err: boolean, response: BidderProfileResponsePayload) =>
                handleResponse({ err, reject, resolve, response })
            );
        }),
};
