import { ActionWithPayload } from '@/types/redux';
import {
    CREATE_ANNOUNCEMENT_FAIL,
    CREATE_ANNOUNCEMENT_REQUEST,
    CREATE_ANNOUNCEMENT_SUCCESS,
    LOAD_ANNOUNCEMENTS_FAIL,
    LOAD_ANNOUNCEMENTS_REQUEST,
    LOAD_ANNOUNCEMENTS_SUCCESS,
    REMOVE_ANNOUNCEMENT_FAIL,
    REMOVE_ANNOUNCEMENT_REQUEST,
    REMOVE_ANNOUNCEMENT_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import api from '../api/announcement';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

// const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type State = {
    byId: { [id: number]: any[] };
    loaded: { [id: number]: number };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
};

type CreateAnnouncementInput = {
    body: string;
    catalogId?: number;
    houseId: number;
    sendEmail: boolean;
    title: string;
};

export const reducer = handleActions(
    {
        /** Create an announcement */
        [CREATE_ANNOUNCEMENT_FAIL]: (state: State, action: ActionWithPayload<{}, { houseId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.houseId]),
        }),
        [CREATE_ANNOUNCEMENT_REQUEST]: (state: State, action: ActionWithPayload<{ houseId: number }, {}>) => ({
            ...state,
            // @ts-ignore
            loading: union(state.loading, action.payload.houseId),
        }),
        [CREATE_ANNOUNCEMENT_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                { announcement: any },
                { actionTime: number; announcementId: number; houseId: number }
            >
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;
            const { houseId } = action.meta;
            const currentAnnouncementsForHouse = existing[houseId] || [];

            if (action.payload) {
                existing[houseId] = [action.payload, ...currentAnnouncementsForHouse];
                loaded[houseId] = time;
                loading = difference(loading, [houseId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },

        /** Get list of announcements for house */
        [LOAD_ANNOUNCEMENTS_FAIL]: (state: State, action: ActionWithPayload<{}, { houseId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.houseId]),
        }),
        [LOAD_ANNOUNCEMENTS_REQUEST]: (state: State, action: ActionWithPayload<{ houseId: number }, {}>) => ({
            ...state,
            // @ts-ignore
            loading: union(state.loading, action.payload.houseId),
        }),
        [LOAD_ANNOUNCEMENTS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ announcements: any[] }, { actionTime: number; houseId: number }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;
            const { houseId } = action.meta;

            if (Array.isArray(action.payload)) {
                existing[houseId] = action.payload;
                loaded[houseId] = time;
                loading = difference(loading, [houseId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },

        /** Delete an announcement */
        [REMOVE_ANNOUNCEMENT_FAIL]: (state: State, action: ActionWithPayload<{}, { houseId: number }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.houseId]),
        }),
        [REMOVE_ANNOUNCEMENT_REQUEST]: (state: State, action: ActionWithPayload<{ houseId: number }, {}>) => ({
            ...state,
            // @ts-ignore
            loading: union(state.loading, action.payload.houseId),
        }),
        [REMOVE_ANNOUNCEMENT_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                { announcements: any[] },
                { actionTime: number; announcementId: number; houseId: number }
            >
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;
            const { announcementId, houseId } = action.meta;

            if (action.payload) {
                existing[houseId] = existing[houseId].filter((a) => a.id !== announcementId);
                loaded[houseId] = time;
                loading = difference(loading, [houseId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.announcements;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

// const loadedSelector = createSelector(stateSelector, state => state.loaded);

// const loadingSelector = createSelector(stateSelector, state => state.loading);

export const getAnnouncements = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

// const getLoadTimeForAnnouncement = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

// const isAnnouncementLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

/* ACTION CREATORS */
const loadAnnouncements = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            payload: houseId,
            type: LOAD_ANNOUNCEMENTS_REQUEST,
        });
        // @ts-ignore
        const response = await api.fetchAnnouncements({ authToken, deployment, houseId });
        return dispatch({
            meta: { actionTime: Date.now(), houseId },
            payload: response.payload,
            type: LOAD_ANNOUNCEMENTS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { houseId },
            payload: error,
            type: LOAD_ANNOUNCEMENTS_FAIL,
        });
    }
};

const loadRemoveAnnouncement =
    (houseId: number, announcementId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                payload: { announcementId, houseId },
                type: REMOVE_ANNOUNCEMENT_REQUEST,
            });
            const response = await api.removeAnnouncement({ announcementId, authToken, deployment, houseId });
            return dispatch({
                meta: { actionTime: Date.now(), announcementId, houseId },
                payload: response.payload,
                type: REMOVE_ANNOUNCEMENT_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { announcementId, houseId },
                payload: error,
                type: REMOVE_ANNOUNCEMENT_FAIL,
            });
        }
    };

const loadCreateAnnouncement =
    ({ body, catalogId, houseId, sendEmail, title }: CreateAnnouncementInput) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                payload: { houseId },
                type: CREATE_ANNOUNCEMENT_REQUEST,
            });
            const response = await api.createAnnouncement({
                authToken,
                body,
                catalogId,
                deployment,
                houseId,
                sendEmail,
                title,
            });
            return dispatch({
                meta: { actionTime: Date.now(), houseId },
                payload: response.payload,
                type: CREATE_ANNOUNCEMENT_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { houseId },
                payload: error,
                type: CREATE_ANNOUNCEMENT_FAIL,
            });
        }
    };

export const fetchAnnouncements = (houseId: number) => async (dispatch: Function) => {
    return dispatch(loadAnnouncements(houseId));
};

export const removeAnnouncement = (houseId: number, announcementId: number) => async (dispatch: Function) => {
    return dispatch(loadRemoveAnnouncement(houseId, announcementId));
};

export const createAnnouncement =
    ({ body, catalogId, houseId, sendEmail, title }: CreateAnnouncementInput) =>
    async (dispatch: Function) => {
        return dispatch(loadCreateAnnouncement({ body, catalogId, houseId, sendEmail, title }));
    };
