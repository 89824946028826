import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    getUserHouseId,
    getUserPermissions,
    isAuthenticated as isAuthenticatedSelector,
    isBrassOrStaffAdminSelector,
    userDataIsLoading,
} from '@/redux/modules/user';
import { isPathPermissionAllowed } from '@/utils/permissions';
import { useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';

interface Props {
    children: React.ReactNode;
    houseId?: number;
    pathname: string;
}

export const ModuleAccess = (props: Props): any => {
    const isAdmin = useAppSelector(isBrassOrStaffAdminSelector);
    const isAuthenticated = useAppSelector(isAuthenticatedSelector);
    const userPermissions = useAppSelector(getUserPermissions);
    const userHouseId = useAppSelector(getUserHouseId);
    const isLoading = useAppSelector(userDataIsLoading);
    const { children, houseId, pathname } = props;

    const hasAccess = React.useMemo(() => {
        // if houseId present in URL, enforce house access check
        const hasHouseAccess = houseId ? userHouseId === Number(houseId) : true;
        // has staff permission level access
        const hasPermission = isPathPermissionAllowed(pathname, userPermissions) && hasHouseAccess;
        // admin overrides all access
        return isAdmin || (hasHouseAccess && hasPermission);
    }, [houseId, isAdmin, pathname, userHouseId, userPermissions]);

    if (isLoading) {
        return <Throbber center />;
    }

    // allows access to private routes or public routes if unauthenticated
    if (hasAccess || !isAuthenticated) {
        return children;
    }

    return (
        <StyledUnauthorized>
            <Text
                colorError
                h3
            >
                <FormattedMessage id="unauthorized" />
            </Text>
        </StyledUnauthorized>
    );
};

export default ModuleAccess;

const StyledUnauthorized = styled.div`
    margin-top: 30px;
    text-align: center;
`;
