import { BidderFavoritingResponse, FavoriteBidders } from '../../types/BidderFavoriting';
import { handleResponse, makeGet, makePost } from './helpers';

type PostFavoriteBidderParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    houseId: number;
};

type PostNeutralizeMassBiddersParams = {
    authToken: string;
    bidderIds: number[];
    deployment: string;
    houseId: number;
};

type BidderFavoritingResponsePayload = {
    error: boolean;
    payload: BidderFavoritingResponse;
};

type FavoriteBiddersResponsePayload = {
    error: boolean;
    payload: FavoriteBidders;
};

type GetFavoriteBiddersParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    page: number;
    pageSize: number;
};

type AdditionalFavoriteBiddersCountResponsePayload = {
    error: boolean;
    payload: number;
};

type GetAdditionalFavoriteBiddersCountParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

type PostFavoriteMassBiddersParams = {
    authToken: string;
    bidderIds: number[];
    deployment: string;
    houseId: number;
};

type PostFavoriteNameOrEmailParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    nameOrEmail: string;
};

type PostFavoriteNameOrEmailPayload = {
    error: boolean;
    payload: number;
};

export const getFavoriteBidders = ({ authToken, deployment, houseId, page, pageSize }: GetFavoriteBiddersParams) =>
    new Promise<FavoriteBiddersResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidders/1`,
        });
        request.query({ page, pageSize });
        request.end((err: boolean, response: FavoriteBiddersResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const getAdditionalFavoriteBiddersCount = ({
    authToken,
    deployment,
    houseId,
}: GetAdditionalFavoriteBiddersCountParams) =>
    new Promise<AdditionalFavoriteBiddersCountResponsePayload>((resolve, reject) => {
        const request = makeGet({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>/house/${houseId}/additional-favorite-count`,
        });

        request.end((err: boolean, response: FavoriteBiddersResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postFavoriteBidder = ({ authToken, bidderId, deployment, houseId }: PostFavoriteBidderParams) =>
    new Promise<BidderFavoritingResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/${bidderId}/regular`,
        });
        request.end((err: boolean, response: BidderFavoritingResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postFavoriteBidderNameOrEmail = ({
    authToken,
    deployment,
    houseId,
    nameOrEmail,
}: PostFavoriteNameOrEmailParams) =>
    new Promise<PostFavoriteNameOrEmailPayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/favorite-by-email-or-username`,
        });
        request.send(JSON.stringify(nameOrEmail));
        request.end((err: boolean, response: PostFavoriteNameOrEmailPayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postFavoriteMassBidders = ({ authToken, bidderIds, deployment, houseId }: PostFavoriteMassBiddersParams) =>
    new Promise<BidderFavoritingResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidders/regular`,
        });
        request.send(bidderIds);
        request.end((err: boolean, response: BidderFavoritingResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postNeutralizeBidder = ({ authToken, bidderId, deployment, houseId }: PostFavoriteBidderParams) =>
    new Promise<BidderFavoritingResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidder/${bidderId}/neutralize`,
        });
        request.end((err: boolean, response: BidderFavoritingResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export const postNeutralizeMassBidders = ({
    authToken,
    bidderIds,
    deployment,
    houseId,
}: PostNeutralizeMassBiddersParams) =>
    new Promise<BidderFavoritingResponsePayload>((resolve, reject) => {
        const request = makePost({
            authToken,
            deployment,
            path: `<APPROVAL-SERVER-API>house/${houseId}/bidders/neutralize`,
        });
        request.send(bidderIds);
        request.end((err: boolean, response: BidderFavoritingResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });
