import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_CATALOG_REGISTRATION_STATS_FAIL,
    LOAD_CATALOG_REGISTRATION_STATS_REQUEST,
    LOAD_CATALOG_REGISTRATION_STATS_SUCCESS,
} from './actions';
import api from '../api/dashboard';

/* reducer */
export const DEFAULT_STATE = {
    data: {},
    loaded: false,
    loading: false,
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LOAD_CATALOG_REGISTRATION_STATS_FAIL]: (state: State) => ({
            ...state,
            loading: false,
        }),
        [LOAD_CATALOG_REGISTRATION_STATS_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [LOAD_CATALOG_REGISTRATION_STATS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ stats: any }, { actionTime: number }>
        ) => {
            const {
                meta: { actionTime },
                payload,
            } = action;

            return {
                ...state,
                data: payload,
                loaded: actionTime,
                loading: false,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.houseStats;

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const houseStatsSelector = createSelector([stateSelector], (stats) => stats.data);

export const loadTimeForHouseStatsSelector = createSelector([loadedSelector], (loaded) => loaded || 0);

export const isHouseStatsLoadingSelector = createSelector([loadingSelector], (loading) => loading);

export const shouldFetchHouseStats = createSelector([isHouseStatsLoadingSelector], (loading) => !loading);

/*  THIS IS CURRENTLY UNUSED */

/* ACTION CREATORS */
const loadHouseStats = (houseId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            type: LOAD_CATALOG_REGISTRATION_STATS_REQUEST,
        });
        const response = await api.fetchHouseStats({
            authToken,
            deployment,
            houseId,
        });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload,
            type: LOAD_CATALOG_REGISTRATION_STATS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_CATALOG_REGISTRATION_STATS_FAIL,
        });
    }
};

export const fetchHouseStatsIfNeeded = (houseId: number) => async (dispatch: Function, getState: Function) => {
    const needed = shouldFetchHouseStats(getState());
    if (needed) {
        return dispatch(loadHouseStats(houseId));
    }
    return Promise.resolve();
};
