import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { fetchMarketingEvents, fetchRecentMarketingEvents } from '../api/dashboard';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LOAD_MARKETING_EVENTS_DATA_FAIL,
    LOAD_MARKETING_EVENTS_DATA_REQUEST,
    LOAD_MARKETING_EVENTS_DATA_SUCCESS,
    MARKETING_EVENTS_FILTERS_CHANGE,
    MARKETING_EVENTS_FILTERS_CHANGE_FAIL,
} from './actions';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';

/* reducer */
export type State = {
    byCatalogId: { [id: number]: any };
    filters: {
        page: number;
        pageSize: number;
        totalRecords: number;
    };
    loaded: { [id: number]: number | 'now' };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byCatalogId: {},
    filters: {
        page: 1,
        pageSize: 20,
        totalRecords: 0,
    },
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_MARKETING_EVENTS_DATA_REQUEST]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>) => ({
            ...state,
            loading: [...state.loading, action.meta.catalogId],
        }),
        [LOAD_MARKETING_EVENTS_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ data: any; totalRecords: number }, { actionTime: number; catalogId: number }>
        ) => {
            const existing = cloneDeep(state.byCatalogId);
            // @ts-ignore
            const loading = difference(state.loading, action.meta.catalogId);
            const loaded = cloneDeep(state.loaded);
            const time = action.meta.actionTime;

            if (Boolean(action.payload) && Boolean(action.payload.data)) {
                existing[action.meta.catalogId] = action.payload.data;
                loaded[action.meta.catalogId] = time;
            }

            let totalRecords: number = state.filters.totalRecords;
            if (Boolean(action.payload) && Boolean(action.payload.totalRecords)) {
                totalRecords = action.payload.totalRecords;
            }

            const filters = state.filters;
            filters.totalRecords = totalRecords;

            return {
                ...state,
                byCatalogId: existing,
                filters,
                loaded,
                loading,
            };
        },
        [MARKETING_EVENTS_FILTERS_CHANGE]: (state: State, action: ActionWithPayload<any>) => {
            let newState = {
                ...state,
                filters: { ...state.filters, ...action.payload },
            };
            return newState;
        },
        [MARKETING_EVENTS_FILTERS_CHANGE_FAIL]: (
            state: State,
            action: ActionWithPayload<{}, { catalogId: number }>
        ) => ({
            ...state,
            // @ts-ignore
            loading: difference(state.loading, action.meta.catalogId),
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.marketingCampaignsAndEvents;
const idSelector = (state, id) => id;

export const byCatalogIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);

export const filtersSelector = createSelector(stateSelector, (state) => state.filters);

export const getMarketingCampaignsAndEventsLoaded = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const marketingCampaignsAndEventsByIdSelector = createSelector(
    [byCatalogIdSelector, idSelector],
    (events, catalogId) => events[catalogId] || []
);

/* ACTION CREATORS */
export const onFilterChange = (filters: PaginationFilter) => async (dispatch: Function) => {
    try {
        dispatch({
            payload: filters,
            type: MARKETING_EVENTS_FILTERS_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: MARKETING_EVENTS_FILTERS_CHANGE_FAIL,
        });
    }
};

const loadCampaignsAndEvents =
    (catalogId: number, houseId: number, startTime: string, endTime: string) => async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                meta: { catalogId },
                payload: { catalogId },
                type: LOAD_MARKETING_EVENTS_DATA_REQUEST,
            });
            const response = await fetchMarketingEvents({
                authToken,
                catalogId,
                deployment,
                endTime,
                houseId,
                startTime,
            });
            return dispatch({
                meta: { actionTime: Date.now(), catalogId },
                payload: response,
                type: LOAD_MARKETING_EVENTS_DATA_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { catalogId },
                payload: error,
                type: LOAD_MARKETING_EVENTS_DATA_FAIL,
            });
        }
    };

const loadRecentCampaignsAndEvents =
    (houseId: number, page: number, pageSize: number) => async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            dispatch({
                meta: { catalogId: 0 },
                payload: { catalogId: 0 },
                type: LOAD_MARKETING_EVENTS_DATA_REQUEST,
            });
            const response = await fetchRecentMarketingEvents({
                authToken,
                deployment,
                houseId,
                page,
                pageSize,
            });
            return dispatch({
                meta: { actionTime: Date.now(), catalogId: 0 },
                payload: response,
                type: LOAD_MARKETING_EVENTS_DATA_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { catalogId: 0 },
                payload: error,
                type: LOAD_MARKETING_EVENTS_DATA_FAIL,
            });
        }
    };

const shouldFetchMarketingEvents = (state, catalogId: number) => {
    const loaded = getMarketingCampaignsAndEventsLoaded(state);
    const loading = loadingSelector(state);
    const exists = (Boolean(loaded) && Boolean(loaded[catalogId])) || loading.includes(catalogId);
    return !exists;
};

export const fetchMarketingEventsIfNeeded =
    (catalogId: number, houseId: number, startTime: string, endTime: string, force?: boolean) =>
    async (dispatch: Function, getState: Function) => {
        if (force || shouldFetchMarketingEvents(getState(), catalogId)) {
            return dispatch(loadCampaignsAndEvents(catalogId, houseId, startTime, endTime));
        }
        return Promise.resolve();
    };

// fetches recent, non-catalog specific by passing catalogId of 0
export const fetchRecentMarketingEventsIfNeeded =
    (houseId: number, page: number, pageSize: number) => async (dispatch: Function) => {
        return dispatch(loadRecentCampaignsAndEvents(houseId, page, pageSize));
    };
