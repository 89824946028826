import * as React from 'react';
import { isAuthenticated as isAuthenticatedSelector } from '@/redux/modules/user';
import { Navigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import { useAppSelector } from '@/redux/hooks';

const PrivateRoute = () => {
    const isAuthenticated = useAppSelector(isAuthenticatedSelector);
    const location = useLocation();

    return isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate
            replace
            state={{ from: location }}
            to="/"
        />
    );
};

export default PrivateRoute;
