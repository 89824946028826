import {
    createNewHouseLocation,
    editHouseLocation,
    fetchHouseLocations,
    validateHouseLocationAddress,
} from '@/redux/modules/houseLocations/houseLocations.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultHouseLocationsSlice } from '@/redux/modules/houseLocations/houseLocations.types';

const houseLocationsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchHouseLocations.pending, (slice) => ({
            ...slice,
            loading: true,
        }));
        builder.addCase(fetchHouseLocations.fulfilled, (slice, { meta, payload }) => ({
            ...slice,
            byId: {
                ...slice.byId,
                [meta.arg]: payload,
            },
            loading: false,
            loadTime: Date.now(),
        }));
        builder.addCase(fetchHouseLocations.rejected, (slice) => ({
            ...slice,
            loading: false,
        }));

        builder.addCase(createNewHouseLocation.pending, (slice) => ({
            ...slice,
            loading: true,
        }));
        builder.addCase(createNewHouseLocation.fulfilled, (slice, { payload }) => ({
            ...slice,
            byId: {
                ...slice.byId,
                [payload.houseId]: [...(slice.byId[payload.houseId] ?? []), payload.location],
            },
            error: undefined,
            loading: false,
        }));
        builder.addCase(createNewHouseLocation.rejected, (slice, { payload }) => ({
            ...slice,
            error: payload,
            loading: false,
        }));

        builder.addCase(validateHouseLocationAddress.fulfilled, (slice) => ({
            ...slice,
            error: undefined,
        }));
        builder.addCase(validateHouseLocationAddress.rejected, (slice, { payload }) => ({
            ...slice,
            error: payload,
        }));

        builder.addCase(editHouseLocation.pending, (slice) => ({
            ...slice,
            loading: true,
        }));
        builder.addCase(editHouseLocation.fulfilled, (slice, { payload }) => {
            const locationIndex = slice.byId[payload.houseId].findIndex(
                (location) => location.addressId === payload.location.addressId
            );
            const updatedLocations = slice.byId[payload.houseId].slice();
            updatedLocations[locationIndex] = payload.location;
            return {
                ...slice,
                byId: {
                    ...slice.byId,
                    [payload.houseId]: updatedLocations,
                },
                error: undefined,
                loading: false,
            };
        });
        builder.addCase(editHouseLocation.rejected, (slice, { payload }) => ({
            ...slice,
            error: payload,
            loading: false,
        }));
    },
    initialState: defaultHouseLocationsSlice,
    name: 'houseLocations',
    reducers: {},
});

export const { reducer: houseLocationsReducer } = houseLocationsSlice;
