import { CustomTheme } from './declaration';

/**
 * Premerging caterwaul theme and HUI theme.
 */
export const mergedCaterwaulAndHammerUIThemes: CustomTheme = {
    border: {
        radius: {
            circle: '50%',
            lg: '16px',
            md: '8px',
            sm: '4px',
            xl: '32px',
            xs: '2px',
            zero: '0',
        },
        width: {
            lg: '8px',
            md: '4px',
            sm: '2px',
            xs: '1px',
            xxs: '0.5px',
        },
    },
    brand: 'LiveAuctioneers',
    breakpoints: {
        lgMax: '1644px',
        lgMaxValue: 1644,
        lgMin: '1249px',
        lgMinValue: 1249,
        mdMax: '1248px',
        mdMaxValue: 1248,
        mdMin: '1024px',
        mdMinValue: 1024,
        mobileWidth: '560px',
        mobileWidthNum: 560,
        pageWidth: '1240px',
        pageWidthNum: 1240,
        smMax: '1023px',
        smMaxValue: 1023,
        smMin: '769px',
        smMinValue: 769,
        tabletNarrowWidth: '768px',
        tabletNarrowWidthNum: 768,
        tabletWidth: '990px',
        tabletWidthNum: 990,
        xlMin: '1645px',
        xlMinValue: 1645,
        xsMax: '768px',
        xsMaxValue: 768,
    },
    button: {
        border: {
            radius: '0',
        },
        favorite: {
            default: {
                color: {
                    background: '#FFFFFF',
                    border: '#595C61',
                },
            },
            disabled: {
                color: {
                    background: '#FFFFFF',
                    border: '#A2A3A5',
                },
            },
            hover: {
                color: {
                    background: '#FBF6F4',
                    border: '#EF4C45',
                },
            },
            pressed: {
                color: {
                    background: '#FBF6F4',
                    border: '#EF4C45',
                },
            },
            selected: {
                color: {
                    background: '#FFFFFF',
                    border: '#CC2F28',
                },
            },
        },
        inline: {
            alt: {
                default: {
                    color: {
                        text: '#CC2F28',
                    },
                },
                hover: {
                    color: {
                        text: '#EF4C45',
                    },
                },
                pressed: {
                    color: {
                        text: '#EF7874',
                    },
                },
            },
            default: {
                color: {
                    text: '#00617F',
                },
            },
            hover: {
                color: {
                    text: '#0097BA',
                },
            },
            inverse: {
                color: {
                    text: '#FFFFFF',
                },
            },
            pressed: {
                color: {
                    text: '#76C6DD',
                },
            },
            weak: {
                color: {
                    text: '#595C61',
                },
            },
        },
        primary: {
            alt: {
                color: {
                    background: '#CC2F28',
                    border: '#CC2F28',
                    text: '#FFFFFF',
                },
                focused: {
                    color: {
                        background: '#CC2F28',
                    },
                },
                hover: {
                    color: {
                        background: '#EF4C45',
                        border: '#EF4C45',
                    },
                },
                pressed: {
                    color: {
                        background: '#EF7874',
                        border: '#EF7874',
                    },
                },
            },
            color: {
                background: '#00617F',
                text: '#FFFFFF',
            },
            focused: {
                color: {
                    background: '#00617F',
                },
            },
            hover: {
                color: {
                    background: '#0097BA',
                },
            },
            pressed: {
                color: {
                    background: '#76C6DD',
                },
            },
        },
        secondary: {
            alt: {
                color: {
                    background: '#FFFFFF',
                    border: '#CC2F28',
                    text: '#CC2F28',
                },
                focused: {
                    color: {
                        background: '#CC2F28',
                        text: '#FFFFFF',
                    },
                },
                hover: {
                    color: {
                        background: '#F4F4F4',
                        border: '#EF4C45',
                        text: '#EF4C45',
                    },
                },
                pressed: {
                    color: {
                        background: '#E3E3E3',
                        border: '#EF4C45',
                    },
                },
            },
            color: {
                background: '#FFFFFF',
                border: '#00617F',
                text: '#00617F',
            },
            focused: {
                color: {
                    background: '#00617F',
                },
            },
            hover: {
                color: {
                    background: '#F4F4F4',
                    border: '#0097BA',
                    text: '#0097BA',
                },
            },
            pressed: {
                color: {
                    background: '#E3E3E3',
                    border: '#0097BA',
                },
            },
        },
        tertiary: {
            color: {
                text: '#00617F',
            },
            hover: {
                color: {
                    background: '#F4F4F4',
                },
            },
            pressed: {
                color: {
                    background: '#E3E3E3',
                },
            },
        },
    },
    checkbox: {
        border: {
            radius: '0',
        },
    },
    color: {
        background: {
            critical: {
                dark: '#CC2F28',
                light: '#FAEAE9',
            },
            decorative: '#FBF6F4',
            disabled: '#E3E3E3',
            field: {
                default: '#FFFFFF',
            },
            hover: '#F4F4F4',
            info: {
                dark: '#00617F',
                light: '#E5F4F8',
            },
            inverse: '#00617F',
            neutral: {
                dark: '#595C61',
                light: '#F4F4F4',
            },
            pressed: '#E3E3E3',
            primary: '#FFFFFF',
            secondary: '#FBF6F4',
            subdued: '#F4F4F4',
            success: {
                dark: '#025D58',
                light: '#EEF7F6',
            },
            tertiary: '#E5F4F8',
            warning: {
                dark: '#FFD200',
                light: '#FFFBE5',
            },
        },
        border: {
            critical: '#EF4C45',
            disabled: '#A2A3A5',
            divider: {
                black: '#211614',
                dark: '#595C61',
                default: '#E3E3E3',
                inverse: '#FFFFFF',
                light: '#F4F4F4',
            },
            focused: {
                default: '#00617F',
                inverse: '#FFFFFF',
            },
            hover: '#0097BA',
            info: '#82B7CC',
            selected: '#00617F',
            success: '#27B4A5',
            warning: '#FFD200',
        },
        icon: {
            active: '#76C6DD',
            alt: {
                active: '#EF7874',
                default: '#CC2F28',
                hover: '#EF4C45',
            },
            critical: '#EF4C45',
            decorative: {
                alt: '#27B4A5',
                default: '#F08D27',
                light: '#F4AC65',
            },
            default: '#595C61',
            disabled: '#A2A3A5',
            hover: '#0097BA',
            info: '#0097BA',
            inverse: '#FFFFFF',
            primary: {
                alt: {
                    active: '#EF7874',
                    default: '#CC2F28',
                    hover: '#EF4C45',
                },
                default: '#00617F',
            },
            success: '#27B4A5',
            warning: {
                default: '#211614',
                light: '#FFD200',
            },
        },
        keyboardFocus: '#00617F80',
        keyboardFocusInverse: '#FFFFFF80',
        shadow: {
            '01': '#A2A3A580',
            '02': '#E3E3E380',
            '03': '#595C6180',
        },
        skeleton: {
            default: '#F4F4F4',
        },
        text: {
            alt: '#FFFFFF',
            critical: '#CC2F28',
            disabled: '#A2A3A5',
            error: '#CC2F28',
            inactive: '#A2A3A5',
            info: '#211614',
            inverse: '#FFFFFF',
            link: {
                active: '#76C6DD',
                default: '#00617F',
                disabled: '#A2A3A5',
                focused: '#00617F',
                hover: '#0097BA',
                inverse: '#FFFFFF',
                monochrome: '#211614',
                weak: '#595C61',
            },
            standard: '#211614',
            success: '#025D58',
            warning: {
                default: '#FFD200',
                light: '#FFFBE5',
            },
            weak: '#595C61',
        },
        transparent00: '#21161400',
        transparent05: '#2116140D',
        transparent20: '#21161433',
        transparent40: '#21161466',
    },
    colors: {
        beige100: '#fbf6f4',
        black: '#211614',
        blue100: '#00617f',
        blue200: '#0097ba',
        blue300: '#82b7cc',
        blueLight: '#e5f4f8',
        codex100: '#552b78',
        codex200: '#3f205a',
        green100: '#91c17b',
        green200: '#eef7f6',
        greenLight: '#eef7f6',
        grey100: '#656565',
        grey200: '#8b8b8b',
        grey300: '#a2a3a5',
        grey400: '#e3e3e3',
        grey500: '#f4f4f4',
        grey700: '#ececec',
        grey800: '#c9ccce',
        grey900: '#282828',
        orange100: '#f08d27',
        red100: '#cc2f28',
        red200: '#ef4c45',
        red300: '#ef7874',
        red400: '#fff6f6',
        redLight: '#faeae9',
        shadow200: 'rgba(227, 227, 227, 0.5)',
        shadow400: 'rgba(33, 22, 20, 0.2)',
        shadow50: 'rgba(255, 255, 255, 0.3)',
        shadow500: 'rgba(33, 22, 20, 0.4)',
        shadowOverlay100: 'rgba(220, 220, 220, 0.8)',
        shadowOverlay200: 'rgba(0, 0, 0, 0.3)',
        shadowOverlay300: 'rgba(41, 41, 41, 0.4)',
        shadowOverlay400: 'rgba(154, 154, 154, 0.8)',
        shadowOverlay50: 'rgba(255, 255, 255, 0.8)',
        shadowOverlay500: 'rgba(127, 127, 127, 0.9)',
        shadowOverlay600: 'rgba(0, 0, 0, 0.5)',
        shadowOverlay700: 'rgba(80, 80, 80, 0.65)',
        shadowOverlayGreen100: 'rgba(145, 193, 123, 0.8)',
        shadowOverlayRed100: 'rgba(255, 199, 199, 0.902)',
        shadowOverlayRed200: 'rgba(239, 120, 116, 0.8)',
        shadowOverlayRed300: 'rgba(239, 76, 69, 0.9)',
        shadowOverlayYellow100: 'rgba(240, 141, 39, 0.1)',
        steel: '#595c61',
        teal: '#27b4a5',
        white: '#fff',
        yellow100: '#ffd200',
        yellow200: '#f7bd6f',
        yellowLight: '#fffbe5',
        zip100: '#411361',
        zip200: '#1A0826',
    },
    core: {
        blue100: '#00617F',
        blue200: '#0097BA',
        blue250: '#76C6DD',
        blue300: '#82B7CC',
        blue500: '#E5F4F8',
        green100: '#025D58',
        green200: '#27B4A5',
        green500: '#EEF7F6',
        neutral100: '#211614',
        neutral200: '#595C61',
        neutral300: '#A2A3A5',
        neutral400: '#E3E3E3',
        neutral500: '#F4F4F4',
        orange100: '#F08D27',
        orange200: '#F4AC65',
        orange500: '#FBF6F4',
        red100: '#CC2F28',
        red200: '#EF4C45',
        red300: '#EF7874',
        red400: '#F7B5B1',
        red500: '#FAEAE9',
        transparent00: '#21161400',
        transparent05: '#2116140D',
        transparent20: '#21161433',
        transparent40: '#21161466',
        white: '#FFFFFF',
        yellow100: '#FFD200',
        yellow500: '#FFFBE5',
    },
    elevation: {
        '01': '0px 4px 4px #E3E3E380',
        '02': '0px 1px 4px #A2A3A580, 0px 1px 8px #E3E3E380',
        '03': '0px 2px 8px #A2A3A580, 0px 4px 16px #E3E3E380',
        '04': '0px 3px 12px #595C6180, 0px 6px 24px #A2A3A580',
        '05': '0px -4px 4px #E3E3E380',
    },
    input: {
        border: {
            radius: '0',
        },
    },
    spacing: {
        lg: '32px',
        lgValue: 32,
        md: '24px',
        mdValue: 24,
        sm: '16px',
        smValue: 16,
        xl: '48px',
        xlValue: 48,
        xs: '8px',
        xsValue: 8,
        xxl: '64px',
        xxlValue: 64,
        xxs: '4px',
        xxsValue: 4,
        xxxl: '128px',
        xxxlValue: 128,
        xxxs: '2px',
        xxxsValue: 2,
    },
    toggle: {
        border: {
            radius: '0',
        },
    },
    typography: {
        body: {
            primary: {
                family: 'Inter',
                height: '24px',
                size: '16px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            secondary: {
                family: 'Inter',
                height: '22px',
                size: '14px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
        },
        button: {
            '100': {
                family: 'Inter',
                height: '10px',
                size: '14px',
                spacing: '1px',
                style: 'normal',
                transform: 'uppercase',
                weight: 600,
            },
            '200': {
                family: 'Inter',
                height: '9px',
                size: '12px',
                spacing: '1px',
                style: 'normal',
                transform: 'uppercase',
                weight: 600,
            },
        },
        caption: {
            '100': {
                family: 'Inter',
                height: '18px',
                size: '12px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
        },
        fontPrimary: 'Inter',
        fontPrimaryStrong: 'Inter',
        fontSecondary: 'Jost',
        heading: {
            '000': {
                family: 'Jost',
                height: '72px',
                size: '48px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            '100': {
                family: 'Jost',
                height: '48px',
                mobile: {
                    family: 'Jost',
                    height: '36px',
                    size: '24px',
                    spacing: '0.2px',
                    style: 'normal',
                    weight: 400,
                },
                size: '32px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            '200': {
                family: 'Jost',
                height: '36px',
                mobile: {
                    family: 'Jost',
                    height: '30px',
                    size: '20px',
                    spacing: '0.2px',
                    style: 'normal',
                    weight: 400,
                },
                size: '24px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            '300': {
                family: 'Jost',
                height: '30px',
                mobile: {
                    family: 'Jost',
                    height: '24px',
                    size: '16px',
                    spacing: '0.2px',
                    style: 'normal',
                    weight: 400,
                },
                size: '20px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            '400': {
                family: 'Jost',
                height: '24px',
                size: '16px',
                spacing: '0.2px',
                style: 'normal',
                weight: 400,
            },
            '500': {
                family: 'Inter',
                height: '24px',
                size: '16px',
                spacing: '0.2px',
                style: 'normal',
                weight: 600,
            },
            '600': {
                family: 'Inter',
                height: '22px',
                size: '14px',
                spacing: '1px',
                style: 'normal',
                transform: 'uppercase',
                weight: 600,
            },
        },
        label: {
            mini: {
                family: 'Inter',
                height: '14px',
                size: '12px',
                spacing: '1px',
                style: 'normal',
                transform: 'uppercase',
                weight: 400,
            },
            strong: {
                family: 'Inter',
                height: '22px',
                size: '14px',
                spacing: '0.2px',
                style: 'normal',
                weight: 600,
            },
        },
    },
};
