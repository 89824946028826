export type CatalogOwnerState = {
    byId: {
        [id: number]: { houseId: number };
    };
    error?: boolean;
    loaded: number;
    loading: boolean;
};

export const defaultCatalogOwnerSlice: CatalogOwnerState = {
    byId: {},
    error: undefined,
    loaded: 0,
    loading: false,
};

export type GetCatalogOwnerParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};

export type GetCatalogOwnerResponse = {
    error: boolean;
    payload: {
        ownerId: number;
    };
};
