import { createSelector } from '@reduxjs/toolkit';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_HOVER_DISMISS,
    LIVE_BID_HOVER_INTENT,
    LIVE_BID_PLACED,
    LIVE_BID_RETRACTED,
    LIVE_BID_SENT,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_MISSIVE,
    LIVE_NEXT_LOT_LOADED,
} from './actions';
import { LiveMessage } from '@/types/Console';

/* helpers */
const addMessage = (messages, newMessages) => {
    messages = [...messages];
    if (Array.isArray(newMessages)) {
        newMessages.forEach((message) => doAddMessage(messages, message));
    } else {
        doAddMessage(messages, newMessages);
    }

    if (messages.length > 250) {
        messages = messages.splice(messages.length - 50, 50);
    }
    return messages;
};

const doAddMessage = (messages, message) => {
    messages.push(message);
    messages.sort((a, b) => a.created - b.created);
};

type LiveItemMessages = {
    [key: number]: LiveMessage;
};

type DefaultState = {
    byId: LiveItemMessages;
};

/* reducer */
export const DEFAULT_STATE: DefaultState = {
    byId: {},
};

export type State = typeof DEFAULT_STATE;

export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    const payload = action.payload || {};
    const {
        amount,
        ask,
        askPrice,
        assignedId,
        bidderId,
        catalogId,
        created,
        currency,
        itemId,
        lotNumber,
        missiveText,
        myBid,
        previousBidAmount,
        source,
    } = payload;

    let existing = state.byId[catalogId] || [];

    switch (action.type) {
        case LIVE_BID_ACCEPTED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { amount, assignedId, bidderId, currency, itemId, previousBidAmount, source },
                        type: 'accepted',
                    }),
                },
            };
        case LIVE_BID_PLACED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { amount, bidderId, currency, itemId, source },
                        type: 'placed',
                    }),
                },
            };
        case LIVE_BID_RETRACTED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: ask,
                            currency,
                            itemId,
                            myBid,
                        },
                        type: 'retracted',
                    }),
                },
            };
        case LIVE_BID_SENT:
            if (action.payload.source === 'Absentee') {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [catalogId]: addMessage(existing, {
                            created,
                            data: { amount, bidderId: assignedId, currency, itemId, source },
                            type: 'placed', // treat absentee bids as placed bids
                        }),
                    },
                };
            } else {
                return state;
            }

        case LIVE_BID_HOVER_DISMISS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount,
                            bidderId,
                            catalogId,
                            created,
                            itemId,
                        },
                        type: 'hover-dismiss',
                    }),
                },
            };
        case LIVE_BID_HOVER_INTENT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount,
                            bidderId,
                            catalogId,
                            created,
                            currency,
                            itemId,
                            source,
                        },
                        type: 'hover-intent',
                    }),
                },
            };
        case LIVE_LOT_PASSED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'passed',
                    }),
                },
            };
        case LIVE_LOT_REOPENED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'reopened',
                    }),
                },
            };
        case LIVE_LOT_SKIPPED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'skipped',
                    }),
                },
            };
        case LIVE_LOT_SOLD:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount,
                            assignedId,
                            bidderId,
                            currency,
                            itemId,
                            lotNumber,
                            myBid,
                        },
                        type: 'sold',
                    }),
                },
            };
        case LIVE_LOT_UNSOLD:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId, lotNumber },
                        type: 'unsold',
                    }),
                },
            };
        case LIVE_MISSIVE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { itemId },
                        text: missiveText,
                        type: 'missive',
                    }),
                },
            };
        // case LOAD_CATALOG_REGISTRATION_SUCCESS:
        //     if (!wasApproved && approved) {
        //         existing = addMessage(existing, {
        //             created,
        //             data: { limit },
        //             type: 'approved',
        //         });
        //     }

        //     return {
        //         ...state,
        //         byId: {
        //             ...state.byId,
        //             [catalogId]: existing,
        //         },
        //     };
        case LIVE_NEXT_LOT_LOADED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: askPrice,
                            currency,
                            itemId,
                            lotNumber,
                        },
                        type: 'opened',
                    }),
                },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state) => state.consoleMessages;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getMessages = createSelector([byIdSelector, idSelector], (byId: LiveMessage, id: number) => {
    if (id === 135665) {
        let i = 1;
        return [
            {
                created: i++,
                data: {
                    amount: 100,
                    currency: 'usd',
                    itemId: 2727263645,
                    myBid: false,
                },
                type: 'retracted',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                    lotNumber: '0001',
                },
                type: 'passed',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                    lotNumber: '0001',
                },
                type: 'reopened',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                    lotNumber: '0001',
                },
                type: 'skipped',
            },
            {
                created: i++,
                data: {
                    amount: 100,
                    assignedId: undefined,
                    bidderId: -1,
                    currency: 'usd',
                    itemId: 2727263645,
                    lotNumber: '0001',
                    myBid: false,
                },
                type: 'sold',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                    lotNumber: '0001',
                },
                type: 'unsold',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                },
                text: 'Going once',
                type: 'missive',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                },
                type: 'approved',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                    limit: 500,
                },
                type: 'approved',
            },
            {
                created: i++,
                data: {
                    itemId: 2727263645,
                },
                type: 'exceeded',
            },
            {
                created: i++,
                data: {
                    amount: 100,
                    currency: 'usd',
                    itemId: 2727263645,
                    lotNumber: '0001',
                },
                type: 'opened',
            },
        ];
    }
    return byId[id] || [];
});

export const getSmallConsoleMessages = createSelector(getMessages, (messages) =>
    messages.filter(
        (x) =>
            x.type === 'accepted' ||
            x.type === 'ended' ||
            x.type === 'opened' ||
            x.type === 'passed' ||
            x.type === 'skipped' ||
            x.type === 'sold' ||
            x.type === 'unsold'
    )
);

export const getAuctioneerConsoleMessages = createSelector(
    getMessages,
    (messages) =>
        messages.filter(
            (x) =>
                x.type === 'accepted' ||
                x.type === 'ended' ||
                x.type === 'hover-dismiss' ||
                x.type === 'hover-intent' ||
                x.type === 'opened' ||
                x.type === 'passed' ||
                x.type === 'placed' ||
                x.type === 'skipped' ||
                x.type === 'sold' ||
                x.type === 'unsold'
        ) as LiveMessage[]
);
