import {
    AutomationSettingsCopyBiddersWalkthroughEnum,
    AutomationSettingsCopyBiddersWalkthroughStepName,
    AutomationSettingsFavoriteBidderRecommendationsWalkthroughEnum,
    AutomationSettingsFavoriteBidderRecommendationsWalkthroughStepName,
} from '@/types/walkthroughs/AutomationSettings';
import {
    BidderApprovalOnboardingWalkthroughEnum,
    WalkthroughStepName,
} from '@/types/walkthroughs/BidderApprovalOnboarding';

export type WalkthroughsState = {
    bidderApprovalOnboarding: {
        currentStep: WalkthroughStepName;
        isOpen: boolean;
        visited: boolean;
    };
    suggestedAutomationForCopyBidders: {
        currentStep: AutomationSettingsCopyBiddersWalkthroughStepName;
        isOpen: boolean;
        visited: boolean;
    };
    suggestedAutomationForFavoriteBidderRecommendations: {
        currentStep: AutomationSettingsFavoriteBidderRecommendationsWalkthroughStepName;
        isOpen: boolean;
        visited: boolean;
    };
};

export const defaultWalkthroughsSlice: WalkthroughsState = {
    bidderApprovalOnboarding: {
        currentStep: BidderApprovalOnboardingWalkthroughEnum.Welcome,
        isOpen: false,
        visited: false,
    },
    suggestedAutomationForCopyBidders: {
        currentStep: AutomationSettingsCopyBiddersWalkthroughEnum.CopyBidders,
        isOpen: false,
        visited: false,
    },
    suggestedAutomationForFavoriteBidderRecommendations: {
        currentStep: AutomationSettingsFavoriteBidderRecommendationsWalkthroughEnum.FavoriteBidderRecommendations,
        isOpen: false,
        visited: false,
    },
};
