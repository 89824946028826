import { createSlice } from '@reduxjs/toolkit';
import {
    CustomPackagingFees,
    defaultShippingLabelsSlice,
    emptyShippingLabelsErrors,
    emptyShippingLabelsLoadingInfo,
    emptyShippingLabelsLoadTimes,
} from '@/redux/modules/shipping/labels/shippingLabels.types';
import {
    fetchPackagingFeeInfo,
    fetchShippingLabelsHousePermissions,
    fetchShippingLabelsPrinterSettings,
    savePackagingFeeInfo,
    saveShippingLabelsPrinterSettings,
} from '@/redux/modules/shipping/labels/shippingLabels.actions';

const shippingLabelsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchPackagingFeeInfo.pending, (slice, { meta }) => {
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                fee: true,
            };
        });
        builder.addCase(fetchPackagingFeeInfo.fulfilled, (slice, { meta, payload }) => {
            slice.byId[meta.arg] = {
                amount: payload.amount ?? 0,
                amountType: payload.amountType || CustomPackagingFees.Package,
                type: payload.type,
            };
            slice.loaded[meta.arg] = {
                ...emptyShippingLabelsLoadTimes,
                ...slice.loaded[meta.arg],
                fee: Date.now(),
            };
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                fee: false,
            };
        });
        builder.addCase(fetchPackagingFeeInfo.rejected, (slice, { meta }) => {
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                fee: false,
            };
            slice.errors[meta.arg] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg],
                fee: true,
            };
        });

        builder.addCase(savePackagingFeeInfo.pending, (slice, { meta }) => {
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                fee: true,
            };
            slice.errors[meta.arg.houseId] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg.houseId],
                fee: false,
            };
        });
        builder.addCase(savePackagingFeeInfo.fulfilled, (slice, { meta, payload }) => {
            slice.byId[meta.arg.houseId] = {
                amount: payload.amount ?? 0,
                amountType: payload.amountType ?? CustomPackagingFees.Package,
                type: payload.type,
            };
            slice.loaded[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadTimes,
                ...slice.loaded[meta.arg.houseId],
                fee: Date.now(),
            };
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                fee: false,
            };
        });
        builder.addCase(savePackagingFeeInfo.rejected, (slice, { meta }) => {
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                fee: false,
            };
            slice.errors[meta.arg.houseId] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg.houseId],
                fee: true,
            };
        });

        builder.addCase(fetchShippingLabelsHousePermissions.fulfilled, (slice, { meta, payload }) => {
            slice.permissions[meta.arg] = payload;
        });

        builder.addCase(fetchShippingLabelsPrinterSettings.pending, (slice, { meta }) => {
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                printer: true,
            };
            slice.errors[meta.arg] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg],
                printer: false,
            };
        });
        builder.addCase(fetchShippingLabelsPrinterSettings.fulfilled, (slice, { meta, payload }) => {
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                printer: false,
            };
            slice.loaded[meta.arg] = {
                ...emptyShippingLabelsLoadTimes,
                ...slice.loaded[meta.arg],
                printer: Date.now(),
            };
            slice.printers[meta.arg] = payload;
        });
        builder.addCase(fetchShippingLabelsPrinterSettings.rejected, (slice, { meta }) => {
            slice.loading[meta.arg] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg],
                printer: false,
            };
            slice.errors[meta.arg] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg],
                printer: true,
            };
        });

        builder.addCase(saveShippingLabelsPrinterSettings.pending, (slice, { meta }) => {
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                printer: true,
            };
            slice.errors[meta.arg.houseId] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg.houseId],
                printer: false,
            };
        });
        builder.addCase(saveShippingLabelsPrinterSettings.fulfilled, (slice, { meta, payload }) => {
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                printer: false,
            };
            slice.loaded[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadTimes,
                ...slice.loaded[meta.arg.houseId],
                printer: Date.now(),
            };
            slice.printers[meta.arg.houseId] = payload;
        });
        builder.addCase(saveShippingLabelsPrinterSettings.rejected, (slice, { meta }) => {
            slice.loading[meta.arg.houseId] = {
                ...emptyShippingLabelsLoadingInfo,
                ...slice.loading[meta.arg.houseId],
                printer: false,
            };
            slice.errors[meta.arg.houseId] = {
                ...emptyShippingLabelsErrors,
                ...slice.errors[meta.arg.houseId],
                printer: true,
            };
        });
    },
    initialState: defaultShippingLabelsSlice,
    name: 'shippingLabels',
    reducers: {},
});

export const { reducer: shippingLabelsReducer } = shippingLabelsSlice;
