import { handleResponse, makeDelete, makeGet, makePatch, makePost } from './helpers';

type FetchConversationFoldersParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    limit?: boolean;
};

export default {
    deleteArchiveConversationFolders: ({ authToken, deployment, folderId, houseId }: any) =>
        new Promise<any>((resolve, reject) => {
            const request = makeDelete({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/folders?folderId=${folderId}`,
            });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    fetchConversationFolders: ({ authToken, deployment, houseId }: FetchConversationFoldersParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/folders`,
            });
            request.query({ userFolderLimit: 200 });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    patchRenameConversationFolders: ({ authToken, deployment, folderId, houseId, name }: any) =>
        new Promise<any>((resolve, reject) => {
            const request = makePatch({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/folders`,
            });
            request.send({ id: folderId, name });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    postNewConversationFolders: ({ authToken, deployment, houseId, name }: any) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/folders`,
            });
            request.send({ name });
            request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
