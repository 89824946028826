export const CREATE_ANNOUNCEMENT_FAIL = 'la/domain/createAnnouncement/LOAD_FAIL';
export const CREATE_ANNOUNCEMENT_REQUEST = 'la/domain/createAnnouncement/LOAD_REQUEST';
export const CREATE_ANNOUNCEMENT_SUCCESS = 'la/domain/createAnnouncement/LOAD_SUCCESS';

export const LOAD_ANNOUNCEMENTS_FAIL = 'la/domain/getAnnouncements/LOAD_FAIL';
export const LOAD_ANNOUNCEMENTS_REQUEST = 'la/domain/getAnnouncements/LOAD_REQUEST';
export const LOAD_ANNOUNCEMENTS_SUCCESS = 'la/domain/getAnnouncements/LOAD_SUCCESS';

export const REMOVE_ANNOUNCEMENT_FAIL = 'la/domain/removeAnnouncement/LOAD_FAIL';
export const REMOVE_ANNOUNCEMENT_REQUEST = 'la/domain/removeAnnouncement/LOAD_REQUEST';
export const REMOVE_ANNOUNCEMENT_SUCCESS = 'la/domain/removeAnnouncement/LOAD_SUCCESS';
