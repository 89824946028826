import {
    confirmCatalogShippingItems,
    loadParsedCatalogShippingItems,
    processCatalogShippingItems,
    removeCatalogShippingItemDimensions,
    resetConfirmationSlice,
    updateFilters,
} from './catalogShippingParser.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogShippingParserSlice, PostConfirmItemsRejectedPayload } from './catalogShippingParser.types';

const catalogShippingParserSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadParsedCatalogShippingItems.pending, (slice, { meta }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;
            slice.page = meta.arg.page;
            slice.pageSize = meta.arg.pageSize;

            // api status
            slice.loading = true;
            slice.error = false;
            slice.success = false;
        });
        builder.addCase(loadParsedCatalogShippingItems.fulfilled, (slice, { meta, payload }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;
            slice.page = meta.arg.page;
            slice.pageSize = meta.arg.pageSize;

            // api status
            slice.loading = false;
            slice.error = false;
            slice.success = true;
            slice.reloadItems = false;

            // item data
            slice.items = payload.lots;
            slice.totalRecords = payload.records;

            // catalog data
            slice.catalogTitle = payload.catalogTitle;
            slice.sellerId = payload.sellerId;
            slice.sellerName = payload.sellerName;
        });

        builder.addCase(loadParsedCatalogShippingItems.rejected, (slice, { meta }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;

            // api status
            slice.loading = false;
            slice.error = true;
            slice.success = false;

            // item data
            slice.items = [];
            slice.totalRecords = 0;
        });
        builder.addCase(processCatalogShippingItems.pending, (slice, { meta }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;
            slice.page = meta.arg.page;
            slice.pageSize = meta.arg.pageSize;

            // api status
            slice.loading = true;
            slice.error = false;
            slice.success = false;
        });
        builder.addCase(processCatalogShippingItems.fulfilled, (slice, { meta, payload }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;
            slice.page = meta.arg.page;
            slice.pageSize = meta.arg.pageSize;

            // api status
            slice.loading = false;
            slice.error = false;
            slice.success = true;

            // item data
            slice.items = payload.lots;
            slice.totalRecords = payload.records;
            slice.reloadItems = false;

            // catalog data
            slice.catalogTitle = payload.catalogTitle;
            slice.sellerId = payload.sellerId;
            slice.sellerName = payload.sellerName;
        });
        builder.addCase(processCatalogShippingItems.rejected, (slice, { meta }) => {
            // params
            slice.filters = meta.arg.filters;
            slice.catalogId = meta.arg.catalogId;

            // api status
            slice.loading = false;
            slice.error = true;
            slice.success = false;

            // item data
            slice.items = [];
            slice.totalRecords = 0;
        });
        builder.addCase(confirmCatalogShippingItems.pending, (slice) => {
            // api status
            slice.loading = true;
            slice.error = false;
            slice.success = false;
        });
        builder.addCase(confirmCatalogShippingItems.fulfilled, (slice, { payload }) => {
            // api status
            slice.loading = false;
            slice.error = false;
            slice.success = true;
            slice.reloadItems = true;

            slice.items = payload.lots;
            slice.totalRecords = payload.records;
        });
        builder.addCase(confirmCatalogShippingItems.rejected, (slice, { payload }) => {
            // api status
            slice.loading = false;
            slice.error = true;
            slice.success = false;
            slice.reloadItems = false;

            if ((payload as PostConfirmItemsRejectedPayload)?.failedLotIds.length) {
                slice.errorInfo.unconfirmedLots = (payload as PostConfirmItemsRejectedPayload).failedLotIds;
            }
            if ((payload as PostConfirmItemsRejectedPayload)?.successfulLots.length) {
                slice.items = (payload as PostConfirmItemsRejectedPayload).successfulLots;
            }
        });
        builder.addCase(resetConfirmationSlice.pending, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.reloadItems = false;
            slice.success = false;
        });
        builder.addCase(removeCatalogShippingItemDimensions.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
            slice.reloadItems = false;
            slice.success = false;
        });
        builder.addCase(removeCatalogShippingItemDimensions.fulfilled, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.reloadItems = true;
            slice.success = true;
        });
        builder.addCase(removeCatalogShippingItemDimensions.rejected, (slice) => {
            slice.loading = false;
            slice.error = true;
            slice.reloadItems = false;
            slice.success = false;
        });
        builder.addCase(updateFilters.pending, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.reloadItems = false;
            slice.success = false;
        });
        builder.addCase(updateFilters.fulfilled, (slice, { payload }) => {
            slice.filters = payload;
            slice.reloadItems = true;
            slice.page = 1;
        });
        builder.addCase(updateFilters.rejected, (slice) => {
            slice.loading = false;
            slice.error = false;
            slice.reloadItems = false;
            slice.success = false;
        });
    },
    initialState: defaultCatalogShippingParserSlice,
    name: 'catalogShippingParserSlice',
    reducers: {},
});

export const { reducer: catalogShippingParserSliceReducer } = catalogShippingParserSlice;
