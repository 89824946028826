import { allNavTabs, NavTab } from '@/components/NavMenu/NavDefinitions';
import { allPermissions } from './allPermissions';

export type UserPermissionsType = Record<string, boolean>;

// TODO: move to NavDefinitions
const excludedPaths = [
    'auctioneer-console',
    'clerk-console',
    'auctioneer-carousel',
    'bnareport',
    'stream',
    'payments/onboarding',
];

/**
 * pathTabsCheck
 * traverses a given collection of tabs and attempts to match url assigned to tab
 * with given pathname; the first matched tab is returned or null if none is found
 * @function pathTabsCheck
 * @category Utilities
 * @param {object} tabs - tabs to traverse and check
 * @param {string} pathname - path to match for
 */
export const pathTabsCheck = (tabs: any, pathname: string): NavTab | undefined => {
    if (!pathname) {
        return undefined;
    }
    const keys = Object.keys(tabs);
    for (let i = 0; i < keys.length; i++) {
        const tab = tabs[keys[i]];
        const children = tab.children;
        // recurse if children present
        if (children) {
            const childReturn = pathTabsCheck(children, pathname);
            if (childReturn) {
                return childReturn;
            }
        }

        const url = tab.url;
        if (url && url.indexOf('/') === 0) {
            const reUrl = url.replace(/<[A-Z]+-ID>/g, String.raw`\d+`).replace(/<[A-Z]+-HOST>/g, String.raw`\s+`);
            const urlMatcher = new RegExp(reUrl + '$');
            const matches = pathname.match(urlMatcher);
            if (matches) {
                return { ...tab, urlMatcher };
            }
        }
    }
};

const isExcludedPath = (pathname: string): boolean => {
    for (let i = 0; i < excludedPaths.length; i++) {
        if (pathname.includes(excludedPaths[i])) {
            return true;
        }
    }
    return false;
};

/**
 * isPathPermissionAllowed checks incoming pathname against passed permissionsList
 * @function isPathPermissionAllowed
 * @category Permissions Utility
 * @param {string} pathname to match against tab URLs, does not match offsite (mainhost) URLs
 *          todo: temporarily used to check against exclusion list
 * @param {UserPermissionsType} userPermissions array of user's security permissions to check against
 */
export const isPathPermissionAllowed = (pathname: string, userPermissions: UserPermissionsType = {}): boolean => {
    const matchedTab = pathTabsCheck(allNavTabs, pathname);
    // allowed if no tab matched or no permission found on matched tab
    if (isExcludedPath(pathname) || !matchedTab || !matchedTab.permissions) {
        return true;
    }
    return hasValidPermission(userPermissions, matchedTab.permissions);
};

/**
 * hasValidPermission checks if any permission in checkPermissions is present in
 * the passed permissionsList
 * @function hasValidPermission
 * @category Permissions Utility
 * @param {UserPermissionsType} userPermissions - array of user permissions to check
 * @param {string[]} permissionsList - array of all security permissions to check against
 */
export const hasValidPermission = (
    userPermissions: UserPermissionsType | null | undefined,
    permissionsList: string[] = []
): boolean => {
    // if no permission restrictions passed in, user has Permission
    if (!permissionsList.length) {
        return true;
    }
    const allPermValues: string[] = Object.values(allPermissions);
    const checkPermissions = userPermissions ? Object.keys(userPermissions) : [];
    let permIsValid = false;
    // ensure at least one of the checkPermissions exists in the allPermissions values
    for (let i = 0; i < checkPermissions.length; i++) {
        if (allPermValues.includes(checkPermissions[i])) {
            permIsValid = true;
        }
    }
    // do not validate perms if no valid perms were passed in
    if (!permIsValid) {
        return false;
    }
    // return true if any of the passed in permissions to be checked
    // are present in the supplied permission list
    for (let i = 0; i < checkPermissions.length; i++) {
        if (permissionsList.includes(checkPermissions[i]) && userPermissions[checkPermissions[i]]) {
            return true;
        }
    }
    return false;
};

/**
 * showNavForPathname - returns false if pathname contains trigger phrase for endpoint
 * where navigation bar should be hidden, otherwise returns true
 * @param pathname string
 * @returns boolean
 */
export function showNavForPathname(pathname: string): boolean {
    let showNav = true;
    if (!pathname) {
        return showNav;
    }
    const skipPaths = [
        '/login',
        '/videosetup',
        'auctioneer-carousel',
        'auctioneer-console',
        'bnareport',
        'clerk-console',
        'confirmation',
        'featuredLots',
        'guaranteedbiddersterms',
        'payment/',
        'payments/onboarding',
        'stream',
        'upcoming-catalog-list',
    ];
    skipPaths.forEach((value) => {
        showNav = pathname.includes(value) ? false : showNav;
    });
    return showNav;
}
