import { hasValidPermission, UserPermissionsType } from './permissions';

const validUserTypes = ['admin', 'auctioneer', 'staff'];

export const isValidUserType = (type: string) => {
    let isValidUserType = false;
    validUserTypes.forEach(function (userType) {
        if (type === userType) {
            isValidUserType = true;
        }
    });

    return isValidUserType;
};

/**
 * userHasPermission returns permission based on incoming perm string and staffPermissions array
 * if no perm string is given, true is returned
 * if staffPermissions is falsy, return true for now, see comments
 * @function userHasPermission
 * @category User Utils
 * @param {UserPermissionsType} staffPermissions staff assigned permissions
 * @param {string[]} perms
 * @returns {boolean}
 */
export const userHasPermission = (staffPermissions: UserPermissionsType, perms: string[]): boolean => {
    return hasValidPermission(staffPermissions, perms);
};
