import { reducer as absenteeBidData, State as AbsenteeBidDataState } from './modules/absenteeBidData';
import { reducer as accountingContact, State as AccountingContactState } from './modules/accountingContact';
import { reducer as admin, State as AdminState } from './modules/admin';
import { reducer as analytics, State as AnalyticsState } from './modules/analytics';
import { reducer as announcements, State as AnnouncementsState } from './modules/announcement';
import { reducer as auctionPreview, State as AuctionPreviewState } from './modules/auctionPreview';
import { reducer as autoAcceptBids, State as AutoAcceptBidsState } from './modules/autoAcceptBids';
import { reducer as autoApprovals, State as AutoApprovalsState } from './modules/autoApprovals';
import { bidderConversationsReducer } from './modules/bidderConversations/bidderConversations.reducer';
import { BidderConversationsState } from '@/redux/modules/bidderConversations/bidderConversations.types';
import { reducer as bidderFavoriting, State as BidderFavoritingState } from './modules/bidderFavoriting';
import { reducer as bidderPaddleNumber, State as BidderPaddleNumberState } from './modules/bidderPaddleNumber';
import { reducer as bidderProfile, State as BidderProfileState } from './modules/bidderProfile';
import { reducer as bidderRegistrations, State as BidderRegistrationsState } from './modules/bidderRegistrations';
import { reducer as bidderTables, State as BidderTablesState } from './modules/bidderTables';
import { reducer as biddingInfo, BiddingInfoState } from './modules/biddingInfo';
import { BidsNotAcceptedState, reducer as bnareport } from './modules/bnareport';
import { reducer as bidValueData, State as BidValueDataState } from './modules/bidValueData';
import { reducer as bidValueMetrics, State as BidValueMetricsState } from './modules/bidValueMetrics';
import { reducer as blockedBidders, State as BlockedBiddersState } from './modules/blockedBidders';
import { reducer as browser, State as BrowserState } from './modules/browser';
import { reducer as campaign, State as CampaignState } from './modules/campaign';
import { reducer as campaignAdPreview, State as CampaignAdPreviewState } from './modules/campaignAdPreview';
import { reducer as catalog, State as CatalogState } from './modules/catalog';
import {
    reducer as catalogBidCountInfoData,
    State as CatalogBidCountInfoDataState,
} from './modules/catalogBidCountInfoData';
import {
    reducer as catalogBidderCountInfoData,
    State as CatalogBidderCountInfoDataState,
} from './modules/catalogBidderCountInfoData';
import { reducer as catalogData, State as CatalogDataState } from './modules/catalogData';
import { reducer as catalogItems, State as CatalogItemsState } from './modules/catalogItems';
import { catalogOwnerReducer } from './modules/catalogOwner/catalogOwner.reducer';
import { CatalogOwnerState } from '@/redux/modules/catalogOwner/catalogOwner.types';
import {
    reducer as catalogPageviewInfoData,
    State as CatalogPageviewInfoDataState,
} from './modules/catalogPageviewInfoData';
import { reducer as catalogPageviewsData, State as CatalogPageviewsDataState } from './modules/catalogPageviewsData';
import { reducer as catalogRegistrationCount, CatalogRegistrationCountState } from './modules/catalogRegistrationCount';
import {
    reducer as catalogRegistrationData,
    State as CatalogRegistrationDataState,
} from './modules/catalogRegistrationData';
import {
    reducer as catalogRegistrationInfo,
    State as CatalogRegistrationInfoState,
} from './modules/catalogRegistrationInfo';
import { reducer as catalogSalesData, State as CatalogSalesDataState } from './modules/catalogSalesData';
import { catalogShippingParserSliceReducer } from '@/redux/modules/shipping/parser/catalogShippingParser.reducer';
import { CatalogShippingParserState } from '@/redux/modules/shipping/parser/catalogShippingParser.types';
import { reducer as catalogStats, State as CatalogStatsState } from './modules/catalogStats';
import { reducer as categorizer, State as CategorizerState } from './modules/categorizer';
import { reducer as changePassword, State as ChangepasswordState } from './modules/changePassword';
import { reducer as changeStartTime, State as ChangeStartTimeState } from './modules/changeStartTime';
import { reducer as clerkButtons, State as ClerkButtonsState } from './modules/clerkButtons';
import { reducer as clerkConsoleMessages, State as ClerkConsoleMessagesState } from './modules/clerkConsoleMessages';
import { combineReducers } from 'redux';
import { reducer as console, State as ConsoleState } from './modules/console';
import { reducer as consoleBranding, State as ConsoleBrandingState } from './modules/consoleBranding';
import { reducer as conversationMessages, State as ConversationMessagesState } from './modules/conversationMessages';
import { reducer as coverLots, State as CoverLotsState } from './modules/coverLots';
import { reducer as creditCard, State as CreditCardState } from './modules/creditCard';
import { reducer as dedicatedFollowers, State as DedicatedFollowersState } from './modules/dedicatedFollowers';
import { reducer as featureAccess, State as FeatureAccessState } from './modules/featureAccess';
import { reducer as followerCount, State as FollowerCountState } from './modules/followerCount';
import { reducer as forgotPassword, ForgotPasswordState } from './modules/forgotPassword';
import { reducer as forumPosts, State as ForumPostsState } from './modules/forumPosts';
import { reducer as houseBetaEnrollments, State as HouseBetaEnrollmentsState } from './modules/houseBetaEnrollments';
import { reducer as houseBilling, State as HouseBillingState } from './modules/houseBilling';
import { reducer as houseInvoice, State as HouseInvoiceState } from './modules/houseInvoice';
import { houseLocationsReducer } from '@/redux/modules/houseLocations/houseLocations.reducer';
import { HouseLocationsSlice } from '@/redux/modules/houseLocations/houseLocations.types';
import { reducer as housePayment, State as HousePaymentState } from './modules/housePayment';
import { reducer as housePaymentAmount, State as HousePaymentAmountState } from './modules/housePaymentAmount';
import { reducer as houseStats, State as HouseStatsState } from './modules/houseStats';
import { reducer as houseStatus, State as HouseStatusState } from './modules/houseStatus';
import { reducer as hoverCount, State as HoverCountState } from './modules/hoverCount';
import { reducer as invoice, State as InvoiceState } from './modules/invoice';
import { reducer as item, State as ItemState } from './modules/item';
import { reducer as itemDetail, State as ItemDetailState } from './modules/itemDetail';
import { reducer as itemReserve, State as ItemReserveState } from './modules/itemReserve';
import { reducer as itemStats, State as ItemStatsState } from './modules/itemStats';
import { reducer as liveBidsAccepted, State as LiveBidsAcceptedState } from './modules/liveBidsAccepted';
import { reducer as liveCatalogStatus, State as LiveCatalogStatusState } from './modules/liveCatalogStatus';
import { reducer as liveItemStatus, State as LiveItemStatusState } from './modules/liveItemStatus';
import { reducer as liveLotsSold, State as LiveLotsSoldState } from './modules/liveLotsSold';
import { reducer as loaded, State as LoadedState } from './modules/loaded';
import { reducer as login, State as LoginState } from './modules/login';
import {
    reducer as marketingCampaignsAndEvents,
    State as MarketingCampaignsAndEventsState,
} from './modules/marketingCampaignsAndEvents';
import { reducer as missive, State as MissiveState } from './modules/missive';
import { reducer as modal, State as ModalState } from './modules/modal';
import { reducer as payments, State as PaymentsState } from './modules/payment';
import { reducer as pendingInternetBid, State as PendingInternetBidState } from './modules/pendingInternetBid';
import { reducer as promotion, State as PromotionState } from './modules/promotion';
import { reducer as publish, State as PublishState } from './modules/publish';
import { reducer as ratingsAndReviews, State as RatingsAndReviewsState } from './modules/ratingsAndReviews';
import { reducer as resetPassword, State as ResetPasswordState } from './modules/resetPassword';
import { reducer as retractBid, State as RetractBidState } from './modules/retractBid';
import { reducer as reviewComments, State as ReviewCommentsState } from './modules/reviewComments';
import { reducer as savedItemCount, State as SavedItemCountState } from './modules/savedItemCount';
import { reducer as savedItemData, State as SavedItemDataState } from './modules/savedItemData';
import { reducer as search, State as SearchState } from './modules/searchSuggestions';
import { reducer as seller, State as SellerState } from './modules/seller';
import { reducer as sellerCatalogs, State as SellerCatalogsState } from './modules/sellerCatalogs';
import { shippingLabelsReducer } from '@/redux/modules/shipping/labels/shippingLabels.reducer';
import { ShippingLabelsSlice } from '@/redux/modules/shipping/labels/shippingLabels.types';
import { reducer as speedCheck, State as SpeedCheckState } from './modules/speedCheck';
import { reducer as stream, State as StreamState } from './modules/stream';
import { reducer as taxonomy, State as TaxonomyState } from './modules/taxonomy';
import { reducer as upcomingAuctions, State as UpcomingAuctionsState } from './modules/upcomingAuctions';
import { reducer as user, State as UserState } from './modules/user';
import { reducer as video, State as VideoState } from './modules/video';
import { walkthroughsReducer } from './modules/walkthroughs/walkthroughs.reducer';
import { WalkthroughsState } from './modules/walkthroughs/walkthroughs.types';
import bidderApproval, { State as BidderApprovalState } from './modules/bidderApproval';
import config, { State as ConfigState } from './modules/config';
import consoleMessages, { State as ConsoleMessagesState } from './modules/consoleMessages';
import conversation, { State as ConversationState } from './modules/conversation';
import conversationFolders, { State as ConversationFoldersState } from './modules/conversationFolders';
import feedback, { State as FeedbackState } from './modules/feedback';
import healthcheck, { State as HealthCheckState } from './modules/healthcheck';
import session, { State as SessionState } from './modules/session';

const createRootReducerObject = () => ({
    absenteeBidData,
    accountingContact,
    admin,
    analytics,
    announcements,
    auctionPreview,
    autoAcceptBids,
    autoApprovals,
    bidderApproval,
    bidderConversations: bidderConversationsReducer,
    bidderFavoriting,
    bidderPaddleNumber,
    bidderProfile,
    bidderRegistrations,
    bidderTables,
    biddingInfo,
    bidValueData,
    bidValueMetrics,
    blockedBidders,
    bnareport,
    browser,
    campaign,
    campaignAdPreview,
    catalog,
    catalogBidCountInfoData,
    catalogBidderCountInfoData,
    catalogData,
    catalogItems,
    catalogOwner: catalogOwnerReducer,
    catalogPageviewInfoData,
    catalogPageviewsData,
    catalogRegistrationCount,
    catalogRegistrationData,
    catalogRegistrationInfo,
    catalogSalesData,
    catalogShippingParser: catalogShippingParserSliceReducer,
    catalogStats,
    categorizer,
    changePassword,
    changeStartTime,
    clerkButtons,
    clerkConsoleMessages,
    config,
    console,
    consoleBranding,
    consoleMessages,
    conversation,
    conversationFolders,
    conversationMessages,
    coverLots,
    creditCard,
    dedicatedFollowers,
    featureAccess,
    feedback,
    followerCount,
    forgotPassword,
    forumPosts,
    healthcheck,
    houseBetaEnrollments,
    houseBilling,
    houseInvoice,
    houseLocations: houseLocationsReducer,
    housePayment,
    housePaymentAmount,
    houseStats,
    houseStatus,
    hoverCount,
    invoice,
    item,
    itemDetail,
    itemReserve,
    itemStats,
    liveBidsAccepted,
    liveCatalogStatus,
    liveItemStatus,
    liveLotsSold,
    loaded,
    login,
    marketingCampaignsAndEvents,
    missive,
    modal,
    payments,
    pendingInternetBid,
    promotion,
    publish,
    ratingsAndReviews,
    resetPassword,
    retractBid,
    reviewComments,
    savedItemCount,
    savedItemData,
    search,
    seller,
    sellerCatalogs,
    session,
    shippingLabels: shippingLabelsReducer,
    speedCheck,
    stream,
    taxonomy,
    upcomingAuctions,
    user,
    video,
    walkthroughs: walkthroughsReducer,
});

export default () => combineReducers(createRootReducerObject());

export type GlobalState = {
    absenteeBidData: AbsenteeBidDataState;
    accountingContact: AccountingContactState;
    admin: AdminState;
    analytics: AnalyticsState;
    announcements: AnnouncementsState;
    auctionPreview: AuctionPreviewState;
    autoAcceptBids: AutoAcceptBidsState;
    autoApprovals: AutoApprovalsState;
    bidValueData: BidValueDataState;
    bidValueMetrics: BidValueMetricsState;
    bidderApproval: BidderApprovalState;
    bidderConversations: BidderConversationsState;
    bidderFavoriting: BidderFavoritingState;
    bidderPaddleNumber: BidderPaddleNumberState;
    bidderProfile: BidderProfileState;
    bidderRegistrations: BidderRegistrationsState;
    bidderTables: BidderTablesState;
    biddingInfo: BiddingInfoState;
    blockedBidders: BlockedBiddersState;
    bnareport: BidsNotAcceptedState;
    browser: BrowserState;
    campaign: CampaignState;
    campaignAdPreview: CampaignAdPreviewState;
    catalog: CatalogState;
    catalogBidCountInfoData: CatalogBidCountInfoDataState;
    catalogBidderCountInfoData: CatalogBidderCountInfoDataState;
    catalogData: CatalogDataState;
    catalogItems: CatalogItemsState;
    catalogOwner: CatalogOwnerState;
    catalogPageviewInfoData: CatalogPageviewInfoDataState;
    catalogPageviewsData: CatalogPageviewsDataState;
    catalogRegistrationCount: CatalogRegistrationCountState;
    catalogRegistrationData: CatalogRegistrationDataState;
    catalogRegistrationInfo: CatalogRegistrationInfoState;
    catalogSalesData: CatalogSalesDataState;
    catalogShippingParser: CatalogShippingParserState;
    catalogStats: CatalogStatsState;
    categorizer: CategorizerState;
    changePassword: ChangepasswordState;
    changeStartTime: ChangeStartTimeState;
    clerkButtons: ClerkButtonsState;
    clerkConsoleMessages: ClerkConsoleMessagesState;
    config: ConfigState;
    console: ConsoleState;
    consoleBranding: ConsoleBrandingState;
    consoleMessages: ConsoleMessagesState;
    conversation: ConversationState;
    conversationFolders: ConversationFoldersState;
    conversationMessages: ConversationMessagesState;
    coverLots: CoverLotsState;
    creditCard: CreditCardState;
    dedicatedFollowers: DedicatedFollowersState;
    featureAccess: FeatureAccessState;
    feedback: FeedbackState;
    followerCount: FollowerCountState;
    forgotPassword: ForgotPasswordState;
    forumPosts: ForumPostsState;
    healthcheck: HealthCheckState;
    houseBetaEnrollments: HouseBetaEnrollmentsState;
    houseBilling: HouseBillingState;
    houseInvoice: HouseInvoiceState;
    houseLocations: HouseLocationsSlice;
    housePayment: HousePaymentState;
    housePaymentAmount: HousePaymentAmountState;
    houseStats: HouseStatsState;
    houseStatus: HouseStatusState;
    hoverCount: HoverCountState;
    invoice: InvoiceState;
    item: ItemState;
    itemDetail: ItemDetailState;
    itemReserve: ItemReserveState;
    itemStats: ItemStatsState;
    liveBidsAccepted: LiveBidsAcceptedState;
    liveCatalogStatus: LiveCatalogStatusState;
    liveItemStatus: LiveItemStatusState;
    liveLotsSold: LiveLotsSoldState;
    loaded: LoadedState;
    login: LoginState;
    marketingCampaignsAndEvents: MarketingCampaignsAndEventsState;
    missive: MissiveState;
    modal: ModalState;
    payments: PaymentsState;
    pendingInternetBid: PendingInternetBidState;
    promotion: PromotionState;
    publish: PublishState;
    ratingsAndReviews: RatingsAndReviewsState;
    resetPassword: ResetPasswordState;
    retractBid: RetractBidState;
    reviewComments: ReviewCommentsState;
    savedItemCount: SavedItemCountState;
    savedItemData: SavedItemDataState;
    search: SearchState;
    seller: SellerState;
    sellerCatalogs: SellerCatalogsState;
    session: SessionState;
    shippingLabels: ShippingLabelsSlice;
    speedCheck: SpeedCheckState;
    stream: StreamState;
    taxonomy: TaxonomyState;
    upcomingAuctions: UpcomingAuctionsState;
    user: UserState;
    video: VideoState;
    walkthroughs: WalkthroughsState;
};
