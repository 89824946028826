import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import api from '../api';

/* Action Types */
export const GET_RATINGS_AND_REVIEWS_FAIL = 'GET_RATINGS_AND_REVIEWS_FAIL';
export const GET_RATINGS_AND_REVIEWS_REQUEST = 'GET_RATINGS_AND_REVIEWS_REQUEST';
export const GET_RATINGS_AND_REVIEWS_SUCCESS = 'GET_RATINGS_AND_REVIEWS_SUCCESS';

export const GET_RATINGS_PERCENTILES_FAIL = 'GET_RATINGS_PERCENTILES_FAIL';
export const GET_RATINGS_PERCENTILES_REQUEST = 'GET_RATINGS_PERCENTILES_REQUEST';
export const GET_RATINGS_PERCENTILES_SUCCESS = 'GET_RATINGS_PERCENTILES_SUCCESS';

export const POST_PUBLIC_REVIEWS_PREFERENCE_FAIL = 'POST_PUBLIC_REVIEWS_PREFERENCE_FAIL';
export const POST_PUBLIC_REVIEWS_PREFERENCE_REQUEST = 'POST_PUBLIC_REVIEWS_PREFERENCE_REQUEST';
export const POST_PUBLIC_REVIEWS_PREFERENCE_SUCCESS = 'POST_PUBLIC_REVIEWS_PREFERENCE_SUCCESS';

/* reducer */
export const DEFAULT_STATE = {
    allowPublicReviews: false,
    filteredCount: 0,
    isLoading: false,
    isTopRated: false,
    percentiles: {
        accuracy: 0,
        overall: 0,
        payment: 0,
        responsiveness: 0,
        shipping: 0,
    },
    ratings: {
        accuracy: 0,
        fiveStar: 0,
        fourStar: 0,
        oneStar: 0,
        overall: 0,
        payment: 0,
        responsiveness: 0,
        shipping: 0,
        threeStar: 0,
        twoStar: 0,
    },
    reviews: [],
    totalReviews: 0,
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [GET_RATINGS_AND_REVIEWS_FAIL]: (state: State) => ({
            ...state,
            isLoading: false,
        }),
        [GET_RATINGS_AND_REVIEWS_REQUEST]: (state: State) => ({
            ...state,
            isLoading: true,
        }),
        [GET_RATINGS_AND_REVIEWS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                allowPublicReviews: boolean;
                filteredCount: number;
                ratings: {};
                reviews: [];
                totalReviews: number;
            }>
        ) => ({
            ...state,
            allowPublicReviews: Boolean(action.payload.allowPublicReviews),
            filteredCount: action.payload.filteredCount,
            isLoading: false,
            ratings: action.payload.ratings,
            reviews: action.payload.reviews,
            totalReviews: action.payload.totalReviews,
        }),
        [GET_RATINGS_PERCENTILES_FAIL]: (state: State) => ({
            ...state,
            isLoading: false,
        }),
        [GET_RATINGS_PERCENTILES_REQUEST]: (state: State) => ({
            ...state,
            isLoading: true,
        }),
        [GET_RATINGS_PERCENTILES_SUCCESS]: (state: State, action: ActionWithPayload<{ isTopRated: boolean }>) => ({
            ...state,
            isLoading: false,
            isTopRated: Boolean(action.payload.isTopRated),
            percentiles: action.payload,
        }),
        [POST_PUBLIC_REVIEWS_PREFERENCE_FAIL]: (state: State) => ({
            ...state,
            isLoading: false,
        }),
        [POST_PUBLIC_REVIEWS_PREFERENCE_REQUEST]: (state: State) => ({
            ...state,
            isLoading: true,
        }),
        [POST_PUBLIC_REVIEWS_PREFERENCE_SUCCESS]: (state: State) => ({
            ...state,
            allowPublicReviews: !state.allowPublicReviews,
            isLoading: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const ratingsAndReviewsSelector = (state) => state.ratingsAndReviews;

export const getPercentiles = createSelector(ratingsAndReviewsSelector, (state) => state.percentiles);

export const getAllowPublicReviews = createSelector(ratingsAndReviewsSelector, (state) => state.allowPublicReviews);

export const getFilteredCount = createSelector(ratingsAndReviewsSelector, (state) => state.filteredCount);

export const getIsTopRated = createSelector(ratingsAndReviewsSelector, (state) => state.isTopRated);

export const getRatings = createSelector(ratingsAndReviewsSelector, (state) => state.ratings);
export const getReviews = createSelector(ratingsAndReviewsSelector, (state) => state.reviews);

export const getTotalReviews = createSelector(ratingsAndReviewsSelector, (state) => state.totalReviews);

export const isLoading = createSelector(ratingsAndReviewsSelector, (state) => state.isLoading);

/* ACTION CREATORS */
export const fetchRatingsAndReviews =
    (houseId: number, page: number, pageSize: number, filter: string, sort: string) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: GET_RATINGS_AND_REVIEWS_REQUEST,
            });

            const response = await api.getRatingsAndReviews({
                authToken,
                deployment,
                filter,
                houseId,
                page,
                pageSize,
                sort,
            });

            dispatch({
                payload: {
                    ...response,
                },
                type: GET_RATINGS_AND_REVIEWS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: GET_RATINGS_AND_REVIEWS_FAIL,
            });
        }
    };

export const fetchRatingsPercentiles = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: GET_RATINGS_PERCENTILES_REQUEST,
        });

        const response = await api.getRatingsPercentiles({
            authToken,
            deployment,
            houseId,
        });
        dispatch({
            payload: {
                ...response,
            },
            type: GET_RATINGS_PERCENTILES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: GET_RATINGS_PERCENTILES_FAIL,
        });
    }
};

export const setPublicReviewsPreference =
    (houseId: number, preference: boolean) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: POST_PUBLIC_REVIEWS_PREFERENCE_REQUEST,
            });

            const response = await api.postSetPublicReviewsPreference({
                authToken,
                deployment,
                houseId,
                preference,
            });
            dispatch({
                payload: {
                    ...response,
                },
                type: POST_PUBLIC_REVIEWS_PREFERENCE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: POST_PUBLIC_REVIEWS_PREFERENCE_FAIL,
            });
        }
    };
