export type Conversation = {
    Subject: string;
    conversationId: number;
    lastMessage: string;
    messageCount: number;
    // Subject sometimes come with a capital S from the database. Added both cases to makes sure nothing breakes.
    subject?: string;
};

export type GetConversationMessagesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    houseId: number;
};

export type GetConversationMessagesResponsePayload = {
    error: boolean;
    payload: {
        conversationCount: number;
        conversations: Conversation[];
    };
};

export type KeyValues = {
    bidderId: number;
    houseId: number;
};

export type BidderConversationsState = {
    conversationCount: number;
    conversations: Conversation[];
    error: boolean;
    isLoading: boolean;
};

export type FetchConversationsResponse = {
    conversationCount: number;
    conversations: Conversation[];
};

export const defaultBidderConversationsSlice: BidderConversationsState = {
    conversationCount: 0,
    conversations: [
        {
            conversationId: 0,
            lastMessage: '',
            messageCount: 0,
            Subject: '',
        },
    ],
    error: false,
    isLoading: false,
};
