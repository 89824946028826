import {
    ARCHIVE_CONVERSATION_FOLDER_FAIL,
    ARCHIVE_CONVERSATION_FOLDER_REQUEST,
    ARCHIVE_CONVERSATION_FOLDER_SUCCESS,
    CREATE_CONVERSATION_FOLDER_FAIL,
    CREATE_CONVERSATION_FOLDER_REQUEST,
    CREATE_CONVERSATION_FOLDER_SUCCESS,
    LOAD_CONVERSATION_FOLDERS_FAIL,
    LOAD_CONVERSATION_FOLDERS_REQUEST,
    LOAD_CONVERSATION_FOLDERS_SUCCESS,
    RENAME_CONVERSATION_FOLDER_FAIL,
    RENAME_CONVERSATION_FOLDER_REQUEST,
    RENAME_CONVERSATION_FOLDER_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '../modules/user';
import { getDeployment } from '../modules/config';
import api from '../api/conversationFolders';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

const REDUX_STORE_TIME = ms('1s');

/* reducer */
export const DEFAULT_STATE = {
    folders: { systemFolders: [], userFolders: [] },
    loaded: false,
    loading: false,
};

export type State = typeof DEFAULT_STATE;

export default function reducer(state: State = DEFAULT_STATE, action: any = {}) {
    let existing;

    switch (action.type) {
        case ARCHIVE_CONVERSATION_FOLDER_SUCCESS:
            existing = cloneDeep(state.folders);
            return {
                ...state,
                folders: {
                    ...existing,
                    userFolders: [...existing.userFolders].filter((f) => f.id !== action.meta.folderId),
                },
            };
        case CREATE_CONVERSATION_FOLDER_SUCCESS:
            existing = cloneDeep(state.folders);
            const userFolders = [...existing.userFolders, action.payload];
            userFolders.sort((a, b) => {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            return {
                ...state,
                folders: {
                    ...existing,
                    userFolders,
                },
            };
        case LOAD_CONVERSATION_FOLDERS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_CONVERSATION_FOLDERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_CONVERSATION_FOLDERS_SUCCESS:
            return {
                ...state,
                folders: action.payload,
                loaded: action.meta.actionTime,
                loading: false,
            };
        default:
            return state;
    }
}

const stateSelector = (state) => state.conversationFolders;

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getConversationFolders = createSelector(
    stateSelector,
    (state) => state.folders || { systemFolders: [], userFolders: [] }
);

export const getUnreadInboxCount = createSelector(getConversationFolders, (state) => {
    const firstTotal = state?.systemFolders?.reduce((total, folder) => total + folder.unReadConversationCount, 0) || 0;
    const secondTotal = state?.userFolders?.reduce((total, folder) => total + folder.unReadConversationCount, 0) || 0;
    return firstTotal + secondTotal;
});

export const getLoadTimeForConversationFolder = loadedSelector;

export const isConversationFolderLoading = loadingSelector;

const shouldFetch = createSelector([loadedSelector, loadingSelector], (loaded, loading) => {
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }
    return !loading;
});

const loadConversationFolders = (houseId: number, limit?: boolean) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_CONVERSATION_FOLDERS_REQUEST,
        });
        const response = await api.fetchConversationFolders({ authToken, deployment, houseId, limit });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload,
            type: LOAD_CONVERSATION_FOLDERS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_CONVERSATION_FOLDERS_FAIL,
        });
    }
};

export const fetchConversationFoldersIfNeeded =
    (houseId: number, limit: boolean = false) =>
    async (dispatch: Function, getState: Function) => {
        if (shouldFetch(getState()) && houseId > 0) {
            return dispatch(loadConversationFolders(houseId, limit));
        }
        return Promise.resolve();
    };

export const createConversationFolder =
    (name: string, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: CREATE_CONVERSATION_FOLDER_REQUEST,
            });
            const response = await api.postNewConversationFolders({ authToken, deployment, houseId, name });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: CREATE_CONVERSATION_FOLDER_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: CREATE_CONVERSATION_FOLDER_FAIL,
            });
        }
    };

export const renameConversationFolder =
    (folderId: number, name: string, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: RENAME_CONVERSATION_FOLDER_REQUEST,
            });
            const response = await api.patchRenameConversationFolders({
                authToken,
                deployment,
                folderId,
                houseId,
                name,
            });

            dispatch(fetchConversationFoldersIfNeeded(houseId));
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: RENAME_CONVERSATION_FOLDER_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: RENAME_CONVERSATION_FOLDER_FAIL,
            });
        }
    };

export const archiveConversationFolder =
    (folderId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: ARCHIVE_CONVERSATION_FOLDER_REQUEST,
            });
            const response = await api.deleteArchiveConversationFolders({ authToken, deployment, folderId, houseId });
            return dispatch({
                meta: { actionTime: Date.now(), folderId },
                payload: response.payload,
                type: ARCHIVE_CONVERSATION_FOLDER_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: ARCHIVE_CONVERSATION_FOLDER_FAIL,
            });
        }
    };
