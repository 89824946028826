export enum ParsedShippingFragility {
    FRAGILE = 'f',
    LITTLE_FRAGILE = 'lf',
    NOT_FRAGILE = 'nf',
    VERY_FRAGILE = 'vf',
}

export enum ParsedShippingPackageType {
    CRATED = 'ct',
    UNSPECIFIED = 'unspecified',
}

export enum ParsedShippingShape {
    PLAIN_SHAPE = 'pl',
    SPHERIC_SHAPE = 'sp',
    SQUARE_SHAPE = 'sq',
    UNDEFINED_SHAPE = 'u',
}

export enum ParsedShippingVisibility {
    Disabled = 'disabled',
    Empty = '',
    Pending = 'pending',
    Visible = 'enabled',
}

export enum ParsedShippingStatus {
    Confirmed = 'cf',
    Unconfirmed = 'nc',
}

export enum ParsedShippingDimensionCompletion {
    Complete = 'c',
    MissingDimensions = 'm',
}

export enum ParsedShippingDimensionUnits {
    CENTIMETERS = 'cm',
    FEET = 'ft',
    INCHES = 'in',
    METERS = 'm',
    MILLIMETERS = 'mm',
}

export enum ParsedShippingWeightUnits {
    GRAMS = 'gr',
    KILOGRAMS = 'kr',
    OUNCES = 'oz',
    POUNDS = 'lb',
}

export type ParsedCatalogShippingItemsSearchFilters = {
    completeDimensions?: ParsedShippingDimensionCompletion;
    confirmed?: ParsedShippingStatus;
    descriptionContains?: string;
    dimensionsUnit?: ParsedShippingDimensionUnits;
    fragility?: string;
    fullMatch?: boolean;
    largerSize?: number;
    packageType?: ParsedShippingPackageType;
    quantity?: number;
    shape?: ParsedShippingShape;
    titleContains?: string;
    visibility?: string;
    weight?: number;
};

export type FetchParsedShippingItemsParams = {
    catalogId: number;
    filters?: ParsedCatalogShippingItemsSearchFilters;
    page: number;
    pageSize: number;
};

export type ParsedCatalogShippingItem = {
    catalogId: number;
    depth: number;
    description: string;
    dimensionsUnitId: ParsedShippingDimensionUnits;
    height: number;
    houseId: number;
    imageUrl: string;
    lotId: number;
    lotNumber: string;
    packageTypeId: ParsedShippingPackageType;
    parsingFragilityId: ParsedShippingFragility;
    quantity: number;
    quoteVisibility: ParsedShippingVisibility;
    shape: ParsedShippingShape;
    statusId: ParsedShippingStatus | ParsedShippingDimensionCompletion;
    title: string;
    weight: number;
    weightUnitsId: ParsedShippingWeightUnits;
    width: number;
};

export type CatalogShippingParserState = {
    catalogId: number;
    catalogTitle: string;
    error: boolean;
    // additional information based on the type of error that occurred
    errorInfo: {
        unconfirmedLots: number[];
    };
    filters: ParsedCatalogShippingItemsSearchFilters;
    items: ParsedCatalogShippingItem[];
    loading: boolean;
    page: number;
    pageSize: number;
    reloadItems: boolean;
    sellerId: number;
    sellerName: string;
    success: boolean;
    totalRecords: number;
};

export const defaultCatalogShippingParserSlice: CatalogShippingParserState = {
    catalogId: 0,
    catalogTitle: '',
    error: false,
    errorInfo: {
        unconfirmedLots: [],
    },
    filters: {},
    items: [],
    loading: false,
    page: 1,
    pageSize: 500,
    reloadItems: false,
    sellerId: 0,
    sellerName: '',
    success: false,
    totalRecords: 0,
};

type CatalogItemsParserPayload = {
    catalogId: number;
    catalogTitle: string;
    lots: ParsedCatalogShippingItem[];
    records: number;
    sellerId: number;
    sellerName: string;
};

export type FetchParsedCatalogShippingItemsResponse = {
    error: boolean;
    payload: CatalogItemsParserPayload;
};

export type GetDownloadShippingItemsResponse = {
    error: boolean;
    payload: {
        bytes: string | Uint8Array;
    };
};

export type PostParseCatalogShippingItemsResponse = {
    error: boolean;
    payload: CatalogItemsParserPayload;
};

export type PostConfirmCatalogShippingItemsResponse = {
    error: boolean;
    payload: CatalogItemsParserPayload & { lotsWithError: number[] };
};

export type DeleteParsedCatalogShippingItemDimensionsResponse = {
    error: boolean;
    payload: {
        catalogId: number;
        lotIds: number[];
    };
};

export type GetDownloadShippingItemsParams = {
    catalogId: number;
};

export type PostParseShippingItemsParams = {
    catalogId: number;
    filters?: ParsedCatalogShippingItemsSearchFilters;
    page: number;
    pageSize: number;
};

export type PostConfirmItemsParams = {
    catalogId: number;
    editOnly: boolean;
    lots: ParsedCatalogShippingItem[];
    quoteVisibility?: ParsedShippingVisibility;
};

export type DeleteParsedShippingItemDimensionsParams = {
    catalogId: number;
    lotIds: number[];
};

export type PostConfirmItemsRejectedPayload = {
    failedLotIds: number[];
    successfulLots: ParsedCatalogShippingItem[];
};
