import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';
import { LOAD_HOUSE_STATUS_FAILURE, LOAD_HOUSE_STATUS_REQUEST, LOAD_HOUSE_STATUS_SUCCESS } from './actions';
import api from '../api/houseStatus';

export interface State {
    readonly error: string | null;
    readonly loading: boolean;
    readonly status: string | null;
}

export const DEFAULT_STATE = {
    error: undefined,
    loading: false,
    status: undefined,
};

export const reducer = handleActions(
    {
        [LOAD_HOUSE_STATUS_FAILURE]: (state: State, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [LOAD_HOUSE_STATUS_REQUEST]: (state: State) => ({
            ...state,
            loading: true,
        }),
        [LOAD_HOUSE_STATUS_SUCCESS]: (state: State, action: ActionWithPayload<any>) => ({
            ...state,
            loading: false,
            status: action.payload.status,
        }),
    },
    DEFAULT_STATE
);

const stateSelector = (state: GlobalState) => state.houseStatus;

export const getHouseStatus = createSelector(stateSelector, (state) => state.status);

export const getHouseStatusLoading = createSelector(stateSelector, (state) => state.loading);

export const fetchHouseStatus = (houseId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({ type: LOAD_HOUSE_STATUS_REQUEST });

        const response = await api.getHouseStatus({
            authToken,
            deployment,
            houseId,
        });

        dispatch({
            payload: response.payload,
            type: LOAD_HOUSE_STATUS_SUCCESS,
        });
    } catch (error) {
        dispatch({ error: true, payload: error, type: LOAD_HOUSE_STATUS_FAILURE });
    }
};
