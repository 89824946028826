export type Item = {
    catalogId: number;
    conditionReport: string;
    highBidEstimate: number;
    imageVersion: number;
    index: number;
    isDeleted: boolean;
    itemId: number;
    lotNumber: string;
    lowBidEstimate: number;
    photos: number[];
    sellerId: number;
    soldType: number;
    startPrice: number;
    title: string;
};

export const defaultItem: Item = {
    catalogId: 0,
    conditionReport: '',
    highBidEstimate: 0,
    imageVersion: 0,
    index: 0,
    isDeleted: false,
    itemId: 0,
    lotNumber: '',
    lowBidEstimate: 0,
    photos: [],
    sellerId: 0,
    soldType: 0,
    startPrice: 0,
    title: '',
};
