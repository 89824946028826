import { Disbursement, Payment, PayrixHouseAccount } from '../../../types/Payment';
import { TypedActionWithPayload } from '../../../types/redux';

export const REPORTING_FILTERS_CHANGE = 'REPORTING_FILTERS_CHANGE';
export const REPORTING_FILTERS_CHANGE_FAIL = 'REPORTING_FILTERS_CHANGE_FAIL';

export const PAYMENT_FILTERS_CHANGE = 'PAYMENT_FILTERS_CHANGE';
export const PAYMENT_FILTERS_CHANGE_FAIL = 'PAYMENT_FILTERS_CHANGE_FAIL';

export const DISBURSEMENT_FILTERS_CHANGE = 'DISBURSEMENTFILTERS_CHANGE';
export const DISBURSEMENT_FILTERS_CHANGE_FAIL = 'DISBURSEMENT_FILTERS_CHANGE_FAIL';

export const ADD_BANK_ACCOUNT_FAIL = 'ADD_BANK_ACCOUNT_FAIL';
export const ADD_BANK_ACCOUNT_REQUEST = 'ADD_BANK_ACCOUNT_REQUEST';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';

export const LOAD_ACCOUNT_SUMMARY_FAIL = 'LOAD_ACCOUNT_SUMMARY_FAIL';
export const LOAD_ACCOUNT_SUMMARY_REQUEST = 'LOAD_ACCOUNT_SUMMARY_REQUEST';
export const LOAD_ACCOUNT_SUMMARY_SUCCESS = 'LOAD_ACCOUNT_SUMMARY_SUCCESS';

export const LOAD_BANK_ACCOUNTS_FAIL = 'LOAD_BANK_ACCOUNTS_FAIL';
export const LOAD_BANK_ACCOUNTS_REQUEST = 'LOAD_BANK_ACCOUNTS_REQUEST';
export const LOAD_BANK_ACCOUNTS_SUCCESS = 'LOAD_BANK_ACCOUNTS_SUCCESS';

export const LOAD_BIDDER_PAYMENTS_FAIL = 'LOAD_BIDDER_PAYMENTS_FAIL';
export const LOAD_BIDDER_PAYMENTS_REQUEST = 'LOAD_BIDDER_PAYMENTS_REQUEST';
export const LOAD_BIDDER_PAYMENTS_SUCCESS = 'LOAD_BIDDER_PAYMENTS_SUCCESS';
export type LOAD_BIDDER_PAYMENTS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_BIDDER_PAYMENTS_SUCCESS,
    { payments: Payment[]; totalRecords: number }
>;
export const LOAD_DISBURSEMENTS_FAIL = 'LOAD_DISBURSEMENTS_FAIL';
export const LOAD_DISBURSEMENTS_REQUEST = 'LOAD_DISBURSEMENTS_REQUEST';
export const LOAD_DISBURSEMENTS_SUCCESS = 'LOAD_DISBURSEMENTS_SUCCESS';
export type LOAD_DISBURSEMENTS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_DISBURSEMENTS_SUCCESS,
    { disbursements: Disbursement[]; totalRecords: number }
>;

export const REMOVE_BANK_ACCOUNTS_FAIL = 'REMOVE_BANK_ACCOUNTS_FAIL';
export const REMOVE_BANK_ACCOUNTS_REQUEST = 'REMOVE_BANK_ACCOUNTS_REQUEST';
export const REMOVE_BANK_ACCOUNTS_SUCCESS = 'REMOVE_BANK_ACCOUNTS_SUCCESS';

export const UPDATE_PRIMARY_BANK_ACCOUNT_FAIL = 'UPDATE_PRIMARY_BANK_ACCOUNT_FAIL';
export const UPDATE_PRIMARY_BANK_ACCOUNT_REQUEST = 'UPDATE_PRIMARY_BANK_ACCOUNT_REQUEST';
export const UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS = 'UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS';
export type UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof UPDATE_PRIMARY_BANK_ACCOUNT_SUCCESS,
    { bankAccountId: number }
>;

export const LOAD_PAYRIX_HOUSE_ACCOUNT_FAIL = 'LOAD_PAYRIX_HOUSE_ACCOUNT_FAIL';
export const LOAD_PAYRIX_HOUSE_ACCOUNT_REQUEST = 'LOAD_PAYRIX_HOUSE_ACCOUNT_REQUEST';
export const LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS = 'LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS';
export type LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_PAYRIX_HOUSE_ACCOUNT_SUCCESS,
    PayrixHouseAccount[]
>;

export const TOGGLE_PAYOUT_TYPE_FAIL = 'TOGGLE_PAYOUT_TYPE_FAIL';
export const TOGGLE_PAYOUT_TYPE_REQUEST = 'TOGGLE_PAYOUT_TYPE_REQUEST';
export const TOGGLE_PAYOUT_TYPE_SUCCESS = 'TOGGLE_PAYOUT_TYPE_SUCCESS';
