import { FacetOption } from '@liveauctioneers/caterwaul-components/types/Facet';
import { handleResponse, makeGet, makePost } from './helpers';
import { PartialLot } from '@/redux/modules/categorizer';
import { TaxonomyEntry, TaxonomyPayload } from '@/types/Taxonomy';

interface GetAllTaxonomiesParams {
    authToken: string;
    categoryId?: number;
    deployment: string;
    searchTerm?: string;
}

interface GetLotIdParams {
    authToken: string;
    catalogId: number;
    categoryId?: number;
    categoryIds: number[];
    deployment: string;
    page: number;
    pageSize: number | 'all';
    searchTerm?: string;
}

interface GetCatalogNameParams {
    authToken: string;
    catalogId: number;
    deployment: string;
    houseId: number;
    lotIds: number[];
}

interface AddTagToLotsParams {
    authToken: string;
    catalogId: number;
    categoryId: number;
    deployment: string;
    lotIds: number[];
}

interface RemoveTagFromLotParams {
    authToken: string;
    catalogId: number;
    categoryId: number;
    deployment: string;
    lotId: number;
}

interface GetFacetsParams {
    authToken: string;
    catalogId: number;
    categoryIds: number[];
    deployment: string;
}

type AddTagLotsResponsePayload = {
    error: boolean;
    payload: any; // can be result of Catch error which is always any
};

type RemoveTagLotResponsePayload = {
    error: boolean;
    payload: any; // can be result of Catch error which is always any
};

type GetLotIdsQueryParams = {
    catalogId: number;
    categoryId?: number;
    page: number;
    pageSize: number | 'all';
    searchTerm?: string;
};

type GetLotIdsResponsePayload = {
    error: boolean;
    payload: { lotCategories: { categories: TaxonomyEntry[]; lotId: number }[]; total: number };
};

type GetLotNamesResponsePayload = {
    error: boolean;
    payload: PartialLot[];
};

type GetAllTaxonomiesResponsePayload = {
    error: boolean;
    payload: TaxonomyPayload;
};

type GetFacetsResponsePayload = {
    error: boolean;
    payload: { facets: FacetOption[]; missingCounts: { 1: number; 2: number; 3: number; 4: number; 5: number } };
};

const addTagToLots = ({ authToken, catalogId, categoryId, deployment, lotIds }: AddTagToLotsParams) =>
    new Promise<AddTagLotsResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: 'tagcategorylots',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ catalogId, categoryId, lotIds });
        request.end((err: boolean, response: AddTagLotsResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

const getAllTaxonomies = ({ authToken, categoryId, deployment, searchTerm }: GetAllTaxonomiesParams) =>
    new Promise<GetAllTaxonomiesResponsePayload>((resolve, reject) => {
        const requestArgs = { facetId: categoryId, searchTerm };

        const request = makeGet({
            apiPath: 'gettaxonomiesall',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query(requestArgs);
        request.end((err: boolean, response: GetAllTaxonomiesResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

const getLotIds = ({
    authToken,
    catalogId,
    categoryId,
    categoryIds,
    deployment,
    page,
    pageSize,
    searchTerm,
}: GetLotIdParams) =>
    new Promise<GetLotIdsResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getcategorylots',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        const queryParams: GetLotIdsQueryParams = { catalogId, page, pageSize };
        if (categoryId) {
            queryParams.categoryId = categoryId;
        }
        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }
        request.query(queryParams);
        request.send({
            categoryIds,
        });
        request.end((err: boolean, response: GetLotIdsResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

const getLotNames = ({ authToken, catalogId, deployment, houseId, lotIds }: GetCatalogNameParams) =>
    new Promise<GetLotNamesResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${houseId}/catalog/${catalogId}/lots`,
            authToken,
            deployment,
            path: '<CATALOG-MANAGEMENT-API>',
        });
        request.send({ lotIds });
        request.end((err: boolean, response: GetLotNamesResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

const removeTagFromLot = ({ authToken, catalogId, categoryId, deployment, lotId }: RemoveTagFromLotParams) =>
    new Promise<RemoveTagLotResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: 'removecategorylot',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.send({ catalogId, categoryId, lotId });
        request.end((err: boolean, response: RemoveTagLotResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

const getFacets = ({ authToken, catalogId, categoryIds, deployment }: GetFacetsParams) =>
    new Promise<GetFacetsResponsePayload>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getfacets',
            authToken,
            deployment,
            path: '<CATEGORY-API>',
        });
        request.query({ catalogId });
        request.send({ categoryIds }); // ! Name Change?
        request.end((err: boolean, response: GetFacetsResponsePayload) =>
            handleResponse({ err, reject, resolve, response })
        );
    });

export default {
    addTagToLots,
    getAllTaxonomies,
    getFacets,
    getLotIds,
    getLotNames,
    removeTagFromLot,
};
