import { CatalogPromotion, Promotion } from '@/types/Promotion';

export const createPromotionObject = (
    catalogId: number,
    sellerId: number,
    selectedLots: number[],
    tabSelected: 'Minimal' | 'Standard' | 'Premium',
    previousSelectedPromotion?: Promotion | null | undefined,
    previousSelectedCatalog?: CatalogPromotion
) => {
    let promotionPlanId;
    switch (tabSelected) {
        case 'Minimal':
            promotionPlanId = 1;
            break;
        case 'Standard':
            promotionPlanId = 2;
            break;
        case 'Premium':
            promotionPlanId = 3;
            break;
        default:
    }

    if (promotionPlanId === 1) {
        selectedLots = [];
    }

    let selectedPromotion: {
        catalog: CatalogPromotion;
        houseId?: number;
        promotion?: Promotion;
        promotionPlan?: {
            promotionPlanId?: number;
        };
        selectedLots: number[];
    };

    if (previousSelectedPromotion) {
        previousSelectedPromotion.promotionPlan.promotionPlanId = promotionPlanId;
        selectedPromotion = {
            catalog: previousSelectedCatalog,
            promotion: previousSelectedPromotion,
            selectedLots,
        };
    } else {
        selectedPromotion = {
            catalog: {
                catalogId,
            },
            houseId: Number(sellerId),
            promotionPlan: {
                promotionPlanId,
            },
            selectedLots,
        };
    }

    return selectedPromotion;
};
