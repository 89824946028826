import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { LOAD_CAMPAIGN_FAIL, LOAD_CAMPAIGN_REQUEST, LOAD_CAMPAIGN_SUCCESS } from './actions';
import api from '../api/campaign';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type Campaign = {
    bids: number;
    catalogId: number;
    catalogStatus: string;
    clickMap: any[];
    date: string;
    delivered: number;
    endDate: string;
    newFollowers: number;
    openRate: number;
    pageViews: number;
    registrations: number;
    sales: number;
    salesGrowth: number;
    sellerId: number;
    timeSent: string;
    title: string;
    totalClicks: number;
    totalImpressions: number;
    uniqueClicks: number;
    uniqueImpressions: number;
    uniqueOpens: number;
    winningBidders: number;
};

const emptyCampaign: Campaign = {
    bids: 0,
    catalogId: 0,
    catalogStatus: '',
    clickMap: [],
    date: '',
    delivered: 0,
    endDate: '',
    newFollowers: 0,
    openRate: 0,
    pageViews: 0,
    registrations: 0,
    sales: 0,
    salesGrowth: 0,
    sellerId: 0,
    timeSent: '',
    title: '',
    totalClicks: 0,
    totalImpressions: 0,
    uniqueClicks: 0,
    uniqueImpressions: 0,
    uniqueOpens: 0,
    winningBidders: 0,
};

export type State = {
    byId: { [campaignId: string]: Campaign };
    loaded: { [campaignId: string]: number };
    loading: string[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_CAMPAIGN_FAIL]: (state: State, action: ActionWithPayload<{}, { campaignId: string }>) => ({
            ...state,
            loading: difference(state.loading, [action.meta.campaignId]),
        }),
        [LOAD_CAMPAIGN_REQUEST]: (state: State, action: ActionWithPayload<string[]>) => ({
            ...state,
            loading: union(state.loading, action.payload),
        }),
        [LOAD_CAMPAIGN_SUCCESS]: (
            state: State,
            action: ActionWithPayload<Campaign, { actionTime: number; campaignId: string }>
        ) => {
            const existing = cloneDeep(state.byId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const time = action.meta.actionTime;

            existing[action.meta.campaignId] = {
                ...action.payload,
            };
            loaded[action.meta.campaignId] = time;
            loading = difference(loading, [action.meta.campaignId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.campaign;
const idSelector = (_: GlobalState, id: string) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const getCampaign = createSelector(
    [byIdSelector, idSelector],
    (byId, campaignId) => byId[campaignId] || emptyCampaign
);

const getLoadTimeForCampaign = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isCampaignLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchCampaign = createSelector(
    [getCampaign, getLoadTimeForCampaign, isCampaignLoading],
    (catalog, loaded, loading) => {
        if (catalog) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

/* ACTION CREATORS */
const loadCampaigns = (campaignId: string, houseId: number, queryParams: any) => async (dispatch, getState) => {
    const eventId = queryParams?.eventId;
    const eventType = queryParams?.eventType;
    const byIdKey =
        Boolean(queryParams.eventId) && Boolean(queryParams.eventType)
            ? `${queryParams.eventId}${queryParams.eventType.replace(/\s+/g, '')}`
            : campaignId;

    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            payload: byIdKey,
            type: LOAD_CAMPAIGN_REQUEST,
        });
        const response = await api.fetchCampaignById({
            authToken,
            campaignId,
            deployment,
            eventId,
            eventType,
            houseId,
        });
        return dispatch({
            meta: { actionTime: Date.now(), campaignId: byIdKey },
            payload: response.payload,
            type: LOAD_CAMPAIGN_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { campaignId: byIdKey },
            payload: error,
            type: LOAD_CAMPAIGN_FAIL,
        });
    }
};

export const fetchCampaignIfNeeded =
    (campaignId: string, houseId: number, queryParams: any) => async (dispatch: Function, getState: Function) => {
        if (Boolean(queryParams.eventId) && Boolean(queryParams.eventType)) {
            if (shouldFetchCampaign(getState(), `${queryParams.eventId}${queryParams.eventType}`)) {
                return dispatch(loadCampaigns('', houseId, queryParams));
            }
        } else {
            if (shouldFetchCampaign(getState(), campaignId.toString())) {
                return dispatch(loadCampaigns(campaignId, houseId, queryParams));
            }
        }

        return Promise.resolve();
    };
