import {
    CONVERSATIONS_ACTION_FAIL,
    CONVERSATIONS_ACTION_REQUEST,
    CONVERSATIONS_ACTION_SUCCESS,
    LOAD_CONVERSATION_FAIL,
    LOAD_CONVERSATION_REQUEST,
    LOAD_CONVERSATION_SUCCESS,
    LOAD_CONVERSATIONS_FAIL,
    LOAD_CONVERSATIONS_REQUEST,
    LOAD_CONVERSATIONS_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { fetchConversationFoldersIfNeeded } from './conversationFolders';
import { getAuthToken } from '../modules/user';
import { getDeployment } from '../modules/config';
import api from '../api/conversation';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
export type State = {
    byId: { [id: number]: any };
    loaded: number;
    loading: boolean;
    pagination: {
        page: number;
        pageSize: number;
        totalPages: number;
        totalResults: number;
    };
    shownConversations: number[];
};

export const DEFAULT_STATE: State = {
    byId: {},
    loaded: 0,
    loading: false,
    pagination: {
        page: 1,
        pageSize: 48,
        totalPages: 1,
        totalResults: 0,
    },
    shownConversations: [],
};

export default function reducer(state: State = DEFAULT_STATE, action: any = {}): State {
    let existing: State['byId'];
    let existingPagination: State['pagination'];
    let shownConversations: State['shownConversations'];

    switch (action.type) {
        case LOAD_CONVERSATIONS_FAIL:
        case LOAD_CONVERSATION_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_CONVERSATIONS_REQUEST:
        case LOAD_CONVERSATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_CONVERSATIONS_SUCCESS:
            existing = cloneDeep(state.byId);
            existingPagination = { ...state.pagination };
            shownConversations = [];

            if (action.payload.payload) {
                action.payload.payload.forEach((convo) => {
                    existing[convo.conversationId] = { ...convo };
                    shownConversations.push(convo.conversationId);
                });
            }

            if (action.payload?.meta?.pagination) {
                const { pagination } = action.payload.meta;
                existingPagination = {
                    page: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    totalPages: pagination.totalPages,
                    totalResults: pagination.totalRecordCount,
                };
            }

            return {
                ...state,
                byId: existing,
                loaded: action.meta.actionTime,
                loading: false,
                pagination: existingPagination,
                shownConversations,
            };
        case LOAD_CONVERSATION_SUCCESS:
            existing = cloneDeep(state.byId);

            if (action.payload.payload) {
                action.payload.payload.forEach((convo) => {
                    existing[convo.conversationId] = { ...convo };
                });
            }

            return {
                ...state,
                byId: existing,
                loaded: action.meta.actionTime,
                loading: false,
            };
        default:
            return state;
    }
}

const stateSelector = (state): State => state.conversation;
const idSelector = (state: State, id: number) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getLoadTimeForConversation = createSelector(stateSelector, (state) => state.loaded);

export const getConversationLoading = createSelector(stateSelector, (state) => state.loading);

const conversationsSelector = createSelector(stateSelector, (state) => state.shownConversations);

export const getConversationPagination = createSelector(stateSelector, (state) => state.pagination);

export const getConversations = createSelector([conversationsSelector, byIdSelector], (conversations, byId) =>
    conversations.map((convo) => byId[convo])
);

export const getConversation = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

const shouldFetchConversation = createSelector(
    [getConversation, getLoadTimeForConversation, getConversationLoading],
    (conversation, loaded, loading) => {
        return !loading;
    }
);

type ConversationQueryType = {
    bidderIdFilter?: string;
    filter?: string;
    folderId: number;
    page?: number;
    pageSize?: number;
    search?: string;
    // sort: string,
};

const loadConversations = (query: ConversationQueryType, houseId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: LOAD_CONVERSATIONS_REQUEST,
        });
        const response = await api.fetchConversations({ authToken, deployment, houseId, query });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response,
            type: LOAD_CONVERSATIONS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_CONVERSATIONS_FAIL,
        });
    }
};

const loadConversation = (conversationId: number, houseId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_CONVERSATION_REQUEST,
        });
        const response = await api.fetchConversation({ authToken, conversationId, deployment, houseId });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response,
            type: LOAD_CONVERSATION_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_CONVERSATION_FAIL,
        });
    }
};

export const fetchConversationsIfNeeded =
    (query: ConversationQueryType, houseId: number) => async (dispatch: Function) =>
        dispatch(loadConversations(query, houseId));

export const fetchConversationIfNeeded =
    (conversationId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        if (shouldFetchConversation(getState(), conversationId)) {
            return dispatch(loadConversation(conversationId, houseId));
        }
        return Promise.resolve();
    };

const actions = {
    archive: api.archiveConversation,
    markRead: api.markConversationRead,
    markUnread: api.markConversationUnread,
    move: api.moveConversation,
};

export const conversationAction =
    (conversations: any[], action: string, query: any, houseId: number, markOnly: boolean = false, folderId?: number) =>
    async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: CONVERSATIONS_ACTION_REQUEST,
            });
            const response = await actions[action]({ authToken, conversations, deployment, folderId, houseId });

            dispatch(fetchConversationFoldersIfNeeded(houseId));

            dispatch({
                meta: { action, actionTime: Date.now(), conversations },
                payload: response,
                type: CONVERSATIONS_ACTION_SUCCESS,
            });

            if (markOnly) {
                return Promise.resolve();
            }
            return dispatch(fetchConversationsIfNeeded(query, houseId));
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: CONVERSATIONS_ACTION_FAIL,
            });
        }
    };
