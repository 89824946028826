import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import {
    LOAD_CATALOG_REGISTRATION_INFO_FAIL,
    LOAD_CATALOG_REGISTRATION_INFO_REQUEST,
    LOAD_CATALOG_REGISTRATION_INFO_SUCCESS,
} from './actions';
import api from '../api/dashboard';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
export type State = {
    byCatalogId: { [id: number]: any[] };
    loaded: { [id: number]: number | 'now' };
    loading: number[];
};

export const DEFAULT_STATE: State = {
    byCatalogId: {},
    loaded: {},
    loading: [],
};

export const reducer = handleActions(
    {
        [LOAD_CATALOG_REGISTRATION_INFO_FAIL]: (
            state: State,
            action: ActionWithPayload<{}, { catalogId: number }>
        ) => ({
            ...state,
            // @ts-ignore
            loading: difference(state.loading, action.meta.catalogId),
        }),
        [LOAD_CATALOG_REGISTRATION_INFO_REQUEST]: (state: State, action: ActionWithPayload<number>) => ({
            ...state,
            loading: union(state.loading, [action.payload]),
        }),
        [LOAD_CATALOG_REGISTRATION_INFO_SUCCESS]: (
            state: State,
            action: ActionWithPayload<any[], { actionTime: number; catalogId: number }>
        ) => {
            const existing = cloneDeep(state.byCatalogId);
            const loaded = { ...state.loaded };
            let loading = cloneDeep(state.loading);
            const {
                meta: { actionTime, catalogId },
                payload,
            } = action;
            const time = actionTime;

            if (action.payload) {
                existing[catalogId] = payload;
                loaded[catalogId] = time;
                loading = difference(loading, [catalogId]);
            }

            return {
                ...state,
                byCatalogId: existing,
                loaded,
                loading,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.catalogRegistrationInfo;
const idSelector = (state, id) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byCatalogId);

const loadedSelector = createSelector(stateSelector, (state) => state.loaded);

const loadingSelector = createSelector(stateSelector, (state) => state.loading);

export const catalogRegistrationInfoSelector = createSelector([byIdSelector, idSelector], (byCatalogId, id) => {
    return byCatalogId[id] || {};
});

export const loadTimeForCatalogRegistrationInfoSelector = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isCatalogRegistrationInfoLoadingSelector = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

const shouldFetchCatalogRegistrationInfo = createSelector(
    [isCatalogRegistrationInfoLoadingSelector],
    (loading) => !loading
);

/* ACTION CREATORS */
const loadCatalogRegistrationInfo = (catalogId: number, houseId: number) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            payload: catalogId,
            type: LOAD_CATALOG_REGISTRATION_INFO_REQUEST,
        });
        const response = await api.fetchCatalogRegistrationInfo({
            authToken,
            catalogId,
            deployment,
            houseId,
        });
        return dispatch({
            meta: { actionTime: Date.now(), catalogId },
            payload: response,
            type: LOAD_CATALOG_REGISTRATION_INFO_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { catalogId },
            payload: error,
            type: LOAD_CATALOG_REGISTRATION_INFO_FAIL,
        });
    }
};

export const fetchCatalogRegistrationInfoIfNeeded =
    (catalogId: number, houseId: number) => async (dispatch: Function, getState: Function) => {
        const needed = shouldFetchCatalogRegistrationInfo(getState(), catalogId);
        if (needed) {
            return dispatch(loadCatalogRegistrationInfo(catalogId, houseId));
        }
        return Promise.resolve();
    };
