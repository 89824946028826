import { Amount } from '@liveauctioneers/caterwaul-components/lib/types/Amount';
import { createSelector } from '@reduxjs/toolkit';
import {
    DELETE_INVOICE_ADJUSTMENT_FAIL,
    DELETE_INVOICE_ADJUSTMENT_FAIL_ACTION,
    DELETE_INVOICE_ADJUSTMENT_REQUEST,
    DELETE_INVOICE_ADJUSTMENT_REQUEST_ACTION,
    DELETE_INVOICE_ADJUSTMENT_SUCCESS,
    DELETE_INVOICE_PAYMENT_FAIL,
    DELETE_INVOICE_PAYMENT_FAIL_ACTION,
    DELETE_INVOICE_PAYMENT_REQUEST,
    DELETE_INVOICE_PAYMENT_REQUEST_ACTION,
    DELETE_INVOICE_PAYMENT_SUCCESS,
    LOAD_INVOICE_FAIL,
    LOAD_INVOICE_FAIL_ACTION,
    LOAD_INVOICE_REQUEST,
    LOAD_INVOICE_REQUEST_ACTION,
    LOAD_INVOICE_SEND_HISTORY_FAIL,
    LOAD_INVOICE_SEND_HISTORY_FAIL_ACTION,
    LOAD_INVOICE_SEND_HISTORY_REQUEST,
    LOAD_INVOICE_SEND_HISTORY_SUCCESS,
    LOAD_INVOICE_SEND_HISTORY_SUCCESS_ACTION,
    LOAD_INVOICE_SUCCESS,
    LOAD_INVOICE_SUCCESS_ACTION,
    POST_INVOICE_ADD_PAYMENT_FAIL,
    POST_INVOICE_ADD_PAYMENT_FAIL_ACTION,
    POST_INVOICE_ADD_PAYMENT_REQUEST,
    POST_INVOICE_ADD_PAYMENT_SUCCESS,
    POST_INVOICE_ADJUSTMENT_FAIL,
    POST_INVOICE_ADJUSTMENT_FAIL_ACTION,
    POST_INVOICE_ADJUSTMENT_REQUEST,
    POST_INVOICE_ADJUSTMENT_SUCCESS,
    POST_INVOICE_MARK_PAID_FAIL,
    POST_INVOICE_MARK_PAID_FAIL_ACTION,
    POST_INVOICE_MARK_PAID_REQUEST,
    POST_INVOICE_MARK_PAID_SUCCESS,
    POST_INVOICE_SEND_FAIL,
    POST_INVOICE_SEND_FAIL_ACTION,
    POST_INVOICE_SEND_REQUEST,
    POST_INVOICE_SEND_SUCCESS,
    POST_INVOICE_SHIPPING_METHOD_FAIL,
    POST_INVOICE_SHIPPING_METHOD_FAIL_ACTION,
    POST_INVOICE_SHIPPING_METHOD_REQUEST,
    POST_INVOICE_SHIPPING_METHOD_SUCCESS,
    POST_INVOICE_UPDATE_SHIPPING_FAIL,
    POST_INVOICE_UPDATE_SHIPPING_FAIL_ACTION,
    POST_INVOICE_UPDATE_SHIPPING_REQUEST,
    POST_INVOICE_UPDATE_SHIPPING_SUCCESS,
    POST_REFUND_TAX_FAIL,
    POST_REFUND_TAX_FAIL_ACTION,
    POST_REFUND_TAX_REQUEST,
    POST_REFUND_TAX_SUCCESS,
    POST_VOID_TAX_FAIL,
    POST_VOID_TAX_FAIL_ACTION,
    POST_VOID_TAX_REQUEST,
    POST_VOID_TAX_SUCCESS,
    RECALCULATE_INVOICE_FAIL,
    RECALCULATE_INVOICE_FAIL_ACTION,
    RECALCULATE_INVOICE_REQUEST,
    RECALCULATE_INVOICE_SUCCESS,
    RECALCULATE_INVOICE_SUCCESS_ACTION,
    REFUND_INVOICE_FAIL,
    REFUND_INVOICE_FAIL_ACTION,
    REFUND_INVOICE_REQUEST,
    REFUND_INVOICE_SUCCESS,
} from './actions';
import {
    deleteBidderInvoiceAdjustment,
    deleteBidderInvoicePayment,
    getBidderInvoiceHouseView,
    getBidderInvoiceSendHistory,
    getRefundInvoiceCalculate,
    postBidderInvoiceAddPayment,
    postBidderInvoiceAdjustment,
    postBidderInvoiceMarkPaid,
    postBidderInvoiceSend,
    postBidderInvoiceShipping,
    postRefundTax,
    postVoidTax,
    sendRefundInvoice,
} from '../api';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { Invoice, InvoiceAlteration, InvoiceSendHistory, Lots } from '@/types/Invoice';
import ms from 'ms';

const REDUX_STORE_TIME_ITEM = ms('20s');

/* reducer */
export const DEFAULT_STATE = {
    addAdjustmentError: undefined,
    addAdjustmentLoading: false,
    addAdjustmentSuccess: false,
    addPaymentError: undefined,
    addPaymentLoading: false,
    addPaymentSuccess: false,
    deleteAdjustmentError: undefined,
    deleteAdjustmentLoadingId: 0,
    deleteAdjustmentSuccess: false,
    deletePaymentError: undefined,
    deletePaymentLoadingId: 0,
    deletePaymentSuccess: false,
    invoice: undefined,
    invoiceId: undefined,
    invoiceLoaded: undefined,
    invoiceLoadError: undefined,
    invoiceLoading: false,
    invoiceSendHistory: undefined,
    invoiceSendHistoryLoaded: undefined,
    invoiceSendHistoryLoadError: undefined,
    invoiceSendHistoryLoading: false,
    markPaidError: undefined,
    markPaidLoading: false,
    markPaidSuccess: false,
    recalculateError: undefined,
    recalculateLoading: false,
    recalculateSuccess: false,
    refundError: undefined,
    refundLoading: false,
    refundSuccess: false,
    refundTaxError: undefined,
    refundTaxLoading: false,
    refundTaxSuccess: false,
    sendError: undefined,
    sendLoading: false,
    sendSuccess: false,
    shippingMethodError: undefined,
    shippingMethodLoading: false,
    shippingMethodSuccess: false,
    shippingRefundAmount: 0,
    taxError: undefined,
    taxLoading: false,
    taxSuccess: false,
    totalBPRefundAmount: 0,
    totalHammerRefundAmount: 0,
    totalRefund: 0,
    totalSalesTaxRefundAmount: 0,
    updateShippingError: undefined,
    updateShippingLoading: false,
    updateShippingSuccess: false,
};

export type State = {
    addAdjustmentError?: string;
    addAdjustmentLoading: boolean;
    addAdjustmentSuccess: boolean;
    addPaymentError?: string;
    addPaymentLoading: boolean;
    addPaymentSuccess: boolean;
    deleteAdjustmentError?: string;
    deleteAdjustmentLoadingId: number;
    deleteAdjustmentSuccess: boolean;
    deletePaymentError?: string;
    deletePaymentLoadingId: number;
    deletePaymentSuccess: boolean;
    invoice?: Invoice;
    invoiceId?: number;
    invoiceLoadError?: string;
    invoiceLoaded?: Date;
    invoiceLoading: boolean;
    invoiceSendHistory?: InvoiceSendHistory[];
    invoiceSendHistoryLoadError?: string;
    invoiceSendHistoryLoaded?: Date;
    invoiceSendHistoryLoading: boolean;
    markPaidError?: string;
    markPaidLoading: boolean;
    markPaidSuccess: boolean;
    recalculateError?: string;
    recalculateLoading?: boolean;
    recalculateSuccess?: boolean;
    refundError?: string;
    refundLoading?: boolean;
    refundSuccess?: boolean;
    refundTaxError: string;
    refundTaxLoading: boolean;
    refundTaxSuccess: boolean;
    sendError?: string;
    sendLoading: boolean;
    sendSuccess: boolean;
    shippingMethodError?: string;
    shippingMethodLoading: boolean;
    shippingMethodSuccess: boolean;
    shippingRefundAmount: number;
    taxError: string;
    taxLoading: boolean;
    taxSuccess: boolean;
    totalBPRefundAmount: number;
    totalHammerRefundAmount: number;
    totalRefund: number;
    totalSalesTaxRefundAmount: number;
    updateShippingError?: string;
    updateShippingLoading: boolean;
    updateShippingSuccess: boolean;
};

export const reducer = handleActions(
    {
        [DELETE_INVOICE_ADJUSTMENT_FAIL]: (state: State, action: DELETE_INVOICE_ADJUSTMENT_FAIL_ACTION): State => ({
            ...state,
            deleteAdjustmentError: action.payload,
            deleteAdjustmentLoadingId: 0,
            deleteAdjustmentSuccess: false,
        }),
        [DELETE_INVOICE_ADJUSTMENT_REQUEST]: (
            state: State,
            action: DELETE_INVOICE_ADJUSTMENT_REQUEST_ACTION
        ): State => ({
            ...state,
            deleteAdjustmentError: undefined,
            deleteAdjustmentLoadingId: action.payload,
            deleteAdjustmentSuccess: false,
        }),
        [DELETE_INVOICE_ADJUSTMENT_SUCCESS]: (state: State): State => ({
            ...state,
            deleteAdjustmentLoadingId: 0,
            deleteAdjustmentSuccess: true,
            invoiceLoaded: undefined,
        }),
        [DELETE_INVOICE_PAYMENT_FAIL]: (state: State, action: DELETE_INVOICE_PAYMENT_FAIL_ACTION): State => ({
            ...state,
            deletePaymentError: action.payload,
            deletePaymentLoadingId: 0,
            deletePaymentSuccess: false,
        }),
        [DELETE_INVOICE_PAYMENT_REQUEST]: (state: State, action: DELETE_INVOICE_PAYMENT_REQUEST_ACTION): State => ({
            ...state,
            deletePaymentError: undefined,
            deletePaymentLoadingId: action.payload,
            deletePaymentSuccess: false,
        }),
        [DELETE_INVOICE_PAYMENT_SUCCESS]: (state: State): State => ({
            ...state,
            deletePaymentLoadingId: 0,
            deletePaymentSuccess: true,
            invoiceLoaded: undefined,
        }),
        [LOAD_INVOICE_FAIL]: (state: State, action: LOAD_INVOICE_FAIL_ACTION): State => ({
            ...state,
            invoiceLoadError: action.payload,
            invoiceLoading: false,
        }),
        [LOAD_INVOICE_REQUEST]: (state: State, action: LOAD_INVOICE_REQUEST_ACTION): State => ({
            ...state,
            invoiceId: action.payload,
            invoiceLoaded: undefined,
            invoiceLoading: true,
        }),
        [LOAD_INVOICE_SEND_HISTORY_FAIL]: (state: State, action: LOAD_INVOICE_SEND_HISTORY_FAIL_ACTION): State => ({
            ...state,
            invoiceSendHistoryLoadError: action.payload,
            invoiceSendHistoryLoading: false,
        }),
        [LOAD_INVOICE_SEND_HISTORY_REQUEST]: (state: State): State => ({
            ...state,
            invoiceSendHistoryLoaded: undefined,
            invoiceSendHistoryLoading: true,
        }),
        [LOAD_INVOICE_SEND_HISTORY_SUCCESS]: (
            state: State,
            action: LOAD_INVOICE_SEND_HISTORY_SUCCESS_ACTION
        ): State => ({
            ...state,
            invoiceSendHistory: action.payload,
            invoiceSendHistoryLoaded: new Date(),
            invoiceSendHistoryLoadError: undefined,
            invoiceSendHistoryLoading: false,
        }),
        [LOAD_INVOICE_SUCCESS]: (state: State, action: LOAD_INVOICE_SUCCESS_ACTION): State => ({
            ...state,
            invoice: action.payload,
            invoiceLoaded: new Date(),
            invoiceLoadError: undefined,
            invoiceLoading: false,
        }),
        [POST_INVOICE_ADD_PAYMENT_FAIL]: (state: State, action: POST_INVOICE_ADD_PAYMENT_FAIL_ACTION): State => ({
            ...state,
            addPaymentError: action.payload,
            addPaymentLoading: false,
            addPaymentSuccess: false,
        }),
        [POST_INVOICE_ADD_PAYMENT_REQUEST]: (state: State): State => ({
            ...state,
            addPaymentError: undefined,
            addPaymentLoading: true,
            addPaymentSuccess: false,
        }),
        [POST_INVOICE_ADD_PAYMENT_SUCCESS]: (state: State): State => ({
            ...state,
            addPaymentLoading: false,
            addPaymentSuccess: true,
            invoiceLoaded: undefined,
        }),
        [POST_INVOICE_ADJUSTMENT_FAIL]: (state: State, action: POST_INVOICE_ADJUSTMENT_FAIL_ACTION): State => ({
            ...state,
            addAdjustmentError: action.payload,
            addAdjustmentLoading: false,
            addAdjustmentSuccess: false,
        }),
        [POST_INVOICE_ADJUSTMENT_REQUEST]: (state: State): State => ({
            ...state,
            addAdjustmentError: undefined,
            addAdjustmentLoading: true,
            addAdjustmentSuccess: false,
        }),
        [POST_INVOICE_ADJUSTMENT_SUCCESS]: (state: State): State => ({
            ...state,
            addAdjustmentLoading: false,
            addAdjustmentSuccess: true,
            invoiceLoaded: undefined,
        }),
        [POST_INVOICE_MARK_PAID_FAIL]: (state: State, action: POST_INVOICE_MARK_PAID_FAIL_ACTION): State => ({
            ...state,
            markPaidError: action.payload,
            markPaidLoading: false,
            markPaidSuccess: false,
        }),
        [POST_INVOICE_MARK_PAID_REQUEST]: (state: State): State => ({
            ...state,
            markPaidError: undefined,
            markPaidLoading: true,
            markPaidSuccess: false,
        }),
        [POST_INVOICE_MARK_PAID_SUCCESS]: (state: State): State => ({
            ...state,
            invoice: {
                ...state.invoice,
                paid: true,
            },
            markPaidError: undefined,
            markPaidLoading: false,
            markPaidSuccess: true,
        }),
        [POST_INVOICE_SEND_FAIL]: (state: State, action: POST_INVOICE_SEND_FAIL_ACTION): State => ({
            ...state,
            sendError: action.payload,
            sendLoading: false,
            sendSuccess: false,
        }),
        [POST_INVOICE_SEND_REQUEST]: (state: State): State => ({
            ...state,
            sendError: undefined,
            sendLoading: true,
            sendSuccess: false,
        }),
        [POST_INVOICE_SEND_SUCCESS]: (state: State): State => ({
            ...state,
            invoiceLoaded: undefined,
            sendLoading: false,
            sendSuccess: true,
        }),
        [POST_INVOICE_SHIPPING_METHOD_FAIL]: (
            state: State,
            action: POST_INVOICE_SHIPPING_METHOD_FAIL_ACTION
        ): State => ({
            ...state,
            shippingMethodError: action.payload,
            shippingMethodLoading: false,
            shippingMethodSuccess: false,
        }),
        [POST_INVOICE_SHIPPING_METHOD_REQUEST]: (state: State): State => ({
            ...state,
            shippingMethodError: undefined,
            shippingMethodLoading: true,
            shippingMethodSuccess: false,
        }),
        [POST_INVOICE_SHIPPING_METHOD_SUCCESS]: (state: State): State => ({
            ...state,
            shippingMethodLoading: false,
            shippingMethodSuccess: true,
        }),
        [POST_INVOICE_UPDATE_SHIPPING_FAIL]: (
            state: State,
            action: POST_INVOICE_UPDATE_SHIPPING_FAIL_ACTION
        ): State => ({
            ...state,
            updateShippingError: action.payload,
            updateShippingLoading: false,
            updateShippingSuccess: false,
        }),
        [POST_INVOICE_UPDATE_SHIPPING_REQUEST]: (state: State): State => ({
            ...state,
            updateShippingError: undefined,
            updateShippingLoading: true,
            updateShippingSuccess: false,
        }),
        [POST_INVOICE_UPDATE_SHIPPING_SUCCESS]: (state: State): State => ({
            ...state,
            invoiceLoaded: undefined,
            updateShippingLoading: false,
            updateShippingSuccess: true,
        }),
        [POST_REFUND_TAX_FAIL]: (state: State, action: POST_REFUND_TAX_FAIL_ACTION): State => ({
            ...state,
            refundTaxError: action.payload,
            refundTaxLoading: false,
            refundTaxSuccess: false,
        }),
        [POST_REFUND_TAX_REQUEST]: (state: State): State => ({
            ...state,
            refundTaxError: undefined,
            refundTaxLoading: true,
            refundTaxSuccess: false,
        }),
        [POST_REFUND_TAX_SUCCESS]: (state: State): State => ({
            ...state,
            invoiceLoaded: undefined,
            refundTaxError: undefined,
            refundTaxLoading: false,
            refundTaxSuccess: true,
        }),
        [POST_VOID_TAX_FAIL]: (state: State, action: POST_VOID_TAX_FAIL_ACTION): State => ({
            ...state,
            taxError: action.payload,
            taxLoading: false,
            taxSuccess: false,
        }),
        [POST_VOID_TAX_REQUEST]: (state: State): State => ({
            ...state,
            taxError: undefined,
            taxLoading: true,
            taxSuccess: false,
        }),
        [POST_VOID_TAX_SUCCESS]: (state: State): State => ({
            ...state,
            invoiceLoaded: undefined,
            taxError: undefined,
            taxLoading: false,
            taxSuccess: true,
        }),
        [RECALCULATE_INVOICE_FAIL]: (state: State, action: RECALCULATE_INVOICE_FAIL_ACTION): State => ({
            ...state,
            recalculateError: action.payload,
            recalculateLoading: false,
            recalculateSuccess: false,
        }),
        [RECALCULATE_INVOICE_REQUEST]: (state: State): State => ({
            ...state,
            recalculateError: '',
            recalculateLoading: true,
            recalculateSuccess: false,
        }),
        [RECALCULATE_INVOICE_SUCCESS]: (state: State, action: RECALCULATE_INVOICE_SUCCESS_ACTION): State => ({
            ...state,
            recalculateError: '',
            recalculateLoading: false,
            recalculateSuccess: true,
            shippingRefundAmount: action.payload.shippingRefundAmount,
            totalBPRefundAmount: action.payload.totalBPRefundAmount,
            totalHammerRefundAmount: action.payload.totalHammerRefundAmount,
            totalRefund: action.payload.totalRefund,
            totalSalesTaxRefundAmount: action.payload.totalSalesTaxRefundAmount,
        }),
        [REFUND_INVOICE_FAIL]: (state: State, action: REFUND_INVOICE_FAIL_ACTION): State => ({
            ...state,
            refundError: action.payload,
            refundLoading: false,
            refundSuccess: false,
        }),
        [REFUND_INVOICE_REQUEST]: (state: State): State => ({
            ...state,
            refundError: '',
            refundLoading: true,
            refundSuccess: false,
        }),
        [REFUND_INVOICE_SUCCESS]: (state: State): State => ({
            ...state,
            refundError: '',
            refundLoading: false,
            refundSuccess: true,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.invoice;
const passThroughSelector = (state, passThroughValue) => passThroughValue;

export const invoiceIdSelector = createSelector(stateSelector, (state) => state.invoiceId);

export const invoiceLoadingSelector = createSelector(stateSelector, (state) => state.invoiceLoading);
export const invoiceLoadedSelector = createSelector(stateSelector, (state) => state.invoiceLoaded);
export const invoiceSelector = createSelector(stateSelector, (state) => state.invoice);

export const invoiceSendLoadingSelector = createSelector(stateSelector, (state) => state.sendLoading);
export const invoiceSendSuccesselector = createSelector(stateSelector, (state) => state.sendSuccess);
export const invoiceSendErrorSelector = createSelector(stateSelector, (state) => state.sendError);

export const invoiceMarkPaidLoadingSelector = createSelector(stateSelector, (state) => state.markPaidLoading);
export const invoiceMarkPaidSuccesselector = createSelector(stateSelector, (state) => state.markPaidSuccess);
export const invoiceMarkPaidErrorSelector = createSelector(stateSelector, (state) => state.markPaidError);

export const invoiceAddAdjustmentLoadingSelector = createSelector(stateSelector, (state) => state.addAdjustmentLoading);
export const invoiceAddAdjustmentSuccesselector = createSelector(stateSelector, (state) => state.addAdjustmentSuccess);
export const invoiceAddAdjustmentErrorSelector = createSelector(stateSelector, (state) => state.addAdjustmentError);

export const shippingRefundAmount = createSelector(stateSelector, (state) => state.shippingRefundAmount);
export const totalBPRefundAmount = createSelector(stateSelector, (state) => state.totalBPRefundAmount);
export const totalHammerRefundAmount = createSelector(stateSelector, (state) => state.totalHammerRefundAmount);
export const totalRefund = createSelector(stateSelector, (state) => state.totalRefund);
export const totalSalesTaxRefundAmount = createSelector(stateSelector, (state) => state.totalSalesTaxRefundAmount);

export const taxLoadingSelector = createSelector(stateSelector, (state) => state.taxLoading);

export const invoiceDeleteAdjustmentLoadingIdSelector = createSelector(
    stateSelector,
    (state) => state.deleteAdjustmentLoadingId
);
export const invoiceDeleteAdjustmentSuccesselector = createSelector(
    stateSelector,
    (state) => state.deleteAdjustmentSuccess
);
export const invoiceDeleteAdjustmentErrorSelector = createSelector(
    stateSelector,
    (state) => state.deleteAdjustmentError
);

export const invoiceAddPaymentLoadingSelector = createSelector(stateSelector, (state) => state.addPaymentLoading);
export const invoiceAddPaymentSuccesselector = createSelector(stateSelector, (state) => state.addPaymentSuccess);
export const invoiceAddPaymentErrorSelector = createSelector(stateSelector, (state) => state.addPaymentError);

export const invoiceDeletePaymentLoadingIdSelector = createSelector(
    stateSelector,
    (state) => state.deletePaymentLoadingId
);
export const invoiceDeletePaymentSuccesselector = createSelector(stateSelector, (state) => state.deletePaymentSuccess);
export const invoiceDeletePaymentErrorSelector = createSelector(stateSelector, (state) => state.deletePaymentError);

export const invoiceUpdateShippingLoadingSelector = createSelector(
    stateSelector,
    (state) => state.updateShippingLoading
);
export const invoiceUpdateShippingSuccesselector = createSelector(
    stateSelector,
    (state) => state.updateShippingSuccess
);
export const invoiceUpdateShippingErrorSelector = createSelector(stateSelector, (state) => state.updateShippingError);

export const invoiceShippingMethodLoadingSelector = createSelector(
    stateSelector,
    (state) => state.shippingMethodLoading
);
export const invoiceShippingMethodSuccesselector = createSelector(
    stateSelector,
    (state) => state.shippingMethodSuccess
);
export const invoiceShippingMethodErrorSelector = createSelector(stateSelector, (state) => state.shippingMethodError);

export const invoiceSendHistoryLoadingSelector = createSelector(
    stateSelector,
    (state) => state.invoiceSendHistoryLoading
);
export const invoiceSendHistoryLoadedSelector = createSelector(
    stateSelector,
    (state) => state.invoiceSendHistoryLoaded
);
export const invoiceSendHistorySelector = createSelector(stateSelector, (state) => state.invoiceSendHistory);

export const allInvoiceErrorsSelector = createSelector(stateSelector, (state) => {
    let invoiceErrors = [
        state.addAdjustmentError,
        state.addPaymentError,
        state.deleteAdjustmentError,
        state.deletePaymentError,
        state.invoiceLoadError,
        state.invoiceSendHistoryLoadError,
        state.markPaidError,
        state.sendError,
        state.shippingMethodError,
        state.updateShippingError,
    ];
    return invoiceErrors;
});

export const shouldFetchInvoice = createSelector(
    invoiceLoadedSelector,
    invoiceLoadingSelector,
    invoiceIdSelector,
    passThroughSelector,
    (loaded, loading, loadedInvoiceId, invoiceId) => {
        if (!loaded || loadedInvoiceId !== invoiceId) {
            return true;
        }
        if (loading) {
            return false;
        }
        const time = Date.now();
        const diff = time - loaded.getTime();
        return diff > REDUX_STORE_TIME_ITEM;
    }
);

/* ACTION CREATORS */

export const fetchInvoice = (invoiceId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({
        payload: invoiceId,
        type: LOAD_INVOICE_REQUEST,
    });
    dispatch(fetchInvoiceSendHistory(invoiceId));
    try {
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getBidderInvoiceHouseView({ authToken, deployment, invoiceId });

        if (response.payload.alterations) {
            response.payload.alterations = response.payload.alterations.map((alteration: InvoiceAlteration) => {
                alteration.preExisting = true;
                return alteration;
            });
        }

        const successAction: LOAD_INVOICE_SUCCESS_ACTION = {
            error: false,
            meta: undefined,
            payload: response.payload,
            type: LOAD_INVOICE_SUCCESS,
        };

        await dispatch(successAction);
    } catch (error) {
        const errorAction: LOAD_INVOICE_FAIL_ACTION = {
            error: true,
            meta: undefined,
            payload: error.message,
            type: LOAD_INVOICE_FAIL,
        };
        dispatch(errorAction);
    }
};

export const fetchInvoiceIfNeeded = (invoiceId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    if (!shouldFetchInvoice(state, invoiceId)) {
        return Promise.resolve();
    }
    dispatch(fetchInvoice(invoiceId));
};

export const fetchInvoiceSendHistory = (invoiceId: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({
        type: LOAD_INVOICE_SEND_HISTORY_REQUEST,
    });
    try {
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const response = await getBidderInvoiceSendHistory({ authToken, deployment, invoiceId });

        const successAction: LOAD_INVOICE_SEND_HISTORY_SUCCESS_ACTION = {
            error: false,
            meta: undefined,
            payload: response.payload,
            type: LOAD_INVOICE_SEND_HISTORY_SUCCESS,
        };

        dispatch(successAction);
    } catch (error) {
        const errorAction: LOAD_INVOICE_SEND_HISTORY_FAIL_ACTION = {
            error: true,
            meta: undefined,
            payload: error.message,
            type: LOAD_INVOICE_SEND_HISTORY_FAIL,
        };
        dispatch(errorAction);
    }
};

export const postInvoiceSend =
    (invoiceId: number, personalMessage: string) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({
            type: POST_INVOICE_SEND_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postBidderInvoiceSend({ authToken, deployment, invoiceId, personalMessage });

            const successAction = {
                error: false,
                meta: undefined,
                type: POST_INVOICE_SEND_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: POST_INVOICE_SEND_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: POST_INVOICE_SEND_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const postInvoiceMarkPaid =
    (invoiceId: number, amount: Amount) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({
            type: POST_INVOICE_MARK_PAID_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postBidderInvoiceMarkPaid({ amount, authToken, deployment, invoiceId });

            const successAction = {
                error: false,
                meta: undefined,
                type: POST_INVOICE_MARK_PAID_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: POST_INVOICE_MARK_PAID_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: POST_INVOICE_MARK_PAID_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const postInvoiceShipping =
    (invoiceId: number, amount: Amount, shippingMethod?: string) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({
            type: POST_INVOICE_UPDATE_SHIPPING_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postBidderInvoiceShipping({ amount, authToken, deployment, invoiceId, shippingMethod });

            const successAction = {
                error: false,
                meta: undefined,
                type: POST_INVOICE_UPDATE_SHIPPING_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: POST_INVOICE_UPDATE_SHIPPING_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: POST_INVOICE_UPDATE_SHIPPING_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const postInvoiceAdjustment =
    (invoiceId: number, adjustmentAmount: Amount, adjustmentExplanation: string, adjustmentName: string) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({
            type: POST_INVOICE_ADJUSTMENT_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postBidderInvoiceAdjustment({
                adjustmentAmount,
                adjustmentExplanation,
                adjustmentName,
                authToken,
                deployment,
                invoiceId,
            });

            const successAction = {
                error: false,
                meta: undefined,
                type: POST_INVOICE_ADJUSTMENT_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: POST_INVOICE_ADJUSTMENT_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: POST_INVOICE_ADJUSTMENT_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const postInvoiceAddPayment =
    (invoiceId: number, paymentAmount: Amount, description: string) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({
            type: POST_INVOICE_ADD_PAYMENT_REQUEST,
        });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postBidderInvoiceAddPayment({ authToken, deployment, description, invoiceId, paymentAmount });

            const successAction = {
                error: false,
                meta: undefined,
                type: POST_INVOICE_ADD_PAYMENT_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: POST_INVOICE_ADD_PAYMENT_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message || 'Unknown error adding payment',
                type: POST_INVOICE_ADD_PAYMENT_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const deleteInvoiceAdjustment =
    (invoiceId: number, adjustmentId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const requestAction: DELETE_INVOICE_ADJUSTMENT_REQUEST_ACTION = {
            error: false,
            meta: undefined,
            payload: adjustmentId,
            type: DELETE_INVOICE_ADJUSTMENT_REQUEST,
        };
        dispatch(requestAction);
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await deleteBidderInvoiceAdjustment({ adjustmentId, authToken, deployment, invoiceId });

            const successAction = {
                error: false,
                meta: undefined,
                type: DELETE_INVOICE_ADJUSTMENT_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: DELETE_INVOICE_ADJUSTMENT_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: DELETE_INVOICE_ADJUSTMENT_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const deleteInvoicePayment =
    (invoiceId: number, paymentId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const requestAction: DELETE_INVOICE_PAYMENT_REQUEST_ACTION = {
            error: false,
            meta: undefined,
            payload: paymentId,
            type: DELETE_INVOICE_PAYMENT_REQUEST,
        };
        dispatch(requestAction);
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await deleteBidderInvoicePayment({ authToken, deployment, invoiceId, paymentId });

            const successAction = {
                error: false,
                meta: undefined,
                type: DELETE_INVOICE_PAYMENT_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: DELETE_INVOICE_PAYMENT_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: DELETE_INVOICE_PAYMENT_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const recalculateInvoice =
    (lots: Lots[], shippingRefundAmount: number, invoiceId: number, isFullRefund: boolean, reason: string) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({ type: RECALCULATE_INVOICE_REQUEST });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const response = await getRefundInvoiceCalculate({
                authToken,
                deployment,
                invoiceId,
                isFullRefund,
                lots,
                reason,
                shippingRefundAmount,
            });

            const successAction: RECALCULATE_INVOICE_SUCCESS_ACTION = {
                error: false,
                meta: undefined,
                payload: response.payload,
                type: RECALCULATE_INVOICE_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            const errorAction: RECALCULATE_INVOICE_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: RECALCULATE_INVOICE_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

//This endpoint is currently pointing to the wrong place.  Need to update when backend updated.
export const refundInvoice =
    (lots: Lots[], shippingRefundAmount: number, invoiceId: number, isFullRefund: boolean, reason: string) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();
        dispatch({ type: REFUND_INVOICE_REQUEST });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await sendRefundInvoice({
                authToken,
                deployment,
                invoiceId,
                isFullRefund,
                lots,
                reason,
                shippingRefundAmount,
            });

            dispatch({ type: REFUND_INVOICE_SUCCESS });
        } catch (error) {
            const errorAction: REFUND_INVOICE_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error,
                type: REFUND_INVOICE_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const voidTax =
    (amount: number, authorizingUserId: number, invoiceId: number, reason: string) =>
    async (dispatch: Function, getState: Function) => {
        const state = getState();

        const date = new Date().toISOString();

        dispatch({ type: POST_VOID_TAX_REQUEST });
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            await postVoidTax({ amount, authorizingUserId, authToken, date, deployment, invoiceId, reason });

            dispatch({ type: POST_VOID_TAX_SUCCESS });
        } catch (error) {
            const errorAction: POST_VOID_TAX_FAIL_ACTION = {
                error: true,
                meta: undefined,
                payload: error.message,
                type: POST_VOID_TAX_FAIL,
            };
            dispatch(errorAction);
            return Promise.reject();
        }
    };

export const refundTax = (invoiceid: number) => async (dispatch: Function, getState: Function) => {
    const state = getState();

    dispatch({ type: POST_REFUND_TAX_REQUEST });
    try {
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        await postRefundTax({ authToken, deployment, invoiceid });

        dispatch({ type: POST_REFUND_TAX_SUCCESS });
    } catch (error) {
        const errorAction: POST_REFUND_TAX_FAIL_ACTION = {
            error: true,
            meta: undefined,
            payload: error.message,
            type: POST_REFUND_TAX_FAIL,
        };
        dispatch(errorAction);
        return Promise.reject();
    }
};
