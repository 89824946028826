import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState } from '@/redux/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { postHouseForgotPassword } from '../api/auth';
import { SUBMIT_FORGOT_PASSWORD_FAIL, SUBMIT_FORGOT_PASSWORD_REQUEST, SUBMIT_FORGOT_PASSWORD_SUCCESS } from './actions';
import ms from 'ms';

/* reducer */
export type ForgotPasswordState = {
    error?: string | null;
    submitted: boolean;
    success: boolean;
    successEmail: string;
    timestamps: {
        [username: string]: number;
    };
};

export const DEFAULT_STATE: ForgotPasswordState = {
    error: null,
    submitted: false,
    success: false,
    successEmail: '',
    timestamps: {},
};

export const reducer = handleActions(
    {
        [SUBMIT_FORGOT_PASSWORD_FAIL]: (
            state: ForgotPasswordState,
            action: ActionWithPayload<string>
        ): ForgotPasswordState => ({
            ...state,
            error: action.payload,
            submitted: false,
            success: false,
        }),
        [SUBMIT_FORGOT_PASSWORD_REQUEST]: (state: ForgotPasswordState): ForgotPasswordState => ({
            ...state,
            error: null,
            submitted: true,
            success: false,
        }),
        [SUBMIT_FORGOT_PASSWORD_SUCCESS]: (
            state: ForgotPasswordState,
            action: ActionWithPayload<{ email: string }, { actionTime: number; username: string }>
        ): ForgotPasswordState => ({
            ...state,
            error: null,
            submitted: false,
            success: true,
            successEmail: action.payload.email,
            timestamps: { [action.meta.username]: action.meta.actionTime },
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.forgotPassword;
const idSelector = (state, id) => id;
const getForgotPasswordTimestamps = createSelector(stateSelector, (state) => state.timestamps);

export const getForgotPasswordState = createSelector(stateSelector, (state) => state);

export const getIsAtLeast5MinutesSinceLastRequest = createSelector(
    [getForgotPasswordTimestamps, idSelector],
    (timestamps, id) => Date.now() - (timestamps[id] || 0) >= ms('5m')
);

/* ACTION CREATORS */
export const submitForgotPassword = (username: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        if (!getIsAtLeast5MinutesSinceLastRequest(state, username)) {
            return dispatch({
                error: true,
                meta: { username },
                payload: 'For your account security, please wait 5 min between sending reset messages.',
                type: SUBMIT_FORGOT_PASSWORD_FAIL,
            });
        }

        dispatch({
            payload: username,
            type: SUBMIT_FORGOT_PASSWORD_REQUEST,
        });
        const response = await postHouseForgotPassword({
            deployment,
            username,
        });

        return dispatch({
            meta: { actionTime: Date.now(), username },
            payload: response.payload,
            type: SUBMIT_FORGOT_PASSWORD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SUBMIT_FORGOT_PASSWORD_FAIL,
        });
    }
};
