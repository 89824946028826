import { Deployments } from '@/redux/modules/config.types';
import { handleResponse, makeGet, makePost } from '@/redux/api/helpers';
import {
    HouseLabelPermissions,
    PackagingFeeInfo,
    Printers,
} from '@/redux/modules/shipping/labels/shippingLabels.types';

type PackagingFeesResponse = {
    error: boolean;
    payload: PackagingFeeInfo;
};

type GetHousePackagingAndHandlingFeeSettingsParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
};

export const getHousePackagingAndHandlingFeeSettings = ({
    authToken,
    deployment,
    houseId,
}: GetHousePackagingAndHandlingFeeSettingsParams) =>
    new Promise<PackagingFeesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/packaging-and-handling-fee`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type PostHousePackagingAndHandlingFeeSettingsParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
    newSettings: PackagingFeeInfo;
};

export const postHousePackagingAndHandlingFeeSettings = ({
    authToken,
    deployment,
    houseId,
    newSettings,
}: PostHousePackagingAndHandlingFeeSettingsParams) =>
    new Promise<PackagingFeesResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${houseId}/packaging-and-handling-fee`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.send(newSettings);
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type GetShippingLabelsHousePermissionsParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
};

type GetShippingLabelsHousePermissionsResponse = {
    error: boolean;
    payload: HouseLabelPermissions;
};

export const getShippingLabelHousePermissions = ({
    authToken,
    deployment,
    houseId,
}: GetShippingLabelsHousePermissionsParams) =>
    new Promise<GetShippingLabelsHousePermissionsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/elabel`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type GetShippingLabelPrinterSettingsParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
};

type PrinterSettingsResponse = {
    error: boolean;
    payload: Printers;
};

export const getShippingLabelPrinterSettings = ({
    authToken,
    deployment,
    houseId,
}: GetShippingLabelPrinterSettingsParams) =>
    new Promise<PrinterSettingsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `house/${houseId}/printer`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

type PostShippingLabelPrinterSettingsParams = {
    authToken: string;
    deployment: Deployments;
    houseId: number;
    selectedPrinter: Printers;
};

type PostShippingLabelPrinterSettingsResponse = {
    error: boolean;
    payload: {
        success: boolean;
    };
};

export const postShippingLabelPrinterSettings = ({
    authToken,
    deployment,
    houseId,
    selectedPrinter,
}: PostShippingLabelPrinterSettingsParams) =>
    new Promise<PostShippingLabelPrinterSettingsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `house/${houseId}/printer`,
            authToken,
            deployment,
            path: '<SHIPPING-API>',
        });
        request.send({
            type: selectedPrinter,
        });
        request.end((err, response) =>
            handleResponse({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
