import {
    AutomationSettingsCopyBiddersWalkthroughStepName,
    AutomationSettingsFavoriteBidderRecommendationsWalkthroughStepName,
} from '@/types/walkthroughs/AutomationSettings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultWalkthroughsSlice } from './walkthroughs.types';
import { WalkthroughStepName } from '@/types/walkthroughs/BidderApprovalOnboarding';

const WalkthroughsSlice = createSlice({
    initialState: defaultWalkthroughsSlice,
    name: 'walkthroughs',
    reducers: {
        setBidderApprovalOnboardingStep: (state, action: PayloadAction<WalkthroughStepName>) => {
            state.bidderApprovalOnboarding.currentStep = action.payload;
            return state;
        },
        setBidderApprovalOnboardingVisibility: (state, action: PayloadAction<boolean>) => {
            // Mark walkthrough as seen by the user
            if (action.payload === false && state.bidderApprovalOnboarding.isOpen === true) {
                state.bidderApprovalOnboarding.visited = true;
            }

            state.bidderApprovalOnboarding.isOpen = action.payload;

            return state;
        },
        setSuggestedAutomationForCopyBiddersStep: (
            state,
            action: PayloadAction<AutomationSettingsCopyBiddersWalkthroughStepName>
        ) => {
            state.suggestedAutomationForCopyBidders.currentStep = action.payload;
            return state;
        },
        setSuggestedAutomationForCopyBiddersVisibility: (state, action: PayloadAction<boolean>) => {
            // Mark walkthrough as seen by the user
            if (action.payload === false && state.suggestedAutomationForCopyBidders.isOpen === true) {
                state.suggestedAutomationForCopyBidders.visited = true;
            }

            state.suggestedAutomationForCopyBidders.isOpen = action.payload;

            return state;
        },
        setSuggestedAutomationForFavoriteBidderRecommendationsStep: (
            state,
            action: PayloadAction<AutomationSettingsFavoriteBidderRecommendationsWalkthroughStepName>
        ) => {
            state.suggestedAutomationForFavoriteBidderRecommendations.currentStep = action.payload;
            return state;
        },
        setSuggestedAutomationForFavoriteBidderRecommendationsVisibility: (state, action: PayloadAction<boolean>) => {
            // Mark walkthrough as seen by the user
            if (action.payload === false && state.suggestedAutomationForFavoriteBidderRecommendations.isOpen === true) {
                state.suggestedAutomationForFavoriteBidderRecommendations.visited = true;
            }

            state.suggestedAutomationForFavoriteBidderRecommendations.isOpen = action.payload;

            return state;
        },
    },
});

export const { reducer: walkthroughsReducer } = WalkthroughsSlice;

export const {
    setBidderApprovalOnboardingStep,
    setBidderApprovalOnboardingVisibility,
    setSuggestedAutomationForCopyBiddersStep,
    setSuggestedAutomationForCopyBiddersVisibility,
    setSuggestedAutomationForFavoriteBidderRecommendationsStep,
    setSuggestedAutomationForFavoriteBidderRecommendationsVisibility,
} = WalkthroughsSlice.actions;
