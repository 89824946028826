import { createSelector } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';
import { LIVE_LOT_SOLD, LIVE_LOT_SOLD_ACTION } from './actions';

/* reducer */
export const DEFAULT_STATE = {
    lotsSold: [],
};

export type State = typeof DEFAULT_STATE;

export const reducer = handleActions(
    {
        [LIVE_LOT_SOLD]: (state: State, action: LIVE_LOT_SOLD_ACTION) => {
            const { amount, assignedId, bidderId, catalogId, created, currency, itemId, lotNumber, myBid, source } =
                action.payload;

            // Store data as keys in an object to filter out duplicates (in case of retractions)
            const lotSoldObj = {};
            state.lotsSold.forEach((lot) => {
                lotSoldObj[lot.lotNumber] = { ...lot };
            });
            lotSoldObj[lotNumber] = {
                amount,
                assignedId,
                bidderId,
                catalogId,
                created,
                currency,
                itemId,
                lotNumber,
                myBid,
                source: bidderId === 0 ? 'Floor' : source, // TODO: do we need source to come back in these messages?
            };

            // turn it back into an array
            const lotsSold = [];
            Object.keys(lotSoldObj).forEach((k) => {
                lotsSold.push(lotSoldObj[k]);
            });

            // Sort oldest to newest
            lotsSold.sort((a, b) => a.created - b.created);

            return {
                ...state,
                lotsSold,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state) => state.liveLotsSold;

export const getLiveLotsSold = createSelector(stateSelector, (state) => state.lotsSold);
