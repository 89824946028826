import { handleResponse, makeDelete, makeGet, makePost } from './helpers';

type CreateAnnouncementParams = {
    authToken?: string;
    body: string;
    catalogId?: number;
    deployment: string;
    houseId: number;
    sendEmail: boolean;
    title: string;
};

type FetchAnnouncementsParams = {
    deployment: string;
    houseId: number;
};

type RemoveAnnouncementParams = {
    announcementId: number;
    authToken?: string;
    deployment: string;
    houseId: number;
};

export default {
    createAnnouncement: ({
        authToken,
        body,
        catalogId,
        deployment,
        houseId,
        sendEmail,
        title,
    }: CreateAnnouncementParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: `<ANNOUNCEMENT-API>/houses/${houseId}/announcements`,
            });
            request.query({ createConversations: sendEmail });
            request.send({ body, ...((catalogId && { catalogId }) || {}), houseId, title });
            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    fetchAnnouncements: ({ deployment, houseId }: FetchAnnouncementsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                deployment,
                path: `<ANNOUNCEMENT-API>/houses/${houseId}/announcements`,
            });

            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
    removeAnnouncement: ({ announcementId, authToken, deployment, houseId }: RemoveAnnouncementParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeDelete({
                authToken,
                deployment,
                path: `<ANNOUNCEMENT-API>/houses/${houseId}/announcements/${announcementId}`,
            });
            request.send({ announcementId });
            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
