export enum BidderApprovalOnboardingWalkthroughEnum {
    AutomationSettings = 'bidder-approval-onboarding-automation-settings',
    BidderDetails = 'bidder-approval-onboarding-bidder-details',
    DisputeHistory = 'bidder-approval-onboarding-dispute-history',
    MessagingHistory = 'bidder-approval-onboarding-messaging-history',
    SkipTour = 'bidder-approval-onboarding-skip-tips',
    Welcome = 'bidder-approval-onboarding-welcome',
}

export type WalkthroughStepName =
    | BidderApprovalOnboardingWalkthroughEnum.Welcome
    | BidderApprovalOnboardingWalkthroughEnum.SkipTour
    | BidderApprovalOnboardingWalkthroughEnum.AutomationSettings
    | BidderApprovalOnboardingWalkthroughEnum.BidderDetails
    | BidderApprovalOnboardingWalkthroughEnum.DisputeHistory
    | BidderApprovalOnboardingWalkthroughEnum.MessagingHistory;
