import { handleResponse, makeGet } from './helpers';

type FetchCampaignAdPreviewParams = {
    adUnitId: string;
    authToken?: string;
    deployment: string;
    houseId: string;
};

export default {
    fetchCampaignAdPreviewById: ({ adUnitId, authToken, deployment, houseId }: FetchCampaignAdPreviewParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({ authToken, deployment, path: '<MARKETING-REPORTS-API>/getadpreview' });

            request.query({
                adUnitId,
                houseId,
            });

            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
