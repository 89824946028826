export type HouseLocation = {
    address1: string;
    address2: string;
    addressId: string;
    city: string;
    countryCode: string;
    createdDate: string;
    enabled: boolean;
    nickname: string;
    postalCode: string;
    state: string;
    updatedDate: string;
};

export const emptyHouseLocation: HouseLocation = {
    address1: '',
    address2: '',
    addressId: '',
    city: '',
    countryCode: '',
    createdDate: '',
    enabled: true,
    nickname: '',
    postalCode: '',
    state: '',
    updatedDate: '',
};

export type HouseLocationsSlice = {
    byId: {
        [houseId: number]: HouseLocation[];
    };
    error: string;
    loadTime: number;
    loading: boolean;
};

export const defaultHouseLocationsSlice: HouseLocationsSlice = {
    byId: {},
    error: undefined,
    loading: false,
    loadTime: 0,
};
