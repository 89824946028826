import * as React from 'react';
import { getBuildNumber } from '@/redux/modules/config';
import { getSeller } from '@/redux/modules/seller';
import { getUserData, getUserHouseId, getUserType } from '@/redux/modules/user';
import { useAppSelector } from '@/redux/hooks';
import tracker from './tracker';

const OpenReplay = () => {
    // Get data to identify user
    const { email, name } = useAppSelector(getUserData);
    const sellerId = useAppSelector(getUserHouseId);
    const userType = useAppSelector(getUserType);
    const { name: sellerName } = useAppSelector((state) => getSeller(state, sellerId));
    const buildNumber = useAppSelector(getBuildNumber);

    // If not in development mode or running tests and the user is loaded
    // start recording to OpenReplay and identify user
    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'development' && process.env.CI !== 'true') {
            if (sellerId > 0 && sellerName) {
                try {
                    tracker
                        .start({
                            metadata: {
                                buildNumber,
                                email,
                                name,
                                sellerName,
                                userType,
                            },
                            userID: `${sellerId}`,
                        })
                        .catch((error) => {
                            console.error('openreplay error', error);
                        });
                } catch (error) {
                    console.error('openreplay error', error);
                }

                // TODO turn this on maybe
                // if (typeof (window as any)?.Beacon !== 'undefined') {
                //     (window as any)?.Beacon('identify', {
                //         email,
                //         name,
                //         sessionURL: tracker.getSessionURL(),
                //     });
                // }
            }
        }
    }, [buildNumber, email, name, sellerId, sellerName, userType]);

    return null;
};

export default OpenReplay;
