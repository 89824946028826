import { createSlice } from '@reduxjs/toolkit';
import { defaultBidderConversationsSlice } from './bidderConversations.types';
import { fetchBidderConversations } from './bidderConversations.actions';

const bidderConversationsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchBidderConversations.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(fetchBidderConversations.fulfilled, (state, { payload }) => {
            state.conversations = payload.conversations;
            state.conversationCount = payload.conversationCount;
            state.isLoading = false;
        });
        builder.addCase(fetchBidderConversations.rejected, (state) => {
            state.isLoading = false;
        });
    },
    initialState: defaultBidderConversationsSlice,
    name: 'bidderConversations',
    reducers: {},
});

export const { reducer: bidderConversationsReducer } = bidderConversationsSlice;
