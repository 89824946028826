import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import api from '../api';

/* Action Types */
export const PREVIEW_DATA_FAIL = 'PREVIEW_DATA_FAIL';
export const PREVIEW_DATA_REQUEST = 'PREVIEW_DATA_REQUEST';
export const PREVIEW_DATA_SUCCESS = 'PREVIEW_DATA_SUCCESS';
export const SUBMIT_PREVIEW_FAIL = 'SUBMIT_PREVIEW_FAIL';
export const SUBMIT_PREVIEW_REQUEST = 'SUBMIT_PREVIEW_REQUEST';
export const SUBMIT_PREVIEW_SUCCESS = 'SUBMIT_PREVIEW_SUCCESS';

/* reducer */
export const DEFAULT_STATE = {
    auctionPreview: {
        dateTime: 0,
        description: '',
        image: [],
        title: '',
    },
    error: null,
    isLoading: false,
    sentPreviewSuccess: false,
    submitted: false,
};

export type AuctionPreviewImageFromServer = {
    name: string;
    preview: string;
};

export type AuctionPreview = {
    dateTime: number;
    description: string;
    image: Array<AuctionPreviewImageFromServer>;
    title: string;
};

export type State = {
    auctionPreview: AuctionPreview;
    error: string | null | undefined;
    isLoading: boolean;
    sentPreviewSuccess: boolean;
    submitted: boolean;
};

export const reducer = handleActions(
    {
        [PREVIEW_DATA_FAIL]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            isLoading: false,
        }),
        [PREVIEW_DATA_REQUEST]: (state: State) => ({
            ...state,
            isLoading: true,
        }),
        [PREVIEW_DATA_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                auctionPreview: {
                    description: string;
                    imagePath: string;
                    saleStart: number;
                    title: string;
                };
            }>
        ) => ({
            ...state,
            auctionPreview: {
                dateTime: action.payload.auctionPreview.saleStart,
                description: action.payload.auctionPreview.description,
                image: [
                    {
                        name: 'image',
                        preview: action.payload.auctionPreview.imagePath,
                    },
                ],
                title: action.payload.auctionPreview.title,
            },
            isLoading: false,
        }),
        [SUBMIT_PREVIEW_FAIL]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            sentPreviewSuccess: false,
            submitted: false,
        }),
        [SUBMIT_PREVIEW_REQUEST]: (state: State) => ({
            ...state,
            error: '',
            sentPreviewSuccess: false,
            submitted: true,
        }),
        [SUBMIT_PREVIEW_SUCCESS]: (state: State) => ({
            ...state,
            error: '',
            sentPreviewSuccess: true,
            submitted: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state): State => state.auctionPreview;

export const isLoading = createSelector(stateSelector, (state) => state.isLoading);

export const getError = createSelector(stateSelector, (state) => state.error);

export const isSuccessfulSubmission = createSelector(stateSelector, (state) => state.sentPreviewSuccess);

export const getAuctionPreview = createSelector(stateSelector, (state) => state.auctionPreview);

export const getAuctionPreviewSubmitted = createSelector(stateSelector, (state) => state.submitted);

/* ACTION CREATORS */
export const fetchPreviewData =
    (previewId: number, sellerId: number) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: PREVIEW_DATA_REQUEST,
            });

            const response = await api.getAuctionPreview({
                authToken,
                deployment,
                previewId,
                sellerId,
            });

            if (response.error) {
                dispatch({
                    payload: response.payload,
                    type: PREVIEW_DATA_FAIL,
                });
            } else {
                // temp for testing
                dispatch({
                    payload: response.payload,
                    type: PREVIEW_DATA_SUCCESS,
                });
            }
        } catch (error) {
            dispatch({
                payload: error.errorMessage,
                type: PREVIEW_DATA_FAIL,
            });
        }
    };

export const submitAuctionPreview =
    (previewId: number, auctionPreview: any) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SUBMIT_PREVIEW_REQUEST,
            });

            let response;
            let error;
            if (previewId) {
                response = await api
                    .postUpdateAuctionPreview({
                        auctionPreview,
                        authToken,
                        deployment,
                        previewId,
                    })
                    .catch((errorMessage) => {
                        error = errorMessage;
                        return dispatch({
                            payload: errorMessage,
                            type: SUBMIT_PREVIEW_FAIL,
                        });
                    });
            } else {
                response = await api
                    .postSubmitAuctionPreview({
                        auctionPreview,
                        authToken,
                        deployment,
                    })
                    .catch((errorMessage) => {
                        error = errorMessage;
                        return dispatch({
                            payload: errorMessage,
                            type: SUBMIT_PREVIEW_FAIL,
                        });
                    });
            }

            if (!error) {
                return dispatch({
                    payload: response.payload,
                    type: SUBMIT_PREVIEW_SUCCESS,
                });
            }
        } catch (error) {
            dispatch({
                payload: error,
                type: SUBMIT_PREVIEW_FAIL,
            });
        }
    };
