import { fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import { getAuthToken, getBidderId, getUserData } from '@/redux/modules/user';
import { getCatalog } from './catalog';
import {
    getCatalogNetworkStatus,
    getLiveCatalogData,
    getLiveItemData,
    getLiveItemExists,
} from '@/redux/modules/console';
import { getClerkButtonsUIState } from './clerkButtons';
import { getDeployment, getPubSubMessagingProvider, updateMessagingProvider } from '@/redux/modules/config';
import {
    LIVE_ASK_CHANGED,
    LIVE_ASK_CHANGED_ACTION,
    LIVE_AUCTION_ENDED,
    LIVE_AUCTION_ENDED_ACTION,
    LIVE_AUCTION_LOADED,
    LIVE_AUCTION_LOADED_ACTION,
    LIVE_AUCTION_MOVED,
    LIVE_AUCTION_MOVED_ACTION,
    LIVE_AUCTION_PAUSED,
    LIVE_AUCTION_PAUSED_ACTION,
    LIVE_AUCTION_RESUMED,
    LIVE_AUCTION_RESUMED_ACTION,
    LIVE_AUCTION_STARTED,
    LIVE_AUCTION_STARTED_ACTION,
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_HOVER_DISMISS,
    LIVE_BID_HOVER_DISMISS_ACTION,
    LIVE_BID_HOVER_INTENT,
    LIVE_BID_HOVER_INTENT_ACTION,
    LIVE_BID_PLACED,
    LIVE_BID_PLACED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LIVE_BID_SENT,
    LIVE_BID_SENT_ACTION,
    LIVE_LOT_CLOSED,
    LIVE_LOT_CLOSED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SKIPPED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LIVE_MISSIVE,
    LIVE_MISSIVE_ACTION,
    LIVE_NETWORK_DISCONNECTED,
    LIVE_NETWORK_DISCONNECTED_ACTION,
    LIVE_NETWORK_RECONNECTED,
    LIVE_NETWORK_RECONNECTED_ACTION,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LIVE_UPDATE_CATALOG_OCCUPANCY,
    LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION,
} from './actions';
import { postRecordMessage, PostRecordMessageParams } from '@/redux/api/clerk';
import { PubnubStatusEvent } from '@/types/Pubnub';
import type { ConnectionStateChange } from 'ably';
import type { LiveAuctionEvent } from '@/types/LiveAuctionEvent';
import type { PubSubProvider } from '@/types/PubSubProvider';

export const handleCatalogOnMessage =
    (originalCatalogId: number, dataSource: 'clerk-button' | 'event-queue' = 'event-queue', provider: PubSubProvider) =>
    (event: LiveAuctionEvent) =>
    async (dispatch: Function, getState: Function) => {
        // For any event that has an AuctionId field
        if ('AuctionId' in event && event.AuctionId === originalCatalogId) {
            const { AuctionId: catalogId } = event;

            // Bail if event is not for current auction item
            if ('LotId' in event) {
                const { LotId: liveEventItemId } = event;
                const state = getState();
                const { currentItem: currentConsoleItemId } = getLiveCatalogData(state, catalogId);

                if (liveEventItemId !== currentConsoleItemId) {
                    console.error('Warning: received live auction event for previous item', event);
                    return;
                }
            }

            // Timestamp for when current message is received
            const currentTime = new Date().getTime();

            const state = getState();
            const currentBidderId = getBidderId();
            const { currency } = getCatalog(state, catalogId);

            // if provider has been switched on the backend mid-auction, update current provider
            // preprod uses the same keys as prod, causing potentially both providers to receive all messages making this not work
            const currentProvider = getPubSubMessagingProvider(state);
            const deployment = getDeployment(state);
            if (deployment !== 'preprod' && currentProvider !== provider) {
                dispatch(updateMessagingProvider(provider));
            }

            // log that we got a message to the logger, don't log messages that were manually created ie dataSource = 'clerk-button'
            if ('MessageId' in event && dataSource === 'event-queue') {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                const record: PostRecordMessageParams = {
                    authToken,
                    catalogId,
                    clientReceivedTs: currentTime,
                    deployment,
                    message: JSON.stringify(event),
                    messageId: event.MessageId,
                };

                // attach a lotid if we have one
                if ('LotId' in event) {
                    record.lotId = event.LotId;
                }
                /**
                 * Record message to dante, but don't wait for a response. If logging fails for some reason, console log it
                 */
                postRecordMessage(record).catch((error) => {
                    console.error('Warning couldnt post message to logger', error, record);
                });
            }

            if (event.EventType === 'ask-changed') {
                // TODO: add currency to ask-changed
                const { AskPrice, Increment, LotId, LotIndex, LotNumber } = event;

                const { currentAskPrice } = getLiveItemData(state, LotId);

                // Ignore duplicate ask-changed events
                if (AskPrice === currentAskPrice) {
                    return;
                }

                return dispatch({
                    payload: {
                        ask: AskPrice,
                        catalogId,
                        created: currentTime,
                        currency,
                        increment: Increment,
                        itemId: LotId,
                        itemIndex: LotIndex,
                        lotNumber: LotNumber,
                    },
                    type: LIVE_ASK_CHANGED,
                } as LIVE_ASK_CHANGED_ACTION);
            } else if (event.EventType === 'auction-closed') {
                return dispatch({
                    payload: { catalogId, created: currentTime },
                    type: LIVE_AUCTION_ENDED,
                } as LIVE_AUCTION_ENDED_ACTION);
            } else if (event.EventType === 'auction-loaded') {
                const { ServerId } = event;
                return dispatch({
                    payload: { catalogId, created: currentTime, serverId: ServerId },
                    type: LIVE_AUCTION_LOADED,
                } as LIVE_AUCTION_LOADED_ACTION);
            } else if (event.EventType === 'auction-moved') {
                return dispatch({
                    payload: { catalogId, created: currentTime },
                    type: LIVE_AUCTION_MOVED,
                } as LIVE_AUCTION_MOVED_ACTION);
            } else if (event.EventType === 'auction-paused') {
                return dispatch({
                    payload: { catalogId, created: currentTime },
                    type: LIVE_AUCTION_PAUSED,
                } as LIVE_AUCTION_PAUSED_ACTION);
            } else if (event.EventType === 'auction-resumed') {
                return dispatch({
                    payload: { catalogId, created: currentTime },
                    type: LIVE_AUCTION_RESUMED,
                } as LIVE_AUCTION_RESUMED_ACTION);
            } else if (event.EventType === 'auction-started') {
                return dispatch({
                    payload: { catalogId, created: currentTime },
                    type: LIVE_AUCTION_STARTED,
                } as LIVE_AUCTION_STARTED_ACTION);
            } else if (event.EventType === 'bid-accepted') {
                const { Amount, AskPrice, AssignedId, BidderId, BidSource, Increment, LotId, LotIndex, LotNumber } =
                    event;
                // const state = getState();
                // const currentBidderId = getBidderId(state);
                // const { bidderHasHighBid, submitted } = getUiLiveBid(state);
                // const bidderOutbid = (currentBidderId !== bidderId && bidderHasHighBid && bidderId !== -1) || (submitted && !myBid);
                // const bidLimitBalance = getBidLimitBalance(state, catalogId);

                // Ignore duplicate bid-accepted messages
                const currentLeadingBid = getLiveItemData(state, LotId).leadingBid;
                if (Amount <= currentLeadingBid) {
                    return;
                }

                // Ignore pubnub message if console bid buttons are submitting this message, we'll update the store with the api response instead
                const { submittingBid } = getClerkButtonsUIState(state);
                if (dataSource === 'event-queue' && submittingBid) {
                    if (submittingBid.amount === Amount && submittingBid.itemId === LotId) {
                        return;
                    }
                }

                return dispatch({
                    payload: {
                        amount: Amount,
                        ask: AskPrice,
                        assignedId: AssignedId,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency,
                        increment: Increment,
                        itemId: LotId,
                        itemIndex: LotIndex,
                        lotNumber: LotNumber,
                        myBid: BidderId === currentBidderId && BidderId !== -1,
                        source: BidSource,
                    },
                    type: LIVE_BID_ACCEPTED,
                } as LIVE_BID_ACCEPTED_ACTION);
            } else if (event.EventType === 'bid-hover-dismiss') {
                // BidderId is correct
                const { Amount, BidderId, Currency, LotId } = event;
                return dispatch({
                    payload: {
                        amount: Amount,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency: Currency,
                        itemId: LotId,
                    },
                    type: LIVE_BID_HOVER_DISMISS,
                } as LIVE_BID_HOVER_DISMISS_ACTION);
            } else if (event.EventType === 'bid-hover-intent') {
                const {
                    Amount,
                    BidderHasHighBid,
                    BidderId,
                    BidSource,
                    Currency,
                    IsItemSaved,
                    IsSellerFollowed,
                    LotId,
                } = event;

                return dispatch({
                    payload: {
                        amount: Amount,
                        bidderHasHighBid: BidderHasHighBid,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency: Currency,
                        isItemSaved: IsItemSaved,
                        isSellerFollowed: IsSellerFollowed,
                        itemId: LotId,
                        source: BidSource,
                    },
                    type: LIVE_BID_HOVER_INTENT,
                } as LIVE_BID_HOVER_INTENT_ACTION);
            } else if (event.EventType === 'bid-placed') {
                const { Amount, BidderId, BidSource, LotId } = event;

                return dispatch({
                    payload: {
                        amount: Amount,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency,
                        itemId: LotId,
                        source: BidSource,
                    },
                    type: LIVE_BID_PLACED,
                } as LIVE_BID_PLACED_ACTION);
            } else if (event.EventType === 'bid-retracted') {
                const { Amount, AskPrice, AssignedId, BidderId, BidSource, Increment, LotId, LotIndex, LotNumber } =
                    event;
                // const state = getState();
                // const currentBidderId = getBidderId(); // state);
                // const itemData = getLiveItemData(state, itemId);
                // const myBid = itemData.leadingBidder === currentBidderId;

                return dispatch({
                    payload: {
                        amount: Amount,
                        ask: AskPrice,
                        assignedId: AssignedId,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency,
                        increment: Increment,
                        itemId: LotId,
                        itemIndex: LotIndex,
                        lotNumber: LotNumber,
                        source: BidSource,
                    },
                    type: LIVE_BID_RETRACTED,
                } as LIVE_BID_RETRACTED_ACTION);
            } else if (event.EventType === 'bid-sent') {
                const { Amount, BidderId, BidSource, LotId } = event;

                const { currentAskPrice } = getLiveItemData(state, LotId);

                // if the bid-sent amount is less than the current ask price, return
                if (Amount < currentAskPrice) {
                    return;
                }

                /**
                 * If the item for this bid-sent message doesn't exist,
                 * refetch the live catalog auction state
                 */
                const liveItemExists = getLiveItemExists(state, LotId);
                if (!liveItemExists) {
                    // Log error
                    console.error('pubnub-queue - bid-sent before lot-loaded, refetching auction state', {
                        extra: {
                            ...event,
                        },
                        tags: {
                            eventType: event.EventType,
                        },
                    });
                    return await dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, true));
                }

                return dispatch({
                    payload: {
                        amount: Amount,
                        assignedId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency,
                        itemId: LotId,
                        source: BidSource,
                    },
                    type: LIVE_BID_SENT,
                } as LIVE_BID_SENT_ACTION);
            } else if (event.EventType === 'lot-closed') {
                const { LotId, LotNumber } = event;

                const { soldAssignedId } = getLiveItemData(state, LotId);

                return dispatch({
                    payload: { catalogId, created: currentTime, itemId: LotId, lotNumber: LotNumber, soldAssignedId },
                    type: LIVE_LOT_CLOSED,
                } as LIVE_LOT_CLOSED_ACTION);
            } else if (event.EventType === 'lot-loaded') {
                const { NextLot } = event;
                const {
                    AskPrice: askPrice,
                    // HighBidSource: highBidSource,
                    HighEstimate: highEstimate,
                    Increment: increment,
                    Index: index,
                    // LastAcceptedBid,
                    LotDescription: description,
                    LotId: itemId,
                    LotNumber: lotNumber,
                    LotTitle: lotTitle,
                    LowEstimate: lowEstimate,
                    PendingBid: pendingBid,
                    PendingBidderId: pendingAssignedId,
                    Status: status,
                } = NextLot;
                // const {
                //     Amount: lastAcceptedBidAmount,
                //     AssignedId: lastAcceptedAssignedId,
                //     LotId: lastAcceptedLotId,
                //     Source: lastAcceptedSource,
                // } = LastAcceptedBid;

                return dispatch({
                    payload: {
                        askPrice,
                        catalogId,
                        created: currentTime,
                        currency,
                        description,
                        highEstimate,
                        increment,
                        index,
                        itemId,
                        itemIndex: index,
                        lotNumber,
                        lotTitle,
                        lowEstimate,
                        pendingAssignedId,
                        pendingBid,
                        status,
                    },
                    type: LIVE_NEXT_LOT_LOADED,
                } as LIVE_NEXT_LOT_LOADED_ACTION);
            } else if (event.EventType === 'lot-passed') {
                const { LotId, LotNumber } = event;
                return dispatch({
                    payload: { catalogId, created: currentTime, itemId: LotId, lotNumber: LotNumber },
                    type: LIVE_LOT_PASSED,
                } as LIVE_LOT_PASSED_ACTION);
            } else if (event.EventType === 'lot-reopened') {
                const { AskPrice, HighBid, Increment, LeadingAssignedId, LeadingBidderId, LotId, LotIndex, LotNumber } =
                    event;
                return dispatch({
                    payload: {
                        askPrice: AskPrice,
                        catalogId,
                        created: currentTime,
                        highBid: HighBid,
                        increment: Increment,
                        itemId: LotId,
                        itemIndex: LotIndex,
                        leadingAssignedId: LeadingAssignedId,
                        leadingBidderId: LeadingBidderId,
                        lotNumber: LotNumber,
                    },
                    type: LIVE_LOT_REOPENED,
                } as LIVE_LOT_REOPENED_ACTION);
            } else if (event.EventType === 'lot-skipped') {
                const { LotId, LotNumber, NewLotNumber } = event;
                return dispatch({
                    payload: {
                        catalogId,
                        created: currentTime,
                        itemId: LotId,
                        lotNumber: LotNumber,
                        newLotNumber: NewLotNumber,
                    },
                    type: LIVE_LOT_SKIPPED,
                } as LIVE_LOT_SKIPPED_ACTION);
            } else if (event.EventType === 'lot-sold') {
                // todo: lot-sold doesn't contain 'Source' field so will always be undefined, fix me
                // @ts-expect-error
                const { Amount, AssignedId, BidderId, BidSource, LotId, LotNumber } = event;

                return dispatch({
                    payload: {
                        amount: Amount,
                        assignedId: AssignedId,
                        bidderId: BidderId,
                        catalogId,
                        created: currentTime,
                        currency,
                        itemId: LotId,
                        lotNumber: LotNumber,
                        myBid: BidderId === currentBidderId && BidderId !== -1,
                        source: BidSource,
                    },
                    type: LIVE_LOT_SOLD,
                } as LIVE_LOT_SOLD_ACTION);
            } else if (event.EventType === 'lot-unsold') {
                const { LotId, LotNumber } = event;
                return dispatch({
                    payload: { catalogId, created: currentTime, itemId: LotId, lotNumber: LotNumber },
                    type: LIVE_LOT_UNSOLD,
                } as LIVE_LOT_UNSOLD_ACTION);
            } else if (event.EventType === 'missive-sent') {
                const { LotId, MissiveText } = event;
                return dispatch({
                    payload: { catalogId, created: currentTime, itemId: LotId, missiveText: MissiveText },
                    type: LIVE_MISSIVE,
                } as LIVE_MISSIVE_ACTION);
            }
        }
    };

type HandleCatalogOnPresenceParams = {
    channel: string;
    occupancy: number;
};

/**
 * Update the presence count in redux if ably receives 'enter' or 'leave' presence messages
 */
export const handleCatalogOnPresence =
    (catalogId: number) => (presenceEvent: HandleCatalogOnPresenceParams) => async (dispatch: Function) => {
        if (presenceEvent.channel.includes(`${catalogId}`)) {
            dispatch({
                payload: { bidderCount: presenceEvent.occupancy, catalogId },
                type: LIVE_UPDATE_CATALOG_OCCUPANCY,
            } as LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION);
        }
    };

export const handleAblyCatalogOnStatus =
    (catalogId: number) => (statusEvent: ConnectionStateChange) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const currentProvider = getPubSubMessagingProvider(state);
        const catalogNetworkStatus = getCatalogNetworkStatus(state, catalogId);

        if (currentProvider !== 'ably') {
            return;
        }

        const currentStatus = statusEvent.current;

        if (currentStatus === 'connected' && catalogNetworkStatus === 'disconnected') {
            return dispatch(networkReconnected({ catalogId, provider: 'ably' }));
        } else if (currentStatus === 'closed' && catalogNetworkStatus === 'connected') {
            return dispatch(networkDisconnected({ catalogId, provider: 'ably' }));
        } else if (
            (currentStatus === 'disconnected' || currentStatus === 'failed' || currentStatus === 'suspended') &&
            catalogNetworkStatus === 'connected'
        ) {
            // Log error to LogRocket session
            console.error('Ably Connection Error Message', {
                extra: {
                    catalogId,
                    channel: `catalog.${catalogId}`,
                    provider: 'ably',
                },
                tags: {
                    event: currentStatus,
                },
            });
            return dispatch(networkDisconnected({ catalogId, provider: 'ably' }));
        }
    };

/**
 * Handle PubNub client connection errors
 * @see https://www.pubnub.com/docs/connections/connection-management#connection-issues
 */
export const handlePubnubCatalogOnStatus =
    (catalogId: number) => (statusEvent: PubnubStatusEvent) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const currentProvider = getPubSubMessagingProvider(state);
        const catalogNetworkStatus = getCatalogNetworkStatus(state, catalogId);

        if (currentProvider !== 'pubnub') {
            return;
        }

        const userData = getUserData(state);
        const user = { email: userData.email, id: userData.houseId || 'brass', username: userData.username };

        // console log errors
        const logError = () => {
            console.error('Pubnub Error Message', {
                extra: {
                    ...user,
                },
                tags: {
                    event: statusEvent.category,
                },
            });
        };

        // PubNub connection re-established
        if (statusEvent.category === 'PNNetworkUpCategory' && catalogNetworkStatus === 'disconnected') {
            return dispatch(networkReconnected({ catalogId, provider: 'pubnub' }));
            // PubNub connection dropped
        } else if (statusEvent.category === 'PNNetworkDownCategory' && catalogNetworkStatus === 'connected') {
            logError();
            return dispatch(networkDisconnected({ catalogId, provider: 'pubnub' }));
        } else if (statusEvent.category === 'PNNetworkIssuesCategory' && catalogNetworkStatus === 'connected') {
            logError();
            return dispatch(networkDisconnected({ catalogId, provider: 'pubnub' }));
        } else if (statusEvent.category === 'PNReconnectedCategory' && catalogNetworkStatus === 'disconnected') {
            logError();
            return dispatch(networkReconnected({ catalogId, provider: 'pubnub' }));
        }

        return Promise.resolve();
    };

type NetworkStatusParams = {
    catalogId: number;
    provider: PubSubProvider;
};

const networkDisconnected = ({ catalogId, provider }: NetworkStatusParams) =>
    ({
        payload: { catalogId, provider },
        type: LIVE_NETWORK_DISCONNECTED,
    }) as LIVE_NETWORK_DISCONNECTED_ACTION;

export const networkReconnected =
    ({ catalogId, provider }: NetworkStatusParams) =>
    async (dispatch) => {
        await dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, true));

        dispatch({
            payload: { catalogId, provider },
            type: LIVE_NETWORK_RECONNECTED,
        } as LIVE_NETWORK_RECONNECTED_ACTION);
    };
