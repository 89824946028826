import { handleResponse, makePost } from './helpers';

type PostChangePasswordParams = {
    authToken: string;
    deployment: string;
    newPassword: string;
    oldPassword: string;
};

export default {
    postChangePassword: ({ authToken, deployment, newPassword, oldPassword }: PostChangePasswordParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePost({
                authToken,
                deployment,
                path: '<HOUSE-USER-API>update-password',
            });
            request.send({ newPassword, oldPassword });
            return request.end((err, response) => handleResponse({ err, reject, resolve, response }));
        }),
};
