import { Amount } from '@liveauctioneers/caterwaul-components/lib/types/Amount';
import { handleResponse, makeDelete, makeGet, makePost } from './helpers';
import { Invoice, InvoiceCalcualteRefund, InvoiceSendHistory, Lots } from '@/types/Invoice';

type GetBidderInvoice = {
    authToken: string;
    deployment: string;
    invoiceId: number;
};

export const getBidderInvoiceHouseView = ({ authToken, deployment, invoiceId }: GetBidderInvoice) =>
    new Promise<{ payload: Invoice }>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/invoice/${invoiceId}/house`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type GetBidderInvoiceSendHistory = {
    authToken: string;
    deployment: string;
    invoiceId: number;
};

export const getBidderInvoiceSendHistory = ({ authToken, deployment, invoiceId }: GetBidderInvoiceSendHistory) =>
    new Promise<{ payload: InvoiceSendHistory[] }>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/invoice/${invoiceId}/send`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBidderInvoiceSend = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    personalMessage: string;
};

export const postBidderInvoiceSend = ({ authToken, deployment, invoiceId, personalMessage }: PostBidderInvoiceSend) =>
    new Promise<{ payload: boolean }>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/invoice/${invoiceId}/send`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({
            personalMessage,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBidderInvoiceMarkPaid = {
    amount: Amount;
    authToken: string;
    deployment: string;
    invoiceId: number;
};

export const postBidderInvoiceMarkPaid = ({ amount, authToken, deployment, invoiceId }: PostBidderInvoiceMarkPaid) =>
    new Promise<{ payload: boolean }>((resolve, reject) => {
        const request = makePost({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/paid`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({
            amount: amount.amount,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBidderInvoiceShipping = {
    amount: Amount;
    authToken: string;
    deployment: string;
    invoiceId: number;
    shippingMethod: string;
};

export const postBidderInvoiceShipping = ({
    amount,
    authToken,
    deployment,
    invoiceId,
    shippingMethod,
}: PostBidderInvoiceShipping) =>
    new Promise<{ payload: {} }>((resolve, reject) => {
        const request = makePost({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/shipping`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({
            Method: shippingMethod,
            shippingAmount: amount,
        });
        //returns a legacy invoice object which isn't useful.
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBidderInvoiceAdjustment = {
    adjustmentAmount: Amount;
    adjustmentExplanation: string;
    adjustmentName: string;
    authToken: string;
    deployment: string;
    invoiceId: number;
};

type PostBidderInvoiceAdjustmentResponse = {
    error: Boolean;
    payload: {
        other_charges: [
            {
                amount: number;
                chargeID: number;
                explanation: string;
                name: string;
            },
        ];
    };
};

export const postBidderInvoiceAdjustment = ({
    adjustmentAmount,
    adjustmentExplanation,
    adjustmentName,
    authToken,
    deployment,
    invoiceId,
}: PostBidderInvoiceAdjustment) =>
    new Promise<{ payload: PostBidderInvoiceAdjustmentResponse }>((resolve, reject) => {
        const request = makePost({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/adjustment`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({
            adjustmentAmount,
            adjustmentExplanation,
            adjustmentName,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type PostBidderInvoiceAddPayment = {
    authToken: string;
    deployment: string;
    description: string;
    invoiceId: number;
    paymentAmount: Amount;
};

export const postBidderInvoiceAddPayment = ({
    authToken,
    deployment,
    description,
    invoiceId,
    paymentAmount,
}: PostBidderInvoiceAddPayment) =>
    new Promise<{ payload: InvoiceSendHistory[] }>((resolve, reject) => {
        const request = makePost({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/payment`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({
            description,
            paymentAmount,
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeleteBidderInvoiceAdjustment = {
    adjustmentId: number;
    authToken: string;
    deployment: string;
    invoiceId: number;
};

export const deleteBidderInvoiceAdjustment = ({
    adjustmentId,
    authToken,
    deployment,
    invoiceId,
}: DeleteBidderInvoiceAdjustment) =>
    new Promise<{ payload: InvoiceSendHistory[] }>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/adjustment/${adjustmentId}`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type DeleteBidderInvoicePayment = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    paymentId: number;
};

export const deleteBidderInvoicePayment = ({
    authToken,
    deployment,
    invoiceId,
    paymentId,
}: DeleteBidderInvoicePayment) =>
    new Promise<{ payload: InvoiceSendHistory[] }>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/payment/${paymentId}`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

type InvoiceCalculate = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    isFullRefund: boolean;
    lots: Lots[];
    reason: string;
    shippingRefundAmount: number;
};

export const getRefundInvoiceCalculate = ({
    authToken,
    deployment,
    invoiceId,
    isFullRefund,
    lots,
    reason,
    shippingRefundAmount,
}: InvoiceCalculate) =>
    new Promise<{ payload: InvoiceCalcualteRefund }>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/invoice/${invoiceId}/refund/calculate`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({ isFullRefund, lots, reason, shippingRefundAmount });
        request.end((err: boolean, response) => handleResponse({ err, reject, resolve, response }));
    });

type RefundInvoice = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    isFullRefund: boolean;
    lots: Lots[];
    reason: string;
    shippingRefundAmount: number;
};

// endpoint for house to issue refund
export const sendRefundInvoice = ({
    authToken,
    deployment,
    invoiceId,
    isFullRefund,
    lots,
    reason,
    shippingRefundAmount,
}: RefundInvoice) =>
    new Promise<{ payload: any }>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/invoice/${invoiceId}/refund/process`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({ isFullRefund, lots, reason, shippingRefundAmount });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const postVoidTax = ({ amount, authorizingUserId, authToken, date, deployment, invoiceId, reason }) =>
    new Promise<{ payload: any }>((resolve, reject) => {
        const request = makePost({
            apiPath: `billing/bidder/legacy/invoice/${invoiceId}/taxes/override`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.send({ amount, authorizingUserId, date, invoiceId, reason });
        request.end((err, response) => handleResponse({ err, reject, resolve, response }));
    });

export const postRefundTax = ({ authToken, deployment, invoiceid }) => {
    new Promise<{ payload: boolean }>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/invoice/${invoiceid}/refund/tax`,
            authToken,
            deployment,
            path: '<BILLING-SERVER-API>',
        });
        request.end((err: boolean, response) => handleResponse({ err, reject, resolve, response }));
    });
};
