import { AsyncThunkBaseConfig, AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    deleteItemDimensions,
    fetchParsedCatalogShippingItems,
    getDownloadShippingItems,
    postConfirmItems,
    postParseCatalogShippingForItems,
} from './catalogShippingParser.api';
import {
    DeleteParsedCatalogShippingItemDimensionsResponse,
    DeleteParsedShippingItemDimensionsParams,
    FetchParsedCatalogShippingItemsResponse,
    FetchParsedShippingItemsParams,
    GetDownloadShippingItemsParams,
    GetDownloadShippingItemsResponse,
    ParsedCatalogShippingItemsSearchFilters,
    PostConfirmItemsParams,
    PostConfirmItemsRejectedPayload,
    PostParseCatalogShippingItemsResponse,
    PostParseShippingItemsParams,
} from './catalogShippingParser.types';
import { getAuthToken } from '@/redux/modules/user';
import { getDeployment } from '@/redux/modules/config';
import { getParsedCatalogItemsPagination } from './catalogShippingParser.selectors';

export const loadParsedCatalogShippingItems = createAsyncThunk<
    FetchParsedCatalogShippingItemsResponse['payload'],
    FetchParsedShippingItemsParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogShippingParser/loadParsedCatalogShippingItems', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        const { error, payload } = await fetchParsedCatalogShippingItems({
            authToken,
            deployment,
            params,
        });

        if (error) {
            throw error;
        }

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching catalog shipping parser items'));
    }
});

export const updateFilters = createAsyncThunk<
    ParsedCatalogShippingItemsSearchFilters,
    ParsedCatalogShippingItemsSearchFilters
>('la/ui/catalogShippingParser/updateFilters', (filters) => filters);

export const processCatalogShippingItems = createAsyncThunk<
    PostParseCatalogShippingItemsResponse['payload'],
    PostParseShippingItemsParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogShippingParser/processCatalogShippingItems', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    try {
        const { error, payload } = await postParseCatalogShippingForItems({ authToken, deployment, params });

        if (error) {
            throw error;
        }

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in parsing catalog shipping parser items'));
    }
});

export const confirmCatalogShippingItems = createAsyncThunk<
    PostParseCatalogShippingItemsResponse['payload'],
    PostConfirmItemsParams,
    AsyncThunkBaseConfig & {
        rejectValue: PostConfirmItemsRejectedPayload | Error;
    }
>(
    'la/ui/catalogShippingParser/confirmCatalogShippingItems',
    async ({ catalogId, editOnly, lots, quoteVisibility }, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { page, pageSize } = getParsedCatalogItemsPagination(state);

        try {
            const { payload } = await postConfirmItems({
                authToken,
                catalogId,
                deployment,
                editOnly,
                lots,
                page,
                pageSize,
                quoteVisibility,
            });
            if (payload.lotsWithError.length > 0) {
                return rejectWithValue({
                    failedLotIds: payload.lotsWithError,
                    successfulLots: payload.lots,
                });
            } else {
                return payload;
            }
        } catch (error) {
            return rejectWithValue(new Error('Error in confirming catalog shipping items'));
        }
    }
);

export const removeCatalogShippingItemDimensions = createAsyncThunk<
    DeleteParsedCatalogShippingItemDimensionsResponse['payload'],
    DeleteParsedShippingItemDimensionsParams,
    AsyncThunkBaseConfig & {
        rejectValue: Error;
    }
>(
    'la/ui/catalogShippingParser/removeCatalogShippingItemDimensions',
    async ({ catalogId, lotIds }, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        try {
            const { payload } = await deleteItemDimensions({
                authToken,
                catalogId,
                deployment,
                lotIds,
            });

            return payload;
        } catch (error) {
            return rejectWithValue(new Error('Error removing catalog shipping items dimensions'));
        }
    }
);

// This "action" doesn't dispatch actions - just returns a file blob
export const downloadShippingItems = createAsyncThunk<
    GetDownloadShippingItemsResponse['payload'],
    GetDownloadShippingItemsParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogShippingParser/downloadShippingItems', async ({ catalogId }, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getDownloadShippingItems({
            authToken,
            catalogId,
            deployment,
        });

        const binaryString = window.atob(payload.bytes as string);
        const length = binaryString.length;

        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // The blob is typed as any in order to add the bytes attribute
        let blob: any = new Blob([bytes], { type: 'application/xlsx' });
        blob.bytes = bytes;

        return blob;
    } catch (error) {
        return rejectWithValue(new Error('Error downloading the shipping items'));
    }
});

export const resetConfirmationSlice = createAsyncThunk<void, void>(
    'la/ui/catalogShippingParser/resetConfirmationSlice',
    () => {}
);
