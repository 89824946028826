import { ActionWithPayload } from '../../types/redux';
import { Catalog } from '../../types/Catalog';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { DanteCurrentItem } from '../../types/DanteCurrentItem';
import { getMobileBrowserOS, getUiBrowser, isUiTablet } from './browser';
import { GlobalState } from '../../redux/rootReducer';
import {
    LIVE_ASK_CHANGED,
    LIVE_ASK_CHANGED_ACTION,
    LIVE_AUCTION_ENDED,
    LIVE_AUCTION_ENDED_ACTION,
    LIVE_AUCTION_MOVED,
    LIVE_AUCTION_MOVED_ACTION,
    LIVE_AUCTION_PAUSED,
    LIVE_AUCTION_PAUSED_ACTION,
    LIVE_AUCTION_RESUMED,
    LIVE_AUCTION_RESUMED_ACTION,
    LIVE_AUCTION_STARTED,
    LIVE_AUCTION_STARTED_ACTION,
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LIVE_BID_SENT,
    LIVE_BID_SENT_ACTION,
    LIVE_LOT_CLOSED,
    LIVE_LOT_CLOSED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LIVE_MISSIVE,
    LIVE_MISSIVE_ACTION,
    LIVE_NETWORK_DISCONNECTED,
    LIVE_NETWORK_RECONNECTED,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LIVE_UPDATE_CATALOG_OCCUPANCY,
    LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION,
    LOAD_CATALOGS_SUCCESS,
    LOAD_CLERK_AUCTION_STATE_FAIL,
    LOAD_CLERK_AUCTION_STATE_REQUEST,
    LOAD_CLERK_AUCTION_STATE_SUCCESS,
    LOAD_CLERK_AUCTION_STATE_SUCCESS_ACTION,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    SEND_CHANGE_BID_INCREMENT_FAILURE,
    SEND_CHANGE_BID_INCREMENT_FAILURE_ACTION,
    SEND_CHANGE_BID_INCREMENT_REQUEST,
    SEND_CHANGE_BID_INCREMENT_REQUEST_ACTION,
    SEND_CHANGE_CURRENT_ASK_FAILURE,
    SEND_CHANGE_CURRENT_ASK_FAILURE_ACTION,
    SEND_COMPETING_BID_FAILURE,
    SEND_LOT_PASSED_FAILURE,
    SEND_LOT_PASSED_FAILURE_ACTION,
    SEND_LOT_REOPEN_FAILURE,
    SEND_LOT_REOPEN_FAILURE_ACTION,
    SEND_LOT_SKIPPED_FAILURE,
    SEND_LOT_SKIPPED_FAILURE_ACTION,
    SEND_LOT_SOLD_FAILURE,
    SEND_LOT_SOLD_FAILURE_ACTION,
    SEND_OPEN_NEXT_LOT_FAILURE,
    SEND_OPEN_NEXT_LOT_FAILURE_ACTION,
    SEND_PAUSE_AUCTION_REQUEST,
    SEND_RESUME_AUCTION_REQUEST,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';

/* Action Types */

/* helpers */
const getCurrentTimestamp = () => new Date().getTime();

type LiveCatalog = {
    activeBidder: boolean;
    approved: boolean;
    bidderOutbid: boolean;
    closed: boolean;
    currency: string;
    currentItem: number;
    currentItemIndex: number;
    networkStatus: string;
    previousItemId: number;
    previousLotNumber: string;
    pubnubBidderCount: number;
    sfsBidderCount: number;
    status: string;
    won: boolean;
};

type LiveItem = {
    assignedId: string;
    created: number;
    currentAskPrice: number;
    currentIncrement: number;
    description: string;
    highEstimate: number;
    index: number;
    leadingBid: number;
    leadingBidder: number;
    lotNumber: string;
    lowEstimate: number;
    previousBidAmount: number;
    soldAssignedId: number;
    source: string;
    status: string;
    title: string;
};

export type State = {
    catalogs: {
        [catalogId: number]: LiveCatalog;
    };
    items: {
        [itemId: number]: LiveItem;
    };
};

/* reducer */
export const DEFAULT_STATE: State = {
    catalogs: {},
    items: {},
};

export const DEFAULT_CATALOG: LiveCatalog = {
    activeBidder: false,
    approved: false,
    bidderOutbid: false,
    closed: false,
    currency: 'USD',
    currentItem: 0,
    currentItemIndex: 0,
    networkStatus: 'connected',
    previousItemId: 0,
    previousLotNumber: '',
    pubnubBidderCount: 0,
    sfsBidderCount: 0,
    status: 'notLoaded',
    won: false,
};

export const DEFAULT_ITEM: LiveItem = {
    assignedId: '',
    created: 0,
    currentAskPrice: 0,
    currentIncrement: 1,
    description: '',
    highEstimate: 0,
    index: 0,
    leadingBid: 0,
    leadingBidder: 0,
    lotNumber: '',
    lowEstimate: 0,
    previousBidAmount: 0,
    soldAssignedId: 0,
    source: '',
    status: 'open',
    title: '',
};

const copyCatalogs = (state: State, catalogId: number) => {
    const catalogs = cloneDeep(state.catalogs);
    catalogs[catalogId] = catalogs[catalogId] || cloneDeep(DEFAULT_CATALOG);
    return catalogs;
};

export const reducer = handleActions(
    {
        [LIVE_ASK_CHANGED]: (state: State, action: LIVE_ASK_CHANGED_ACTION): State => {
            const { ask, catalogId, increment, itemId, itemIndex } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].currentAskPrice = ask;
            items[itemId].currentIncrement = increment;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_AUCTION_ENDED]: (state: State, action: LIVE_AUCTION_ENDED_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'ended';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_MOVED]: (state: State, action: LIVE_AUCTION_MOVED_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'notLoaded';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_PAUSED]: (state: State, action: LIVE_AUCTION_PAUSED_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'paused';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_RESUMED]: (state: State, action: LIVE_AUCTION_RESUMED_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'live';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_STARTED]: (state: State, action: LIVE_AUCTION_STARTED_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'live';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_BID_ACCEPTED]: (state: State, action: LIVE_BID_ACCEPTED_ACTION): State => {
            const {
                amount,
                ask,
                assignedId,
                bidderId,
                catalogId,
                created,
                increment,
                itemId,
                itemIndex,
                myBid,
                source,
            } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].activeBidder = Boolean(catalogs[catalogId].activeBidder) || Boolean(myBid);
            catalogs[catalogId].closed = false;
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {
                assignedId: 0,
                created: getCurrentTimestamp(),
                currentAskPrice: 0,
                currentIncrement: 1,
                leadingBid: 0,
                leadingBidder: 0,
                source: '',
            };

            if (items[itemId].created < created) {
                items[itemId].created = created;
                items[itemId].currentAskPrice = ask;
                items[itemId].currentIncrement = increment;
                items[itemId].leadingBid = amount;
                items[itemId].leadingBidder = bidderId;
                // @ts-ignore
                items[itemId].assignedId = assignedId;
                items[itemId].source = bidderId === 0 ? 'Floor' : source; // TODO: do we need source to come back in these messages?
            }

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_BID_RETRACTED]: (state: State, action: LIVE_BID_RETRACTED_ACTION): State => {
            const { amount, ask, assignedId, bidderId, catalogId, created, increment, itemId, itemIndex, source } =
                action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].bidderOutbid = false;
            catalogs[catalogId].closed = false;
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            // @ts-ignore
            items[itemId].assignedId = assignedId;
            items[itemId].created = created;
            items[itemId].currentAskPrice = ask;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = amount;
            items[itemId].leadingBidder = bidderId;
            items[itemId].source = bidderId === 0 ? 'Floor' : source; // TODO: do we need source to come back in these messages?;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_BID_SENT]: (state: State, action: LIVE_BID_SENT_ACTION): State => {
            const { amount, itemId } = action.payload;

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].currentAskPrice = amount;

            return {
                ...state,
                items,
            };
        },
        [LIVE_LOT_CLOSED]: (state: State, action: LIVE_LOT_CLOSED_ACTION): State => {
            const { catalogId, itemId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'live';

            catalogs[catalogId].bidderOutbid = false;
            if (catalogs[catalogId].currentItem === itemId) {
                catalogs[catalogId].closed = true;
                catalogs[catalogId].previousItemId = itemId;
            }

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_LOT_PASSED]: (state: State, action: LIVE_LOT_PASSED_ACTION): State => {
            const { catalogId, lotNumber } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].bidderOutbid = false;
            catalogs[catalogId].closed = true;
            catalogs[catalogId].previousItemId = catalogs[catalogId].currentItem;
            catalogs[catalogId].previousLotNumber = lotNumber;
            catalogs[catalogId].status = 'live';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_LOT_REOPENED]: (state: State, action: LIVE_LOT_REOPENED_ACTION): State => {
            const {
                askPrice,
                catalogId,
                created,
                highBid,
                increment,
                itemId,
                itemIndex,
                leadingAssignedId,
                leadingBidderId,
            } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].closed = false;
            catalogs[catalogId].status = 'live';
            catalogs[catalogId].previousLotNumber = '';

            // if from new system set variables, else don't -- old message does not have askprice
            if (askPrice !== null && askPrice !== undefined && askPrice !== 0) {
                const items = cloneDeep(state.items);
                if (itemId) {
                    catalogs[catalogId].currentItem = itemId;
                    catalogs[catalogId].currentItemIndex = itemIndex || 0;
                    // @ts-ignore
                    items[itemId] = items[itemId] || {};
                    // @ts-ignore
                    items[itemId].assignedId = leadingAssignedId;
                    items[itemId].created = created;
                    items[itemId].currentAskPrice = askPrice;
                    items[itemId].currentIncrement = increment;
                    items[itemId].leadingBid = highBid;
                    items[itemId].leadingBidder = leadingBidderId;
                    // items[itemId].source = leadingBidderId === 0 ? 'Floor' : source; // TODO: do we need source to come back in these messages?;
                }
                return {
                    ...state,
                    catalogs,
                    items,
                };
            } else {
                return {
                    ...state,
                    catalogs,
                };
            }
        },
        [LIVE_LOT_SOLD]: (state: State, action: LIVE_LOT_SOLD_ACTION): State => {
            const { assignedId, catalogId, itemId, lotNumber, myBid } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].bidderOutbid = false;
            catalogs[catalogId].closed = true;
            catalogs[catalogId].previousItemId = catalogs[catalogId].currentItem;
            catalogs[catalogId].previousLotNumber = lotNumber;
            catalogs[catalogId].status = 'live';
            catalogs[catalogId].won = myBid;

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            // @ts-ignore
            items[itemId].soldAssignedId = assignedId;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_LOT_UNSOLD]: (state: State, action: LIVE_LOT_UNSOLD_ACTION): State => {
            const { catalogId, lotNumber } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].bidderOutbid = false;
            catalogs[catalogId].closed = true;
            catalogs[catalogId].previousItemId = catalogs[catalogId].currentItem;
            catalogs[catalogId].previousLotNumber = lotNumber;
            catalogs[catalogId].status = 'live';
            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_MISSIVE]: (state: State, action: LIVE_MISSIVE_ACTION): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'live';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NETWORK_DISCONNECTED]: (state: State, action: ActionWithPayload<{ catalogId: number }>): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].networkStatus = 'disconnected';
            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NETWORK_RECONNECTED]: (state: State, action: ActionWithPayload<{ catalogId: number }>): State => {
            const { catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].networkStatus = 'connected';
            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NEXT_LOT_LOADED]: (state: State, action: LIVE_NEXT_LOT_LOADED_ACTION): State => {
            const {
                askPrice,
                catalogId,
                created,
                currency,
                description,
                highEstimate,
                increment,
                index,
                itemId,
                itemIndex,
                lotNumber,
                lotTitle,
                lowEstimate,
                pendingBid,
                status,
            } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].activeBidder = false;
            catalogs[catalogId].bidderOutbid = false;
            catalogs[catalogId].closed = false;
            catalogs[catalogId].currency = currency;
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';
            catalogs[catalogId].won = false;

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            // @ts-ignore
            items[itemId].assignedId = 0;
            items[itemId].created = created;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].description = description;
            items[itemId].highEstimate = highEstimate;
            items[itemId].index = index;
            items[itemId].leadingBid = 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].lowEstimate = lowEstimate;
            items[itemId].source = '';
            items[itemId].status = status;
            items[itemId].title = lotTitle;

            if (pendingBid) {
                items[itemId].currentAskPrice = pendingBid;
            }

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_UPDATE_CATALOG_OCCUPANCY]: (state: State, action: LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION): State => {
            const { bidderCount, catalogId } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].pubnubBidderCount = bidderCount;

            return {
                ...state,
                catalogs,
            };
        },
        [LOAD_CATALOGS_SUCCESS]: (state: State, action: ActionWithPayload<{ catalogs: Catalog[] }>): State => {
            const catalogs = cloneDeep(state.catalogs);
            action.payload.catalogs.forEach((catalog) => {
                if (catalog.catalogStatus === 'done') {
                    // set the catalog to done if it is
                    // @ts-ignore
                    catalogs[catalog.catalogId] = catalogs[catalog.catalogId] || {};
                    catalogs[catalog.catalogId].status = catalog.catalogStatus;
                }
            });

            return {
                ...state,
                catalogs,
            };
        },
        [LOAD_CLERK_AUCTION_STATE_FAIL]: (
            state: State,
            action: ActionWithPayload<string, { catalogId: number }>
        ): State => {
            const { catalogId } = action.meta;
            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].previousLotNumber = '';

            return { ...state, catalogs };
        },
        [LOAD_CLERK_AUCTION_STATE_REQUEST]: (state: State, action: ActionWithPayload<{ catalogId: number }>): State => {
            const { catalogId } = action.payload;
            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].previousLotNumber = '';

            return { ...state, catalogs };
        },
        [LOAD_CLERK_AUCTION_STATE_SUCCESS]: (state: State, action: LOAD_CLERK_AUCTION_STATE_SUCCESS_ACTION): State => {
            const { catalogId, currentItem, itemCount, lastUpdated, previousLotNumber, status } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            if (catalogs[catalogId].status !== 'done') {
                catalogs[catalogId].status = status;
            }
            // @ts-ignore
            catalogs[catalogId].itemCount = itemCount;
            catalogs[catalogId].previousLotNumber = previousLotNumber;

            const items = cloneDeep(state.items);

            if (currentItem && currentItem.itemId) {
                const { askPrice, increment, itemId, itemIndex, lotNumber } = currentItem;

                const created = new Date(lastUpdated).getTime();

                catalogs[catalogId].currentItem = itemId;
                catalogs[catalogId].currentItemIndex = itemIndex || 0;

                // @ts-ignore
                items[itemId] = items[itemId] || {};
                items[itemId].created = created || getCurrentTimestamp();
                items[itemId].currentAskPrice = askPrice;
                items[itemId].currentIncrement = increment;
                items[itemId].lotNumber = lotNumber;
            }

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{
                catalogId: number;
                created: number;
                currency: string;
                currentItem: DanteCurrentItem;
                itemCount: number;
                previousLotNumber?: string;
                status: string;
            }>
        ): State => {
            const { catalogId, created, currency, currentItem, itemCount, previousLotNumber, status } = action.payload;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currency = currency;
            if (catalogs[catalogId].status !== 'done') {
                catalogs[catalogId].status = status;
            }
            // @ts-ignore
            catalogs[catalogId].itemCount = itemCount;

            const items = cloneDeep(state.items);

            if (currentItem && currentItem.itemId) {
                const {
                    askPrice,
                    highEstimate,
                    increment,
                    itemId,
                    itemIndex,
                    leadingAssignedId,
                    leadingBid,
                    leadingBidder,
                    lotDescription,
                    lotNumber,
                    lotTitle,
                    lowEstimate,
                } = currentItem;

                catalogs[catalogId].closed = false;
                catalogs[catalogId].won = false;
                catalogs[catalogId].currentItem = itemId;
                catalogs[catalogId].currentItemIndex = itemIndex || 0;

                // For new SFS, store previous lot number
                if (previousLotNumber) {
                    catalogs[catalogId].previousLotNumber = previousLotNumber;
                }

                // @ts-ignore
                items[itemId] = items[itemId] || {};
                // @ts-ignore
                items[itemId].assignedId = leadingAssignedId || 0;
                items[itemId].created = created || getCurrentTimestamp();
                items[itemId].currentAskPrice = askPrice;
                items[itemId].currentIncrement = increment;
                items[itemId].description = lotDescription;
                items[itemId].highEstimate = highEstimate;
                items[itemId].leadingBid = leadingBid || 0;
                items[itemId].leadingBidder = leadingBidder || 0;
                items[itemId].lotNumber = lotNumber;
                items[itemId].lowEstimate = lowEstimate;
                items[itemId].source = leadingBidder === 0 ? 'Floor' : 'Internet';
                items[itemId].title = lotTitle;
            }

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_CHANGE_BID_INCREMENT_FAILURE]: (
            state: State,
            action: SEND_CHANGE_BID_INCREMENT_FAILURE_ACTION
        ): State => {
            const { itemId, previousIncrement } = action.meta;

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].currentIncrement = previousIncrement;

            return {
                ...state,
                items,
            };
        },
        [SEND_CHANGE_BID_INCREMENT_REQUEST]: (
            state: State,
            action: SEND_CHANGE_BID_INCREMENT_REQUEST_ACTION
        ): State => {
            const { increment, itemId } = action.payload;

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            // @ts-ignore
            items[itemId].currentIncrement = increment;

            return {
                ...state,
                items,
            };
        },
        [combineActions(SEND_CHANGE_CURRENT_ASK_FAILURE, SEND_COMPETING_BID_FAILURE)]: (
            state: State,
            action: SEND_CHANGE_CURRENT_ASK_FAILURE_ACTION
        ): State => {
            const { itemId, previousAsk } = action.meta;

            // Set the ask back to the previous value on failure
            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].currentAskPrice = previousAsk;

            return {
                ...state,
                items,
            };
        },
        [SEND_LOT_PASSED_FAILURE]: (state: State, action: SEND_LOT_PASSED_FAILURE_ACTION) => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { askPrice, increment, itemId, itemIndex, leadingAssignedId, leadingBid, lotNumber } = currentState;

            if (!itemId) {
                return state;
            }

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].assignedId = leadingAssignedId || 0;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = leadingBid || 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].source = leadingAssignedId === 0 ? 'Floor' : 'Internet';

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_LOT_REOPEN_FAILURE]: (state: State, action: SEND_LOT_REOPEN_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { askPrice, increment, itemId, itemIndex, leadingAssignedId, leadingBid, lotNumber } = currentState;

            if (!itemId) {
                return state;
            }

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].assignedId = leadingAssignedId || 0;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = leadingBid || 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].source = leadingAssignedId === 0 ? 'Floor' : 'Internet';

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_LOT_SKIPPED_FAILURE]: (state: State, action: SEND_LOT_SKIPPED_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { askPrice, increment, itemId, itemIndex, leadingAssignedId, leadingBid, lotNumber } = currentState;

            if (!itemId) {
                return state;
            }

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].assignedId = leadingAssignedId || 0;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = leadingBid || 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].source = leadingAssignedId === 0 ? 'Floor' : 'Internet';

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_LOT_SOLD_FAILURE]: (state: State, action: SEND_LOT_SOLD_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { askPrice, increment, itemId, itemIndex, leadingAssignedId, leadingBid, lotNumber } = currentState;

            if (!itemId) {
                return state;
            }

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].assignedId = leadingAssignedId || 0;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = leadingBid || 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].source = leadingAssignedId === 0 ? 'Floor' : 'Internet';

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_OPEN_NEXT_LOT_FAILURE]: (state: State, action: SEND_OPEN_NEXT_LOT_FAILURE_ACTION): State => {
            const { catalogId, currentState: curState } = action.meta;
            const currentState = curState || {};
            // @ts-ignore
            const { askPrice, increment, itemId, itemIndex, leadingAssignedId, leadingBid, lotNumber } = currentState;

            if (!itemId) {
                return state;
            }

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = itemIndex;
            catalogs[catalogId].status = 'live';

            const items = cloneDeep(state.items);
            // @ts-ignore
            items[itemId] = items[itemId] || {};
            items[itemId].assignedId = leadingAssignedId || 0;
            items[itemId].currentAskPrice = askPrice;
            items[itemId].currentIncrement = increment;
            items[itemId].leadingBid = leadingBid || 0;
            items[itemId].leadingBidder = 0;
            items[itemId].lotNumber = lotNumber;
            items[itemId].source = leadingAssignedId === 0 ? 'Floor' : 'Internet';

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [SEND_PAUSE_AUCTION_REQUEST]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>): State => {
            const { catalogId } = action.meta;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'paused';

            return {
                ...state,
                catalogs,
            };
        },
        [SEND_RESUME_AUCTION_REQUEST]: (state: State, action: ActionWithPayload<{}, { catalogId: number }>): State => {
            const { catalogId } = action.meta;

            const catalogs = copyCatalogs(state, catalogId);
            catalogs[catalogId].status = 'live';

            return {
                ...state,
                catalogs,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.console;
const idSelector = (state, id) => id;

const catalogsSelector = createSelector(stateSelector, (state) => state.catalogs);
const itemsSelector = createSelector(stateSelector, (state) => state.items);

export const getCatalogNetworkStatus = createSelector([catalogsSelector, idSelector], (catalogs, id) => {
    return catalogs[id]?.networkStatus || 'connected';
});

export const getCatalogBidders = createSelector([catalogsSelector, idSelector], (catalogs, id) => {
    const sfs = catalogs[id]?.sfsBidderCount || 0;
    const pubnub = catalogs[id]?.pubnubBidderCount || 0;
    return sfs + pubnub || 1;
});

export const getLiveCatalogData = createSelector(
    [catalogsSelector, getCatalogBidders, idSelector],
    (catalogs, bidders, id) => {
        return { ...DEFAULT_CATALOG, ...(catalogs[id] || {}), bidders };
    }
);

export const getLiveItemData = createSelector(
    [itemsSelector, idSelector],
    (items, id): LiveItem => items[id] || DEFAULT_ITEM
);

export const getLiveItemExists = createSelector([itemsSelector, idSelector], (items, id) => items.hasOwnProperty(id));

export const getBrowserSessionCookieSuffix = (state: any) => {
    const mobileBrowser = getMobileBrowserOS(state);
    const tablet = isUiTablet(state);
    const { majorVersion, name } = getUiBrowser(state);
    const browser = `${name}${majorVersion}`;

    const deviceType = !mobileBrowser ? 'computer' : tablet ? 'tablet' : 'phone';

    return `::web-${deviceType}-${browser}`;
};
