import { getImageUrl } from '../../utils/urls';
import {
    handleResponse,
    makeGet,
    makePatch,
    /*, makePost */
} from './helpers';

const transformConversations = ({ error, meta, payload }) => {
    const transformedPayload = Array.isArray(payload)
        ? payload.map((conv: any) => {
              const { id: bidderId, name } = conv.participants.find((p) => p.type === 'bidder');
              const { id: sellerId, logoUrl } = conv.participants.find((p) => p.type === 'house');

              let imageUrl =
                  getImageUrl({
                      catalogId: conv.catalogId,
                      imageNumber: 1,
                      imageVersion: 1,
                      itemId: conv.lotId,
                      sellerId,
                      width: 100,
                  }) || '';

              return {
                  bidderId,
                  bidderManagementLink: conv.bidderManagementLink,
                  catalogId: conv.catalogId,
                  conversationId: conv.conversationId,
                  folderId: conv.folderId,
                  hasAttachments: conv.hasAttachments,
                  houseId: sellerId,
                  imageUrl,
                  itemId: conv.lotId,
                  logoUrl,
                  messageCount: conv.messageCount,
                  read: conv.read,
                  sender: name,
                  subject: conv.subject,
                  tsLastMessageSent: conv.tsLastMessageSent,
                  type: 'bidder',
              };
          })
        : payload;

    return {
        error,
        meta,
        payload: transformedPayload,
    };
};

type FetchConversationParams = {
    authToken: string;
    bidderId?: string;
    conversationId: number;
    deployment: string;
    houseId: number;
};

type FetchConversationsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
    query?: any;
};

type ConversationActionsParams = {
    authToken: string;
    conversations: number[];
    deployment: string;
    folderId?: number;
    houseId: number;
};

export default {
    archiveConversation: ({ authToken, conversations, deployment, houseId }: ConversationActionsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePatch({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            });
            request.send(
                conversations.map((id) => ({
                    id: Number(id),
                    op: 'replace',
                    path: '/folder/id',
                    value: -1,
                }))
            );
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),
    fetchConversation: ({ authToken, conversationId, deployment, houseId }: FetchConversationParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makeGet({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            });
            request.query({ conversationId });
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),

    fetchConversations: ({ authToken, deployment, query = {}, houseId }: FetchConversationsParams) =>
        new Promise<any>((resolve, reject) => {
            const requestQuery = { ...query };
            const options = {
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            };
            if (query.search) {
                options.path = `<MESSAGING-API>house/${houseId}/conversations/search`;
                requestQuery.q = requestQuery.search;
                delete requestQuery.search;
            }
            const request = makeGet(options);

            request.query(requestQuery);
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),

    markConversationRead: ({ authToken, conversations, deployment, houseId }: ConversationActionsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePatch({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            });
            request.send(
                conversations.map((id) => ({
                    id: Number(id),
                    op: 'replace',
                    path: '/read',
                    value: true,
                }))
            );
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),

    markConversationUnread: ({ authToken, conversations, deployment, houseId }: ConversationActionsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePatch({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            });
            request.send(
                conversations.map((id) => ({
                    id: Number(id),
                    op: 'replace',
                    path: '/read',
                    value: false,
                }))
            );
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),
    moveConversation: ({ authToken, conversations, deployment, folderId = 0, houseId }: ConversationActionsParams) =>
        new Promise<any>((resolve, reject) => {
            const request = makePatch({
                authToken,
                deployment,
                path: `<MESSAGING-API>house/${houseId}/conversations`,
            });
            request.send(
                conversations.map((id) => ({
                    id: Number(id),
                    op: 'replace',
                    path: '/folder/id',
                    value: folderId,
                }))
            );
            request.end((err, response) =>
                handleResponse({ err, reject, resolve, response, transform: transformConversations })
            );
        }),
};
