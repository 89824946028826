import { Carriers } from '@/pages/Shipping/subpages/LabelsPage/components/AvailableCarriers/components/CarrierBadge/CarrierBadge.types';
import { createSelector } from '@reduxjs/toolkit';
import {
    CustomPackagingFees,
    emptyHouseLabelPermissions,
    emptyShippingLabelsErrors,
    emptyShippingLabelsLoadingInfo,
    emptyShippingLabelsLoadTimes,
    PackagingFees,
    Printers,
} from '@/redux/modules/shipping/labels/shippingLabels.types';
import { GlobalState } from '@/redux/rootReducer';
import ms from 'ms';

export const SHIPPING_LABELS_CACHE_TIME = ms('5m');

const sliceSelector = (state: GlobalState) => state.shippingLabels;
const idSelector = (_: GlobalState, id: number) => id;
const byIdSelector = createSelector([sliceSelector, idSelector], (slice, id) => slice.byId[id]);

export const getSelectedPackagingFeeType = createSelector([byIdSelector], (info) => info?.type ?? PackagingFees.NoFee);

export const getCustomPackagingFeeAmount = createSelector([byIdSelector], (info) => info?.amount ?? 0);

export const getCustomPackagingFeeType = createSelector(
    [byIdSelector],
    (info) => info?.amountType ?? CustomPackagingFees.Package
);

const permissionsSelector = createSelector(
    [sliceSelector, idSelector],
    ({ permissions }, id) => permissions?.[id] ?? emptyHouseLabelPermissions
);

export const getHouseHasLiveShippingAvailable = createSelector(
    [permissionsSelector],
    ({ isInLiveShippingBeta }) => isInLiveShippingBeta
);
export const getHouseHasShippingLabelAvailable = createSelector([permissionsSelector], ({ isELabel }) => isELabel);

export const getDefaultLabelCarrier = createSelector(
    [permissionsSelector],
    ({ isDefault }) => isDefault ?? Carriers.None
);

export const getHasUpsCarrier = createSelector(
    [permissionsSelector],
    ({ upsSettings }) => upsSettings?.isEnabled ?? false
);
export const getHasFedexCarrier = createSelector(
    [permissionsSelector],
    ({ fedexSettings }) => fedexSettings?.isEnabled ?? false
);
export const getHasUspsCarrier = createSelector(
    [permissionsSelector],
    ({ uspsSettings }) => uspsSettings?.isEnabled ?? false
);

const printersSelector = createSelector([sliceSelector, idSelector], ({ printers }, id) => printers[id]);

export const getShippingLabelPrinter = createSelector(
    [printersSelector],
    (savedPrinter) => savedPrinter ?? Printers.Pdf
);

const errorsSelector = createSelector(
    [sliceSelector, idSelector],
    ({ errors }, id) => errors[id] ?? emptyShippingLabelsErrors
);

export const getHasShippingLabelFeeError = createSelector([errorsSelector], ({ fee }) => fee ?? false);

export const getHasPrinterError = createSelector([errorsSelector], ({ printer }) => printer ?? false);

const getInfoLoadTimes = createSelector(
    [sliceSelector, idSelector],
    ({ loaded }, id) => loaded[id] ?? emptyShippingLabelsLoadTimes
);

export const getShippingLabelFeeLoadTime = createSelector([getInfoLoadTimes], ({ fee }) => fee ?? 0);

export const getShippingLabelPrinterLoadTime = createSelector([getInfoLoadTimes], ({ printer }) => printer ?? 0);

const getInfoLoading = createSelector(
    [sliceSelector, idSelector],
    ({ loading }, id) => loading[id] ?? emptyShippingLabelsLoadingInfo
);

export const getIsLoadingShippingLabelsFeeInfo = createSelector([getInfoLoading], ({ fee }) => fee ?? false);

export const getIsLoadingShippingLabelsPrinterInfo = createSelector(
    [getInfoLoading],
    ({ printer }) => printer ?? false
);

export const shouldFetchShippingLabelsFeesInfo = createSelector(
    [getShippingLabelFeeLoadTime, getIsLoadingShippingLabelsFeeInfo],
    (lastLoaded, isLoading) => {
        if (isLoading) {
            return false;
        }
        const timeSinceLastLoad = Date.now() - lastLoaded;
        return timeSinceLastLoad > SHIPPING_LABELS_CACHE_TIME;
    }
);

export const shouldFetchShippingLabelsPrinterInfo = createSelector(
    [getShippingLabelPrinterLoadTime, getIsLoadingShippingLabelsPrinterInfo],
    (lastLoaded, isLoading) => {
        if (isLoading) {
            return false;
        }
        const timeSinceLastLoad = Date.now() - lastLoaded;
        return timeSinceLastLoad > SHIPPING_LABELS_CACHE_TIME;
    }
);
