import { faTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSellerTermsAndConditions } from '@/utils/urls';
import { openHealthcheckModal } from '@/redux/modules/modal';
import { useAppDispatch } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import moment from 'moment';
import styled from 'styled-components';

const Footer = () => {
    const dispatch = useAppDispatch();
    const year = moment().format('YYYY');
    return (
        <StyledFooter>
            <InnerFooter>
                <Left>Copyright © 2002–{year} LiveAuctioneers. All Rights Reserved.</Left>
                <Right>
                    <Link to={getSellerTermsAndConditions()}>Terms & Conditions</Link>
                    {' | '}
                    <Link to="https://www.liveauctioneers.com/privacy">Privacy Policy</Link>
                    {' | '}
                    <Link onClick={() => dispatch(openHealthcheckModal())}>
                        <StyledIcon icon={faTachometerAlt} />
                    </Link>
                </Right>
            </InnerFooter>
        </StyledFooter>
    );
};

export default Footer;

const StyledFooter = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
`;

const InnerFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--steel);
    height: 70px;
    padding: 0 20px;
    max-width: 1240px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        text-align: center;
        margin: 10px 0;
    }
`;
const Right = styled.div`
    display: flex;
    flex-direction: row;

    & > * {
        color: var(--steel);
        margin: 0 5px;
    }

    @media (max-width: 767px) {
        margin: 0 0 10px;
    }
`;

const StyledIcon = styled(FontAwesomeIcon)`
    height: 20px;
`;
