import { ActionWithPayload } from '@/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from './user';
import { getCatalog } from './catalog';
import { getDeployment } from '@/redux/modules/config';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import { postSendMissive } from '../api/clerk';
import { SEND_MISSIVE_FAILURE, SEND_MISSIVE_REQUEST, SEND_MISSIVE_SUCCESS, UPDATE_MISSIVE_BUTTONS } from './actions';

/* reducer */
export type State = {
    buttons: {
        missive1: string;
        missive2: string;
        missive3: string;
        text1: string;
        text2: string;
        text3: string;
    };
    error?: string | null;
    submitted: boolean;
    success: boolean;
};

export const DEFAULT_STATE: State = {
    buttons: {
        missive1: 'Fair Warning',
        missive2: 'Fair Warning',
        missive3: 'Fair Warning',
        text1: 'Fair Warning',
        text2: 'Fair Warning',
        text3: 'Fair Warning',
    },
    error: null,
    submitted: false,
    success: false,
};

export const reducer = handleActions(
    {
        [SEND_MISSIVE_FAILURE]: (state: State, action: ActionWithPayload<string>) => ({
            ...state,
            error: action.payload,
            submitted: false,
            success: false,
        }),
        [SEND_MISSIVE_REQUEST]: (state: State) => ({
            ...state,
            error: null,
            submitted: true,
            success: false,
        }),
        [SEND_MISSIVE_SUCCESS]: (state: State) => ({
            ...state,
            error: null,
            submitted: false,
            success: true,
        }),
        [UPDATE_MISSIVE_BUTTONS]: (
            state: State,
            action: ActionWithPayload<{
                buttonText1: string;
                buttonText2: string;
                buttonText3: string;
                missiveText1: string;
                missiveText2: string;
                missiveText3: string;
            }>
        ) => ({
            ...state,
            buttons: {
                ...state.buttons,
                missive1: action.payload.missiveText1,
                missive2: action.payload.missiveText2,
                missive3: action.payload.missiveText3,
                text1: action.payload.buttonText1,
                text2: action.payload.buttonText2,
                text3: action.payload.buttonText3,
            },
            error: null,
            submitted: false,
            success: false,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.missive;

export const getMissiveButtons = createSelector(stateSelector, (state) => {
    return {
        buttonText1: state.buttons.text1,
        buttonText2: state.buttons.text2,
        buttonText3: state.buttons.text3,
        missiveText1: state.buttons.missive1,
        missiveText2: state.buttons.missive2,
        missiveText3: state.buttons.missive3,
    };
});

/* ACTION CREATORS */
export const submitMissiveMessage =
    (catalogId: number, missiveText: string) => async (dispatch: Function, getState: Function) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const { sellerId } = getCatalog(state, catalogId);
            const deployment = getDeployment(state);

            dispatch({
                type: SEND_MISSIVE_REQUEST,
            });
            const response = await postSendMissive({ authToken, catalogId, deployment, missiveText, sellerId });
            dispatch({
                meta: { catalogId },
                payload: response.payload,
                type: SEND_MISSIVE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SEND_MISSIVE_FAILURE,
            });
        }
    };

type UpdateMissiveButtonsParams = {
    buttonText1: string;
    buttonText2: string;
    buttonText3: string;
    catalogId: number;
    missiveText1: string;
    missiveText2: string;
    missiveText3: string;
};

export const updateMissiveButtons = ({
    buttonText1,
    buttonText2,
    buttonText3,
    missiveText1,
    missiveText2,
    missiveText3,
}: UpdateMissiveButtonsParams) => ({
    payload: { buttonText1, buttonText2, buttonText3, missiveText1, missiveText2, missiveText3 },
    type: UPDATE_MISSIVE_BUTTONS,
});
