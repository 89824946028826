import {
    CLEAR_HOUSE_PAYMENT_AMOUNT,
    CLEAR_HOUSE_PAYMENT_AMOUNT_ACTION,
    SET_HOUSE_PAYMENT_AMOUNT,
    SET_HOUSE_PAYMENT_AMOUNT_ACTION,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '../rootReducer';
import { handleActions } from 'redux-actions';

/* reducer */
export interface State {
    readonly paymentAmount: number | null;
    readonly selectedCardToken: string | null;
}

export const DEFAULT_STATE: State = {
    paymentAmount: undefined,
    selectedCardToken: undefined,
};

export const reducer = handleActions(
    {
        [CLEAR_HOUSE_PAYMENT_AMOUNT]: () => {
            return { ...DEFAULT_STATE };
        },
        [SET_HOUSE_PAYMENT_AMOUNT]: (state: State, action: SET_HOUSE_PAYMENT_AMOUNT_ACTION) => {
            return {
                ...state,
                paymentAmount: action.payload.paymentAmount,
                selectedCardToken: action.payload.selectedCardToken,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.housePaymentAmount;

export const getHousePaymentAmountData = createSelector(stateSelector, (state) => state);

/* ACTION CREATORS */
export const clearHousePaymentAmount = () => async (dispatch: Function) => {
    return dispatch({
        type: CLEAR_HOUSE_PAYMENT_AMOUNT,
    } as CLEAR_HOUSE_PAYMENT_AMOUNT_ACTION);
};

export const setHousePaymentAmount = (amount: number, cardToken: string) => async (dispatch: Function) => {
    return dispatch({
        payload: {
            paymentAmount: amount,
            selectedCardToken: cardToken,
        },
        type: SET_HOUSE_PAYMENT_AMOUNT,
    } as SET_HOUSE_PAYMENT_AMOUNT_ACTION);
};
