import { CatalogShippingParserState } from './catalogShippingParser.types';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/rootReducer';

const stateSelector = (state: GlobalState): CatalogShippingParserState => state.catalogShippingParser;

export const isLoading = createSelector(stateSelector, (state) => state.loading);

export const hasError = createSelector(stateSelector, (state) => state.error);

export const reloadItems = createSelector(stateSelector, (state) => state.reloadItems);

export const getIncompleteLotsFromConfirmationAttempt = createSelector(
    stateSelector,
    ({ errorInfo }) => errorInfo.unconfirmedLots
);

export const getShippingParserSuccess = createSelector(stateSelector, ({ success }) => success);

export const getParsedCatalogItemsPagination = createSelector(stateSelector, ({ page, pageSize, totalRecords }) => ({
    page,
    pageSize,
    totalRecords,
}));

export const getParsedCatalogItems = createSelector(stateSelector, (state) => state.items);

export const getParsedCatalogSearchFilters = createSelector(stateSelector, (state) => state.filters);

export const getParsedCatalogId = createSelector(stateSelector, (state) => state.catalogId);

export const getParsedCatalogTitle = createSelector(stateSelector, (state) => state.catalogTitle);

export const getParsedCatalogSellerName = createSelector(stateSelector, (state) => state.sellerName);
