import { ActionWithPayload } from '@/types/redux';
import { Catalog } from '@/types/Catalog';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { fetchConversationFoldersIfNeeded } from './conversationFolders';
import { fetchSellersIfNeeded } from './seller';
import { getDeployment } from './config';
import { GlobalState } from '../rootReducer';
import { isEmpty } from 'lodash';
import { isValidUserType } from '@/utils/user';
import { LOAD_CATALOGS_SUCCESS, LOG_OUT, LOGIN_FAIL, LOGIN_SUCCESS } from './actions/oldActions';
import api from '../api';
import jwtdecode from 'jwt-decode';

/* Action Types */
export const MANAGABLE_CATALOG_FAIL = 'MANAGABLE_CATALOG_FAIL';
export const MANAGABLE_CATALOG_REQUEST = 'MANAGABLE_CATALOG_REQUEST';
export const MANAGABLE_CATALOG_SUCCESS = 'MANAGABLE_CATALOG_SUCCESS';
export const UPDATE_STAFF_PERMISSIONS = 'UPDATE_STAFF_PERMISSIONS';
export const USER_DATA_FAIL = 'USER_DATA_FAIL';
export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const IMPERSONATE_HOUSE = 'IMPERSONATE_HOUSE';

type UserData = {
    adminAccount: boolean;
    email: string;
    featureLotExclusion: boolean;
    houseId: number | null;
    limitedAccess: boolean;
    managableCatalogs?: Catalog[];
    name: string;
    ownerLoginWithStaffPerms: boolean;
    sellerId?: number;
    staffPermissions: any;
    staffPerms: any;
    username: string;
};

export type State = {
    amcToken?: string;
    impersonatedHouseId: number;
    managableCatalogDataIsLoading?: boolean;
    referrer: string;
    sessionKey?: string;
    token: string;
    type: 'auctioneer' | 'bidder' | 'staff' | 'admin';
    userData: UserData;
    userDataIsLoading: boolean;
};

export const defaultUserSlice: State = {
    amcToken: null,
    impersonatedHouseId: null,
    managableCatalogDataIsLoading: false,
    referrer: null,
    sessionKey: null,
    token: null,
    type: null,
    userData: {
        adminAccount: false,
        email: '',
        featureLotExclusion: false,
        houseId: undefined,
        limitedAccess: false,
        managableCatalogs: null,
        name: '',
        ownerLoginWithStaffPerms: false,
        sellerId: undefined,
        staffPermissions: undefined,
        staffPerms: {},
        username: '',
    },
    userDataIsLoading: false,
};

export const reducer = handleActions(
    {
        [combineActions(LOG_OUT, LOGIN_FAIL, MANAGABLE_CATALOG_FAIL, USER_DATA_FAIL)]: (state: State): State => ({
            ...state,
            amcToken: null,
            impersonatedHouseId: null,
            managableCatalogDataIsLoading: false,
            sessionKey: null,
            token: null,
            type: null,
            userData: {
                adminAccount: false,
                email: '',
                featureLotExclusion: false,
                houseId: undefined,
                limitedAccess: false,
                name: '',
                ownerLoginWithStaffPerms: false,
                sellerId: undefined,
                staffPermissions: undefined,
                staffPerms: {},
                username: '',
            },
            userDataIsLoading: false,
        }),
        [combineActions(LOGIN_SUCCESS, USER_DATA_SUCCESS)]: (
            state: State,
            action: ActionWithPayload<{
                amcToken: string;
                sessionKey: string;
                token: string;
                type: 'auctioneer' | 'bidder' | 'staff' | 'admin';
                userData: UserData;
            }>
        ): State => ({
            ...state,
            amcToken: action.payload.amcToken,
            sessionKey: action.payload.sessionKey,
            token: action.payload.token,
            type: action.payload.type,
            userData: { ...state.userData, ...action.payload.userData },
            userDataIsLoading: false,
        }),
        [IMPERSONATE_HOUSE]: (state: State, action: ActionWithPayload<{ houseId: number }>): State => ({
            ...state,
            impersonatedHouseId: action.payload.houseId,
        }),
        [MANAGABLE_CATALOG_REQUEST]: (state: State): State => ({
            ...state,
            managableCatalogDataIsLoading: true,
        }),
        [MANAGABLE_CATALOG_SUCCESS]: (
            state: State,
            action: ActionWithPayload<{ catalogs: Catalog[]; sellerId: number }>
        ): State => ({
            ...state,
            managableCatalogDataIsLoading: false,
            userData: {
                ...state.userData,
                managableCatalogs: action.payload.catalogs,
                sellerId: action.payload.sellerId,
            },
        }),
        [UPDATE_STAFF_PERMISSIONS]: (state: State, action: ActionWithPayload<{ staffPermissions: any }>): State => ({
            ...state,
            userData: {
                ...state.userData,
                staffPermissions: action.payload.staffPermissions,
            },
        }),
        [USER_DATA_REQUEST]: (state: State): State => ({
            ...state,
            userDataIsLoading: true,
        }),
    },
    defaultUserSlice
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state.user;
const idSelector = (state, id) => id;

export const getAuthToken = createSelector(stateSelector, (state) => state.token);
export const getAmcToken = createSelector(stateSelector, (state) => state.amcToken);
export const getSessionKey = createSelector(stateSelector, (state) => state.sessionKey);
export const getReferralLink = createSelector(stateSelector, (state) => state.referrer);
export const getUserData = createSelector(stateSelector, (state) => state.userData);
export const getUserPermissions = createSelector(getUserData, (state) => state.staffPermissions);
export const staffHasPermissionSelector = createSelector(
    [getUserPermissions, idSelector],
    (state, permKey) => state.staffPermissions && Boolean(state.staffPermissions[permKey])
);
export const isOwnerWithStaffPerms = createSelector(getUserData, (state) => state.ownerLoginWithStaffPerms);

export const getImpersonatedHouseId = createSelector(stateSelector, (state) => state.impersonatedHouseId);

export const getUserType = createSelector(stateSelector, (state) => state.type);

export const isBrassOrStaffAdminSelector = createSelector(
    stateSelector,
    (state) => state.type === 'admin' || state.userData.adminAccount
);
export const isBrassAdminSelector = createSelector(stateSelector, (state) => state.type === 'admin');

export const managableCatalogDataIsLoading = createSelector(
    stateSelector,
    (state) => state.managableCatalogDataIsLoading
);
export const userDataIsLoading = createSelector(stateSelector, (state) => state.userDataIsLoading);

export const isAuthenticated = createSelector(getAuthToken, (token) => token !== null && token !== '');

export const isUserExcludedFromFeaturedLots = createSelector(getUserData, (userData) => userData.featureLotExclusion);
export const isUserExcludedFromCreatingNewAuctions = createSelector(getUserData, (userData) => userData.limitedAccess);
export const getUserManagableCatalogs = createSelector(getUserData, (userData) => userData.managableCatalogs);
export const getUsername = createSelector(getUserData, (userData) => userData.username);
export const getUserHouseId = createSelector(
    [getUserType, getUserData, getImpersonatedHouseId],
    (userType, userData, impersonatedHouseId) => {
        // userData Ids can be empty or null if admin, default to internal house 869 if so
        if (userType === 'admin') {
            return Number(impersonatedHouseId) || Number(userData.sellerId) || Number(userData.houseId) || 869;
        } else {
            return Number(userData.sellerId) || Number(userData.houseId);
        }
    }
);
export const getUserStaffHouseId = createSelector(getUserData, (userData: any) => {
    return userData.userHouseId;
});
export const getUserHasLimitedAccess = createSelector(getUserData, (userData: any) => {
    return userData.limitedAccess;
});
export const userEmailSelector = createSelector(getUserData, (userData) => userData.email);

export const getBidderId = () => -1;

/* ACTION CREATORS */
export const submitImpersonateHouse = (houseId: number) => (dispatch: Function) => {
    dispatch(fetchConversationFoldersIfNeeded(houseId));
    return dispatch({
        payload: { houseId },
        type: IMPERSONATE_HOUSE,
    });
};

export const setPermissionsFromToken = () => (dispatch: Function, getState: Function) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const jwt: any = jwtdecode(authToken);
    const staffPermissions = isEmpty(jwt.staff_perms) ? {} : JSON.parse(jwt.staff_perms);
    dispatch({
        payload: { staffPermissions },
        type: UPDATE_STAFF_PERMISSIONS,
    });
};

const fetchUserDataFail = (errorMessage) => ({
    error: true,
    payload: errorMessage,
    type: USER_DATA_FAIL,
});

export const fetchUserData = () => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: USER_DATA_REQUEST,
        });

        const response = await api.getSpaUser({
            authToken,
            deployment,
        });

        if (response && response.data && !isValidUserType(response.data.type)) {
            dispatch(fetchUserDataFail('Unauthorized'));
        } else {
            const sellerId = response.data.userData.houseId === null ? undefined : response.data.userData.houseId;

            await dispatch({
                payload: {
                    ...response.data,
                    token: authToken,
                    userData: {
                        ...response.data.userData,
                        sellerId,
                    },
                },
                type: USER_DATA_SUCCESS,
            });

            dispatch(fetchConversationFoldersIfNeeded(sellerId || getImpersonatedHouseId(state)));
            const houseId = getUserHouseId(state);
            if (houseId) {
                dispatch(fetchSellersIfNeeded([houseId]));
            }
        }
    } catch (error) {
        dispatch(fetchUserDataFail(error ? error.message : 'Unknown error'));
    }
};

export const fetchManagableCatalogs = (sellerId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            type: MANAGABLE_CATALOG_REQUEST,
        });

        const response = await api.getSmallSellerRecent({
            authToken,
            deployment,
            sellerId,
        });

        dispatch({
            payload: {
                catalogs: response,
                sellerId,
            },
            type: MANAGABLE_CATALOG_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: MANAGABLE_CATALOG_FAIL,
        });
    }
};

export const fetchCatalogData = (catalogId: number) => async (dispatch: Function, getState: Function) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            type: MANAGABLE_CATALOG_REQUEST,
        });

        const response = await api.getCatalogs({
            catalogId,
            deployment,
        });
        let sellerId = response.data.catalogs[0] ? response.data.catalogs[0].sellerId : -1;
        dispatch({
            payload: {
                catalogs: response.data.catalogs,
                sellerId: sellerId,
            },
            type: MANAGABLE_CATALOG_SUCCESS,
        });
        dispatch({
            meta: { actionTime: Date.now(), catalogIds: [catalogId] },
            payload: response.data,
            type: LOAD_CATALOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: MANAGABLE_CATALOG_FAIL,
        });
    }
};
