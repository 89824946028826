import { ActionWithPayload } from '../../types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { DanteCurrentItem } from '../../types/DanteCurrentItem';
import { GlobalState } from '@/redux/rootReducer';
import { handleActions } from 'redux-actions';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_ACCEPTED_ACTION,
    LIVE_BID_RETRACTED,
    LIVE_BID_RETRACTED_ACTION,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
} from './actions';
import { LiveBidAccepted } from '@/types/LiveBidAccepted';
import cloneDeep from 'lodash/cloneDeep';

export type State = {
    byId: {
        [itemId: number]: LiveBidAccepted[];
    };
};

export const DEFAULT_STATE: State = {
    byId: {},
};

export const reducer = handleActions(
    {
        [LIVE_BID_ACCEPTED]: (state: State, action: LIVE_BID_ACCEPTED_ACTION): State => {
            const { amount, assignedId, bidderId, catalogId, created, currency, itemId, source } = action.payload;

            const existing = cloneDeep(state.byId);
            existing[itemId] = existing[itemId] || [];

            existing[itemId].push({
                amount,
                assignedId,
                bidderId,
                catalogId,
                created,
                currency,
                itemId,
                source: bidderId === 0 ? 'Floor' : source, // TODO: do we need source to come back in these messages?
            });

            // Sort oldest to newest
            existing[itemId].sort((a, b) => a.created - b.created);

            return {
                ...state,
                byId: existing,
            };
        },
        [LIVE_BID_RETRACTED]: (state: State, action: LIVE_BID_RETRACTED_ACTION): State => {
            const { amount, assignedId, bidderId, catalogId, created, currency, itemId, source } = action.payload;

            const existing = cloneDeep(state.byId);
            existing[itemId] = existing[itemId] || [];

            existing[itemId].push({
                created,
                text: 'Bid Retracted',
            });

            existing[itemId].push({
                amount,
                assignedId,
                bidderId,
                catalogId,
                created: created + 1,
                currency,
                itemId,
                source: bidderId === 0 ? 'Floor' : source, // TODO: do we need source to come back in these messages?
            });

            // Sort oldest to newest
            existing[itemId].sort((a, b) => a.created - b.created);

            return {
                ...state,
                byId: existing,
            };
        },
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: State,
            action: ActionWithPayload<
                {
                    catalogId: number;
                    currency: string;
                    currentItem: DanteCurrentItem;
                    itemCount: number;
                    status: string;
                },
                {
                    actionTime: number;
                }
            >
        ): State => {
            const { catalogId, currency, currentItem } = action.payload;
            const { actionTime } = action.meta;
            const { itemId, leadingAssignedId, leadingBid, leadingBidder } = currentItem;

            // Add nothing if there had not been a bid already
            if (!leadingBid) {
                return state;
            }

            const existing = cloneDeep(state.byId);
            existing[itemId] = existing[itemId] || [];
            if (itemId > 0 && existing[itemId].length === 0) {
                existing[itemId].push({
                    amount: leadingBid,
                    assignedId: leadingAssignedId,
                    bidderId: leadingBidder,
                    catalogId,
                    created: actionTime,
                    currency,
                    itemId,
                    source: Boolean(leadingAssignedId) || Boolean(leadingBidder) ? 'Internet' : 'Floor',
                });

                // Sort oldest to newest
                existing[itemId].sort((a, b) => a.created - b.created);

                return {
                    ...state,
                    byId: existing,
                };
            }
            return state;
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState): State => state.liveBidsAccepted;
const idSelector = (state: GlobalState, id: number) => id;

const byIdSelector = createSelector(stateSelector, (state) => state.byId);

export const getLiveBidsAccepted = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);
