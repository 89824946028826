import { cachekey } from '../../config';
import { instrumentRequest } from '../../utils/rum';
import isEmpty from 'lodash/isEmpty';
import superagent from 'superagent';
import uuid from 'uuid';

type HandleResponse = {
    attachMetaToError?: boolean;
    err?: any;
    reject: Function;
    resolve: Function;
    response: any;
    success404?: boolean;
    transform?: Function;
};

type MakeRequest = {
    apiPath?: string;
    authToken?: string;
    deployment?: string;
    forceCredentials?: boolean;
    path: string;
    queryParams?: any;
    useCacheKey?: boolean;
};

type MakeRequestPrivate = MakeRequest & {
    requestFunc: any;
    // typing doesn't work yet but this is what it would be.
    // | typeof superagent.delete
    // | typeof superagent.get
    // | typeof superagent.post
    // | typeof superagent.patch
    // | typeof superagent.put;
};

const backupKey = uuid.v4();

export const delay = 10; // in milliseconds

export const handleResponse = ({
    attachMetaToError,
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponse) => {
    const rejectAndCaptureError = (errorToReturn, originalError?: any) => {
        console.error(originalError || errorToReturn);

        return reject(errorToReturn);
    };

    const handleRequestError = () => {
        // If nothing could handle the response, and it was an error, reject the whole thing
        // Should we make this always be a string?
        if (typeof err === 'string') {
            return rejectAndCaptureError(err);
        } else {
            return rejectAndCaptureError('There was an error with the request', err);
        }
    };

    // If there is a server level error, reject the promise
    if (err) {
        if (success404 && err.status === 404) {
            return resolve(response.body || {});
        }
        // If there is not a  response, we can handle the error now. (CORS errors look like this)
        if (isEmpty(response)) {
            return handleRequestError();
        }
    }

    // This is a single weird request that we need to check for in the clerk console
    if (response && response.req && response.req.url && response.req.url.includes('bidmaster') && response.text) {
        try {
            return resolve(JSON.parse(response.text));
        } catch (parseError) {
            return resolve(false);
        }
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    // Go ResponseEnvelope
    if ('error' in body && body.error) {
        if (Boolean(body.meta) && attachMetaToError) {
            return resolve(body);
        } else if ('meta' in body && body.meta && 'currentState' in body.meta) {
            return rejectAndCaptureError(body, body.payload);
        } else if (typeof body.error === 'string') {
            return rejectAndCaptureError(body.error);
        } else if (body?.payload) {
            return rejectAndCaptureError(body.payload);
        } else {
            return rejectAndCaptureError(body);
        }
    }

    // If the success and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'message' in body && !body.success) {
        return rejectAndCaptureError(body.message);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        if ('payload' in body) {
            if ('error' in body.payload) {
                // Check if the error is from the create account endpoint for the verify email screen
                if (body.payload.error === 'User Already Exists') {
                    return resolve(body);
                }
                return rejectAndCaptureError(body.payload.error);
            }
            return rejectAndCaptureError(body.payload);
        }
        return rejectAndCaptureError(body);
    }

    //if the response has an errorMessage it means it's broken
    if (Boolean(body.errorMessage)) {
        return rejectAndCaptureError(body.errorMessage);
    }

    if (err) {
        // If we have gotten this far and there was an err object, handle it
        return handleRequestError();
    }

    // else
    return resolve(body);
};

const addCacheKey = (request) => {
    let c = cachekey;
    if (!cachekey) {
        c = backupKey;
    }
    request.query({ c });
    return request;
};

const hosts = [
    {
        dropDeploymentForProd: true,
        path: '<ANALYTICS-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/analytics/APIPATH',
    },
    {
        path: '<ANNOUNCEMENT-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/announcement-api/APIPATH',
    },
    {
        dropDeploymentForProd: true,
        path: '<APPROVAL-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/approval/APIPATH',
    },
    {
        path: '<APPROVAL-SERVER-API>',
        url: 'https://approval-DEPLOYMENT.liveauctioneers.com/approval/APIPATH',
    },
    {
        path: '<BILLING-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/billing/APIPATH',
    },
    {
        path: '<BILLING-SERVER-API>',
        url: 'https://billing-DEPLOYMENT.liveauctioneers.com/APIPATH',
    },
    {
        path: '<ATGPAY-SELLER-API>',
        url: 'https://atgpay-marketplace-auth-DEPLOYMENT.liveauctioneers.com/atgpay-auth/sellers/APIPATH',
    },
    {
        path: '<ATGPAY-SUPPORT-API>',
        url: 'https://atgpay-marketplace-auth-DEPLOYMENT.liveauctioneers.com/atgpay-auth/support/APIPATH',
    },
    {
        path: '<BILLING-SERVER-API>',
        url: 'https://billing-DEPLOYMENT.liveauctioneers.com/APIPATH',
    },
    { path: '<HOUSEREPORT-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<CATALOG-MANAGEMENT-API>', url: 'https://catalog-management-DEPLOYMENT.liveauctioneers.com/cms/APIPATH' },
    { path: '<CATEGORY-API>', url: 'https://category-api-DEPLOYMENT.liveauctioneers.com/category-api/APIPATH' },
    { path: '<CLERK-SERVICE>', url: 'https://dante-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<COLLECTION-API>', url: 'https://api.liveauctioneers.com/collections/APIPATH/DEPLOYMENT' },
    { path: '<CURRENCY-API>', url: 'https://api.liveauctioneers.com/currency/DEPLOYMENT' },
    { path: '<ESCROW-API>', url: 'https://api.liveauctioneers.com/escrow/APIPATH/DEPLOYMENT' },
    { path: '<FORUMPOSTS-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/forum/posts/APIPATH' },
    { path: '<FP-API>', url: 'https://api.liveauctioneers.com/fp-DEPLOYMENT/APIPATH' },
    { path: '<ITEM-API>', url: 'https://item-api-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    {
        path: '<CROWS-API>',
        url: 'https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/APIPATH',
    },
    { path: '<MAINHOST-API>', url: 'https://mainhost-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    {
        dropDeploymentForProd: true,
        path: '<MARKETPLACE-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/marketplace/APIPATH',
    },
    { path: '<MESSAGING-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/messaging/APIPATH' },
    {
        path: '<MESSAGING-ATTACHMENTS-API>',
        url: 'https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/APIPATH',
    },
    {
        dropDeploymentForProd: true,
        path: '<PAYMENT-API>',
        url: 'https://api-DEPLOYMENT.liveauctioneers.com/payment/APIPATH',
    },
    { path: '<PROMOTION-API>', url: 'https://api.liveauctioneers.com/promotion/APIPATH/DEPLOYMENT' },
    { path: '<PROMOTION-API-TWO>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/promotion/APIPATH' },
    { path: '<PUBNUB-API>', url: 'https://ps.pndsn.com/v2/presence/sub-key/' },
    { path: '<PUSH-NOTIFICATION-API>', url: 'https://api.liveauctioneers.com/push/APIPATH/DEPLOYMENT' },
    {
        path: '<RECOMMENDATION-API>',
        url: 'https://api.liveauctioneers.com/recommendation/getrecommendations/DEPLOYMENT',
    },
    { path: '<BOOMCATCH-API>', url: 'https://boomcatch-DEPLOYMENT.liveauctioneers.com/beacon/clerk' },
    { path: '<REDSTRIPE-API>', url: 'https://redstripe-DEPLOYMENT.liveauctioneers.com/payment-api/APIPATH' },
    { path: '<REVIEW-API>', url: 'https://review-service-DEPLOYMENT.liveauctioneers.com/review/APIPATH' },
    { path: '<SAVED-SEARCH-API>', url: 'https://api.liveauctioneers.com/savedsearch/APIPATH/DEPLOYMENT' },
    { path: '<SEARCH-API>', url: 'https://search-party-DEPLOYMENT.liveauctioneers.com/search/APIPATH' },
    { path: '<AUCTION-ENGINE-API>', url: 'https://hardboiled-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<STATS-API>', url: 'https://crispy-pancake-DEPLOYMENT.liveauctioneers.com/APIPATH' },
    { path: '<STREAM-API>', url: 'https://api.liveauctioneers.com/stream/APIPATH/DEPLOYMENT' },
    { path: '<STREAM-MANAGER>', url: 'https://api.liveauctioneers.com/stream/APIPATH/DEPLOYMENT' },
    { path: '<SUPPORT-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/support/APIPATH' },
    { path: '<USER-API>', url: 'https://api.liveauctioneers.com/user/APIPATH/DEPLOYMENT' },
    { path: '<VARIANT-TEST-API>', url: 'https://variant-test-api-DEPLOYMENT.liveauctioneers.com/variant-test/APIPATH' },
    { path: '<REPORT-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/report' },
    { path: '<MARKETING-REPORTS-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/marketing-reports' },
    { path: '<HOUSE-FINANCE-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/invoice/APIPATH' },
    { path: '<HOUSE-USER-API>', url: 'https://house-user-service-DEPLOYMENT.liveauctioneers.com/houseuser/APIPATH' },
    { path: '<SUPPORT-API>', url: 'https://api-DEPLOYMENT.liveauctioneers.com/support/APIPATH' },
    { path: '<CATALOG_MANAGEMENT>', url: 'https://catalog-management-DEPLOYMENT.liveauctioneers.com/catalog/APIPATH' },
    {
        path: '<ADDRESS-VALIDATION-API>',
        url: 'https://validation-DEPLOYMENT.liveauctioneers.com/APIPATH',
    },
    {
        path: '<SHIPPING-API>',
        url: 'https://shipping-api-DEPLOYMENT.liveauctioneers.com/shipping/APIPATH',
    },
    { path: '<TELEGRAM-API', url: 'https://telegram-stage.liveauctioneers.com' },
];

export const buildUrl = (path: string = '', deployment: string = '', apiPath: string = '') => {
    let updatedPath = path;
    hosts.forEach((obj) => {
        if (path.includes(obj.path)) {
            let url = '';

            if (deployment === 'prod' && obj.dropDeploymentForProd) {
                if (obj.url.indexOf('-DEPLOYMENT') !== -1) {
                    url = obj.url.replace('-DEPLOYMENT', '');
                }
            } else {
                url = obj.url.replace('DEPLOYMENT', deployment);
            }

            // Upgrade auction-service api call prefix to 'partners' in production
            if (path === '<AUCTION-ENGINE-API>' && deployment === 'prod') {
                const subdomain = new URL(url).hostname.split('.')[0];
                url = url.replace(subdomain, 'partners');
            }

            updatedPath = path.replace(obj.path, url);
            updatedPath = updatedPath.replace('APIPATH', apiPath);
        }
    });
    return updatedPath;
};

const buildRequest = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials = false,
    path,
    queryParams,
    requestFunc,
    useCacheKey = true,
}: MakeRequestPrivate) => {
    const url = buildUrl(path, deployment, apiPath);
    const request = requestFunc(url);

    if (forceCredentials) {
        request.withCredentials();
    }
    if (useCacheKey) {
        addCacheKey(request);
    }
    if (queryParams) {
        request.query(queryParams);
    }
    if (authToken) {
        request.set('Authorization', `Bearer ${authToken}`);
    }

    // only send after the request has completed, we want 1 resource timing entry per call.
    // session.id can be used to link them up
    request.on('progress', ({ percent }) => percent >= 100 && instrumentRequest(path));

    return request;
};

export const makeDelete = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        path,
        queryParams,
        requestFunc: superagent.delete,
        useCacheKey,
    });

export const makeGet = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        path,
        queryParams,
        requestFunc: superagent.get,
        useCacheKey,
    });

export const makePost = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        path,
        queryParams,
        requestFunc: superagent.post,
        useCacheKey,
    });

export const makePatch = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        path,
        queryParams,
        requestFunc: superagent.patch,
        useCacheKey,
    });

export const makePut = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        path,
        queryParams,
        requestFunc: superagent.put,
        useCacheKey,
    });
